/** @jsx jsx */
// tslint:disable:max-line-length
import { css, jsx } from '@emotion/core';
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableList, TableTitleHeader } from '../../_shared/table-list';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import { Icon, Alert, Layout, Menu, Input } from 'antd';
import { get, sortBy } from 'lodash';
import styles from './manage-profile.styles';
import { Org } from '../../_shared/interfaces/org';
import { Location } from '../../_shared/interfaces/location';
import { Select } from '../../_shared/select';
import { SelectValue } from 'antd/lib/select';
import {
  getAllMyOrgs,
  findAssocDentalOrgs,
  isHandpiece,
  userHasRole,
  sortAlphaNumeric,
} from '../../utils';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import {
  setHandpieceProfileOverride,
  getHandpieceProfilesForLocation,
} from '../../_shared/services/handpiece-profiles.service';
import {
  IHandpieceProfile,
  IHandpieceDefaultsProfiles,
  IHandpiecePreset,
  humanReadableEndoSetting,
  humanReadableGearRatio,
  HandpieceGearRatio,
  HandpieceEndoSetting,
  HandpiecePresetKey,
  HandpieceProfileKey,
} from '../../_shared/interfaces/handpieceProfiles';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { useStore } from 'react-redux';

interface IRowItem {
  preset: IHandpiecePreset;
  profileKey: HandpieceProfileKey;
  presetKey: HandpiecePresetKey;
}

type SelectedProfile = {
  key: HandpieceProfileKey;
  profile: IHandpieceProfile;
};

const _ManageProfileComponent: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const store = useStore();
  const selected = get(location, 'state.selected', undefined);

  //---- STATE
  const [userOrgs, setUserOrgs] = useState<Org[]>([]);
  const [selectedDentalOrg, setSelectedDentalOrg] = useState<Org | undefined>(
    selected
      ? selected.dentalOrg
      : userOrgs.length > 0
      ? userOrgs[0]
      : undefined
  );
  const [validLocations, setValidLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(
    selected
      ? selected.location
      : validLocations.length > 0
      ? validLocations[0]
      : undefined
  );

  const [profiles, setProfiles] = useState<
    IHandpieceDefaultsProfiles | undefined
  >(undefined);
  const [selectedPresetType, setSelectedPresetType] = useState<PresetType>(
    selected ? selected.presetType : 'prep'
  );
  const [presetsToDisplay, setPresetsToDisplay] = useState<IRowItem[]>([]);

  const [selectedProfile, setSelectedProfile] = useState<
    SelectedProfile | undefined
  >(
    selected
      ? { key: selected.profileKey, profile: selected.profile }
      : undefined
  );
  const [selectedProfileName, setSelectedProfileName] = useState<string>(
    selectedProfile ? selectedProfile.profile.name : 'N/A'
  );
  const [editingProfileName, setEditingProfileName] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    get(location, 'state.successMessage', undefined)
  );

  //---- EFFECTS

  // orgs need to be calculated at runtime in case
  // the user is associated with any SERVICE ORGS, we
  // need to flatted that down to related DENTAL ORGS,
  // we need the DENTAL ORG id to get related locations
  useEffect(() => {
    const asyncWork = async () => {
      const sState = store.getState();
      const showAll = userHasRole([0, 1], sState);
      const serviceAndRegularOrgs: Org[] = showAll
        ? await getOrgs()
        : getAllMyOrgs(sState);
      var final: Org[] = [];
      for (const someOrg of serviceAndRegularOrgs) {
        // this is a service org and we have to go a layer deeper
        if (someOrg.orgType === 0) {
          for (const org of findAssocDentalOrgs(someOrg.id, sState)) {
            const orgEquip = await getEquipments({
              dentalOrgId: { eq: org.id },
            });
            if (
              orgEquip.some(e => isHandpiece(e)) &&
              !final.some(o => o.id === org.id)
            ) {
              final.push(org);
            }
          }
        } else {
          const orgEquip = await getEquipments({
            dentalOrgId: { eq: someOrg.id },
          });
          if (
            orgEquip.some(e => isHandpiece(e)) &&
            !final.some(o => o.id === someOrg.id)
          ) {
            final.push(someOrg);
          }
        }
      }
      setUserOrgs(final);
    };

    asyncWork();
  }, []);

  // when orgs options change, auto-select the first (or un-set anything)
  useEffect(
    () => setSelectedDentalOrg(userOrgs.length > 0 ? userOrgs[0] : undefined),
    [userOrgs]
  );

  // when selectedDentalOrg changes, we need to update the list of valid Locations
  useEffect(() => {
    const fetchLocations = async () => {
      const sState = store.getState();
      if (selectedDentalOrg) {
        setLoading(true);
        const locations = get(sState, 'dash.locations', []) as Location[];
        const equipment = await getEquipments();
        const locationsForSelectedOrg = locations.filter(
          l =>
            selectedDentalOrg.id === l.orgId ||
            selectedDentalOrg.serviceOrgId === l.orgId
        );
        var final: Location[] = [];

        for (var o of locationsForSelectedOrg) {
          const locEquip = equipment.filter(e => e.locationId == o.id);
          if (locEquip.some(e => isHandpiece(e))) {
            final.push(o);
          }
        }
        setValidLocations(final);
        if (final.length > 0) {
          setSelectedLocation(final[0]);
        }
        setLoading(false);
      } else {
        setValidLocations([]);
      }
    };

    fetchLocations();
  }, [selectedDentalOrg]);

  // when the selected location changes,
  // the profiles must be re-calculated
  useEffect(() => {
    const work = async (loc: Location) => {
      const profiles = await getHandpieceProfilesForLocation(loc);
      profiles && setProfiles(profiles);
    };

    selectedLocation && work(selectedLocation);
  }, [selectedLocation]);

  // when the profiles change, let's go ahead and auto-select the first (if there isn't already a selected profile)
  useEffect(() => {
    if (
      (!selectedProfile || !selectedProfile.profile) &&
      profiles &&
      profiles.profile1
    ) {
      setSelectedProfile({ key: 'profile1', profile: profiles.profile1 });
    } else if (
      selectedProfile &&
      selectedProfile.key &&
      profiles &&
      profiles[selectedProfile.key]
    ) {
      setSelectedProfile({
        key: selectedProfile.key,
        profile: profiles[selectedProfile.key],
      });
    }
  }, [profiles]);

  // when either the selected preset type (endo or prep) changes
  // or the selectedProfile changes, we gotta update the presets to display
  useEffect(() => {
    if (selectedProfile && selectedProfile.key && selectedProfile.profile) {
      if (selectedPresetType === 'endo') {
        setPresetsToDisplay([
          {
            presetKey: 'e1',
            profileKey: selectedProfile.key,
            preset: get(
              selectedProfile,
              `profile.endo_presets.e1`,
              get(profiles, 'endo_presets.e1')
            ),
          },
          {
            presetKey: 'e2',
            profileKey: selectedProfile.key,
            preset: get(
              selectedProfile,
              `profile.endo_presets.e2`,
              get(profiles, 'endo_presets.e2')
            ),
          },
          {
            presetKey: 'e3',
            profileKey: selectedProfile.key,
            preset: get(
              selectedProfile,
              `profile.endo_presets.e3`,
              get(profiles, 'endo_presets.e3')
            ),
          },
        ]);
      } else {
        setPresetsToDisplay([
          {
            presetKey: 'p1',
            profileKey: selectedProfile.key,
            preset: get(
              selectedProfile,
              `profile.prep_presets.p1`,
              get(profiles, 'prep_presets.p1')
            ),
          },
          {
            presetKey: 'p2',
            profileKey: selectedProfile.key,
            preset: get(
              selectedProfile,
              `profile.prep_presets.p2`,
              get(profiles, 'prep_presets.p2')
            ),
          },
          {
            presetKey: 'p3',
            profileKey: selectedProfile.key,
            preset: get(
              selectedProfile,
              `profile.prep_presets.p3`,
              get(profiles, 'prep_presets.p3')
            ),
          },
        ]);
      }

      setSelectedProfileName(selectedProfile.profile.name);
    } else {
      setSelectedProfileName('N/A');
    }
  }, [selectedPresetType, selectedProfile]);

  //---- UI HANDLERS

  const dentalOrgOptionSelected = useCallback(
    (value: SelectValue) => {
      const selected = userOrgs.find(o => o.id === value);
      selected && setSelectedDentalOrg(selected);
    },
    [userOrgs]
  );

  const locationOptionSelected = useCallback(
    (value: SelectValue) => {
      const selected = validLocations.find(l => l.id === value);
      selected && setSelectedLocation(selected);
    },
    [validLocations]
  );

  const profileSelected = useCallback(
    (profile: IHandpieceProfile, index: number) => {
      if (index > 9 || index < 0) return;
      const key = get(profile, 'key');
      if (key) {
        setSelectedProfile({ key, profile });
      }
    },
    [profiles, selectedLocation, selectedDentalOrg]
  );

  const editNameClicked = useCallback(() => {
    setEditingProfileName(true);
  }, []);

  const editNameCanceled = useCallback(() => {
    setSelectedProfileName(
      selectedProfile ? selectedProfile.profile.name : 'N/A'
    );
    setEditingProfileName(false);
  }, [selectedProfile]);

  const editNameConfirmed = useCallback(() => {
    if (selectedProfileName.match(/^[A-z0-9. ]{1,16}$/)) {
      if (profiles && selectedProfile) {
        var mutable = profiles[selectedProfile.key];
        if (mutable) {
          mutable.name = selectedProfileName;
          setSelectedProfile({ key: selectedProfile.key, profile: mutable });
        }

        if (selectedLocation) {
          setHandpieceProfileOverride(
            selectedLocation,
            selectedProfile.key,
            selectedProfileName
          );
        }
      }
      setEditingProfileName(false);
    } else {
      editNameCanceled();
    }
  }, [selectedProfile, selectedProfileName, profiles]);

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setSelectedProfileName(event.target.value ? event.target.value : '');
    }
  }, []);

  const presetSelected = useCallback(
    (rowItem: IRowItem, _index: number) => {
      if (
        selectedDentalOrg &&
        selectedLocation &&
        selectedProfile &&
        selectedProfile.profile
      ) {
        const sState = store.getState();
        history.replace('/dashboard/editPreset', {
          sState,
          profileKey: rowItem.profileKey,
          presetKey: rowItem.presetKey,
          preset: rowItem.preset,
          profile: selectedProfile.profile,
          org: selectedDentalOrg,
          location: selectedLocation,
        });
      }
    },
    [selectedLocation, selectedProfile, selectedDentalOrg]
  );

  const validProfiles = sortAlphaNumeric(
    Object.keys(profiles || {})
      .filter(k => k.startsWith('profile'))
      .map(k => get(profiles, k, {})),
    'key'
  ) as IHandpieceProfile[];

  //---- UI DECLARATIVE

  return (
    <div css={css(styles.pageContainer)}>
      {successMessage && (
        <div css={css(styles.successToast)}>
          <Alert
            type="success"
            message={successMessage}
            closable
            onClose={() => setSuccessMessage(undefined)}
          />
        </div>
      )}

      <div css={css(styles.controlsContainer)}>
        {!!profiles && (
          <Layout.Sider width={200} css={css(styles.sideBar)}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[get(validProfiles, '0.key')]}
              selectedKeys={
                selectedProfile ? [get(selectedProfile, 'key')] : []
              }
              style={{ height: '100%', borderRight: 0 }}
            >
              {validProfiles.map((profile, i) => {
                return (
                  <Menu.Item
                    key={get(profile, 'key')}
                    onClick={() => profile && profileSelected(profile, i)}
                  >
                    {get(profile, 'name', get(profile, 'key', 'n/a'))}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Layout.Sider>
        )}

        <div css={css(styles.presetsContainer)}>
          {!!error && (
            <Alert
              css={css(SharedStyles.formAlert)}
              type="error"
              message={error}
              closable
              onClose={() => setError(undefined)}
            />
          )}

          <div css={css(styles.presetTypeToggle)}>
            <Button
              title="Prep"
              outline={selectedPresetType !== 'prep'}
              onClick={() => setSelectedPresetType('prep')}
            />
            <Button
              title="Endo"
              outline={selectedPresetType !== 'endo'}
              onClick={() => setSelectedPresetType('endo')}
            />
          </div>

          <div css={css(styles.dropDownSelections)}>
            {userOrgs.length > 0 && (
              <div css={css(styles.dropDownItem)}>
                <label css={css(styles.dropDownLabel)}>Select Dental Org</label>
                <Select
                  mode="single"
                  css={css(styles.dropDownSelect)}
                  placeholder={'Dental Org'}
                  value={selectedDentalOrg ? selectedDentalOrg.name : undefined}
                  onChange={dentalOrgOptionSelected}
                  filterOption={(input, option) => {
                    const name = option.props.children as string;
                    if (name)
                      return (
                        name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    return true;
                  }}
                >
                  {userOrgs.map((item, i) => (
                    <Select.Option key={i} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}

            {validLocations.length > 0 && (
              <div css={css(styles.dropDownItem)}>
                <label css={css(styles.dropDownLabel)}>Select Location</label>
                <Select
                  mode="single"
                  css={css(styles.dropDownSelect)}
                  placeholder={'Locations'}
                  value={selectedLocation ? selectedLocation.name : undefined}
                  onChange={locationOptionSelected}
                  filterOption={(input, option) => {
                    const name = option.props.children as string;
                    if (name)
                      return (
                        name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    return true;
                  }}
                >
                  {validLocations.map((item, i) => (
                    <Select.Option key={i} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </div>

          <div css={css(styles.table)}>
            <div css={css(`flex-direction: row`)}>
              {!editingProfileName && (
                <TableTitleHeader>{selectedProfileName}</TableTitleHeader>
              )}
              {editingProfileName && (
                <Input
                  maxLength={16}
                  onChange={onNameChange}
                  value={selectedProfileName}
                  css={css(`width: 12%`)}
                />
              )}

              <div css={css(styles.nameControls)}>
                {!editingProfileName && (
                  <Icon onClick={() => editNameClicked()} type={'edit'} />
                )}

                {editingProfileName && (
                  <Icon
                    onClick={() => editNameConfirmed()}
                    type={'check-circle'}
                  />
                )}
                {editingProfileName && (
                  <Icon
                    onClick={() => editNameCanceled()}
                    css={css(`margin-left: 5px`)}
                    type={'close-circle'}
                  />
                )}
              </div>
            </div>

            {loading && <Icon css={css('margin-left: 4px;')} type="loading" />}

            {!loading && (
              <div>
                <TableList
                  title={'Presets'}
                  data={presetsToDisplay as never}
                  rowKey={r => `${JSON.stringify(r)}`}
                  loading={loading}
                  columns={PresetColumns.filter(c =>
                    selectedPresetType === 'endo'
                      ? true // remove endo mode only columns if not viewing endo profiles|
                      : c.dataIndex !== 'preset.torque_threshold' &&
                        c.dataIndex !== 'preset.endo_mode_enum_raw'
                  )}
                  includeCount={false}
                  showPagination={false}
                  canSelectRows={false}
                  implementScroll={false}
                  rowClick={presetSelected}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PresetColumns = [
  {
    title: 'Preset Name',
    dataIndex: 'preset.name',
    width: '11.1%',
  },
  {
    title: 'Gear Ratio',
    dataIndex: 'preset.gear_ratio_enum_raw',
    width: '11.1%',
    render: (gr: HandpieceGearRatio) => (
      <span>{humanReadableGearRatio(gr)}</span>
    ),
  },
  {
    title: 'RPM',
    dataIndex: 'preset.rpm',
    width: '9%',
  },
  {
    title: 'Rotation',
    dataIndex: 'preset.rotation_clockwise',
    width: '11.1%',
    render: (clockwise: boolean) => (
      <span>{clockwise ? 'clockwise' : 'counter-clockwise'}</span>
    ),
  },
  {
    title: 'Torque',
    dataIndex: 'preset.torque_threshold',
    width: '9%',
  },
  {
    title: 'Endo Setting',
    dataIndex: 'preset.endo_mode_enum_raw',
    width: '11.1%',
    render: (em: HandpieceEndoSetting) => (
      <span>{humanReadableEndoSetting(em)}</span>
    ),
  },
];

type PresetType = 'endo' | 'prep';

export const ManageProfileComponent = withRouter(_ManageProfileComponent);
