/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import { userHasRole, isPB } from '../../utils';
import { User } from '../../_shared/interfaces/user';
import { Icon, Alert } from 'antd';
import { AppState } from '../../app.state';
import { get } from 'lodash';
import styles from './manage-preset.styles';
import colors from '../../_shared/colors';
import { Profile } from '../../_shared/interfaces/profile';
import { Preset, _Preset } from '../../_shared/interfaces/preset';
import { getProfile } from '../../_shared/services/manage-profiles.service';
import { getOrg } from '../../_shared/services/manage-orgs.service';
import { Org } from '../../_shared/interfaces/org';
import { getUser } from '../../_shared/services/manage-users.service';
import { getPreset } from '../../_shared/services/manage-presets.service';
import InfoRow from '../../_shared/info-row';
import { renderHandpieceCol } from '../../utils/mixins';

interface IProps extends RouteComponentProps {
  sState: AppState;
  updatePresets: () => Promise<void>;
}

interface IState {
  show: boolean;
  loading: boolean;
  isEdit: boolean;
  error: string | undefined;
  success: string | undefined;
  active: boolean;
  preset: Preset | undefined;
  profile: Profile | undefined;
  dentalOrg: Org | undefined;
  user: User | undefined;
}

export class _ManagePresetComponent extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,
    active: true,
    activationDate: undefined,
    dentalOrg: undefined,
    equipment: [],
    allEquipment: [],
    preset: undefined,
    profile: undefined,
    user: undefined,
  } as IState;
  componentDidMount = async () => {
    const { history } = this.props;
    const preset =
      get(history, 'location.state.preset.id') &&
      (await getPreset(get(history, 'location.state.preset.id')));

    this.setState(
      {
        preset: Array.isArray(preset) ? preset[0] : preset,
      },
      this.retrieveData
    );
  };

  retrieveData = async (res?: { error?: string }) => {
    try {
      if (res && res.error) {
        return this.setState({ error: res.error });
      }

      this.setState(
        {
          show: false,
          loading: true,
        },
        () => this.setState({ show: true })
      );

      const profile: Profile = await getProfile(
        get(this.state, 'preset.profileId')
      );
      const dentalOrg = profile
        ? await getOrg(get(profile, 'dentalOrgId'))
        : null;
      const user = profile.userId ? await getUser(profile.userId) : undefined;
      this.setState({
        profile,
        dentalOrg,
        user,
        show: true,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  toggleEdit = () => {
    const { isMyPresets } = get(this.props, 'history.location.state');
    this.props.history.push(
      isMyPresets ? '/dashboard/editMyPreset' : '/dashboard/editPreset',
      {
        editMode: true,
        profile: this.state.profile,
        preset: this.state.preset,
        isMyPresets,
        isAdd: false,
        back: '< Back to View Preset',
      }
    );
  };

  renderSubInfo = () => {
    const { preset, profile, dentalOrg } = this.state;
    const items = [
      {
        title: 'Dental Org Name',
        toText: () => get(dentalOrg, 'name'),
      },
      {
        title: 'Profile',
        toText: () => `Profile: ${get(profile, 'name')}`,
      },
      {
        title: 'Type',
        toText: () => `Type: ${get(preset, 'type')}`,
      },
    ];

    return (
      <span css={css(`font-size: 12px;`)}>
        {items
          .filter((item, i) => {
            const val = item.toText() ? item.toText() : undefined;
            return !!val;
          })
          .map((item, i) => {
            const val = item.toText();
            return (
              <span key={i}>
                {i > 0 ? (
                  <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</span>
                ) : null}
                {`${val}`}
              </span>
            );
          })}
      </span>
    );
  };

  renderInfoRow1 = () => {
    const { sState } = this.props;
    const ispb = isPB(sState);
    const preset = this.state.preset
      ? this.state.preset
      : (new _Preset() as Preset);
    const { speed, torque, gearRatio } = preset;
    const columns = [
      {
        render: () =>
          renderHandpieceCol(
            undefined,
            {
              text: !!speed ? speed.toString() : 'N/A',
              // color: colors.lightText,
            },
            { text: 'Speed (RPM)', icon: 'speed' }
          ),
      },
      {
        render: () =>
          renderHandpieceCol(
            undefined,
            {
              text: !!torque ? torque.toString() : 'N/A',
              color: colors.lightText,
            },
            { text: 'Torque (NCM)', icon: 'torque' }
          ),
      },
      {
        render: () =>
          renderHandpieceCol(
            undefined,
            {
              text: !!gearRatio ? gearRatio : 'N/A',
              color: colors.lightText,
            },
            { text: 'Gear Ratio', icon: 'gearratio' }
          ),
      },
    ];

    return (
      <InfoRow
        css={css('marginTop; 20, marginBotton: 20;')}
        isPhoneBreak={ispb}
        noFill={true}
        columns={columns}
      />
    );
  };

  renderInfoRow2 = () => {
    const { sState } = this.props;
    const ispb = isPB(sState);
    const preset = this.state.preset
      ? this.state.preset
      : (new _Preset() as Preset);
    const { endoSettings, oscillation, rotation } = preset;
    const columns = [
      {
        render: () =>
          renderHandpieceCol(
            undefined,
            {
              text: !!rotation ? rotation : 'N/A',
              color: colors.lightText,
            },
            { text: 'Rotation Direction', icon: 'rotation' }
          ),
      },
      {
        render: () =>
          renderHandpieceCol(
            undefined,
            {
              text: !!endoSettings ? endoSettings : 'N/A',
              color: colors.lightText,
            },
            { text: 'Endo Settings', icon: 'endo' }
          ),
      },
      {
        render: () =>
          renderHandpieceCol(
            undefined,
            {
              text: !!oscillation ? oscillation : 'N/A',
              color: colors.lightText,
            },
            { text: 'Oscillation', icon: 'oscillation' }
          ),
      },
    ];

    return (
      <InfoRow
        css={css('margin-top; 20px, margin-botton: 20px;')}
        isPhoneBreak={ispb}
        noFill={true}
        columns={columns}
      />
    );
  };

  render() {
    const { loading, error, success, preset } = this.state;
    const isMyPresets = get(this.props, 'history.location.state.isMyPresets');
    const canShowEdit = userHasRole([2, 3, 4], this.props.sState);
    return (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link onClick={() => this.props.history.goBack()}>
            {isMyPresets
              ? `< Back to Manage Handpiece Profile`
              : `< Back to Manage Org`}
          </Link>
        </div>

        <div
          css={css`
            margin-left: auto;
          `}
        >
          {canShowEdit && (
            <div
              css={css(`
              display: flex; 
              float: right; 
              margin-left: 10px;`)}
            >
              <Button
                css={css('float: right;')}
                title="Edit"
                onClick={this.toggleEdit}
              />
            </div>
          )}
        </div>

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <TableTitleHeader>
            {get(preset, 'name', 'N/A')}
            {loading && <Icon css={css('margin-left: 5px;')} type="loading" />}
          </TableTitleHeader>
        </div>

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          {this.renderSubInfo()}
        </div>
        <div css={css(styles.hr)} />
        {this.renderInfoRow1()}
        <div css={css(styles.hr2)} />
        {this.renderInfoRow2()}
        <div css={css(styles.hr2)} />
      </div>
    );
  }
}

export const ManagePresetComponent = withRouter(_ManagePresetComponent);
