/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import { Input, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Content from './verify.content';
import { buildErrorMsgFromForm, getContent } from '../../utils';
import { TargetChange } from '../../_shared/interfaces/target';
import { Button } from '../../_shared/button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Logo from '../../_shared/logo';
import SharedStyles from '../../_shared/styles';
import styles from '../authentication.styles';
import { Alert, message } from 'antd';
import Link from '../../_shared/link';
import { resendCode } from '../../_shared/services/authentication.service';
import { get } from 'lodash';
import {
  generatePinAndSendMsgToUser,
  validateCode,
} from '../../_shared/services/manage-users.service';

interface IProps extends RouteComponentProps, FormComponentProps {
  verify: (email: string, code: string) => Promise<{ error?: string }>;
  signIn: (
    email: string,
    password: string,
    checkRole?: boolean,
    setToActive?: boolean
  ) => Promise<{}>;
  destination?: string;
}

class VerifyComponent extends Component<IProps> {
  state = {
    code: '',
    error: null,
    loading: false,
    is2fa: undefined,
    email: '',
    password: '',
    method: undefined,
  };
  componentDidMount = () => {
    const { history } = this.props;
    const state = get(history, 'location.state', {});
    this.setState({
      ...this.state,
      ...state,
    });
  };
  changeVal = (e: TargetChange) => {
    this.setState({ code: e.target.value });
  };
  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.setState(this.state);
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      const { code } = values;
      try {
        const { is2fa, email, password } = this.state;

        let resp;
        if (is2fa) {
          const isValid = await validateCode(email, code);
          if (!isValid) {
            throw new Error('Code does not match');
          }
        } else {
          resp = await this.props.verify(email, code);
        }
        await this.props.signIn(email, password, false, true);

        const error = get(resp, 'error');
        if (error) {
          this.setState({ loading: false, error });
        } else {
          this.props.history.push(get(this, 'props.location.state.destination', '/dashboard'));
        }
      } catch (err) {
        this.setState({
          error: err.message,
          loading: false,
        });
      }
    });
  };
  resendCode = async () => {
    try {
      const { email, is2fa } = this.state;
      if (is2fa) {
        const { method } = await generatePinAndSendMsgToUser(email);
        this.setState({
          is2fa: true,
          method,
        });
      } else {
        await resendCode(email);
      }
      message.success(
        `New verification code sent to ${
          this.state.method === 'email' ? 'email' : 'sms'
        }`
      );
    } catch (err) {
      message.error(`Error: ${err.message}`);
    }
  };
  render() {
    const { error, loading, is2fa } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const code = getFieldValue('code');
    const btnDisabled = !code;
    const content = getContent(Content);
    return (
      <div className="Verify" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <p css={css(styles.title)}>
          {(this.state.method &&
            this.state.method === 'sms' &&
            content.title.sms) ||
            content.title.email}
        </p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <Form onSubmit={this.submit}>
            <Form.Item label={content.label}>
              {getFieldDecorator('code')(<Input />)}
            </Form.Item>
            <Form.Item>
              <div css={css(styles.buttonContainer, `margin-top: 10px;`)}>
                <Link
                  css={css('margin-bottom: 10px;')}
                  onClick={this.resendCode}
                >
                  {'Resend Verification Code'}
                </Link>
                <Button
                  loading={loading}
                  title={content.button}
                  onClick={this.submit}
                  disabled={btnDisabled}
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const Verify = Form.create()(withRouter(VerifyComponent));
