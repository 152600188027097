import { get } from 'lodash';
import { Email } from '../interfaces/email';
import { instance } from './instance';

// let emailParams = {
//   Destination: {
//     BccAddresses: bccEmailAddresses,
//     CcAddresses: ccEmailAddresses,
//     ToAddresses: toEmailAddresses
//   },
//   Message: {
//     Body: {
//       Text: {
//         Data: bodyData,
//         Charset: bodyCharset
//       }
//     },
//     Subject: {
//       Data: subjectdata,
//       Charset: subjectCharset
//     }
//   },
//   Source: sourceEmail,
//   ReplyToAddresses: replyToAddresses
// };

export const sendEmail = async (email: Email, template?: {}) => {
  const resp = await instance.post('/email/v3/send', {
    email,
    template,
  });
  return get(resp, 'data.data', null);
};

export const sendSMS = async (sms: { body: string; to: string }) => {
  const resp = await instance.post('/email/sms/send', {
    sms,
  });
  return get(resp, 'data.data', null);
};
