import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { Model } from '../interfaces/model';
import { instance } from './instance';

export const getModel = async (id: string) => {
  const resp = await instance.get(`/model/${id}`, { data: {} });
  return get(resp, 'data.model', null) as Model;
};

export const getModels = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/model`, {
      where,
    });
  } else {
    resp = await instance.get(`/model`, { data: {} });
  }
  return get(resp, 'data.models', []) as Model[];
};

export const createModel = async (model: Model) => {
  const resp = await instance.post('/model/create', {
    model: removeEmpty(model),
  });
  return get(resp, 'data.model', null) as Model;
};

export const updateModel = (model: Model) => {
  return createModel(model);
};

export const deleteModel = async (model: Model) => {
  const resp = await instance.delete(`/model/${model.id}`);
  return get(resp, 'data.model', null) as Model;
};
