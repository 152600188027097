/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import { Moment } from 'moment';
import moment from 'moment';
import styles from '../../spoof-a-publish.styles';
import { EQ_TYPE } from '../../../../_shared/interfaces/equipment';
import { Model } from '../../../../_shared/interfaces/model';
import { DatePicker } from 'antd';
import _ from 'lodash';
import {
  makeInputGroup,
  makeSwitch,
  makeNumInput,
} from '../../spoof-a-publish.component';
import { BaseDzMaintDataForm } from './base-dz-maint-form.component';
import { VacuumDzMaintDataForm } from './vacuum-maint-form.component';
import { CompressorDzMaintDataForm } from './compressor-maint-form.component';
import {
  MaintenancePublishData,
  MaintSensorData,
  BaseMaintPData,
  CompressorMaintPData,
  VacuumMaintPData,
} from '../../../../_shared/interfaces/publishData';

type Payload = MaintenancePublishData;

interface IProps {
  equipmentType: EQ_TYPE;
  model: Model;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const DzMaintDataForm = (props: IProps): JSX.Element => {
  const { equipmentType, model, validChanged, payloadChanged } = props;

  const [baseDataValid, setBaseDataIsValid] = useState<boolean>(false);
  const [vacuumDataValid, setVacuumDataIsValid] = useState<boolean>(false);
  const [compressorDataValid, setCompressorDataIsValid] = useState<boolean>(
    false
  );
  const [baseData, setBaseData] = useState<BaseMaintPData | undefined>(
    undefined
  );
  const [vacuumData, setVacuumData] = useState<VacuumMaintPData | undefined>(
    undefined
  );
  const [compressorData, setCompressorData] = useState<
    CompressorMaintPData | undefined
  >(undefined);

  //notify parent of (combined) validation change whenever either of our children validation changes
  useEffect(() => {
    let valid = baseDataValid;

    if (valid) {
      if (equipmentType === EQ_TYPE.Vacuum) {
        valid = vacuumDataValid;
      } else if (equipmentType === EQ_TYPE.Compressor) {
        valid = compressorDataValid;
      }
    }

    validChanged(valid);
  }, [baseDataValid, vacuumDataValid, compressorDataValid]);

  //notify parent of (combined) payload change whenever either of our children payload changes
  useEffect(() => {
    if (!!!baseData) {
      payloadChanged(undefined);
      return;
    }

    let data = baseData;
    if (equipmentType === EQ_TYPE.Vacuum) {
      _.merge(data, vacuumData);
    } else if (equipmentType === EQ_TYPE.Compressor) {
      _.merge(data, compressorData);
    }

    payloadChanged(data as MaintenancePublishData);
  }, [baseData, vacuumData, compressorData]);

  return (
    <Fragment>
      <BaseDzMaintDataForm
        equipmentType={equipmentType}
        model={model}
        validChanged={setBaseDataIsValid}
        payloadChanged={setBaseData}
      />

      {equipmentType === EQ_TYPE.Vacuum && (
        <VacuumDzMaintDataForm
          model={model}
          validChanged={setVacuumDataIsValid}
          payloadChanged={setVacuumData}
        />
      )}

      {equipmentType === EQ_TYPE.Compressor && (
        <CompressorDzMaintDataForm
          model={model}
          validChanged={setCompressorDataIsValid}
          payloadChanged={setCompressorData}
        />
      )}
    </Fragment>
  );
};

export const sensorDataIsValid = (data: MaintSensorData): boolean => {
  return data[1] >= 0 && data[2] >= 2 && !!data[3];
};

// [maintenanceRequired, realTimeHoursSinceLastService, realTimeHoursIntendedMaintenanceInterval, lastMaintenanceTime]
export const createMaintSensorDataInputs = (
  name: string,
  data: MaintSensorData,
  dataChangedHandler: (data: MaintSensorData) => void
): JSX.Element => {
  const updateMaintRequired = (_required: boolean) => {
    let payload: MaintSensorData = data;
    payload[0] = !payload[0];
    dataChangedHandler([...payload] as MaintSensorData);
  };

  const updateHoursSinceLastService = (hours: number | undefined) => {
    if (hours === undefined) return;
    let payload: MaintSensorData = data;
    payload[1] = hours;
    dataChangedHandler([...payload] as MaintSensorData);
  };

  const updateIntendedMaintInterval = (hours: number | undefined) => {
    if (hours === undefined) return;
    let payload: MaintSensorData = data;
    payload[2] = hours;
    dataChangedHandler([...payload] as MaintSensorData);
  };

  const updateLastMaintTime = (date: Moment, _dateString: string) => {
    if (!date) return;
    let payload: MaintSensorData = data;
    payload[3] = date.utc().unix();
    dataChangedHandler([...payload] as MaintSensorData);
  };

  return (
    <div css={css(styles.maintenanceSensorDataGroup)}>
      <div css={css(styles.inputGroup)}>
        <p>{name}:</p>
        <div>
          {makeSwitch('Maintenance Required', data[0], updateMaintRequired)}
          {makeNumInput(
            'Hours Since Last',
            data[1],
            updateHoursSinceLastService
          )}
          {makeNumInput(
            'Maint Interval (Hours)',
            data[2],
            updateIntendedMaintInterval
          )}
          {makeInputGroup(
            'Last Maint',
            <DatePicker
              onChange={updateLastMaintTime}
              value={moment(data[3])}
            />
          )}
        </div>
      </div>
    </div>
  );
};
