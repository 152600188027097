import colors from '../../_shared/colors';

export const global = `
  .App .rbc-row {
    min-height: 0px;
  }

  .App .rbc-allday-cell {
    display: none;
  }

  .App .rbc-time-view {
    border: none;
  }

  .App .rbc-time-gutter .rbc-timeslot-group {
    width: 45px;
  }

  .App .rbc-time-gutter .rbc-timeslot-group:last-child {
    border-bottom: none;
  }

  .App .rbc-time-gutter .rbc-time-slot {
    font-size: 10px;
    color: ${colors.tableBorder};
    background-color: ${colors.white};
    margin-top: -8px;
    align-items: center;
    padding-right: 5px;
    justify-content: flex-end;
    width: 38px;
  }

  .App .rbc-time-gutter .rbc-label {
    padding: 0px;
    padding-right: 2px;
    float: right;
  }

  .App .rbc-time-content {
    overflow: visible;
    border-top: 1px solid ${colors.tableBorder};
  }

  .App .rbc-timeslot-group {
    min-height: 22px;
  }

  .App .rbc-header {
    border-bottom: 0;
  }
`;

export default {
  deviceStatusAlertContainer: `
    margin-left: -20px;
    margin-top: -20px;
    width: calc(100% + 40px);
  `,
  deviceStatusAlert: `
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: ${colors.white};
    border-bottom: 1px solid ${colors.disabledLight};
  `,
  container: ``,
  topRow: ``,
  loadingIcon: `
    position: relative;
    left: -45px;
    color: ${colors.highlight};
  `,
  equipmentHeader: `
    height: 40px;
  `,
  daySelectContainer: `
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  daySelect: `
    color: ${colors.black};
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 15px;
    cursor: pointer;
  `,
  activeDaySelect: `
    background-color: ${colors.black};
    color: ${colors.white};
  `,
  copyToText: `
    font-weight: bold;
    font-size: 16px
  `,
  copyToContainer: `
    margin-top: 20px;
  `,
};
