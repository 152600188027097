import { EditTandemGroupComponent } from './edit-tandem-group.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { updateMyEquipment } from '../dashboard.actions';

export const EditTandemGroup = connect(
  (sState: AppState) => ({ sState }),
  {
    updateMyEquipment,
  }
)(EditTandemGroupComponent);
