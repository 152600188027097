/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import { AppState } from '../../app.state';
import { User, _User } from '../../_shared/interfaces/user';
import { Form, Input, Alert, Modal } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import styles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import {
  buildErrorMsgFromForm,
  uppercaseFirst,
  validPhoneNumber,
  userHasRole,
  isServiceOrgAdmin,
  serviceOrgThenDentalOrgs,
  sortByLabel,
  cleanPhoneNumber,
  cleanCopy,
  displayPhoneNumber,
} from '../../utils';
import Link from '../../_shared/link';
import { get, set } from 'lodash';
import { getRolesAvailableForUser } from '../add-user/add-user.component';
import { Select } from '../../_shared/select';
import {
  _ModalVerify,
  ModalVerify,
} from '../../_shared/modal-verify/modal-verify.component';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateUser: (user: User, shouldUpdateCurrent: boolean) => Promise<User>;
  getUserData: () => Promise<{}>;
}

export class _AccountDetailsComponent extends Component<IProps> {
  state = {
    isEdit: false,
    canEditRole: false,
    loading: false,
    error: null,
    success: null,
    user: undefined,
    back: undefined,
    toDisplay: [
      {
        title: 'Role',
        var: 'role',
        type: 'dropdown',
        disabled: () => {
          const { sState } = this.props;
          const { user, isEdit } = this.state;

          const uState = { auth: { user } } as AppState;
          const currentIsSuper = userHasRole([0], sState);
          const currentIsTech = userHasRole([1], sState);
          const userIsSuperTech = userHasRole([0, 1], uState);
          const currentIsOrgAdmin = userHasRole([2], sState);

          const areTheSame =
            get(sState, 'auth.user.userId') === get(uState, 'auth.user.userId');

          if (!isEdit) {
            return true;
          } else if (currentIsSuper) {
            return false;
          } else if (areTheSame) {
            return true;
          } else if (currentIsTech) {
            return userIsSuperTech ? true : false;
          } else if (currentIsOrgAdmin) {
            const currentOrg = get(sState, 'auth.user.orgId');
            const userOrg = get(uState, 'auth.user.orgId');
            if (currentOrg && currentOrg === userOrg) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
        opts: () => getRolesAvailableForUser(this.props.sState),
        options: {
          rules: [
            {
              required: true,
              message: 'Role is required. ',
            },
          ],
        },
      },
      {
        title: 'Equipment Types',
        var: 'eqTypeOptions',
        type: 'dropdown',
        disabled: () => !this.state.isEdit,
        canDisplay: () => {
          const {
            form: { getFieldValue },
          } = this.props;

          return getFieldValue('role') === 5;
        },
        opts: () => [
          { title: 'Utility', value: 'utility' },
          { title: 'Handpiece', value: 'handpiece' },
          { title: 'Equipment', value: 'chair' },
        ],
        props: {
          mode: 'multiple',
        },
      },
      {
        title: 'Organization',
        var: 'orgId',
        type: 'dropdown',
        disabled: () => !this.state.isEdit,
        canDisplay: () => {
          const { form, sState } = this.props;
          const hasRole = userHasRole([0, 1], sState);
          const isSOAdmin = isServiceOrgAdmin(sState);
          const formrole = parseInt(form.getFieldValue('role'));
          const hiddenroles = [7, 5];
          return (hasRole || isSOAdmin) &&
            formrole >= 2 &&
            hiddenroles.indexOf(formrole) === -1
            ? true
            : false;
        },
        transform: (val: string) => {
          const { sState } = this.props;
          if (userHasRole([0, 1], sState) && !!val) {
            return val;
          } else {
            return sState.auth.user && sState.auth.user.orgId
              ? sState.auth.user.orgId
              : get(this, 'state.user.orgId', val);
          }
        },
        placeholder: 'Select',
        opts: () => {
          const { sState } = this.props;
          const isSOAdmin = isServiceOrgAdmin(sState);

          if (isSOAdmin) {
            return serviceOrgThenDentalOrgs(sState);
          } else {
            return sState.dash.orgs
              .map(o => ({ title: o.name, value: o.id, label: o.name }))
              .sort(sortByLabel);
          }
        },
        options: () => {
          const { form } = this.props;
          const isRole =
            parseInt(form.getFieldValue('role')) >= 2 ? true : false;
          if (isRole) {
            return {
              rules: [
                {
                  required: true,
                  message: 'Organization is required. ',
                },
              ],
            };
          }
          return undefined;
        },
      },
      {
        title: 'Branch Location',
        var: 'branchLocation',
        canDisplay: () => {
          const { form } = this.props;
          const org = form.getFieldValue('orgId');
          return !!org && parseInt(form.getFieldValue('role')) == 6;
        },
        options: {
          rules: [
            {
              max: 80,
              message: 'Branch is limited to 80 characters',
            },
          ],
        },
      },
      {
        title: 'Name',
        var: 'name',
        transform: (value: string) => uppercaseFirst(value),
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Email',
        var: 'email',
        alwaysDisabled: true,
        transform: (value: string) => (value || '').toLowerCase(),
        options: {
          rules: [
            {
              required: true,
              message: 'Email is required',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'phone',
        props: {},
        val: (user: User) => displayPhoneNumber(get(user, 'phone')),
        transform: (value: string) => cleanPhoneNumber(value),
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = validPhoneNumber(value);
                cb(!value || passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
          ],
        },
      },
    ],
  };

  onSubmit = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    const { getFieldValue } = get(this.props, 'form');
    const phone = getFieldValue('phone');
    const { user } = this.state;
    e.preventDefault();
    if (this.state.loading) {
      return;
    }
    if (!phone && get(user, 'mfa') === 'sms') {
      return Modal.confirm({
        title: '2FA Currently Set to SMS',
        content:
          //tslint:disable-next-line:max-line-length
          'Two-Factor Authentication for this user is currently set to SMS.  If you remove the phone number associated with this account, 2FA will be updated to use the email address instead.',
        okText: 'Update',
        onCancel: () => 0,
        onOk: this.submit,
      });
    }
    this.submit();
  };

  submit = async () => {
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const originalUser = cleanCopy(this.state.user);

        const ouser = new _User({
          ...(this.state.user || {}),
        } as User);
        const user = new _User({
          ...(this.state.user || {}),
        } as User);

        this.state.toDisplay.map(item => {
          const val = values[item.var];
          user[item.var] = item.transform ? item.transform(val) : val;
        });

        if (userHasRole([0, 1], ({ auth: { user } } as unknown) as AppState)) {
          user.orgId = '';
        }

        const isSuper = userHasRole([0], ({
          auth: { user },
        } as unknown) as AppState);
        if (
          isSuper &&
          user.phoneVerified &&
          user.phone &&
          ouser.phone !== user.phone
        ) {
          const mverify = get(this, 'MODALVERIFY');
          const valid =
            mverify &&
            mverify.openModal &&
            (await mverify.openModal({
              phone: user.phone,
            }));
          set(user, 'phone', get(valid, 'phone'));
          set(user, 'phoneVerified', true);
        }

        if (
          isSuper &&
          user.phoneVerified &&
          ouser.phone &&
          !user.phone &&
          ouser.mfa === 'sms'
        ) {
          set(user, 'phoneVerified', false);
          set(user, 'mfa', 'email');
        }

        if (ouser.orgId !== user.orgId) {
          set(user, 'equipment', []);
          set(user, 'groups', []);
          set(user, 'locations', []);
        }

        const { sState } = this.props;
        const me = get(sState, 'auth.user.userId');
        const shouldUpdateCurrent = me === user.userId;

        await this.props.updateUser(user, shouldUpdateCurrent);

        trackEvent(eventTypes.user_update, { old: originalUser, new: user });
        this.setState(
          {
            loading: false,
            isEdit: false,
            user,
            success: 'Data saved successfully!',
          },
          this.setFieldsOriginal
        );
        this.props.getUserData();
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };
  componentDidMount = () => {
    const {
      sState: {
        auth: { user },
      },
      history: { location },
    } = this.props;

    let _user = user;
    let back = undefined;
    let isEdit = this.state.isEdit;
    if (location && location.state && location.state.user) {
      _user = location.state.user;
      back = location.state.back;
      isEdit = location.state.editMode || isEdit;
    }

    this.setState(
      {
        user: _user,
        back,
        isEdit,
      },
      this.setFieldsOriginal
    );
  };
  setFieldsOriginal = () => {
    const setFields = () => {
      const { toDisplay, user } = this.state;
      const {
        form: { setFieldsValue },
      } = this.props;

      const User = new _User({
        ...(user || {}),
      } as User);

      toDisplay.map(item => {
        setFieldsValue({
          [item.var]: item.val ? item.val(User) : User[item.var],
        });
      });
    };
    setFields();
    //we are doing this becuase in some instasnces org might not be shown
    //until after the first render. so the field can't be set if it isn't yet shown
    setTimeout(setFields, 200);
  };
  backClick = () => {
    this.props.history.goBack();
  };
  MODALVERIFY: _ModalVerify | undefined;
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { toDisplay, isEdit, loading, error, success, back } = this.state;

    const user = (this.state.user as unknown) as User;

    return user ? (
      <div css={css(styles.formContainer)}>
        {!!back && (
          <Link
            css={css`
              margin-bottom: 5px;
            `}
            onClick={this.backClick}
          >
            {back}
          </Link>
        )}

        <FormTitle
          size={'20px'}
          title={
            !!back && isEdit
              ? 'Edit User'
              : `${isEdit ? 'Edit ' : ''}Account Details`
          }
        />

        {error && (
          <Alert
            css={css(styles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(styles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <Form layout="vertical" onSubmit={this.onSubmit}>
          {toDisplay.map((item, i) => {
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            const itemOptions =
              typeof item.options === 'function'
                ? item.options()
                : item.options;
            return !canDisplay ? null : (
              <Form.Item key={i} label={item.title}>
                {getFieldDecorator(item.var, itemOptions)(
                  item.type === 'dropdown' && item.opts ? (
                    <Select
                      placeholder={item.placeholder}
                      disabled={item.disabled && item.disabled()}
                      {...(item.props || {})}
                    >
                      {item.opts().map((opt, i) => (
                        <Select.Option key={i} value={opt.value}>
                          {opt.title}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      {...item.props}
                      disabled={
                        item.var === 'email'
                          ? true
                          : !isEdit
                          ? true
                          : get(item, 'props.disabled')
                      }
                    />
                  )
                )}
              </Form.Item>
            );
          })}

          {!isEdit && (
            <Button
              title={'Edit'}
              onClick={() => this.setState({ isEdit: true })}
            />
          )}
          {isEdit && (
            <Button
              title={'Cancel'}
              disabled={loading}
              css={css`
                margin-right: 10px;
              `}
              outline={true}
              onClick={() => {
                if (back) {
                  this.backClick();
                } else {
                  this.setFieldsOriginal();
                  this.setState({ isEdit: false });
                }
              }}
            />
          )}
          {isEdit && (
            <Button title={'Save'} loading={loading} onClick={this.onSubmit} />
          )}
          <ModalVerify
            wrappedComponentRef={(comp: _ModalVerify) =>
              set(this, 'MODALVERIFY', comp)
            }
          />
        </Form>
      </div>
    ) : (
      <div>No User Provided</div>
    );
  }
}

export const AccountDetailsComponent = Form.create()(
  withRouter(_AccountDetailsComponent)
);
