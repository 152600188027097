import { User, _User } from '../_shared/interfaces/user';
import { Role } from '../_shared/interfaces/role';

export interface AuthenticationState {
  user?: User;
  token?: String;
  allRoles: Role[];
}

export interface AuthenticationActionTypes {
  type: AuthActions;
  payload?: {};
  token?: '';
}

export enum AuthActions {
  LOG_USER_OUT = 'LOG_USER_OUT',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_ALL_ROLES = 'SET_ALL_ROLES',
  SET_TOKEN = 'SET_TOKEN',
}

const initialState = {
  user: undefined,
  allRoles: [],
  token: undefined,
};

export const authenticationReducer = (
  state: AuthenticationState = initialState,
  action: AuthenticationActionTypes
) => {
  switch (action.type) {
    default:
      return state;
    case AuthActions.LOG_USER_OUT:
      return {
        ...state,
        user: undefined,
      };
    case AuthActions.SET_ALL_ROLES:
      return {
        ...state,
        allRoles: (action.payload as Role[]).sort((a, b) =>
          a.id > b.id ? 1 : -1
        ),
      };
    case AuthActions.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case AuthActions.SET_CURRENT_USER:
      return {
        ...state,
        user: new _User({
          ...action.payload,
        } as User),
      };
  }
};
