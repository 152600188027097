export default () => {
  return {
    title: 'Change Your Password',
    subTitle: 'Enter the code you received from the email we sent you.',
    codeLabel: 'Code',
    passwordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    button: 'Reset Password',
    link: 'Return to Sign In',
  };
};
