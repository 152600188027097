import { get } from 'lodash';
import { Publish } from '../interfaces/publish';
import moment from 'moment';
import { instance } from './instance';

export const getHistory = async (
  id: string,
  event: string,
  cutoffDate?: string,
  limit = 1000
) => {
  const resp = await instance.get(
    `/publish/history/${id}?event=${event}&limit=${limit}`
  );
  let history = get(resp, 'data.data', []) as Publish[];

  if (cutoffDate) {
    const cutoff = moment(cutoffDate);
    history = history.filter(
      pub => moment(get(pub, 'published_at')).diff(cutoff) <= 0
    );
  }

  return history;
};
