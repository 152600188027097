/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core';
import { Component } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { get } from 'lodash';
import { FormComponentProps } from 'antd/lib/form';
import { validPhoneNumber, buildErrorMsgFromForm } from '../../utils';
import colors from '../../_shared/colors';
import { AppState } from '../../app.state';
import { Org } from '../../_shared/interfaces/org';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { Emails } from '../../_shared/lib/emails';
import { sendEmail } from '../../_shared/services/email.service';

interface IProps extends FormComponentProps {
  onClose: () => void;
  visible: boolean;
  onSubmit: () => void;
  sState: AppState;
}

class ModalTransferComponent extends Component<IProps> {
  state = {
    loading: false,
    toDisplay: [
      {
        title: 'Contact Name',
        var: 'name',
        canDisplay: () => true,
        options: {
          preserve: true,
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
          ],
        },
      },
      {
        title: 'Contact Email',
        var: 'email',
        options: {
          preserve: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid E-mail. ',
            },
            {
              required: true,
              message: 'Email is required. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'phone',
        options: {
          preserve: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
            {
              required: true,
              message: 'Phone is required. ',
            },
          ],
        },
      },
      {
        title: 'Current Service Organization',
        var: 'serviceOrg',
        disabled: true,
        options: {
          preserve: true,
          disabled: true,
        },
      },
      {
        title: 'New Service Organization',
        var: 'newServiceOrg',
        options: {
          preserve: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'New Service Organization is required. ',
            },
          ],
        },
      },
      {
        title: 'Comment',
        var: 'comment',
        type: 'textarea',
        options: {
          preserve: true,
          validateTrigger: 'onBlur',
        },
      },
    ],
  };
  componentDidMount = async () => {
    const {
      sState,
      form: { setFieldsValue },
    } = this.props;
    const orgId = get(sState, 'auth.user.orgId');

    const orgs = await getOrgs();

    //users org, need to find service org;
    let org = orgs.find(o => o.id === orgId) as Org;

    if (org && org.serviceOrgId) {
      org = orgs.find(o => o.id === org.serviceOrgId) as Org;
    }

    if (org) {
      setFieldsValue({
        serviceOrg: org.name,
      });
    }
  };
  onSubmit = () => {
    this.setState({ loading: true });

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        message.error(error);
        return this.setState({ loading: false });
      }

      try {
        const Email = Emails.transfer({
          ToAddresses: ['aerasutility@dentalez.com'],
          props: {
            ...values,
          },
        });

        await sendEmail(Email);

        message.success(
          'Transfer request submitted. A representative from DentalEZ will contact you shortly.'
        );
        this.setState({ loading: false });
        this.props.onClose();
      } catch (err) {
        message.error(`Error occured: ${err.message}`);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const {
      onClose,
      visible,
      form: { getFieldDecorator },
    } = this.props;

    const { toDisplay, loading } = this.state;

    return (
      <Modal
        confirmLoading={loading}
        closable={true}
        onCancel={onClose}
        onOk={this.onSubmit}
        visible={visible}
        title={'Request Service Organization Transfer'}
        okText={'Submit'}
        css={css(`
          .ant-modal-title {
            color: ${colors.highlight};
          }
        `)}
      >
        <div>
          <div>
            <p>
              Please enter your contact information and Service Organization you
              would prefer to be transferred to. DentalEZ will send a
              confirmation email when the transfer is complete.
            </p>
          </div>
          {toDisplay.map((item, _i) => {
            const itemOptions = item.options;
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            return !canDisplay ? null : (
              <Form.Item key={_i} label={item.title}>
                {getFieldDecorator(item.var, itemOptions)(
                  item.type === 'textarea' ? (
                    <Input.TextArea rows={3} />
                  ) : (
                    <Input disabled={item.disabled} />
                  )
                )}
              </Form.Item>
            );
          })}
        </div>
      </Modal>
    );
  }
}

export const ModalTransfer = Form.create()(ModalTransferComponent);
