import { User, _User } from '../interfaces/user';
import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getUser = async (id: string) => {
  const resp = await instance.get(`/user/${id}`, { data: {} }).catch(error => {
    if (error.request.response.includes('Could not retrieve user')) {
      throw new Error('UserNotFoundException');
    } else {
      //TODO: Decide on wording for error here.
      throw new Error('Network Error');
    }
  });
  return new _User(get(resp, 'data.user', null)) as User;
};

export const getUsers = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/user`, {
      where,
    });
  } else {
    resp = await instance.get(`/user`, { data: {} });
  }
  return get(resp, 'data.users', []).map(
    (u: User | undefined) => new _User(u)
  ) as User[];
};

export const getAllUsers = async (where?: {}): Promise<User[]> => {
  return getUsers(where);
};

export const createUser = async (user: User) => {
  const resp = await instance.post('/user/create', {
    user: removeEmpty(user),
  });
  return get(resp, 'data.user', null) as User;
};

export const updateUser = (user: User) => {
  return createUser(user);
};

export const deleteUser = async (sk: string) => {
  const resp = await instance.post(`user/delete`, { sk });
  return resp;
};

export const getToken = async (data = {}) => {
  const resp = await instance.post('/user/token', { ...data });
  return get(resp, 'data.token');
};

export const generatePinAndSendMsgToUser = async (
  email: string,
  useSMSIfAvail = true
) => {
  const resp = await instance.post('/user/generatepin', {
    email,
    useSMSIfAvail,
  });
  return get(resp, 'data', {});
};

export const validateCode = async (email: string, code: string) => {
  const resp = await instance.post('/user/validatecode', { email, code });
  return !!get(resp, 'data.valid', false);
};
