/** @jsx jsx */
import './handpieceProfileUsageTable.css';
import { IHPProfileUsageData } from '../../_shared/interfaces/handpieceProfiles';
import styles from './equipment-details.styles';
import { css, jsx } from '@emotion/core';

interface IProps {
  data: IHPProfileUsageData;
}

export const HandpieceProfileUsageTable = (props: IProps): JSX.Element => {
  if (!props.data) {
    return (
      <p css={css(styles.blankMessage)}>
        No profile data has been received for this equipment
      </p>
    );
  }

  let data: { [key: string]: number } = {};

  const keys = Object.keys(props.data);
  const values = Object.values(props.data);
  for (let i = 0; i < keys.length; i++) {
    data[keys[i]] = values[i];
  }

  const createCell = (i: number): JSX.Element => {
    // tslint:disable-next-line:no-any
    const key = `pro_${i}`;
    if (key in data) {
      //d is in seconds, need to convert to hours
      const d = data[key];
      return (
        <div className="cell" key={`cell_${i}`}>
          <p>{Math.round(d / 3600)} hours</p>
          <p>Profile {i}</p>
        </div>
      );
    }

    return (
      <div className="cell" key={`cell_${i}`}>
        <p>--</p>
        <p>Profile {i}</p>
      </div>
    );
  };

  if (data) {
    return (
      <table className="usageData">
        <tbody>
          <tr>
            {[1, 2, 3, 4, 5].map(i => (
              <td key={`tr1_td${i}`}>{createCell(i)}</td>
            ))}
          </tr>
          <tr>
            {[6, 7, 8, 9, 10].map(i => (
              <td key={`tr1_td${i}`}>{createCell(i)}</td>
            ))}
          </tr>
          <tr></tr>
        </tbody>
      </table>
    );
  }

  return (
    <p css={css(styles.blankMessage)}>
      No profile data has been received for this equipment
    </p>
  );
};
