/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { ConfigProvider, Icon } from 'antd';
import { useSelector, useStore } from 'react-redux';
import { AppState } from '../../app.state';
import colors from '../colors';
import { get } from 'lodash';
import { loadIntegration } from '../../utils/setup';
import styles from '../styles';

//@ts-ignore
// tslint:disable-next-line
let STORE;

export const useAppConfig = () => {
  const app = useSelector((state: AppState) => state.app);
  // const antd = App.useApp();

  const obj = {
    // ...antd,
    ...(app || {}),
    style: {
      ...get(app, 'style', {}),
      colors: {
        ...colors,
        ...get(app, 'style.colors', {}),
      },
    },
  };

  const getColor = (str: string) => {
    return get(obj, `style.colors.${str}`);
  };

  return {
    ...obj,
    getColor,
  };
};

export const AppConfigProvider = (props: any) => {
  // const app = useAppConfig();
  const store = useStore();
  
  const [state, setState] = useState({
    loading: true,
  })

  useEffect(() => {
    STORE = store;

    loadIntegration(store, 'default').finally(() => {
      setState(s => ({ ...s, loading: false }))
    })
  }, []);

  return (
    <ConfigProvider
      // theme={{
      //   token: {
      //     colorPrimary: app.getColor('primary'),
      //     borderRadius: 0,
      //     lineWidth: 2,
      //     colorBorder: app.getColor('inputBorder'),
      //   },
      // }}
    >
      {/* <App> */}
        {state.loading && <div css={css(styles.fullHeightCenterColumn)}><Icon type="loading" style={{ fontSize: 40 }} /></div>}
        {!state.loading && props.children}
      {/* </App> */}
    </ConfigProvider>
  );
};

export const WithAppConfig = (Comp: any) => {
  return (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const app = useAppConfig();    

    return <Comp app={app} {...props} />;
  };
};

export const getAppConfig = (key: string, fb?: any) => {
  //@ts-ignore
// tslint:disable-next-line
  const sState = STORE && STORE.getState();
  return get(sState, `app.${key}`, fb);
}