/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  Calendar,
  momentLocalizer,
  DateLocalizer,
  stringOrDate,
} from 'react-big-calendar';
import moment from 'moment';
import SharedStyles from '../../_shared/styles';
import styles, { global } from './scheduling.styles';
import { Modal, Icon, message, Switch, Alert } from 'antd';
import { Button } from '../../_shared/button';
import { SelectValue } from 'antd/lib/select';
import {
  createHours,
  cleanCopy,
  inRange,
  scheduleToEvents,
  findDayFromNumber,
  numberToMilitary,
  militaryToNumber,
  dayMap,
  generateDefaultPublish,
  makeSureArrayIsOfLength,
  buildScheduleCommands,
  getEquipConfig,
  getEquipSchedule,
  SchedEvent,
  singleStringAddress,
  isPB,
  getEquipmentModel,
  getDeviceStatusFromEquip,
  userHasRole,
  isHandpiece,
} from '../../utils';
import Label from '../../_shared/label';
import { _Schedule, Schedule, Sched } from '../../_shared/interfaces/schedule';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import { User } from '../../_shared/interfaces/user';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { get, flatten, isEqual } from 'lodash';
import { AutoCompleteLimited } from '../../_shared/autocomplete';
import { renderLabel } from '../../utils/mixins';
import { getLocation } from '../../_shared/services/manage-locations.service';
import colors from '../../_shared/colors';
import { Select } from '../../_shared/select';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { getEquipmentRecord, getEquipments } from '../../_shared/services/manage-equipment.service';

const localizer = momentLocalizer(moment);

interface IProps extends RouteComponentProps {
  sState: AppState;
  waitForNewConfig: (eq: Equipment) => Promise<unknown>;
  waitForNewSchedule: (eq: Equipment) => Promise<unknown>;
}

interface IOpt {
  label: string;
  value: number | string;
}

interface SlotInfo {
  start: stringOrDate;
  end: stringOrDate;
  slots: string[] | Date[];
  action: 'select' | 'click' | 'doubleClick';
}

interface ITime {
  [key: string]: string | IOpt[] | number | undefined;
  label: string;
  options: IOpt[];
  selected?: number;
}

const Time: { [key: string]: ITime } = {
  day: {
    label: 'Day',
    options: [
      { label: 'Sunday', value: 0 },
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value: 3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 },
    ],
    selected: undefined,
  },
  start: {
    label: 'Start Time',
    options: createHours().map(h => ({
      label: moment(h, 'HH:mm').format('hh:mm a'),
      value: h,
    })),
    selected: undefined,
  },
  end: {
    label: 'Standby Time',
    options: createHours().map(h => ({
      label: moment(h, 'HH:mm').format('hh:mm a'),
      value: h,
    })),
    selected: undefined,
  },
};

export class _SchedulingComponent extends Component<IProps> {
  state = {
    loading: false,
    error: undefined,
    success: undefined,
    selectedEquip: undefined,
    sendingCommand: false,
    equipment: [],
    equipmentSelect: [],
    fullEquipment: undefined,
    location: undefined,
    refreshInterval: 1000000,
    selectedDay: moment().day(),
    selectedDate: moment(),
    selectedCopyTo: undefined,
    copyToModalVisible: false,
    modal: {
      visible: false,
      title: '',
      schedule: undefined,
      time: cleanCopy(Time),
      sched: undefined,
    },
    copyToSchedule: undefined,
  };

  componentDidMount = () => {
    this.retrieveEquipment();
  };
  trackEvent = (event = '', data = {}) => {
    trackEvent(event, { ...data, equipment: this.getSelectedEquip() });
  };
  addTime = (slotInfo?: { start: stringOrDate }) => {
    const time = cleanCopy(Time);

    if (slotInfo && slotInfo.start) {
      const start = moment(slotInfo.start).toISOString();
      const day = moment(start).weekday();
      const hour = moment(start).format('HH:mm');
      const endOfDay = moment(start)
        .endOf('day')
        .toISOString();
      const diff = Math.round(
        moment(endOfDay).diff(moment(start), 'hours', true)
      );
      time.day.selected = day;
      time.start.selected = hour;
      time.end.selected =
        diff <= 9
          ? '23:45'
          : moment(start)
              .add(9, 'hours')
              .format('HH:mm');
    }

    this.setState({
      modal: {
        ...this.state.modal,
        visible: true,
        title: `Add Scheduled Time for ${this.getDeviceName()}`,
        time,
      },
    });
  };
  renderBadAccessAlert = () => {
    Modal.warn({
      title: 'Functionality Not Available',
      content:
        //tslint:disable-next-line: max-line-length
        'Your user account does not have access to complete this functionality.\
        If you feel that this is incorrect, please contact your company Admin.',
      onOk: () => {
        return 0;
      },
    });
  };
  toggleScheduleEnabled = async () => {
    const { sState } = this.props;
    if (userHasRole([6, 7], sState)) {
      this.renderBadAccessAlert();
      return;
    }

    const config = getEquipConfig(this.getSelectedEquip(), sState);

    if (this.state.sendingCommand) {
      return message.error('Sending command already. Please wait.');
    }

    const schedule_enabled = get(config, 'data.schedule_enabled.0');
    const equip = this.getSelectedEquip() as Equipment;
    const sendCommand = async () => {
      try {
        this.setState({ sendingCommand: true });

        if (equip && equip.deviceId) {
          sendDeviceCommand(
            equip.deviceId,
            {
              arg: `sched_en,${schedule_enabled ? 0 : 1}`,
            },
            { showErrorToast: true },
            isHandpiece(equip)
          );

          await this.props.waitForNewConfig(equip);
          await this.retrieveSchedule();
          message.success(
            schedule_enabled
              ? 'Equipment schedule has been disabled'
              : 'Equipment schedule has been enabled'
          );
        } else {
          return message.error('No deviceId supplied');
        }

        this.setState({ sendingCommand: false });
      } catch (err) {
        console.warn(err.message);
        message.error('Equipment schedule can not be adjusted at this time');
        this.setState({ sendingCommand: false });
      }
    };

    if (schedule_enabled) {
      Modal.confirm({
        title: 'Confirmation:  Disable Schedule',
        content: equip.tandemGroupId
          ? // tslint:disable-next-line: max-line-length
            ' Are you sure you want to disable the schedule for this piece of equipment? This equipment is part of a Tandem Group, this action will disable schedule for all equipment in the Tandem Group.'
          : 'Are you sure you want to disable the schedule for this piece of equipment?',
        okText: 'Disable',
        onOk: sendCommand,
        onCancel: () => 0,
      });
    } else {
      sendCommand();
    }
  };
  closeModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        schedule: undefined,
        sched: undefined,
        visible: false,
      },
    });
  };
  equipmentSelect = (opt: SelectValue) => {
    this.setState({ selectedEquip: opt as string, location: undefined }, () =>
      this.retrieveSchedule(true)
    );
  };
  getUser = () => {
    const {
      sState: {
        auth: { user },
      },
    } = this.props;
    return user as User;
  };
  sendCommands = (commands: string[]) => {
    const { equipment, selectedEquip } = this.state;
    const eq = (equipment.find(
      (e: Equipment) => e.id === selectedEquip
    ) as unknown) as Equipment;

    return new Promise(async (resolve, reject) => {
      let rejected = false;
      if (eq.deviceId) {
        try {
          await Promise.all([
            this.props.waitForNewSchedule(eq).then(resolve),
            await Promise.all(
              commands.map(cmd =>
                sendDeviceCommand(
                  eq.deviceId as string,
                  {
                    arg: cmd,
                  },
                  { showErrorToast: true },
                  isHandpiece(eq)
                )
              )
            ),
          ]);
        } catch (err) {
          if (!rejected) {
            rejected = true;
            console.error(err);
            reject(err);
          }
        }
      } else {
        reject(new Error('No device ID'));
      }
    });
  };
  processValues = async () => {
    const {
      sState,
      sState: { auth },
    } = this.props;

    if (userHasRole([6, 7], sState)) {
      this.renderBadAccessAlert();
      return;
    }
    this.setState({ loading: true });

    const {
      modal,
      modal: { time },
      selectedEquip,
      equipment,
    } = this.state;

    const isEdit = (modal.sched as unknown) as { day: string; index: number };

    const user = this.getUser();
    const e = this.getSelectedEquip();

    const theSchedule = new _Schedule(getEquipSchedule(e, sState)) as Schedule;

    const day = time.day.selected || 0;
    const startTime = time.start.selected || '00:00';
    const standbyTime = time.end.selected || '00:00';

    const theSched = theSchedule.sched;
    const origSched = cleanCopy(theSched);
    const dayString = findDayFromNumber(day);
    const schedDay = (theSched[dayString] || []).filter(
      s => Array.isArray(s) && s[0] !== undefined
    );

    const hours = createHours();

    const startIndex = hours.indexOf(startTime);
    const endIndex = hours.indexOf(standbyTime);
    let noScheduleChange = false;
    let clashes = false;
    for (let i = 0; i < schedDay.length; i++) {
      let s = schedDay[i] as number[];
      const sTime = numberToMilitary(s[0]);
      const eTime = numberToMilitary(s[1]);

      const sIndex = hours.indexOf(sTime);
      const eIndex = hours.indexOf(eTime);
      const isSchedDayEdit = isEdit && i === isEdit.index;
      if (
        !isSchedDayEdit &&
        (inRange(startIndex, sIndex, eIndex) ||
          inRange(endIndex, sIndex, eIndex))
      ) {
        clashes = true;
        break;
      } else if (!isEdit && startIndex <= sIndex && endIndex >= eIndex) {
        clashes = true;
        break;
      } else if (isEdit && startIndex === sIndex && endIndex === eIndex) {
        noScheduleChange = true;
        break;
      }
    }

    if (noScheduleChange) {
      console.warn('no change has been made');
      this.setState({ loading: false });
      return Modal.warn({
        title: 'No Schedule Change',
        content:
          'No schedule change was made. If you do not want to change\
        the schedule select cancel in the schedule change window.',
      });
    }

    if (startIndex >= endIndex) {
      this.setState({ loading: false });
      return Modal.error({
        title: 'Error',
        content:
          'Selected standby time is earlier than selected start time.  \
        Adjust start time or standby time to correct this issue.',
      });
    } else if (!isEdit && schedDay.length >= 2) {
      this.setState({ loading: false });
      return Modal.error({
        title: 'Error',
        content: 'Can not add more than two scheduled times per day.',
      });
    } else if (clashes) {
      this.setState({ loading: false });
      return Modal.error({
        title: 'Error',
        content:
          'This equipment is already scheduled during some of this time period.  \
        Adjust start time or standby time to remove overlap.',
      });
    }

    const eq = (equipment.find(
      (e: Equipment) => e.id === selectedEquip
    ) as unknown) as Equipment;

    const now = new Date().toISOString();

    theSchedule.orgId = (!!theSchedule && theSchedule.orgId) || user.orgId;
    theSchedule.equipmentId = selectedEquip;
    theSchedule.deviceId = eq && eq.deviceId ? eq.deviceId : '';
    theSchedule.updatedBy = get(auth, 'user.userId');
    theSchedule.createdBy = theSchedule.createdBy || get(auth, 'user.userId');
    theSchedule.createdAt = theSchedule.createdAt || now;
    theSchedule.updatedAt = now;
    let newSched = { ...theSched };
    if (isEdit) {
      newSched[isEdit.day][isEdit.index] = [
        militaryToNumber(startTime),
        militaryToNumber(standbyTime),
      ];
    }

    let sched;
    if (isEdit) {
      sched = { ...newSched };
    } else {
      sched = {
        ...theSched,
        [dayString]: makeSureArrayIsOfLength(
          [
            ...schedDay,
            [militaryToNumber(startTime), militaryToNumber(standbyTime)],
          ],
          2
        ),
      };
    }

    const Sched = new _Schedule({
      ...theSchedule,
      sched,
    }) as Schedule;

    try {
      const commands = buildScheduleCommands(Sched.sched);
      await this.sendCommands(commands);
      this.trackEvent(
        isEdit ? eventTypes.sched_update : eventTypes.sched_add,
        isEdit ? { old: origSched, new: sched } : undefined
      );
    } catch (err) {
      this.setState({ loading: false });
      return Modal.error({
        title: 'Error',
        content: `Failed to save Schedule. Please try again.`,
      });
    }

    this.closeModal();
    await this.retrieveSchedule();
  };
  retrieveEquipment = async (showLoading = true) => {
    const { sState } = this.props;
    this.setState({ loading: showLoading });

    try {
      const equipment = (await getEquipments()).filter(
        (equip: Equipment) => {
          const model = getEquipmentModel(equip, sState);
          return get(model, 'type') !== 'handpiece';
        }
      ) as Equipment[];

      this.setState(
        {
          loading: false,
          equipment,
          equipmentSelect: equipment.map(e => ({
            value: e.id,
            text: `${e.equipmentSN || e.name} (${e.name || 'No name'})`,
          })),
        },
        () => {
          const lastEquipment = get(
            this.props.sState,
            'dash.view.lastEquipment'
          );

          if (lastEquipment) {
            const equip = equipment.find(equip => equip.id === lastEquipment);
            equip && this.equipmentSelect(lastEquipment);
          } else if (equipment.length === 1) {
            const equip: Equipment = get(equipment, '0');
            const model = getEquipmentModel(equip, sState);
            const eid: string | undefined = get(equipment, '0.id');
            eid &&
              get(model, 'type') !== 'handpiece' &&
              this.equipmentSelect(eid);
          }
        }
      );
    } catch (err) {
      this.setState({
        loading: false,
        error: 'Failed to retrieve Equipment' + err.message,
      });
    }
  };
  changeEquipmentVal = (val: SelectValue) => {
    this.setState({ selectedEquip: val.toString() });
  };
  getSelectedEquip = () => {
    const { equipment, selectedEquip, fullEquipment } = this.state;
    
    let e = (equipment.find(
      (eq: Equipment) => eq.id === selectedEquip
    ) as unknown) as Equipment;

    if (get(e, 'deviceId') == get(fullEquipment, 'deviceId')) {
      e = { ...(fullEquipment || {}), ...e };
    }

    return e;
  };

  retrieveSchedule = async (showLoading = true) => {
    const { sState } = this.props;
    this.setState({
      isLoading: true,
      loading: showLoading,
    });

    try {
      const e = this.getSelectedEquip();

      const location = e.locationId ? await getLocation(e.locationId) : null;

      let config = generateDefaultPublish(
        'dz_config',
        e.deviceId || '',
        sState
      );

      let fullEquipment;
      if (e) {
        fullEquipment = await getEquipmentRecord(e.deviceId, true);
        const hasConfig = getEquipConfig(fullEquipment, sState);
        config = hasConfig || config;
      }

      this.setState({
        isLoading: false,
        loading: false,
        location,
        fullEquipment,
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        loading: false,
        isLoading: false,
        error: 'Failed to retrieve Schedules: ' + err.message,
      });
    }
  };
  deleteSchedule = async (modal: {
    sched?: { day: string; index: number };
  }) => {
    const { sState } = this.props;
    if (userHasRole([6, 7], sState)) {
      this.renderBadAccessAlert();
      return;
    }
    const e = this.getSelectedEquip();

    const finalize = async () => {
      const { sState } = this.props;
      this.setState({
        loading: true,
      });

      const schedule = new _Schedule(getEquipSchedule(e, sState)) as Schedule;

      const sched = modal.sched;

      try {
        if (sched) {
          let day = schedule.sched[sched.day];
          day.splice(sched.index, 1);
          schedule.sched[sched.day] = makeSureArrayIsOfLength(day, 2, -1);

          const commands = buildScheduleCommands(schedule.sched);
          await this.sendCommands(commands);
        }

        this.trackEvent(eventTypes.sched_delete);
        this.setState({
          loading: false,
        });

        this.closeModal();
      } catch (err) {
        message.error('Failed to delete schedule.');
        this.setState({ loading: false });
      }
    };

    const showDeleteConf = () => {
      Modal.confirm({
        title: 'Delete Confirmation',
        content: 'Are you sure you want to delete this scheduled time?',
        onOk: () => {
          finalize();
        },
      });
    };
    showDeleteConf();
  };
  selectEvent = (_event?: SchedEvent | SlotInfo) => {
    const { modal } = this.state;
    const event = _event as SchedEvent;
    const { schedule } = event;

    const time = cleanCopy(Time);

    const schedDay = get(event, 'sched.day', 'sun');
    const _sched = (get(
      schedule,
      `sched.${schedDay}.${event.sched.index}`
    ) as unknown) as number[];

    time.day.selected = dayMap[schedDay];
    time.start.selected = numberToMilitary(_sched[0]);
    time.end.selected = numberToMilitary(_sched[1]);

    this.setState({
      modal: {
        ...modal,
        visible: true,
        schedule: schedule,
        title: `Edit Scheduled Time for ${this.getDeviceName()}`,
        time,
        sched: event.sched,
      },
    });
  };

  getDeviceName = () => {
    const e = this.getSelectedEquip();
    const deviceName = get(e, 'name', get(e, 'equipmentSN', get(e, 'id')));
    return deviceName;
  };
  renderSubTitle = () => {
    const { selectedEquip, location } = this.state;

    const ss = `margin: 0;`;

    const name = this.getDeviceName();
    const locName = get(location, 'name', 'No Location');

    const labels = [
      renderLabel('name', {
        prefix: 'Equipment: ',
        obj: { name },
        styles:
          ss + `font-weight: bold; cursor: pointer; color: ${colors.highlight}`,
        onClick: this.clickEquipTitle,
      }),
      renderLabel('name', {
        prefix: 'Location Name: ',
        obj: { name: locName },
        styles: ss,
      }),
      renderLabel('address', {
        prefix: 'Location Address: ',
        obj: location,
        get: (val: string) => singleStringAddress(val),
        styles: ss,
      }),
    ].filter(l => l);

    return (
      <div css={css(SharedStyles.row, styles.equipmentHeader)}>
        {selectedEquip &&
          labels.map((l, i) => {
            return (
              <div css={css(SharedStyles.row)} key={i}>
                {l}
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {i === labels.length - 1 ? '' : '•'}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            );
          })}
      </div>
    );
  };
  clickEquipTitle = () => {
    const equipment = this.getSelectedEquip();
    this.props.history.push('/dashboard/equipmentDetails', {
      equipment,
    });
  };
  renderDaySelect = () => {
    const { selectedDay } = this.state;

    const days = [
      { title: 'S' },
      { title: 'M' },
      { title: 'T' },
      { title: 'W' },
      { title: 'T' },
      { title: 'F' },
      { title: 'S' },
    ];

    return (
      <div css={css(SharedStyles.row, styles.daySelectContainer)}>
        {days.map((day, i) => {
          const isActive = i === selectedDay;
          return (
            <div
              css={css(styles.daySelect, isActive && styles.activeDaySelect)}
              onClick={() => {
                const now = moment().day();
                this.setState({
                  selectedDay: i,
                  selectedDate: moment().day(i),
                });
              }}
            >
              {day.title}
            </div>
          );
        })}
      </div>
    );
  };
  renderDeviceStatusAlerts = () => {
    const { sState } = this.props;
    const e = this.getSelectedEquip();
    const { scheduleEnabled } = getDeviceStatusFromEquip(e);

    const ispb = isPB(sState);

    const display = [
      {
        state: !scheduleEnabled,
        title: 'Equipment Schedule Disabled',
        color: colors.statusOff,
      },
    ].filter(f => f.state);

    return display.length === 0 ? null : (
      <div
        css={css(
          ispb
            ? 'width: 100%; margin-bottom: 10px;'
            : styles.deviceStatusAlertContainer
        )}
      >
        {display.map(d => (
          <div
            key={d.title}
            css={css(styles.deviceStatusAlert, `background-color: ${d.color}`)}
          >
            {d.title}
          </div>
        ))}
      </div>
    );
  };

  renderSelectEquipPlaceholder = () => {
    return (
      <div css={css('margin-top: 10px;')}>
        <Alert
          message={''}
          showIcon
          description={
            'Select a piece of Equipment using the dropdown above to see its schedule.'
          }
          type="info"
        />
      </div>
    );
  };

  toggleCopyToModal = () => {
    this.setState({ copyToModalVisible: !this.state.copyToModalVisible });
  };
  shouldShowCopyButton = (scheduleEvents: SchedEvent[]) => {
    const selectedEquipment = this.getSelectedEquip();
    const allEquipment: Equipment[] = this.state.equipment;
    const orgId = get(selectedEquipment, 'dentalOrgId', '');
    const equipmentForOrg = allEquipment.filter(
      (equip: Equipment) => equip.dentalOrgId === orgId
    );
    return equipmentForOrg.length > 1 && !scheduleEvents.length;
  };
  hasSchedule = (equip: Equipment) => {
    const data = getEquipSchedule(equip, this.props.sState);
    if (!data || !data.data) {
      return false;
    }
    const schedule = data.data;
    const keys = Object.keys(schedule);
    const daysWSchedule = keys.filter((key: string) => {
      const dailySchedule: [] = get(schedule, `[${key}]`);
      const hasValues: boolean = !!flatten(dailySchedule).length;
      return hasValues;
    });
    return !!daysWSchedule.length;
  };

  getCopyToOptions = () => {
    let copyToOptions: DataSourceItemObject[] = [];
    const equipForOrg: Equipment[] = this.state.equipment.filter(
      (equip: Equipment) => {
        const hasSchedule = this.hasSchedule(equip);
        return (
          get(equip, 'dentalOrgId') ===
            get(this.getSelectedEquip(), 'dentalOrgId') &&
          equip.id !== get(this.state, 'selectedEquip') &&
          hasSchedule
        );
      }
    );
    equipForOrg.map((equip: Equipment) => {
      const sameLoc =
        get(equip, 'locationId') === get(this.getSelectedEquip(), 'locationId');
      const option = {
        value: equip.id as string,
        text: `${equip.equipmentSN} (${equip.name})`,
      };
      if (sameLoc) {
        copyToOptions.unshift(option);
      } else {
        copyToOptions.push(option);
      }
    });
    return copyToOptions;
  };

  changeCopyToVal = (val: SelectValue) => {
    this.setState({ selectedCopyTo: val.toString() });
  };

  getScheduleToCopy = (equipId: SelectValue) => {
    const { sState } = this.props;
    const { equipment } = this.state;
    const copyFromEquip = (equipment.find(
      (eq: Equipment) => eq.id === equipId
    ) as unknown) as Equipment;
    const schedule: Schedule = getEquipSchedule(copyFromEquip, sState).data;
    this.setState({ copyToSchedule: schedule });
  };

  processCopyToValues = async () => {
    this.setState({ loading: true });
    const { copyToSchedule, equipment, selectedEquip } = this.state;
    const {
      sState,
      sState: { auth },
    } = this.props;
    const e = this.getSelectedEquip();
    const user = this.getUser();
    const theSchedule = new _Schedule(getEquipSchedule(e, sState)) as Schedule;
    const eq = (equipment.find(
      (e: Equipment) => e.id === selectedEquip
    ) as unknown) as Equipment;

    const now = new Date().toISOString();

    theSchedule.orgId = (!!theSchedule && theSchedule.orgId) || user.orgId;
    theSchedule.equipmentId = selectedEquip;
    theSchedule.deviceId = eq && eq.deviceId ? eq.deviceId : '';
    theSchedule.updatedBy = get(auth, 'user.userId');
    theSchedule.createdBy = theSchedule.createdBy || get(auth, 'user.userId');
    theSchedule.createdAt = theSchedule.createdAt || now;
    theSchedule.updatedAt = now;

    const Sched = new _Schedule({
      ...theSchedule,
      sched: (copyToSchedule as unknown) as Sched,
    }) as Schedule;

    try {
      const commands = buildScheduleCommands(Sched.sched);
      await this.sendCommands(commands);
    } catch (err) {
      return Modal.error({
        title: 'Error',
        content: `Failed to save Schedule. Please try again.`,
      });
    }
    await this.retrieveSchedule();
    this.setState({ loading: false });
    this.toggleCopyToModal();
  };

  showTgConfirmModal = (
    onOk: (_event?: SchedEvent | SlotInfo) => void,
    _event?: SchedEvent | SlotInfo
  ) => {
    const onCancel = () => 0;
    Modal.confirm({
      title: 'Schedule Change for Tandem Group',
      content:
        // tslint:disable-next-line: max-line-length
        'This piece of equipment is associated with a tandem group, and this schedule update will be pushed to all equipment within this group.',
      okText: 'Update Schedule',
      cancelText: 'Cancel',
      onOk: () => (_event ? onOk(_event) : onOk()),
      onCancel,
    });
  };

  onClickAddTime = (slotInfo?: SlotInfo | SchedEvent) => {
    if (slotInfo) {
      return this.addTime(slotInfo);
    }
    this.addTime();
  };

  isDeviceOn = () => {
    const equip = this.getSelectedEquip();
    const deviceState =
      equip && equip.deviceStatus ? equip.deviceStatus : 'off';
    const isOn = deviceState === 'on' || deviceState === 'standby';
    return isOn;
  };

  onSelectEvent = (_event: SchedEvent | SlotInfo) => {
    const e = this.getSelectedEquip();
    if (e.tandemGroupId) {
      return this.showTgConfirmModal(this.selectEvent, _event);
    }
    this.selectEvent(_event);
  };

  render() {
    const { sState } = this.props;
    const {
      modal,
      loading,
      equipmentSelect,
      selectedEquip,
      sendingCommand,
      selectedDate,
      selectedCopyTo,
      copyToModalVisible,
    } = this.state;
    const ispb = isPB(sState);
    const e = this.getSelectedEquip();
    const config = getEquipConfig(e, sState);
    const schedule = getEquipSchedule(e, sState);
    const scheduleEvents = scheduleToEvents(schedule);
    const showCopyButton = this.shouldShowCopyButton(scheduleEvents);
    const saveEnabled =
      modal.time.day.selected !== undefined &&
      modal.time.start.selected !== undefined &&
      modal.time.end.selected !== undefined;
    const date = new Date();
    date.setHours(date.getHours() + 1);
    const disabled = !this.isDeviceOn();
    // move the initially selectedEquipment to position 0
    // tslint:disable-next-line:no-any
    const selectedOption = equipmentSelect.find(
      (item: DataSourceItemObject) => item.value === selectedEquip
    );
    // tslint:disable-next-line:no-any
    const filteredOptions = equipmentSelect.filter(
      (item: DataSourceItemObject) => item.value !== selectedEquip
    );
    const _data = [selectedOption, ...filteredOptions] as never[];
    const data = selectedOption ? _data : equipmentSelect;

    return (
      <div>
        {this.renderDeviceStatusAlerts()}
        <Global styles={global} />

        <div
          css={css(SharedStyles.row, `height: 40px; align-items: flex-start;`)}
        >
          {!!selectedEquip && (
            <div
              css={css(
                SharedStyles.row,
                `margin-left: auto; align-items: flex-start;`
              )}
            >
              <span
                css={css`
                  font-size: 16px;
                  margin-right: 5px;
                `}
              >
                {`Schedule ${
                  get(config, 'data.schedule_enabled.0') ? 'On' : 'Off'
                }`}
                {sendingCommand && (
                  <Icon css={css('margin-left: 4px;')} type="loading" />
                )}
              </span>
              <Switch
                onChange={this.toggleScheduleEnabled}
                checked={get(config, 'data.schedule_enabled.0', false)}
              />
            </div>
          )}
        </div>

        <div
          css={css(
            ispb ? SharedStyles.column : SharedStyles.row,
            styles.topRow
          )}
        >
          <AutoCompleteLimited
            css={css(
              `
              width: ${ispb ? 100 : 50}%;
            `,
              ispb && 'margin-bottom: 20px;'
            )}
            dataSource={data}
            onSelect={this.equipmentSelect}
            value={selectedEquip}
            onChange={this.changeEquipmentVal}
            placeholder={'Select Equipment'}
            loading={loading}
            limit={25}
          />

          {loading && <Icon type="loading" css={css(styles.loadingIcon)} />}

          {
            <div css={css(SharedStyles.row, !ispb && `margin-left: auto;`)}>
              {!!selectedEquip && showCopyButton && (
                <Button
                  onClick={this.toggleCopyToModal}
                  title={'Copy Schedule'}
                  disabled={disabled}
                />
              )}
              {!!selectedEquip && (
                <Button
                  title={'Add Scheduled Time'}
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    e.tandemGroupId
                      ? this.showTgConfirmModal(this.onClickAddTime)
                      : this.onClickAddTime()
                  }
                />
              )}
            </div>
          }
        </div>

        {!ispb && this.renderSubTitle()}

        {ispb && this.renderDaySelect()}

        {!!e && (
          <Calendar
            onSelectSlot={slotInfo => {
              if (e.tandemGroupId) {
                return this.showTgConfirmModal(this.onClickAddTime);
              }
              return this.onClickAddTime(slotInfo);
            }}
            formats={{
              dayFormat: (date, culture, localizer) =>
                (localizer as DateLocalizer).format(
                  date,
                  'ddd',
                  culture as string
                ),
              timeGutterFormat: (date, culture, localizer) =>
                (localizer as DateLocalizer)
                  .format(date, 'h a', culture as string)
                  .toUpperCase(),
              dayRangeHeaderFormat: () => '',
            }}
            date={selectedDate.toISOString()}
            getNow={() => date}
            onSelectEvent={this.onSelectEvent}
            events={scheduleEvents}
            views={['week', 'day']}
            step={60}
            timeslots={1}
            view={ispb ? 'day' : 'week'}
            localizer={localizer}
            selectable={!!selectedEquip}
            onSelecting={() => false}
            components={{
              toolbar: () => null,
            }}
          />
        )}

        {!e && this.renderSelectEquipPlaceholder()}

        {modal.visible && (
          <Modal
            centered={true}
            title={modal.title}
            visible={modal.visible}
            onOk={this.processValues}
            onCancel={this.closeModal}
            okText={'Save'}
            confirmLoading={loading}
            footer={
              <div>
                {modal.schedule && (
                  <Button
                    title="Delete"
                    dark={true}
                    onClick={() => this.deleteSchedule(modal)}
                    loading={loading}
                  />
                )}
                <Button
                  title="Cancel"
                  outline={true}
                  onClick={this.closeModal}
                />
                <Button
                  title="Save"
                  disabled={!saveEnabled}
                  onClick={this.processValues}
                  loading={loading}
                />
              </div>
            }
          >
            <div
              css={css(
                !ispb && SharedStyles.row,
                `justify-content: space-between`
              )}
            >
              {Object.keys(modal.time).map((key, i) => {
                const item = modal.time[key];
                const isSelected = item.selected !== undefined;

                return (
                  <Label
                    css={css(!ispb && `width: 30%;`)}
                    key={i}
                    label={item.label}
                  >
                    <Select
                      css={css`
                        width: 100%;
                      `}
                      placeholder={!isSelected && 'Select...'}
                      defaultValue={
                        (item.selected !== undefined
                          ? item.selected
                          : undefined) as number
                      }
                      onChange={val => {
                        item.selected = val;
                        this.forceUpdate();
                      }}
                    >
                      {item.options.map(
                        (
                          d: { value: string | number; label: string },
                          _i: number
                        ) => (
                          <Select.Option key={_i} value={d.value}>
                            {d.label}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Label>
                );
              })}
            </div>
          </Modal>
        )}
        {copyToModalVisible && (
          <Modal
            title={'Copy Schedule from Existing Equipment'}
            visible={copyToModalVisible}
            onOk={() => 0}
            onCancel={() => this.toggleCopyToModal()}
            footer={
              <div>
                <Button
                  title="Cancel"
                  outline={true}
                  onClick={() => this.toggleCopyToModal()}
                />
                <Button
                  title="Save"
                  disabled={!this.state.selectedCopyTo}
                  onClick={this.processCopyToValues}
                  loading={loading}
                />
              </div>
            }
          >
            <div
              css={css(
                ispb ? SharedStyles.column : SharedStyles.row,
                styles.topRow
              )}
            >
              <AutoCompleteLimited
                css={css(
                  `
              width: ${ispb ? 100 : 75}%;
            `,
                  ispb && 'margin-bottom: 20px;'
                )}
                dataSource={this.getCopyToOptions()}
                onSelect={value => this.setState({ selectedCopyTo: value })}
                value={selectedCopyTo}
                onChange={value => this.getScheduleToCopy(value)}
                placeholder={'Select Equipment'}
                loading={loading}
                limit={25}
              />
            </div>
            <div css={css(styles.copyToContainer)}>
              <p css={css(styles.copyToText)}>
                Copy Schedule to{' '}
                {selectedEquip &&
                  `${get(this.getSelectedEquip(), 'equipmentSN')} (${get(
                    this.getSelectedEquip(),
                    'name'
                  )})`}
              </p>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export const SchedulingComponent = withRouter(_SchedulingComponent);
