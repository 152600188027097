export interface Publish {
  // tslint:disable-next-line:no-any
  [key: string]: any;
  published_at: string;
  // tslint:disable-next-line:no-any
  data: any;
}

export class _Publish {
  constructor(props?: Publish) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  // tslint:disable-next-line:no-any
  [key: string]: string | number | undefined | any;
  published_at = new Date().toISOString();
  data = {
    error: 0,
    source: '',
  };
}
