import { get } from 'lodash';
import { OrgType } from '../interfaces/orgType';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getOrgType = async (id: string) => {
  const resp = await instance.get(`/orgType/${id}`, { data: {} });
  return get(resp, 'data.orgType', null) as OrgType;
};

export const getOrgTypes = async () => {
  const resp = await instance.get(`/orgType`, { data: {} });
  return get(resp, 'data.orgTypes', []) as OrgType[];
};

export const createOrgType = async (orgType: OrgType) => {
  const resp = await instance.post('/orgType/create', {
    orgType: removeEmpty(orgType),
  });
  return get(resp, 'data.orgType', null) as OrgType;
};

export const updateOrgType = (orgType: OrgType) => {
  return createOrgType(orgType);
};
