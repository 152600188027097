/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import {
  createMaintSensorDataInputs,
  sensorDataIsValid,
} from './maint-form-container.component';
import { Model } from '../../../../_shared/interfaces/model';
import {
  MaintSensorData,
  CompressorMaintPData,
} from '../../../../_shared/interfaces/publishData';

type Payload = CompressorMaintPData;

interface IProps {
  model: Model;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const CompressorDzMaintDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  const emptyMaintSensorData = (): MaintSensorData => [false, 0, 100, 0];

  //STATE
  const [dewSensor, setDewSensor] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [filterpack, setFilterpack] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [desdryers, setDesdryers] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [des, setDes] = useState<MaintSensorData>(emptyMaintSensorData());

  //EFFECTS
  useEffect(() => {
    //VALIDATE!
    if (
      sensorDataIsValid(dewSensor) &&
      sensorDataIsValid(filterpack) &&
      sensorDataIsValid(desdryers) &&
      sensorDataIsValid(des)
    ) {
      validChanged(true);
      payloadChanged({
        dewsensor: dewSensor,
        filterpack: filterpack,
        desdryers: desdryers,
        des: des,
      });
    } else {
      validChanged(false);
    }
  }, [dewSensor, filterpack, desdryers, des]);

  return (
    <div>
      {createMaintSensorDataInputs('Dew Sensor', dewSensor, setDewSensor)}
      {createMaintSensorDataInputs('Filter Pack', filterpack, setFilterpack)}
      {createMaintSensorDataInputs('desdryers', desdryers, setDesdryers)}
      {createMaintSensorDataInputs('des', des, setDes)}
    </div>
  );
};
