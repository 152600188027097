export default () => {
  return {
    title: {
      email: 'Check your email for the verification code',
      sms: 'Check your phone for the verification code',
    },
    label: 'Verification Code',
    button: 'Verify',
  };
};
