/* tslint:disable */
import { get } from 'lodash';
import { getContent } from '../_shared/services/content.service';

export let CONTENT: {
  compressorErrorText: any;
  vacuumErrorText: any;
  handpieceErrorText: any;
  chairErrorText: any;
  maintErrorText: any;
} = {
  compressorErrorText: {},
  vacuumErrorText: {},
  handpieceErrorText: {},
  chairErrorText: {},
  maintErrorText: {},
};

(async () => {
  const data = await getContent('alerts');
  CONTENT = {
    ...data,
    maintErrorText: data.maintErrorText,
    compressorErrorText: data.compressorErrorText,
    vacuumErrorText: data.vacuumErrorText,
    handpieceErrorText: data.handpieceErrorText,
    chairErrorText: data.chairErrorText,
  };
})();

export const getAlertText = (
  sensorId: string,
  errorCode: number,
  eqType: string,
  key = 'alertName'
) => {
  return get(
    CONTENT,
    `${eqType}ErrorText.${sensorId}.error.${errorCode}.${key}`,
    'Unknown'
  );
};

export const getAlert = (
  sensorId: string,
  errorCode: number,
  eqType: string
) => {
  return getAlertText(sensorId, errorCode, eqType);
};

export const getMessage = (
  sensorId: string,
  errorCode: number,
  eqType: string
) => {
  return getAlertText(sensorId, errorCode, eqType, 'message');
};

export const getInfo = (
  sensorId: string,
  errorCode: number,
  eqType: string
) => {
  return getAlertText(sensorId, errorCode, eqType, 'info');
};
