/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Modal, message } from 'antd';
import styles from '../styles';
import { Form } from '../../_shared/form';
import { FormComponentProps } from 'antd/lib/form';
import { useRef, useState, useEffect, ReactNode } from 'react';
import { Row } from '../../_shared/row';
import { get, set, orderBy, size, unset, uniqBy } from 'lodash';
import { Equipment, EquipmentRecord } from '../../_shared/interfaces/equipment';
import eqstyles from './styles';
import { Button } from '../../_shared/button';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { createLocation, getLocations } from '../../_shared/services/manage-locations.service';
import { Org } from '../../_shared/interfaces/org';
import { Location, _Location } from '../../_shared/interfaces/location';
import { Loading } from '../../_shared/icon';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouterProps } from 'react-router';
import moment from 'moment';
import { cleanPhoneNumber, firstKey, getUrlParams, guid, userHasRole, validPhoneNumber } from '../../utils';
import { StatesSelect } from '../../_shared/lib/states';
import { ScanQRCode } from '../../_shared/qr-scanner';
import { getEquipmentBySN, updateEquipment } from '../../_shared/services/manage-equipment.service';
import { REG_KEY } from '..';
import Logo from '../../_shared/logo';
import { AppState } from '../../app.state';

interface IProps extends RouterProps {
  loadedEquipment?: {
    [key: string]: EquipmentRecord;
  }
  renderLoadedEquipment?: (s?: {}) => ReactNode;
  loading?: boolean;
}

interface IState {
  orgs: Org[];
  locations: Location[];
  loading: boolean;
  equipments: Equipment[];
  userOrg: Org | null;
  submitting: boolean;
}

export const EqRegistrationForm = withRouter((props: IProps) => {
  const user = useSelector(s => get(s, 'auth.user'));
  const isPhone = useSelector(s => get(s, 'dash.view.isPhoneBreak'));
  const [state, setState] = useState({
    loading: true,
    orgs: [],
    locations: [],
    equipments: [],
    userOrg: null,
    submitting: false,
  } as IState);
  
  const [locationModal, setLocationModal] = useState({});
  const [formVals, setFormVals] = useState({
    dentalOrgId: user.orgId,
  });

  const dentalOrgId = formVals.dentalOrgId || user.orgId;

  const allforms = useRef();

  const registerForm = (form: any, key: string | number) => {
    set(allforms, `current.${key}`, form);
  }

  const submit = async () => {
    try {
      setState(s => ({ ...s, submitting: true }))
      const all = {};
      const errs: any[] = [];
      Object.keys(allforms.current as any).map(k => {
        const form = get(allforms, `current.${k}`) as any;
        form.validateFieldsAndScroll(async (err: any, vals: any) => {
          if (err) {
            return errs.push(err);
          }
  
          set(all, k, vals);
        })
      })
  
      if (!size(errs)) {
        await Promise.all(Object.keys(all).map(async k => {
          if (k !== 'main') {
            const e = get(all, k);
            const eq = await getEquipmentBySN(get(e, 'equipmentSN'));
  
            if (!eq) {
              errs.push(`doesn't exist`)
              return message.error(`${e.equipmentSN} does not exist. Please verify that the serial number has been entered correctly.`);
            }
  
            const eqCodes = get(eq, 'activationCodes', []) as string[];
            if (!eqCodes.map(s => s.toLowerCase()).includes(get(e, 'activationcode', '').toLowerCase())) {
              errs.push(`bad activation code`)
              return message.error(`Activation code is incorrect for ${e.equipmentSN}. Please verify that it has been entered correctly.`);
            }
            
            const mainForm = get(all, 'main');
            if (eq) {
              set(eq, 'locationId', get(mainForm, 'locationId'));
              set(eq, 'dentalOrgId', dentalOrgId);
              set(eq, 'installDate', moment(e.installDate).toISOString());
              set(eq, 'registrationDate', moment().toISOString());
              set(eq, 'updatedBy', user.email);
              set(eq, 'updatedAt', moment().toISOString());

              await updateEquipment(eq);
            }
          }
        }))
      }

      if (!size(errs)) {
        message.success('Equipment registered successfully.')
        localStorage.removeItem(REG_KEY);
        clear();
      }

    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, submitting: false }))
    }
  }

  const clear = () => {
    const eqforms: any[] = [];
    Object.keys(allforms.current as any).map(k => {
      const form = get(allforms, `current.${k}`) as any;
      if (k !== 'main') {
        if (size(eqforms)) {
          unset(allforms, `current.${k}`);
        } else {
          form.resetFields();
          eqforms.push(k);
        }
      } else {
        form.resetFields();
        setFormVals(form.getFieldsValue());
      }
    })

    setState(s => ({ ...s, equipments: s.equipments.filter(e => eqforms.includes(e.id)) }))
  }

  const loadItems = async () => {
    try {
      setState(s => ({ ...s, loading: true }))
      const [orgs, locations] = await Promise.all([
        getOrgs(),
        getLocations()
      ])

      const userOrg = orgs.find(o => o.id == user.orgId) as Org;

      setState(s => ({ ...s, orgs: userOrg && userOrg.orgType == 0 ? orgs.filter(o => o.serviceOrgId == userOrg.id) : orgs, locations, userOrg }))

      if (get(userOrg, 'orgType') == 0 && formVals.dentalOrgId == get(userOrg, 'id')) {
        setFormVals(fv => ({ ...fv, dentalOrgId: null }));
      }
    } catch (err) {
      message.error(err);
    } finally {
      setState(s => ({ ...s, loading: false })) 
    }
  }

  const viewEquipment = () => {
    Modal.confirm({
      title: 'Navigation Confirmation',
      content: 'Are you sure you want to navigate away from Equipment Registration? The entered equipment will not be registered and any progress will be lost.',
      okText: 'Leave',
      maskClosable: true,
      onCancel: () => 0,
      onOk: () => {
        props.history.push('/dashboard');
      }
    })
  }

  const removeEq = (e: Equipment) => {
    unset(allforms, `current.${e.id}`);
    setState(s => ({ ...s, equipments: s.equipments.filter(eq => eq.id != e.id) }))
  }

  useEffect(() => {
    loadItems();
  }, [])

  useEffect(() => {
    if (!props.loading) {
      if (firstKey(props.loadedEquipment)) {
        setState(s => ({ ...s, equipments: uniqBy([...s.equipments, firstKey(props.loadedEquipment)], 'equipmentSN') }))
      } else {
        setState(s => ({ ...s, equipments: [...s.equipments, { id: guid() } as Equipment] }))
      }
    }
  }, [props.loading]);

  useEffect(() => {
    setFormVals(v => ({ ...v, locationId: null }));
  }, [formVals.dentalOrgId])

  const locations = state.locations.filter(l => l.orgId == dentalOrgId).map(o => ({ title: o.name, value: o.sk || o.id }))

  if (size(locations) === 1 && !get(formVals, 'locationId')) {
    setFormVals(v => ({ ...v, locationId: get(locations, '0.value') }))
  }

  const isShowingDental = get(state, 'userOrg.orgType') == 0 || userHasRole([0], { auth: { user } } as AppState);
  const locDisabled = isShowingDental ? !get(formVals, 'dentalOrgId') : !dentalOrgId;

  const formProps = {
    registerForm: (f: any) => registerForm(f, 'main'),
    defaults: formVals,
    onChange: (e: any, f: any) => setFormVals(f.getFieldsValue()),
    toDisplay: [
      {
        canDisplay: () => isShowingDental,
        title: 'Dental Organization',
        var: 'dentalOrgId',
        type: 'dropdown',
        opts: () => orderBy(state.orgs.filter(o => o.orgType === 1).map(o => ({ title: o.name, value: o.id })), 'title', 'asc'),
        props: {
          showSearch: true,
          filterOption: (inp: string, option: any) => get(option, 'props.children', '').toUpperCase().indexOf(inp.toUpperCase()) !== -1,
        },
        options: {
          rules: [
            {
              required: true,
              message: 'Dental Organization is required'
            }
          ]
        }
      },
      {
        title: 'Location',
        var: 'locationId',
        type: 'dropdown',
        style: `position: relative; width: calc(100% - 32px);`,
        subContent: () => {
          return (
            <div css={css(eqstyles.addLocationButton)}>
              <Button disabled={locDisabled} icon="plus" onClick={addLocation} title="" />
            </div>
          )
        },
        props: {
          disabled: locDisabled,
        },
        opts: () => locations,
        options: {
          rules: [
            {
              required: true,
              message: 'Location is required'
            }
          ]
        }
      }
    ]
  }

  const addLocation = () => {
    let form: any;

    const close = () => {
      setLocationModal({});
    }

    setLocationModal({
      title: 'Add Location',
      visible: true,
      formProps: {
        registerForm: (f: any) => form = f,
      },
      footer: null,
      onCancel: () => {
        close()
      },
      onSubmit: () => {
        form.validateFieldsAndScroll(async (err: any, vals: any) => {
          if (err) {
            return;
          }

          try {
            const loc = new _Location(vals);
            loc.orgId = dentalOrgId;
            loc.createdBy = user.email;
            loc.updatedBy = user.email;
            await createLocation(loc);
            close();
            await loadItems();
            form.resetFields();
            setFormVals(fv => ({ ...fv, locationId: loc.id }));
          } catch (err) {
            message.error(err);
          }
        });
      }
    })
  }

  return (
    <div css={css(styles.inputContainer, eqstyles.inputContainer)}>
      <Row center style={{ marginBottom: 10 }}><Logo /></Row>
      <p css={css(styles.title)}>Equipment Registration</p>
      {state.loading ? <Row center><Loading /></Row> : <Form {...formProps as unknown as FormComponentProps} />}

      {state.equipments.map(e => <EQRow key={e.id} isPhone={isPhone} canDelete={size(state.equipments) > 1} onDelete={() => removeEq(e)} registerForm={(f: any) => registerForm(f, e.id as string)} equipment={e} />)}

      <div style={{ marginTop: 10 }}>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Button css={css(`font-weight: bold;`)} icon="plus" title="Add Equipment" type="link" onClick={() => setState(s => ({ ...s, equipments: [...s.equipments, { id: guid() } as Equipment] }))} />
        </Row>
        <Row center style={{ marginTop: 20 }}>
          <Button title="Clear" style={{ marginRight: 10 }} onClick={clear} outline />
          <Button title="Register" loading={state.submitting} onClick={submit} />
        </Row>
        <Row center style={{ marginTop: 10 }}>
          <Button title="View Equipment List" type="link" onClick={viewEquipment} />
        </Row>
      </div>
      <AddLocationModal {...locationModal} />
    </div>
  )
})

const EQRow = (props: any) => {
  const [defaults, setDefaults] = useState({
    ...(props.equipment || {}),
    installDate: moment(),
  });

  const clickQr = () => {
    ScanQRCode(props).then((res) => {
      const params = getUrlParams(res as string);
      const equipmentSN = params.serialnumber || defaults.equipmentSN;
      const activationcode = params.activationcode || defaults.activationcode;

      setDefaults((d: any) => ({ ...d, equipmentSN, activationcode, }));
    }).catch(err => {
      if (err) {
        message.error(err);
      }
    }) 
  }

  const formProps = {
    defaults,
    toDisplay: [
      {
        title: 'Equipment Serial Number',
        var: 'equipmentSN',
        options: {
          rules: [
            {
              required: true,
              message: 'Equipment Serial Number is required'
            },
            {
              len: 10,
              message: 'Equipment Serial Number must be exactly 10 alphanumeric characters',
            },
          ]
        }
      },
      {
        title: 'Activation Code',
        var: 'activationcode',
        options: {
          rules: [
            {
              required: true,
              message: 'Activation Code is required'
            }
          ]
        }
      },
      {
        title: 'Install Date',
        var: 'installDate',
        type: 'date',
        options: {
          rules: [
            {
              required: true,
              message: 'Install Date is required'
            }
          ]
        }
      }
    ],
    ...props,
  }

  return (
    <div css={css(eqstyles.eqRow)}>
      <Row style={{ width: '100%' }}>
        <b>ADD EQUIPMENT</b>

        <Row className='btnRow'>
          {props.canDelete && <Button icon="delete" title="" onClick={props.onDelete} style={{ marginRight: 5 }} />}
          <Button icon="qrcode" title="" onClick={clickQr} />
        </Row>
      </Row>
      <Form {...formProps} />
    </div>
  )
}

const AddLocationModal = (props: any) => {
  const formProps = {
    ...(props.formProps || {}),
    defaults: {},
    toDisplay: [
      {
        title: 'Name',
        var: 'name',
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'phone',
        transform: (value: string) => cleanPhoneNumber(value),
        options: {
          preserve: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
          ],
        },
      },
      {
        title: 'Address',
        var: 'address.address',
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Address is required',
            },
          ],
        },
      },
      {
        title: 'Address 2',
        var: 'address.address2'
      },
      {
        title: 'City',
        var: 'address.city',
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'City is required ',
            },
          ],
        },
      },
      {
        title: 'State',
        var: 'address.state',
        type: 'dropdown',
        opts: StatesSelect,
        style: `
          float: left;
          width: 50%;
          padding-right: 5px;
        `,
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'State is required ',
            },
          ],
        },
      },
      {
        title: 'ZIP Code',
        var: 'address.zip',
        style: `
          float: left;
          width: 50%;
          padding-left: 5px;
        `,
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Zip Code is required ',
            },
          ],
        },
      },
    ]
  }

  return !props.visible ? null : (
    <Modal {...props}>
      <Form {...formProps as unknown as FormComponentProps} />
      <Row center style={{ float: 'none', width: '100%' }}>
        <Button title="Submit" onClick={props.onSubmit} />
      </Row>
    </Modal>
  )
}