import colors from '../colors';

export default {
  container: `
    width: 100%;
    background-color: ${colors.infoRow};
    border: 1px solid ${colors.inputBorder};
    padding: 5px;
  `,
  extendContainer: `
    margin-left: -20px;
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
  `,
  noFill: `
    background-color: transparent;
    border: none;
  `,
  col: `
    min-height: 65px;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-right: 1px solid ${colors.inputBorder};
  `,
};
