import { connect } from 'react-redux';
import { NotificationSettings as NotificationSettingsComponent } from './notification-settings.component';
import { updateUser } from '../../authentication/authentication.actions';
import { AppState } from '../../app.state';
import { User } from '../../_shared/interfaces/user';

interface IProps {
  _user?: User;
}
const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { _user } = ownProps;
  return {
    _user,
    sState: state,
  };
};

export const NotificationSettings = connect(
  mapStateToProps,
  {
    updateUser,
  }
)(NotificationSettingsComponent);
