import { get } from 'lodash';
import { wait } from '../../utils';
import { instance } from './instance';

//@ts-ignore
export const getContent = async (key: string, tries = 0) => {
  try {
    const resp = await instance.get(`/content/${key}`, { data: {} });
    return get(resp, 'data.data', {});
  } catch (err) {
    if (tries < 5) {
      await wait();
      return getContent(key, tries + 1);
    } else {
      throw err;
    }
  }
};
