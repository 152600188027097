import axios from 'axios';
import { getUrl } from '../../config';
import { get, isObject, set } from 'lodash';
import localforage from 'localforage';

const createInstance = () => {
  const baseURL = getUrl();
  return axios.create({ baseURL });
};

const getStore = () => {
  return get(window, 'STORE', { getState: () => {} });
};

const findAllUpdatedAndFix = (data = {}) => {
  if (isObject(data)) {
    for (let key in data) {
      const d = get(data, key);
      if (isObject(d)) {
        findAllUpdatedAndFix(d);
      }

      if (key === 'updatedBy') {
        const store = getStore();
        const user = get(store.getState(), 'auth.user.userId', get(data, key, 'unknown'));
        set(data, key, user);
      } else if (key === 'updatedAt') {
        set(data, key, new Date().toISOString());
      }
    }
  }
  return data;
};

const getCurrentToken = (_opts = {}) => {
  const opts = {
    tries: 1,
    maxTries: 10,
    ..._opts,
  }
  return new Promise((resolve, reject) => {
    const store = getStore();
    const sState = store.getState();

    const token = get(sState, 'auth.token');

    if (!token && opts.tries <= opts.maxTries) {
      setTimeout(() => {
        getCurrentToken({ ...opts, tries: opts.tries + 1 }).then(resolve).catch(reject);
      }, 100);
    } else {
      resolve(token);
    }
  })
};

export const instance = createInstance();

const DONTNEEDTOKEN = [
  '/content/alerts',
  '/user/token',
]

instance.interceptors.request.use(
  async config => {
    if (
      get(config, 'method', '').toLowerCase() === 'post' &&
      config.url !== '/event'
    ) {
      config.data = findAllUpdatedAndFix(config.data);
    }

    if (!get(config, 'headers.Authorization') && config.url && !DONTNEEDTOKEN.includes(config.url)) {
      const ct = await getCurrentToken();
      if (ct) {
        set(config, 'headers.Authorization', `Bearer ${ct}`);
      }
    }

    return config;
  },
  error => {
    throw Promise.reject(error);
  }
);

let loggingOut = false;
instance.interceptors.response.use(
  value => {
    return value;
  },
  async err => {
    const status = get(err, 'response.status');
    const message = get(err, 'response.data.error');
    if (status == 401 && !loggingOut) {
      loggingOut = true;
      await localforage.clear();
      alert('Your token is no longer valid. Please login again.');
      window.location.href = window.location.origin;
      setTimeout(() => window.location.reload(), 50);
    }

    err.message = message || err.message;
    throw err;
  }
);
