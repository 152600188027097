import { guid } from '../../utils';

export interface Threshold {
  minorLower?: number;
  majorLower?: number;
  minorUpper?: number;
  majorUpper?: number;
  duration?: number;
}

interface Thresh {
  [key: string]: Threshold;
}

export interface InfoCol {
  key: string;
  text: string;
  icon: string;
  suffix?: string;
}

export interface InfoRow {
  title: string;
  columns: InfoCol[];
}

export interface Model {
  [key: string]: string | number | undefined | Thresh | InfoRow[];
  id: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;

  isActive?: number;
  type: string;
  family?: string;
  name: string;
  modelNumber?: string;
  image?: string;
  thresholds?: Thresh;
  header?: number;
  heads: number;
  infoRows?: InfoRow[];
}

export class _Model {
  constructor(props?: Model) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined | Thresh | InfoRow[];
  id = guid();
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;

  isActive = 0;
  type: string = "compressor";
  family = '';
  name = '';
  modelNumber = '';
  image = '';
  thresholds = {};
  heads = 0;
  infoRows = undefined;
}
