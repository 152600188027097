import axios, { Method } from 'axios';
import { get } from 'lodash';
import CONFIG, { IAppConfig, ENVS } from '../../config';

const config: IAppConfig = CONFIG(process.env.ENV_STAGE as ENVS);

export const getHardwareVersion = async (deviceId: string) => {
  const access_token = config.particle_key;
  // tslint:disable-next-line: max-line-length
  const url = `https://api.particle.io/v1/products/9783/devices/${deviceId}/HW_VER?access_token=${access_token}`;
  const options = {
    method: 'GET' as Method,
    url,
  };
  try {
    const response = await axios(options);
    return get(response, 'data.result') as string;
  } catch (error) {
    console.warn(error);
  }
};
