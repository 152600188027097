/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import { AppState } from '../../app.state';
import Link from '../../_shared/link';
import FormTitle from '../../_shared/form-title';
import {
  TandemGroup,
  _TandemGroup,
} from '../../_shared/interfaces/tandemGroup';
import { chain, get, set } from 'lodash';
import sharedStyles from '../../_shared/styles';
import styles from './edit-tandem-group.styles';
import {
  Form,
  Input,
  Alert,
  DatePicker,
  message,
  Checkbox,
  Modal,
  Icon,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  buildErrorMsgFromForm,
  userHasRole,
  uppercaseWords,
  isServiceOrgAdmin,
  sortByLabel,
  getEquipmentType,
  sortByKey,
  isPB,
  getEquipConfig,
  getEquipmentModel,
  getDeviceStatusFromEquip,
  generateTGDeviceCMD,
  TG_ROLES,
  cleanCopy,
} from '../../utils';
import { Select } from '../../_shared/select';
import { Button } from '../../_shared/button';
import { Org } from '../../_shared/interfaces/org';
import { Location } from '../../_shared/interfaces/location';
import { Equipment, EQ_TYPE } from '../../_shared/interfaces/equipment';
import { TableListMultiGroup } from '../../_shared/table-list-multi';
import { User } from '../../_shared/interfaces/user';
import { updateEquipment } from '../../_shared/services/manage-equipment.service';
import {
  getTandemGroups,
  updateTandemGroup,
} from '../../_shared/services/manage-tandem-groups.service';
import { SelectValue } from 'antd/lib/select';
import { Sidebar } from '../equipment-details/sidebar.component';
import colors from '../../_shared/colors';
import { cardStyles } from '../manage-equipment/manage-equipment.styles';
import { renderFWErrorModal } from '../../_shared/fw-error-modal';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { ColumnProps } from 'antd/lib/table';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateMyEquipment: () => Promise<void>;
}

interface Opt {
  label: string;
  value: string | number;
  orgId?: string;
}

const EquipColumns = (_this: EditTandemGroup) => {
  return [
    {
      title: 'Select',
      dataIndex: '',
      width: '16.66%',
      render: (text: string, record: Equipment) => {
        const disabled =
          _this.state.selectedEquipment.length >= 4 &&
          !_this.state.selectedEquipment.includes(record.id as never);

        return (
          <Checkbox
            disabled={disabled}
            defaultChecked={_this.state.selectedEquipment.includes(
              record.id as never
            )}
            onChange={e => {
              if (!e.target.checked) {
                if (_this.state.central === record.id) {
                  _this.setState({ central: '' });
                }
                const selectedEquipment = _this.state.selectedEquipment.filter(
                  (equipId: string) => {
                    return equipId !== record.id;
                  }
                );
                if (selectedEquipment.length) {
                  _this.setState({ central: selectedEquipment[0] });
                }
                return _this.setState({ selectedEquipment });
              }

              if (!_this.state.selectedEquipment.length) {
                _this.setState({ central: record.id });
              }
              _this.setState({
                selectedEquipment: [
                  ..._this.state.selectedEquipment,
                  record.id,
                ],
              });
            }}
          />
        );
      },
    },
    {
      title: 'Serial Number',
      dataIndex: 'equipmentSN',
      width: ' 16.66%',
      defaultSortOrder: 'ascend',
      render: (text: string, record: Equipment) => {
        return (
          <span>{record.equipmentSN ? record.equipmentSN : record.name}</span>
        );
      },
      sorter: (a: Equipment, b: Equipment) => sortByKey(a, b, 'equipmentSN'),
    },
    {
      title: 'Model Id',
      dataIndex: 'modelId',
      width: '16.66%',
    },
    {
      title: 'Aeras Controller',
      dataIndex: 'controllerSerial',
      width: '16.66%',
      render: (text: string, record: Equipment) => {
        return <span>{record.controllerSerial}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '16.66%',
      render: (text: string, e: Equipment) => {
        const config = getEquipConfig(e, _this.props.sState);
        return _this.renderStatus({
          equipment: e,
          loading: _this.state.loading,
          sState: _this.props.sState,
        });
      },
    },
    {
      title: 'Central',
      dataIndex: 'central',
      width: '16.66%',
      render: (text: string, record: Equipment) => {
        return (
          <Checkbox
            checked={_this.state.central === record.id}
            onChange={e => {
              if (e.target.checked) {
                return _this.setState({ central: record.id });
              }
              _this.setState({ central: '' });
            }}
          />
        );
      },
    },
  ].filter(f => f) as ColumnProps<any>[];
};

class EditTandemGroup extends Component<IProps> {
  state = {
    back: undefined,
    equipment: undefined,
    equip: undefined,
    model: undefined,
    showSideBar: undefined,
    tandemGroup: undefined as TandemGroup | undefined,
    loading: false,
    error: null,
    isEdit: false,
    isAdd: false,
    success: null,
    central: null,
    selectedEquipment: [],
    allEquipment: [],
    equipMap: {},
    tandemGroupOriginal: undefined,
    toDisplay: [
      {
        title: 'Name',
        var: 'name',
        transform: (value: string) => uppercaseWords(value),
        options: {
          initialValue: get(
            this.props,
            'history.location.state.tandemGroup.name'
          ),
          rules: [
            {
              required: true,
              message: 'Name is required',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Organization',
        type: 'dropdown',
        var: 'orgId',
        canDisplay: () => {
          const {
            history: { location },
          } = this.props;
          const isEdit = get(location, 'state.editMode', true) as boolean;
          const tandemGroup = get(location, 'state.tandemGroup') as TandemGroup;
          return !isEdit || (isEdit && !get(tandemGroup, 'locationId'));
        },
        onChange: (value: SelectValue) => {
          const {
            form: { getFieldsValue, setFieldsValue },
          } = this.props;
          const { locationId, equipType } = getFieldsValue([
            'locationId',
            'equipType',
          ]);
          const { central, selectedEquipment } = this.state;
          if (locationId) setFieldsValue({ locationId: '' });
          if (equipType) setFieldsValue({ equipType: '' });
          if (central) this.setState({ central: '' });
          if (selectedEquipment.length) this.setState({ equipment: [] });
        },
        opts: () => {
          const { sState } = this.props;
          return sState.dash.orgs
            .filter((org: Org) => org.orgType === 1)
            .map(o => ({ label: o.name, value: o.id } as Opt))
            .sort(sortByLabel);
        },
        options: {
          initialValue: get(this.props, 'history.location.state.orgId'),
          rules: [
            {
              required: true,
              message: 'Organization is required',
            },
          ],
        },
      },
      {
        title: 'Location',
        type: 'dropdown',
        var: 'locationId',
        canDisplay: () => {
          const {
            history: { location },
          } = this.props;
          const isEdit = get(location, 'state.editMode', true) as boolean;
          const tandemGroup = get(location, 'state.tandemGroup') as TandemGroup;
          return !isEdit || (isEdit && !get(tandemGroup, 'locationId'));
        },
        disabled: () => {
          const {
            form: { getFieldValue },
          } = this.props;
          return !getFieldValue('orgId');
        },
        onChange: (value: SelectValue) => {
          const {
            form: { getFieldsValue, setFieldsValue },
          } = this.props;
          const { equipType } = getFieldsValue(['locationId', 'equipType']);
          const { central, selectedEquipment } = this.state;
          if (equipType) setFieldsValue({ equipType: '' });
          if (central) this.setState({ central: '' });
          if (selectedEquipment.length) this.setState({ equipment: [] });
        },
        opts: () => {
          const isSuper = userHasRole([0, 1], this.props.sState);
          const isSOAdmin = isServiceOrgAdmin(this.props.sState);
          const {
            form: { getFieldValue },
          } = this.props;
          const opts = get(this.props.sState, 'dash.locations', []).map(
            (m: Location) => ({
              label: m.name,
              value: m.id,
              orgId: m.orgId,
            })
          );
          if (isSuper || isSOAdmin) {
            return opts
              .filter((opt: Opt) => opt.orgId === getFieldValue('orgId'))
              .sort(sortByLabel);
          } else {
            return opts
              .filter(
                (opt: Opt) =>
                  opt.orgId === get(this.props.sState, 'auth.user.orgId')
              )
              .sort(sortByLabel);
          }
        },
        options: {
          initialValue: get(this.props, 'history.location.state.locationId'),
          rules: [
            {
              required: true,
              message: 'Location is required',
            },
          ],
        },
      },
      {
        title: 'Equipment Type',
        type: 'dropdown',
        var: 'equipType',
        canDisplay: () => {
          const {
            history: { location },
          } = this.props;
          const isEdit = get(
            location,
            'state.editMode',
            this.state.isEdit
          ) as boolean;
          return isEdit ? false : true;
        },
        disabled: () => {
          const {
            form: { getFieldValue },
          } = this.props;
          return !getFieldValue('orgId');
        },
        onChange: (value: SelectValue) => {
          const { central, selectedEquipment } = this.state;
          if (central) this.setState({ central: '' });
          if (selectedEquipment.length) this.setState({ equipment: [] });
        },
        opts: () => {
          return [
            {
              label: EQ_TYPE.Compressor,
              value: EQ_TYPE.Compressor,
            },
            {
              label: EQ_TYPE.Vacuum,
              value: EQ_TYPE.Vacuum,
            },
          ];
        },
        options: {
          initialValue:
            !!get(this.props, 'history.location.state.equipment') &&
            getEquipmentType(
              get(this.props, 'history.location.state.equipment.equipmentSN'),
              get(this.props, 'history.location.state.equipment.modelId')
            ),
          rules: [
            {
              required: true,
              message: 'Equipment Type is required',
            },
          ],
        },
      },
    ],
  };

  componentDidMount = async () => {
    const {
      sState,
      history: { location },
    } = this.props;
    const back = get(location, 'state.back');
    const showSideBar = get(location, 'state.showSideBar');
    const tandemGroup = get(location, 'state.tandemGroup', new _TandemGroup());
    const equipment = get(location, 'state.equipment');
    const model = equipment && getEquipmentModel(equipment, sState);
    const tandemGroupOriginal = get(location, 'state.tandemGroup');
    const isEdit = get(location, 'state.editMode', this.state.isEdit);
    const isAdd = get(location, 'state.isAdd', false);
    const allEquipment = get(
      this.props,
      'sState.dash.equipment',
      []
    ) as Equipment[];
    const equipMap = chain(allEquipment)
      .keyBy('id')
      .value();

    const tgEquipment = allEquipment.filter(
      eq => eq.tandemGroupId === get(tandemGroupOriginal, 'id')
    );
    const centralEq = tgEquipment.find(eq => eq.tandemGroupRole === 'central');

    let selectedEquipment = [];
    if (equipment) {
      selectedEquipment.push(equipment.id);
    }

    this.setState({
      back,
      tandemGroup,
      isAdd,
      isEdit,
      central: get(centralEq, 'id') || get(equipment, 'id'),
      equipment: tgEquipment,
      allEquipment,
      tandemGroupOriginal,
      showSideBar,
      equip: equipment,
      model,
      equipMap,
      selectedEquipment,
    });
  };

  sendDevComAndUpdateEq = async (tandemGroup: TandemGroup) => {
    const { equipMap, selectedEquipment, central } = this.state;
    const centralEquipment = get(equipMap, `[${central}]`);
    const peerEquipmentIds = selectedEquipment.filter(id => id !== central);
    const peerEquipment = peerEquipmentIds.map(id => get(equipMap, `[${id}]`));
    try {
      const arg = generateTGDeviceCMD(
        tandemGroup.groupNumber,
        TG_ROLES.CENTRAL
      );
      const centralConfig = getEquipConfig(centralEquipment, this.props.sState);
      const centFWVersion: number = get(centralConfig, 'fw_version');
      if (centFWVersion < 34) {
        return renderFWErrorModal(
          centralEquipment.equipmentSN,
          centFWVersion.toString()
        );
      }
      await sendDeviceCommand(
        centralEquipment.deviceId,
        { arg },
        {
          tandem: {
            tandemGroupNumber: tandemGroup.groupNumber,
            tandemGroupRole: TG_ROLES.CENTRAL,
          },
        }
      );
      message.success(
        `${centralEquipment.equipmentSN} has been added to Tandem Group ${tandemGroup.name}`
      );
      peerEquipment.length &&
        (await Promise.all(
          peerEquipment.map(async equipment => {
            const config = getEquipConfig(equipment, this.props.sState);
            const FWVersion: number = get(config, 'fw_version');
            if (FWVersion < 34) {
              return renderFWErrorModal(
                equipment.equipmentSN,
                FWVersion.toString()
              );
            }
            try {
              const arg = generateTGDeviceCMD(
                tandemGroup.groupNumber,
                TG_ROLES.PEER
              );
              await sendDeviceCommand(
                equipment.deviceId,
                { arg },
                {
                  tandem: {
                    tandemGroupNumber: tandemGroup.groupNumber,
                    tandemGroupRole: TG_ROLES.PEER,
                  },
                }
              );
              message.success(
                `${equipment.equipmentSN} has been added to tandem group ${tandemGroup.name}`
              );
            } catch (error) {
              message.error(
                `${equipment.equipmentSN} could not be added to tandem group ${tandemGroup.name}`
              );
            }
          })
        ));
      return true;
    } catch (error) {
      message.error(
        `${centralEquipment.equipmentSN} could not be added to tandem group ${tandemGroup.name}`
      );
      throw new Error();
    }
  };

  verifyTG = (isAdd: boolean, isEdit: boolean) => {
    if (!isEdit && isAdd && !this.state.selectedEquipment.length) {
      this.setState({
        error: 'You must select 1-4 pieces of equipment for this Tandem Group.',
        loading: false,
      });
      return false;
    }
    if (!isEdit && isAdd && !this.state.central) {
      this.setState({
        error:
          // tslint:disable-next-line: max-line-length
          'You must select one piece of equipment as the Central equipment for this tandem group.',
        loading: false,
      });
      return false;
    }
    if (
      !isEdit &&
      !this.state.selectedEquipment.includes(this.state.central as never)
    ) {
      this.setState({
        error: 'Central equipment must be a member of the tandem group.',
        loading: false,
      });
      return false;
    }
    return true;
  };

  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    if (userHasRole([7], this.props.sState)) {
      Modal.warn({
        title: 'Functionality Not Available',
        content:
          //tslint:disable-next-line: max-line-length
          'Your user account does not have access to complete this functionality.\
          If you feel that this is incorrect, please contact your company Admin.',
        onOk: () => {
          return 0;
        },
      });

      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);
    const { tandemGroup } = this.state;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const originalTandem = cleanCopy(tandemGroup);

        const _tandemGroup = new _TandemGroup({
          ...((tandemGroup as TandemGroup) || {}),
        }) as TandemGroup;
        this.state.toDisplay.map(item => {
          const val = values[item.var];
          _tandemGroup[item.var] = item.transform ? item.transform(val) : val;
        });
        const isEdit = get(this.state, 'isEdit', true);
        const isAdd = get(this.state, 'isAdd', false);
        const date = new Date().toISOString();
        const currentUser = get(this.props.sState, 'auth.user', {}) as User;
        const groupNumber = Math.floor(moment().valueOf() / 1000);

        set(_tandemGroup, 'updatedAt', date);
        set(_tandemGroup, 'updatedBy', currentUser.userId);
        if (isEdit && !_tandemGroup.equipType) {
          delete _tandemGroup.orgId;
          set(_tandemGroup, 'equipType', get(tandemGroup, 'equipType'));
        }
        if (isEdit && !_tandemGroup.locationId) {
          set(_tandemGroup, 'locationId', get(tandemGroup, 'locationId'));
        }
        const canContinue = this.verifyTG(isAdd, isEdit);
        if (!canContinue) return;

        let location;

        if (isAdd) {
          set(_tandemGroup, 'groupNumber', groupNumber);
          const procede = await this.sendDevComAndUpdateEq(_tandemGroup);
          if (procede) {
            const response = await getTandemGroups({
              groupNumber: { eq: _tandemGroup.groupNumber },
            });
            const newTandemGroup = get(response, '0');
            if (newTandemGroup) {
              set(newTandemGroup, 'name', _tandemGroup.name);
              set(newTandemGroup, 'createdBy', currentUser.id);
              await updateTandemGroup(newTandemGroup);
            }
          }
        } else {
          const equipment = get(this, 'props.sState.dash.equipment').filter(
            (e: Equipment) => get(e, 'tandemGroupId') === _tandemGroup.id
          ) as Equipment[];
          const equipmentNoLoc = equipment.filter(eq => !eq.locationId);
          const allLocations = get(
            this.props,
            'sState.dash.locations',
            []
          ) as Location[];
          location = allLocations.find(
            loc => loc.id === values.locationId
          ) as Location;
          if (equipmentNoLoc.length) {
            return this.showAddLocToEquipModal(
              equipmentNoLoc,
              location,
              _tandemGroup
            );
          } else {
            await updateTandemGroup(_tandemGroup);
          }
        }
        await this.props.updateMyEquipment();
        message.success(
          isAdd
            ? 'New tandem group has been created!'
            : 'Tandem group has been saved'
        );

        trackEvent(
          isAdd ? eventTypes.tandem_add : eventTypes.tandem_update,
          isAdd
            ? {
              tandemGroup: _tandemGroup,
              org: get(this, 'props.sState.dash.orgs', []).find(
                (o: Org) => o.id === _tandemGroup.orgId
              ),
              location,
            }
            : { old: originalTandem, new: _tandemGroup }
        );

        this.setState({
          loading: false,
          isEdit: false,
          tandemGroup: _tandemGroup,
        });

        if (get(this.state, 'back', '').includes('Equipment')) {
          this.goBack();
        } else {
          this.manageTandemGroup(_tandemGroup);
        }
      } catch (error) {
        message.error('Tandem Group could not be created');
        return this.setState({
          loading: false,
        });
      }
    });
  };

  showAddLocToEquipModal = (
    equip: Equipment[],
    loc: Location,
    _tandemGroup: TandemGroup
  ) => {
    Modal.confirm({
      title: 'Add Equipment to Location?',
      content: (
        <div>
          <p>The following equipment are not associated with a location: </p>
          {equip.map(eq => {
            return <span> - {eq.equipmentSN || eq.name}</span>;
          })}
          <br />
          <p>
            Do you want to add these equipment to the same location as tandem
            group {_tandemGroup.name}?
          </p>
        </div>
      ),
      onOk: async () => {
        try {
          await updateTandemGroup(_tandemGroup);
          message.success(
            `Successfully updated tandem group ${_tandemGroup.name}`
          );
          equip.map(async eq => {
            const me = get(this.props, 'sState.auth.user');
            set(eq, 'updatedAt', moment().toISOString());
            set(eq, 'updatedBy', me.userId);
            set(eq, 'locationId', loc.id);
            try {
              await updateEquipment(eq);
              message.success(
                `Successfully updated equipment ${eq.equipmentSN || eq.name}`
              );
            } catch (error) {
              message.error(
                `Could not update equipment ${eq.equipmentSN || eq.name}`
              );
            }
          });
          this.setState({
            loading: false,
            isEdit: false,
            tandemGroup: _tandemGroup,
          });

          this.manageTandemGroup(_tandemGroup);
        } catch (error) {
          message.error(`Could not update tandem group ${_tandemGroup.name}`);
        }
      },
      okText: 'YES',
      cancelText: 'NO',
      onCancel: () => 0,
    });
  };

  setFieldsOriginal = () => {
    const { toDisplay, tandemGroup } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    const TandemGroup = new _TandemGroup({
      ...(tandemGroup || {}),
    } as TandemGroup);
    toDisplay.map(item => {
      setFieldsValue({ [item.var]: TandemGroup[item.var] });
    });
  };

  goBack = () => {
    this.setFieldsOriginal();
    this.props.history.goBack();
  };

  manageTandemGroup = (tandemGroup: TandemGroup) => {
    this.props.history.push('/dashboard/manageTandemGroups', {
      tandemGroup,
    });
  };

  goToBillingHistory = (equipmentId: string) => { };

  renderEquipTable = () => {
    const { loading } = this.state;
    const {
      form: { getFieldValue },
    } = this.props;
    const allEquip = get(this.state, 'allEquipment');
    const equipment = allEquip.filter(
      (equip: {
        locationId: string;
        equipmentSN: string;
        modelId: string;
        tandemGroupId: string;
        controllerSerial: string;
        id: string;
      }) => {
        const {
          form: { getFieldValue },
        } = this.props;
        const eqType = getFieldValue('equipType');
        const locationId = getFieldValue('locationId');
        return (
          equip.locationId === locationId &&
          getEquipmentType(equip.equipmentSN, equip.modelId) === eqType &&
          !equip.tandemGroupId
        );
      }
    );
    return (
      <div css={css(`margin-bottom: 50px`)}>
        <TableListMultiGroup
          css={css(`margin-bottom: 50px`)}
          data={equipment as never}
          loading={loading}
          columns={EquipColumns(this)}
          canSelectRows={false}
          canShowAdd={false}
          title={'Equipment'}
          variable={'equipment'}
          allOpts={allEquip as never}
          totalTitle={(count: number) => `Total Equipment: ${count}`}
          blankText={`No ${getFieldValue(
            'equipType'
          )}s are available to be added to a tandem group at this location. `}
          subtitle={
            // tslint:disable-next-line: max-line-length
            'Select up to 4 pieces of equipment to add to the tandem group. The first device you select will be designated as the central device, but that can be changed if desired. The central device coordinates communication between all tandem devices. If any other device goes down the tandem group will self-heal, but if the central device goes down the remaining devices will transition to standalone mode and continue running without intelligent tandem functionality. If one device is newer it is recommended to use that as the central device.'
          }
        />
      </div>
    );
  };

  renderStatus = (props: {
    equipment: Equipment;
    loading: boolean;
    sState: AppState;
  }) => {
    const { loading, equipment } = props;

    const deviceState = getDeviceStatusFromEquip(equipment);
    const deviceStatus: string = deviceState.deviceStatus;
    const opt: {
      [key: string]: { color: string; label: string; canDisplay?: boolean };
    } = {
      loading: { color: colors.statusOff, label: 'loading' },
      off: { color: colors.statusOff, label: 'Offline' },
      standby: { color: colors.statusStandby, label: 'Standby' },
      on: { color: colors.statusOn, label: 'On' },
    };
    const renderLabel = (option: { label: string; color: string }) => {
      return (
        <div
          key={option.label}
          css={css(cardStyles.cardStatus, { backgroundColor: option.color })}
        >
          {option.label}
          {loading && <Icon css={css('margin-left: 2px;')} type="loading" />}
        </div>
      );
    };

    return loading ? (
      <div css={css(cardStyles.statusContainer)}>
        {loading ? renderLabel(opt.loading) : renderLabel(opt[deviceStatus])}
      </div>
    ) : (
      '--'
    );
  };

  render() {
    const {
      back,
      tandemGroup,
      toDisplay,
      loading,
      error,
      isAdd,
      equip,
      model,
      showSideBar,
      selectedEquipment,
    } = this.state;
    const { sState } = this.props;
    const ispb = isPB(sState);
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props;
    const showEquipTable =
      (!!getFieldValue('locationId') && !!getFieldValue('equipType')) ||
      !!this.state.selectedEquipment.length;
    const config = getEquipConfig(equip, sState);
    return (
      <div css={css(styles.container)}>
        {!!back && (
          <Link
            css={css`
              margin-bottom: 5px;
            `}
            onClick={this.goBack}
          >
            {back}
          </Link>
        )}
        <div
          css={css(
            styles.container,
            !ispb && `flex-direction: row;`,
            ispb && 'margin-bottom: 0px;'
          )}
        >
          {!ispb && showSideBar && (
            <Sidebar
              equipment={(equip as unknown) as Equipment}
              model={model}
              config={config}
              goToBillingHistory={this.goToBillingHistory}
            />
          )}
          {tandemGroup ? (
            <div css={css(styles.mainContainer)}>
              <FormTitle
                size={'20px'}
                title={
                  !!back && isAdd ? 'Add Tandem Group' : 'Edit Tandem Group'
                }
              />

              {error && (
                <Alert
                  css={css(sharedStyles.formAlert)}
                  type="error"
                  message={error}
                  closable
                  onClose={() => this.setState({ error: null })}
                />
              )}

              <Form layout="vertical" onSubmit={this.submit}>
                <div css={css(sharedStyles.formContainer)}>
                  {toDisplay.map((item, i) => {
                    const canDisplay = item.canDisplay
                      ? item.canDisplay()
                      : true;
                    const disabled = item.disabled ? item.disabled() : false;
                    const itemOptions = item.options;
                    const onChange = item.onChange ? item.onChange : undefined;
                    return !canDisplay ? null : (
                      <Form.Item key={i} label={item.title}>
                        {getFieldDecorator(item.var, itemOptions)(
                          item.type === 'dropdown' && item.opts ? (
                            <Select
                              onChange={onChange}
                              placeholder="Select"
                              disabled={disabled}
                            >
                              {item.opts().map((opt: Opt, i: number) => (
                                <Select.Option key={i} value={opt.value}>
                                  {opt.label}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : item.type === 'datepicker' ? (
                            <DatePicker />
                          ) : (
                            <Input />
                          )
                        )}
                      </Form.Item>
                    );
                  })}
                </div>

                {showEquipTable && this.renderEquipTable()}

                <Button
                  title={'Cancel'}
                  disabled={loading}
                  css={css`
                    margin-right: 10px;
                  `}
                  outline={true}
                  onClick={this.goBack}
                />
                <Button
                  title={'Save'}
                  loading={loading}
                  onClick={this.submit}
                />
              </Form>
            </div>
          ) : (
            <div>No Tandem Group Provided</div>
          )}
        </div>
      </div>
    );
  }
}

export const EditTandemGroupComponent = Form.create()(
  withRouter(EditTandemGroup)
);
