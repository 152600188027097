/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import { Component } from 'react';
import colors from '../colors';

interface IProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  css?: SerializedStyles;
  className?: string;
}

export default class Link extends Component<IProps> {
  render() {
    const { onClick, children } = this.props;
    return (
      <div
        className={this.props.className}
        css={css(
          `
          color: ${colors.highlight};
          font-size: 12px;
          cursor: pointer;
        `,
          this.props.css
        )}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
}
