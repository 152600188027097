import { guid } from '../../utils';

export type StartStop = number[];

export interface Sched {
  [key: string]: StartStop[];
  mon: StartStop[];
  tue: StartStop[];
  wed: StartStop[];
  thu: StartStop[];
  fri: StartStop[];
  sat: StartStop[];
  sun: StartStop[];
}

export interface Schedule {
  [key: string]: string | number | undefined | Sched;
  id: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  orgId?: string;
  equipmentId?: string;
  deviceId?: string;
  sched: Sched;
}

export class _Schedule {
  constructor(props?: Schedule) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined | Sched;
  id = guid();
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  orgId = '';
  equipmentId = '';
  deviceId = '';
  sched = {
    mon: [] as StartStop[],
    tue: [] as StartStop[],
    wed: [] as StartStop[],
    thu: [] as StartStop[],
    fri: [] as StartStop[],
    sat: [] as StartStop[],
    sun: [] as StartStop[],
  };
}
