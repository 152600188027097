// tslint:disable:max-line-length
import { createSensor } from '../services/manage-sensors.service';
import { Sensor } from '../interfaces/sensor';
import { createModel, deleteModel } from '../services/manage-models.service';
import { Model } from '../interfaces/model';

export const hydrateSensors = () => {
  const createOne = async (s: Sensor) => {
    try {
      await createSensor(s);
    } catch (err) {
      console.error(err, err.message);
    }
  };

  const sensors = [
    {
      name: 'Motor 1 Temp',
      shortName: 'M1T',
      description: 'Temperature of the compressor head',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'F',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 2 Temp',
      shortName: 'M2T',
      description: 'Temperature of the compressor head',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'F',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 3 Temp',
      shortName: 'M3T',
      description: 'Temperature of the compressor head',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'F',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 1 Power',
      shortName: 'M1P',
      description: 'Power used by the compressor head',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'W',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 2 Power',
      shortName: 'M2P',
      description: 'Power used by the compressor head',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'W',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 3 Power',
      shortName: 'M3P',
      description: 'Power used by the compressor head',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'W',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 1 Runtime',
      shortName: 'M1R',
      description: 'Number of hours that the compressor head has been running',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'hrs',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 2 Runtime',
      shortName: 'M2R',
      description: 'Number of hours that the compressor head has been running',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'hrs',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Motor 3 Runtime',
      shortName: 'M3R',
      description: 'Number of hours that the compressor head has been running',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'hrs',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Tank Pressure',
      shortName: 'TP',
      description: 'Air pressure in the compressor tank',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'psi',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Tank Pressure (HIGH)',
      shortName: 'TPH',
      description: 'Air pressure in the compressor tank',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'psi',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Tank Temp',
      shortName: 'TT',
      description: 'Temperature of the air in the compressor tank',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'F',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Tank Humidity',
      shortName: 'TH',
      description: 'Humidity of the air in the compressor tank',
      isCompressor: 1,
      isVacuum: 0,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Ambient Temp',
      shortName: 'AT',
      description: 'Temperature of the ambient air in the equipment room',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'F',
      isChair: 1,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Ambient Humidity',
      shortName: 'AH',
      description: 'Humidity of the ambient air in the equipment room',
      isCompressor: 1,
      isVacuum: 1,
      suffix: '%',
      isChair: 1,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Voltage Line',
      shortName: 'VL',
      description: 'Voltage supplied to the compressor from the building',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'V',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'H2O in Oil',
      shortName: 'WO',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Oil Level',
      shortName: 'OL',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'H2O in Pump Outlet',
      shortName: 'WP',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Inlet Pressure',
      shortName: 'IP',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: 'psi',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Pump Temp',
      shortName: 'PT',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: 'F',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Equipment Runtime',
      shortName: 'ER',
      description: 'Number of hours that the compressor has been on',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'hrs',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Pump Runtime',
      shortName: 'PR',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: 'hrs',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Pressure Dew Point',
      shortName: 'PDP',
      description:
        'Pressure dew point of the air in the compressor tank. The pressure dew point shows the temperature that the air would need to be for dew to form.',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'F',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Altimeter',
      shortName: 'ALT',
      description:
        'Approximate altitude of the compressor. This is used to optimize the efficiency of the compressor',
      isCompressor: 1,
      isVacuum: 1,
      suffix: 'ft',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Drier Efficiency',
      shortName: 'DE',
      description:
        'The drier efficiency shows how well the drier is pulling moisture out of the ambient air. As the efficiency goes down more air will be lost to keep the tank air dry.',
      isCompressor: 1,
      isVacuum: 0,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Regen Time',
      shortName: 'RGT',
      description:
        'Average number of seconds for the pressure in the tank to pump up from the minimum to the maximum pressure',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'sec',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Power Usage',
      shortName: 'PU',
      description: 'Power usage of the compressor',
      isCompressor: 1,
      isVacuum: 0,
      suffix: 'kWh',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Head Duty Cycle',
      shortName: 'HDC',
      description:
        'Average duty cycle of the heads. This shows the percentage of time that the heads are running.',
      isCompressor: 1,
      isVacuum: 0,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Oil Drip Rate',
      shortName: 'ODR',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: '',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
    {
      name: 'Vacuum Efficiency',
      shortName: 'VE',
      description: 'PLACEHOLDER',
      isCompressor: 0,
      isVacuum: 1,
      suffix: '%',
      isChair: 0,
      isHandpiece: 0,
      isSterilizer: 0,
    },
  ];

  sensors.map(s => {
    const ss = {
      ...s,
      id: s.shortName,
    };

    delete ss.shortName;
    createOne(ss);
  });
};

export const hydrateModels = () => {
  const createOne = async (s: Model) => {
    try {
      await createModel(s);
    } catch (err) {
      console.error(err, err.message);
    }
  };

  const models = [
    {
      isActive: 1,
      type: 'compressor',
      family: 'Osprey',
      name: 'Osprey 13S',
      modelNumber: 'OSP13S',
      image: 'Osprey13S.png',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Osprey',
      name: 'Osprey 22S',
      modelNumber: 'OSP22S',
      image: 'Osprey_22S_hr2_2019.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Osprey',
      name: 'Osprey 23S',
      modelNumber: 'OSP23S',
      image: 'Osprey23S.png',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Osprey',
      name: 'Osprey 24S',
      modelNumber: 'OSP24S',
      image: 'Osprey_24S_hr_2019.jpg',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Osprey',
      name: 'Osprey 25S',
      modelNumber: 'OSP25S',
      image: 'Osprey_25S_hr_2019.jpg',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Osprey',
      name: 'Osprey 28S',
      modelNumber: 'OSP28S',
      image: 'Osprey_28S_hr2_2019.jpg',
      heads: 3,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Badger LF',
      modelNumber: 'BADGLF2',
      image: 'Badger-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bulldog',
      modelNumber: 'BDT4',
      image: 'Badger-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B3T5',
      image: 'Bulldog-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B5T7',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B5T7-3',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B5T7-3-460',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B7T10',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B7T10-3',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B7T10-3-460',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B9T12-3',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Osprey',
      name: 'Bison',
      modelNumber: 'B9T12-3-460',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
  ];
  models.map(m => {
    const mm = {
      ...m,
      id: m.modelNumber,
    };

    delete mm.modelNumber;

    createOne(mm);
  });
};

export const hydrateModels2 = () => {
  const createOne = async (s: Model) => {
    try {
      await createModel(s);
    } catch (err) {
      console.error(err, err.message);
    }
  };

  const deleteOne = async (s: Model) => {
    try {
      await deleteModel(s);
    } catch (err) {
      console.error(err, err.message);
    }
  };

  const todelete = [
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      name: 'Badger LF',
      modelNumber: 'BADGLF2',
      image: 'Badger-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: '',
      name: 'Bison',
      modelNumber: 'B5T7-3',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: '',
      name: 'Bison',
      modelNumber: 'B5T7-3-460',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: '',
      name: 'Bison',
      modelNumber: 'B7T10-3',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: '',
      name: 'Bison',
      modelNumber: 'B7T10-3-460',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: '',
      name: 'Bison',
      modelNumber: 'B9T12-3',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: '',
      name: 'Bison',
      modelNumber: 'B9T12-3-460',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
  ];

  const models = [
    {
      isActive: 1,
      type: 'compressor',
      family: 'Aeras',
      name: 'Aeras 4 Compressor',
      description: 'AERAS 4 COMPRESSOR - 4 USER/115V',
      modelNumber: 'C4-1',
      oldModel: 'OSP13S',
      image: 'Osprey13S.png',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Aeras',
      description: 'AERAS 3 COMPRESSOR - 3 USER/230V',
      name: 'Aeras 3 Compressor',
      modelNumber: 'C3-2',
      oldModel: 'OSP22S',
      image: 'Osprey_22S_hr2_2019.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Aeras',
      description: 'AERAS 4 COMPRESSOR - 4 USER/230V',
      name: 'Aeras 4 Compressor',
      modelNumber: 'C4-2',
      oldModel: 'OSP23S',
      image: 'Osprey23S.png',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Aeras',
      description: 'AERAS 6 COMPRESSOR - 6 USER/230V',
      name: 'Aeras 6 Compressor',
      modelNumber: 'C6-2',
      oldModel: 'OSP24S',
      image: 'Osprey_24S_hr_2019.jpg',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Aeras',
      description: 'AERAS 7 COMPRESSOR - 7 USER/230V',
      name: 'Aeras 7 Compressor',
      modelNumber: 'C7-2',
      oldModel: 'OSP25S',
      image: 'Osprey_25S_hr_2019.jpg',
      heads: 2,
    },
    {
      isActive: 1,
      type: 'compressor',
      family: 'Aeras',
      description: 'AERAS 11 COMPRESSOR - 11 USER/230V',
      name: 'Aeras 11 Compressor',
      modelNumber: 'C11-2',
      oldModel: 'OSP28S',
      image: 'Osprey_28S_hr2_2019.jpg',
      heads: 3,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: 'AERAS 4 DRY VACUUM ONLY - 4 USER/115V & 230V SINGLE PHASE',
      name: 'Aeras 4 Dry Vacuum',
      modelNumber: 'RV4-12',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: 'AERAS 5 DRY VACUUM ONLY - 5 USER/230V SINGLE PHASE',
      name: 'Aeras 5 Dry Vacuum',
      modelNumber: 'RV5-2',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: 'AERAS 7 DRY VACUUM ONLY - 7 USER/230V SINGLE PHASE',
      name: 'Aeras 7 Dry Vacuum',
      modelNumber: 'RV7-2',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
    {
      isActive: 1,
      type: 'vacuum',
      family: 'Aeras',
      description: 'AERAS 10 DRY VACUUM ONLY - 10 USER/230V SINGLE PHASE',
      name: 'Aeras 10 Dry Vacuum',
      modelNumber: 'RV10-2',
      image: 'Bison-hr.jpg',
      heads: 1,
    },
  ];

  models.map(m => {
    const mm = {
      ...m,
      id: m.modelNumber,
    };

    if (m.oldModel) {
      todelete.push({
        ...m,
        modelNumber: m.oldModel,
      });
    }

    delete mm.modelNumber;

    // createOne(mm);
  });

  todelete.map(m => {
    const mm = {
      ...m,
      id: m.modelNumber,
    };

    delete mm.modelNumber;

    deleteOne(mm);
  });
};
