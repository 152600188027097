/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Dropdown, Menu, Icon } from 'antd';
import { ellipsis as styles } from './header.styles';
import { RouteComponentProps } from 'react-router';
import { DashRoute, DashIcon } from '../../_shared/interfaces/dashroute';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { getNameFromState } from '../../utils';
import { SvgIcon } from '../../_shared/icon';
import SMStyles from '../side-menu/side-menu.styles';

interface IProps extends RouteComponentProps {
  sState: AppState;
  routes: DashRoute[];
  changeRoute: (route: DashRoute) => void;
}

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1284029_o1wb277rbnn.js',
});

export class _Ellipsis extends Component<IProps> {
  renderIcon = (route: DashRoute) => {
    const { icon } = route;

    const props = ({
      theme: 'outlined',
      ...icon,
      css: icon && icon.css ? css(icon.css) : null,
    } as unknown) as DashIcon;

    if (props.lib === 'fontawesome') {
      return (
        <span css={css(SMStyles.faContainer)}>
          <i {...props} className={`far fa-${props.type}`} />
        </span>
      );
    } else if (props.lib === 'iconfont') {
      return <IconFont {...props} />;
    } else if (props.lib === 'SvgIcon') {
      props.css = (css('width: 14px;') as unknown) as string;
      return <SvgIcon {...props} />;
    }
    return <Icon {...props} />;
  };
  renderMenu = () => {
    const { changeRoute, routes } = this.props;

    return (
      <Menu css={css(styles.dropContainer)}>
        {routes.map((route, i) => {
          return (
            <Menu.Item
              key={i}
              css={css(styles.menuItem)}
              onClick={route.onClick ? route.onClick : () => changeRoute(route)}
            >
              {route.icon && (
                <span css={css(styles.menuIcon)}>{this.renderIcon(route)}</span>
              )}
              {route.title}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  render() {
    const { sState } = this.props;
    const menu = this.renderMenu();

    return (
      <div css={css(styles.container)}>
        <div>{getNameFromState(sState)}</div>
        <Dropdown overlay={menu} trigger={['click']}>
          <a css={css(styles.a)} className="ant-dropdown-link" href="#">
            <Icon type="ellipsis" />
          </a>
        </Dropdown>
      </div>
    );
  }
}

export const Ellipsis = connect((state: AppState) => ({ sState: state }))(
  _Ellipsis
);
