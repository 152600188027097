/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import { EQ_TYPE } from '../../../../_shared/interfaces/equipment';
import _ from 'lodash';
import { BaseDzSensorStatsDataForm } from './base-sensor-stats-form.component';
import { VacuumSensorStatsDataForm } from './vacuum-sensor-stats-form.component';
import { CompressorSensorStatsDataForm } from './compressor-sensor-stats-form.component';
import {
  SensorStatisticsPublishData,
  BaseSensorStatisticsPData,
  VacuumSensorStatisticsPData,
  CompressorSensorStatisticsPData,
} from '../../../../_shared/interfaces/publishData';

type Payload = SensorStatisticsPublishData;

interface IProps {
  equipmentType: EQ_TYPE;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const DzSensorStatisticsDataForm = (props: IProps): JSX.Element => {
  const { equipmentType, validChanged, payloadChanged } = props;

  const [baseDataValid, setBaseDataIsValid] = useState<boolean>(false);
  const [vacuumDataValid, setVacuumDataIsValid] = useState<boolean>(false);
  const [compressorDataValid, setCompressorDataIsValid] = useState<boolean>(
    false
  );
  const [baseData, setBaseData] = useState<
    BaseSensorStatisticsPData | undefined
  >(undefined);
  const [vacuumData, setVacuumData] = useState<
    VacuumSensorStatisticsPData | undefined
  >(undefined);
  const [compressorData, setCompressorData] = useState<
    CompressorSensorStatisticsPData | undefined
  >(undefined);

  //notify parent of (combined) validation change whenever either of our children validation changes
  useEffect(() => {
    let valid = baseDataValid;

    if (valid) {
      if (equipmentType === EQ_TYPE.Vacuum) {
        valid = vacuumDataValid;
      } else if (equipmentType === EQ_TYPE.Compressor) {
        valid = compressorDataValid;
      }
    }

    validChanged(valid);
  }, [baseDataValid, vacuumDataValid, compressorDataValid]);

  //notify parent of (combined) payload change whenever either of our children payload changes
  useEffect(() => {
    if (!!!baseData) {
      payloadChanged(undefined);
      return;
    }

    let data = baseData;
    if (equipmentType === EQ_TYPE.Vacuum) {
      _.merge(data, vacuumData);
    } else if (equipmentType === EQ_TYPE.Compressor) {
      _.merge(data, compressorData);
    }

    payloadChanged(data as Payload);
  }, [baseData, vacuumData, compressorData]);

  return (
    <Fragment>
      <BaseDzSensorStatsDataForm
        validChanged={setBaseDataIsValid}
        payloadChanged={setBaseData}
      />

      {equipmentType === EQ_TYPE.Vacuum && (
        <VacuumSensorStatsDataForm
          validChanged={setVacuumDataIsValid}
          payloadChanged={setVacuumData}
        />
      )}

      {equipmentType === EQ_TYPE.Compressor && (
        <CompressorSensorStatsDataForm
          validChanged={setCompressorDataIsValid}
          payloadChanged={setCompressorData}
        />
      )}
    </Fragment>
  );
};
