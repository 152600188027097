import { get } from 'lodash';
import { AppSetting } from '../interfaces/appsetting';
import { instance } from './instance';

export const getAppSettings = async (where?: {}) => {
  const resp = await instance.get(`/appsetting`, { data: {} });
  return get(resp, 'data.appsetting', {});
};

export const setAppSettings = async (appsetting: AppSetting) => {
  const resp = await instance.post('/appsetting/create', {
    appsetting,
  });
  return get(resp, 'data.appsetting', {});
};
