/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PWReqsMessage, buildErrorMsgFromForm, isLoggedIn, stopProp, testPassword, uppercaseFirst, validPhoneNumber } from '../../utils';
import { Button } from '../../_shared/button';
import { Row } from '../../_shared/row';
import { AppState } from '../../app.state';
import { Login } from '../../authentication';
import { Form } from '../../_shared/form';
import { FormComponentProps } from 'antd/lib/form';
import { get, unset } from 'lodash';
import { Alert, message } from 'antd';
import styles from '../styles';
import { User, _User } from '../../_shared/interfaces/user';
import { validateCode } from '../../_shared/services/manage-users.service';
import { newSignUp, signIn } from '../../authentication/authentication.actions';
import Logo from '../../_shared/logo';

interface IProps {
  onStateChange?: (state: any) => void;
  renderLoadedEquipment: (s?: {}) => ReactNode;
}

export const Auth = (props: IProps) => {
  const [state, setState] = useState({
    showSignup: false,
    showVerify: false,
    loading: false,
  })

  const [error, setError] = useState();

  const [signup, setSignUp] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    work: 'dentalPractice',
    acceptTerms: false,
  })

  const [verify, setVerify] = useState({
    pin: '',
  })

  const formRef = useRef();

  const loggedIn = useSelector(s => isLoggedIn(s as AppState));
  const dispatch = useDispatch();

  const toggleSignUp = () => {
    setState(s => ({ ...s, showSignup: !s.showSignup, }))
  }

  const goToStart = () => {
    setState(s => ({ ...s, showSignup: false, showVerify: false }))
  }

  const onSubmit = (e: any, form: any) => {
    stopProp(e);

    return new Promise<void>((resolve, reject) => {
      setState(s => ({ ...s, loading: true }))
      setError(undefined)

      const finalize = (err?: any) => {
        setState(s => ({ ...s, loading: false }))

        if (!!err) {
          setError(err.message);
          reject(err);
        } else {
          resolve()
        }
      }

      (form || formRef.current).validateFieldsAndScroll(async (err: any, vals: any) => {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          return finalize(new Error(error));
        }
  
        try {
          const test = testPassword(vals.password);

          if (!test) {
            return finalize(new Error(PWReqsMessage));
          }

          if (!vals.acceptTerms) {
            return finalize(new Error('You must accept the Terms of Service to continue.'));
          }

          const user = new _User(vals) as User;
          ['password'].map(v => unset(user, v))
          
          user.email = user.email.toLowerCase();
          user.userId = user.email;
          user.notification.alerts = ['email'];
          user.notification.service = ['email'];
          user.role = 2;
          user.createdBy = user.email;
          user.updatedBy = user.email;
          user.isVerified = false;

          await dispatch(newSignUp(user, vals.password));
          await dispatch(signIn(user.email, vals.password, true, true, true))

          setState(s => ({ ...s, showSignup: false, showVerify: true }));

          finalize();
        } catch (err) {
          finalize(err);
        }
      })
    })
  }

  const onSubmitVerify = (e: any, form: any) => {
    stopProp(e);

    return new Promise<void>((resolve, reject) => {
      setState(s => ({ ...s, loading: true }))
      setError(undefined)

      const finalize = (err?: any) => {
        setState(s => ({ ...s, loading: false }))

        if (!!err) {
          setError(err.message);
          reject(err);
        } else {
          resolve()
        }
      }

      (form || formRef.current).validateFieldsAndScroll(async (err: any, vals: any) => {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          return finalize(new Error(error));
        }
  
        try {
          const email = signup.email.toLowerCase();
          const resp = await validateCode(email, vals.pin);

          const hasError = get(resp, 'error');
          if (!!hasError) {
            return finalize(new Error(hasError));
          }

          await dispatch(signIn(email, signup.password, false, true));

          message.success(`You have signed up successfully.`);

          finalize();
        } catch (err) {
          finalize(err);
        }
      })
    })
  }

  useEffect(() => {
    props.onStateChange && props.onStateChange(state);
  }, [state]);

  if (loggedIn) { return null }

  if (state.showSignup) {
    const formProps = {
      ref: formRef,
      defaults: signup,
      onChange: (e: any, f: any) => setSignUp(f.getFieldsValue()),
      toDisplay: [
        {
          title: 'Name',
          var: 'name',
          transform: (value: string) => uppercaseFirst(value),
          options: {
            rules: [
              {
                required: true,
                message: 'Name is required. ',
              },
              {
                max: 50,
                message: 'Name is limited to 50 characters. ',
              },
            ],
          },
        },
        {
          title: 'Email',
          var: 'email',
          options: {
            validateTrigger: 'onBlur',
            normalize: (value: string) => value && value.trim(),
            rules: [
              {
                type: 'email',
                message: 'The input is not a valid E-mail. ',
              },
              {
                required: true,
                message: 'The input is not a valid E-mail. ',
              },
              {
                max: 60,
                message: 'Email is limited to 60 characters. ',
              },
            ],
          },
        },
        {
          title: 'Password',
          var: 'password',
          type: 'password',
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Password is required ',
              },
            ],
          },
        },
        {
          title: 'Phone',
          var: 'phone',
          required: () => false,
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                // tslint:disable-next-line:no-any
                validator: (rule: any, value: string, cb: any) => {
                  const passes = !value || validPhoneNumber(value);
                  cb(passes ? undefined : rule.message);
                },
                message: 'Phone needs to be a valid phone number. ',
              },
            ],
          },
        },
        {
          title: 'I work at a...',
          var: 'work',
          type: 'dropdown',
          opts: () => {
            return [
              { title: 'Dental Practice', value: 'dentalPractice' },
              { title: 'Dental School', value: 'dentalSchool' },
              { title: 'Government', value: 'gov' },
              { title: 'Group Dental Practice', value: 'groupDentalPractice' },
              { title: 'DSO', value: 'dso' },
              { title: 'Dental Distributor', value: 'dentalDistributor' },
            ]
          },
          options: {
            rules: [
              {
                required: true,
                message: 'Where you work is required. ',
              },
            ],
          },
        },
        {
          title: 'Terms of Service',
          props: {
            children: (
              <span>
                By checking this box, you consent to and agree to our{' '}
                  <a
                    href={'https://www.dentalez.com/privacy-policy'}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{' '}
                  and our{' '}
                  <a href={'https://www.dentalez.com/terms'} target="_blank">
                    Terms of Use
                  </a>
                  .
              </span>
            )
          },
          var: 'acceptTerms',
          type: 'checkbox',
          options: {
            rules: [
              {
                required: true,
                message: 'Where you work is required. ',
              },
            ],
          },
        }
      ],
      onSubmit,
    }

    const invalidWork = signup.work === 'dentalDistributor';

    return (
      <div css={css(styles.column)}>
        <Row center style={{ marginBottom: 20, maxWidth: 360 }}><Logo /></Row>
        
        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => setError(undefined)}
          />
        )}

        <div css={css(styles.inputContainer)}>
          {props.renderLoadedEquipment({ margin: -20, marginBottom: 20 })}
          <p css={css(styles.title)}>New User Sign Up</p>
          <p css={css(styles.subTitle, `font-style: italic;`)}>Fill out the form below to create your new user account.  You will use this account to register and monitor your equipment.</p>
          <Form {...formProps as unknown as FormComponentProps} />
          {invalidWork && <Alert style={{ marginBottom: 10 }} type="error" message="Sign Up for dental distributor representatives is not available via this form.  Please contact DentalEZ for assistance:  123-456-7890" />}
          <Row center>
            <Button title="Back to Sign In" outline onClick={goToStart} />
            <Button disabled={!!invalidWork || state.loading} loading={state.loading} title="Submit" style={{ marginLeft: 10 }} onClick={e => onSubmit(e, get(formRef, 'current.form'))} />
          </Row>
        </div>
      </div>
    )
  }

  if (state.showVerify) {
    const formProps = {
      ref: formRef,
      defaults: verify,
      toDisplay: [
        {
          title: 'Verification code',
          var: 'pin',
          options: {
            rules: [
              {
                required: true,
                message: 'Verification is required. ',
              },
            ],
          },
        },
      ],
      onSubmit: onSubmitVerify,
    }

    return (
      <div css={css(styles.column)}>
        <Row center style={{ marginBottom: 20, maxWidth: 360 }}><Logo /></Row>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => setError(undefined)}
          />
        )}

        <div css={css(styles.inputContainer)}>
          {props.renderLoadedEquipment({ margin: -20, marginBottom: 20 })}
          <p css={css(styles.title)}>New User Sign Up</p>
          <p css={css(styles.subTitle, `font-style: italic;`)}>A validation code has been sent to your email. Please enter it to verify your email address and continue.</p>
          <Form {...formProps as unknown as FormComponentProps} />
          <Row center>
            <Button title="Back to Sign In" outline onClick={goToStart} />
            <Button disabled={state.loading} loading={state.loading} title="Submit" style={{ marginLeft: 10 }} onClick={e => onSubmitVerify(e, formRef.current)} />
          </Row>
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Login {...{ destination: '/eqRegistration', inputContainer: () => props.renderLoadedEquipment({ margin: -30, marginBottom: 30 }) }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ marginBottom: 5, textAlign: 'center' }}>First time registering equipment? Sign Up for an account.</div>
          <Button title="Sign Up" onClick={toggleSignUp} />
        </div>
      </Login>
    </div>
  )
}