import { connect } from 'react-redux';
import { ManageOrgComponent } from './manage-org.component';
import { AppState } from '../../app.state';
import { retrieveData } from '../dashboard.actions';

export const ManageOrg = connect((sState: AppState) => ({ sState }), {
  retrieveData,
})(
  ManageOrgComponent
);
