import { connect } from 'react-redux';
import { ManageLocationComponent } from './manage-location.component';
import { AppState } from '../../app.state';
import { retrieveData } from '../dashboard.actions';

export const ManageLocation = connect(
  (sState: AppState) => ({ sState }),
  {
    retrieveData,
  }
)(ManageLocationComponent);
