/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Modal, DatePicker } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { MultiSelectSearch } from '../multi-select-search';
import { Equipment } from '../interfaces/equipment';
import colors from '../../_shared/colors';
import { get } from 'lodash';
import { findActiveSubscription, hasSubHistory } from '../../utils';

interface IProps extends ModalProps {
  // tslint:disable-next-line:no-any
  searchOpts: any[];
  placeholder?: string;
  onCancel?: () => void;
  onResult: (result: { tags: string[] }) => void;
  equipMap?: {};
  selectedEquipLimit?: number;
}

export class ModalMultiSelectSearch extends Component<IProps> {
  state = { tags: [], loading: false };
  MultiSearch?: MultiSelectSearch;
  onOk = async () => {
    const { tags } = this.state;
    const { onResult } = this.props;

    this.setState({ loading: true });

    await onResult({
      tags,
    });

    this.setState({ loading: false });
    this.clearTags();
  };
  clearTags = () => {
    this.setState({ tags: [] });
    if (this.MultiSearch) {
      this.MultiSearch.clearTags();
    }
  };
  onCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
    this.clearTags();
  };
  onTagsChange = (tags: string[]) => {
    if (this.props.selectedEquipLimit) {
      if (tags.length <= this.props.selectedEquipLimit) {
        return this.setState({ tags });
      }
      return;
    }
    this.setState({ tags });
  };
  render() {
    const { loading } = this.state;
    const { visible, title, searchOpts, placeholder, equipMap } = this.props;
    let equips: Equipment[] = [];
    if (equipMap) {
      equips =
        equipMap &&
        this.state.tags.map(tag => {
          return equipMap[tag];
        });
    }
    const limitReached = this.props.selectedEquipLimit
      ? this.state.tags.length >= this.props.selectedEquipLimit
      : false;

    return (
      <Modal
        centered
        title={title}
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        confirmLoading={loading}
      >
        <MultiSelectSearch
          ref={comp => (this.MultiSearch = comp)}
          placeholder={placeholder}
          dataSource={searchOpts}
          onTagsChange={this.onTagsChange}
          limitReached={limitReached}
        />
        {limitReached && (
          <p css={css(`color: ${colors.warning}`)}>
            You have reached the maximum number of equipment for this group.
          </p>
        )}
      </Modal>
    );
  }
}
