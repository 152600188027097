import colors from '../../_shared/colors';

export default {
  // DEN-97
  // wow big bad hack. -- ContentContainer applies a padding of 20 I don't want to remove
  // but there's a faux sidebar in here that needs to bump up agaist all the edges,
  // less destructive to just move everything -ContentContainer.padding here which at the
  // time of writing is 20
  pageContainer: `
    margin: -20px
  `,
  sideBar: `
    background-color: ${colors.white};
    border-right: 2px solid ${colors.inputBorder};
  `,
  successToast: `
    padding: 20px;
  `,
  controlsContainer: `
    display: flex;
    flex-direction: row;
    paddingTop: 20px;
    marginTop: 50px;
  `,
  // DEN-971 reapplying that content padding here!
  presetsContainer: `
    padding: 20px;
    flex: 1;
  `,
  presetTypeToggle: `
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  dropDownSelections: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  `,
  dropDownItem: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  `,
  dropDownLabel: `
    font-weight: bold;
    font-size: 12px
  `,
  dropDownSelect: `
    margin-left: 10px;
    width: 150px;
  `,
  table: `
    margin-top: 20px;
  `,
  nameControls: `
    margin-left: 15px;
    display: inline;
  `,
};
