/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { DashRoute, DashIcon } from '../../_shared/interfaces/dashroute';
import styles from './side-menu.styles';
import { SvgIcon } from '../../_shared/icon';

interface IProps extends RouteComponentProps {
  routes: DashRoute[];
  currentRoute?: DashRoute;
  changeRoute: (route: DashRoute) => void;
}

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1284029_o1wb277rbnn.js',
});

export class SideMenuComponent extends Component<IProps> {
  renderIcon = (route: DashRoute) => {
    const { icon } = route;

    const props = ({
      theme: 'outlined',
      ...icon,
      css: icon && icon.css ? css(icon.css) : null,
      width: 14,
      height: 14,
    } as unknown) as DashIcon;

    if (props.lib === 'fontawesome') {
      return (
        <span css={css(styles.faContainer)}>
          <i
            {...props}
            className={`far
           fa-${props.type}`}
          />
        </span>
      );
    } else if (props.lib === 'iconfont') {
      return <IconFont {...props} />;
    } else if (props.lib === 'SvgIcon') {
      return <SvgIcon {...props} />;
    }
    return <Icon {...props} />;
  };
  render() {
    const { routes, changeRoute, currentRoute } = this.props;

    const selectedKeys = routes
      .map(
        (r, i) =>
          currentRoute &&
          currentRoute.highlighted &&
          currentRoute.highlighted === r.path &&
          i.toString()
      )
      .filter(r => !!r) as string[];

    return (
      <Layout.Sider width={200} css={css(styles.container)}>
        <Menu
          mode="inline"
          selectedKeys={selectedKeys.length > 0 ? selectedKeys : ['0']}
          style={{ height: '100%', borderRight: 0 }}
        >
          {routes.map((route, i) => {
            return (
              <Menu.Item key={i} onClick={() => changeRoute(route)}>
                {route.icon && (
                  <span
                    css={css`
                      margin: 0px;
                    `}
                  >
                    {this.renderIcon(route)}
                  </span>
                )}
                {route.title}
              </Menu.Item>
            );
          })}
        </Menu>
      </Layout.Sider>
    );
  }
}

export const SideMenu = withRouter(SideMenuComponent);
