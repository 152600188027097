/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, MouseEvent } from 'react';
import { AppState } from '../../app.state';
import { User, _User } from '../../_shared/interfaces/user';
import { Form, Alert, Row, Col, Radio } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import styles from './mfa-settings.styles';
import { Button } from '../../_shared/button';
import { ModalVerify } from '../../_shared/modal-verify';
import { get, set } from 'lodash';
import { _ModalVerify } from '../../_shared/modal-verify/modal-verify.component';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateUser: (user: User, shouldUpdateCurrent?: boolean) => Promise<User>;
}

export class _MFASettings extends Component<IProps> {
  state = {
    loading: false,
    error: null,
    success: null,
    toDisplay: [
      {
        title: 'Multi Factor Authentication',
        var: 'mfa',
        vals: ['email', 'sms'],
      },
    ],
  };

  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }
    const me = get(this.props, 'sState.auth.user');
    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return this.setState({ error: err, loading: false });
      }

      try {
        const {
          sState: { auth },
        } = this.props;

        const ouser = new _User({
          ...auth.user,
        } as User);
        const user = new _User({
          ...auth.user,
        } as User);

        this.state.toDisplay.map(item => {
          const val = values[item.var];
          user[item.var] = val;
        });

        if (user.mfa === 'sms' && ouser.mfa !== 'sms') {
          const mverify = get(this, 'MODALVERIFY');
          const valid =
            mverify &&
            mverify.openModal &&
            (await mverify.openModal({
              phone: user.phone,
            }));
          set(user, 'phone', get(valid, 'phone'));
          set(user, 'phoneVerified', true);
        }
        const shouldUpdateCurrent = me.userId === user.userId;
        await this.props.updateUser(user, shouldUpdateCurrent);
        this.setState({
          loading: false,
          isEdit: false,
          success: 'Data saved successfully!',
        });
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };
  componentDidMount = () => {
    this.setFieldsOriginal();
  };
  setFieldsOriginal = () => {
    const { toDisplay } = this.state;
    const {
      sState: {
        auth: { user },
      },
      form: { setFieldsValue },
    } = this.props;

    const User = new _User({
      ...user,
    } as User);

    if (!User.mfa) {
      User.mfa = 'email';
    }

    toDisplay.map(item => {
      setFieldsValue({ [item.var]: User[item.var] });
    });
  };
  MODALVERIFY: _ModalVerify | undefined;
  render() {
    const {
      sState: {
        auth: { user },
      },
      form: { getFieldDecorator },
    } = this.props;
    const { toDisplay, loading, error, success } = this.state;

    return user ? (
      <div css={css(styles.container)}>
        <FormTitle title={`2 Factor Authentication Preference`} />

        {error && (
          <Alert
            css={css(styles.alert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(styles.alert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <Form layout="vertical" onSubmit={this.submit}>
          <div css={css(styles.colContainer)}>
            <span css={css(styles.col1)}>Notification Type</span>
            <span css={css(styles.col2)}>Email</span>
            <span css={css(styles.col3)}>SMS</span>
          </div>
          {toDisplay.map((item, i) => {
            return (
              <Form.Item key={i}>
                {getFieldDecorator(item.var)(
                  <Radio.Group style={{ width: '100%' }}>
                    <Row>
                      <div css={css(styles.col1)}>
                        <label css={css(styles.label)}>{item.title}</label>
                      </div>
                      {item.vals.map((v, _i) => (
                        <Col key={_i} css={css(styles.col2)}>
                          <Radio key={_i} value={v} />
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                )}
              </Form.Item>
            );
          })}

          <Button title={'Save'} loading={loading} onClick={this.submit} />
        </Form>

        <ModalVerify
          wrappedComponentRef={(comp: _ModalVerify) =>
            set(this, 'MODALVERIFY', comp)
          }
        />
      </div>
    ) : (
      <div>No User Provided</div>
    );
  }
}

export const MFASettings = Form.create()(withRouter(_MFASettings));
