/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import SharedStyles from '../styles';
import FormTitle from '../form-title';
import { Icon, Modal } from 'antd';
import { TableList } from '../table-list';
import Link from '../link';
import { ModalMultiSelectSearch } from '../modal-multi-select-search';
import { User } from '../interfaces/user';
import { chain, uniq } from 'lodash';
import { updateUser } from '../services/manage-users.service';
import { Button } from '../button';
import { uppercaseFirst } from '../../utils';
import { ColumnProps } from 'antd/lib/table';

interface IProps<T> {
  data: never[];
  columns: ColumnProps<T>[];
  loading: boolean;
  addBtn: string;
  title: string;
  modalTitle?: string;
  blankText?: string;
  variable: string;
  allOpts: { id: string; name: string }[];
  totalTitle: (val: number) => string;
  user: User;
  onDone: (res?: { error?: string }) => void;
}

interface IState {
  modal: { visible: boolean };
  // tslint:disable-next-line:no-any
  selectedRows: any[];
  searchOpts: string[];
  loading: boolean;
}

export class TableListMulti<T> extends Component<IProps<T>, IState> {
  constructor(props: IProps<T>) {
    super(props);

    this.state = {
      modal: {
        visible: false,
      },
      selectedRows: [],
      searchOpts: this.filterOptsBasedOnUser(),
      loading: false,
    };
  }
  openModal = () => {
    this.setState({
      selectedRows: [],
      modal: {
        ...this.state.modal,
        visible: true,
      },
    });
  };
  finish = (res?: { error?: string }) => {
    const { onDone } = this.props;

    this.closeModal();

    onDone && onDone(res);
  };
  closeModal = () => {
    this.setState({
      loading: false,
      modal: {
        ...this.state.modal,
        visible: false,
      },
    });
  };
  filterOptsBasedOnUser = () => {
    const { allOpts, user, variable } = this.props;
    return allOpts
      .filter(
        opt =>
          ((Array.isArray(user[variable])
            ? user[variable]
            : []) as string[]).indexOf(opt.id) === -1
      )
      .map(opt => opt.name || opt.id);
  };
  selectedRowsChange = (selectedRows: number[] | string[]) => {
    this.setState({
      selectedRows,
    });
  };
  modalResult = async (res: { tags: string[] }) => {
    const { allOpts, user, variable } = this.props;

    try {
      const optMap = chain(allOpts)
        .keyBy('name')
        .value();
      const opts = res.tags.map(t => {
        const o = (optMap[t] as unknown) as { id: string };

        if (o && o.id) {
          return o.id;
        }
      });

      let currentOpts = (user[variable] || []) as string[];
      if (!Array.isArray(currentOpts)) {
        currentOpts = [];
      }

      const u = {
        ...user,
        [variable]: uniq([...(currentOpts || []), ...opts]),
      } as User;

      await updateUser(u);
    } catch (err) {
      return this.finish({ error: err.message });
    }

    this.setState({ selectedRows: [] });
    return this.finish();
  };
  removeItems = async () => {
    const { data, user, variable } = this.props;

    const onOk = async () => {
      if (this.state.loading) {
        return;
      }

      const state = { ...this.state };
      state.loading = true;
      this.setState(state);

      const { selectedRows } = this.state;
      const itemsToRemove = selectedRows.map((sr, i) => {
        const item = Array.isArray(data) ? data[i] : data[sr] as { id: string };
        return item.id;
      });

      const opts = (user[variable] as string[]).filter(
        o => itemsToRemove.indexOf(o) === -1
      );

      const u = {
        ...user,
        [variable]: uniq(opts),
      };

      try {
        await updateUser(u);
      } catch (err) {
        return this.finish({ error: err.message });
      }

      this.setState({ selectedRows: [] });
      return this.finish();
    };

    const onCancel = () => this.closeModal();

    Modal.confirm({
      title: 'Removal Confirmation',
      content: `Are you sure you want to remove the user from these ${uppercaseFirst(
        variable
      )}?`,
      onOk: onOk,
      onCancel: onCancel,
    });
  };
  componentDidUpdate = (prevProps: IProps<T>) => {
    if (prevProps.allOpts !== this.props.allOpts) {
      this.setState({
        searchOpts: this.filterOptsBasedOnUser(),
      });
    }
  };
  render() {
    const { modal, searchOpts, selectedRows } = this.state;

    const {
      data,
      loading,
      columns,
      addBtn,
      totalTitle,
      title,
      variable,
      blankText,
    } = this.props;

    const V = uppercaseFirst(variable);

    const mTitle = `Add ${V} to User`;
    const mPlaceholder = `Add ${V}`;

    const showBlank = !loading && data.length === 0 && blankText;

    return (
      <div>
        <div css={css(SharedStyles.hr)} />

        <div css={css(SharedStyles.row, `height: 30px;`)}>
          <FormTitle
            css={css`
              margin-bottom: 0;
            `}
            title={title}
          />
          {selectedRows.length > 0 && (
            <Button
              css={css`
                font-size: 10px;
                padding: 1px 3px;
                margin-left: 10px;
                line-height: 10px;
                height: 16px;
              `}
              title={`Remove ${V}`}
              onClick={this.removeItems}
            />
          )}
          <Link
            css={css(`
                margin-left: auto;
                font-weight: bold;
              `)}
            onClick={this.openModal}
          >
            <Icon style={{ fontWeight: 'bold' }} type={'plus'} />
            <span>{addBtn}</span>
          </Link>
        </div>
        {!showBlank && (
          <TableList
            columns={columns}
            canSelectRows={true}
            data={data}
            loading={this.state.loading || loading}
            scrollHeight={data.length > 5 ? 180 : undefined}
            showPagination={false}
            onSelectedRowsChange={this.selectedRowsChange}
          />
        )}
        {showBlank && (
          <div
            css={css(
              SharedStyles.row,
              `font-size: 14px;
          font-style: italic;
          margin: 10px 0px;
          justify-content: center;
          alignItems: center;
          text-align: center;
          min-height: 50px;
          padding: 20px;
          `
            )}
          >
            {blankText}
          </div>
        )}
        {!showBlank && (
          <div
            css={css`
              font-size: 12px;
              font-style: italic;
              margin: 10px 0px;
            `}
          >
            {totalTitle(data && data.length ? data.length : 0)}
          </div>
        )}

        <ModalMultiSelectSearch
          title={mTitle}
          placeholder={mPlaceholder}
          visible={modal.visible}
          searchOpts={searchOpts}
          onResult={this.modalResult}
          onCancel={this.closeModal}
        />
      </div>
    );
  }
}
