import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { get, sample, set } from 'lodash';
import { getSerial } from '../../_shared/services/manage-serials.service';
import {
  getEquipments,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import {
  buildMaintenanceCommands,
  buildScheduleCommands,
  cleanCopy,
  getEquipMaint,
  getEquipSchedule,
  isHandpiece,
  wait,
} from '../../utils';
import moment from 'moment';
import { Sched, _Schedule } from '../../_shared/interfaces/schedule';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import { message } from 'antd';
import Bugsnag from '@bugsnag/browser';
import { trackEvent } from '../../_shared/services/analytics.service';
import { awaitAll } from '../../utils/awaitAll';

//returns boolean whether controller is assoc with any equipment.
export const checkEquipForController = (
  controllerSN: string,
  allEquipment: Equipment[]
) => {
  const _controllerSN = controllerSN;
  const equip = allEquipment.find(
    (e: Equipment) => get(e, 'controllerSerial', '') === _controllerSN
  );
  return !!equip ? true : false;
};

//returns equipment with the Controller Serial inputed by user
export const getEquipForContSerial = (
  controllerSN: string,
  allEquipment: Equipment[]
) => {
  return allEquipment.filter(
    (e: Equipment) => get(e, 'controllerSerial', '') === controllerSN
  );
};

export const getAllOtherEquipWithContSN = (
  equipmentSN: string,
  controllerSN: string,
  allEquipment: Equipment[]
) => {
  const equip = allEquipment.filter((equip: Equipment) => {
    return (
      equip.equipmentSN !== equipmentSN &&
      equip.controllerSerial === controllerSN
    );
  });
  return equip;
};

export const checkContAssocWithOtherEquip = (
  controllerSN: string,
  equipmentSN: string,
  allEquipment: Equipment[]
) => {
  const eCS = controllerSN;
  const eSN = equipmentSN;
  const equip = allEquipment.find((equip: Equipment) => {
    return equip.controllerSerial === eCS && equip.equipmentSN !== eSN;
  });
  return !!equip ? true : false;
};

export const checkEquipForEquipSN = (
  equipSN: string,
  allEquipment: Equipment[]
) => {
  const _equipSN = equipSN;
  const equip = allEquipment.find((equip: Equipment) => {});
};

//return equipment with the inputed equipmentSN
export const getEquipmentWithEquipSN = (
  equipmentSN: string,
  allEquipment: Equipment[]
) => {
  const eSN = equipmentSN;
  return allEquipment.find((e: Equipment) => get(e, 'equipmentSN', '') === eSN);
};

export const getAllEquipmentWithEquipSN = (
  equipmentSN: string,
  allEquipment: Equipment[]
) => {
  return allEquipment.filter(
    (e: Equipment) => get(e, 'equipmentSN', '') === equipmentSN
  );
};

//returns true or false whether equipment with inputed equipSN is assoc with any controller
export const equipHasController = (
  equipmentSN: string,
  allEquipment: Equipment[]
) => {
  const _eSN = equipmentSN;
  const equip = allEquipment.find((equip: Equipment) => {
    return _eSN === equip.equipmentSN;
  });
  return !!equip ? true : false;
};

//returns true or false whether equipment exists with inputed contollerSerial and equipSN
export const checkEquipForControllerAndESN = (
  equipmentSN: string,
  controllerSN: string,
  allEquipment: Equipment[]
) => {
  const _eSN = equipmentSN;
  const _eCS = controllerSN;
  const equip = allEquipment.find(
    e => e.equipmentSN === _eSN && e.controllerSN === _eCS
  );
  return !!equip ? true : false;
};

// returns equipment with inputed controllerSerial and equipmentSN
export const getEquipWithControllerandEquipSN = (
  equipSN: string,
  controllerSerial: string,
  allEquipment: Equipment[]
) => {
  const eSN = equipSN;
  const eCS = controllerSerial;
  return allEquipment.find(equip => {
    return equip.equipmentSN === eSN && equip.controllerSerial === eCS;
  });
};

//returns boolean whether equipment is associated with a different controller
export const checkEquipHasDifferentController = (
  equipSN: string,
  controllerSN: string,
  allEquipment: Equipment[]
) => {
  const eSN = equipSN;
  const eCS = controllerSN;
  const equip = allEquipment.find(
    (equip: Equipment) => eSN === equip.equipmentSN
  );
  return get(equip, 'controllerSerial') !== eCS;
};

export const generateTestCommand = (equip: Equipment, eqType: string) => {
  const vals = {
    ...equip,
    model: get(equip, 'modelId'),
    eSerial: get(equip, 'equipmentSN'),
    tSerial: get(equip, 'tankSerial'),
    dSerial: get(equip, 'dryerSerial'),
    mSerial1: get(equip, 'mSerial1'),
    mSerial2: get(equip, 'mSerial2'),
    mSerial3: get(equip, 'mSerial3'),
    pSerial: get(equip, 'pumpSerial'),
    mSerial_motorboard: get(equip, 'mSerial_motorboard'),
  };

  if (eqType === 'handpiece') {
    return `{"cmd":"run_test","equipment_serial":"${vals.eSerial}","motor_serial":"${vals.mSerial_motorboard}"}`;
  } else {
    const _vals = [
      'run_test',
      vals.model,
      vals.eSerial,
      vals.tSerial,
      vals.dSerial,
      vals.mSerial1,
      vals.mSerial2,
      vals.mSerial3,
      vals.pSerial,
    ].filter(f => f);

    return _vals.join(',');
  }
};

export const replaceEqController = async (_opts = {}) => {
  try {
    const opts = {
      replaceDate: moment(),
      equipment: new _Equipment(),
      replaceHistory: true,
      ..._opts,
    };

    const sn = get(opts, 'controllerSerial', '')
      .trim()
      .toUpperCase();

    const controllerSerial = await getSerial(sn);

    if (!controllerSerial) {
      throw new Error(
        // tslint:disable-next-line: max-line-length
        `Controller serial number entered can not be found in the database. Please re-scan or re-enter controller serial number or contact an Admin.`
      );
    }

    //make sure the device is online and we are able to communicate with it
    await sendDeviceCommand(
      controllerSerial.deviceId,
      { arg: 'get_config' },
      { showErrorToast: false },
      isHandpiece(opts.equipment)
    ).catch(() => {
      throw new Error('Unable to communicate with new Aeras controller.');
    });

    const allEquip = await getEquipments(undefined, false);

    const alreadyExists = allEquip.find((e: Equipment) => {
      const esn = get(e, 'equipmentSN', '') || '';
      const isProto =
        esn.includes('0000000') || esn.toLowerCase().indexOf('proto') > -1;

      return (
        !isProto &&
        get(e, 'controllerSerial', '')
          .toString()
          .trim()
          .toUpperCase() === sn
      );
    });

    if (alreadyExists) {
      throw new Error(
        'Serial number is already attached to another piece of Equipment'
      );
    }

    const e = cleanCopy(opts.equipment);

    const replaceDate = moment(opts.replaceDate)
      .toDate()
      .toISOString();

    const prevSerials = get(e, 'previousSerials', []);
    set(e, 'previousSerials', [
      {
        controllerSerial: get(e, 'controllerSerial', '__none__'),
        deviceId: get(e, 'deviceId', '__none__'),
        dateChange: new Date().toISOString(),
        replaceDate,
      },
      ...(Array.isArray(prevSerials) ? prevSerials : []),
    ]);

    set(e, 'controllerSerial', sn);
    set(e, 'deviceId', controllerSerial.deviceId);

    await Promise.all([
      updateEquipment(e),
      updateEquipment({ ...opts.equipment, showOnDash: false, isActive: 0 }),
    ]);

    if (opts.replaceHistory) {
      await transferDeviceHistory(opts.equipment, e.deviceId).catch(err => {
        message.error(err.message);
      });
    }

    return e;
  } catch (err) {
    throw err;
  }
};

export const transferDeviceHistory = async (
  equip: Equipment,
  deviceId: string
) => {
  const store = get(window, 'STORE', { getState: () => {} });
  const sState = store.getState();

  return new Promise<void>(async (resolve, reject) => {
    if (deviceId) {
      try {
        const theSchedule = new _Schedule(getEquipSchedule(equip, sState));
        const schedCmds = buildScheduleCommands(theSchedule.data as Sched);
        await Promise.all(
          schedCmds.map(async cmd => {
            const day = cmd.split(',')[1].toUpperCase();
            try {
              await sendDeviceCommand(
                deviceId,
                { arg: cmd },
                { showErrorToast: true },
                isHandpiece(equip)
              );
              message.success(`${day} Schedule set successfully`, 3);
            } catch (error) {
              message.error(`Unable to set ${day} Schedule`, 3);
            }
          })
        );

        const set_modelCMD = `set_model,${equip.modelId}`;
        try {
          sendDeviceCommand(
            deviceId,
            { arg: set_modelCMD },
            { showErrorToast: true },
            isHandpiece(equip)
          );
          message.success('Equipment model set successfully', 3);
        } catch (error) {
          message.error('Unable to set Equipment model', 3);
        }

        const set_snCMD = `set_sn,${equip.equipmentSN}`;
        try {
          await sendDeviceCommand(
            deviceId,
            { arg: set_snCMD },
            { showErrorToast: true },
            isHandpiece(equip)
          );
          message.success('Equipment serial number set successfully', 3);
        } catch (error) {
          message.error('Unable to set Equipment serial number', 3);
        }

        const install_dateCMD = `install_time,${moment(
          equip.installDate as string
        ).valueOf() / 1000}`;
        try {
          await sendDeviceCommand(
            deviceId,
            { arg: install_dateCMD },
            { showErrorToast: true },
            isHandpiece(equip)
          );
          message.success('Equipment install date set successfully', 3);
        } catch (error) {
          message.error('Unable to set Equipment install date', 3);
        }

        const maint: {} = get(
          sState,
          `dash.equipMap[${equip.deviceId}].maint[0].data`
        );
        const { compRTCMDs, compSNCMDs } = buildMaintenanceCommands(maint);

        await Promise.all(
          compRTCMDs.map(async cmd => {
            const component = cmd.split(',')[1].toUpperCase();
            try {
              await sendDeviceCommand(
                deviceId,
                { arg: cmd },
                { showErrorToast: true },
                isHandpiece(equip)
              );
              message.success(`${component} runtime set successfully`, 3);
            } catch (error) {
              message.error(`Unable to set ${component} runtime`, 3);
            }
          })
        );
        await Promise.all(
          compSNCMDs.map(async cmd => {
            const component = cmd.split(',')[1].toUpperCase();
            try {
              await sendDeviceCommand(
                deviceId,
                { arg: cmd },
                { showErrorToast: true },
                isHandpiece(equip)
              );
              message.success(`${component} serial number set successfully`, 3);
            } catch (error) {
              message.error(`Unable to set ${component} serial number`, 3);
            }
          })
        );
        resolve();
        const commands = [
          ...schedCmds,
          ...compRTCMDs,
          ...compSNCMDs,
          set_modelCMD,
          set_snCMD,
          install_dateCMD,
        ];
        // tslint:disable-next-line:no-console
        console.log(commands);
      } catch (error) {
        reject(error);
      }
    }
  });
};

export const DEVICETIMEOUT = 30000;
const deviceIsOnline = async (opts = {}) => {
  const deviceId = get(opts, 'deviceId');
  const equipment = get(opts, 'equipment');
  //make sure the device is online and we are able to communicate with it
  return await sendDeviceCommand(
    deviceId,
    { arg: 'get_config' },
    { showErrorToast: false, timeout: DEVICETIMEOUT },
    isHandpiece(equipment)
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      throw new Error('Device is offline');
    });
};

const doesntExist = async (opts = {}) => {
  const sn = (get(opts, 'controllerSerial', '') || '').trim().toUpperCase();

  const allEquip = await getEquipments(undefined, false);

  const alreadyExists = allEquip.find((e: Equipment) => {
    const esn = get(e, 'equipmentSN', '') || '';
    const isProto =
      esn.includes('0000000') || esn.toLowerCase().indexOf('proto') > -1;

    return (
      !isProto &&
      (get(e, 'controllerSerial', '') || '')
        .toString()
        .trim()
        .toUpperCase() === sn
    );
  });

  return !alreadyExists;
};

const createNewEquip = async (opts = {}) => {
  const sn = (get(opts, 'controllerSerial', '') || '').trim().toUpperCase();

  const e = cleanCopy(get(opts, 'equipment'));

  const replaceDate = moment(get(opts, 'replaceDate'))
    .toDate()
    .toISOString();

  const prevSerials = get(e, 'previousSerials', []);
  set(e, 'previousSerials', [
    {
      controllerSerial: get(e, 'controllerSerial', '__none__'),
      deviceId: get(e, 'deviceId', '__none__'),
      dateChange: new Date().toISOString(),
      replaceDate,
    },
    ...(Array.isArray(prevSerials) ? prevSerials : []),
  ]);

  set(e, 'controllerSerial', sn);
  set(e, 'deviceId', get(opts, 'deviceId'));

  return await updateEquipment(e);
};

const updateOldEquip = async (opts = {}) => {
  return await updateEquipment({
    ...get(opts, 'equipment', {}),
    showOnDash: false,
    isActive: 0,
  });
};

const updateSchedule = async (opts = {}) => {
  const store = get(window, 'STORE', { getState: () => {} });
  const sState = store.getState();

  const equip = get(opts, 'equipment');
  const deviceId = get(opts, 'deviceId');

  const theSchedule = new _Schedule(getEquipSchedule(equip, sState));
  const schedCmds = buildScheduleCommands(theSchedule.data as Sched);
  const respObj = {
    success: [] as string[],
    errors: [] as string[],
  };
  await awaitAll(
    schedCmds.map(cmd => () => async () => {
      const day = cmd.split(',')[1].toUpperCase();
      try {
        await sendDeviceCommand(
          deviceId,
          { arg: cmd },
          { showErrorToast: false, timeout: DEVICETIMEOUT },
          isHandpiece(equip)
        );
        respObj.success.push(day);
      } catch (error) {
        respObj.errors.push(day);
      }
    }),
    { queueSize: 1 }
  );

  return respObj;
};

const updateModel = async (opts = {}) => {
  const equip = get(opts, 'equipment');
  const deviceId = get(opts, 'deviceId');

  const set_modelCMD = `set_model,${equip.modelId}`;
  return await sendDeviceCommand(
    deviceId,
    { arg: set_modelCMD },
    { showErrorToast: false, timeout: DEVICETIMEOUT },
    isHandpiece(equip)
  );
};

const updateSerial = async (opts = {}) => {
  const equip = get(opts, 'equipment');
  const deviceId = get(opts, 'deviceId');

  const set_snCMD = `set_sn,${equip.equipmentSN}`;
  return await sendDeviceCommand(
    deviceId,
    { arg: set_snCMD },
    { showErrorToast: false, timeout: DEVICETIMEOUT },
    isHandpiece(equip)
  );
};

const updateInstallDate = async (opts = {}) => {
  const equip = get(opts, 'equipment');
  const deviceId = get(opts, 'deviceId');

  const install_dateCMD = `install_time,${parseInt(
    (moment(equip.installDate as string).valueOf() / 1000).toString()
  )}`;
  return await sendDeviceCommand(
    deviceId,
    { arg: install_dateCMD },
    { showErrorToast: false, timeout: DEVICETIMEOUT },
    isHandpiece(equip)
  );
};

const updateRuntime = async (opts = {}) => {
  const store = get(window, 'STORE', { getState: () => {} });
  const sState = store.getState();

  const equip = get(opts, 'equipment');
  const deviceId = get(opts, 'deviceId');

  const maint: {} = get(getEquipMaint(equip, sState), '0.data');
  const { compRTCMDs } = buildMaintenanceCommands(maint);

  const respObj = {
    success: [] as string[],
    errors: [] as string[],
  };
  await awaitAll(
    compRTCMDs.map(cmd => () => async () => {
      const component = cmd.split(',')[1].toUpperCase();
      try {
        await sendDeviceCommand(
          deviceId,
          { arg: cmd },
          { showErrorToast: false, timeout: DEVICETIMEOUT },
          isHandpiece(equip)
        );
        respObj.success.push(component);
      } catch (error) {
        respObj.errors.push(component);
      }
    }),
    { queueSize: 1 }
  );

  return respObj;
};

const updateComponentSerial = async (opts = {}) => {
  const store = get(window, 'STORE', { getState: () => {} });
  const sState = store.getState();

  const equip = get(opts, 'equipment');
  const deviceId = get(opts, 'deviceId');

  const maint: {} = get(getEquipMaint(equip, sState), '0.data');
  const { compSNCMDs } = buildMaintenanceCommands(maint);

  const respObj = {
    success: [] as string[],
    errors: [] as string[],
  };
  await awaitAll(
    compSNCMDs.map(cmd => () => async () => {
      const component = cmd.split(',')[1].toUpperCase();
      try {
        await sendDeviceCommand(
          deviceId,
          { arg: cmd },
          { showErrorToast: false, timeout: DEVICETIMEOUT },
          isHandpiece(equip)
        );
        respObj.success.push(component);
      } catch (error) {
        respObj.errors.push(component);
      }
    }),
    { queueSize: 1 }
  );

  return respObj;
};

const fakeEvent = async (opts = {}) => {
  const isError = sample([0, 1]);
  await wait(500);

  if (!!isError) {
    throw new Error('Some error here');
  }
  return { data: 'stuff' };
};

export const replaceEquipmentController = async (_opts = {}) => {
  const opts = {
    checkSerial: true,
    events: [
      { name: 'deviceIsOnline', killOnFail: true }, //communicate with the device see if it is online
      'doesntExist', //check if the device already exists
      'updateSchedule', //returns as { success: [], errors: [] } on the schedule days updates
      'updateModel', //update the model on the particle equipment device
      'updateSerial', //update the serial on the particle equipment device
      'updateInstallDate', //update the install date on the particle equipment device
      'updateRuntime', //update the runtime on the particle equipment device { success: [], errors: [] }
      'updateComponentSerial', //update the runtime on the particle equipment device components { success: [], errors: [] }
      'createNewEquip', //create the new equipment record
      'updateOldEquip', //update the old eq record so that it doesn't show on the dashboard
    ],
    ..._opts,
    onEventUpdate: (event: string, state: string, data: {}) => {
      trackEvent(event, data);
      get(_opts, 'onEventUpdate', () => 0)(event, state, data);
    },
  };

  const ReplaceMethods = {
    deviceIsOnline,
    doesntExist,
    createNewEquip,
    updateOldEquip,
    updateSchedule,
    updateModel,
    updateSerial,
    updateInstallDate,
    updateRuntime,
    updateComponentSerial,
  };

  const controllerSerial = await getSerial(get(opts, 'controllerSerial'));
  if (!get(controllerSerial, 'deviceId') && opts.checkSerial) {
    throw new Error('Serial number does not exist!');
  }

  if (opts.checkSerial) {
    set(opts, 'deviceId', get(controllerSerial, 'deviceId'));
  }

  let events = [...opts.events];
  let killed = false;
  let useFake = false;

  const results = await awaitAll(
    events.map(event => () => async () => {
      const key = typeof event === 'string' ? event : get(event, 'name');

      let result = { data: null, event: key };

      if (killed) {
        return result;
      }

      try {
        const method = useFake
          ? fakeEvent
          : get(event, 'method', get(ReplaceMethods, key, () => 0));
        opts.onEventUpdate(key, 'start', {});
        result.data = await method(opts);
      } catch (err) {
        set(result, 'error', err);
        Bugsnag.notify(err as Error);
      } finally {
        opts.onEventUpdate(key, 'complete', result);
        if (get(result, 'error') && !!get(event, 'killOnFail')) {
          killed = true;
        }
        return result;
      }
    }),
    { queueSize: 1 }
  );

  trackEvent('replaceController', { results, opts })

  return results;
};
