/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core';
import { ChangeEvent, Component } from 'react';
import { Layout, Menu } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { DashRoute } from '../../_shared/interfaces/dashroute';
import styles, { GlobalInserts } from './header.styles';
import Logo from '../../_shared/logo';
import { Ellipsis } from './ellipsis.component';
import { connect } from 'react-redux';
import { filterEquipment } from '../dashboard.actions';
import { AppState } from '../../app.state';
import { DashEquipFilters } from '../dashboard.reducer';
import { isPB } from '../../utils';
import { Search } from './search.component';
import { get } from 'lodash';

interface IProps extends RouteComponentProps {
  routes: DashRoute[];
  ellipsisRoutes: DashRoute[];
  changeRoute: (route: DashRoute) => void;
  filterEquipment: (filters?: DashEquipFilters) => void;
  sState: AppState;
  showSearch?: boolean;
  searchValue: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export class HeaderComponent extends Component<IProps> {
  logoClick = () => {
    const { sState } = this.props;
    const role = get(sState, 'auth.user.role');

    if (role !== 5) {
      this.props.history.push('/dashboard');
    }
  };

  render() {
    const { routes, changeRoute, ellipsisRoutes, sState } = this.props;
    const isPhoneBreak = isPB(sState);
    const role = get(sState, 'auth.user.role');
    const showSearch = get(this.props, 'showSearch', true);

    return (
      <Layout.Header css={css(styles.container)}>
        <Global styles={GlobalInserts} />
        <Logo
          height={50}
          type="aeras_i"
          style={{ marginLeft: 10, marginRight: 10 }}
          onClick={this.logoClick}
        />
        {!isPhoneBreak && (
          <Menu
            mode="horizontal"
            defaultSelectedKeys={['0']}
            css={css(styles.container)}
          >
            {routes.map((route, i) => {
              return (
                <Menu.Item key={i} onClick={() => changeRoute(route)}>
                  {route.title}
                </Menu.Item>
              );
            })}
          </Menu>
        )}
        {!isPhoneBreak && role !== 5 && showSearch && (
          <Search
            searchValue={this.props.searchValue}
            onChange={this.props.onChange}
          />
        )}
        <Ellipsis {...this.props} routes={ellipsisRoutes} />
      </Layout.Header>
    );
  }
}

export const Header = connect(
  (sState: AppState) => ({ sState }),
  {
    filterEquipment,
  }
)(withRouter(HeaderComponent));
