import colors from '../../_shared/colors';

export default {
  container: `
    flex-direction: column;
    height: auto;
    width: 100%;
  `,
  formContainer: `
    height: auto;
    width: 100%; 
    padding-bottom: 20px;
    max-width: 500px;
  `,
  itemLoading: `
    position: absolute;
    right: 10px;
    top: 2px;
  `,
  subtext: `
    font-size: 10px;
  `,
  failed: `
   color: ${colors.error};
   font-weight: bold;
  `,
  passed: `
   color: ${colors.success};
   font-weight: bold;
  `,
  link: `
    background-color: transparent;
    border-color: transparent;
    color: ${colors.highlight}
  `,
  textContainer: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .imageContainer {
      width: 50%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 100%;
    }

    .text {
      max-width: 800px;
      font-size: 15px;
      
      &.center {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

  `
};
