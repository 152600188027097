/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, MouseEvent } from 'react';
import { Card, Icon } from 'antd';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { cardStyles as styles } from './manage-equipment.styles';
import SharedStyles from '../../_shared/styles';
import colors from '../../_shared/colors';
import { Org } from '../../_shared/interfaces/org';
import { Location } from '../../_shared/interfaces/location';
import { renderLabel } from '../../utils/mixins';
import { get } from 'lodash';
import { Model } from '../../_shared/interfaces/model';
import ModelImage from '../../_shared/model-image';
import { Publish } from '../../_shared/interfaces/publish';
import {
  renderStatus,
  RenderAlert,
  RenderConsignment,
} from './renders.component.';
import { AppState } from '../../app.state';
import {
  isDentalOrgAdmin,
  isServiceOrgAdmin,
  isPB,
  getEquipMaint,
  getTriggeredMaint,
  handpieceIsDueForService,
  isHandpiece,
} from '../../utils';

interface IProps {
  equipment: Equipment;
  onClick: () => void;
  onEditClick: () => void;
  location?: Location;
  serviceOrg?: Org;
  dentalOrg?: Org;
  model?: Model;
  config?: Publish;
  alerts: Publish[];
  alertMap: {};
  loading: boolean;
  sState: AppState;
}

export class EquipCard extends Component<IProps> {
  static defaultProps: IProps;
  editClick = (e: MouseEvent) => {
    const { onEditClick } = this.props;
    e.preventDefault();
    e.stopPropagation();

    onEditClick && onEditClick();
  };
  onClear = async () => {
    this.setState({ modalVisible: false });
  };
  render() {
    const {
      equipment,
      onClick,
      serviceOrg,
      dentalOrg,
      location,
      model,
      alertMap,
      loading,
      sState,
    } = this.props;

    const eq = new _Equipment(equipment);
    const isDOAdmin = isDentalOrgAdmin(sState);
    const isSOAdmin = isServiceOrgAdmin(sState);

    const ispb = isPB(sState);

    var isServiceDue = false;
    var isServiceOverDue = false;
    var timeUntilServiceDueHours = -1;

    if (isHandpiece(equipment) === false) {
      const maint = getEquipMaint(eq, sState, []);
      let timeToService = getTriggeredMaint(maint[0]);

      timeToService.length &&
        timeToService.sort((a, b) => {
          if (a.timeRemaining > b.timeRemaining) return 1;
          if (a.timeRemaining < b.timeRemaining) return -1;
          return 0;
        });
      isServiceDue = !!timeToService.length;
      timeUntilServiceDueHours =
        timeToService.length > 0 ? timeToService[0].timeRemaining : -1;
      isServiceOverDue =
        !!timeToService.length && timeToService[0].timeRemaining < 0;
    } else {
      const [due, hours] = handpieceIsDueForService(sState, equipment, []);
      isServiceDue = due;
      timeUntilServiceDueHours = 500 - hours;
      isServiceOverDue = timeUntilServiceDueHours < 0;
    }

    return (
      <div css={css(styles.container, ispb && 'width: 100%;')}>
        <Card
          onClick={onClick}
          css={css(styles.card, eq.alert > 0 && styles.cardOutline)}
          hoverable
          cover={
            <div css={css(SharedStyles.row, styles.imageHolder)}>
              <Icon
                onClick={this.editClick}
                type={'edit'}
                css={css(styles.icon, `color: ${colors.highlight}`)}
              />
              <ModelImage css={css(styles.image)} model={model} />
            </div>
          }
        >
          <div>
            <div css={css(styles.cardText)}>
              <div css={css(SharedStyles.row)}>
                {renderLabel('name', {
                  styles: styles.cardTitle,
                  get: val => val || 'None',
                  obj: eq,
                })}
                <RenderAlert equipment={equipment} />
                <RenderConsignment equipment={equipment} />
              </div>
              <p>&nbsp;</p>
              {renderLabel('modelName', {
                obj: eq,
                get: _val =>
                  get(model, 'name', get(model, 'id', 'Osprey Compressor')),
              })}
              {!isDOAdmin &&
                !isSOAdmin &&
                renderLabel('serviceOrgId', {
                  obj: eq,
                  prefix: 'Service Org: ',
                  get: (_val: string) => get(serviceOrg, 'name'),
                })}
              {!isDOAdmin &&
                renderLabel('dentalOrgId', {
                  obj: eq,
                  prefix: 'Dental Org: ',
                  get: (_val: string) => get(dentalOrg, 'name'),
                })}
              {renderLabel('locationId', {
                obj: eq,
                prefix: 'Location: ',
                get: (_val: string) => get(location, 'name'),
              })}
              {isServiceOverDue ? (
                <p css={css(styles.serviceOverdueText)}>Service Overdue</p>
              ) : isServiceDue && timeUntilServiceDueHours > 0 ? (
                <p>{`Service Due: ${timeUntilServiceDueHours} hours`}</p>
              ) : (
                <br />
              )}
            </div>

            {renderStatus({ equipment, loading, sState })}
          </div>
        </Card>
      </div>
    );
  }
}

EquipCard.defaultProps = {
  equipment: {} as Equipment,
  onClick: () => 0,
  onEditClick: () => 0,
  locations: {},
  orgs: {},
  alerts: [] as Publish[],
  alertMap: {},
  loading: true,
  sState: {} as AppState,
} as IProps;
