import { EditGroupComponent } from './edit-group.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import {
  updateUser,
  getUserData,
} from '../../authentication/authentication.actions';

export const EditGroup = connect(
  (state: AppState) => ({ sState: state }),
  {
    updateUser,
    getUserData,
  }
)(EditGroupComponent);
