import { Auth } from 'aws-amplify';
import { generatePin, cleanPhoneNumber } from '../../utils';
import { getUser } from './manage-users.service';
import { Emails } from '../lib/emails';
import { sendEmail, sendSMS } from './email.service';
import { get } from 'lodash';
import { changeUserPassword, signUpUser } from './roles.service';

export const signUp = async (estring: string, password: string) => {
  try {
    const resp = await signUpUser(estring, password);
    return resp.userSub;
  } catch (err) {
    if (err.code === 'UsernameExistsException') {
      throw new Error('Email address already exists');
    }
    if (err.code === 'InvalidPasswordException') {
      throw new Error(err.message);
    }
    throw err;
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    const success = await Auth.signIn(email, password);
    return success;
  } catch (err) {
    const invalidCredentials =
      err.code === 'UserNotFoundException' ||
      err.code === 'NotAuthorizedException';
    if (invalidCredentials) {
      throw new Error('That email and password combination is not valid');
    }
    throw err;
  }
};

export const generatePinAndSendMsgToUser = async (
  email: string,
  useSMSIfAvail = true
) => {
  const pin = generatePin();
  const user = await getUser(email);

  const send = Emails.verify({ ToAddresses: [email], props: { pin } });

  const mfa = get(user, 'mfa', 'email');
  let method;

  if (mfa === 'sms' && useSMSIfAvail) {
    method = 'sms';
    await sendSMS({
      to: cleanPhoneNumber(get(user, 'phone')),
      body: send.Message.Body.Html.Data,
    }).catch(async error => {
      method = 'email';
      await sendEmail(send);
    });
  } else {
    method = 'email';
    await sendEmail(send);
  }

  return { pin, method };
};

export const resendCode = (emailAddress: string) =>
  Auth.resendSignUp(emailAddress);

export const confirmSignUp = async (email: string, code: string) => {
  return await Auth.confirmSignUp(email, code);
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
};

export const forgotPassword = async (username: string) => {
  try {
    return await Auth.forgotPassword(username);
  } catch (err) {
    if (get(err, 'message', '').indexOf('with error Success') > -1) {
      return Promise.resolve({
        CodeDeliveryDetails: true,
      });
    } else {
      throw err;
    }
  }
};

export const forgotPasswordSubmit = async (
  username: string,
  code: string,
  password: string
) => {
  return await changeUserPassword(username, code, password);
};
