/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { BaseTestResultsPData } from '../../../../_shared/interfaces/publishData';
import { EQ_TYPE } from '../../../../_shared/interfaces/equipment';
import { Model } from '../../../../_shared/interfaces/model';
import {
  makeInputGroup,
  makeSwitch,
  makeInput,
  makeNumInput,
} from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: BaseTestResultsPData | undefined) => void;
  equipmentType: EQ_TYPE;
  model: Model;
}

export const BaseDzTestResultsDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged, model } = props;

  const now = (): Moment => moment();

  //STATE
  const [fgh, setFGH] = useState<number | undefined>();
  const [endurancePassed, setEndurancePassed] = useState<boolean>(false);
  const [enduranceStartDate, setEnduranceStartDate] = useState<Moment>(now());
  const [enduranceEndDate, setEnduranceEndDate] = useState<Moment>(
    now().endOf('day')
  );
  const [overall, setOverall] = useState<number | undefined>(undefined);
  const [serialNumber, setSerialNumber] = useState<string | undefined>(
    undefined
  );
  const [hiPot, setHiPot] = useState<number | undefined>(undefined);
  const [ground, setGround] = useState<number | undefined>(undefined);
  const [seq, setSeq] = useState<number | undefined>(undefined);

  //EFFECTS
  useEffect(() => {
    const enduranceStartUnix = enduranceStartDate.unix();
    const enduranceEndUnix = enduranceEndDate.unix();

    //VALIDATE!
    if (
      fgh !== undefined &&
      enduranceStartUnix < enduranceEndUnix && //endurance end should be after start
      overall !== undefined && //everything else just needs to be defined
      serialNumber !== undefined &&
      hiPot !== undefined &&
      ground !== undefined &&
      seq !== undefined
    ) {
      validChanged(true);

      payloadChanged({
        fgh: fgh,
        endurance: [
          endurancePassed ? 1 : 0,
          enduranceStartUnix,
          enduranceEndUnix,
        ],
        overall: overall,
        model: model.id,
        sn: serialNumber,
        hi_pot: hiPot,
        gnd: ground,
        seq: seq,
      });
    } else {
      validChanged(false);
    }
  }, [
    fgh,
    endurancePassed,
    enduranceStartDate,
    enduranceEndDate,
    overall,
    serialNumber,
    hiPot,
    ground,
    seq,
  ]);

  //UI DECLARATIVE
  return (
    <div>
      {makeNumInput('FGH', fgh, setFGH)}
      {makeSwitch('Endurance Passed', endurancePassed, setEndurancePassed)}
      {makeInputGroup(
        'Endurance Test Start',
        <DatePicker
          showTime={true}
          showToday={true}
          defaultValue={enduranceStartDate}
          onChange={setEnduranceStartDate}
        />
      )}
      {makeInputGroup(
        'Endurance Test End',
        <DatePicker
          showTime={true}
          showToday={true}
          defaultValue={enduranceEndDate}
          onChange={setEnduranceEndDate}
        />
      )}
      {makeNumInput('Overall', overall, setOverall)}
      {makeInput('Serial #', serialNumber, setSerialNumber)}
      {makeNumInput('Hi Pot', hiPot, setHiPot)}
      {makeNumInput('Ground', ground, setGround)}
      {makeNumInput('Seq', seq, setSeq)}
    </div>
  );
};
