/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { TableListHeader, TableList } from '../../_shared/table-list';
import { Input, DatePicker, message, Modal, Icon } from 'antd';
import { Button } from '../../_shared/button';
import { Select } from '../../_shared/select';
import { getNoteEvents } from '../../_shared/services/analytics.service';
import { useSelector } from 'react-redux';
import { AppState } from '../../app.state';
import { Org } from '../../_shared/interfaces/org';
import styles from './styles';
import { size, get, sortBy, uniq } from 'lodash';
import { useDebouncedEffect } from '../../_shared/hooks/useDebouncedEffect';
import moment from 'moment';
import { exportToCsv, sortByKey } from '../../utils';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { Location } from '../../_shared/interfaces/location';
import { ModalEvent } from '../../_shared/modal-event';
import { _Equipment } from '../../_shared/interfaces/equipment';
import { Publish } from '../../_shared/interfaces/publish';
import { User } from '../../_shared/interfaces/user';
import { CONTENT } from '../../utils/publish';

const format = 'L hh:mm:ssa';

export const NotificationEvents = (props = {}) => {
  const orgs = useSelector((state: AppState) => state.dash.orgs);
  const locations = useSelector((state: AppState) => state.dash.locations);

  const [state, setState] = useState({
    loading: false,
    data: [],
    uniqUsers: 0,
    uniqEq: 0,
    filtered: null,
    orgs: [],
    locations: [],
    alertNames: [],
    input: '',
    date: {
      selected: '6_month',
      start: moment().subtract(6, 'month'),
      end: moment(),
    },
    modalEvent: {
      visible: false,
      onClose: () => null,
      equipment: new _Equipment(),
    },
    filters: [
      {
        id: 'orgs',
        opts: orgs.map((org: Org) => ({ label: org.name, value: org.sk })),
        props: {
          placeholder: 'Select Organizations',
        },
      },
      {
        id: 'locations',
        opts: locations.map((role: Location) => ({
          label: role.name,
          value: role.sk,
        })),
        props: {
          placeholder: 'Select Locations',
        },
      },
      {
        id: 'alertNames',
        opts: (() => {
          const {
            compressorErrorText,
            vacuumErrorText,
            handpieceErrorText,
            maintErrorText,
          } = CONTENT;
          const cText = compressorErrorText;
          const vText = vacuumErrorText;
          const hText = handpieceErrorText;
          const mText = maintErrorText;

          const names: string[] = [];

          const loop = (obj = {}) => {
            Object.keys(obj).map(k => {
              const error = get(obj, [k, 'error'], {});

              Object.keys(error).map(ek => {
                const alertName = get(error, [ek, 'alertName']);

                if (alertName && ek !== '0') {
                  names.push(alertName);
                }
              });
            });
          };

          loop(cText);
          loop(vText);
          loop(hText);
          loop(mText);

          return sortBy(uniq(names)).map(n => ({ label: n, value: n }));
        })(),
        props: {
          placeholder: 'Select Alert Names',
        },
      },
    ],
  });

  const fireSearch = (value: string) => {
    setState(s => ({ ...s, input: value }));
  };

  const filterBasedOnInput = () => {
    try {
      if (!state.input) {
        return setState(s => ({ ...s, filtered: null }));
      }

      const inp = state.input.toLowerCase();

      const filtered = (state.data.filter(d => {
        const toMatch = ['userId', 'alertName', 'equipment.equipmentSN'];
        return !!toMatch.find(
          f =>
            `${get(d, f, '')}`
              .toString()
              .toLowerCase()
              .indexOf(inp) > -1
        );
      }) as unknown) as null;

      const uniq = getUniq(filtered as unknown as any[]);

      setState(s => ({ ...s, filtered, uniqEq: size(uniq.equipment), uniqUsers: size(uniq.users) }));
    } catch (err) {
      message.error(err.message);
    }
  };

  const getUniq = (arr: any[]) => {
    const uniq = {
      users: [] as string[],
      equipment: [] as string[],
    };
    arr.map((e: {}) => {
      const userid = get(e, 'userId');
      const eid = get(e, 'equipment.deviceId');
      if (!!userid && uniq.users.indexOf(userid) === -1) {
        uniq.users.push(userid);
      }
      if (!!eid && uniq.equipment.indexOf(eid) === -1) {
        uniq.equipment.push(eid);
      }
      return e;
    });
    return uniq;
  }

  const retrieveData = async () => {
    try {
      setState(s => ({ ...s, loading: true, filtered: null }));
      const data = (await getNoteEvents(['email', 'sms'], {
        orgs: state.orgs,
        date: state.date,
        locations: state.locations,
        alertNames: state.alertNames,
      }))
      const uniq = getUniq(data);
      setState(s => ({
        ...s,
        data,
        uniqUsers: size(uniq.users),
        uniqEq: size(uniq.equipment),
      }));
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  };

  const changeSelect = (filt: string, values: string[]) => {
    setState(s => ({ ...s, [filt]: values }));
  };

  const clearModalEvent = () => {
    setState(s => ({
      ...s,
      modalEvent: {
        visible: false,
        onClose: () => null,
        equipment: new _Equipment(),
      },
    }));
    return null;
  };

  const columns = (includeExport = false) => {
    const cols = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        sorter: (a: {}, b: {}) =>
          new Date(get(b, 'createdAt')).getTime() -
          new Date(get(a, 'createdAt')).getTime(),
        render: (text: string) => {
          return moment(text).format(format);
        },
      },
      {
        title: 'Recipients',
        dataIndex: 'users',
        sorter: (a: {}, b: {}) => sortByKey(a, b, 'userId'),
        exportOnly: false,
        render: (text: string, row = {}) => {
          const emails = get(row, 'email', []);
          const sms = get(row, 'sms', []);
          const count = size(emails) + size(sms);

          const openModal = () => {
            Modal.info({
              title: 'Recipients',
              content: (
                <div style={{ marginTop: 10 }}>
                  {!!size(emails) && (
                    <div>
                      <div css={css(styles.noteUserModalTitle)}>Emails:</div>
                      <ul>
                        {emails.map((user: User) => (
                          <li>{get(user, 'userId', '')}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {!!size(sms) && (
                    <div>
                      <div css={css(styles.noteUserModalTitle)}>SMS:</div>
                      <ul>
                        {sms.map((user: User) => (
                          <li>{get(user, 'userId', '')}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ),
            });
          };

          return (
            <div css={css(styles.recipRow)} onClick={openModal}>
              <Icon type="question-circle" style={{ marginRight: 5 }} />
              {`${count} Recipients`}
            </div>
          );
        },
      },
      {
        title: 'Email Recipients',
        dataIndex: '',
        value: (row = {}) => {
          return get(row, 'email', [])
            .map((u: User) => get(u, 'userId'))
            .join(', ');
        },
        exportOnly: true,
      },
      {
        title: 'SMS Recipients',
        dataIndex: '',
        value: (row = {}) => {
          return get(row, 'sms', [])
            .map((u: User) => get(u, 'userId'))
            .join(', ');
        },
        exportOnly: true,
      },
      {
        title: 'Equipment',
        dataIndex: 'equipment',
        render: (text: string, row = {}) => {
          return get(row, 'equipment.equipmentSN', '--') || '--';
        },
      },
      {
        title: 'Model',
        dataIndex: 'equipment.modelId',
        sorter: (a: {}, b: {}) =>
          sortByKey(get(a, 'equipment'), get(b, 'equipment'), 'modelId'),
        value: (row = {}) => get(row, 'equipment.modelId', ''),
      },
      {
        title: 'Alert',
        dataIndex: 'alertName',
        render: (text: string) => text || 'N/A',
      },
      {
        title: 'Alert Details',
        dataIndex: '',
        render: (text: string, row = {}) => {
          const onClick = () => {
            setState(s => ({
              ...s,
              modalEvent: {
                visible: true,
                publish: get(row, 'alert') as Publish,
                onClose: clearModalEvent,
                equipment: new _Equipment(get(row, 'equipment')),
              },
            }));
          };

          return <Button title="Details" icon="eye" onClick={onClick} />;
        },
      },
    ];
    return cols
      .map(c => ({
        ...c,
        width: get(c, 'width', `${100 / size(cols)}%`),
      }))
      .filter(c => {
        if (includeExport) {
          return get(c, 'exportOnly', true);
        }
        return !get(c, 'exportOnly', false);
      });
  };

  const exportData = () => {
    const exdata = !!size(state.filtered) ? state.filtered : state.data;
    const cols = columns(true).filter(c => c.title !== 'Details');
    const rows = [cols.map(c => c.title)];
    (exdata || []).map(event => {
      rows.push(
        cols.map(c =>
          c.value
            ? c.value(event)
            : c.render
            ? c.render(get(event, c.dataIndex), event)
            : get(event, get(c, 'dataIndex'), '')
        )
      );
    });
    exportToCsv(`NoteEvents-${moment().toISOString()}.csv`, rows);
  };

  // #region date stuff
  const dateRangeChange = (
    _pickerVals: RangePickerValue,
    dates: [string, string]
  ) => {
    const date = { ...state.date };
    date.start = moment(dates[0]);
    date.end = moment(dates[1]);
    setState(s => ({ ...s, date }));
  };

  const datePickerChange = (date: moment.Moment) => {
    const _date = { ...state.date };
    _date.start = moment(date).startOf('day');
    _date.end = _date.start.clone().endOf('day');
    setState(s => ({ ...s, date: _date }));
  };

  const dateChange = (val: string) => {
    const date = { ...state.date };
    if (val === '1_day') {
      date.start = moment().subtract(1, 'day');
    } else if (val === '1_week') {
      date.start = moment().subtract(1, 'week');
    } else if (val === '1_month') {
      date.start = moment().subtract(1, 'month');
    } else if (val === '3_month') {
      date.start = moment().subtract(3, 'month');
    } else if (val === '6_month') {
      date.start = moment().subtract(6, 'month');
    }
    date.end = moment();
    date.selected = val;
    setState(s => ({ ...s, date }));
  };

  const renderDateDrop = () => {
    const {
      date: { selected, start, end },
    } = state;

    const options = [
      { title: '1 day', value: '1_day' },
      { title: '1 week', value: '1_week' },
      { title: '1 month', value: '1_month' },
      { title: '3 months', value: '3_month' },
      { title: '6 months', value: '6_month' },
      { title: 'Custom', value: 'custom_date' },
    ];

    return (
      <div>
        <Select onChange={v => dateChange(v as string)} defaultValue={selected}>
          {options.map((opt, i) => (
            <Select.Option value={opt.value} key={i}>
              {opt.title}
            </Select.Option>
          ))}
        </Select>
        {selected === 'custom_date' && (
          <DatePicker.RangePicker
            onChange={dateRangeChange}
            defaultValue={[start, end]}
          />
        )}
        {selected === '1_day' && (
          <DatePicker onChange={datePickerChange} defaultValue={start} />
        )}
      </div>
    );
  };
  //#endregion

  useEffect(() => {
    filterBasedOnInput();
  }, [state.input, state.data]);

  useDebouncedEffect(
    () => {
      retrieveData();
    },
    [state.orgs, state.locations, state.date, state.alertNames] as never[],
    1000
  );

  const { loading, data } = state;

  return (
    <div>
      <TableListHeader style={`justify-content: flex-start;`}>
        <Input.Search
          css={css`
            width: 50%;
            margin-right: 10px;
          `}
          placeholder="Search by User, Alert, or Equipment SN..."
          enterButton="Search"
          size="default"
          onSearch={fireSearch}
          defaultValue={state.input}
        />

        <Button
          loading={loading}
          title="Refresh"
          onClick={retrieveData}
          type="link"
        />

        <Button
          css={css('margin-left: auto;')}
          title="Export"
          onClick={exportData}
        />
      </TableListHeader>

      <TableListHeader style={`justify-content: flex-start;`}>
        {state.filters.map(filt => {
          return (
            <Select
              key={filt.id}
              mode="multiple"
              css={css(styles.select)}
              onChange={value => changeSelect(filt.id, value as string[])}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
              {...(filt.props || {})}
            >
              {(get(filt, 'opts', []) as never[]).map(
                (item: { label: string; value: string | number }) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                )
              )}
            </Select>
          );
        })}
      </TableListHeader>

      <TableListHeader>{renderDateDrop()}</TableListHeader>

      <TableList
        loading={loading}
        columns={columns()}
        canSelectRows={false}
        data={(state.filtered !== null ? state.filtered : data) as never[]}
        pageSize={100}
        subtractHeight={270}
        includeCount="Events"
        countExtra={`Unique Users: ${state.uniqUsers} - Unique Equipment: ${state.uniqEq}`}
      />

      <ModalEvent {...state.modalEvent} supressEvent={true} />
    </div>
  );
};
