/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {
  FormEvent,
  ReactNode,
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { AppState } from '../../app.state';
import { _User } from '../../_shared/interfaces/user';
import { Form, Input, Alert, Icon, Select, InputNumber } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import styles from './edit-preset.styles';
import { Button } from '../../_shared/button';
import Link from '../../_shared/link';
import { set } from 'lodash';
import SharedStyles from '../../_shared/styles';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { Equipment } from '../../_shared/interfaces/equipment';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { _Location, Location } from '../../_shared/interfaces/location';
import { Opt } from '../../_shared/interfaces/opt';
import { SelectValue } from 'antd/lib/select';
import { _Preset } from '../../_shared/interfaces/preset';
import colors from '../../_shared/colors';
import Label from '../../_shared/label';
import { TableTitleHeader } from '../../_shared/table-list';
import { uppercaseWords, isHandpiece } from '../../utils';
import {
  IHandpiecePreset,
  HandpieceEndoSetting,
  humanReadableEndoSetting,
  humanReadableGearRatio,
  rpmOptionsForGearRatio,
  torqueOptionsForGearRatio,
  HandpieceProfileKey,
  HandpiecePresetKey,
  IHandpieceProfile,
} from '../../_shared/interfaces/handpieceProfiles';
import {
  setHandpiecePresetOverride,
  resetHandpiecePresets,
} from '../../_shared/services/handpiece-profiles.service';
import { Org } from '../../_shared/interfaces/org';

interface IProps {
  sState: AppState;
  updatePresets: () => Promise<void>;
  backText: string;
  preset: IHandpiecePreset;
  profile: IHandpieceProfile;
  profileKey: HandpieceProfileKey;
  presetKey: HandpiecePresetKey;
  // tslint:disable-next-line:no-any
  history: any;
  org: Org;
  location: Location;
}

interface TDisplay {
  title?: string;
  var: string;
  style?: string;
  canDisplay?: () => boolean;
  transform?: (value: string) => string;
  options?: GetFieldDecoratorOptions;
  data?: () => string[];
  opts?: Opt[];
  type?: string;
  value?: string;
  label?: string;
  subText?: (item: TDisplay) => string;
  onChange?: (e: FormEvent | number | undefined, variable: string) => void;
  onSelect?: (value: SelectValue) => void;
  disabled?: boolean;
  valueType?: string;
  suffix?: string | ReactNode;
  suffixIcon?: ReactNode;
}

const _EditPresetComponent: React.FC<
  RouteComponentProps & FormComponentProps
> = props => {
  const { form, history } = props;
  const {
    preset,
    org,
    location,
    profile,
    profileKey,
    presetKey,
    backText,
  } = props.location.state as IProps;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>(preset.name);
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [workingPreset, setWorkingPreset] = useState<IHandpiecePreset>(preset);
  const [isEndoPreset] = useState<boolean>(
    preset && preset.endo_mode_enum_raw !== HandpieceEndoSetting.autoOff
  );

  const tableData: Array<TDisplay[]> = useMemo(() => {
    const gearRatioOptions = [
      { label: humanReadableGearRatio(0), value: 0 },
      { label: humanReadableGearRatio(1), value: 1 },
      isEndoPreset ? { label: humanReadableGearRatio(2), value: 2 } : undefined,
      isEndoPreset ? { label: humanReadableGearRatio(3), value: 3 } : undefined,
      isEndoPreset ? { label: humanReadableGearRatio(4), value: 4 } : undefined,
      isEndoPreset ? { label: humanReadableGearRatio(5), value: 5 } : undefined,
    ].filter(o => o !== undefined) as Opt[];

    const rpmOptions = rpmOptionsForGearRatio(
      workingPreset.gear_ratio_enum_raw
    ).map(i => ({ label: i, value: i }));
    const torqueOptions = torqueOptionsForGearRatio(
      workingPreset.gear_ratio_enum_raw
    ).map(i => ({ label: i, value: i }));

    return [
      [
        {
          title: 'Gear Ratio',
          var: 'gear_ratio_enum_raw',
          type: 'dropdown',
          opts: gearRatioOptions,
          options: {
            initialValue: humanReadableGearRatio(
              workingPreset.gear_ratio_enum_raw
            ),
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Gear ratio is required',
              },
            ],
          },
        },
        {
          title: 'Head Speed',
          var: 'rpm',
          type: 'dropdown',
          opts: rpmOptions,
          options: {
            initialValue: workingPreset.rpm,
            validateTrigger: 'onChange',
            rules: [
              {
                required: true,
                message: 'Speed is required',
              },
            ],
          },
        },
        {
          title: 'Direction of Rotation',
          type: 'dropdown',
          var: 'rotation_clockwise',
          opts: [
            { value: 1, label: 'Clockwise' },
            { value: 0, label: 'Counterclockwise' },
          ],
          options: {
            initialValue: workingPreset.rotation_clockwise
              ? 'Clockwise'
              : 'Counterclockwise',
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Rotation Direction is required',
              },
            ],
          },
        },
        {
          title: 'Endo Settings',
          var: 'endo_mode_enum_raw',
          type: 'dropdown',
          canDisplay: () => isEndoPreset,
          options: {
            initialValue: humanReadableEndoSetting(
              workingPreset.endo_mode_enum_raw
            ),
          },
          opts: [
            { label: humanReadableEndoSetting(0), value: 0 },
            { label: humanReadableEndoSetting(1), value: 1 },
            { label: humanReadableEndoSetting(2), value: 2 },
          ],
        },
        {
          title: 'Torque Limit',
          var: 'torque_threshold',
          type: 'dropdown',
          canDisplay: () => isEndoPreset,
          opts: torqueOptions,
          options: {
            initialValue: workingPreset.torque_threshold,
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Torque is required',
              },
            ],
          },
        },
      ],
    ];
  }, [workingPreset]);

  useEffect(() => {
    const work = async () => {
      setLoading(true);
      const eqs: Equipment[] = await getEquipments({
        dentalOrgId: { eq: location.orgId },
      });
      setEquipment(eqs);
      setLoading(false);
    };
    work();
  }, []);

  useEffect(() => {
    if (name) {
      workingPreset.name = name;
      setWorkingPreset({ ...workingPreset });
    }
  }, [name]);

  const submit = useCallback(
    async (e: MouseEvent | FormEvent) => {
      const { validateFieldsAndScroll } = form;
      e.preventDefault();
      if (loading) {
        return;
      }

      try {
        await setHandpiecePresetOverride(
          location,
          workingPreset,
          profileKey,
          presetKey
        );
        if (location.orgId) {
          for (const handpiece of equipment.filter(e => isHandpiece(e))) {
            await resetHandpiecePresets(handpiece, location.orgId, location.id);
          }
          goBack(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setSaving(false);
      }
    },
    [workingPreset]
  );

  const onChange = useCallback(
    (value: SelectValue | number | undefined, variable: string) => {
      set(workingPreset, variable, value);

      if (variable === 'gear_ratio_enum_raw') {
        const rpmOptions = rpmOptionsForGearRatio(
          workingPreset.gear_ratio_enum_raw
        );
        set(workingPreset, 'rpm', rpmOptions[0]);
        const torqueOptions = torqueOptionsForGearRatio(
          workingPreset.gear_ratio_enum_raw
        );
        set(workingPreset, 'torque_threshold', torqueOptions[0]);
      }

      setWorkingPreset({ ...workingPreset });
    },
    [workingPreset]
  );

  const goBack = useCallback((edited: boolean) => {
    history.replace('/dashboard/manageprofile', {
      selected: {
        dentalOrg: org,
        location: location,
        profile: profile,
        profileKey: profileKey,
        presetType: presetKey[0] === 'e' ? 'endo' : 'prep',
        presetKey: presetKey,
        preset: workingPreset,
      },
      successMessage: edited
        ? 'Preset updated and sent to handpiece unit(s)'
        : undefined,
    });
  }, []);

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const val = uppercaseWords(event.target.value);
    if (!val) {
      setNameError('Name is required');
    } else {
      setNameError(undefined);
    }
    setName(val);
  }, []);

  const renderTopForm = useMemo(() => {
    if (loading) {
      return <Icon type="loading" />;
    }

    return (
      <div css={css(styles.topFormContainer)}>
        <div>
          <Label
            required
            label="Name"
            customCss={`font-size: 12px; font-weight: bold;`}
          />
          <Input
            maxLength={16}
            value={name}
            onChange={onChangeName}
            css={css(`margin-top: -5px;`)}
          />
          {nameError && (
            <span css={css(`color: ${colors.error}; margin-top: -100px;`)}>
              {nameError}
            </span>
          )}
        </div>
      </div>
    );
  }, [name]);

  const hasFieldError = (): boolean => {
    const errors = form.getFieldsError();
    function checkProperties(errors: Record<string, string[] | undefined>) {
      for (let key in errors) {
        if (errors[key] !== undefined) {
          return true;
        }
      }
      return false;
    }
    return checkProperties(errors);
  };

  const renderForm = useMemo(() => {
    const { getFieldDecorator } = form;
    return (
      <Form layout="vertical" css={css(styles.formContainer)} onSubmit={submit}>
        {(tableData as [TDisplay[]]).map((items, i) => {
          return (
            <div key={i}>
              {items.map((item, _i) => {
                const itemOptions = item.options;
                const canDisplay = item.canDisplay ? item.canDisplay() : true;
                return !canDisplay ? null : (
                  <Form.Item key={_i} css={css(item.style)} label={item.title}>
                    <div>
                      {getFieldDecorator(item.var, itemOptions)(
                        item.type === 'dropdown' && item.opts ? (
                          <Select
                            suffixIcon={item.suffixIcon}
                            disabled={item.disabled}
                            onChange={(value: SelectValue) =>
                              onChange(value, item.var)
                            }
                          >
                            {item.opts.map((opt, i) => (
                              <Select.Option key={i} value={opt.value}>
                                {opt.label}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <InputNumber
                            css={css(
                              `width: 100%; border-radius: 0px; border: 2px solid #efefef`
                            )}
                            min={100}
                            max={40000}
                            onChange={(value: number | undefined) =>
                              onChange(value, item.var)
                            }
                          />
                        )
                      )}
                      {item && item.subText && item.subText(item)}
                    </div>
                  </Form.Item>
                );
              })}
            </div>
          );
        })}
      </Form>
    );
  }, [workingPreset]);

  const renderButtons = () => {
    const cancel = (
      <Button
        title={'Cancel'}
        disabled={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={() => goBack(false)}
      />
    );

    const submitButton = (
      <Button
        title={'Save'}
        loading={saving || loading}
        css={css`
          margin: 0 5px;
        `}
        disabled={saving || loading || !!nameError || hasFieldError()}
        onClick={submit}
      />
    );

    return (
      <div css={css(SharedStyles.row, `margin-top: 50px; align-self: center;`)}>
        {cancel}
        {submitButton}
      </div>
    );
  };

  return (
    <div css={css(styles.container)}>
      {!!backText && (
        <Link
          css={css`
            margin-bottom: 5px;
            margin-right: auto;
          `}
          onClick={() => goBack(false)}
        >
          {backText}
        </Link>
      )}
      {success && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="success"
          message={success}
          closable
          onClose={() => setSuccess(undefined)}
        />
      )}
      {error && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="error"
          message={error}
          closable
          onClose={() => setError(undefined)}
        />
      )}

      <div css={css(SharedStyles.row, styles.rowMargin)}>
        <TableTitleHeader>
          {'Edit Preset'}
          {loading && <Icon css={css('margin-left: 5px;')} type="loading" />}
        </TableTitleHeader>
      </div>
      <div css={css(SharedStyles.hr)} />

      {workingPreset && renderTopForm}
      {workingPreset && renderForm}
      {workingPreset && renderButtons()}
    </div>
  );
};

export const EditPresetComponent = Form.create()(
  withRouter(_EditPresetComponent)
);
