/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Model } from '../interfaces/model';
import { modelImageSource } from '../../utils';
import { Icon } from 'antd';
import { Publish } from '../interfaces/publish';

interface IProps {
  model?: Model;
  config?: Publish;
  className?: string;
}

const styles = {
  imageContainer: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      transition: 0.5s all;
      opacity: 0.0;
      max-height: 100%;
    }  
    
    .anticon {
      position: absolute;
      transition: 0.5s all;
      opacity: 1.0;
      font-size: 40px;
    }
  `,
  hasLoaded: `
    img {
      opacity: 1.0;
    }

    .anticon {
      opacity: 0.0;
    }
  `,
};

export default class ModelImage extends Component<IProps> {
  state = {
    hasLoaded: false,
  };
  onLoad = () => {
    this.setState({ hasLoaded: true });
  };
  render() {
    const { hasLoaded } = this.state;
    const { model, className, config } = this.props;

    const imageSource = modelImageSource(model, config);

    return (
      <div
        className={className}
        css={css(styles.imageContainer, hasLoaded && styles.hasLoaded)}
      >
        {imageSource && (
          <img src={imageSource} alt={'Equipment'} onLoad={this.onLoad} />
        )}
        <Icon type="loading" />
      </div>
    );
  }
}
