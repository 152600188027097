/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import SharedStyles from '../styles';
import FormTitle from '../form-title';
import { Icon, Modal } from 'antd';
import { TableList } from '../table-list';
import Link from '../link';
import { ModalMultiSelectSearch } from '../modal-multi-select-search';
import { Button } from '../button';
import { uppercaseFirst } from '../../utils';
import { get } from 'lodash';
import { Equipment } from '../interfaces/equipment';
import { ColumnProps } from 'antd/lib/table';

interface IProps<T> {
  data: never[];
  preset?: boolean;
  columns: ColumnProps<T>[];
  loading: boolean;
  addBtn?: string;
  title: string;
  subtitle?: string;
  modalTitle?: string;
  blankText?: string;
  variable: string;
  // tslint:disable-next-line: no-any
  allOpts?: any[];
  confirmMessage?: string;
  removeLabel?: string;
  totalTitle: (val: number) => string;
  onDone?: (res?: { error?: string }, refreshDash?: boolean) => void;
  onResult?: (res: { tags: string[] }) => Promise<void | unknown>;
  // tslint:disable-next-line:no-any
  onRemove?: (rows: any[]) => Promise<void | unknown>;
  modalPlaceholder?: string;
  canSelectRows?: boolean;
  canShowAdd?: boolean;
  equipMap?: {};
  selectedEquipLimit?: number;
  showRemoveAlert?: (selectedRows: Equipment[]) => boolean | undefined;
  showNoEquipAlert?: (rows: Equipment[]) => boolean | undefined;
  showLimitAlert?: () => boolean;
  showNoAvailEquipAlert?: () => boolean;
  goToAddPreset?: () => void;
  scrollLength?: number;
  // tslint:disable-next-line:no-any
  rowClick?: (record: any, rowIndex: number) => void;
}

interface IState {
  modal: { visible: boolean };
  // tslint:disable-next-line:no-any
  selectedRows: any[];
  loading: boolean;
}

export class TableListMultiGroup<T> extends Component<IProps<T>, IState> {
  constructor(props: IProps<T>) {
    super(props);

    this.state = {
      modal: {
        visible: false,
      },
      selectedRows: [],
      loading: false,
    };
  }

  openModal = () => {
    const { showLimitAlert, showNoAvailEquipAlert } = this.props;
    if (!!showLimitAlert && showLimitAlert()) {
      return;
    }
    if (!!showNoAvailEquipAlert && showNoAvailEquipAlert()) {
      return;
    }
    this.setState({
      selectedRows: [],
      modal: {
        ...this.state.modal,
        visible: true,
      },
    });
  };
  finish = (res?: { error?: string }) => {
    const { onDone } = this.props;

    this.closeModal();

    onDone && onDone(res);
  };
  closeModal = () => {
    this.setState({
      loading: false,
      modal: {
        ...this.state.modal,
        visible: false,
      },
    });
  };
  selectedRowsChange = (selectedRows: number[] | string[]) => {
    this.setState({
      selectedRows,
    });
  };
  modalResult = async (res: { tags: string[] }) => {
    const { onResult } = this.props;

    try {
      this.setState({ loading: true });
      onResult && (await onResult(res));
    } catch (err) {
      return this.finish({ error: err.message });
    }

    this.setState({ selectedRows: [], loading: false });
    return this.finish();
  };
  removeItems = async () => {
    const {
      data,
      onRemove,
      confirmMessage,
      showRemoveAlert,
      showNoEquipAlert,
    } = this.props;
    const { selectedRows } = this.state;
    const rows = data.filter(
      (d: { sk?: string; id?: string }) =>
        selectedRows.indexOf(d.sk || d.id) > -1
    );
    if (!!showNoEquipAlert && showNoEquipAlert(rows)) {
      return;
    }
    if (!!showRemoveAlert && showRemoveAlert(rows)) {
      return;
    }

    const onOk = async () => {
      if (this.state.loading) {
        return;
      }
      try {
        this.setState({ loading: true });
        !!onRemove && (await onRemove(rows));
      } catch (err) {
        this.setState({ loading: false });
        return this.finish({ error: err.message });
      }

      this.setState({ selectedRows: [], loading: false });
      return this.finish();
    };

    const onCancel = () => this.closeModal();

    Modal.confirm({
      title: 'Removal Confirmation',
      content: confirmMessage,
      onOk: onOk,
      onCancel: onCancel,
    });
  };
  render() {
    const { modal, selectedRows } = this.state;
    const {
      data,
      loading,
      columns,
      addBtn,
      totalTitle,
      title,
      variable,
      blankText,
      allOpts,
      removeLabel,
      modalTitle,
      modalPlaceholder,
      equipMap,
      onRemove,
      subtitle,
      scrollLength,
      rowClick,
    } = this.props;
    const V = uppercaseFirst(variable);

    const mTitle = modalTitle || `Add ${V} to User`;
    const mPlaceholder = modalPlaceholder || `Add ${V}`;

    const showBlank = !loading && data.length === 0 && blankText;

    const canShowAdd = get(this, 'props.canShowAdd', true);

    return (
      <div>
        <div css={css(SharedStyles.hr)} />

        <div css={css(SharedStyles.row, `height: 30px;`)}>
          <FormTitle
            css={css`
              margin-bottom: 0;
            `}
            title={title}
          />

          {selectedRows.length > 0 && removeLabel && !!onRemove && (
            <Button
              css={css`
                font-size: 10px;
                padding: 1px 3px;
                margin-left: 10px;
                line-height: 10px;
                height: 16px;
              `}
              title={removeLabel}
              onClick={this.removeItems}
            />
          )}
          {canShowAdd && (
            <Link
              css={css(`
                margin-left: auto;
                font-weight: bold;
              `)}
              onClick={
                this.props.goToAddPreset
                  ? this.props.goToAddPreset
                  : this.openModal
              }
            >
              <Icon style={{ fontWeight: 'bold' }} type={'plus'} />
              <span>{addBtn}</span>
            </Link>
          )}
        </div>
        {!!data.length && !!subtitle && (
          <p css={css(`font-style: italic`)}>{subtitle}</p>
        )}
        {!showBlank && (
          <TableList
            columns={columns}
            canSelectRows={get(this.props, 'canSelectRows', true)}
            data={data}
            loading={this.state.loading || loading}
            scrollHeight={
              scrollLength && data.length > scrollLength
                ? 180
                : !scrollLength && data.length > 5
                  ? 180
                  : undefined
            }
            showPagination={false}
            onSelectedRowsChange={this.selectedRowsChange}
            rowClick={rowClick}
            includeCount={false}
          />
        )}
        {showBlank && (
          <div
            css={css(
              SharedStyles.row,
              `font-size: 14px;
          font-style: italic;
          margin: 10px 0px;
          justify-content: center;
          alignItems: center;
          text-align: center;
          min-height: 50px;
          padding: 20px;
          `
            )}
          >
            {blankText}
          </div>
        )}
        {!showBlank && (
          <div
            css={css`
              font-size: 12px;
              font-style: italic;
              margin: 10px 0px;
            `}
          >
            {totalTitle(data && data.length ? data.length : 0)}
          </div>
        )}

        <ModalMultiSelectSearch
          title={mTitle}
          placeholder={mPlaceholder}
          visible={modal.visible}
          searchOpts={allOpts ? allOpts : []}
          onResult={this.modalResult}
          onCancel={this.closeModal}
          equipMap={equipMap}
          selectedEquipLimit={this.props.selectedEquipLimit}
        />
      </div>
    );
  }
}
