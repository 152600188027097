/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { VacuumSensorStatisticsPData } from '../../../../_shared/interfaces/publishData';
import { makeInput } from '../../spoof-a-publish.component';
import { minMaxAvgInputs } from './base-sensor-stats-form.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: VacuumSensorStatisticsPData | undefined) => void;
}

export const VacuumSensorStatsDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [pT, setPT] = useState<string | undefined>(undefined);
  const [iP, setIP] = useState<string | undefined>(undefined);
  const wOMinState = useState<number | undefined>(undefined);
  const wOMaxState = useState<number | undefined>(undefined);
  const wOAvgState = useState<number | undefined>(undefined);
  const wPMinState = useState<number | undefined>(undefined);
  const wPMaxState = useState<number | undefined>(undefined);
  const wPAvgState = useState<number | undefined>(undefined);
  const oLMinState = useState<number | undefined>(undefined);
  const oLMaxState = useState<number | undefined>(undefined);
  const oLAvgState = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      pT !== undefined &&
      iP !== undefined &&
      wOMinState[0] !== undefined &&
      wOMaxState[0] !== undefined &&
      wOAvgState[0] !== undefined &&
      wPMinState[0] !== undefined &&
      wPMaxState[0] !== undefined &&
      wPAvgState[0] !== undefined &&
      oLMaxState[0] !== undefined &&
      oLMinState[0] !== undefined &&
      oLAvgState[0] !== undefined
    ) {
      validChanged(true);
      payloadChanged({
        pT: pT,
        iP: iP,
        wO: [wOMinState[0], wOMaxState[0], wOAvgState[0]],
        wP: [wPMinState[0], wPMaxState[0], wPAvgState[0]],
        oL: [oLMinState[0], oLMaxState[0], oLAvgState[0]],
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [
    pT,
    iP,
    wOMinState[0],
    wOMaxState[0],
    wOAvgState[0],
    wPMinState[0],
    wPMaxState[0],
    wPAvgState[0],
    oLMinState[0],
    oLMaxState[0],
    oLAvgState[0],
  ]);

  return (
    <div>
      {makeInput('PT', pT, setPT)}
      {makeInput('IP', iP, setIP)}
      {minMaxAvgInputs('WO', wOMinState, wOMaxState, wOAvgState)}
      {minMaxAvgInputs('WP', wPMinState, wPMaxState, wPAvgState)}
      {minMaxAvgInputs('OL', oLMinState, oLMaxState, oLAvgState)}
    </div>
  );
};
