/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import axios from 'axios';
import { Component, FormEvent, ReactElement } from 'react';
import {
  Form,
  Input,
  Alert,
  DatePicker,
  InputNumber,
  Icon,
  Modal,
  message,
  Checkbox,
  Radio,
} from 'antd';
import { withRouter } from 'react-router';
import FormTitle from '../../_shared/form-title';
import SharedStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import styles from './manufacture-process.styles';
import {
  getEquipments,
  createEquipment,
  updateEquipment,
  deleteEquipment,
  getEquipmentRecord,
} from '../../_shared/services/manage-equipment.service';
import { _Equipment, Equipment } from '../../_shared/interfaces/equipment';
import moment from 'moment';
import {
  findModel,
  uppercaseFirst,
  buildErrorMsgFromForm,
  defaultDateFormat,
  getEquipTestResults,
  sortByValue,
  downloadFile,
  isHandpiece,
  _checkEquipmentSN,
  getEqType,
  motorControlBoardTests,
  isBetween,
  compareVersionNumbers,
} from '../../utils';
import { debounce, get, set, size, first, orderBy } from 'lodash';
import { Model } from '../../_shared/interfaces/model';
import { Select } from '../../_shared/select';
import colors from '../../_shared/colors';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import { TableList } from '../../_shared/table-list';
import { getSerial } from '../../_shared/services/manage-serials.service';
import { getModels } from '../../_shared/services/manage-models.service';
import { IFormItem, IProps, IState } from './manufacture-process.interfaces';
import {
  checkEquipForController,
  checkEquipHasDifferentController,
  getEquipWithControllerandEquipSN,
  generateTestCommand,
  checkContAssocWithOtherEquip,
  getAllEquipmentWithEquipSN,
} from './manufacture-process.utils';
import CONFIG, { ENVS, IAppConfig, isProd } from '../../config';
import Bugsnag from '@bugsnag/js';
import { AlertStatus } from '../../_shared/interfaces/equipmentStatus';
import { getAppConfig } from '../../_shared/hooks/useAppConfig';
import { Tutorial } from '../../_shared/tutorial';
import { checkForNewStatus } from '../dashboard.actions';
import { createPublish } from '../../_shared/services/publish.service';

const generateEquipColumns = (_this: _ManufactureProcess) => {
  const cols = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: '10%',
      render: (_text: string, record: Equipment) => {
        const model = findModel(record, _this.props.sState);
        return <span>{uppercaseFirst(get(model, 'type', ''))}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '8.5%',
      render: (_text: string, record: Equipment) => {
        const testResults = get(record, 'status.test');
        const isCompleted = !!record.testCompletion;
        const passedTests = get(testResults, 'overall');
        const status =
          isCompleted && passedTests
            ? 'Passed'
            : isCompleted && !passedTests
            ? 'Failed'
            : 'In Progress';
        return (
          <span
            css={css(
              isCompleted && !passedTests && styles.failed,
              isCompleted && passedTests && styles.passed
            )}
          >
            {status}
          </span>
        );
      },
    },
    {
      title: 'Equipment SN',
      dataIndex: 'equipmentSN',
      width: '11%',
    },
    {
      title: 'Show on Dash',
      dataIndex: 'showOnDash',
      width: '8.5%',
      render: (_text: string, record: Equipment) => {
        const showOnDash = record.showOnDash;
        return (
          <span css={css(showOnDash ? styles.passed : styles.failed)}>
            {showOnDash ? 'True' : 'False'}
          </span>
        );
      },
    },
    {
      title: 'Active Alerts',
      width: '8.5%',
      dataIndex: '',
      render: (_text: string, record: Equipment) => {
        const installDate = moment(get(record, 'installDate'));
        const alerts = (get(record, 'status.alerts', []) as string[]).filter(a => {
          const time = moment(get(a, 'time') * 1000);
          return time.isAfter(installDate);
        });
        const alertCount = size(alerts);
        return (
          <span css={css(styles.failed)}>
            {alertCount && alertCount > 1
              ? `${alertCount} Alerts`
              : alertCount && alertCount === 1
              ? `${alertCount} Alert`
              : ''}
          </span>
        );
      },
    },
    {
      title: 'Controller SN',
      dataIndex: 'controllerSerial',
      width: '15%',
    },
    {
      title: 'Test Start',
      dataIndex: 'test',
      width: '15%',
      render: (_text: string, record: Equipment) => {
        const time = record.test
          ? moment(record.test).format(defaultDateFormat)
          : '';
        return <span>{time}</span>;
      },
    },
    {
      title: 'Test Completion',
      dataIndex: 'testCompletion',
      width: '15%',
      render: (_text: string, record: Equipment) => {
        const time = moment(record.testCompletion).format(defaultDateFormat);
        return <span>{record.testCompletion ? time : ''}</span>;
      },
    },
    {
      title: 'Test Results',
      dataIndex: 'testDataUrl',
      width: '8.5%',
      render: (url: string) => {
        return url ? (
          <button
            css={css(styles.link)}
            onClick={async () => await downloadFile(url)}
          >
            Test Results
          </button>
        ) : null;
      },
    },
  ];

  return cols;
};

export class _ManufactureProcess extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const equipment = new _Equipment();

    const blurCheck = async () => {
      const {
        form: { getFieldValue },
      } = this.props;
      const allEquipment = this.state.allEquipments;
      const str1 = this.getFieldKey('controllerSerial');
      const that1 = get(this, str1);
      const controllerSerialInput = getFieldValue(that1.var)
        .trim()
        .toUpperCase();

      const ignore = this.ignoreValidation();

      const str2 = this.getFieldKey('equipmentSN');
      const that2 = get(this, str2);
      const equipmentSNInput = getFieldValue(that2.var)
        .trim()
        .toUpperCase();
      const equipWithSN = getAllEquipmentWithEquipSN(
        equipmentSNInput,
        allEquipment
      );

      const controllerAssocWithEquip = checkEquipForController(
        controllerSerialInput,
        allEquipment
      );

      const equipWithESNandECS = getEquipWithControllerandEquipSN(
        equipmentSNInput,
        controllerSerialInput,
        allEquipment
      );

      const equipHasOtherController = checkEquipHasDifferentController(
        controllerSerialInput,
        equipmentSNInput,
        allEquipment
      );

      if (!controllerSerialInput || !equipmentSNInput || ignore) return;

      //scenario 4
      if (
        !controllerAssocWithEquip &&
        !!size(equipWithSN) &&
        equipHasOtherController
      ) {
        return await this.openEquipHasDiffControllerModal(
          equipWithSN,
          controllerSerialInput
        );
      }
      //scenario 5
      if (!!equipWithESNandECS) {
        return await this.openEquipExistsModal(
          controllerSerialInput,
          equipWithESNandECS
        );
      }
      //scenario 2 and 3

      this.checkControllerSerialNumber(
        controllerSerialInput,
        equipmentSNInput,
        equipWithSN
      );
    };

    const setFieldError = (
      key: string,
      errors: undefined | Error[] = undefined
    ) => {
      const {
        form: { setFields, getFieldValue, getFieldError },
      } = this.props;

      const value = getFieldValue(key);
      const str = this.getFieldKey(key);

      const hasErrors = !!size(errors);

      const ico = hasErrors ? (
        <Icon type={'close-circle'} css={css(`color: ${colors.error};`)} />
      ) : (
        <Icon type={'check-circle'} css={css(`color: ${colors.success};`)} />
      );

      set(this, str + '.props.suffix', ico);

      setFields({
        [key]: {
          value,
          errors: size(errors) ? errors : undefined,
        },
      });
    };

    const generateDisplay2 = (modelId?: string) => {
      const models = get(this.props.sState, 'dash.models', []) as Model[];
      const model = models.find(m => m.id === modelId);
      const mHeads = get(model, 'heads', 1);
      const isCompressor = modelId ? modelId[0] === 'C' : false;
      const eqType = this.getEqType();

      const toDisplay2 = [];

      if (!modelId) {
        //do nothing
      } else if (isCompressor) {
        for (let i = 0; i < mHeads; i++) {
          const ii = i + 1;
          toDisplay2.push({
            title: `Motor Head ${ii} Serial Number`,
            var: `mSerial${ii}`,
            options: {
              rules: [
                {
                  required: true,
                  message: `Motor Head ${ii} is required`,
                },
              ],
            },
            subtext: () =>
              'REMINDER: motor heads should be scanned in order from left to right',
          });
        }

        toDisplay2.push({
          title: `Tank Serial Number`,
          var: `tankSerial`,
          options: {
            rules: [
              {
                required: true,
                message: `Tank Serial Number is required`,
              },
            ],
          },
        });

        toDisplay2.push({
          title: `Dryer Serial Number`,
          var: `dryerSerial`,
          options: {
            rules: [
              {
                required: true,
                message: `Dryer Serial Number is required`,
              },
            ],
          },
        });
      } else if (eqType === 'handpiece') {
        toDisplay2.push({
          title: `Motor Control Board`,
          var: `mSerial_motorboard`,
          options: {
            rules: [
              {
                required: true,
                message: `Motor Control Board is required`,
              },
            ],
          },
          props: {
            onBlur: async () => {
              const {
                form: { setFields, getFieldValue, getFieldError },
              } = this.props;

              const key = 'mSerial_motorboard';
              let value = getFieldValue(key);

              if (!value) return;

              const ignore = this.ignoreValidation();
              const isValid = motorControlBoardTests(value.toUpperCase());

              const setTheFields = (errs: undefined | Error[] = undefined) => {
                setTimeout(() => setFieldError('mSerial_motorboard', errs), 0);
              };

              if (ignore || !size(isValid)) {
                setTheFields(undefined);
                // blurCheck();
              } else {
                setTheFields([first(isValid) as Error]);
              }
            },
          },
        });

        toDisplay2.push({
          title: `Lock Presets`,
          var: `lockPresets`,
          type: 'checkbox',
        });
      } else if (eqType == 'chair') {
        //nothing yet
      } else {
        toDisplay2.push({
          title: `Motor Serial Number`,
          var: `mSerial1`,
          options: {
            rules: [
              {
                required: true,
                message: `Motor SN is required`,
              },
            ],
          },
        });

        toDisplay2.push({
          title: `Vacuum Pump Serial Number`,
          var: `pumpSerial`,
          options: {
            rules: [
              {
                required: true,
                message: `Vacuum Pump Serial Number is required`,
              },
            ],
          },
        });
      }

      this.setState({
        toDisplay2: (toDisplay2 as unknown) as IFormItem[],
      });
    };

    const userEqOptions = get(props, 'sState.auth.user.eqTypeOptions', [
      'utility',
    ]);
    const hasOneOption = size(userEqOptions) === 1;

    const toDisplay = ([
      {
        title: 'Equipment Type',
        var: 'eqType',
        type: 'dropdown',
        opts: () =>
          [
            { label: 'Utility', value: 'utility' },
            { label: 'Handpiece', value: 'handpiece' },
            { label: 'Equipment', value: 'chair' },
          ].filter(f => userEqOptions.indexOf(f.value) > -1),
        props: {
          initialValue: hasOneOption ? first(userEqOptions) : 'utility',
          disabled: size(userEqOptions) <= 1,
          onChange: (v: string) => {
            this.setFieldsOriginal();
            this.retrieveEquipments();
            generateDisplay2();
          },
        },
      },
      {
        title: 'Particle Controller Serial Number',
        var: 'controllerSerial',
        options: {
          rules: [
            {
              required: true,
              message: 'Particle Controller Serial Number is required.',
            },
          ],
        },
        props: {
          suffix: <span />,
          onChange: (e: any) => {
            const val = e.target.value;
            
            const checks = [
              size(val) >= 30, 
              size((val || '').split(' ')) > 1
            ]

            if (size(checks.filter(c => !!c)) >= size(checks)) { //all true, so this means its from a QR code scan, and we only want the beginning part of this value
              const realVal = (val || '').split(' ')[0];
              message.info('QR code recognized');
              setTimeout(() => {
                this.props.form.setFieldsValue({ controllerSerial: realVal });
              }, 50)
            }
          },
          onBlur: async () => {
            const {
              form: { setFields, getFieldValue },
            } = this.props;
            const str = this.getFieldKey('controllerSerial');
            const that = get(this, str);
            const str2 = 'state.toDisplay2.0';
            const that2 = get(this, str2);
            let value = getFieldValue(that.var);
            if (!value) return;

            set(that, 'props.suffix', <Icon type="loading" />);
            this.setState({ ...this.state });
            setFields({
              controllerSerial: {
                value,
                errors: undefined,
              },
            });
            const serial = await getSerial(value);
            const ignore = this.ignoreValidation();

            set(this, 'state.serial', serial);
            const isValid = ignore || (!!serial as unknown);
            if (isValid === -1) {
              value = getFieldValue(that.var);
              setFields({
                controllerSerial: {
                  value,
                  errors: undefined,
                },
              });
              set(this, str + '.props.suffix', <span />);
            } else if (isValid) {
              value = getFieldValue(that.var);
              setFields({
                controllerSerial: {
                  value,
                  errors: undefined,
                },
              });
              set(
                this,
                str + '.props.suffix',
                <Icon
                  type={'check-circle'}
                  css={css(`color: ${colors.success};`)}
                />
              );
            } else {
              value = getFieldValue(that.var);
              setFields({
                controllerSerial: {
                  value,
                  errors: [
                    new Error(
                      'Controller serial number entered can not be found. Please re-scan or re-enter controller serial number or contact an Admin.'
                    ),
                  ],
                },
              });
              set(
                this,
                str + '.props.suffix',
                <Icon
                  type={'close-circle'}
                  css={css(`color: ${colors.error};`)}
                />
              );
            }
            this.setState({ ...this.state });
            if (getFieldValue(get(that2, 'var'))) {
              blurCheck();
            }
          },
        },
      },
      {
        title: () => {
          let value = this.getEqType();
          return value == 'utility'
            ? `Equipment Serial Number`
            : `${uppercaseFirst(value)} Serial Number`;
        },
        var: `equipmentSN`,
        options: () => ({
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: `${
                this.getEqType() == 'utility'
                  ? 'Equipment'
                  : uppercaseFirst(this.getEqType())
              } Serial Number is required`,
            },
          ],
        }),
        props: {
          onChange: (e: any) => {
            const val = e.target.value;
            
            const checks = [
              size(val) >= 34, 
              size((val || '').split('/')) > 1,
              val.indexOf('$+BU')
            ]

            if (size(checks.filter(c => !!c)) >= size(checks)) { //all true, so this means its from a QR code scan, and we only want the middle part of this value
              const middle = (val || '').split('/')[1];
              const realVal = middle.split('$+')[1];
              message.info('QR code recognized');
              setTimeout(() => {
                this.props.form.setFieldsValue({ equipmentSN: realVal });
              }, 50)
            }

            const checks2 = [
              size(val) >= 34, 
              size((val || '').split(']')) > 1,
              val.indexOf('$]ABU')
            ]

            if (size(checks2.filter(c => !!c)) >= size(checks2)) { //all true, so this means its from a QR code scan, and we only want the middle part of this value
              const middle = (val || '').split(']')[2];
              const realVal = middle.split('&')[0];
              message.info('QR code recognized');
              setTimeout(() => {
                this.props.form.setFieldsValue({ equipmentSN: realVal });
              }, 50)
            }
          },
          onBlur: async () => {
            const {
              form: { setFields, getFieldValue, getFieldError },
            } = this.props;
            const str = this.getFieldKey('equipmentSN');
            const that = get(this, str);
            let value = getFieldValue(that.var);
            let eqType = this.getEqType();

            if (!value) return;

            const ignore = this.ignoreValidation();
            const isValid = _checkEquipmentSN(
              value.toUpperCase(),
              undefined,
              eqType
            );

            const setTheFields = (errs: undefined | Error[] = undefined) => {
              setTimeout(() => {
                setTimeout(() => setFieldError('equipmentSN', errs), 0);
              }, 0);
            };

            if (ignore || !size(isValid)) {
              setTheFields(undefined);
              blurCheck();
            } else {
              setTheFields([first(isValid) as Error]);
            }
          },
        },
      },
      {
        title: 'Equipment Model',
        var: 'modelId',
        type: 'dropdown',
        opts: () => {
          const {
            form: { getFieldValue, setFieldsValue },
          } = this.props;
          const key = this.getFieldKey('equipmentSN');
          const snFieldItem = get(this, key);
          let sn = (getFieldValue(snFieldItem.var) || '').toLowerCase();

          const allModels = get(this, 'state.models', [])
            .filter((f: Model) => get(f, 'showOnManu', true))
            .map((o: Model) => ({
              ...o,
              label: `${o.name} (${o.id})`,
              value: o.id,
            }))
            .sort(sortByValue) as Model[];

          const ignore = this.ignoreValidation();
          const eqType = this.getEqType();

          const retOpts = allModels.filter(o => {
            if (ignore) {
              return true;
            }

            if (eqType === 'handpiece') {
              return o.type === 'handpiece';
            } else if (eqType === 'chair') {
              return o.type === 'chair';
            } else {
              // rules from DEN-808
              if (!sn || sn.length <= 2) {
                return true;
              } else if (sn.startsWith('acr')) {
                return o.id && o.id.startsWith('C');
              } else if (sn.startsWith('abn')) {
                return (
                  o.type &&
                  o.type.startsWith('vac') &&
                  o.id &&
                  o.id.toLowerCase() !== 'rv4-12'
                );
              } else if (sn.startsWith('aqt')) {
                return o.id && o.id.toLowerCase() === 'rv4-12';
              }

              return o.type !== 'handpiece' && o.type !== 'chair';
            }
          });

          if (size(retOpts) === 1) {
            setTimeout(() => {
              if (!getFieldValue('modelId')) {
                const modelId = get(retOpts, '0.value');
                setFieldsValue({ modelId });
                generateDisplay2(modelId);
              }
            }, 0);
          }

          return retOpts;
        },
        props: {
          onChange: (modelId: string) => {
            generateDisplay2(modelId);
          },
        },
        options: {
          rules: [
            {
              required: true,
              message: 'Equipment Model is required.',
            },
          ],
        },
      },
      {
        title: 'Includes SS',
        var: 'include_ss',
        type: 'checkbox',
        canDisplay: () => {
          const {
            form: { getFieldValue },
          } = this.props;
          const validvalues = getAppConfig('ss_values', []);
          const modelVal = getFieldValue('modelId');
          return validvalues.indexOf(modelVal) > -1;
        },
      },
    ] as unknown) as IFormItem[];

    this.state = {
      isEdit: true,
      canEditRole: false,
      loading: false,
      loadingEquipments: false,
      error: undefined,
      success: undefined,
      equipment,
      equipments: [],
      allEquipments: [],
      serial: undefined,
      toDisplay,
      toDisplay2: [],
      modelId: '',
      models: [],
      existingEquipment: undefined,
      eqToDelete: undefined,
      tutModal: { open: false }
    } as IState;
  }

  //scenario 4
  openEquipHasDiffControllerModal = (
    equipWithSN: Equipment[],
    controllerSerialInput: string
  ) => {
    return new Promise<void>(resolve => {
      Modal.confirm({
        title: (
          <p css={css(`color: ${colors.error};`)}>
            {
              'Equipment is already associated with a different\ncontroller serial number'
            }
          </p>
        ),
        icon: (
          <Icon type={'exclamation-circle'} style={{ color: colors.error }} />
        ),
        content:
          'Would you like to DELETE previous equipment record and continue with entered controller serial number?',
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: async () => {
          if (!!size(equipWithSN)) {
            const serial = await getSerial(controllerSerialInput);
            this.setState({ eqToDelete: equipWithSN, serial });
          }

          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  };
  // scenario 5
  openEquipExistsModal = (
    controllerSN: string,
    existingEquipment: Equipment
  ) => {
    return new Promise<void>(resolve => {
      Modal.info({
        title:
          'Controller serial number is already associated with this equipment.',
        content:
          'Submitting this form will update applicable serial numbers and \
          re-run manufacturing test.',
        okText: 'Continue',
        onOk: async () => {
          const serial = await getSerial(controllerSN);
          this.setState({ serial, existingEquipment });
          resolve();
        },
      });
    });
  };

  checkControllerSerialNumber = async (
    controllerSN: string,
    equipSN = '',
    equipWithSN: Equipment[] | undefined
  ) => {
    return new Promise(async resolve => {
      const allEquipment = this.state.allEquipments;
      const canOpenModal = checkContAssocWithOtherEquip(
        controllerSN.toUpperCase(),
        equipSN,
        allEquipment
      );
      const serial = await getSerial(controllerSN.toUpperCase());

      // 0, not valid, doesn't exist
      // 1, is already associated with a piece of equip. Open modal (scenario 3)
      // 2, good to go (scenario 2)
      const option = canOpenModal ? 1 : !serial ? 0 : 2;
      const openModal = () => {
        Modal.info({
          title:
            'Controller serial number is already associated with a different piece of equipment.',
          content:
            // tslint:disable-next-line: max-line-length
            !!size(equipWithSN)
              ? // tslint:disable-next-line: max-line-length
                `Submitting this form will update the entered equipment record with the entered Particle controller serial number. Controller serial number will be removed from any previous records.`
              : // tslint:disable-next-line: max-line-length
                `Submitting this form will create a new Equipment record using the same Particle controller serial number. Controller serial number will be removed from any previous records.`,
          okText: 'Continue',
          onOk: async () => {
            resolve(true);
          },
        });
      };

      this.setState({ serial, eqToDelete: equipWithSN });
      if (option === 0) {
        resolve(false);
      } else if (option === 1) {
        openModal();
      } else if (option === 2) {
        resolve(true);
      }
    });
  };

  retrieveEquipments = async (fromState = true) => {
    try {
      this.setState({ loadingEquipments: true });
      const allEquipments = await getEquipments(undefined, false, fromState);
      const eqtype = this.getEqType();

      const isType = (e: Equipment) => {
        const etype = getEqType(e);
        if (eqtype === 'utility') {
          const types = ['compressor', 'vacuum', 'unknown'];
          return types.indexOf(etype) > -1;
        } else {
          return eqtype == etype;
        }
      };

      const equipments = (allEquipments
        .map(e => {
          if (e.test || e.testCompletion) {
            const now = moment();
            const date = moment(e.testCompletion || e.test); //we do this so that we can actually show some data on qa
            const diff = now.diff(date, 'days');
            e._testDiff = diff;
          } else {
            e._testDiff = undefined;
          }
          return e as Equipment;
        })
        .filter(e => {
          const passesdiff = isProd() ? (e._testDiff !== undefined && e._testDiff <= 7 ? true : false) : !!e.test; //force things to showup for manu on qa

          return !!e.modelId && passesdiff && isType(e);
        })) as Equipment[];
      this.setState({
        equipments: orderBy(equipments, 'test', 'desc'),
        allEquipments,
        loadingEquipments: false,
      });
    } catch (err) {
      this.setState({
        loadingEquipments: false,
      });
    }
  };

  openChairModal = (record = {}) => {
    return new Promise<void>((resolve, reject) => {
      try {
        const radioStyle = {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        };
  
        let addState = {
          mount: 'chair',
          equipped: true,
        }
  
        const generateTutModal = (obj: any = {}) => {
          const cconfig = get(obj, 'chairconfig', get(record, '_status.chair_config', {}));
          const model = get(this, 'props.sState.dash.models', []).find((m: Model) => m.id == '6400');
  
          const firmwares = [
            { val: get(cconfig, 'fw_version'), title: 'Firmware: ', prefix: 'v', comp: get(model, 'fw_version') },
            { val: get(cconfig, 'data.control_board_fw'), prefix: '', title: 'CB Firmware: ', comp: get(model, 'control_board_fw') },
            { val: get(cconfig, 'data.control_board_hw'), prefix: '', title: 'CB Hardware: ', comp: get(model, 'control_board_hw') },
          ].map(c => {
            return {
              ...c,
              isValid: c.val == c.comp,
            }
          })

          const allValid = size(firmwares.filter(f => f.isValid)) >= size(firmwares);
  
          this.setState({
            tutModal: {
              open: true,
              onClose: (tutstate: {}) => {
                this.setState({ tutModal: { open: false } });
                reject({ ...tutstate, sensors: getSensors(), fail: true });
              },
              screens: [
                {
                  title: 'Verify Hardware & Firmware',
                  body: () => (
                    <div css={css(styles.textContainer)}>
                      <div className='text'>
                        <b>Confirm that the installed hardware and firmware is using the latest versions</b>
                        <br /><br />
                        <Button title='Get Config' onClick={getChairConfig} loading={obj.updateLoading} />
                        <br /><br />
                        {firmwares.map(v => <div><span style={!v.isValid ? { fontWeight: 'bold', color: colors.error } : {}}>{`${v.title}${v.prefix || ''}${v.val}`}</span><span>{`${!v.isValid ? ` (should be ${v.prefix || ''}${v.comp})` : ''}`}</span></div>)}
                      </div>
                    </div>
                  ),
                  buttons: [
                    { 
                      title: 'FAIL', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.error, borderColor: colors.error },
                      onClick: (p: any) => {
                        p.events.close();
                      }
                    },
                    { 
                      title: 'PASS', 
                      type: 'primary',
                      size: 'large',
                      disabled: !allValid,
                      style: !allValid ? {} : { backgroundColor: colors.success, borderColor: colors.success },
                      onClick: (p: any) => {
                        p.events.next();
                      }
                    },
                  ]
                },
                {
                  title: 'Calibration & Manual Inspections',
                  body: () => (
                    <div css={css(styles.textContainer)}>
                      <div className='imageContainer'><img src={'https://particle-images.s3.amazonaws.com/touchpad.png'} /></div>
  
                      <div className='text'>
                        <b>After calibration sequence has completed, perform the following checks:</b>
                        <br /><br />
                        
                        <i>Manual arrow buttons</i><br /><br />
                        <ol>
                          <li>Depress and hold one of the arrow buttons.  Verify the chair responds with a single chime and moves continuously.</li>
                          <li>Verify the chair halts movement when it reaches the maximum travel for the selected direction.</li>
                          <li>Depress the same arrow button; verify chair did not move and responded with an error chime.</li>
                          <li>Repeat steps 1-3 for the remaining arrow buttons.</li>
                        </ol>
                        
                        <i>Preset Verification (1,2,3 and A)</i><br /><br />
                        <ol>
                          <li>Depress one of the preset buttons; verify chair responds with a single chime then moves to the desired preset location and responds with a double chime when preset location is achieved.</li>
                          <li>Verify movement halts when pressing another preset or arrow button and responds with a single chime.</li>
                          <li>Repeat steps 1-2 for the remaining preset buttons.</li>
                        </ol>
                        
                        <i>Last Position (L)</i><br /><br />
                        <ol>
                          <li>Depress ‘L’ button for Last Position; verify chair responds with a single chime then moves to the last selected preset location and responds with a double chime when last location is achieved.</li>
                        </ol>
                      </div>
                    </div>
                  ),
                  buttons: [
                    { 
                      title: 'FAIL', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.error, borderColor: colors.error },
                      onClick: (p: any) => {
                        p.events.close();
                      }
                    },
                    { 
                      title: 'PASS', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.success, borderColor: colors.success },
                      onClick: (p: any) => {
                        p.events.next();
                      }
                    },
                  ]
                },
                {
                  title: 'Chair Functionality Tests',
                  body: () => (
                    <div css={css(styles.textContainer)}>
                      <div className='imageContainer'><img src={'https://particle-images.s3.amazonaws.com/pendant.png'} /></div>
  
                      <div className='text'>
                        Cycle heat selection; confirm pendant LEDs illuminate as shown.
                        <br /><br />
                        Cycle massage selection and feel backrest for vibration; confirm pendant LEDs illuminate as shown.
                        <br /><br />
                        <b>If both tests pass, select PASS button below.  If either or both tests fail, select FAIL button below.</b>
                      </div>
                    </div>
                  ),
                  buttons: [
                    { 
                      title: 'FAIL', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.error, borderColor: colors.error },
                      onClick: (p: any) => {
                        p.events.close();
                      }
                    },
                    { 
                      title: 'NOT EQUIPPED', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.warning, borderColor: colors.warning },
                      onClick: (p: any) => {
                        addState.equipped = false;
                        p.events.next();
                      }
                    },
                    { 
                      title: 'PASS', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.success, borderColor: colors.success },
                      onClick: (p: any) => {
                        p.events.next();
                      }
                    },
                  ]
                },
                {
                  title: 'Seat Occupancy',
                  body: () => (
                    <div css={css(styles.textContainer)}>
                      <div className='text center'>
                        Add weight to rear area of seat cushion, then press ‘Update’ to trigger data publish.  Once occupancy is detected in data publish, PASS button will be enabled.
                        <br /><br />
                        <Button title='UPDATE' onClick={updateChair} loading={obj.updateLoading} />
                        <br />
                        <div>
                          {!!obj.canContinue && 'Occupancy detection is working as expected.  Please remove weight before proceeding.'}
                          {!obj.canContinue && <br />}
                        </div>
                      </div>
                    </div>
                  ),
                  buttons: [
                    { 
                      title: 'FAIL', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.error, borderColor: colors.error },
                      onClick: (p: any) => {
                        p.events.close();
                      }
                    },
                    { 
                      title: 'PASS', 
                      type: 'primary',
                      size: 'large',
                      disabled: !obj.canContinue,
                      style: !obj.canContinue ? {  } : { backgroundColor: colors.success, borderColor: colors.success },
                      onClick: (p: any) => {
                        p.events.next();
                      }
                    },
                  ]
                },
                {
                  title: 'Sensor Validation',
                  body: () => (
                    <div css={css(styles.textContainer)}>
                      <div className='text center'>
                        Plug in air pressure sensor fitting to compressor, then start compressor (depressurize if needed).  Plug in vacuum sensor fitting to vacuum, start vacuum, set vacuum to 7 inHg. Verify that all sensors are connected and working as expected.
                        <br /><br />
                        <Button title='UPDATE' onClick={updateSensorChair} loading={obj.sensorLoading} />
                        <br /><br />
                        {!!obj.sensors && <div>
                          <ul style={{ marginBottom: 0, textAlign: 'left' }}>
                            {obj.sensors.map((s: any) => (<li>{s.title}: <span>{s.passed ? 'PASS' : 'FAIL'}</span></li>))}
                          </ul>
                        </div>}
                        {!obj.sensors && [0, 0, 0, 0].map(a => <br />)}
                      </div>
                    </div>
                  ),
                  buttons: [
                    { 
                      title: 'FAIL', 
                      type: 'primary',
                      size: 'large',
                      style: { backgroundColor: colors.error, borderColor: colors.error },
                      onClick: (p: any) => {
                        p.events.close();
                      }
                    },
                    { 
                      title: 'PASS', 
                      type: 'primary',
                      size: 'large',
                      disabled: !obj.sensorContinue,
                      style: !obj.sensorContinue ? {  } : { backgroundColor: colors.success, borderColor: colors.success },
                      onClick: (p: any) => {
                        p.events.next();
                      }
                    },
                  ]
                },
                {
                  title: 'Chair Installation',
                  body: () => (
                    <div css={css(styles.textContainer)}>
                      <div className='text center'>
                        Select the correct configuration for how the Chair will be installed:
                        <br /><br />
                        <Radio.Group onChange={e => { addState.mount = e.target.value; generateTutModal(obj); }} value={addState.mount}>
                          <Radio style={radioStyle} value={'chair'}>
                            Chair mount
                          </Radio>
                          <Radio style={radioStyle} value={'non_chair'}>
                            Non-chair mount
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  ),
                  buttons: [
                    { 
                      title: 'SUBMIT', 
                      type: 'primary',
                      size: 'large',
                      disabled: !obj.sensorContinue,
                      style: !obj.sensorContinue ? {  } : { backgroundColor: colors.success, borderColor: colors.success },
                      onClick: (p: any) => {
                        this.setState({ tutModal: { open: false } });
                        resolve({ ...p, ...obj, ...addState })
                      }
                    },
                  ]
                },
              ]
            }
          })
        }
  
        let latestStat = {};
  
        const getSensors = (stat = latestStat) => {
          const sensors = [
            { title: `Seat Sensor`, passed: !get(stat, 'in_chair'), val: "" },
            { title: `Ambient Temperature`, passed: !!get(stat, 'AT'), val: get(stat, 'AT', null), suffix: " °C" },
            { title: `Ambient Humidity`, passed: !!get(stat, 'AH'), val: get(stat, 'AH', null), suffix: "%" },
            { title: `Pressure`, passed: isBetween(get(stat, 'LP') || 0, [53, 67]), val: get(stat, 'LP', null), suffix: " PSI" },
            { title: `Vacuum`, passed: isBetween(get(stat, 'LV') || 0, [6.2, 7.8]), val: get(stat, 'LV', null), suffix: " inHg" },
          ]
  
          return sensors;
        }
        
        const deviceId = get(this, 'state.serial.deviceId');
  
        const getChairConfig = async () => {
          try {
            generateTutModal({ updateLoading: true, canContinue: false });
            
            latestStat = await checkForNewStatus({ deviceId } as Equipment, {
              key: 'chair_config.published_at',
              hasOriginal: () => {
                sendDeviceCommand(deviceId, { arg: 'get_config' }, { showErrorToast: false }).catch(e => 0)
              },
            }) as unknown as {};
    
            const chairconfig = get(latestStat, '_status.chair_config', {});
  
            console.log({
              latestStat,
            })
    
            generateTutModal({ updateLoading: false, chairconfig })
          } catch (err) {
            message.error(`Unable to communicate with equipment`);
            generateTutModal({ updateLoading: false, canContinue: false })
          }
        }
    
        const updateChair = async () => {
          try {
            generateTutModal({ updateLoading: true, canContinue: false });
            
            latestStat = await checkForNewStatus({ deviceId } as Equipment, {
              key: 'chair_sensor_instant.published_at',
              hasOriginal: () => {
                sendDeviceCommand(deviceId, { arg: 'get_data' }, { showErrorToast: false }).catch(e => 0)
              },
            }) as unknown as {};
    
            const val = get(latestStat, '_status.chair_sensor_instant.data.in_chair', false);
  
            console.log({
              latestStat,
            })
    
            generateTutModal({ updateLoading: false, canContinue: val })
          } catch (err) {
            message.error(`Unable to communicate with equipment`);
            generateTutModal({ updateLoading: false, canContinue: false })
          }
        }
    
        const updateSensorChair = async () => {
          try {
            generateTutModal({ sensorLoading: true, sensorContinue: false });
            
            const key = 'chair_sensor_instant';
      
            latestStat = get(await checkForNewStatus({ deviceId } as Equipment, {
              key: `${key}.published_at`,
              hasOriginal: () => {
                sendDeviceCommand(deviceId, { arg: 'get_data' }, { showErrorToast: false }).catch(e => 0)
              },
            }), `_status.${key}.data`);
            
            const sensors = getSensors();
  
            console.log({
              stat: latestStat,
              sensors,
            })
      
            const allpassed = sensors.filter(s => s.passed).length >= sensors.length;
      
            generateTutModal({ sensorLoading: false, hasStats: true, sensors, sensorContinue: allpassed })
          } catch (err) {
            message.error(`Unable to communicate with equipment`);
            generateTutModal({ sensorLoading: false, sensorContinue: false });
          }
        }
    
        generateTutModal();
      } catch (err) {
        console.error(err);
        reject(err);
      }
    })
  }

  // submit = async () => {
  //     // await this.clear();
  //     // this.retrieveEquipments();
  //     this.openChairModal();
  // }

  submit = debounce(
    async (e: MouseEvent | FormEvent) => {
      e.preventDefault();
      if (this.state.loading) {
        return;
      }

      const state = { ...this.state };

      state.loading = true;
      state.error = undefined;
      state.success = undefined;
      this.setState(state);

      const {
        form,
        form: { getFieldError },
      } = this.props;

      const cSerialError = getFieldError('equipmentSN');
      if (cSerialError) {
        return this.setState({
          error: cSerialError.toString(),
          loading: false,
        });
      }

      form.validateFieldsAndScroll(async (err, values) => {
        if (err) {
          return this.setState({
            error: buildErrorMsgFromForm(err),
            loading: false,
          });
        }

        try {
          const openConfirmModal = () => {
            const openerText =
              'Please review and confirm that the correct model has been selected and that all \
            serial numbers scanned or entered for Particle controller and equipment parts are \
            accurate. If any data is not accurate, close this confirmation and update the data.';

            const genFieldsText = () => {
              const { toDisplay, toDisplay2 } = this.state;
              let text: ReactElement[] = [];
              [...toDisplay, ...toDisplay2].map(td => {
                const canDisplay = td.canDisplay ? td.canDisplay() : true;
                if (canDisplay) {
                  const val = get(values, td.var, false);
                  const title =
                    typeof td.title === 'function' ? td.title() : td.title;
                  text.push(
                    <li>
                      <span css={css('font-weight: bold')}>{`${title}: `}</span>
                      {`${val}`}
                    </li>
                  );
                }
              });
              return text;
            };

            Modal.confirm({
              title: 'Confirm Equipment Details',
              width: '80%',
              content: (
                <div>
                  <p>{openerText}</p>
                  <ul>{genFieldsText().map(t => t)}</ul>
                </div>
              ),
              okText: 'Start Test',
              onCancel: () => {
                this.setState({ loading: false });
              },
              onOk: () => {
                this.startTest(values);
              },
            });
          };
          openConfirmModal();
        } catch (err) {
          return this.setState({
            error: 'Save failed: ' + err.message,
            loading: false,
          });
        }
      });
    },
    500,
    { leading: false }
  );

  ignoreValidation = () => {
    const {
      form: { getFieldValue },
    } = this.props;
    const val = getFieldValue('equipmentSN');
    return (val || '').toLowerCase().indexOf('proto') > -1;
  };

  // tslint:disable-next-line: no-any
  startTest = async (values: any) => {
    try {
      const { sState } = this.props;
      const {
        toDisplay,
        toDisplay2,
        existingEquipment,
        eqToDelete,
      } = this.state;
      const me = get(sState, 'auth.user');
      const eq = new _Equipment(existingEquipment);
      let mid = get(values, 'modelId');
      if (!!get(values, 'include_ss')) {
        mid += 'SS';
      }
      const models = get(sState, 'dash.models', []) as Model[];
      const model = models.find(m => m.id === mid) as Model;
      const modelType = get(model, 'type') as string;
      const serial = get(values, 'controllerSerial').toUpperCase();

      const eqType = this.getEqType();

      [...toDisplay, ...toDisplay2].map(td => {
        const canDisplay = td.canDisplay ? td.canDisplay() : true;
        if (canDisplay) {
          const val = get(values, td.var);
          set(eq, td.var, val);
        }
      });

      set(eq, 'modelId', mid);

      const equipmentSN = get(eq, 'equipmentSN', '')
        .toUpperCase()
        .trim();
      const mSerial1 = get(eq, 'mSerial1', '')
        .toUpperCase()
        .trim();
      const mSerial2 = get(eq, 'mSerial2', '')
        .toUpperCase()
        .trim();
      const mSerial3 = get(eq, 'mSerial3', '')
        .toUpperCase()
        .trim();
      const tankSerial = get(eq, 'tankSerial', '')
        .toUpperCase()
        .trim();
      const dryerSerial = get(eq, 'dryerSerial', '')
        .toUpperCase()
        .trim();
      const pumpSerial = get(eq, 'pumpSerial', '')
        .toUpperCase()
        .trim();

      set(eq, 'equipmentSN', equipmentSN);
      set(eq, 'controllerSerial', serial.trim());
      set(eq, 'name', equipmentSN);
      set(eq, 'tankSerial', tankSerial);
      set(eq, 'dryerSerial', dryerSerial);
      set(eq, 'pumpSerial', pumpSerial);

      if (model && model.heads !== undefined) {
        if (model.heads > 0) {
          set(eq, 'mSerial1', mSerial1);
        } else {
          set(eq, 'mSerial1', '');
        }

        if (model.heads > 1) {
          set(eq, 'mSerial2', mSerial2);
        } else {
          set(eq, 'mSerial2', '');
        }

        if (model.heads > 2) {
          set(eq, 'mSerial3', mSerial3);
        } else {
          set(eq, 'mSerial3', '');
        }
      }

      const deviceId = get(this, 'state.serial.deviceId');

      if (!deviceId) {
        throw new Error('No device ID supplied');
      } else {
        const date = new Date().toISOString();
        set(eq, 'deviceId', deviceId);
        set(eq, 'createdBy', me.userId);
        set(eq, 'createdAt', date);
        set(eq, 'updatedAt', date);
        set(eq, 'updatedBy', me.userId);
        set(eq, 'test', date);
        set(eq, 'testStartedBy', `${me.firstName} ${me.lastName}`);
        set(eq, 'testCompletion', null);
        set(eq, 'testDataUrl', null);
        set(eq, 'showOnDash', false);
        if (eq.serialNumber) {
          delete eq.serialNumber;
        }

        await createEquipment(eq).catch(err => {
          Bugsnag.notify(err);
          throw new Error(
            `Unable to create Equipment record.  Please try again, and contact Technical Support if the issue persists.`
          );
        });

        const retrieveHWVer = (deviceId: string) => {
          return new Promise(async (resolve, reject) => {
            const config: IAppConfig = CONFIG(process.env.ENV_STAGE as ENVS);
            const access_token = config.particle_key;
            const url = `https://api.particle.io/v1/products/9783/devices/${deviceId}/HW_VER?access_token=${access_token}`;
            const options = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            };
            try {
              const response = await axios.get(url, options);
              resolve(response.data);
            } catch (error) {
              reject(error);
            }
          });
        };

        // we want to try and get/set hw_version before the test begins
        const validHwRetrieve = ['utility'].includes(eqType); //this HW_VER command is only valid with utility eq
        if (!!deviceId && validHwRetrieve && eq.hw_version === undefined) {
          try {
            const response = await retrieveHWVer(deviceId);
            const hw_version = get(response, 'result');
            !!hw_version &&
              set(eq, 'hw_version', hw_version) &&
              (await updateEquipment(eq));
          } catch (error) {
            console.warn(`problem updating equipment hw_ver: ${error}`);
          }
        }

        const testCommand = generateTestCommand(eq, eqType);

        //just add chair here when chair is ready to go
        const sendCommands = ['utility', 'handpiece'].includes(eqType);

        if (sendCommands) {
          await sendDeviceCommand(
            deviceId,
            {
              arg: testCommand,
            },
            { showErrorToast: true },
            isHandpiece(eq)
          );

          if (get(values, 'lockPresets') && this.getEqType() === 'handpiece') {
            await sendDeviceCommand(
              deviceId,
              {
                arg: `{"cmd":"set_cfg", "cfg": {"config":{"dental_school": true}}}`,
              },
              { showErrorToast: true },
              true
            );
          }

          if (eqToDelete && !!size(eqToDelete)) {
            await Promise.all(eqToDelete.map(deleteEquipment));
          }
        } else if (eqType === 'chair') {
          const sendCalibrate = true;
          
          if (sendCalibrate) {
            const autocal = await sendDeviceCommand(deviceId, { arg: "chair_autocal" }, { showErrorToast: false }).catch(e => {
              return null;
            });

            const sensorcal = await sendDeviceCommand(deviceId, { arg: "sensor_cal" }, { showErrorToast: false }).catch(e => {
              return null;
            });
  
            if (!autocal || !sensorcal) {
              throw new Error('Unable to calibrate Equipment');
            }
          }

          const record = await getEquipmentRecord(deviceId, true);

          const chairData = await this.openChairModal(record).catch(e => e);

          if (chairData.mount === 'non_chair') {
            const chairmountcommand = await sendDeviceCommand(deviceId, { arg: "chair_mount,0" }, { showErrorToast: false }).catch(e => {
              return null;
            });

            if (!chairmountcommand) {
              throw new Error('Unable to calibrate Equipment');
            }
          }

          if (chairData.fail) {
            set(eq, 'status.test', null);
            set(eq, 'testCompletion', new Date().toISOString());
            await createEquipment(eq);
            throw new Error('Test Failed')
          } else {
            const pubSend = {
              "data": {
                "overall": 1,
                ...chairData,
              },
              "public": false,
              "coreid": deviceId,
              "event": "dz_testresults",
              "published_at": new Date().toISOString(),
              "userid": "manufacturing",
              "fw_version": 56
            };
  
            createPublish(pubSend);

            message.success(
              `Manufacturing test started for ${uppercaseFirst(
                modelType
              )} ${serial}`
            );
          }
        }

        this.setState(
          {
            loading: false,
          },
          async () => {
            await this.clear();
            this.retrieveEquipments();
          }
        );
      }
    } catch (err) {
      this.setState({ loading: false, error: err.message }, () => this.retrieveEquipments(false));
    }
  };

  retrieveModels = async () => {
    try {
      const models = await getModels();
      this.setState({ models: models.filter(m => !!get(m, 'isActive', 1)) });
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount = () => {
    this.retrieveEquipments();
    this.retrieveModels();
    this.setFieldsOriginal();
  };
  setFieldsOriginal = (flds = {}) => {
    const { toDisplay, equipment } = this.state;

    const {
      form: { setFieldsValue },
    } = this.props;

    const fields = {
      ...flds,
    };

    toDisplay.map(item => {
      let val = get(equipment, item.var, get(fields, item.var, get(item, 'props.initialValue')));

      if (item.match) {
        val = get(this, item.match, val);
      }

      set(
        fields,
        item.var,
        item.type === 'datepicker'
          ? val && (moment(val as string) as unknown)
          : val
      );
    });

    setFieldsValue(fields);

    const comp = get(this, 'controllerSerial');
    comp.select();
  };
  getFieldKey = (key: string, sel = `state.toDisplay`) => {
    const index = get(this, sel, []).findIndex(
      (f: { var: string }) => f.var == key
    );
    return `${sel}.${index}`;
  };
  getEqType = () => {
    const {
      form: { getFieldValue },
    } = this.props;
    return getFieldValue('eqType') || 'utility';
  };
  clear = () => {
    return new Promise((resolve) => {
      const state = { ...this.state };
      set(state, 'toDisplay.0.props.suffix', <span />);
      state.toDisplay2 = [];
      state.existingEquipment = undefined;
      state.serial = undefined;
      state.equipment = new _Equipment();
      state.eqToDelete = undefined;
      const eqType = this.props.form.getFieldValue('eqType');
      this.setState(state, () => {
        setTimeout(() => {
          this.setFieldsOriginal({ eqType });
          setTimeout(resolve, 50);
        }, 50)
      });
    })
  };
  deleteEquip = async (e: Equipment) => {
    await deleteEquipment(e);
    this.retrieveEquipments();
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const {
      toDisplay,
      toDisplay2,
      loading,
      error,
      success,
      loadingEquipments,
      equipments,
    } = this.state;

    return (
      <div css={css(styles.container)}>
        <FormTitle size={'20px'} title={`Manufacturing Test`} />

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: undefined })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: undefined })}
          />
        )}

        <div css={css(styles.container)}>
          <div css={css(styles.formContainer)}>
            <Form layout="vertical">
              {[...toDisplay, ...toDisplay2].map((item, i) => {
                const canDisplay = item.canDisplay ? item.canDisplay() : true;
                const loading = get(item, 'props.loading');
                return !canDisplay ? null : (
                  <Form.Item
                    key={i}
                    label={
                      typeof item.title === 'function'
                        ? item.title()
                        : item.title
                    }
                  >
                    {getFieldDecorator(
                      item.var,
                      typeof item.options === 'function'
                        ? item.options()
                        : item.options
                    )(
                      item.type === 'dropdown' && item.opts ? (
                        <Select {...item.props}>
                          {item.opts().map((opt, i) => (
                            <Select.Option key={i} value={opt.value}>
                              {opt.label}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : item.type === 'datepicker' ? (
                        <DatePicker />
                      ) : item.type === 'number' ? (
                        <InputNumber {...item.props} />
                      ) : item.type === 'checkbox' ? (
                        <Checkbox {...item.props} />
                      ) : (
                        <Input
                          ref={comp => set(this, item.var, comp)}
                          {...item.props}
                        />
                      )
                    )}
                    {loading && (
                      <Icon css={css(styles.itemLoading)} type="loading" />
                    )}
                    {item.subtext && (
                      <div css={css(styles.subtext)}>{item.subtext()}</div>
                    )}
                  </Form.Item>
                );
              })}

              <Button
                title={'Clear'}
                disabled={loading}
                css={css`
                  margin-right: 10px;
                `}
                outline={true}
                onClick={this.clear}
              />
              <Button
                title={'Start Manufacturing Test'}
                loading={loading}
                onClick={this.submit}
              />
            </Form>
          </div>
        </div>

        <Tutorial {...this.state.tutModal} />

        <div css={css(styles.container)}>
          <FormTitle size={'20px'} title={`Recently Tested Equipment`} />
          <TableList
            canSelectRows={false}
            columns={generateEquipColumns(this)}
            loading={loadingEquipments}
            data={equipments as never[]}
            implementScroll={false}
          />
        </div>
      </div>
    );
  }
}

export const ManufactureProcess = Form.create()(
  withRouter(_ManufactureProcess)
);
