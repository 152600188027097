/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker, message, Modal } from 'antd';
import { findTrial, extendTrial } from '../../utils';
import {
  Equipment,
  Subscription,
  SUBSCRIPTION_TYPE,
  _Equipment,
} from '../../_shared/interfaces/equipment';
import { updateEquipment } from '../../_shared/services/manage-equipment.service';
import { get, isEmpty } from 'lodash';
import styles from '../../_shared/styles';
import { AppState } from '../../app.state';
import colors from '../../_shared/colors';

interface IProps {
  close: () => void;
  visible: boolean;
  equipment: Equipment;
  appState: AppState;
  trialSub: Subscription;
}

const ExtendTrialModalComponent = (props: IProps): JSX.Element => {
  const { visible, close, trialSub, equipment } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [currentTrialEnd, setCurrentTrialEnd] = useState<Moment>(
    moment(trialSub.subscriptionEnd)
  );
  const [newTrialEnd, setNewTrialEnd] = useState<Moment | undefined>(undefined);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined
  );

  const submit = async () => {
    //sanity check
    if (!loading && !!newTrialEnd && newDateIsValid(newTrialEnd)) {
      setLoading(true);

      const extendTrialResult: [boolean, Equipment] = extendTrial(
        equipment,
        newTrialEnd
      );
      if (extendTrialResult[0]) {
        const newEquipmentRecord = extendTrialResult[1];
        await updateEquipment(newEquipmentRecord);
        setCurrentTrialEnd(newTrialEnd);
        setNewTrialEnd(undefined);
        close();
      } else {
        setValidationError('unknown error ocurred.');
      }

      setLoading(false);
    }
  };

  const onChangeDate = (value: Moment) => {
    if (!newDateIsValid(value)) {
      setValidationError(
        'Updated trial end date can not be ' +
          'prior to current End Date of ' +
          `${currentTrialEnd.format('MMM Do YYYY')}.`
      );

      setNewTrialEnd(undefined); //clear any previously selected new trial end
    } else {
      setValidationError(undefined); //clear error
      setNewTrialEnd(value);
    }
  };

  //make sure new date is not before the trial subs end --
  //the intention is to only allow _extending_ the trial
  const newDateIsValid = (m: Moment): boolean => {
    return !!m && m.toISOString() > trialSub.subscriptionEnd;
  };

  const saveButtonProps = { disabled: !!!newTrialEnd };

  return (
    <Modal
      visible={visible}
      title={'Extend Trial'}
      centered
      closable
      confirmLoading={loading}
      onCancel={close}
      okButtonProps={saveButtonProps}
      okText="Save"
      onOk={submit}
    >
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">
          Current Trial End Date: {currentTrialEnd.format('MMM Do YYYY')}
        </label>
      </div>
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">
          <span css={css(`color: ${colors.error};`)}>* </span>
          Updated Trial End Date
        </label>
        <div className="ant-col ant-form-item-control-wrapper">
          <DatePicker onChange={onChangeDate} value={newTrialEnd} />
          {!!validationError && (
            <p css={css(styles.error)}>{validationError}</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const ExtendTrialModal = ExtendTrialModalComponent;
