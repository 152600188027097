import colors from "../colors";

const BARHEIGHT = 70;
const FOOTERHEIGHT = BARHEIGHT + 50;
const PADDING = 10;

export default {
  container: `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    z-index: 1000;
  `,
  titleBar: `
    height: ${BARHEIGHT}px;
    width: 100%;
    border-bottom: 1px solid ${colors.disabledText};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${PADDING}px;

    .title {
      font-size: 30px;
      font-weight: bold;
    }

    .close {
      justify-self: flex-end;
      margin-left: auto;

      button {
        border: none !important;
        box-shadow: none !important;
      }

      i {
        font-size: 30px;
      }
    }
  `,
  bodyContainer: `
    position: absolute;
    top: ${BARHEIGHT}px;
    bottom: ${FOOTERHEIGHT}px;
    left: 0;
    right: 0;
    height: calc(100% - ${BARHEIGHT + FOOTERHEIGHT}px);
    padding: ${PADDING}px;
    overflow: auto;
  `,
  footerBar: `
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${FOOTERHEIGHT}px;
    border-top: 1px solid ${colors.disabledText};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${PADDING}px;
    background-color: white;

    .buttons {
      display: flex;
      flex-direction: row;
      
      button {
        &:first-child {
          margin-left: 0px;
        }
        margin-left: 120px;

        &.ant-btn-lg {
          height: 100px;
          padding: 0 80px;
          font-size: 30px;
        }
      }
    }
  `
}