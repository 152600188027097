import colors from '../colors';

export default {
  rowContainer: `
    min-height: 40px;
    padding: 0 0 3px 0;
  `,
  border: `
    border-top: 1px solid ${colors.inputBorder};
  `,
  header: `

  `,
  headerCol: `
    font-weight: bold;
  `,
  chevron: `
    position: absolute;
    right: 0;
    padding: 10px;
  `,
  openContainer: `
    width: 100%;
    margin: 5px;
    position: relative;
  `,
};
