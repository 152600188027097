/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useRef, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buildErrorMsgFromForm, cleanPhoneNumber, getSum, isLoggedIn, stringSimilarity, validPhoneNumber } from '../../utils';
import { AppState } from '../../app.state';
import { get, orderBy, size, set } from 'lodash';
import { message, Modal, Table } from 'antd';
import styles from '../styles';
import { Row } from '../../_shared/row';
import { Form } from '../../_shared/form';
import { FormComponentProps } from 'antd/lib/form';
import { Button } from '../../_shared/button';
import { createOrg, getOrgs } from '../../_shared/services/manage-orgs.service';
import { Org, _Org } from '../../_shared/interfaces/org';
import { StatesSelect } from '../../_shared/lib/states';
import { Equipment } from '../../_shared/interfaces/equipment';
import { Loading } from '../../_shared/icon';
import { renderAddress } from '../../dashboard/manage-orgs/manage-orgs.component';
import { updateUser } from '../../_shared/services/manage-users.service';
import { getUserData } from '../../authentication/authentication.actions';
import Logo from '../../_shared/logo';
import colors from '../../_shared/colors';

interface IProps {
  equipment?: Equipment;
  renderLoadedEquipment: (s?: {}) => ReactNode;
}

interface IState {
  orgs: Org[];
  loading: boolean;
  loadingOrgs: boolean;
  matchModal: any;
  defaults?: any;
}

export const OrgCreate = (props: IProps) => {
  const loggedIn = useSelector(s => isLoggedIn(s as AppState));
  const user = useSelector(s => get(s, 'auth.user'));
  const hasOrg = useSelector(s => get(s, 'auth.user.orgId'))
  const isPhone = useSelector(s => get(s, 'dash.view.isPhoneBreak'));
  let [state, setState] = useState({
    loading: false,
    loadingOrgs: true,
    orgs: [],
    matchModal: {},
    defaults: undefined,
  } as IState)
  
  const dispatch = useDispatch();

  const formRef = useRef();

  const cleanVals = (vals = {}) => {
    let name = (get(vals, 'name', '') || '').toLowerCase();

    const remove = [
      'dental',
      'dentistry',
      'llc',
      'DDS',
      'DMD',
      'smile',
      'care',
      'health',
      'clinic',
      'family',
    ]

    remove.map(r => name = name.split(r).join(''))

    return {
      ...vals,
      name,
    }
  }

  const searchThroughOrgs = (vals = {}) => {
    vals = cleanVals(vals);
    
    const fields = [
      { key: 'name', val: (o: Org) => stringSimilarity(get(vals, 'name', '').toLowerCase(), o.name.toLowerCase()) },
      { key: 'address', val: (o: Org) => stringSimilarity(get(vals, 'address.address', '').toLowerCase(), `${get(o, 'address.address', '')}`.toLowerCase()) },
      { key: 'address.zip', val: `${get(vals, 'address.zip', '')}`.toLowerCase() },
    ]

    const ret = state.orgs.filter(o => o.orgType === 1).map(o => {
      const matches = fields.map(f => {
        if (typeof f.val == 'function') {
          return f.val(o);
        }
        return `${get(o, f.key, '')}`.toLowerCase() == f.val ? 1 : 0;
      })

      return {
        ...o,
        matches,
      }
    }).filter(o => getSum(o.matches) >= 0.5).sort((a, b) => getSum(b.matches) - getSum(a.matches))

    return ret;
  }

  const onSubmit = (e: any, form: any) => {
    return new Promise<void>((resolve, reject) => {
      setState(s => ({ ...s, loading: true }))

      const finalize = (err?: any, displayMessage = false) => {
        setState(s => ({ ...s, loading: false }))

        if (!!err) {
          if (displayMessage) { message.error(err.message); }
          reject(err);
        } else {
          resolve()
        }
      }

      const FORM = form || formRef.current;

      FORM.validateFieldsAndScroll(async (err: any, vals: any) => {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          return finalize(new Error(error));
        }
  
        try {
          const continueAndCreate = async () => {
            try {
              setState(s => ({ ...s, loading: true }))
              const org = new _Org({ ...(state.defaults || {}), ...vals });
              org.orgType = 1;
              org.createdBy = org.createdBy || user.email;
              org.updatedBy = user.email;
              await createOrg(org as Org);
              await updateUser({ ...user, role: !!state.defaults ? 4 : 2, orgId: org.id });
              await dispatch(getUserData())
            } catch (err) {
              message.error(err);
            } finally {
              setState(s => ({ ...s, loading: false }))
            }
          }

          const matches = searchThroughOrgs(vals);

          if (size(matches) && state.defaults === undefined) {
            setState(s => ({ 
              ...s,
              matchModal: {
                vals,
                matches,
                visible: true,
                onRowClick: (r: Org) => {
                  FORM.setFieldsValue({ ...r });
                  state.defaults = r;
                  setState(s => ({ ...s, matchModal: {}, defaults: r }));
                  finalize();
                  onSubmit(null, null);
                },
                footer: null,
                okText: 'No match - my organization is different',
                onOk: async () => {
                  setState(s => ({ ...s, matchModal: {}, defaults: null }))
                  await continueAndCreate();
                  finalize();
                },
                onCancel: () => {
                  setState(s => ({ ...s, matchModal: {} }))
                  finalize();
                }
              }
            }))
          } else {
            await continueAndCreate();
            finalize();
          }
        } catch (err) {
          finalize(err, true);
        }
      })
    })
  }

  const getAllOrgs = async () => {
    try {
      const orgs = await getOrgs();
      setState(s => ({ ...s, orgs }))
    } catch (err) {
      message.error(err);
    } finally {
      setState(s => ({ ...s, loadingOrgs: false })) 
    }
  }

  useEffect(() => {
    if (loggedIn) {
      getAllOrgs();
    }
  }, [loggedIn])

  if (loggedIn && !hasOrg) {
    if (state.loadingOrgs) { return <Loading /> }

    const formProps = {
      ref: formRef,
      defaults: state.defaults || {
        serviceOrgId: get(props, 'equipment.serviceOrgId'),
      },
      // onChange: (e: any, f: any) => setSignUp(f.getFieldsValue()),
      toDisplay: [
        {
          title: 'Organization Name',
          var: 'name',
          options: {
            rules: [
              {
                required: true,
                message: 'Organization Name is required. ',
              },
              {
                max: 50,
                message: 'Organization Name is limited to 50 characters. ',
              },
            ],
          },
        },
        {
          title: 'Dental Distributor',
          var: 'serviceOrgId',
          type: 'dropdown',
          opts: () => {
            return orderBy(state.orgs.filter(o => o.orgType === 0).map(o => ({ title: o.name, value: o.id })), ['title']);
          },
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Dental Distributor is required ',
              },
            ],
          },
        },
        {
          title: 'Phone',
          var: 'phone',
          transform: (value: string) => cleanPhoneNumber(value),
          options: {
            preserve: true,
            validateTrigger: 'onBlur',
            rules: [
              {
                // tslint:disable-next-line:no-any
                validator: (rule: any, value: string, cb: any) => {
                  const passes = !value || validPhoneNumber(value);
                  cb(passes ? undefined : rule.message);
                },
                message: 'Phone needs to be a valid phone number. ',
              },
            ],
          },
        },
        {
          title: 'Address',
          var: 'address.address',
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Address is required',
              },
            ],
          },
        },
        {
          title: 'Address 2',
          var: 'address.address2'
        },
        {
          title: 'City',
          var: 'address.city',
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'City is required ',
              },
            ],
          },
        },
        {
          title: 'State',
          var: 'address.state',
          type: 'dropdown',
          opts: StatesSelect,
          style: `
            float: left;
            width: 50%;
            padding-right: 5px;
          `,
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'State is required ',
              },
            ],
          },
        },
        {
          title: 'ZIP Code',
          var: 'address.zip',
          style: `
            float: left;
            width: 50%;
            padding-left: 5px;
          `,
          options: {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Zip Code is required ',
              },
            ],
          },
        },
      ],
      onSubmit,
    }

    return (
      <div css={css(styles.column)}>
        <Row center style={{ marginBottom: 20, maxWidth: 360 }}><Logo /></Row>
        <div css={css(styles.inputContainer)}>
          {props.renderLoadedEquipment({ margin: -20, marginBottom: 20 })}
          <p css={css(styles.title)}>Organization Details</p>
          <p css={css(styles.subTitle, `font-style: italic;`)}>Please provide some additional details about the organization that you represent. This will allow us to group all of your equipment together under one account.</p>
          <Form {...formProps as unknown as FormComponentProps} />
          <Row center style={{ float: 'none', width: '100%' }}>
            <Button disabled={state.loading} loading={state.loading} title="Submit" style={{ marginRight: 10 }} onClick={e => onSubmit(e, get(formRef, 'current.form'))} />
          </Row>
          <MatchModal {...state.matchModal} isPhone={isPhone} />
        </div>
      </div>
    )
  }

  return null;
}

const columns = [
  { title: 'Name', dataIndex: 'name', render: (t: string) => <span style={{ fontSize: 12 }}>{t || ''}</span> },
  { title: 'Phone', dataIndex: 'phone', render: (t: string) => <span style={{ fontSize: 12 }}>{t || ''}</span> },
  { title: 'Address', dataIndex: 'address', render: (t: string, r: Org) => <div style={{ fontSize: 12, flexDirection: 'row', display: 'flex' }}>{renderAddress(r)}</div> },
]

const phoneColumns = [
  { 
    title: 'Name', 
    dataIndex: 'name', 
    render: (t: string, row: any) => {
      const items = [
        { render: () => <div><b>{get(row, 'name', '')}</b></div> },
        { render: () => <div>{get(row, 'phone', '')}</div> },
        { render: () => <div>{renderAddress(row)}</div> },
      ];

      return (
        <div style={{ borderBottom: `1px solid ${colors.inputBorder}`, paddingBottom: 15 }}>
          {items.map(c => {
            return (
              <div>{c.render()}</div>
            )
          })}
        </div>
      )
    } 
  },
]

const MatchModal = (props: any) => {
  const { isPhone } = props;

  const onRowClick = (r: any) => {
    props.onRowClick && props.onRowClick(r)
  }

  const BUTTONS = [
    <Button outline title="Cancel" onClick={props.onCancel} style={isPhone ? { marginTop: 10 } : { marginRight: 10 } } />,
    <Button title={props.okText} onClick={props.onOk} />
  ]

  return (
    <Modal 
      {...props}
      width={'90%'}
    >
      <div style={{ borderBottom: `1px solid ${colors.inputBorder}` }}>
        <p css={css(styles.title)}>Possible matches found</p>
        <p css={css(styles.subTitle, `font-style: italic;`)}>It looks like we may already have this organization in our system.  Please review the details below and make the appropriate selection.</p>
      </div>
      <div>
        <Table size="small" showHeader={!isPhone} dataSource={props.matches} columns={isPhone ? phoneColumns : columns} onRowClick={onRowClick} />
      </div>
      <div style={props.isPhone ? { flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' } : { flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {(isPhone ? BUTTONS.reverse() : BUTTONS).map(b => b)}
      </div>
    </Modal>
  )
}