import { guid } from '../../utils';

export interface Profile {
  [key: string]: string | number | undefined | string[] | boolean;
  id: string;
  name?: string;
  userId?: string;
  dentalOrgId: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export class _Profile {
  constructor(props?: Profile) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined | string[] | boolean;
  id = guid();
  createdAt = new Date().toISOString();
  createdBy = '';
  updatedAt = new Date().toISOString();
  updatedBy = '';
  dentalOrgId = '';
  userId = '';
  name = '';
}
