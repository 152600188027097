import { persistStore } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { set } from 'lodash';

import rootReducer from './reducer';

const middleware = [thunk];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);

  middleware.push(createLogger());
}

const enhancer = composeWithDevTools(applyMiddleware(...middleware));

export const configureStore = () => {
  const store = createStore(rootReducer, enhancer);
  const persistor = persistStore(store);
  set(window, 'STORE', store);
  return { store, persistor };
};
