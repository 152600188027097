import { guid } from '../../utils';

export interface TandemGroup {
  [key: string]: string | number | undefined | string[];
  id: string;
  createdAt: string;
  createdBy: string | undefined;
  updatedAt?: string;
  updatedBy?: string | undefined;
  name: string;
  locationId: string;
  groupNumber: number;
  equipType: string;
  equipment?: string[];
}

export class _TandemGroup {
  constructor(props?: TandemGroup) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | string[] | undefined;
  id = guid();
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  locationId = '';
  name = '';
  equipType = '';
}
