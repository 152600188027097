import { connect } from 'react-redux';
import { ManageEquipmentComponent } from './manage-equipment.component';
import { AppState } from '../../app.state';
import { setLastEquipment, addToMap, setEquipView } from '../dashboard.actions';

export const ManageEquipment = connect(
  (sState: AppState) => ({ sState }),
  {
    setLastEquipment,
    addToMap,
    setEquipView,
  }
)(ManageEquipmentComponent);
