export interface ServiceSerial {
  [key: string]: string;
}

export interface Service {
  [key: string]: string | number | undefined | ServiceSerial[];
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  published_at?: string;

  coreid?: string;
  note?: string;
  serviceDate?: string;
  techName?: string;
  services?: ServiceSerial[];
  pk?: string;
  sk?: string;
}

export class _Service {
  constructor(props?: Service) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined | ServiceSerial[];
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  published_at = new Date().toISOString();

  coreid = '';
  serviceDate = '';
  techName = '';
  note = '';
  services = [];
  pk = '';
  sk = '';
}
