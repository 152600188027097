/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import { Component, MouseEvent } from 'react';
import colors from '../colors';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export interface IProps extends ButtonProps {
  onClick: (e: MouseEvent) => void;
  title: string;
  disabled?: boolean;
  outline?: boolean;
  dark?: boolean;
  css?: SerializedStyles;
}

export class _Button extends Component<IProps> {
  render() {
    const { title, disabled, outline, children, dark, type } = this.props;

    // tslint:disable-next-line:no-any
    const fs = { ...this.props } as any;
    fs.outline = !!this.props.outline ? 'true' : 'false';

    return (
      <Button
        {...fs}
        css={css(
          type === 'link'
            ? ``
            : `
          background-color: ${
            disabled
              ? colors.disabledColor
              : outline
              ? colors.white
              : dark
              ? colors.buttonDark
              : colors.highlight
          };
          color: ${
            disabled ? colors.white : outline ? colors.highlight : colors.white
          };
          font-size: 14px;
          cursor: pointer;
          border-radius: 0px;
          border: 1px solid ${
            disabled
              ? colors.disabledColor
              : dark
              ? colors.buttonDark
              : colors.highlight
          };
        `,
          this.props.css
        )}
      >
        {title || children}
      </Button>
    );
  }
}
