import colors from '../../_shared/colors';

export default {
  container: `
    background-color: ${colors.white};
    border-right: 2px solid ${colors.inputBorder};
  `,
  faContainer: `
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;

    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  `,
};
