import colors from '../../_shared/colors';

export default {
  inputGroup: `
    display: flex;
    flex-direction: row;
    gap: 15px;
    p {
      width: 150px;
    }
  `,
  nestedInputGroup: `
    display: flex;
    flex-direction: column;
    margin-left: 35px;
  `,
  selectInput: `
    width: 350px;
  `,
  maintenanceSensorDataGroup: `
    padding-top: 15px;
    padding-bottom: 15px;
  `,
};
