/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Modal } from 'antd';
import QrReader from 'react-qr-reader';
import { Button } from '../button';
import { useState } from 'react';
import { Row } from '../row';
import { get } from 'lodash';

export const QRScan = (props: any) => {
  const [state, setState] = useState({
    facingMode: 'environment',
    delay: 500,
  })

  return (
    <div style={{ width: '100%', height: '100%',  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '100%', height: '100%', maxWidth: 500, margin: '0 auto' }}>
        <QrReader
          delay={state.delay}
          onError={(e: any) => props.onResult(e)}
          onScan={(r: any) => {
            if (!!r) {
              props.onResult(null, r)
            }
          }}
          style={{ width: '100%' }}
          //@ts-ignore
          facingMode={state.facingMode}
        />
        <Row center style={{ marginTop: 20 }}>
          <Button title={`Toggle Camera`} onClick={() => setState(s =>({ ...s, facingMode: s.facingMode === 'user' ? 'environment' : 'user' }))} />
        </Row>
      </div>
    </div>
  )
}

export const ScanQRCode = (props = {}) => {
  return new Promise((resolve, reject) => {
    const getWidth = () => {
      if (get(props, 'isPhone')) {
        return '100%';
      } else {
        const w = document.body.clientWidth;
        return w > 700 ? 600 : w;
      }
    }

    const modal = Modal.info({
      title: (
        <Row>
          <h3>Scan QR Code</h3>
          <Button outline style={{ position: 'absolute', top: 10, right: 5, borderColor: 'rgba(0,0,0,0.0)' }} icon="close" title="" onClick={() => {
            modal.destroy();
            reject();
          }} />
        </Row>
      ),
      icon: null,
      maskClosable: true,
      okText: 'Close',
      content: <QRScan {...props} onResult={(err: any, res: any) => {
        modal.destroy();
        if (err) { 
          return reject(err) 
        }
        resolve(res)
      }} />,
      width: getWidth(),
      onCancel: () => {
        reject();
      },
      onOk: () => {
        reject();
      },
      ...props,
    })
  })
}