/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Sidebar } from '../equipment-details/sidebar.component';
import Link from '../../_shared/link';
import {
  Equipment,
  IInvoice,
  Subscription,
  SUBSCRIPTION_TYPE,
  SUB_TYPE_DISPLAY,
  _Equipment,
} from '../../_shared/interfaces/equipment';
import styles from './billing-history.styles';
import SharedStyles from '../../_shared/styles';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../app.state';
import React, { useEffect, useState } from 'react';
import { Model } from '../../_shared/interfaces/model';
import { get } from 'lodash';
import FormTitle from '../../_shared/form-title';
import {
  findActiveOrPendingSubscription,
  userHasRole,
  findTrial,
  getCancelableSub,
} from '../../utils';
import { ChangeBillingModal } from './change-billing-modal.component';
import { Button } from '../../_shared/button';
import { TableList } from '../../_shared/table-list';
import { ExtendTrialModal } from './extend-trial-modal.component';
import { camelCase } from 'lodash';
import { Publish } from '../../_shared/interfaces/publishData';
import { ColumnProps } from 'antd/lib/table';

interface IProps extends RouteComponentProps {
  appState: AppState;
  refreshEquipment: () => Promise<void>;
}

const BillingHistoryComponent: React.FC<IProps> = (props): JSX.Element => {
  const { appState, history, refreshEquipment } = props;

  const [equipment, setEquipment] = useState<Equipment | undefined>(undefined);
  const [trialSub, setTrialSub] = useState<Subscription | undefined>(undefined);
  const [model, setModel] = useState<Model | undefined>(undefined);
  const [config, setConfig] = useState<Publish | undefined>(undefined);
  const [changeBillingVisible, setChangeBillingVisible] = useState<boolean>(
    false
  );
  const [extendTrialVisible, setExtendTrialVisible] = useState<boolean>(false);

  useEffect(() => {
    const { equipment, model, config } = props.location.state;
    setEquipment(equipment);
    setTrialSub(findTrial(equipment));
    setModel(model);
    setConfig(config);
  }, []); //pass empty array so this doesn't infi-loop

  const isPhoneBreak: boolean = get(appState.dash.view, 'isPhoneBreak', false);

  const goBack = () => {
    history.goBack();
  };
  const deleteThis = () => { };

  const showChangeBillingModal = () => {
    setChangeBillingVisible(true);
  };
  const hideChangeBillingModal = () => {
    setChangeBillingVisible(false);
  };

  const showExtendTrialModal = () => {
    setExtendTrialVisible(true);
  };

  const hideExtendTrialModal = () => {
    setExtendTrialVisible(false);
  };

  const formatDate = (str: string): string => {
    //uh this seems to be the most reliable way to parse incoming iso date strings?
    let dc: string[] = str.split(/\D+/); //date components
    const date = new Date(
      parseInt(dc[0]),
      parseInt(dc[2]) - 1,
      parseInt(dc[1]),
      parseInt(dc[3]),
      parseInt(dc[4]),
      parseInt(dc[5]),
      parseInt(dc[6])
    );

    if (!date || isNaN(date.getDate())) return str;

    return moment(date).format('MMMM DD, YYYY');
  };

  const invoiceToRowModel = (
    equipment: Equipment,
    invoice: IInvoice,
    index: number
  ) => {
    let subscriptionType: SUBSCRIPTION_TYPE | undefined = undefined;

    if (equipment.subscriptionHistory) {
      let allMatches = equipment.subscriptionHistory
        .sort((a, b) => (b.invoiceDate > a.invoiceDate ? 1 : -1))
        .filter(s => s.subscriptionStart < invoice.invoiceDate);

      if (allMatches[0] && allMatches[0].billingPreference) {
        subscriptionType = allMatches[0].billingPreference as SUBSCRIPTION_TYPE;
      }
    }

    return {
      key: index,
      invoiceDate: formatDate(invoice.invoiceDate),
      invoiceNumber: invoice.invoiceNumber,
      invoiceLine: invoice.invoiceLine,
      subscription: subscriptionType
        ? SUB_TYPE_DISPLAY[subscriptionType]
        : '--',
      billingPeriodStart: formatDate(invoice.billSpanStartDate),
      billingPeriodEnd: formatDate(invoice.billSpanEndDate),
    };
  };

  const renderSideBar = (): JSX.Element => {
    return (
      <React.Fragment>
        {equipment && !isPhoneBreak && (
          <Sidebar
            equipment={equipment}
            model={model}
            config={config}
            showHeads={true}
            goToBillingHistory={deleteThis}
          />
        )}
      </React.Fragment>
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <>
        <div css={css(SharedStyles.row)}>
          <FormTitle size={'16px'}>Billing History</FormTitle>
        </div>
      </>
    );
  };

  const renderActionButtons = (): JSX.Element => {
    if (!equipment) return <></>; //safety

    const { appState } = props;

    const showTrialExtension =
      userHasRole([0, 1], appState) && !!trialSub && !!trialSub.subscriptionEnd;

    const cancelableSub = getCancelableSub(equipment);
    const showChangeBilling =
      cancelableSub &&
      cancelableSub.billingPreference != SUBSCRIPTION_TYPE.multiyear;

    return (
      <div css={css(styles.actionButtonContainer)}>
        {showTrialExtension && (
          <Button onClick={showExtendTrialModal} title={'Extend Trial'} />
        )}
        {showChangeBilling && (
          <Button
            onClick={showChangeBillingModal}
            title={'Change Billing Preference'}
          />
        )}
      </div>
    );
  };

  const renderSubHeader = (): JSX.Element => {
    const fallback = (): JSX.Element => {
      return <></>;
    };

    //exit early if we don't have equipment record or
    //associated invoice history & subscription records
    if (
      !equipment ||
      !equipment.invoiceHistory ||
      equipment.invoiceHistory.length <= 0 ||
      !equipment.subscriptionHistory ||
      equipment.subscriptionHistory.length <= 0
    )
      return fallback();

    //note, we are not accounting for the case where invoices have the same date in the sort.
    //exit early if we don't find an invoice
    const mostRecentInvoice: IInvoice = equipment.invoiceHistory.sort((a, b) =>
      b.invoiceDate > a.invoiceDate ? 1 : -1
    )[0];
    if (!mostRecentInvoice) return fallback();

    const nextInvoiceDateISO: string = mostRecentInvoice.nextInvoiceDate;
    const assumedActiveSubscription = findActiveOrPendingSubscription(
      equipment
    );
    if (!assumedActiveSubscription) return fallback();

    //we will determine if the next invoice date will be meaningful if
    //the active subscr has an end date and it is after the next invoice
    const willBeInvoiced =
      !assumedActiveSubscription.subscriptionEnd ||
      assumedActiveSubscription.subscriptionEnd <= nextInvoiceDateISO;
    if (!willBeInvoiced) return fallback();

    return (
      <div>
        <p>Next Invoice: {formatDate(nextInvoiceDateISO)}</p>
      </div>
    );
  };

  const renderBillingHistory = (): JSX.Element => {
    if (
      !equipment ||
      !equipment.invoiceHistory ||
      equipment.invoiceHistory.length <= 0
    ) {
      return (
        <div>
          <p css={css(styles.billingHistoryEmptyStateHeader)}>
            {'No Billing History'}
          </p>
          <p css={css(styles.billingHistoryEmptyStateText)}>
            {'There are no invoices for this piece of equipment.'}
          </p>
          <p css={css(styles.billingHistoryEmptyStateText)}>
            {
              'When an invoice has been sent, the details will be displayed here.'
            }
          </p>
        </div>
      );
    }

    const columns: ColumnProps<any>[] = [
      'Invoice Date',
      'Invoice Number',
      'Invoice Line',
      'Subscription',
      'Billing Period Start',
      'Billing Period End',
    ].map(i => {
      return { title: i, dataIndex: camelCase(i) };
    });

    const invoiceData = equipment.invoiceHistory
      .sort((a, b) => (b.invoiceDate > a.invoiceDate ? 1 : -1))
      .map((item, index) => invoiceToRowModel(equipment, item, index));

    return (
      <TableList
        columns={columns}
        data={invoiceData as never[]}
        showPagination={false}
        canSelectRows={false}
        implementScroll={false}
      />
    );
  };

  return (
    <div css={css(styles.container)}>
      <div css={css(SharedStyles.row, `width: 100%;`)}>
        <Link onClick={goBack}>{'< Back'}</Link>
      </div>

      <div css={css(styles.container, `flex-direction: row;`)}>
        {renderSideBar()}

        <div css={css(styles.mainContainer)}>
          <div css={css(styles.headerContainer)}>
            {renderHeader()}
            {renderActionButtons()}
          </div>
          {renderSubHeader()}
          {renderBillingHistory()}
        </div>
      </div>

      {equipment && (
        <ChangeBillingModal
          visible={changeBillingVisible}
          closeModal={hideChangeBillingModal}
          equipment={equipment}
          refreshEquipment={refreshEquipment}
        />
      )}

      {equipment && trialSub && (
        <ExtendTrialModal
          visible={extendTrialVisible}
          close={hideExtendTrialModal}
          equipment={equipment}
          appState={appState}
          trialSub={trialSub}
        />
      )}
    </div>
  );
};

export default withRouter(BillingHistoryComponent);
