import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { EquipmentDetailsComponent } from './equipment-details.component';
import {
  addToMap,
  waitForNewConfig,
  waitForNewStatistic,
} from '../dashboard.actions';

export const EquipmentDetails = connect(
  (sState: AppState) => ({ sState }),
  {
    addToMap,
    waitForNewConfig,
    waitForNewStatistic,
  }
)(EquipmentDetailsComponent);
