/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import {
  updateUser,
  getUsers,
} from '../../_shared/services/manage-users.service';
import { Input, Checkbox, Alert, Icon } from 'antd';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  userHasRole,
  getRoleName,
  isServiceOrgAdmin,
  serviceOrgThenDentalOrgs,
  sortByLabel,
  sortByKey,
  sortByLastThenFirstName,
  truncate,
  isPB,
} from '../../utils';
import { SelectValue } from 'antd/lib/select';
import { chain, uniq, get, sortBy, size } from 'lodash';
import { User, _User } from '../../_shared/interfaces/user';
import colors from '../../_shared/colors';
import { ModalMultiSelectSearch } from '../../_shared/modal-multi-select-search';
import { Select } from '../../_shared/select';
import { Role } from '../../_shared/interfaces/role';
import moment from 'moment';
import Link from '../../_shared/link';
import { ColumnProps } from 'antd/lib/table';

const Columns = (_this: _ManageUsersComponent): ColumnProps<UserRow>[] => {
  const isSuperAdmin = userHasRole(0, _this.props.sState);
  return [
    {
      title: 'Role',
      dataIndex: 'role',
      width: 175,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a: UserRow, b: UserRow) =>
        sortByLastThenFirstName(a.user, b.user),
      width: 175,
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      width: 175,
    },
    ...isSuperAdmin ? [{
      title: 'Org Type',
      dataIndex: 'orgType',
      width: 90,
    }] : [],
    {
      title: 'Email',
      dataIndex: 'email',
      width: 275,
      sorter: (a: UserRow, b: UserRow) => sortByKey(a, b, 'email'),
    },
    {
      title: 'Created Date',
      dataIndex: 'user.createdAt',
      width: 150,
      sorter: (a: UserRow, b: UserRow) =>
        sortByKey(a.user, b.user, 'createdAt'),
      render: (text: string) => {
        return moment(text).format('L');
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (text: string, record: { user: User }) => {
        const user = record.user;
        const isActive = user.isActive > 0;
        return (
          <Icon
            type={isActive ? 'check-circle' : 'close-circle'}
            css={css(`color: ${isActive ? colors.success : colors.error};`)}
          />
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      render: (text: string, record: { user: User }) => {
        const user = record.user;

        const canShow = userHasRole([0, 1, 2], _this.props.sState);

        return !canShow ? null : (
          <Icon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              _this.editUser(user);
            }}
            type={'edit'}
            css={css(`color: ${colors.highlight};`)}
          />
        );
      },
    },
  ];
};

interface IProps extends RouteComponentProps {
  sState: AppState;
}

interface Modal {
  visible: boolean;
  opts: string[];
  selected: [];
  users: User[];
  title: string;
  placeholder: string;
  opt: Opt;
}

interface Something<T> {
  canDisplay: () => boolean;
  opts: () => { label: string; value: string | number }[];
  selected: T[];
}

interface UserRow {
  user: User;
  email: string; //separate to truncate
  rowkey: string;
  name: string;
  role: string;
  orgType: string;
  organization: string;
  locations: string | number;
  groups: string | number;
  equipment: string | number;
}

interface IState {
  columns: ColumnProps<UserRow>[];
  loading: boolean;
  success: string | null;
  error: string | null;
  data: UserRow[];
  allUsers: User[];
  searchValue: string | null;
  showInactive: boolean;
  selectedRows: User[];
  show: boolean;
  roles: Something<number>;
  orgs: Something<string>;
  orgTypes: Something<string>;
  modal: Modal;
  showFilters: boolean;
}

type Opt = 'locations' | 'groups' | 'equipment';

class _ManageUsersComponent extends Component<IProps, IState> {
  state: IState = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    data: [],
    allUsers: [],
    searchValue: null,
    showInactive: false,
    showFilters: true,
    selectedRows: [],
    show: true,
    roles: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1], this.props.sState);
        const isSOAdmin = isServiceOrgAdmin(this.props.sState);
        return isSuper || isSOAdmin ? true : false;
      },
      opts: () => {
        const opts = (get(
          this,
          'props.sState.auth.allRoles',
          []
        ) as Role[]).map(role => ({
          label: role.title,
          value: role.id,
        }));

        if (isServiceOrgAdmin(this.props.sState)) {
          return opts.filter(
            r =>
              r.value >= 2 &&
              r.value !== 5 &&
              r.label.indexOf('DentalEZ') === -1
          );
        } else {
          return opts;
        }
      },
      selected: [] as number[],
    },
    orgs: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1], this.props.sState);
        const isSOAdmin = isServiceOrgAdmin(this.props.sState);
        return isSuper || isSOAdmin ? true : false;
      },
      opts: () => {
        const isSOAdmin = isServiceOrgAdmin(this.props.sState);

        if (isSOAdmin) {
          return serviceOrgThenDentalOrgs(this.props.sState);
        } else {
          return this.props.sState.dash.orgs
            .map(org => ({
              label: org.name,
              value: org.id,
            }))
            .sort(sortByLabel);
        }
      },
      selected: [] as string[],
    },
    orgTypes: {
      canDisplay: () => {
        return userHasRole([0, 1, 7], this.props.sState);
      },
      opts: () => {
        return [
          { label: 'DentalEZ', value: 'DENTALEZ' },
          { label: 'Service', value: 0 },
          { label: 'Dental', value: 1 },
        ];
      },
      selected: [] as string[],
    },
    modal: {
      visible: false,
      opts: [],
      selected: [],
      users: [],
      title: '',
      placeholder: '',
      opt: 'locations' as Opt,
    },
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    const { sState } = this.props;
    const ispb = isPB(sState);
    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    if (ispb) {
      this.setState({ showFilters: false });
    }
    const org = get(state, 'org');
    const allUsers = get(sState, 'dash.users');

    if (org) {
      const name = 'orgs';
      this.setState(
        {
          show: false,
          [name]: {
            ...get(this.state, name),
            selected: [org.id],
          },
          allUsers,
        },
        this.retrieveData
      );
    } else {
      this.setState({ allUsers });
      this.retrieveData();
    }
  };
  componentDidUpdate = (prevProps: IProps) => {
    if (
      get(prevProps, 'sState.dash.view.isPhoneBreak') !==
      get(this.props, 'sState.dash.view.isPhoneBreak')
    ) {
      const { sState } = this.props;
      const ispb = isPB(sState);
      if (ispb) {
        this.setState({ showFilters: false });
      } else {
        this.setState({ showFilters: true });
      }
    }
  };

  editUser = (user: User, editMode = true) => {
    this.props.history.push('/dashboard/accountDetails', {
      user,
      editMode,
      back: `< Back`,
    });
  };
  manageUser = (user: User) => {
    this.props.history.push('/dashboard/manageUser', {
      user,
    });
  };
  addUser = () => {
    this.props.history.push('/dashboard/addUser');
  };
  selectUser = (record: { user: User }, index: number) => {
    this.manageUser(record.user);
  };
  selectedRowsChange = (keys: number[] | string[], rows: any[]) => {
    this.setState({
      selectedRows: rows,
    });
  };
  fireSearch = (value: string) => {
    this.setState({ searchValue: value }, () => this.retrieveData(false));
  };
  retrieveData = async (shouldFetch = true) => {
    this.setState({ loading: true });

    const allUsers = shouldFetch
      ? await getUsers()
      : (this.state.allUsers as User[]);
    const state = { ...this.state };
    const { roles, orgs, showInactive, searchValue, orgTypes } = state;
    const {
      sState,
      sState: { dash },
    } = this.props;

    const iAmOrgAdmin = userHasRole(2, sState);
    const orgArr = orgs.opts().map(o => o.value);

    state.allUsers = allUsers;

    this.setState(state);

    try {
      const orgMap = chain(dash.orgs)
        .keyBy('id')
        .value();

      const data: UserRow[] = [...allUsers]
        .map((user, i) => {
          const userIsSuperOrTech = userHasRole([0, 1, 2, 7], {
            auth: { user },
          } as AppState);
          // tslint:disable-next-line: no-any
          const orgType: any = get(orgMap, `${user.orgId}.orgType`);

          const maxTextLength = 23;

          const row: UserRow = {
            user: { ...user },
            email: truncate(user.email, maxTextLength),
            rowkey: `${user.userId}_${i}`,
            name: truncate(user.name, maxTextLength),
            role: getRoleName(user.role, sState).substring(0, maxTextLength),
            orgType: truncate(
              orgType === 0 ? 'Service' : orgType === 1 ? 'Dental' : '',
              maxTextLength
            ),
            organization: truncate(
              orgMap[user.orgId] ? orgMap[user.orgId].name : 'None',
              maxTextLength
            ),
            locations: userIsSuperOrTech
              ? '--'
              : Array.isArray(user.locations)
                ? user.locations.length
                : 0,
            groups: userIsSuperOrTech
              ? '--'
              : Array.isArray(user.groups)
                ? user.groups.length
                : 0,
            equipment: userIsSuperOrTech
              ? '--'
              : Array.isArray(user.equipment)
                ? user.equipment.length
                : 0,
          };
          return row;
        })
        .filter(row => {
          let isVisible = iAmOrgAdmin
            ? orgArr.indexOf(get(row, 'user.orgId')) > -1
            : true;
          if (!isVisible) {
            return isVisible;
          }

          if (roles.selected.length > 0) {
            isVisible = userHasRole(roles.selected, ({
              auth: { user: row.user },
            } as unknown) as AppState);
          }

          if (orgs.selected.length > 0) {
            isVisible = orgs.selected.indexOf(row.user.orgId) > -1;
          }

          if (roles.selected.length > 0 && orgs.selected.length > 0) {
            isVisible =
              userHasRole(roles.selected, ({
                auth: { user: row.user },
              } as unknown) as AppState) &&
              orgs.selected.indexOf(row.user.orgId) > -1;
          }

          if (orgTypes.selected.length > 0 && isVisible) {
            // tslint:disable-next-line: no-any
            const orgType: any = get(orgMap, `${row.user.orgId}.orgType`);

            isVisible = !!size(
              orgTypes.selected.filter(ot => {
                if (ot === 'DENTALEZ') {
                  return [0, 1, 7].indexOf(row.user.role) > -1;
                } else {
                  return ot === orgType;
                }
              })
            );
          }

          if (!showInactive && isVisible) {
            isVisible = row.user.isActive > 0 ? true : false;
          }

          if (searchValue && isVisible) {
            isVisible =
              get(row, 'user.name', '')
                .toUpperCase()
                .indexOf((searchValue || '').toUpperCase()) > -1 ||
              get(row, 'user.email', '')
                .toUpperCase()
                .indexOf((searchValue || '').toUpperCase()) > -1;
          }

          return isVisible;
        });

      // tslint:disable-next-line:no-any
      const columns: any[] = [];
      const isSOAdmin = isServiceOrgAdmin(sState);
      Columns(this).map(col => {
        if (col.dataIndex === 'organization' && iAmOrgAdmin && !isSOAdmin) {
          //Do Nothing
        } else {
          columns.push(col);
        }
      });

      state.data = sortBy(data, ['name', 'userId']);
      state.columns = columns;
      state.loading = false;
      state.show = true;

      this.setState(state);
    } catch (err) {
      console.warn(err);
    }
  };
  changeSelect = (item: 'roles' | 'orgs' | 'orgTypes', value: SelectValue) => {
    const state = { ...this.state };
    state[item].selected = [...(value as string[])];
    this.setState(state, () => this.retrieveData(false));
  };

  getSelectedUsers = (): User[] => {
    const { selectedRows } = this.state;
    return selectedRows as User[];
  };

  modalOk = async (result: { tags: string[] }) => {
    const {
      sState: { dash },
    } = this.props;
    const { modal } = this.state;

    const dopt = dash[modal.opt];
    // tslint:disable-next-line:no-any
    const opts = (dopt as any[])
      .filter(l => result.tags.indexOf(l.name || '') > -1)
      .map(l => l.id);

    const users = (modal.users as User[])
      .filter(u => get(u, 'user.role') as number > 1)
      .map(user => {
        const u = { ...user.user as unknown as User };
        u[modal.opt] = uniq([...opts, ...u[modal.opt]]);
        return u;
      });

    users.map(async (user, i) => {
      try {
        await updateUser(user);
      } catch (err) {
        this.setState({ error: `Save Failed: ${err.message}` });
      }
      if (i >= users.length - 1) {
        this.closeModal();
      }
    });
  };
  openModal = (opt: 'locations' | 'groups' | 'equipment') => {
    const {
      sState: { dash },
    } = this.props;
    const { orgs } = this.state;
    const selectedOrg = orgs.selected[0];

    let titles = {
      locations: 'Add Location(s) to selected users',
      groups: 'Add Groups(s) to selected users',
      equipment: 'Add Equipment to selected users',
    };

    let placeholders = {
      locations: 'Search by Location name',
      groups: 'Search by Group name',
      equipment: 'Search by Equipment name',
    };

    const modal = { ...this.state.modal };
    modal.visible = true;
    modal.title = titles[opt];
    modal.placeholder = placeholders[opt];
    modal.opt = opt;
    modal.selected = [];
    modal.users = this.getSelectedUsers();
    // tslint:disable-next-line:no-any
    modal.opts = uniq((dash[opt] as any[])
      .filter(o => {
        if (opt === 'equipment') {
          return o.dentalOrgId === selectedOrg || o.serviceOrgId === selectedOrg;
        }
        return o.orgId === selectedOrg
      })
      .map(l => l.name));

    this.setState({ modal });
  };
  closeModal = () => {
    const state = { ...this.state };
    state.modal.visible = false;
    this.setState(state);
  };
  getCheckboxProps = (row: any) => {
    return {
      disabled: get(row, 'user.role') == 2 ? true : false,
    }
  }

  render() {
    const {
      columns,
      data,
      loading,
      showInactive,
      roles,
      orgs,
      error,
      success,
      selectedRows,
      modal,
      show,
      orgTypes,
      showFilters,
    } = this.state;
    const { sState } = this.props;
    const ispb = isPB(sState);

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <TableListHeader
          style={ispb && SharedStyles.pbFilterContainer}
          hide={selectedRows.length > 0}
        >
          <div
            css={css(SharedStyles.row, ispb ? `width: 100%` : `width: 50%;`)}
          >
            <Input.Search
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 70%;
                      margin-right: 10px;
                    `
              }
              placeholder="Search..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />

            <Button
              loading={loading}
              css={ispb && css(`display: none;`)}
              title="Refresh"
              type="link"
              onClick={() => this.retrieveData(true)}
            />
          </div>
          <div css={ispb && css(SharedStyles.pbButtonContainer)}>
            <Button title={'Invite User'} onClick={this.addUser} />
            <div css={!ispb && css(SharedStyles.hidden)}>
              <Link
                css={css(SharedStyles.row)}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
                <Icon
                  css={css('margin-left: 5px;')}
                  type={showFilters ? 'up' : 'down'}
                />
              </Link>
            </div>
          </div>
        </TableListHeader>

        <TableListHeader
          hide={selectedRows.length > 0}
          style={
            ispb
              ? SharedStyles.pbFilterContainer
              : `justify-content: flex-start;`
          }
        >
          {roles.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Roles'}
              onChange={value => this.changeSelect('roles', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {roles.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          {orgs.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Orgs'}
              defaultValue={(orgs.selected as unknown) as string}
              onChange={value => this.changeSelect('orgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {orgs.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          {orgTypes.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Org Types'}
              defaultValue={(orgTypes.selected as unknown) as string}
              onChange={value => this.changeSelect('orgTypes', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {orgTypes.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          <Checkbox
            checked={showInactive}
            onChange={() =>
              this.setState({ showInactive: !showInactive }, () =>
                this.retrieveData(false)
              )
            }
          >
            Show Inactive
          </Checkbox>
        </TableListHeader>

        <TableListHeader hide={selectedRows.length === 0}>
          <Button
            title={'Add to Locations(s)'}
            onClick={() => this.openModal('locations')}
          />
          <Button
            title={'Add to Group(s)'}
            onClick={() => this.openModal('groups')}
          />
          <Button
            title={'Add to Equipment'}
            onClick={() => this.openModal('equipment')}
          />
        </TableListHeader>

        <TableList
          canSelectRows={orgs.selected.length === 1}
          loading={loading}
          columns={columns}
          data={data as never[]}
          rowClick={this.selectUser}
          onSelectedRowsChange={this.selectedRowsChange}
          pageSize={100}
          includeCount="Users Count"
          getCheckboxProps={this.getCheckboxProps}
          rowKey={u => u.rowkey}
        />

        <ModalMultiSelectSearch
          title={modal.title}
          placeholder={modal.placeholder}
          visible={modal.visible}
          searchOpts={modal.opts}
          onResult={this.modalOk}
          onCancel={this.closeModal}
        />
      </div>
    );
  }
}

export const ManageUsersComponent = withRouter(_ManageUsersComponent);
