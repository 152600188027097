/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import {
  buildErrorMsgFromForm,
  uppercaseFirst,
  validPhoneNumber,
} from '../../../utils';
import { Button } from '../../../_shared/button';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import Logo from '../../../_shared/logo';
import { Form, Input, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styles from '../../authentication.styles';
import { AppState } from '../../../app.state';
import { Emails } from '../../../_shared/lib/emails';
import { sendEmail } from '../../../_shared/services/email.service';
import { set } from 'lodash';
import { User, _User } from '../../../_shared/interfaces/user';
import {
  getUser,
  createUser,
} from '../../../_shared/services/manage-users.service';

type LogoType =
  | 'default'
  | 'white'
  | 'footer1'
  | 'footer2'
  | 'henryschein'
  | 'henryscheinCanada'
  | 'benco'
  | 'patterson';

interface Brand {
  emailDomain: string | string[];
  logo: LogoType;
  id: string;
}

const brands: { [key: string]: Brand } = {
  patterson: {
    emailDomain: ['@pattersondental.com', `@pattersoncompanies.com`],
    logo: 'patterson',
    id: '9659b85c-05b7-4a54-895a-c514f7a008c7',
  },
  benco: {
    emailDomain: '@benco.com',
    logo: 'benco',
    id: '69e40ee2-53e8-4759-b7ad-e37bcc9264fe',
  },
  henryschein: {
    emailDomain: '@henryschein.com',
    logo: 'henryschein',
    id: '03749259-be7b-4802-a15a-dea92a70f7c3',
  },
  hscanada: {
    emailDomain: '@henryschein.ca',
    logo: 'henryscheinCanada',
    id: '7b53dd8f-958c-42d0-9192-31c2ca3adb17',
  },
};

interface IProps extends RouteComponentProps, FormComponentProps {
  appState: AppState;
}

interface IParams {
  org: string;
}

const hasEmailDomain = (email: string, brand: Brand) => {
  const { emailDomain } = brand;
  return Array.isArray(emailDomain) ? emailDomain.find(e => email.endsWith(e)) : emailDomain.endsWith(email);
}

class BrandedLoginComponent extends Component<IProps> {
  state = {
    error: null,
    loading: false,
    toDisplay: [
      {
        title: 'First Name',
        var: 'firstName',
        transform: (value: string) => uppercaseFirst(value),
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Last Name',
        var: 'lastName',
        transform: (value: string) => uppercaseFirst(value),
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Email',
        var: 'email',
        options: {
          validateTrigger: 'onBlur',
          normalize: (value: string) => value && value.trim(),
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid E-mail. ',
            },
            {
              required: true,
              message: 'The input is not a valid E-mail. ',
            },
            {
              max: 60,
              message: 'Email is limited to 60 characters. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'phone',
        required: () => false,
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
            {
              max: 16,
              message: 'Phone is limited to 16 characters. ',
            },
          ],
        },
      },
      {
        title: 'Branch Location',
        var: 'branchLocation',
        transform: (value: string) => uppercaseFirst(value),
        options: {
          rules: [
            {
              max: 70,
              message: 'Branch is limited to 70 characters. ',
            },
          ],
        },
      },
    ],
  };

  submit = async (e: MouseEvent | FormEvent) => {
    const org = this.props.match.params as IParams;
    const brand = brands[org.org];
    if (!brand) {
      return;
    }

    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true, error: null });

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const {
          appState: { auth },
        } = this.props;

        const me = auth.user && auth.user.userId;
        const user = new _User({ createdBy: me, updatedBy: me } as User);

        user.firstName = values.firstName;
        user.lastName = values.lastName;
        user.email = values.email.toLowerCase();
        user.userId = values.email.toLowerCase();
        user.orgId = brand.id;
        user.phone = values.phone;
        user.branchLocation = values.branchLocation;
        user.role = 6; //anyone using this form is READONLY ORG

        if (!user.email || !user.firstName || !user.lastName) {
          return this.setState({
            error:
              // tslint:disable-next-line:max-line-length
              'First Name, Last Name, Email are required.',
            loading: false,
          });
        }

        if (!hasEmailDomain(user.email, brand)) {
          return this.setState({
            error:
              // tslint:disable-next-line:max-line-length
              'Email Address not recognized -- please use your company email.',
            loading: false,
          });
        }

        set(user, 'notification.alerts', ['email']);
        set(user, 'notification.service', ['email']);

        try {
          const userExists = await getUser(user.email);

          if (userExists) {
            return this.setState({
              error:
                // tslint:disable-next-line:max-line-length
                'A user with this email already exists, did you already sign up?',
              loading: false,
            });
          }
        } catch (err) {}

        const Email = Emails.invite({ ToAddresses: [user.email] });

        await sendEmail(Email);
        await createUser(user);
        this.props.history.push('/login', {
          toast: 'An invite has been sent to the entered email address',
        });
      } catch (err) {
        return this.setState({
          error: 'failed: ' + err.message,
          loading: false,
        });
      }
    });
  };

  render() {
    const org = this.props.match.params as IParams;
    const { getFieldDecorator } = this.props.form;
    const { error, loading, toDisplay } = this.state;

    const brand = brands[org.org];
    if (!brand) {
      return <Redirect to={'/login'} />;
    }

    const logoString: LogoType = brand.logo;
    return (
      <div css={css(styles.container)}>
        <Logo size={'xlarge'} type={logoString} />
        <br />
        <p css={css(styles.title)}>
          {'Sign up for your Aeras account using your company email address.'}
        </p>
        <p css={css(styles.title)}>
          {
            'You will be required to verify this email during the account creation process.'
          }
        </p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <Form
          css={css(styles.brandedLoginForm)}
          layout="vertical"
          onSubmit={this.submit}
        >
          {/* tslint:disable-next-line: no-any*/}
          {toDisplay.map((item: any, i: number) => {
            const itemOptions =
              typeof item.options === 'function'
                ? item.options()
                : item.options;
            return (
              <Form.Item key={i} label={item.title}>
                {getFieldDecorator(item.var as string, itemOptions)(<Input />)}
              </Form.Item>
            );
          })}

          <Button title={'Submit'} loading={loading} onClick={this.submit} />
        </Form>
      </div>
    );
  }
}

export const BrandedLogin = Form.create()(withRouter(BrandedLoginComponent));
