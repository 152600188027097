/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { getUsers } from '../../_shared/services/manage-users.service';
import { Input, Alert, Icon } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  userHasRole,
  getMyOrg,
  sortByLabel,
  sortByKey,
  isPB,
  getActiveModels,
} from '../../utils';
import { SelectValue } from 'antd/lib/select';
import { chain, get, set } from 'lodash';
import { Equipment } from '../../_shared/interfaces/equipment';
import { getLocations } from '../../_shared/services/manage-locations.service';
import { Select } from '../../_shared/select';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { Model } from '../../_shared/interfaces/model';
import { Address } from '../../_shared/interfaces/org';
import { Button } from '../../_shared/button';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import Link from '../../_shared/link';
import { ColumnProps } from 'antd/lib/table';

const Columns = (_this: _ManageEquipmentList): ColumnProps<Equipment>[] => {
  const myOrg = getMyOrg(_this.props.sState);
  const isSuper = userHasRole([0, 1, 7], _this.props.sState);
  const canShowServiceCol = () => {
    if (isSuper) {
      return true;
    } else {
      return false;
    }
  };
  const canShowDentalCol = () => {
    if (isSuper) {
      return true;
    } else {
      if (myOrg && myOrg.orgType === 0) {
        return true;
      }
      return false;
    }
  };
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a: Equipment, b: Equipment) => sortByKey(a, b, 'name'),
    },
    {
      title: 'Model ID',
      dataIndex: 'modelId',
      width: 100,
    },
    {
      title: 'Serial Number',
      dataIndex: 'equipmentSN',
      width: 150,
      sorter: (a: Equipment, b: Equipment) => sortByKey(a, b, 'equipmentSN'),
    },
    ...canShowServiceCol() ? [{
      title: 'Service Org',
      dataIndex: 'serviceOrg',
      width: 200,
    }] : [],
    ...canShowDentalCol() ? [{
      title: 'Dental Org',
      dataIndex: 'dentalOrg',
      width: 200,
    }] : [],
    {
      title: 'Address',
      dataIndex: 'address',
      width: 200,
      render: (_text: string, record: Equipment) => {
        return _this.renderAddress(record);
      },
    },
  ];
};

interface IProps extends RouteComponentProps {
  sState: AppState;
}

class _ManageEquipmentList extends Component<IProps> {
  state = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    data: [],
    equipment: [],
    orgs: [],
    locations: [],
    users: [],
    searchValue: null,
    show: true,
    showFilters: true,
    models: {
      canDisplay: () => userHasRole([0, 1, 2, 7], this.props.sState),
      opts: () =>
        (getActiveModels() as Model[])
          .map(model => ({
            label: model.id,
            value: model.id,
          }))
          .sort(sortByLabel),
      selected: [] as string[],
    },
    serviceOrgs: {
      canDisplay: () => userHasRole([0, 1, 7], this.props.sState),
      opts: () =>
        this.props.sState.dash.orgs
          .filter(o => o.orgType === 0)
          .map(org => ({
            label: org.name,
            value: org.id,
          }))
          .sort(sortByLabel),
      selected: [] as string[],
    },
    dentalOrgs: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1, 7], this.props.sState);
        const myOrg = getMyOrg(this.props.sState);

        if (isSuper) {
          return true;
        } else {
          if (myOrg && myOrg.orgType === 1) {
            return false;
          } else if (myOrg && myOrg.orgType === 0) {
            return true;
          } else {
            return false;
          }
        }
      },
      opts: () =>
        this.props.sState.dash.orgs
          .filter(o => o.orgType === 1)
          .map(org => ({
            label: org.name,
            value: org.id,
          }))
          .sort(sortByLabel),
      selected: [] as string[],
    },
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    const { sState } = this.props;
    const ispb = isPB(sState);
    if (ispb) {
      this.setState({ showFilters: false });
    }

    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    const org = get(state, 'org');

    if (org) {
      const name = org.orgType === 0 ? 'serviceOrgs' : 'dentalOrgs';
      this.setState(
        {
          show: false,
          [name]: {
            ...get(this.state, name),
            selected: [org.id],
          },
        },
        this.retrieveEquipment
      );
    } else {
      this.retrieveEquipment();
    }
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (
      get(prevProps, 'sState.dash.view.isPhoneBreak') !==
      get(this.props, 'sState.dash.view.isPhoneBreak')
    ) {
      const { sState } = this.props;
      const ispb = isPB(sState);
      if (ispb) {
        this.setState({ showFilters: false });
      } else {
        this.setState({ showFilters: true });
      }
    }
  };

  manageEquipment = (equipment: Equipment) => {
    this.props.history.push('/dashboard/manageEquipmentSelect', {
      equipment,
    });
  };
  selectEquipment = (record: { _equipment: Equipment }, index: number) => {
    this.manageEquipment(record._equipment);
  };
  selectedRowsChange = (keys: string[] | number[]) => {
    this.setState({
      selectedRows: keys,
    });
  };
  fireSearch = (value: string) => {
    this.setState({ searchValue: value }, () => this.retrieveEquipment(false));
  };
  retrieveEquipment = async (shouldFetch = true) => {
    const state = { ...this.state };
    const { searchValue, serviceOrgs, dentalOrgs, models } = state;
    const {
      sState: { dash },
    } = this.props;

    this.setState({ loading: true });

    try {
      const [locations, users, equipment, orgs] = await Promise.all([
        shouldFetch ? await getLocations() : state.locations,
        shouldFetch ? await getUsers() : state.users,
        shouldFetch ? await getEquipments() : state.equipment,
        shouldFetch ? await getOrgs() : state.orgs,
      ]);

      const eMap = chain(equipment)
        .keyBy('id')
        .value();
      const orgMap = chain(orgs)
        .keyBy('id')
        .value();
      const modelMap = chain(dash.models)
        .keyBy('id')
        .value();
      const locMap = chain(locations)
        .keyBy('id')
        .value();
      users.map(user => {
        if (user.equipment) {
          user.equipment.map(ue => {
            const count = get(eMap, `${ue}._userCount`, 0) as number;
            set(eMap, `${ue}._userCount`, count + 1);
          });
        }
      });

      const sterm = `${(searchValue || '')}`.toLowerCase();
      const data = [...equipment]
        .map((e, i) => {
          return {
            ...e,
            _equipment: { ...e },
            key: e.id,
            model: get(modelMap, `${e.modelId}.name`, ''),
            serviceOrg: get(orgMap, `${e.serviceOrgId}.name`, ''),
            dentalOrg: get(orgMap, `${e.dentalOrgId}.name`, ''),
            location: get(locMap, `${e.locationId}.name`, ''),
            groupCount: e.groups ? e.groups.length : 0,
            userCount: get(eMap, `${e.id}._userCount`, 0),
            address: get(orgMap, `${e.dentalOrgId}.address`),
          };
        })
        .filter(e => {
          let passes = true;

          if (searchValue) {
            const ename = `${get(e, 'name', '')}`.toLowerCase();
            const eSN = `${get(e, 'equipmentSN', '')}`.toLowerCase();
            if (
              ename.indexOf(sterm.trim()) === -1 &&
              eSN.indexOf(sterm.trim()) === -1
            ) {
              passes = false;
            }
          }

          if (serviceOrgs.selected.length > 0) {
            if (
              !e.serviceOrgId ||
              serviceOrgs.selected.indexOf(e.serviceOrgId) === -1
            ) {
              passes = false;
            }
          }

          if (dentalOrgs.selected.length > 0) {
            if (
              !e.dentalOrgId ||
              dentalOrgs.selected.indexOf(e.dentalOrgId) === -1
            ) {
              passes = false;
            }
          }

          if (models.selected.length > 0) {
            if (!e.modelId || models.selected.indexOf(e.modelId) === -1) {
              passes = false;
            }
          }

          return passes;
        });

      const columns = Columns(this);

      this.setState({
        equipment: equipment as never[],
        orgs: orgs as never[],
        data: data as never[],
        locations: locations as never[],
        users: users as never[],
        columns: columns as never[],
        loading: false,
        show: true,
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        loading: false,
        error: err.message,
      });
    }
  };
  changeSelect = (
    item: 'serviceOrgs' | 'dentalOrgs' | 'models',
    value: SelectValue
  ) => {
    const state = { ...this.state };
    state[item].selected = [...(value as string[])];
    this.setState(state, () => this.retrieveEquipment(false));
  };

  renderAddress = (record: Equipment) => {
    const addr = get(record, 'address') as string | Address;

    let address;
    if (typeof addr === 'string') {
      address = addr.split(',').map(s => (
        <p
          css={css(
            `line-height: 16px; margin-block-start: 0px; margin-block-end: 0px; padding: 0px`
          )}
          key={s}
        >
          {s}
        </p>
      ));
    } else if (addr && addr.address) {
      const order = ['address', 'address2', ['city', 'state', 'zip']];
      address = order
        .map((o, i) => {
          if (Array.isArray(o)) {
            const vals: string[] = [];
            o.map(oo => {
              const val = get(addr, oo);
              if (val) {
                vals.push(val);
              }
            });
            return (
              <p
                key={i}
                css={css(
                  `line-height: 16px; margin-block-start: 0px; margin-block-end: 0px; padding: 0px;`
                )}
              >
                {vals.join(', ')}
              </p>
            );
          } else {
            const val = get(addr, o);
            if (val) {
              return (
                <p
                  css={css(
                    // tslint:disable-next-line: max-line-length
                    `line-height: 16px; margin-block-start: 0px; margin-block-end: 0px; padding: 0px;`
                  )}
                  key={o}
                >
                  {val}
                </p>
              );
            }
          }
        })
        .filter(a => a);
    }

    return address;
  };

  render() {
    const {
      columns,
      data,
      loading,
      serviceOrgs,
      dentalOrgs,
      error,
      success,
      models,
      show,
      showFilters,
    } = this.state;

    const { sState } = this.props;
    const ispb = isPB(sState);

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <TableListHeader style={ispb && SharedStyles.pbFilterContainer}>
          <div
            css={css(SharedStyles.row, ispb ? `width: 100%` : `width: 50%;`)}
          >
            <Input.Search
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 70%;
                      margin-right: 10px;
                    `
              }
              placeholder="Search by name or serial number..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />
            <Button
              css={ispb && css(`display: none;`)}
              loading={loading}
              title="Refresh"
              type="link"
              onClick={() => this.retrieveEquipment(true)}
            />
          </div>
          <div
            css={
              ispb &&
              css([
                SharedStyles.pbButtonContainer,
                `justify-content: flex-end;`,
              ])
            }
          >
            <div />
            <div css={!ispb && css(SharedStyles.hidden)}>
              <Link
                css={css(SharedStyles.row)}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
                <Icon
                  css={css('margin-left: 5px;')}
                  type={showFilters ? 'up' : 'down'}
                />
              </Link>
            </div>
          </div>
        </TableListHeader>

        <TableListHeader
          style={
            ispb
              ? SharedStyles.pbFilterContainer
              : `justify-content: flex-start;`
          }
        >
          {models.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Models'}
              defaultValue={(models.selected as unknown) as string}
              onChange={value => this.changeSelect('models', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {models.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          {serviceOrgs.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              defaultValue={(serviceOrgs.selected as unknown) as string}
              placeholder={'Select Service Orgs'}
              onChange={value => this.changeSelect('serviceOrgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {serviceOrgs.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}

          {dentalOrgs.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              defaultValue={(dentalOrgs.selected as unknown) as string}
              placeholder={'Select Dental Orgs'}
              onChange={value => this.changeSelect('dentalOrgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {dentalOrgs.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}
        </TableListHeader>

        <TableList
          canSelectRows={false}
          loading={loading}
          columns={columns}
          data={data}
          rowClick={this.selectEquipment}
          includeCount="Equipment Count"
        />
      </div>
    );
  }
}

export const ManageEquipmentList = withRouter(_ManageEquipmentList);
