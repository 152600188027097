/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import { Component } from 'react';
import { HeaderStyles } from './table-list.styles';

interface IProps {
  hide?: boolean;
  style?: string | SerializedStyles;
}

export class TableListHeader extends Component<IProps> {
  render() {
    const { children, hide } = this.props;
    return (
      <div
        css={css(
          HeaderStyles.container,
          hide && HeaderStyles.hide,
          this.props.style
        )}
      >
        {children}
      </div>
    );
  }
}
