export default () => {
  return {
    title: 'Forgot Your Password?',
    subTitle:
      'Enter your email associated with the account and we will send you a reset link.',
    emailLabel: 'Email',
    button: 'Send Code',
    link: 'Return to Sign In',
  };
};
