import { connect } from 'react-redux';
import { Dashboard as DashboardComponent } from './dashboard.component';
import { AppState } from '../app.state';
import {
  retrieveData,
  logout,
  addToMap,
  setEquipView,
  updateEquipmentPusher,
  updateProfiles,
  updateUserPusher,
} from './dashboard.actions';

export const Dashboard = connect(
  (state: AppState) => ({ sState: state }),
  {
    logout,
    retrieveData,
    addToMap,
    setEquipView,
    updateEquipmentPusher,
    updateProfiles,
    updateUserPusher,
  }
)(DashboardComponent);
