import { connect } from 'react-redux';
import { ManageModelComponent } from './manage-model.component';
import { AppState } from '../../app.state';
import { updateModels } from '../dashboard.actions';

export const ManageModel = connect(
  (sState: AppState) => ({ sState }),
  {
    updateModels,
  }
)(ManageModelComponent);
