/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import { Modal, Icon, Form, Input, message } from 'antd';
import { Button } from '../button';
import { InputLabel } from '../input-label/input-label.component';
import SharedStyles from '../styles';
import styles from './modal-verify.styles';
import { get, set } from 'lodash';
import { FormComponentProps } from 'antd/lib/form';
import {
  validPhoneNumber,
  buildErrorMsgFromForm,
  cleanPhoneNumber,
  generatePin,
} from '../../utils';
import { Emails } from '../lib/emails';
import { sendSMS } from '../services/email.service';

interface IProps extends FormComponentProps {
  // tslint:disable-next-line:no-any
  ref: (comp: any) => void;
}

export class _ModalVerify extends Component<IProps> {
  state = {
    loading: false,
    toDisplay: [
      {
        title: 'Phone',
        var: 'phone',
        props: {
          disabled: false,
        },
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = validPhoneNumber(value);
                cb(!value || passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
            {
              max: 16,
              message: 'Phone is limited to 16 characters. ',
            },
          ],
        },
      },
      {
        title: 'Code',
        var: 'code',
        canDisplay: () => {
          return get(this, 'state.pin');
        },
      },
    ],
    pin: '',
    visible: false,
  };
  openModal = (state = {}) => {
    return new Promise((resolve, reject) => {
      this.Resolve = resolve;
      this.Reject = reject;
      this.setState(
        {
          visible: true,
        },
        () => {
          const hasPhone = get(state, 'phone');
          if (hasPhone) {
            const {
              form: { setFieldsValue },
            } = this.props;

            setFieldsValue({
              phone: hasPhone,
            });
          }
        }
      );
    });
  };
  check = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const { pin } = this.state;
        const { phone, code } = values;

        if (pin) {
          if (code == pin) {
            this.finish('Resolve', { phone });
          } else {
            throw new Error(`Code doesn't match. Please try again.`);
          }
        } else {
          const pin = generatePin();
          const send = Emails.verify({ ToAddresses: [''], props: { pin } });
          const sms = {
            to: cleanPhoneNumber(phone),
            body: send.Message.Body.Html.Data,
          };
          await sendSMS(sms);
          message.success('Verification Code sent to phone number.');
          const state = { ...this.state };
          state.pin = pin;
          state.loading = false;
          set(state, 'toDisplay.0.props.disabled', true);
          this.setState(state);
        }
      } catch (err) {
        message.error(`Error: ${err.message}`);
        this.setState({
          loading: false,
        });
      }
    });
  };
  finish = (res: 'Resolve' | 'Reject', opts = {}) => {
    const resp = get(this, res);
    if (res === 'Reject') {
      resp && resp(new Error('Failed to validate'));
    } else {
      resp && resp(opts);
    }
    this.setState({ visible: false });
  };
  Resolve: ((value?: unknown) => void) | undefined;
  // tslint:disable-next-line:no-any
  Reject: ((reason?: any) => void) | undefined;
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { loading, visible, toDisplay, pin } = this.state;

    return (
      <Modal
        visible={visible}
        centered
        closable
        onCancel={() => this.finish('Reject')}
        title={'Verify your Phone Number'}
        footer={
          <div css={css(SharedStyles.row, 'justify-content: center;')}>
            <Button
              title="Cancel"
              onClick={() => this.finish('Reject')}
              loading={loading}
            />
            <Button
              outline={true}
              css={css('margin-left: 10px;')}
              title={pin ? 'Verify' : 'Send Code'}
              loading={loading}
              onClick={this.check}
            />
          </div>
        }
      >
        <div css={css(SharedStyles.column)}>
          <Form layout="vertical" onSubmit={this.check}>
            {toDisplay.map((item, i) => {
              const canDisplay = item.canDisplay ? item.canDisplay() : true;
              return !canDisplay ? null : (
                <Form.Item key={i} label={item.title}>
                  {getFieldDecorator(item.var, item.options)(
                    <Input {...item.props} />
                  )}
                </Form.Item>
              );
            })}
          </Form>
        </div>
      </Modal>
    );
  }
}

export const ModalVerify = Form.create()(_ModalVerify);
