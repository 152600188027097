import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-calendar-picker {
      width: 100%;
    }
  `,
  formContainer: `
    width: 80%;
  `,
  formContainer2: `
    width: 50%;
  `,
  halfColumn: `
    width: 50%;
    float: left;
    padding: 10px;
  `,
  subText: `
    background-color: ${colors.disabledLight};
    font-size: 12px;
    text-align: left;
    padding: 2px 5px;
  `,
  info: `
    font-size: 12px;
    font-style: italic;
  `,
};
