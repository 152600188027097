import { Modal } from 'antd';

export const renderFWErrorModal = (equipmentSN: string, current_FW: string) => {
  return Modal.error({
    title: `Firmware Update Required for ${equipmentSN}`,
    //tslint:disable-next-line:max-line-length
    content: `A firmware update is required before this equipment can be added to a tandem group.  This equipment is currently on firmware version ${current_FW}.  Tandem group functionality requires firmware v34+.  Firmware will update automatically overnight. Call DENTALEZ tech support (866-383-4636) if you need an update right away.`,
    okText: 'Close',
    onOk: () => 0,
  });
};
