/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import SStyles from '../styles';
import styles from './styles';

interface Col {
  text: string;
  selected?: boolean;
}

interface IProps {
  cols: Col[];
  activeIndex: number;
}

export default class extends Component<IProps> {
  render() {
    const { cols, activeIndex } = this.props;

    return (
      <div css={css(SStyles.row, styles.rowWrap)}>
        {cols.map((c, i) => {
          return (
            <div
              key={i}
              css={css(
                SStyles.row,
                styles.row,
                (activeIndex >= i || c.selected) && styles.activeRow
              )}
            >
              <div className={'bubble'} css={css(styles.numberCircle)}>
                {i + 1}
              </div>
              <div>{c.text}</div>
              {i !== cols.length - 1 && <div css={css(styles.divider)} />}
            </div>
          );
        })}
      </div>
    );
  }
}
