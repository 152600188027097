/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { _Equipment } from '../_shared/interfaces/equipment';
import SharedStyles from '../_shared/styles';
import styles from '../dashboard/equipment-details/equipment-details.styles';
import colors from '../_shared/colors';
import _Icon, { SvgIcon } from '../_shared/icon';
import { singleStringAddress } from '.';
import { SignalStrength } from '../dashboard/equipment-details/signal-strength.component';
import { first, get } from 'lodash';
import { useEffect, useState } from 'react';
import { Progress } from 'antd';
import moment from 'moment';

interface Opt {
  // tslint:disable-next-line:no-any
  obj: any;
  styles?: string;
  prefix?: string;
  get?: (val: string) => string | undefined;
  onClick?: () => void;
}

interface ColOpt {
  // tslint:disable-next-line:no-any
  text?: any;
  icon?: string;
  suffix?: string;
  color?: string;
  value?: number | string;
  fontSize?: number;
}

export const renderLabel = (name: string, opts: Opt = { obj: {} }) => {
  const eq = opts.obj;
  let v = !eq
    ? null
    : opts && opts.get
    ? opts.get(eq[name] as string)
    : name === 'address'
    ? singleStringAddress(eq[name])
    : eq[name];
  const val = `${opts.prefix || ''}${v}`;

  return !v ? null : (
    <p
      onClick={opts.onClick}
      css={css('white-space: nowrap;', (opts.styles || '').toString())}
    >
      {val}
    </p>
  );
};

export const renderSignalStrength = (
  item1: ColOpt,
  item2?: ColOpt,
  opts = {}
) => {
  opts = {
    canClick: true,
    ...opts,
  };
  const bars = get(item1, 'value') as number;
  return (
    <div>
      {!!get(opts, 'canClick') && (
        <SvgIcon
          type={'arrowUpRightFromSquare'}
          css={css(
            'font-size: 9px;',
            'float: right;',
            'margin-top: 0px;',
            `margin-right: 2px;`
          )}
        />
      )}
      <div
        css={css(
          SharedStyles.row,
          `width: 100%; justify-content: center; align-items: center;`
        )}
      >
        {!!bars && <SignalStrength bars={bars} />}
        {!!!bars && (
          <div
            css={css(
              styles.largeText,
              `text-wrap: wrap;`,
              `text-align: center;`,
              `font-size: 18px;`,
              `color: ${item1.color};`
            )}
          >
            {'--'}
          </div>
        )}
        {item1.icon && (
          <SvgIcon
            type={item1.icon}
            css={css(
              styles.smallText,
              styles.suffix,
              styles.suffixIcon,
              `margin-left: 2px`
            )}
          />
        )}
      </div>
      {item2 && (
        <div
          css={css(
            SharedStyles.row,
            styles.smallText,
            styles.suffix,
            `width: 100%; justify-content: center; align-items: center; margin-top: 10px; gap: 5px`
          )}
        >
          {item2.icon && (
            <SvgIcon
              type={item2.icon}
              css={css(styles.icon, `margin-bottom: 1px`)}
            />
          )}
          <span>{item2.text}</span>
        </div>
      )}
    </div>
  );
};

export const renderProgress = (props = {}) => {
  return <Progress {...props} />;
};

export const renderPosition = (props = {}) => {
  const key = get(props, 'key');
  const sensor = get(props, `sn.${key}`, false);

  props = {
    inuseText: 'YES',
    notInuseText: 'NO',
    ...props,
  };

  const inuse = !!sensor;

  const buttonBg = css(`
    border-radius: 40px;
    background-color: ${inuse ? colors.success : colors.disabledLight};
    border: 1px solid ${inuse ? colors.success : colors.disabledText};
    pointer-events: none;
    color: ${inuse ? colors.white : colors.disabledText};
    padding: 0 20px;
    font-weight: bold;
  `);

  return renderCol(
    {
      text: (
        <div css={buttonBg}>{`${
          inuse ? get(props, 'inuseText') : get(props, 'notInuseText')
        }`}</div>
      ),
    },
    Array.isArray(get(props, 'title'))
      ? get(props, 'title')
      : {
          text: get(props, 'title', ''),
          icon: get(props, 'icon'),
        }
  );
};

const toMS = function(num = 0) {
  var sec_num = parseInt(num.toString(), 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = 0 + hours;
  }
  // if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {
    seconds = 0 + seconds;
  }
  var time =
    hours >= 0
      ? hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + seconds
      : minutes + ':' + seconds;
  return time;
};

// tslint:disable-next-line:no-any
export const CountUp = (props: any) => {
  const { add, start, pause } = props;

  const [state, setState] = useState({
    start: moment(get(props, 'start')),
    // add: 0,
    count: 0,
  });

  useEffect(() => {
    setState(s => ({
      ...s,
      // add: add || 0,
      count: !add ? 0 : s.count,
      start: moment(start),
    }));
  }, [add, start]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!pause) {
      interval = setInterval(() => {
        setState(s => ({ ...s, count: s.count + 1 }));
      }, 1000);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [pause]);

  return (
    <div>
      {toMS(
        moment(state.start)
          .add(state.count, 'seconds')
          .diff(moment(state.start), 'seconds')
      )}
    </div>
  );
};

export const renderCol = (
  item1?: ColOpt,
  item2?: ColOpt | ColOpt[],
  clickable?: boolean
) => {
  const getFontSize = (
    item?: ColOpt | ColOpt[],
    fallback: number = 10
  ): string => {
    const it = (Array.isArray(item) ? first(item) : item) as ColOpt;
    return !item
      ? `${fallback}px`
      : `${item && it.fontSize ? it.fontSize : fallback}px`;
  };

  const item1FontSize = getFontSize(item1, 18);
  const item2FontSize = getFontSize(item2, 10);

  const ico1style = css(
    styles.smallText,
    styles.suffix,
    styles.suffixIcon,
    `margin-left: 2px`
  );

  const ico2style = css(styles.icon, `margin-bottom: 1px;`);

  return (
    <div>
      <div css={css('height: 9px;', 'width: 9px;', 'float: right;')}>
        <SvgIcon
          type={clickable ? 'arrowUpRightFromSquare' : ''}
          css={css('font-size: 9px;')}
        />
      </div>
      {item1 && (
        <div
          css={css(
            SharedStyles.row,
            `width: 100%; justify-content: center; align-items: center;`
          )}
        >
          <div
            css={css(
              styles.largeText,
              `text-wrap: wrap;`,
              `text-align: center;`,
              `font-size: ${item1FontSize};`,
              `color: ${item1.color};`
            )}
          >
            {item1.text}
          </div>
          {!!item1.suffix && (
            <div css={css(styles.smallText, styles.suffix)}>{item1.suffix}</div>
          )}
          {item1.icon &&
            (item1.icon.includes('fa-') ? (
              <i className={`fa ${item1.icon}`} css={ico1style} />
            ) : (
              <SvgIcon type={item1.icon} css={ico1style} />
            ))}
        </div>
      )}
      {!!item2 && (
        <div
          css={css(
            SharedStyles.row,
            styles.smallText,
            styles.suffix,
            `width: 100%; justify-content: center; align-items: center; margin-top: 10px; gap: 5px`
          )}
        >
          {(Array.isArray(item2) ? item2 : [item2]).map((it2, i) => {
            const ico = !it2.icon ? null : it2.icon.includes('fa-') ? (
              <i className={`fa ${it2.icon}`} css={ico2style} />
            ) : (
              <SvgIcon type={it2.icon} css={ico2style} />
            );

            return (
              <React.Fragment key={i}>
                {ico}
                <span css={css(`font-size: ${item2FontSize}`)}>{it2.text}</span>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const renderHandpieceCol = (
  item1?: ColOpt,
  item2?: ColOpt,
  item3?: ColOpt
) => {
  return (
    <div>
      {item1 && (
        <div
          css={css(
            SharedStyles.row,
            styles.smallText,
            `width: 100%; justify-content: center; align-items: center;`
          )}
        >
          <span>{item1.text}</span>
          {item1.icon && (
            <_Icon
              name={item1.icon}
              color={colors.labelColor}
              size={9}
              css={css(
                styles.smallText,
                `margin-bottom: 3px; margin-left: auto;`
              )}
            />
          )}
        </div>
      )}
      {item2 && (
        <div
          css={css(
            SharedStyles.row,
            `width: 100%; justify-content: center; align-items: center;`
          )}
        >
          <div
            css={css(
              styles.largeText,
              item2.color &&
                `color: ${item2.color}, text-wrap: wrap; text-align: center;`
            )}
          >
            {item2.text}
          </div>
          {!!item2.suffix && (
            <div css={css(styles.smallText, styles.suffix)}>{item2.suffix}</div>
          )}
          {item2.icon && (
            <_Icon
              name={item2.icon}
              color={colors.labelColor}
              size={9}
              css={css(
                styles.smallText,
                styles.suffix,
                styles.suffixIcon,
                `transform: rotate(90deg);`
              )}
            />
          )}
        </div>
      )}
      {item3 && (
        <div
          css={css(
            SharedStyles.row,
            styles.mediumText,
            styles.suffix,
            `width: 100%; justify-content: center; align-items: center; margin-top: 20px`
          )}
        >
          {item3.icon && (
            <SvgIcon
              type={item3.icon}
              css={css(styles.icon, `margin-right: 16px; margin-bottom: 1px`)}
            />
          )}
          <span>{item3.text}</span>
        </div>
      )}
    </div>
  );
};
