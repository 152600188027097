import colors from '../colors';

export default {
  titleText: `
    font-size: 16px;
    margin-left: 10px;
  `,
  text: `
    font-size: 13px;
    align-self: flex-start;
  `,
  dateHeader: `
    font-size: 13px;
    font-weight: bold;
  `,
};

export const globals = `
    .ant-modal-header,
    .ant-modal-footer {
      border-color: transparent;
    }
`;
