import colors from '../colors';

export default {
  container: `
    position: relative;

    &:hover {
      span {
        opacity: 1.0;
      }
    }
  `,
  edit: `
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.0;
    transition: 0.5s all;
    cursor: pointer;
    padding: 2px;
  `,
  closeIcon: `
    color: ${colors.disabledColor};
    font-size: 12px;
  `,
  italic: `
    font-style: italic;
  `,
};
