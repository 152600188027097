/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, ReactElement } from 'react';
import { Table, Select, Icon } from 'antd';
import SharedStyles from '../styles';
import { get } from 'lodash';
import styles from './row-list.styles';
import { Footer } from '../../footer';
import { ColumnProps } from 'antd/lib/table';

interface IProps<T> {
  columns: ColumnProps<T>[];
  data: never[];
  canSelectRows?: boolean;
  loading?: boolean;
  implementScroll?: boolean;
  showPagination?: boolean;
  scrollHeight?: number;
  pageSize?: number;
  // tslint:disable-next-line:no-any
  rowClick?: (record: any, rowIndex: number) => void;
  onSelectedRowsChange?: (keys: string[] | number[]) => void;
  // tslint:disable-next-line:no-any
  renderOpen?: (record: any, rowIndex: number) => ReactElement;
}

export class RowList<T> extends Component<IProps<T>> {
  state = {
    itemOpen: undefined,
    loading: false,
    pageSize: this.props.pageSize || 25,
  };

  static defaultProps = {
    canSelectRows: true,
    implementScroll: false,
    showPagination: true,
    pageSize: 25,
  };

  // tslint:disable-next-line:no-any
  rowClick = (record: any, rowIndex: number) => {
    const { rowClick } = this.props;
    if (rowClick) {
      rowClick(record, rowIndex);
    }
  };
  changeSelect = (v: number) => {
    this.setState({ pageSize: v });
    setTimeout(() => this.forceUpdate(), 20);
  };
  renderPageSelect = () => {
    const { pageSize } = this.state;
    const pageOptions = [
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: 'All', value: 10000 },
    ];

    const pagination = document.getElementsByClassName(
      'ant-pagination'
    )[0] as HTMLElement;
    let width = 0;
    if (pagination) {
      width = pagination.offsetWidth;
    }

    return (
      <div
        css={css(
          SharedStyles.row,
          `width: 140px; 
          position: relative;
          float: right;
          margin-bottom: 100px;
          padding-bottom: 15px;
        `
        )}
      >
        <label css={css(`margin-right: 10px;`)}>Page Size</label>
        <Select size="small" onChange={this.changeSelect} value={pageSize}>
          {pageOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };
  // tslint:disable-next-line:no-any
  renderItem = (item: any, index: number) => {
    const { columns, renderOpen } = this.props;
    const { itemOpen } = this.state;
    const isOpen = itemOpen === index;

    return (
      <div
        key={`item${index}`}
        onClick={() => this.rowClick(item, index)}
        css={css(styles.rowContainer, styles.border, isOpen && 'height: auto;')}
      >
        <div css={css(SharedStyles.row, styles.rowContainer)}>
          {columns.map((col, i) => {
            const hasRender = get(col, 'render');
            const di = get(col, 'dataIndex');
            const val = di ? get(item, di) : undefined;
            return (
              <div
                key={`col${i}`}
                css={css(
                  i === 0
                    ? `width: ${100 / columns.length}%;`
                    : `width: ${100 / columns.length - 10}%;`
                )}
              >
                {hasRender ? hasRender(val, item, index) : val}
              </div>
            );
          })}
          <Icon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ itemOpen: isOpen ? undefined : index });
            }}
            css={css(styles.chevron)}
            type={isOpen ? 'up' : 'right'}
          />
        </div>

        {isOpen && renderOpen && (
          <div css={css(styles.openContainer)}>{renderOpen(item, index)}</div>
        )}
      </div>
    );
  };
  renderHeader = () => {
    const { columns } = this.props;
    return (
      <div css={css(SharedStyles.row, styles.header)}>
        {columns.map((col, i) => {
          return (
            <div
              key={i}
              css={css(
                styles.headerCol,
                i === 0
                  ? `width: ${100 / columns.length}%;`
                  : `width: ${100 / columns.length - 12}%;`
              )}
            >
              {col.title}
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    const { data } = this.props;
    let dataToRender =
      data.length > 25 ? data.slice(0, this.state.pageSize - 1) : data;
    return (
      <div
        css={css(`
          height: 1000px;
        `)}
      >
        {this.renderHeader()}
        {dataToRender.map(this.renderItem)}
        {data.length > 25 && this.renderPageSelect()}
        <Footer shouldExtend={true} />
      </div>
    );
  }
}
