import getConfig from '../../config';
import { get, set } from 'lodash';
import { Schedule } from '../interfaces/schedule';
import { removeEmpty, pullDataOutOfString } from '../../utils';
import { message } from 'antd';
import { instance } from './instance';

export const getSchedule = async (id: string) => {
  const resp = await instance.get(`/schedule/${id}`, { data: {} });
  return get(resp, 'data.schedule', null) as Schedule;
};

export const getSchedules = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/schedule`, {
      where,
    });
  } else {
    resp = await instance.get(`/schedule`, { data: {} });
  }
  return get(resp, 'data.schedule', []) as Schedule[];
};

export const createSchedule = async (schedule: Schedule) => {
  const resp = await instance.post('/schedule/create', {
    schedule: removeEmpty(schedule),
  });
  return get(resp, 'data.schedule', null) as Schedule;
};

export const updateSchedule = (schedule: Schedule) => {
  return createSchedule(schedule);
};

export const deleteSchedule = async (schedule: Schedule) => {
  const resp = await instance.delete(`/schedule/${schedule.id}`);
  return get(resp, 'data.schedule', null) as Schedule;
};

export const loadEvents = async () => {
  const resp = await instance.get('/schedule/loadEvents', { data: {} });
  return resp;
};

export const sendDeviceCommand = async (
  deviceId: string,
  data: {},
  // tslint:disable-next-line
  _opts: any = { showErrorToast: true },
  isHandpiece: boolean = false,
  callback?: (success: boolean) => void
) => {
  const opts = {
    timeout: 0,
    ..._opts,
  };

  try {
    const config = getConfig();
    const resp = await instance.post(
      '/schedule/sendDeviceCommand',
      {
        deviceId,
        data,
      },
      {
        headers: {
          accesstoken: config.access_token,
        },
        ...opts,
      }
    );
    const respData = get(resp, 'data.success', {});

    const returnCode = respData.return_value;
    if (returnCode < 0 && (!isHandpiece && returnCode !== -12)) {
      // -12 code is treated as unknown error for non-handpieces (I guess?)<calvin>
      const errr = new Error(
        isHandpiece
          ? handpieceErrorFromCode(returnCode)
          : errorFromCode(returnCode)
      );
      set(errr, 'response', resp);
      throw errr;
    }

    // at this point assuming the device command responded as success-esque
    // invoke callback success
    callback && callback(true);

    return resp;
  } catch (err) {
    const error = pullDataOutOfString(
      get(err, 'response.data.error', err.message)
    );
    console.error(error);
    if (opts.showErrorToast) {
      message.error(`Request failed. Unable to communicate with equipment.`);
    }
    const retError = new Error(get(error, 'error', error));
    set(retError, 'data', error);
    callback && callback(false);
    throw retError;
  }
};

const errorFromCode = (code: number): string => {
  switch (code) {
    default:
      return 'Unable to complete command -- unknown error';
    case -1:
      return 'ERROR_FUNC_ARG1_FORMATTING';
    case -2:
      return 'ERROR_FUNC_ARG2_FORMATTING';
    case -3:
      return 'ERROR_FUNC_ARG3_FORMATTING';
    case -4:
      return 'ERROR_FUNC_ARG4_FORMATTING';
    case -5:
      return 'ERROR_FUNC_ARG5_FORMATTING';
    case -6:
      return 'ERROR_FUNC_ARG6_FORMATTING';
    case -7:
      return 'ERROR_FUNC_ARG7_FORMATTING';
    case -8:
      return 'ERROR_FUNC_ARG8_FORMATTING';
    case -9:
      return 'ERROR_FUNC_RESERVED';
    case -10:
      return 'ERROR_FUNC_NAME_UNKNOWN';
    case -11:
      return 'ERROR_FUNC_INVALID_OPERATION';
    case -12:
      return 'ERROR_FUNC_OPERATION_FAILED';
  }
};

const handpieceErrorFromCode = (code: number): string => {
  switch (code) {
    default:
      return 'Unable to complete command -- unknown error';
    case -1:
      return 'Not owner';
    case -2:
      return 'No such file or directory';
    case -3:
      return 'No such process';
    case -4:
      return 'Interrupted system call';
    case -5:
      return 'I/O error';
    case -6:
      return 'No such device or address';
    case -7:
      return 'Arg list too long';
    case -8:
      return 'Exec format error';
    case -9:
      return 'Bad file number';
    case -10:
      return 'No children';
    case -11:
      return 'No more processes';
    case -12:
      return 'Not enough space';
    case -13:
      return 'Permission denied';
    case -14:
      return 'Bad address';
    //no 15, I guess
    case -16:
      return 'Bad address';
    case -17:
      return 'File exists';
    case -18:
      return 'Cross-device link';
    case -19:
      return 'No such device';
    case -20:
      return 'Not a directory';
    case -21:
      return 'Is a directory';
    case -22:
      return 'Invalid argument';
    case -23:
      return 'Too many open files in system';
    case -24:
      return 'File descriptor value too large';
    case -25:
      return 'Not a character device';
    case -26:
      return 'Textfile busy';
    case -27:
      return 'File too large';
    case -28:
      return 'No space left on device';
    case -29:
      return 'Illegal seek';
    case -30:
      return 'Read-only file system';
    case -31:
      return 'Too many links';
    case -32:
      return 'Broken pipe';
    case -33:
      return 'Mathematics argument out of domain functions';
    case -34:
      return 'Result too large';
    case -35:
      return 'No message of desired type';
    case -36:
      return 'Identifier removed';
    //big jump
    case -77:
      return 'Bad message';
    case -79:
      return 'Inappropriate file type or format';
    case -88:
      return 'Function not implemented';
  }
};
