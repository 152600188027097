import { Location } from '../../_shared/interfaces/location';
import {
  IHandpieceDefaultsProfiles,
  HandpiecePresetKey,
  IHandpiecePreset,
  HandpieceProfileKey,
} from '../interfaces/handpieceProfiles';
import { Equipment } from '../interfaces/equipment';
import { instance } from './instance';

export const setHandpieceProfileOverride = async (
  loc: Location,
  profileKey: HandpieceProfileKey,
  name: string
) => {
  try {
    await instance.post(`/hp-profiles/handpiece-profile-location-override`, {
      orgId: loc.orgId,
      locationId: loc.id,
      profileKey,
      name,
    });

    return true;
  } catch (err) {
    return false;
  }
};

export const setHandpiecePresetOverride = async (
  loc: Location,
  newPreset: IHandpiecePreset,
  profileKey: HandpieceProfileKey,
  presetKey: HandpiecePresetKey
) => {
  try {
    await instance.post(`/hp-profiles/handpiece-preset-location-overrides`, {
      orgId: loc.orgId,
      locationId: loc.id,
      preset: {
        ...newPreset,
        profileKey,
        presetKey,
      },
    });

    return true;
  } catch (err) {
    return false;
  }
};

export const getHandpieceProfilesForLocation = async (
  loc: Location,
  firmware: number = 1,
  version: number = 1
): Promise<IHandpieceDefaultsProfiles | undefined> => {
  try {
    const response = await instance.get(
      `/hp-profiles/handpiece-presets-for-location?orgId=${loc.orgId}&locId=${loc.id}&fw=${firmware}&def=${version}`
    );
    // todo: this response is wonk -- why is it sometimes one way or the other.
    // investigate this on backend
    if (response && response.data && response.data.Items) {
      let profiles = response.data.Items[0] as IHandpieceDefaultsProfiles;
      delete profiles.fw;
      delete profiles.version;
      return profiles;
    }
    if (response && response.data) {
      let profiles = response.data as IHandpieceDefaultsProfiles;
      delete profiles.fw;
      delete profiles.version;
      return profiles;
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const resetHandpiecePresets = async (
  equipment: Equipment,
  orgId: string,
  locationId: string
): Promise<{ error?: string; synced: string[]; failed: string[] }> => {
  try {
    var failed: string[] = [];
    var synced: string[] = [];

    const prepResult = await instance.post(
      `/hp-profiles/handpiece-reset-prep-profiles`,
      {
        handpiece: equipment,
        orgId,
        locationId,
        def: 1,
      }
    );
    if (!prepResult || prepResult.status !== 200) {
      throw new Error('request failed');
    }
    if (!prepResult.data || prepResult.data.error) {
      throw prepResult.data.error;
    }

    failed.push(...prepResult.data.failed);
    synced.push(...prepResult.data.synced);

    const endoResult = await instance.post(
      `/hp-profiles/handpiece-reset-endo-profiles`,
      {
        handpiece: equipment,
        orgId,
        locationId,
        def: 1,
      }
    );
    if (!endoResult || endoResult.status !== 200) {
      throw new Error('request failed');
    }
    if (!endoResult.data || endoResult.data.error) {
      throw endoResult.data.error;
    }

    failed.push(...endoResult.data.failed);
    synced.push(...endoResult.data.synced);

    return { synced, failed };
  } catch (err) {
    return { error: err.message, synced: [], failed: [] };
  }
};
