/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  BaseMaintPData,
  MaintSensorData,
} from '../../../../_shared/interfaces/publishData';
import { EQ_TYPE } from '../../../../_shared/interfaces/equipment';
import { TimePicker } from 'antd';
import { Model } from '../../../../_shared/interfaces/model';
import styles from '../../spoof-a-publish.styles';
import {
  createMaintSensorDataInputs,
  sensorDataIsValid,
} from './maint-form-container.component';
import { makeInput, makeNumInput } from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: BaseMaintPData | undefined) => void;
  equipmentType: EQ_TYPE;
  model: Model;
}

export const BaseDzMaintDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged, model } = props;

  const now = () => moment();
  const emptyMaintSensorData = (): MaintSensorData => [
    false,
    0,
    100,
    now()
      .utc()
      .unix(),
  ];

  //STATE
  const [motors, setMotors] = useState<MaintSensorData[]>([]);
  const [motorSerials, setMotorSerials] = useState<(string | undefined)[]>([]);
  const [enabled, setEnabled] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [general, setGeneral] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [amSensor, setAMSensor] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [inUse, setInUse] = useState<MaintSensorData>(emptyMaintSensorData());
  const [tpSensor, setTPSensor] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [seq, setSeq] = useState<number | undefined>(undefined);
  const [time, setTime] = useState<Moment>(now());

  //HANDLERS
  const timeChanged = (m: Moment, _s: string) => {
    setTime(m);
  };
  const setMotorMaintData = (motorData: MaintSensorData, index: number) => {
    let mutable = motors;
    mutable[index] = motorData;
    setMotors([...mutable]);
  };
  const setMotorSerialNumber = (serial: string | undefined, index: number) => {
    let mutable = motorSerials;
    mutable[index] = serial;
    setMotorSerials([...mutable]);
  };

  //EFFECTS
  useEffect(() => {
    if (model !== undefined && model.heads !== undefined && model.heads > 0) {
      let motors = [] as MaintSensorData[];
      for (let i = 0; i < model.heads; i++) {
        motors[i] = emptyMaintSensorData();
      }
      setMotors(motors);
    }
  }, []); //pass empty array so this just runs on attach, initialize motor array to dynamic size

  useEffect(() => {
    //VALIDATE!
    if (
      !motors.some(m => !sensorDataIsValid(m)) && //all motor data should be valid
      !motorSerials.some(s => s === undefined) && //all serial numbers shuold be defined
      sensorDataIsValid(enabled) &&
      sensorDataIsValid(general) &&
      sensorDataIsValid(amSensor) &&
      sensorDataIsValid(inUse) &&
      sensorDataIsValid(tpSensor) &&
      !!seq
    ) {
      //seq must be defined
      validChanged(true);

      let serial_numbers: { [key: string]: string } = {};
      motors.forEach((_motor, index) => {
        let serial = motorSerials[index];
        if (serial !== undefined) {
          serial_numbers[`motor${index + 1}`] = serial;
        }
      });

      payloadChanged({
        motors: motors,
        serial_numbers: serial_numbers,
        enabled: enabled,
        general: general,
        amsensor: amSensor,
        inuse: inUse,
        tpsensor: tpSensor,
        seq: seq,
        time: time.utc().unix(),
      });
    } else {
      payloadChanged(undefined);
      validChanged(false);
    }
  }, [
    motors,
    motorSerials,
    enabled,
    general,
    amSensor,
    inUse,
    tpSensor,
    time,
    seq,
  ]);

  //UI GENERATIVE
  const generateMotorInputs = (): JSX.Element[] => {
    let content = [] as JSX.Element[];

    if (model !== undefined && model.heads !== undefined && model.heads > 0) {
      for (let i = 0; i < model.heads; i++) {
        const motorMaintData = motors[i];

        if (motorMaintData !== undefined) {
          content.push(
            <div key={`hs${i}`}>
              {createMaintSensorDataInputs(
                `Motor #${i + 1}`,
                motorMaintData,
                newData => setMotorMaintData(newData, i)
              )}
              {makeInput(`Motor #${i + 1} Serial`, motorSerials[i], serial =>
                setMotorSerialNumber(serial, i)
              )}
            </div>
          );
        }
      }
    } else {
      content.push(
        <p key={'heads failure'}>unable to determine number of heads</p>
      );
    }

    return content;
  };

  //UI DECLARATIVE
  return (
    <div>
      {createMaintSensorDataInputs('Enabled', enabled, setEnabled)}
      {createMaintSensorDataInputs('General', general, setGeneral)}
      {createMaintSensorDataInputs('AM Sensor', amSensor, setAMSensor)}
      {createMaintSensorDataInputs('In User', inUse, setInUse)}
      {createMaintSensorDataInputs('TP Sensor', tpSensor, setTPSensor)}
      {generateMotorInputs()}
      {makeNumInput('Seq', seq, setSeq)}

      <div css={css(styles.inputGroup)}>
        <p>Time:</p>
        <TimePicker
          defaultValue={time}
          format={'HH:mm'}
          onChange={timeChanged}
        />
      </div>
    </div>
  );
};
