// tslint:disable:max-line-length
import React, { Component, MouseEvent, ComponentType } from 'react';
import { Icon as AntIcon } from 'antd';
import { IconProps, CustomIconComponentProps } from 'antd/lib/icon';
import { get } from 'lodash';

const ICONS: {
  [key: string]: ComponentType<CustomIconComponentProps>;
} = {
  account: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
      preserveAspectRatio="none"
    >
      <title>Icon</title>
      <path d="M9.53,14V5.59L5.68,7.19A8.73,8.73,0,0,0,9.53,14Zm-.86-1.8A8.59,8.59,0,0,1,6.61,7.74l2.06-.86Zm6.54-7L10,3A1.24,1.24,0,0,0,9,3L3.85,5.15a1.28,1.28,0,0,0-.8,1.19c0,5.36,3.09,9.07,6,10.27a1.33,1.33,0,0,0,1,0c2.31-1,6-4.29,6-10.27A1.28,1.28,0,0,0,15.21,5.15ZM9.7,15.81a.4.4,0,0,1-.34,0c-2.64-1-5.45-4.72-5.45-9.47a.45.45,0,0,1,.27-.4L9.36,3.78a.5.5,0,0,1,.34,0l5.18,2.16a.45.45,0,0,1,.27.4C15.15,11.09,12.34,14.77,9.7,15.81Z" />
    </svg>
  ),
  dentalOrg: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M12.45,17.83a1.34,1.34,0,0,1-1.3-1L10.2,13a.61.61,0,0,0-.6-.48A.63.63,0,0,0,9,13l-.94,3.79a1.34,1.34,0,0,1-2.6,0l-.21-.93a28.88,28.88,0,0,1-.59-3.92,5.14,5.14,0,0,0-.9-2.69A4.09,4.09,0,0,1,3.08,5.8,4,4,0,0,1,6,2.87a2.93,2.93,0,0,1,2.19.32l1.34.86.76-.37.12-.07a3.35,3.35,0,0,1,2.87-.74A4,4,0,0,1,16.12,5.8a4.09,4.09,0,0,1-.67,3.45,5.05,5.05,0,0,0-.9,2.69A29.75,29.75,0,0,1,14,15.86l-.21.93A1.34,1.34,0,0,1,12.45,17.83ZM9.6,11.54a1.64,1.64,0,0,1,1.58,1.23l.94,3.8a.35.35,0,0,0,.65,0l.22-.94a30.15,30.15,0,0,0,.56-3.78,6.18,6.18,0,0,1,1.09-3.2A3.06,3.06,0,0,0,15.15,6h0A3.06,3.06,0,0,0,13,3.83a2.14,2.14,0,0,0-1.67.35l.1.07a.9.9,0,0,1,.41.59.91.91,0,0,1-.12.7.94.94,0,0,1-.6.41.91.91,0,0,1-.7-.12L7.65,4.05a2.08,2.08,0,0,0-1.44-.22A3.09,3.09,0,0,0,4.56,8.65a6.18,6.18,0,0,1,1.09,3.2,28.55,28.55,0,0,0,.56,3.78l.22.94a.35.35,0,0,0,.65,0L8,12.77A1.63,1.63,0,0,1,9.6,11.54Z" />
    </svg>
  ),
  equipment: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M9,2.24,2.94,4.53A1.41,1.41,0,0,0,2,5.85v6.6a1.42,1.42,0,0,0,.77,1.26l6.1,3.05a1.4,1.4,0,0,0,1.26,0l6.1-3.05A1.4,1.4,0,0,0,17,12.45V5.85a1.41,1.41,0,0,0-.91-1.32L10,2.25A1.33,1.33,0,0,0,9,2.24Zm.49.82L16.1,5.53h0L9.53,8.38,3,5.53H3ZM3,6.56,9.06,9.2v6.59l-6.1-3Zm7,9.23V9.2l6.1-2.64v6.18l-6.1,3Z" />
    </svg>
  ),
  groups: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M16.22,9.35a2.54,2.54,0,0,0-2-4A2.49,2.49,0,0,0,12.4,6a3.87,3.87,0,0,0-.48-.59,3.38,3.38,0,0,0-4.78,0A3.87,3.87,0,0,0,6.66,6,2.49,2.49,0,0,0,4.88,5.3a2.54,2.54,0,0,0-2,4.05,2.12,2.12,0,0,0-1.77,2.08V12.6a1.16,1.16,0,0,0,1.16,1.16H5.35a1.15,1.15,0,0,0,1.11.85H12.6a1.15,1.15,0,0,0,1.11-.85h3.12A1.16,1.16,0,0,0,18,12.6V11.43A2.12,2.12,0,0,0,16.22,9.35Zm-2-3.2a1.69,1.69,0,1,1-1.4,2.64,3.4,3.4,0,0,0,.13-.95,3.35,3.35,0,0,0-.13-1A1.68,1.68,0,0,1,14.18,6.15ZM9.53,5.3A2.54,2.54,0,1,1,7,7.84,2.54,2.54,0,0,1,9.53,5.3Zm-4.65.85a1.68,1.68,0,0,1,1.4.74,3.35,3.35,0,0,0-.13,1,3.4,3.4,0,0,0,.13.95,1.69,1.69,0,1,1-1.4-2.64Zm.42,5.92v.84H2.23a.31.31,0,0,1-.31-.31V11.43a1.26,1.26,0,0,1,1.27-1.26h.67a2.56,2.56,0,0,0,2,0h.6A2.11,2.11,0,0,0,5.3,12.07Zm7.61,1.37a.31.31,0,0,1-.31.32H6.46a.31.31,0,0,1-.31-.32V12.07A1.27,1.27,0,0,1,7.42,10.8h.47a3.39,3.39,0,0,0,3.28,0h.47a1.27,1.27,0,0,1,1.27,1.27Zm4.23-.84a.31.31,0,0,1-.31.31H13.76v-.84a2.11,2.11,0,0,0-1.19-1.9h.6a2.56,2.56,0,0,0,2,0h.67a1.26,1.26,0,0,1,1.27,1.26Z" />
    </svg>
  ),
  locations: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M9.6,4.62a2.95,2.95,0,1,0,3,3A3,3,0,0,0,9.6,4.62Zm0,4.91a2,2,0,1,1,2-2A2,2,0,0,1,9.6,9.53Zm0-7.85A5.89,5.89,0,0,0,3.71,7.57c0,2.37.83,3,5.28,9.5a.75.75,0,0,0,1.22,0c4.45-6.46,5.28-7.13,5.28-9.5A5.89,5.89,0,0,0,9.6,1.68Zm0,14.54C5.33,10,4.69,9.55,4.69,7.57A4.91,4.91,0,0,1,13.07,4.1a4.85,4.85,0,0,1,1.44,3.47C14.51,9.55,13.87,10,9.6,16.22Z" />
    </svg>
  ),
  serials: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M16.1,9.28,10.41,3.59a1.3,1.3,0,0,0-.91-.38H4A1.28,1.28,0,0,0,2.73,4.5V10a1.3,1.3,0,0,0,.37.91l5.69,5.69a1.3,1.3,0,0,0,1.83,0l5.48-5.48a1.3,1.3,0,0,0,0-1.83Zm-.61,1.22L10,16a.43.43,0,0,1-.61,0L3.71,10.29a.43.43,0,0,1-.13-.3V4.5A.44.44,0,0,1,4,4.07H9.5a.43.43,0,0,1,.3.13l5.69,5.69a.43.43,0,0,1,0,.61Zm-8.9-4a.54.54,0,0,1,.54.54.54.54,0,0,1-.54.54.54.54,0,0,1-.54-.54.55.55,0,0,1,.54-.54m0-.75A1.29,1.29,0,1,0,7.88,7.08,1.29,1.29,0,0,0,6.59,5.79Z" />
    </svg>
  ),
  models: props => (
    <svg
      {...props}
      viewBox="0 0 75 60"
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
    >
      <title>Icon</title>
      <path d="M26.25 9H24.75C24.2812 9 24 9.375 24 9.75V38.25C24 38.7188 24.2812 39 24.75 39H26.25C26.625 39 27 38.7188 27 38.25V9.75C27 9.375 26.625 9 26.25 9ZM20.25 9H18.75C18.2812 9 18 9.375 18 9.75V38.25C18 38.7188 18.2812 39 18.75 39H20.25C20.625 39 21 38.7188 21 38.25V9.75C21 9.375 20.625 9 20.25 9ZM55.5 0H4.5C1.96875 0 0 2.0625 0 4.5V43.5C0 46.0312 1.96875 48 4.5 48H55.5C57.9375 48 60 46.0312 60 43.5V4.5C60 2.0625 57.9375 0 55.5 0ZM57 43.5C57 44.3438 56.25 45 55.5 45H4.5C3.65625 45 3 44.3438 3 43.5V4.5C3 3.75 3.65625 3 4.5 3H55.5C56.25 3 57 3.75 57 4.5V43.5ZM14.25 9H9.75C9.28125 9 9 9.375 9 9.75V38.25C9 38.7188 9.28125 39 9.75 39H14.25C14.625 39 15 38.7188 15 38.25V9.75C15 9.375 14.625 9 14.25 9ZM50.25 9H45.75C45.2812 9 45 9.375 45 9.75V38.25C45 38.7188 45.2812 39 45.75 39H50.25C50.625 39 51 38.7188 51 38.25V9.75C51 9.375 50.625 9 50.25 9ZM38.25 9H33.75C33.2812 9 33 9.375 33 9.75V38.25C33 38.7188 33.2812 39 33.75 39H38.25C38.625 39 39 38.7188 39 38.25V9.75C39 9.375 38.625 9 38.25 9Z" />
    </svg>
  ),
  serviceOrg: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M5.7,15A.72.72,0,1,1,5,14.28.72.72,0,0,1,5.7,15ZM16.83,9.71a4.81,4.81,0,0,1-4.16,2.41A4.7,4.7,0,0,1,11.43,12L6.2,17.18a2.4,2.4,0,0,1-3.4-3.4L8,8.55a4.7,4.7,0,0,1-.17-1.24,4.81,4.81,0,0,1,9-2.41A1,1,0,0,1,16,6.35H12.72l-.6,1,.6,1H16A1,1,0,0,1,16.83,9.71Zm-6.38,1.86a4.78,4.78,0,0,1-2-2L3.48,14.46a1.44,1.44,0,0,0,2,2l4.93-4.93ZM16,9.23H12.19L11,7.31l1.2-1.92H16a3.84,3.84,0,1,0,0,3.84Z" />
    </svg>
  ),
  users: props => (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.06 19.06"
    >
      <title>Icon</title>
      <path d="M12.62,9.07A4.08,4.08,0,0,0,13.68,6.3a4.15,4.15,0,0,0-8.3,0A4.08,4.08,0,0,0,6.44,9.07,3.23,3.23,0,0,0,3.08,12.3v3.34a1.26,1.26,0,0,0,1.26,1.27H14.72A1.26,1.26,0,0,0,16,15.64V12.3A3.23,3.23,0,0,0,12.62,9.07Zm-3.09-6A3.23,3.23,0,1,1,6.3,6.3,3.23,3.23,0,0,1,9.53,3.08Zm5.53,12.56a.35.35,0,0,1-.34.35H4.34A.35.35,0,0,1,4,15.64V12.3A2.3,2.3,0,0,1,6.3,10H7.63a4.11,4.11,0,0,0,3.8,0h1.33a2.3,2.3,0,0,1,2.3,2.31Z" />
    </svg>
  ),
  resources: props => (
    <svg
      {...props}
      id="Layer_1"
      viewBox="0 0 54 72"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon</title>
      <path d="M31.5 39.5156C31.5 37.6875 29.8125 36 27.9844 36H12.375C10.5469 36 9 37.6875 9 39.5156V55.125C9 56.9531 10.5469 58.5 12.375 58.5H27.9844C29.8125 58.5 31.5 56.9531 31.5 55.125V53.5781L35.2969 57.5156C35.8594 58.0781 36.9844 58.5 37.6875 58.5H41.625C43.4531 58.5 45 57.0938 45 55.125V39.375C45 37.5469 43.4531 36 41.625 36H37.6875C36.9844 36 35.8594 36.5625 35.2969 37.125L31.5 40.9219V39.5156ZM27 54H13.5V40.5H27V54ZM38.25 40.5H40.5V54H38.25L31.5 48.2344V46.4062L38.25 40.5ZM51.8906 13.7812L40.2188 2.10938C39.0938 0.984375 36.9844 0 35.4375 0H6.75C2.95312 0 0 3.09375 0 6.75V65.25C0 69.0469 2.95312 72 6.75 72H47.25C50.9062 72 54 69.0469 54 65.25V18.5625C54 17.0156 53.0156 14.9062 51.8906 13.7812ZM36 4.64062C36.2812 4.78125 36.7031 4.92188 36.9844 5.20312L48.7969 17.0156C49.0781 17.2969 49.2188 17.7188 49.3594 18H36V4.64062ZM49.5 65.25C49.5 66.5156 48.375 67.5 47.25 67.5H6.75C5.48438 67.5 4.5 66.5156 4.5 65.25V6.75C4.5 5.625 5.48438 4.5 6.75 4.5H31.5V19.125C31.5 21.0938 32.9062 22.5 34.875 22.5H49.5V65.25Z" />
    </svg>
  ),
  tandemGroups: props => (
    <svg
      {...props}
      viewBox="0 0 60 60"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon</title>
      <path d="M48 24.0938C48 23.1562 47.4375 22.3125 46.5938 21.9375L37.2188 17.8125L46.5938 13.7812C47.4375 13.4062 48 12.5625 48 11.625C48 10.6875 47.4375 9.84375 46.5938 9.5625L25.4062 0.375C24.4688 -0.09375 23.4375 -0.09375 22.5 0.375L1.3125 9.5625C0.46875 9.84375 0 10.6875 0 11.625C0 12.5625 0.46875 13.4062 1.3125 13.7812L10.6875 17.8125L1.3125 21.9375C0.46875 22.3125 0 23.1562 0 24.0938C0 24.9375 0.46875 25.7812 1.3125 26.1562L10.6875 30.2812L1.3125 34.3125C0.46875 34.6875 0 35.5312 0 36.4688C0 37.4062 0.46875 38.25 1.3125 38.5312L22.5 47.7188C22.875 47.9062 23.5312 48 23.9062 48C24.4688 48 24.9375 47.9062 25.4062 47.7188L46.5938 38.5312C47.4375 38.25 47.9062 37.4062 47.9062 36.4688C47.9062 35.5312 47.4375 34.6875 46.5938 34.3125L37.2188 30.2812L46.5938 26.1562C47.4375 25.7812 48 24.9375 48 24.0938ZM4.21875 11.625L23.7188 3.1875C23.9062 3.09375 24 3.09375 24.1875 3.1875L43.6875 11.625L24.1875 20.1562C24 20.1562 23.9062 20.1562 23.7188 20.1562L4.21875 11.625ZM43.6875 36.4688L24.1875 44.9062C24 45 23.9062 45 23.7188 44.9062L4.21875 36.4688L14.5312 31.9688L22.5 35.3438C22.875 35.5312 23.5312 35.625 24 35.625C24.4688 35.625 24.9375 35.5312 25.4062 35.3438L33.375 31.9688L43.6875 36.4688ZM24.1875 32.5312C24 32.625 23.9062 32.625 23.7188 32.5312L4.21875 24L14.625 19.5L22.5 22.9688C22.875 23.1562 23.5312 23.25 24 23.25C24.4688 23.25 24.9375 23.1562 25.4062 22.9688L33.375 19.5L43.6875 24.0938L24.1875 32.5312Z" />
    </svg>
  ),
  profiles: props => (
    <svg
      {...props}
      viewBox="0 0 50 50"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon</title>
      <path d="M40.5938 9.1875L32.8125 1.40625C32.0625 0.65625 30.6562 0 29.625 0H4.5C1.96875 0 0 2.0625 0 4.5V37.5C0 40.0312 1.96875 42 4.5 42H37.5C39.9375 42 42 40.0312 42 37.5V12.375C42 11.3438 41.3438 9.9375 40.5938 9.1875ZM27 3V12H9V3H27ZM39 37.5C39 38.3438 38.25 39 37.5 39H4.5C3.65625 39 3 38.3438 3 37.5V4.5C3 3.75 3.65625 3 4.5 3H6V12.75C6 14.0625 6.9375 15 8.25 15H27.75C28.9688 15 30 14.0625 30 12.75V3.09375C30.1875 3.1875 30.4688 3.28125 30.6562 3.46875L38.5312 11.3438C38.7188 11.625 39 12.0938 39 12.375V37.5ZM21 18.75C16.4062 18.75 12.75 22.5 12.75 27C12.75 31.5938 16.4062 35.25 21 35.25C25.5 35.25 29.25 31.5938 29.25 27C29.25 22.5 25.5 18.75 21 18.75ZM21 32.25C18.0938 32.25 15.75 29.9062 15.75 27C15.75 24.1875 18.0938 21.75 21 21.75C23.8125 21.75 26.25 24.1875 26.25 27C26.25 29.9062 23.8125 32.25 21 32.25Z" />
    </svg>
  ),
  speed: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="tachometer-slow"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M288 288c-9.47 0-18.38 2.18-26.47 5.88l-97.09-119.94c-5.53-6.88-15.62-7.92-22.5-2.38-6.88 5.56-7.94 15.64-2.38 22.5l97.14 120C228.82 324.7 224 337.74 224 352c0 35.35 28.65 64 64 64s64-28.65 64-64-28.65-64-64-64zm0 96c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm0-352C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm221.5 416l-442.8.68C44 409.75 32 365.26 32 320 32 178.84 146.84 64 288 64s256 114.84 256 256c0 45.26-12 89.75-34.5 128z"
      ></path>
    </svg>
  ),
  torque: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="wrench"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M507.42 114.49c-2.34-9.47-9.66-16.98-19.06-19.61-9.47-2.61-19.65 0-26.65 6.98l-63.87 63.87-44.25-7.36-7.38-44.24 63.87-63.87c6.94-6.92 9.62-17.09 7-26.54-2.62-9.47-10.19-16.83-19.75-19.2C345.6-8.31 291.95 6.54 254.14 44.3c-37.84 37.87-52.21 92.52-38.62 144.7L22.19 382.29c-29.59 29.63-29.59 77.83 0 107.45C36.54 504.09 55.63 512 75.94 512s39.37-7.91 53.71-22.26l193.14-193.11c52.03 13.73 106.8-.72 144.89-38.82 37.81-37.81 52.68-91.39 39.74-143.32zm-62.36 120.7c-31.87 31.81-78.43 42.63-121.77 28.23l-9.38-3.14-206.88 206.84c-16.62 16.62-45.59 16.62-62.21 0-17.12-17.14-17.12-45.06 0-62.21l207.01-206.98-3.09-9.34c-14.31-43.45-3.56-90.06 28.03-121.67C299.48 44.2 329.44 32 360.56 32c6.87 0 13.81.59 20.72 1.81l-69.31 69.35 13.81 83.02L408.84 200l69.3-69.35c6.72 38.25-5.34 76.79-33.08 104.54zM80 416c-8.84 0-16 7.16-16 16s7.16 16 16 16 16-7.16 16-16-7.16-16-16-16z"
      ></path>
    </svg>
  ),
  gearratio: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="cog"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 0 0 0-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 0 0-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 0 0-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 0 0 0 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0 0 42.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0 0 42.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 0 1-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0 1 87.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"
      ></path>
    </svg>
  ),
  rotation: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="sync"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M492 8h-10c-6.627 0-12 5.373-12 12v110.627C426.929 57.261 347.224 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h10.016c6.353 0 11.646-4.949 11.977-11.293C48.157 132.216 141.097 42 256 42c82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-.301 248h-10.015c-6.352 0-11.647 4.949-11.977 11.293C463.841 380.158 370.546 470 256 470c-82.608 0-154.672-46.952-190.299-116H180c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H20c-6.627 0-12 5.373-12 12v160c0 6.627 5.373 12 12 12h10c6.627 0 12-5.373 12-12V381.373C85.071 454.739 164.777 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z"
      ></path>
    </svg>
  ),
  endo: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="tooth"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M443.94 96.25c-11.03-45.28-47.12-82.08-92.02-93.73-27.93-7.22-57.56 1.12-89.08 24.34-12.58 9.24-44.2 24.48-77.59.06l-.4-.28c-.06-.04-.13-.04-.19-.08C153.32 3.6 123.92-4.66 96.05 2.54c-44.87 11.64-80.99 48.44-92 93.72-9.61 39.48-1.97 78.7 21.5 110.44 20.51 27.73 32.06 61.84 36.29 107.34 3.62 38.69 9.25 89.61 20.93 140.31l7.81 33.97c3.22 13.95 15.4 23.69 29.65 23.69 14.03 0 26.18-9.55 29.53-23.16l34.5-138.44c4.56-18.33 20.9-31.14 39.74-31.14s35.18 12.81 39.74 31.16l34.5 138.41a30.348 30.348 0 0 0 29.53 23.17c14.25 0 26.43-9.73 29.65-23.69l7.81-33.97c11.69-50.72 17.31-101.64 20.93-140.31 4.28-45.56 15.81-79.66 36.31-107.34 23.47-31.76 31.1-70.98 21.47-110.45zm-47.21 91.41c-24.09 32.56-37.59 71.76-42.43 123.39-3.5 37.7-9 87.23-20.25 136.11l-6.34 27.59-32.9-132.06c-8.12-32.64-37.25-55.42-70.8-55.42s-62.68 22.78-70.8 55.41l-31.43 138.45-7.81-33.97c-11.25-48.86-16.75-98.39-20.25-136.09-4.81-51.56-18.31-90.78-42.45-123.41-17.72-23.97-23.45-53.75-16.12-83.84 8.26-33.98 35.32-61.58 68.94-70.3 24.3-6.27 48.55 9.13 62.12 19.14.07.05.15.08.21.13.03.02.04.05.07.07l80.33 56.25c10.7 7.43 20.15-.87 22.28-3.94 5.09-7.23 3.31-17.2-3.91-22.28L245.5 69.11c13.05-3.13 25.54-8.55 36.31-16.47 23.5-17.36 43.74-23.89 62.09-19.14 33.62 8.73 60.71 36.33 68.99 70.31 7.3 30.1 1.59 59.88-16.16 83.85z"
      ></path>
    </svg>
  ),
  oscillation: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="waveform-path"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M632 276h-61.72l-14.44-93.83A12 12 0 0 0 544.25 172c-4.69.11-10.91 4.11-12 9.66L498 353 459.88 86.3a12 12 0 0 0-23.76-.16L385 412.42l-53.06-402a12 12 0 0 0-23.82 0l-53.12 402-51.12-326.28a12 12 0 0 0-23.76.16L142 353l-34.25-171.36c-1.16-5.81-7.87-9.53-12.19-9.64a12 12 0 0 0-11.44 10.17L69.72 276H8a8 8 0 0 0-8 8v8a8 8 0 0 0 8 8h72a12 12 0 0 0 11.84-10.17l5.72-36.91 34.69 173.44c1.16 5.92 5.56 9.34 12.37 9.64a11.92 11.92 0 0 0 11.26-10.3L192.56 169l51.56 332.91c.5 3.08 2.63 10.14 11.6 10.14h.37a12.09 12.09 0 0 0 11.82-10.42L320 106.78l52.09 394.8a12 12 0 0 0 23.79.28L447.44 169l36.68 256.7a12 12 0 0 0 11.57 10.3c4.75-.25 10.81-3.39 12.09-9.66l34.66-173.42 5.72 36.91A11.88 11.88 0 0 0 560 300h72a8 8 0 0 0 8-8v-8a8 8 0 0 0-8-8z"
      ></path>
    </svg>
  ),
  water: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="tint"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 352 512"
    >
      <path
        fill="currentColor"
        d="M205.22 22.09C201.21 7.53 188.61 0 175.97 0c-12.35 0-24.74 7.2-29.19 22.09C100.01 179.85 0 222.72 0 333.91 0 432.35 78.72 512 176 512s176-79.65 176-178.09c0-111.75-99.79-153.34-146.78-311.82zM176 480c-79.4 0-144-65.54-144-146.09 0-48.36 23-81.32 54.84-126.94 29.18-41.81 65.34-93.63 89.18-170.91 23.83 77.52 60.06 129.31 89.3 171.08C297.06 252.52 320 285.3 320 333.91 320 414.46 255.4 480 176 480zm0-64c-44.12 0-80-35.89-80-80 0-8.84-7.16-16-16-16s-16 7.16-16 16c0 61.75 50.25 112 112 112 8.84 0 16-7.16 16-16s-7.16-16-16-16z"
      ></path>
    </svg>
  ),
  beepvolume: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="volume"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 512"
    >
      <path
        fill="currentColor"
        d="M342.91 193.57c-7.81-3.8-17.5-.48-21.34 7.5-3.81 7.97-.44 17.53 7.53 21.34C343.22 229.2 352 242.06 352 256c0 13.94-8.78 26.8-22.9 33.58-7.97 3.81-11.34 13.38-7.53 21.34 3.86 8.05 13.54 11.29 21.34 7.5C368.25 306.28 384 282.36 384 256s-15.75-50.29-41.09-62.43zM231.81 64c-5.91 0-11.92 2.18-16.78 7.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c4.87 4.87 10.88 7.05 16.78 7.05 12.33 0 24.19-9.52 24.19-24.02V88.02C256 73.51 244.13 64 231.81 64zM224 404.67L139.31 320H32V192h107.31L224 107.33v297.34zm256-148.68c0-66.12-34.02-126.62-88.81-157.87-7.69-4.38-17.59-1.78-22.04 5.89-4.45 7.66-1.77 17.44 5.96 21.86 44.77 25.55 72.61 75.4 72.61 130.12s-27.84 104.58-72.61 130.12c-7.72 4.42-10.4 14.2-5.96 21.86 4.3 7.38 14.06 10.44 22.04 5.89C445.98 382.62 480 322.12 480 255.99z"
      ></path>
    </svg>
  ),

  lightintensity: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="lightbulb-on"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z"
      ></path>
    </svg>
  ),
  towerBroadcast: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="tower-broadcast"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M119 63.1c-7 0-13.5 4.375-15.38 11.12C98.75 91.87 96 109.6 96 127.1s2.75 36.12 7.625 52.87c2 6.75 8.25 11.12 15.25 11.12c10.75 0 18.25-10.25 15.25-20.5C130.1 157.6 128 143.1 128 127.1s2.125-29.62 6.125-43.49C137.1 74.25 129.8 63.1 119 63.1zM40.5 0C34.38 0 28.63 3.5 25.1 9.125C9.5 45.37 0 85.62 0 127.1S9.5 210.6 25.1 246.9C28.63 252.5 34.38 255.1 40.5 255.1c11.75 0 19.25-12.12 14.38-22.75C40.25 201.1 32 165.5 32 127.1s8.25-73.12 22.88-105.2C59.75 12.12 52.25 0 40.5 0zM457.1 63.1c-10.75 0-18.25 10.25-15.25 20.5C445.9 98.37 448 112.9 448 127.1s-2.125 29.63-6.125 43.5c-3 10.25 4.375 20.5 15.12 20.5c7 0 13.5-4.375 15.38-11.12C477.3 164.1 480 146.4 480 127.1s-2.75-36.12-7.625-52.87C470.5 68.37 464.1 63.1 457.1 63.1zM549.1 9.125C547.4 3.5 541.6 0 535.5 0c-11.75 0-19.25 12.12-14.38 22.75C535.8 54.87 544 90.49 544 127.1S535.8 201.1 521.1 233.2c-4.875 10.62 2.625 22.75 14.38 22.75c6.125 0 11.88-3.5 14.5-9.125C566.5 210.6 576 170.4 576 127.1S566.5 45.37 549.1 9.125zM272.7 65.79C249.7 71.11 231 89.82 225.8 112.8C217.4 149.1 239.9 181.4 272 189.8v306.2c0 8.836 7.164 15.1 16 15.1s16-7.162 16-15.1V189.8c27.56-7.137 48-31.96 48-61.74C352 87.69 314.7 56.09 272.7 65.79zM288 160c-17.64 0-32-14.36-32-32s14.36-31.1 32-31.1s32 14.36 32 31.1S305.6 160 288 160z"
      ></path>
    </svg>
  ),
  noSignal: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="signal-slash"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M290.71 192.91A16 16 0 0 1 296 192h48a16 16 0 0 1 16 16v38.47zM88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm400-38.6V112a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v171.57zm128 98.93V16a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v366.5zM408 496a16 16 0 0 0 16 16h48a15.72 15.72 0 0 0 13-7.15l-77-59.5zm-128 0a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-87.75l-80-61.83zM168 288a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V309.32L204.41 288z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M636.63 480.54L617 505.81a15.77 15.77 0 0 1-14.93 5.77 14.47 14.47 0 0 1-2.07.42h-2.56a15.42 15.42 0 0 0-2.9-3.37L6.18 53.9a16 16 0 0 1-2.81-22.45L23 6.18a16 16 0 0 1 22.47-2.81L633.82 458.1a16 16 0 0 1 2.81 22.44z"
        ></path>
      </g>
    </svg>
  ),
  signal1: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="signal-1"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M216 288h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16zm128-96h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zM600 0h-48a16 16 0 0 0-16 16v480a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zM472 96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M104 400v96a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h48a16 16 0 0 1 16 16z"
        ></path>
      </g>
    </svg>
  ),
  signal2: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="signal-2"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M344 192h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zm128-96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16zM600 0h-48a16 16 0 0 0-16 16v480a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm128-96h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16z"
        ></path>
      </g>
    </svg>
  ),
  signal3: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="signal-3"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M472 96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16zM600 0h-48a16 16 0 0 0-16 16v480a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm256-192h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zm-128 96h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16z"
        ></path>
      </g>
    </svg>
  ),
  signal4: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="signal-4"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M616 16v480a16 16 0 0 1-16 16h-48a16 16 0 0 1-16-16V16a16 16 0 0 1 16-16h48a16 16 0 0 1 16 16z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M216 288h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16zM88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm256-192h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zm128-96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16z"
        ></path>
      </g>
    </svg>
  ),
  signal5: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="signal"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <g>
        <path fill="currentColor" d="" opacity="0.4"></path>
        <path
          fill="currentColor"
          d="M216 288h-48a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16zM88 384H40a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16zm256-192h-48a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V208a16 16 0 0 0-16-16zM600 0h-48a16 16 0 0 0-16 16v480a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16zM472 96h-48a16 16 0 0 0-16 16v384a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V112a16 16 0 0 0-16-16z"
        ></path>
      </g>
    </svg>
  ),
  billing: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="dollar-sign"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
    >
      <path
        fill="currentColor"
        d="M191.9 259.3L73.7 222.2C49.2 214.5 32 189 32 160.3 32 124.8 57.6 96 89 96h73.8c22.2 0 43.3 8.6 60.1 24.5 3.1 2.9 7.8 3.2 11 .3l11.9-10.8c3.4-3.1 3.6-8.4.4-11.6-22.8-22-52.7-34.5-83.3-34.5H144V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v56H89c-49.1 0-89 43.2-89 96.3 0 42.6 26.4 80.6 64.1 92.4l118.2 37.1c24.6 7.7 41.7 33.2 41.7 61.9 0 35.4-25.6 64.3-57 64.3H93.2c-22.2 0-43.3-8.6-60.1-24.5-3.1-2.9-7.8-3.2-11-.3L10.3 402c-3.3 3-3.6 8.4-.3 11.5 22.8 22 52.7 34.5 83.3 34.5H112v56c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-56h23c49.1 0 89-43.2 89-96.3 0-42.5-26.4-80.5-64.1-92.4z"
      ></path>
    </svg>
  ),
  cloudDownload: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="cloud-download"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M571.7 238.8c2.8-9.9 4.3-20.2 4.3-30.8 0-61.9-50.1-112-112-112-16.7 0-32.9 3.6-48 10.8-31.6-45-84.3-74.8-144-74.8-94.4 0-171.7 74.5-175.8 168.2C39.2 220.2 0 274.3 0 336c0 79.6 64.4 144 144 144h368c70.7 0 128-57.2 128-128 0-47-25.8-90.8-68.3-113.2zM512 448H144c-61.9 0-112-50.1-112-112 0-56.8 42.2-103.7 97-111-.7-5.6-1-11.3-1-17 0-79.5 64.5-144 144-144 60.3 0 111.9 37 133.4 89.6C420 137.9 440.8 128 464 128c44.2 0 80 35.8 80 80 0 18.5-6.3 35.6-16.9 49.2C573 264.4 608 304.1 608 352c0 53-43 96-96 96zM405.2 254.5c-4.7-4.7-12.3-4.7-17 0L320 322.7V172c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v150.7l-68.2-68.2c-4.7-4.7-12.3-4.7-17 0l-5.7 5.7c-4.7 4.7-4.7 12.3 0 17l98.3 98.3c4.7 4.7 12.3 4.7 17 0l98.3-98.3c4.7-4.7 4.7-12.3 0-17l-5.5-5.7z"
      ></path>
    </svg>
  ),
  hourglassEmpty: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="hourglass-empty"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M16 32h352C376.8 32 384 24.84 384 16S376.8 0 368 0h-352C7.156 0 0 7.156 0 16S7.156 32 16 32zM352 80C352 71.16 344.8 64 336 64S320 71.16 320 80c0 27.5-9.094 54.78-25.59 76.81l-67.2 89.59c-4.266 5.688-4.266 13.5 0 19.19l67.2 89.59C310.9 377.2 320 404.5 320 432c0 8.844 7.156 16 16 16s16-7.156 16-16c0-34.38-11.36-68.47-32-96L260 256L320 176C340.6 148.5 352 114.4 352 80zM32 432C32 440.8 39.16 448 48 448S64 440.8 64 432c0-27.5 9.094-54.78 25.59-76.81l67.2-89.59c4.266-5.688 4.266-13.5 0-19.19L89.59 156.8C73.09 134.8 64 107.5 64 80C64 71.16 56.84 64 48 64S32 71.16 32 80c0 34.38 11.36 68.47 32 96L124 256L64 336C43.36 363.5 32 397.6 32 432zM368 480h-352C7.156 480 0 487.2 0 496S7.156 512 16 512h352c8.844 0 16-7.156 16-16S376.8 480 368 480z"
      ></path>
    </svg>
  ),
  gaugeMed: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="gauge-simple-med"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M304 290.2V128c0-8.875-7.125-16-16-16S272 119.1 272 128v162.2C244.5 297.4 224 322.2 224 352c0 35.38 28.62 64 64 64s64-28.62 64-64C352 322.2 331.5 297.4 304 290.2zM288 384c-17.62 0-32-14.38-32-32s14.38-32 32-32s32 14.38 32 32S305.6 384 288 384zM288 32c-159 0-288 129-288 288c0 52.75 14.25 102.3 39 144.8c5.625 9.625 16.38 15.25 27.5 15.25h443c11.12 0 21.88-5.625 27.5-15.25C561.8 422.3 576 372.8 576 320C576 161 447 32 288 32zM509.5 448H66.75C44 409.1 32 365.2 32 320c0-141.1 114.9-256 256-256s256 114.9 256 256C544 365.2 532 409.8 509.5 448z"
      ></path>
    </svg>
  ),
  gaugeLow: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="gauge-simple-min"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M288 32c-159 0-288 129-288 288c0 52.75 14.25 102.3 39 144.8c5.625 9.625 16.38 15.25 27.5 15.25h443c11.12 0 21.88-5.625 27.5-15.25C561.8 422.3 576 372.8 576 320C576 161 447 32 288 32zM509.5 448H66.75C44 409.1 32 365.2 32 320c0-141.1 114.9-256 256-256s256 114.9 256 256C544 365.2 532 409.8 509.5 448zM288 288c-26.12 0-48.5 15.62-58.38 38L98.62 304.2C90.12 302.8 81.62 308.6 80.25 317.4c-1.5 8.75 4.375 17 13.13 18.38l131.2 21.87C227.5 390.2 254.6 416 288 416c35.38 0 64-28.62 64-64S323.4 288 288 288zM288 384c-17.62 0-32-14.38-32-32s14.38-31.1 32-31.1S320 334.4 320 352S305.6 384 288 384z"
      ></path>
    </svg>
  ),
  thermometer: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="temperature-three-quarters"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M208 384c0 26.5-21.5 48-48 48S112 410.5 112 384c0-20.88 13.37-38.63 31.1-45.25V144c0-8.875 7.124-16 15.1-16s16 7.125 16 16v194.8C194.6 345.4 208 363.1 208 384zM256 299.4C275.9 321.9 288 351.5 288 384c0 70.75-57.25 128-127.1 128L159.1 512c-70.37-.5-127.5-58.38-127.1-128.6c.125-32.25 12.25-61.62 31.1-84V96c0-53 42.1-95.1 95.1-95.1S256 43 256 96V299.4zM256 384c0-39.88-22.87-62.13-31.1-72.5V96c0-35.25-28.75-64-63.1-64S96 60.75 96 96v215.5c-9.5 10.62-31.75 32.5-31.1 72C63.75 436.2 106.6 479.6 159.4 480h.625C212.9 480 256 436.9 256 384z"
      ></path>
    </svg>
  ),
  oilCan: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="oil-can-drip"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M637.9 168c-3.531-6.094-10.73-9.181-17.36-7.65l-169.6 38.56L408.1 172.2C395.3 164.2 380.7 160 365.7 160H271.1V96h63.1c8.838 0 16-7.164 16-16c0-8.838-7.162-16-16-16H175.1C167.2 64 159.1 71.16 159.1 80c0 8.836 7.163 16 15.1 16h63.1v64H47.1C21.53 160 0 181.5 0 208v60.22c0 18.3 10.16 34.75 26.53 42.94l69.47 34.58L95.1 368c0 26.47 21.53 48 48 48h258c13.31 0 26.12-5.578 35.19-15.33l198.5-213.8C640.5 181.7 641.4 174.1 637.9 168zM95.1 208l-.0007 102.1L40.84 282.5C35.37 279.8 31.1 274.3 31.1 268.2V208c0-8.828 7.187-16 15.1-16h50.95C97.15 197 95.1 202.4 95.1 208zM413.8 378.9C410.7 382.1 406.5 384 402 384H143.1c-8.812 0-15.1-7.172-15.1-16v-160c0-8.828 7.187-16 15.1-16h221.7c8.1 0 17.78 2.516 25.44 7.297l48.44 30.27c3.594 2.25 7.937 3 11.1 2.047l125.5-28.52L413.8 378.9zM598.8 299.4c-1.541-2.627-4.192-3.941-6.843-3.941c-2.648 0-5.298 1.314-6.839 3.941c-13.26 22.59-41.16 72.81-41.16 95.32C543.1 424.1 565.4 448 591.1 448c26.61 0 47.1-23.91 47.1-53.28C639.1 372.2 612.1 321.1 598.8 299.4zM591.1 416c-8.672 0-15.1-9.746-16-21.18c.209-4.861 4.861-18.78 16-41.18c11.14 22.39 15.79 36.29 15.1 41.07C607.1 406.3 600.7 416 591.1 416z"
      ></path>
    </svg>
  ),
  dropletPercent: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="droplet-percent"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M186.6 51.36c-3.631-12.77-15.08-19.36-26.57-19.36c-11.25 0-22.53 6.32-26.55 19.36C90.94 189.3 0 226.9 0 324.1c0 86.08 71.56 155.9 160 155.9s160-69.78 160-155.9C320 226.4 229.3 190 186.6 51.36zM160 448c-70.58 0-128-55.56-128-123.9c0-40.71 20.56-69.07 49.01-108.3C106.7 180.4 138.3 136.9 159.1 73.17c21.85 64.09 53.46 107.5 79.16 142.9C267.5 255 288 283.2 288 324.1C288 392.4 230.6 448 160 448zM128 264C128 250.7 117.3 240 104 240S80 250.7 80 264s10.69 23.1 24 23.1S128 277.3 128 264zM219.3 260.7c-6.25-6.25-16.38-6.25-22.62 0l-96 95.1c-6.25 6.25-6.25 16.37 0 22.62C103.8 382.4 107.9 383.1 112 383.1s8.188-1.562 11.31-4.688l96-95.1C225.6 277.1 225.6 266.9 219.3 260.7zM216 351.1c-13.31 0-24 10.69-24 23.1s10.69 23.1 24 23.1s24-10.69 24-23.1S229.3 351.1 216 351.1z"
      ></path>
    </svg>
  ),
  bolt: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="bolt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M381.1 244.8c-4.687-12.48-16.62-20.75-29.97-20.75l-124.2-.0114l88.84-177.7c6.843-13.69 3.031-30.28-9.062-39.64C301.8 2.209 294.9 0 287.1 0c-7.541 0-15.06 2.666-21.05 7.926L10.95 231.9c-10.03 8.782-13.59 22.86-8.906 35.33C6.728 279.7 18.66 288 32.01 288l124.2-.0089l-88.84 177.7c-6.843 13.69-3.031 30.28 9.062 39.64C82.22 509.8 89.13 512 96 512c7.531 0 15.06-2.656 21.06-7.922l255.1-223.1C383.1 271.3 386.6 257.2 381.1 244.8zM96 479.1l88.84-177.7c4.961-9.92 4.429-21.7-1.4-31.14c-5.83-9.434-16.13-15.18-27.22-15.18L32.02 255.1l255.1-223.1c.0039-.002-.0176 0 0 0c0 0-.002 0 0 0l-88.82 177.7c-4.961 9.918-4.429 21.7 1.4 31.13c5.83 9.436 16.13 15.18 27.22 15.18l124.2-.0059L96 479.1z"
      ></path>
    </svg>
  ),
  meterBolt: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="meter-bolt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M336 144v64C336 216.8 343.2 224 352 224s16-7.156 16-16v-64C368 135.2 360.8 128 352 128S336 135.2 336 144zM240 144v64C240 216.8 247.2 224 256 224s16-7.156 16-16v-64C272 135.2 264.8 128 256 128S240 135.2 240 144zM144 144v64C144 216.8 151.2 224 160 224s16-7.156 16-16v-64C176 135.2 168.8 128 160 128S144 135.2 144 144zM638.6 361.4c-2.594-5.719-8.281-9.375-14.56-9.375h-92.75l88.78-101.5c5.844-6.656 5.156-16.75-1.5-22.56c-6.625-5.781-16.72-5.188-22.56 1.5l-112 128c-4.156 4.719-5.125 11.44-2.531 17.16s8.281 9.375 14.56 9.375h92.75l-88.78 101.5c-5.844 6.656-5.156 16.75 1.5 22.56c3.031 2.656 6.781 3.969 10.53 3.969c4.438 0 8.875-1.844 12.03-5.469l112-128C640.2 373.8 641.2 367.1 638.6 361.4zM480 256c0 8.844 7.156 16 16 16S512 264.8 512 256c0-141.2-114.8-256-256-256S0 114.8 0 256s114.8 256 256 256c72.72 0 142.3-31.09 190.8-85.34c5.891-6.562 5.328-16.69-1.25-22.59c-6.625-5.875-16.7-5.312-22.59 1.281C380.5 452.8 319.6 480 256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224S480 132.5 480 256z"
      ></path>
    </svg>
  ),
  dropletSlash: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="droplet-slash"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill="currentColor"
        d="M633.9 483.4l-608-480c-6.938-5.453-17-4.25-22.48 2.641c-5.469 6.938-4.281 17 2.641 22.48l608 480C617 510.9 620.5 512 623.1 512c4.734 0 9.422-2.094 12.58-6.078C642 498.1 640.8 488.9 633.9 483.4zM320 448c8.875 0 16-7.125 16-16S328.9 416 320 416c-44.13 0-80-35.88-80-80C240 327.1 232.9 320 224 320s-16 7.125-16 16C208 397.8 258.3 448 320 448zM320 36c23.88 77.63 60.13 129.4 89.38 171.1c18.45 26.48 33.79 48.69 43.52 72.23l41.73 32.95c-11.85-96.24-101.5-142.3-145.4-290.2C345.3 7.503 332.6 0 319.1 0c-12.38 0-24.74 7.253-29.24 22.13c-11.3 38.14-25.74 69.47-41.11 96.76l25.35 20.01C291.5 110.1 307.5 76.66 320 36zM320 480c-79.38 0-144-65.5-144-146.1c0-33.46 11.16-59.55 28.49-87.53L179.2 226.4c-20.59 32.68-35.17 65.14-35.17 107.5C144 432.4 222.8 512 320 512c55.39 0 104.7-25.88 136.1-66.32l-25.65-20.25C404.9 458.4 365.1 480 320 480z"
      ></path>
    </svg>
  ),
  droplet: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="droplet"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M192 416c-44.13 0-80-35.87-80-79.1c0-8.875-7.125-16-16-16s-16 7.125-16 16c0 61.75 50.25 111.1 112 111.1c8.875 0 16-7.125 16-16S200.9 416 192 416zM221.3 22.13C217.3 7.501 204.6 0 191.1 0C179.6 0 167.3 7.251 162.8 22.13C116 179.9 16 222.8 16 333.9c0 98.5 78.75 178.1 176 178.1s176-79.62 176-178.1C368 222.1 268.3 180.6 221.3 22.13zM192 480c-79.38 0-144-65.5-144-146.1C48 285.5 71 252.6 102.9 207C132 165.1 168.1 113.4 192 36c23.88 77.63 60.13 129.4 89.38 171.1C313 252.5 336 285.3 336 333.9C336 414.5 271.4 480 192 480z"
      ></path>
    </svg>
  ),
  wrench: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="wrench-simple"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M384 179.4c0 69.16-36.54 132-96.04 166.3V496c0 8.844-7.158 16-16.01 16s-16.01-7.156-16.01-16v-159.8c0-6 3.375-11.5 8.721-14.25c53.87-27.5 87.32-82.13 87.32-142.5c0-57.84-30.73-109.8-80.03-138v139.9c0 5.035-2.377 9.785-6.408 12.82l-64.03 48C198.7 244.2 195.4 245.3 192 245.3c-3.352-.0156-6.699-1.055-9.523-3.164l-64.03-48C114.4 191.1 112 186.3 112 181.3V41.36C62.74 69.62 32.01 121.6 32.01 179.4c0 60.41 33.45 115 87.32 142.5c5.346 2.75 8.721 8.25 8.721 14.25V496c0 8.844-7.158 16-16.01 16s-16.01-7.156-16.01-16v-150.3C36.54 311.4 0 248.6 0 179.4c0-79.21 47.96-149.2 122.2-178.3c4.971-1.875 10.47-1.312 14.85 1.688C141.4 5.738 144.1 10.71 144.1 15.99v157.3L192 209.3l47.94-35.95V15.99c0-5.281 2.627-10.25 7.004-13.22c4.377-3 9.879-3.562 14.85-1.688C336 30.18 384 100.2 384 179.4z"
      ></path>
    </svg>
  ),
  timer: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="timer"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M256 16C247.2 16 240 23.16 240 32v80C240 120.8 247.2 128 256 128s16-7.156 16-16V48.59C379.3 56.81 464 146.7 464 256c0 114.7-93.31 208-208 208S48 370.7 48 256c0-48.84 17.28-96.34 48.66-133.7c5.688-6.75 4.812-16.84-1.969-22.53S77.84 94.94 72.16 101.7C35.94 144.8 16 199.6 16 256c0 132.3 107.7 240 239.1 240S496 388.3 496 256S388.3 16 256 16zM244.7 267.3C247.8 270.4 251.9 272 256 272s8.188-1.562 11.31-4.688c6.25-6.25 6.25-16.38 0-22.62l-80-80c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L244.7 267.3z"
      ></path>
    </svg>
  ),
  percent: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="percent"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M379.3 68.69c-6.25-6.25-16.38-6.25-22.62 0l-352 352c-6.25 6.25-6.25 16.38 0 22.62C7.813 446.4 11.91 448 16 448s8.188-1.562 11.31-4.688l352-352C385.6 85.06 385.6 74.94 379.3 68.69zM64 192c35.35 0 64-28.65 64-64S99.35 64.01 64 64.01c-35.35 0-64 28.65-64 63.1S28.65 192 64 192zM64 96c17.64 0 32 14.36 32 32S81.64 160 64 160S32 145.6 32 128S46.36 96 64 96zM320 320c-35.35 0-64 28.65-64 64s28.65 64 64 64c35.35 0 64-28.65 64-64S355.3 320 320 320zM320 416c-17.64 0-32-14.36-32-32s14.36-32 32-32s32 14.36 32 32S337.6 416 320 416z"
      ></path>
    </svg>
  ),
  arrowUpRightFromSquare: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="arrow-up-right-from-square"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M400 288C391.2 288 384 295.2 384 304V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V160c0-17.67 14.33-32 32-32h112C184.8 128 192 120.8 192 112S184.8 96 176 96L64 96c-35.35 0-64 28.65-64 64V448c0 35.35 28.65 64 64 64h288c35.35 0 64-28.65 64-64V304C416 295.2 408.8 288 400 288zM496 0h-160C327.2 0 320 7.156 320 16S327.2 32 336 32h121.4L180.7 308.7c-6.25 6.25-6.25 16.38 0 22.62C183.8 334.4 187.9 336 192 336s8.188-1.562 11.31-4.688L480 54.63V176C480 184.8 487.2 192 496 192S512 184.8 512 176v-160C512 7.156 504.8 0 496 0z"
      ></path>
    </svg>
  ),
  clock: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="clock"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"
      ></path>
    </svg>
  ),
  calendar: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="calendar"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M112 0c8.8 0 16 7.2 16 16V64H320V16c0-8.8 7.2-16 16-16s16 7.2 16 16V64h32c35.3 0 64 28.7 64 64v32 32V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 160 128C0 92.7 28.7 64 64 64H96V16c0-8.8 7.2-16 16-16zM416 192H32V448c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V192zM384 96H64c-17.7 0-32 14.3-32 32v32H416V128c0-17.7-14.3-32-32-32z" />
    </svg>
  ),
  arrowspin: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="calendar"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M257.1 79.5c-11.3 0-22.4 1.1-33.1 3.1V50.2c10.8-1.7 21.9-2.6 33.1-2.6c53.8 0 102.8 20.4 139.7 53.9l27.1-27.1c15.1-15.1 41-4.4 41 17l0 86.1c0 8.8-7.2 16-16 16l-86.1 0c-21.4 0-32.1-25.9-17-41l28.3-28.3c-31.1-27.7-72.1-44.6-117-44.6zM124.2 140.2c-26.8 30.9-43.1 71.2-43.1 115.3c0 11.1 1 21.9 3 32.5H51.7c-1.7-10.6-2.5-21.4-2.5-32.5c0-53 19.8-101.3 52.4-138L74.3 90.3c-15.1-15.1-4.4-41 17-41l86.1 0c8.8 0 16 7.2 16 16v86.1c0 21.4-25.9 32.1-41 17l-28.2-28.2zM433.1 255.5c0-10.8-1-21.3-2.8-31.5h32.4c1.6 10.3 2.4 20.8 2.4 31.5c0 53.8-20.4 102.8-53.9 139.7l28.7 28.7c15.1 15.1 4.4 41-17 41H336.9c-8.8 0-16-7.2-16-16V362.8c0-21.4 25.8-32.1 41-17l26.7 26.7c27.7-31.1 44.6-72.1 44.6-117zM288 428.8v32.4c-10.1 1.5-20.4 2.3-30.9 2.3c-53 0-101.3-19.8-138-52.4L90.3 439.9c-15.1 15.1-41 4.4-41-17l0-86.1c0-8.8 7.2-16 16-16l86.1 0c21.4 0 32.1 25.9 17 41l-26.6 26.6c30.9 26.8 71.2 43.1 115.3 43.1c10.5 0 20.8-.9 30.9-2.7zm115.6 4.1l-50.7-50.7v50.7h50.7zM161.4 132.1V81.4l-50.7 0 50.7 50.7zm-80 271.5l50.7-50.7H81.4l0 50.7zM382.2 161.4l50.7 0V110.6l-50.7 50.7z" />
    </svg>
  ),
  dryerheat: props => (
    <svg
      {...props}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="calendar"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M64 32C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H64zM0 64C0 28.7 28.7 0 64 0H384c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm104 8a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM336 304a112 112 0 1 0 -224 0 112 112 0 1 0 224 0zM80 304a144 144 0 1 1 288 0A144 144 0 1 1 80 304zm115.3-75.3l4.7 4.7c10.2 10.2 16 24.1 16 38.6s-5.8 28.4-16 38.6l-9.4 9.4c-4.2 4.2-6.6 10-6.6 16s2.4 11.8 6.6 16l4.7 4.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-4.7-4.7c-10.2-10.2-16-24.1-16-38.6s5.8-28.4 16-38.6l9.4-9.4c4.2-4.2 6.6-10 6.6-16s-2.4-11.8-6.6-16l-4.7-4.7c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0zm80 0l4.7 4.7c10.2 10.2 16 24.1 16 38.6s-5.8 28.4-16 38.6l-9.4 9.4c-4.2 4.2-6.6 10-6.6 16s2.4 11.8 6.6 16l4.7 4.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-4.7-4.7c-10.2-10.2-16-24.1-16-38.6s5.8-28.4 16-38.6l9.4-9.4c4.2-4.2 6.6-10 6.6-16s-2.4-11.8-6.6-16l-4.7-4.7c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0z" />
    </svg>
  ),
};

interface IProps {
  name: string;
  color?: string;
  size?: number;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

export default class Icon extends Component<IProps> {
  render() {
    const { name, color, size, onClick } = this.props;
    return (
      <i
        onClick={onClick}
        className={`ICON fa fa-${name} ${this.props.className || ''}`}
        style={{ color, fontSize: size && `${size}px` }}
      />
    );
  }
}

export class SvgIcon extends Component<IconProps> {
  render() {
    const icon = ICONS[this.props.type || ''];
    const props = {
      ...this.props,
    };
    if (icon) {
      delete props.type;
      delete props.theme;
    }
    return <AntIcon {...props} component={icon} />;
  }
}

export const Loading = (props: IconProps) => {
  return <AntIcon type="loading" style={{ fontSize: get(props, 'size', 50) }} {...props} />
}