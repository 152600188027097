/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, ChangeEvent, useState, useEffect, Fragment } from 'react';
import SharedStyles from '../../_shared/styles';
import { Switch, Input, InputNumber } from 'antd';
import { SelectValue } from 'antd/lib/select';
import Label from '../../_shared/label';
import { Button } from '../../_shared/button';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import FormTitle from '../../_shared/form-title';
import styles from './spoof-a-publish.styles';
import { EQ_TYPE, eqTypeFromString } from '../../_shared/interfaces/equipment';
import { Publish } from '../../_shared/interfaces/publish';
import { Select } from '../../_shared/select';
import { guid } from '../../utils';
import { DzConfigDataForm } from './publish-forms/dz-config/config-form-container.component';
import { DzMaintDataForm } from './publish-forms/dz-maint/maint-form-container.component';
import { DzTestResultsDataForm } from './publish-forms/dz-testresults/testresults-form-container.component';
import { DzSensorInstantDataForm } from './publish-forms/dz-sensor-instant/sensor-instant-form.component';
import { DzSensorStatisticsDataForm } from './publish-forms/dz-sensor-statistics/sensor-statistics-form.component';
import { DzAlertDataForm } from './publish-forms/dz-alert/alert-form-container.component';
import { Model } from '../../_shared/interfaces/model';
import { getModels } from '../../_shared/services/manage-models.service';
import { createPublish } from '../../_shared/services/publish.service';
import {
  ConfigPublishData,
  AlertPublishData,
  SensorStatisticsPublishData,
  SensorInstantPublishData,
  MaintenancePublishData,
  TestResultsPublishData,
} from '../../_shared/interfaces/publishData';

interface IProps extends RouteComponentProps {
  appState: AppState;
}

type Payload =
  | ConfigPublishData
  | MaintenancePublishData
  | TestResultsPublishData
  | SensorStatisticsPublishData
  | SensorInstantPublishData
  | AlertPublishData;

enum SpoofEvent {
  config = 'dz_config',
  maintenance = 'dz_maint',
  sensorStats = 'dz_sensor_statistics',
  sensorInstant = 'dz_sensor_instant',
  testResults = 'dz_testresults',
  alert = 'dz_alert',
}

interface ISpoofPublish {
  isFake: boolean;
  event: string;
  public: boolean; //this should always be false in the case of false publishes!!
  coreid: string;
  id: string; //can be a random GUID, just has to exist idk
  published_at: string; //just set to ISO now when publishing
  userId: string; //just set to 0000000000
  fw_version: number; //just set to 999
  data: Payload;
}

class SpoofPublish implements ISpoofPublish, Publish {
  event: string;
  coreid: string;
  data: Payload;

  id = guid();
  public = false;
  published_at = new Date(Date.now()).toISOString();
  userId = '0000000000';
  fw_version = 999;
  isFake = true;

  constructor(event: string, deviceId: string, data: Payload) {
    this.event = event;
    this.coreid = deviceId;
    this.data = data;
  }
}

const SpoofAPublishComponent: React.FC<IProps> = ({}): JSX.Element => {
  //STATE
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isPublishFormValid, setIsPublishFormValid] = useState<boolean>(false);
  const [publishData, setPublishData] = useState<Payload | undefined>(
    undefined
  );
  const [isFake, setIsFake] = useState<boolean>(true);
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  const [equipmentType, setEquipmentType] = useState<EQ_TYPE>(EQ_TYPE.Unknown);
  const [publishType, setPublishType] = useState<string | undefined>(undefined);
  const [models, setModels] = useState<Model[] | undefined>(undefined);
  const [isLoadingModels, setIsLoadingModels] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<Model | undefined>(
    undefined
  );

  const isMetaDataFormValid = (): boolean => {
    return (
      isFake &&
      deviceId !== undefined &&
      equipmentType !== EQ_TYPE.Unknown &&
      publishType !== undefined &&
      selectedModel !== undefined &&
      publishData !== undefined
    );
  };

  const clearForm = () => {
    setPublishData(undefined);
    setIsFake(true);
    setDeviceId(undefined);
    setEquipmentType(EQ_TYPE.Unknown);
    setPublishType(undefined);
    setSelectedModel(undefined);
    setIsPublishFormValid(false);
  };

  useEffect(() => {
    setSelectedModel(undefined);
  }, [equipmentType]); //when equipment type changes, reset selected model

  //HANDLERS
  const toggleIsFake = () => {
    setIsFake(!isFake);
  };

  const handleInputChanged = (
    event: string | SelectValue,
    setter: (newVal: string) => void
  ) => {
    if (typeof event === typeof 'a string!') {
      setter(event as string);
    }
  };

  const deviceIdInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChanged(event.target.value, setDeviceId);
  };

  const equipmentTypeChanged = (event: SelectValue) => {
    handleInputChanged(event, s => setEquipmentType(eqTypeFromString(s)));
  };

  const modelChanged = (event: SelectValue) => {
    handleInputChanged(event, setModelFromIdString);
  };

  function setModelFromIdString(id: string) {
    if (!!!id || !!!models) return;

    const model = models.find(m => m.id === id);
    setSelectedModel(model);
  }

  const publishTypeChanged = (event: SelectValue) => {
    handleInputChanged(event, setPublishType);
  };

  async function loadModels() {
    setIsLoadingModels(true);
    const models = await getModels();
    setModels(models);
    setIsLoadingModels(false);
  }

  const submit = async () => {
    if (!isMetaDataFormValid() || !isPublishFormValid) return;
    //redundnant..type narrowing missed
    if (
      deviceId === undefined ||
      publishData === undefined ||
      publishType === undefined
    )
      return;

    setIsPublishing(true);
    await createPublish(new SpoofPublish(publishType, deviceId, publishData));
    setIsPublishing(false);
  };

  //UI GENERATIVE
  const generateEquipmentTypeSelectOptions = (): JSX.Element[] => {
    let content = [];
    for (const eType in EQ_TYPE) {
      //this new variable is us making sure we are getting
      //the TYPE value of the item, not the related string value.
      const enumValue = EQ_TYPE[eType];

      //curently only allowing publish spoofing for non-handpieces
      //(and obviously no reason to include `.Unknown`, here.
      if (enumValue === EQ_TYPE.Handpiece || enumValue === EQ_TYPE.Unknown) {
        continue;
      }

      content.push(
        <Select.Option key={eType} value={eType}>
          {eType}
        </Select.Option>
      );
    }
    return content;
  };

  const generateModelSelectOptions = (): JSX.Element[] => {
    if (isLoadingModels)
      return [<Select.Option key="666">loading...</Select.Option>];

    let content = [] as JSX.Element[];

    if (isLoadingModels == false && models === undefined) {
      loadModels();
    }

    if (models) {
      models
        .filter(m => eqTypeFromString(m.type) === equipmentType)
        .forEach(m => {
          content.push(
            <Select.Option
              key={m.id}
              value={m.id}
            >{`${m.id} - ${m.name}`}</Select.Option>
          );
        });
    } else {
      return [<Select.Option key="666">no models...</Select.Option>];
    }

    return content;
  };

  const generatePublishTypeSelectOptions = (): JSX.Element[] => {
    let content = [];
    for (const fPub in SpoofEvent) {
      const stringVal = SpoofEvent[fPub];
      content.push(
        <Select.Option key={fPub} value={stringVal}>
          {stringVal}
        </Select.Option>
      );
    }
    return content;
  };

  const renderPublishSpecificForm = (): JSX.Element => {
    if (!!!selectedModel) return <Fragment></Fragment>;
    const eType = eqTypeFromString(equipmentType);

    switch (publishType) {
      case SpoofEvent.config:
        return (
          <DzConfigDataForm
            equipmentType={eType}
            model={selectedModel}
            validChanged={setIsPublishFormValid}
            payloadChanged={setPublishData}
          />
        );

      case SpoofEvent.maintenance:
        return (
          <DzMaintDataForm
            equipmentType={eType}
            model={selectedModel}
            validChanged={setIsPublishFormValid}
            payloadChanged={setPublishData}
          />
        );

      case SpoofEvent.sensorStats:
        return (
          <DzSensorStatisticsDataForm
            equipmentType={eType}
            validChanged={setIsPublishFormValid}
            payloadChanged={setPublishData}
          />
        );

      case SpoofEvent.sensorInstant:
        return (
          <DzSensorInstantDataForm
            equipmentType={eType}
            validChanged={setIsPublishFormValid}
            payloadChanged={setPublishData}
          />
        );

      case SpoofEvent.testResults:
        return (
          <DzTestResultsDataForm
            equipmentType={eType}
            model={selectedModel}
            validChanged={setIsPublishFormValid}
            payloadChanged={setPublishData}
          />
        );

      case SpoofEvent.alert:
        return (
          <DzAlertDataForm
            validChanged={setIsPublishFormValid}
            payloadChanged={setPublishData}
          />
        );
    }

    return <h1>Not Implemented!</h1>;
  };

  //UI DECLARATIVE
  return (
    <div>
      <FormTitle size={'18px'}>{'Spoof-a-Publish'}</FormTitle>

      <div css={css(styles.inputGroup)}>
        <p>Is Fake:</p>
        <Switch onChange={toggleIsFake} checked={isFake} />
      </div>

      <div css={css(styles.inputGroup)}>
        <p>Device Id:</p>
        <Input value={deviceId} onChange={deviceIdInputChanged} />
      </div>

      <div css={css(styles.inputGroup)}>
        <p>Equipment Type:</p>
        <Select
          css={css(styles.selectInput)}
          mode="single"
          placeholder={'Equipment Type'}
          onChange={equipmentTypeChanged}
        >
          {generateEquipmentTypeSelectOptions()}
        </Select>
      </div>

      <div css={css(styles.inputGroup)}>
        <p>Model:</p>
        <Select
          css={css(styles.selectInput)}
          mode="single"
          placeholder={'Model'}
          disabled={isLoadingModels}
          value={!!selectedModel ? selectedModel.id : '--'}
          onChange={modelChanged}
        >
          {generateModelSelectOptions()}
        </Select>
      </div>

      <div css={css(styles.inputGroup)}>
        <p>Publish Type:</p>
        <Select
          css={css(styles.selectInput)}
          mode="single"
          placeholder={'dz_{event}'}
          onChange={publishTypeChanged}
        >
          {generatePublishTypeSelectOptions()}
        </Select>
      </div>

      {renderPublishSpecificForm()}

      <Button
        onClick={submit}
        title={'DO-A-SPOOF'}
        disabled={!isMetaDataFormValid() || !isPublishFormValid || isPublishing}
      />
      <Button onClick={clearForm} title={'Clear'} disabled={isPublishing} />
    </div>
  );
};

export default withRouter(SpoofAPublishComponent);

//UI GENERATIVE
export function makeInputGroup(
  name: string,
  control: JSX.Element
): JSX.Element {
  return (
    <div css={css(styles.inputGroup)}>
      <p>{name}:</p>
      {control}
    </div>
  );
}

export function makeSwitch(
  name: string,
  on: boolean,
  sHandler: (on: boolean) => void
): JSX.Element {
  return makeInputGroup(
    name,
    <Switch key={name} checked={on} onChange={e => sHandler(!on)} />
  );
}

export function makeInput(
  name: string,
  value: string | undefined,
  handler: (str: string | undefined) => void
): JSX.Element {
  return makeInputGroup(
    name,
    <Input value={value} onChange={e => handler(e.target.value)} />
  );
}

export function makeNumInput(
  name: string,
  value: number | undefined,
  handler: (n: number | undefined) => void
): JSX.Element {
  return makeInputGroup(
    name,
    <InputNumber value={value} onChange={e => handler(e)} />
  );
}
