import React, { Component } from 'react';
import './input-label.styles.css';
import { Input, DatePicker } from 'antd';

interface IProps {
  label: string;
  // tslint:disable-next-line: no-any
  onChange?: (e: any) => void;
  // tslint:disable-next-line: no-any
  onChangeText?: (e: any) => void;
  // tslint:disable-next-line: no-any
  value?: any;
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
}

export class InputLabel extends Component<IProps> {
  // tslint:disable-next-line: no-any
  onChange = (e: any) => {
    this.props.onChange && this.props.onChange(e);
    this.props.onChangeText && this.props.onChangeText(e.target.value);
  };
  getInput = () => {
    const { type } = this.props;
    if (type) {
      if (type === 'date') {
        return DatePicker;
      }
    }
    return Input;
  };
  render() {
    const { label, value } = this.props;

    const INP = this.getInput();

    return (
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">{label}</label>
        <div className="ant-col ant-form-item-control-wrapper">
          <INP onChange={this.onChange} value={value} />
        </div>
      </div>
    );
  }
}
