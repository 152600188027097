import { guid } from '../../utils';

// orgType = {
//   0: 'Service',
//   1: 'Dental'
// }

export interface Address {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface Org {
  [key: string]: string | number | undefined | Address;
  id: string;
  sk: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  orgType?: number;
  isActive?: number;
  name: string;
  code?: string;
  emailAddress?: string;
  phone?: string;
  address?: Address;
  serviceOrgId?: string;
  accountNumber?: string;
  doType?: string;
}

export class _Org {
  constructor(props?: Org) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined | Address;
  id = guid();
  createdAt = new Date().toISOString();
  createdBy = '';
  updatedAt = new Date().toISOString();
  updatedBy = '';
  orgType = 0;
  isActive = 1;
  name = '';
  code = '';
  emailAddress = '';
  phone = '';
  address = {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  };
  serviceOrgId = '';
  accountNumber = '';
  doType = '';
}
