import colors from '../../_shared/colors';

export default {
  rowMargin: `
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  `,
  hr: `
    width: 100%;
    height: 2px;
    background-color: ${colors.hrBorder};
  `,
  rowMargin2: `
    margin-bottom: 20px;
  `,
};
