/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import SharedStyles from '../../_shared/styles';
import colors from '../../_shared/colors';
import styles from './table-header.styles';
import Link from '../../_shared/link';
import Icon from '../../_shared/icon';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { filterEquipment, NONE } from '../dashboard.actions';
import { DashEquipFilters } from '../dashboard.reducer';
import { get } from 'lodash';
import { Model } from '../../_shared/interfaces/model';
import { Org } from '../../_shared/interfaces/org';
import { Select, Icon as AIcon } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
  userHasRole,
  getMyOrg,
  sortByLabel,
  isPB,
  getActiveModels,
} from '../../utils';
import { StatesSelect } from '../../_shared/lib/states';
import { Button, IProps as BtnProps } from '../../_shared/button';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { REG_KEY } from '../../registration';

interface IOpt {
  label: string;
  value: string | number;
}

interface Filter {
  opts: () => IOpt[];
  placeholder?: string;
  value?: string | string[];
  var: string;
  canDisplay?: () => boolean;
  style?: string;
  single?: boolean;
  type?: string;
  onClick?: () => void;
  title?: string;
  icon?: string;
}

interface IProps extends RouteComponentProps {
  selectedView: number;
  onSelectView: (index: number) => void;
  views: { icon: string }[];
  filterEquipment: (filters?: DashEquipFilters) => void;
  sState: AppState;
  equipmentCount: string;
}

interface IState {
  showFilters: boolean;
  showMoreFilters: boolean;
  filters_1: Filter[];
  filters_2: Filter[];
}

export class _TableHeader extends Component<IProps> {
  static defaultProps: IProps;
  state: IState = {
    showFilters: false,
    showMoreFilters: false,
    filters_1: [
      {
        canDisplay: () => !userHasRole([5, 6, 7], this.props.sState),
        onClick: () => this.goToReg(),
        title: 'Register Equipment',
        type: 'button',
        var: 'button',
        opts: () => [],
        icon: 'plus',
      },
      {
        opts: () =>
          getActiveModels()
            .map((m: Model) => ({
              label: m.id,
              value: m.id,
            }))
            .sort(sortByLabel),
        placeholder: 'Model',
        var: 'model',
        value: 'dash.equipmentFilters.model',
      },
      {
        canDisplay: () => userHasRole([0, 1, 7], this.props.sState),
        opts: () => [
          { label: 'No Service Org', value: NONE },
          ...get(this.props.sState, 'dash.orgs', [])
            .filter((o: Org) => o.orgType === 0)
            .map((m: Org) => ({ label: m.name, value: m.id }))
            .sort(sortByLabel),
        ],
        placeholder: 'Service Org',
        var: 'serviceOrg',
        value: 'dash.equipmentFilters.serviceOrg',
        style: `width: 300px;`,
      },
      {
        canDisplay: () => {
          const isSuper = userHasRole([0, 1, 7], this.props.sState);
          const mOrg = getMyOrg(this.props.sState);
          if (isSuper) {
            return true;
          } else {
            if (mOrg && mOrg.orgType === 0) {
              return true;
            }
            return false;
          }
        },
        opts: () => {
          return [
            { label: 'No Dental Org', value: NONE },
            ...get(this.props.sState, 'dash.orgs', [])
              .filter((o: Org) => o.orgType === 1)
              .map((m: Org) => ({ label: m.name, value: m.id }))
              .sort(sortByLabel),
          ];
        },
        placeholder: 'Dental Org',
        var: 'dentalOrg',
        value: 'dash.equipmentFilters.dentalOrg',
        style: `width: 300px;`,
      },
      {
        canDisplay: () => true,
        opts: () => {
          const isSuper = userHasRole([0, 1, 7], this.props.sState);
          const opts = [
            { label: 'No Location', value: NONE },
            ...get(this.props.sState, 'dash.locations', [])
              .map((m: Org) => ({
                label: m.name,
                value: m.id,
                orgId: m.orgId,
              }))
              .sort(sortByLabel),
          ];

          if (isSuper) {
            return opts;
          } else {
            const orgIds: string[] = [];
            get(this.props.sState, 'dash.orgs', []).map((o: Org) => {
              orgIds.push(o.id);
              if (o.serviceOrgId) {
                orgIds.push(o.serviceOrgId);
              }
            });
            let locs = get(this.props.sState, 'auth.user.locations', []);
            if (!Array.isArray(locs)) {
              locs = [];
            }
            return opts.filter(
              (o: IOpt & { orgId: string }) =>
                locs.indexOf(o.value) !== -1 ||
                orgIds.indexOf(o.orgId) !== -1 ||
                o.value === NONE
            );
          }
        },
        placeholder: 'Location',
        var: 'location',
        value: 'dash.equipmentFilters.location',
        style: `width: 300px;`,
      },
    ],
    filters_2: [
      {
        canDisplay: () => userHasRole([0], this.props.sState),
        opts: () => [
          { label: 'True', value: 1 },
          { label: 'False', value: 0 },
          { label: 'None', value: -1 },
        ],
        placeholder: 'Show On Dash',
        var: 'showOnDash',
        value: 'dash.equipmentFilters.showOnDash',
        single: true,
      },
      {
        opts: () => [
          { label: 'On', value: 'on' },
          { label: 'Standby', value: 'standby' },
          { label: 'Offline', value: 'off' },
        ],
        placeholder: 'Status',
        var: 'status',
        value: 'dash.equipmentFilters.status',
      },
      {
        canDisplay: () => userHasRole([0, 1, 7], this.props.sState),
        opts: () => [
          { label: 'Maintenance', value: 'maint' },
          { label: 'High Pressure', value: 'highPressure' },
          { label: 'Debug', value: 'debug' },
          { label: 'Power Up Override', value: 'highVoltage' },
        ],
        placeholder: 'Mode',
        var: 'mode',
        value: 'dash.equipmentFilters.mode',
        style: `width: 230px;`,
      },
      {
        opts: () => [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
        placeholder: 'Consignment',
        var: 'onConsignment',
        value: 'dash.equipmentFilters.onConsignment',
        single: true,
      },
      {
        canDisplay: () => userHasRole([0, 1], this.props.sState),
        opts: () =>
          (get(this, 'props.sState.dash.integrations', []) || []).filter((f: any) => get(f, 'frontend.showInFilter', true)).map((i: {}) => ({
            label: get(i, 'sk'),
            value: get(i, 'sk'),
          })),
        placeholder: 'External Integrations',
        var: 'integrations',
        value: 'dash.equipmentFilters.integrations',
      },
      {
        opts: () => StatesSelect(),
        placeholder: 'State',
        var: 'dentalOrgState',
        value: 'dash.equipmentFilters.dentalOrgState',
      },
      // {
      //   opts: () => [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
      //   placeholder: 'Active',
      //   var: 'isActive',
      //   value: 'dash.equipmentFilters.isActive',
      //   single: true,
      // },
    ],
  };

  goToReg = () => {
    localStorage.removeItem(REG_KEY);
    this.props.history.push('/eqRegistration');
  }

  componentDidMount(): void {
      const { sState } = this.props;
      if (userHasRole([2, 3, 4, 6], sState) && !get(sState, 'auth.user.orgId')) {
        this.goToReg();
      }
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (nextProps.selectedView !== this.props.selectedView) {
      return true;
    } else if (nextProps.views !== this.props.views) {
      return true;
    } else if (nextProps.filterEquipment !== this.props.filterEquipment) {
      return true;
    } else if (nextState.showFilters !== this.state.showFilters) {
      return true;
    } else if (nextState.showMoreFilters !== this.state.showMoreFilters) {
      return true;
    } else if (
      nextProps.sState.dash.equipmentFilters !==
      this.props.sState.dash.equipmentFilters
    ) {
      return true;
    } else if (nextProps.equipmentCount !== this.props.equipmentCount) {
      return true;
    }
    return false;
  }

  changeSelect = (val: SelectValue, index: number, filter: Filter[]) => {
    const { sState, filterEquipment } = this.props;

    const select = filter[index];

    filterEquipment({
      ...get(sState, 'dash.equipmentFilters', {}),
      [select.var]: val,
    });
  };
  clearFilters = () => {
    const { filterEquipment } = this.props;
    filterEquipment();
  };
  changeView = (i: number) => {
    const { onSelectView } = this.props;
    onSelectView && onSelectView(i);
  };
  renderFilters = (
    filters: Filter[],
    shouldRenderButtons: boolean,
    shouldRenderClear: boolean
  ) => {
    const { showFilters, showMoreFilters } = this.state;
    const { sState } = this.props;

    const isPhoneBreak = isPB(sState);

    const Filters = filters.map((filter, i) => {
      const canDisplay = filter.canDisplay ? filter.canDisplay() : true;
      const opts =
        typeof filter.opts === 'function' ? filter.opts() : filter.opts;

      if (!canDisplay) { return null }

      if (filter.type === 'button') {
        return <Button css={css(styles.margRight, filter.style)} {...filter as BtnProps} />
      }
      return (
        <Select
          key={i}
          css={css(styles.select, styles.margRight, filter.style)}
          placeholder={filter.placeholder}
          value={get(sState, filter.value || '', [])}
          mode={filter.single ? 'single' : 'multiple'}
          onChange={(val: SelectValue) => this.changeSelect(val, i, filters)}
          showArrow={true}
          filterOption={(input, option) => {
            const name = option.props.children as string;
            if (name)
              return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            return true;
          }}
        >
          {opts.map((opt, _i) => (
            <Select.Option key={_i} value={opt.value}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      );
    });

    return isPhoneBreak ? (
      <div>
        {shouldRenderButtons && (
          <div css={css(styles.pbContainer, SharedStyles.row)}>
            <Link
              css={css(SharedStyles.row)}
              onClick={() => this.setState({ showFilters: !showFilters })}
            >
              {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
              <AIcon
                css={css('margin-left: 5px;')}
                type={showFilters ? 'up' : 'down'}
              />
            </Link>
            {this.renderViews()}
          </div>
        )}

        {showFilters && <div css={css(styles.pbFilters)}>{Filters}</div>}

        {showFilters && shouldRenderClear && (
          <Link
            css={css(styles.link, `margin-bottom: 20px;`)}
            onClick={this.clearFilters}
          >
            Clear
          </Link>
        )}
      </div>
    ) : (
      <div css={css(styles.container, SharedStyles.row)}>
        {Filters}

        {shouldRenderButtons && (
          <Link css={css(styles.link)} onClick={this.clearFilters}>
            Clear
          </Link>
        )}
        {shouldRenderButtons && (
          <Link
            css={css(SharedStyles.row, styles.link)}
            onClick={() => this.setState({ showMoreFilters: !showMoreFilters })}
          >
            {`${showMoreFilters ? 'Less Filters' : 'More Filters'}`}
            <AIcon
              css={css('margin-left: 5px; font-size: 12px;')}
              type={showMoreFilters ? 'up' : 'down'}
            />
          </Link>
        )}

        {shouldRenderButtons && this.renderViews()}
      </div>
    );
  };
  renderViews = () => {
    const { selectedView, views } = this.props;

    return (
      <div css={css(styles.viewContainer, SharedStyles.row)}>
        {views.map((view, i) => (
          <div
            key={i}
            onClick={() => this.changeView(i)}
            css={css(styles.viewIcon)}
          >
            <Icon
              name={view.icon}
              color={
                i === selectedView ? colors.highlight : colors.disabledColor
              }
              size={18}
            />
          </div>
        ))}
      </div>
    );
  };
  render() {
    const { sState } = this.props;
    const isPhoneBreak = isPB(sState);
    return (
      <div css={css(styles.filtersContainer)}>
        {this.renderFilters(
          this.state.filters_1,
          true,
          isPhoneBreak ? false : true
        )}
        {(isPhoneBreak || this.state.showMoreFilters) &&
          this.renderFilters(
            this.state.filters_2,
            false,
            isPhoneBreak ? true : false
          )}
      </div>
    );
  }
}

_TableHeader.defaultProps = {
  selectedView: 0,
  onSelectView: () => 0,
  views: [],
  sState: {} as AppState,
  filterEquipment: () => 0,
  equipmentCount: '',
} as unknown as IProps;

export const TableHeader = connect(
  (sState: AppState) => ({ sState }),
  {
    filterEquipment,
  }
)(withRouter(_TableHeader));
