import React, { Component } from 'react';
import { configureStore } from './configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Navigation from './navigation';
import setup from './utils/setup';
import { GlobalInserts } from './_shared/styles';
import { Global } from '@emotion/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AppConfigProvider } from './_shared/hooks/useAppConfig';

export const { store, persistor } = configureStore();
setup();

Bugsnag.start({
  apiKey: '9a21d7e0f5140c076e2b910d84f3aaa4',
  plugins: [new BugsnagPluginReact()],
});

//@ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Global styles={GlobalInserts} />
            <AppConfigProvider>
              <Navigation />
            </AppConfigProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    );
  }
}
