/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';

const styles = {
  row: `
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  fullWidth: `
    width: 100%;
  `,
  column: `
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  fullHeight: `
    flex: 1;
    height: 100%;
  `,
  center: `
    justify-content: center;
  `,
  dontCenter: `
    align-items: flex-start;
  `
};

export const Row = props => {
  const { center, children, className, fullWidth } = props;

  const classes = styles;

  return (
    <div {...props} css={css(classes.row, center && classes.center, fullWidth && classes.fullWidth, className)}>
      {children}
    </div>
  )
}

export const Column = props => {
  const { center, children, className, fullHeight, fullWidth } = props;

  const classes = styles;

  return (
    <div {...props} css={css(classes.column, center ? classes.center : classes.dontCenter, fullWidth && classes.fullWidth, fullHeight && classes.fullHeight, className)}>
      {children}
    </div>
  )
}