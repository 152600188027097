import Amplify from 'aws-amplify';
import config from '../config';
import { getEnv } from '../config';
import { instance } from '../_shared/services/instance';
import { AppActions } from '../app.reducer';
import { get } from 'lodash';

//@ts-ignore
console._log = (...args) => {
  const env = getEnv();
  if (env !== 'prod') {
    //@ts-ignore
    // tslint:disable-next-line
    console.log(...args);
  }
};

//@ts-ignore
console._warn = (...args) => {
  const env = getEnv();
  if (env !== 'prod') {
    console.warn(...args);
  }
};

//@ts-ignore
console._error = (...args) => {
  const env = getEnv();
  if (env !== 'prod') {
    console.error(...args);
  }
};

export default () => {
  const conf = config();

  Amplify.configure({
    Auth: {
      identityPoolId: conf.aws_identity_pool,
      region: conf.aws_region,
      userPoolId: conf.aws_user_pool,
      userPoolWebClientId: conf.aws_webclient_id,
    },
    disabled: true,
  });

  require('./support');
};

export const loadIntegration = async (store: any, integrationid?: string) => {
  const [end, domain, sub, integration] = window.location.host
    .split(':')
    .join('.')
    .split('.')
    .reverse();

  const int = integrationid || integration || sub;
  if (int) {
    const result = await instance.get(`/content/integration/${int}`);

    const data = get(result, 'data.data', {});

    store.dispatch({ type: AppActions.SET_APP, payload: data });
  }
};