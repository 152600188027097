import { EditProfileComponent } from './edit-profile.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { updateProfiles } from '../dashboard.actions';

export const EditProfile = connect(
  (sState: AppState) => ({ sState }),
  {
    updateProfiles,
  }
)(EditProfileComponent);
