/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import SharedStyles from '../styles';
import styles from './info-row.styles';

export interface ICol {
  onClick?: () => void;
  render?: () => React.ReactElement;
}

interface IProps {
  columns: ICol[];
  header?: () => React.ReactElement;
  noFill?: boolean;
  isPhoneBreak?: boolean;
}

export default class InfoRow extends Component<IProps> {
  render() {
    const { columns, header, noFill, isPhoneBreak } = this.props;

    const _header = header && header();

    return (
      <div
        css={css(
          styles.container,
          isPhoneBreak && styles.extendContainer,
          noFill && styles.noFill
        )}
      >
        {_header}

        <div css={css(!isPhoneBreak && SharedStyles.row)}>
          {columns.map((col, i) => {
            const elem = col.render ? col.render() : null;
            const isLast = i === columns.length - 1;
            return (
              <div
                onClick={col.onClick}
                css={css(
                  styles.col,
                  `width: ${isPhoneBreak ? 50 : 100 / columns.length}%;`,
                  isPhoneBreak && `float: left;`,
                  (isLast || (isPhoneBreak && i % 2 !== 0)) &&
                    `border-right: none;`,
                  col.onClick && `cursor: pointer;`
                )}
                key={i}
              >
                {elem}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
