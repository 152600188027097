import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex-direction: column;
  `,
  mainFormContainer: `
    display: flex;
    flex-direction: column;
  `,
  formContainer: `
    width: 80%;
    flex-direction: row;
    align-self: center;
  `,
  topFormContainer: `
    width: 80%;
    border-bottom: 2px solid ${colors.inputBorder};
    margin-bottom: 20px;
    padding-bottom: 20px;
    align-self: center;
  `,
  formContainer2: `
    width: 50%;
  `,
  subText: `
    background-color: ${colors.disabledLight};
    font-size: 12px;
    text-align: left;
    padding: 2px 5px;
  `,
  rowMargin: `
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`,
};
