/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PureComponent } from 'react';
import { Select } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { take, size } from 'lodash';
import { AutoComplete } from '.';

interface IProps extends AutoCompleteProps {
  limit: number;
}

export class AutoCompleteLimited extends PureComponent<IProps> {
  state = {
    allData: this.props.dataSource || [],
    filtered: take(this.props.dataSource, this.props.limit),
  };
  onSearch = (value: string) => {
    const allData = (this.props.dataSource || []).filter(s => {
      const _s = (s || { text: '' }) as { text: string };
      const str = _s.text.toUpperCase();
      return str.indexOf(value.toUpperCase()) > -1;
    });

    this.setState({
      allData,
      filtered: take(allData, this.props.limit),
    });
  };
  componentDidUpdate = (prevProps: IProps) => {
    if (this.props.dataSource !== prevProps.dataSource) {
      this.setState({
        filtered: take(this.props.dataSource, this.props.limit),
        allData: this.props.dataSource,
      });
    }
  };
  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    if ((size(this.props.dataSource) !== size(nextProps.dataSource)) || nextState !== this.state || nextProps.value !== this.props.value) {
      return true;
    }

    return false;
  }
  render() {
    const gt = this.state.allData.length > this.props.limit;
    const filtered = gt
      ? this.state.filtered.concat([
          <Select.Option disabled key="all" className="show-all">
            Displaying first 25 results. Search by Name or Serial Number to
            filter this list.
          </Select.Option>,
        ])
      : this.state.filtered;
    return (
      <AutoComplete
        {...this.props}
        dataSource={filtered}
        onSearch={this.onSearch}
      />
    );
  }
}
