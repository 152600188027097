import colors from '../colors';

const bubble = 20;

export default {
  rowWrap: `
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 40px;
  `,
  row: `
    width: auto;
    justify-content: center;
    align-items: center;
  `,
  activeRow: `
    .bubble {
      background-color: ${colors.highlight};
    }
    > div {
      color: ${colors.highlight};
      font-weight: bold;
    }
  `,
  numberCircle: `
    width: ${bubble}px;
    height: ${bubble}px;
    border-radius: ${bubble}px;
    background-color: ${colors.buttonDark};
    color: ${colors.white} !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 5px;
  `,
  divider: `
    width: 30px;
    border-bottom: 1px solid ${colors.buttonDark};
    margin-right: 5px;
    margin-left: 5px;
  `,
};
