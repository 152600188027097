import colors from '../../_shared/colors';

export default {
  inputContainer: `
    max-width: 800px;
    border: none;
    width: 100%;
  `,
  eqRow: `
    align-items: flex-start;
    border: 1px solid ${colors.inputBorder};
    margin: 10px 0px;
    padding: 10px;

    .ant-calendar-picker {
      width: 100%;
    }

    .btnRow {
      margin-left: auto;
    }
  `,
  addLocationButton: `
    position: absolute;
    top: -8px;
    right: -32px;
  `
};
