import { guid } from '../../utils';

export interface Group {
  [key: string]: string | number | undefined;
  id: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  isActive?: number;
  orgId?: string;
  name?: string;
}

export class _Group {
  constructor(props?: Group) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined;
  id = guid();
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  orgId = '';
  isActive = 1;
}
