import colors from '../colors';

export default {
  container: ``,
  headerWrap: `
    margin: -24px;
    padding: 20px;
    margin-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-between;
  `,
  headerCol: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${colors.black};
    font-size: 10px;
    width: 25%;
  `,
  pbHeaderCol: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    color: ${colors.black};
    font-size: 10px;
  `,
  headerColVal: `
    font-size: 18px;
  `,
  pbHeaderColVal: `
    font-size: 14px;
  `,
  modalTitle: `
    font-weight: bold;
  `,
  modalSubTitle: `
    font-size: 11px;
    font-weight: normal;
    line-height: 12px;
    margin-right: 30px;
    margin-top: 5px;
  `,
  legend: `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  `,
};
