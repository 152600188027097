import { SchedulingComponent } from './scheduling.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { waitForNewConfig, waitForNewSchedule } from '../dashboard.actions';

export const Scheduling = connect(
  (sState: AppState) => ({ sState }),
  {
    waitForNewConfig,
    waitForNewSchedule,
  }
)(SchedulingComponent);
