/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import {
  getUsers,
  updateUser,
} from '../../_shared/services/manage-users.service';
import { Input, Checkbox, Alert, Icon } from 'antd';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  userHasRole,
  sortByName,
  sortByLabel,
  isServiceOrgAdmin,
  serviceOrgThenDentalOrgs,
  isPB,
} from '../../utils';
import { SelectValue } from 'antd/lib/select';
import { chain, uniq, get } from 'lodash';
import { User } from '../../_shared/interfaces/user';
import colors from '../../_shared/colors';
import { ModalMultiSelectSearch } from '../../_shared/modal-multi-select-search';
import { getGroups } from '../../_shared/services/manage-groups.service';
import { Group } from '../../_shared/interfaces/group';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { Select } from '../../_shared/select';
import { Org } from '../../_shared/interfaces/org';
import Link from '../../_shared/link';
import { ColumnProps } from 'antd/lib/table';

const Columns = (_this: _ManageGroupsComponent): ColumnProps<{ _group: Group }>[] => {
  const iAmOrgAdmin = userHasRole(2, _this.props.sState);
  const isSuperAdmin = userHasRole(0, _this.props.sState);
  const cols: ColumnProps<{ _group: Group }>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '22.5%',
    },
    ... (isServiceOrgAdmin(_this.props.sState) ||
      userHasRole([0, 1], _this.props.sState) ||
      iAmOrgAdmin) ? [{
        title: 'Organization',
        dataIndex: 'organization',
        width: '22.5%',
      }] : [],
    ...isSuperAdmin ? [{
      title: 'Org Type',
      dataIndex: 'orgType',
      width: '15%',
    }] : [],
    {
      title: 'Users',
      dataIndex: 'users',
      width: '15%',
    },
    {
      title: 'Equipment',
      dataIndex: 'equipment',
      width: '15%',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '5%',
      render: (text: string, record: { _group: Group }) => {
        const isActive = get(record._group, 'isActive', 0);
        return (
          <Icon
            type={isActive ? 'check-circle' : 'close-circle'}
            css={css(`color: ${isActive ? colors.success : colors.error};`)}
          />
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '5%',
      render: (text: string, record: { _group: Group }) => {
        const canShow = userHasRole([0, 1, 2, 3], _this.props.sState);

        return !canShow ? null : (
          <Icon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              _this.editGroup(record._group);
            }}
            type={'edit'}
            css={css(`color: ${colors.highlight};`)}
          />
        );
      },
    },
  ];
  return cols;
};

interface IProps extends RouteComponentProps {
  sState: AppState;
}

type Opt = 'locations' | 'groups' | 'equipment';

class _ManageGroupsComponent extends Component<IProps> {
  state = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    data: [],
    allUsers: [],
    allGroups: [],
    allEquip: [],
    searchValue: null,
    showInactive: false,
    selectedRows: [],
    show: true,
    showFilters: true,
    orgs: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1], this.props.sState);
        const isSOAdmin = isServiceOrgAdmin(this.props.sState);
        return isSuper || isSOAdmin ? true : false;
      },
      opts: () => {
        const isSOAdmin = isServiceOrgAdmin(this.props.sState);

        if (isSOAdmin) {
          return serviceOrgThenDentalOrgs(this.props.sState);
        } else {
          return this.props.sState.dash.orgs
            .map(org => ({
              label: org.name,
              value: org.id,
            }))
            .sort(sortByLabel);
        }
      },
      selected: [] as string[],
    },
    modal: {
      visible: false,
      opts: [],
      selected: [],
      users: [],
      title: '',
      placeholder: '',
      opt: 'locations' as Opt,
    },
  };
  componentDidMount = () => {
    const { sState } = this.props;
    const { state } = this.props.history.location;
    const ispb = isPB(sState);

    if (this.state.showFilters && ispb) {
      this.setState({ showFilters: false });
    }

    if (state && state.toast) {
      this.setState({ success: state.toast });
    }

    const org = get(state, 'org');

    if (org) {
      const name = 'orgs';
      this.setState(
        {
          show: false,
          [name]: {
            ...get(this.state, name),
            selected: [org.id],
          },
        },
        this.retrieveGroups
      );
    } else {
      this.retrieveGroups();
    }
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (
      get(prevProps, 'sState.dash.view.isPhoneBreak') !==
      get(this.props, 'sState.dash.view.isPhoneBreak')
    ) {
      const { sState } = this.props;
      const ispb = isPB(sState);
      if (ispb) {
        this.setState({ showFilters: false });
      } else {
        this.setState({ showFilters: true });
      }
    }
  };

  editGroup = (group: Group | undefined, editMode = true, isAdd = false) => {
    this.props.history.push('/dashboard/editGroup', {
      group,
      editMode,
      isAdd,
      back: `< Back To Groups`,
    });
  };
  manageGroup = (group: Group) => {
    this.props.history.push('/dashboard/manageGroup', {
      group,
    });
  };
  selectGroup = (record: { _group: Group }, index: number) => {
    this.manageGroup(record._group);
  };
  selectedRowsChange = (keys: string[] | number[]) => {
    this.setState({
      selectedRows: keys,
    });
  };
  fireSearch = (value: string) => {
    this.setState({ searchValue: value }, () => this.retrieveGroups(false));
  };
  retrieveGroups = async (shouldFetch = true) => {
    const state = { ...this.state };
    const { searchValue, showInactive } = state;
    const {
      sState,
      sState: { dash },
    } = this.props;

    const iAmSuperOrTech = userHasRole([0, 1], sState);
    const iAmGroupAdmin = userHasRole(3, sState);

    this.setState({ loading: true });

    try {
      const [users, allGroups, equip] = await Promise.all([
        shouldFetch ? await getUsers() : state.allUsers,
        shouldFetch ? await getGroups() : state.allGroups,
        await getEquipments(),
      ]);

      const orgStrings: string[] = [];
      get(sState, 'dash.orgs', []).map((o: Org) => {
        if (o.serviceOrgId) {
          orgStrings.push(o.serviceOrgId);
        }
        orgStrings.push(o.id);
      });

      let groups = iAmSuperOrTech
        ? allGroups
        : allGroups.filter(g => orgStrings.indexOf(g.orgId || '') !== -1);

      if (iAmGroupAdmin) {
        const gStrings = get(sState, 'auth.user.groups', []);
        groups = allGroups.filter(g => gStrings.indexOf(g.id) > -1);
      }

      const groupMap = chain(groups)
        .keyBy('id')
        .value();

      const orgMap = chain(dash.orgs)
        .keyBy('id')
        .value();

      users.map(u => {
        const g = get(u, 'groups', []) as string[];

        g.map(_g => {
          // tslint:disable-next-line:no-any
          let map: { [key: string]: any } = groupMap[_g];
          if (!map || map._count === undefined) {
            map = groupMap[_g] = {
              ...groupMap[_g],
              _count: 0,
            };
          }

          map._count += 1;
        });
      });

      const data = [...groups]
        .map((group, i) => {
          const gMap = groupMap[group.id];
          const _e = equip.filter(
            e =>
              ((get(e, 'groups', []) || []) as string[]).indexOf(group.id) > -1
          );

          // tslint:disable-next-line: no-any
          const orgType: any = get(orgMap, `${group.orgId}.orgType`);

          return {
            ...group,
            _group: { ...group },
            organization: get(orgMap, `${group.orgId}.name`, ''),
            orgType: orgType === 0 ? 'Service' : orgType === 1 ? 'Dental' : '',
            users: get(gMap, '_count', 0),
            equipment: _e.length,
          };
        })
        .filter(group => {
          let isVisible = true;

          if (isVisible && searchValue) {
            isVisible =
              (group.name || '')
                .toUpperCase()
                .indexOf((searchValue || '').toUpperCase()) > -1;
          }

          if (isVisible && !showInactive) {
            isVisible = get(group, 'isActive', 0) > 0;
          }

          const orgsSelected = get(this.state, 'orgs.selected', []);
          if (isVisible && orgsSelected.length > 0) {
            isVisible = orgsSelected.indexOf(group.orgId) > -1;
          }

          return isVisible;
        })
        .sort(sortByName);

      this.setState({
        allGroups: groups as never[],
        allUser: users as never[],
        data: data as never[],
        loading: false,
        show: true,
      });
    } catch (err) {
      console.warn(err);
    }
  };
  changeSelect = (item: 'orgs', value: SelectValue) => {
    const state = { ...this.state };
    state[item].selected = [...(value as string[])];
    this.setState(state, () => this.retrieveGroups(false));
  };

  getSelectedUsers = () => {
    const { selectedRows, allUsers } = this.state;
    return selectedRows.map(k => allUsers[k]);
  };
  modalOk = async (result: { tags: string[] }) => {
    const {
      sState: { dash },
    } = this.props;
    const { modal } = this.state;

    const dopt = dash[modal.opt];
    // tslint:disable-next-line:no-any
    const opts = (dopt as any[])
      .filter(l => result.tags.indexOf(l.name || '') > -1)
      .map(l => l.id);

    const users = (modal.users as User[])
      .filter(u => u.role > 1)
      .map(user => {
        const u = {
          ...user,
        };
        u[modal.opt] = uniq([...opts, ...u[modal.opt]]);
        return u;
      });

    users.map(async (user, i) => {
      try {
        await updateUser(user);
      } catch (err) {
        this.setState({ error: `Save Failed: ${err.message}` });
      }
      if (i >= users.length - 1) {
        this.closeModal();
      }
    });
  };
  openModal = (opt: 'locations' | 'groups' | 'equipment') => {
    const {
      sState: { dash },
    } = this.props;
    const { orgs } = this.state;
    const selectedOrg = orgs.selected[0];

    let titles = {
      locations: 'Add Location(s) to selected users',
      groups: 'Add Groups(s) to selected users',
      equipment: 'Add Equipment to selected users',
    };

    let placeholders = {
      locations: 'Search by Location name',
      groups: 'Search by Group name',
      equipment: 'Search by Equipment name',
    };

    const modal = { ...this.state.modal };
    modal.visible = true;
    modal.title = titles[opt];
    modal.placeholder = placeholders[opt];
    modal.opt = opt;
    modal.selected = [];
    modal.users = this.getSelectedUsers();
    // tslint:disable-next-line:no-any
    modal.opts = (dash[opt] as any[])
      .filter(o => o.orgId === selectedOrg)
      .map(l => l.name) as never[];

    this.setState({ modal });
  };
  closeModal = () => {
    const state = { ...this.state };
    state.modal.visible = false;
    this.setState(state);
  };

  render() {
    const {
      columns,
      data,
      loading,
      showInactive,
      orgs,
      error,
      success,
      selectedRows,
      modal,
      show,
      showFilters,
    } = this.state;

    const { sState } = this.props;
    const ispb = isPB(sState);

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <TableListHeader
          style={ispb && SharedStyles.pbFilterContainer}
          hide={selectedRows.length > 0}
        >
          <div
            css={css(SharedStyles.row, ispb ? `width: 100%` : `width: 50%;`)}
          >
            <Input.Search
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 70%;
                      margin-right: 10px;
                    `
              }
              placeholder="Search..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />

            <Button
              css={ispb && css(`display: none;`)}
              loading={loading}
              type="link"
              title="Refresh"
              onClick={() => this.retrieveGroups(true)}
            />
          </div>
          <div css={ispb && css(SharedStyles.pbButtonContainer)}>
            <Button
              title={'Add Group'}
              onClick={() => this.editGroup(undefined, true, true)}
            />
            <div css={!ispb && css(SharedStyles.hidden)}>
              <Link
                css={css(SharedStyles.row)}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
                <Icon
                  css={css('margin-left: 5px;')}
                  type={showFilters ? 'up' : 'down'}
                />
              </Link>
            </div>
          </div>
        </TableListHeader>

        <TableListHeader
          hide={selectedRows.length > 0}
          style={
            ispb
              ? SharedStyles.pbFilterContainer
              : `justify-content: flex-start;`
          }
        >
          {orgs.canDisplay() && show && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 30%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Orgs'}
              defaultValue={(orgs.selected as unknown) as string}
              onChange={value => this.changeSelect('orgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {orgs.opts &&
                orgs.opts().map((item, i) => (
                  <Select.Option key={i} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          )}

          <Checkbox
            checked={showInactive}
            onChange={() =>
              this.setState({ showInactive: !showInactive }, () =>
                this.retrieveGroups(false)
              )
            }
          >
            Show Inactive
          </Checkbox>
        </TableListHeader>

        <TableListHeader hide={selectedRows.length === 0}>
          <Button
            title={'Add to Locations(s)'}
            onClick={() => this.openModal('locations')}
          />
          <Button
            title={'Add to Group(s)'}
            onClick={() => this.openModal('groups')}
          />
          <Button
            title={'Add to Equipment'}
            onClick={() => this.openModal('equipment')}
          />
        </TableListHeader>

        <TableList
          canSelectRows={false}
          loading={loading}
          columns={columns}
          data={data}
          rowClick={this.selectGroup}
          onSelectedRowsChange={this.selectedRowsChange}
          pageSize={100}
          includeCount="Groups Count"
        />

        <ModalMultiSelectSearch
          title={modal.title}
          placeholder={modal.placeholder}
          visible={modal.visible}
          searchOpts={modal.opts}
          onResult={this.modalOk}
          onCancel={this.closeModal}
        />
      </div>
    );
  }
}

export const ManageGroupsComponent = withRouter(_ManageGroupsComponent);
