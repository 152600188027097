import getConfig from '../../config';
import { get } from 'lodash';
import { Publish } from '../interfaces/publish';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getPublish = async (id: string) => {
  const resp = await instance.get(`/publish/${id}`, { data: {} });
  return get(resp, 'data.event', null) as Publish;
};

export const createPublish = async (event: Publish) => {
  const config = getConfig();
  const e = { ...event };
  if (e.data && typeof e.data !== 'string') {
    e.data = JSON.stringify(e.data);
  }
  return await instance.post(
    '/publish/create',
    {
      ...removeEmpty(e),
    },
    { headers: { accesstoken: config.access_token } }
  );
};

export const updatePublish = (event: Publish) => {
  return createPublish(event);
};

export const getPreSignedUrl = async (key: string) => {
  const url = await instance.post(`/publish/testResults`, {
    data: key,
  });
  return url;
};
