import colors from '../_shared/colors';

export default {
  container: `
    background-color: ${colors.white};
  `,
  contentContainer: `
    background-color: ${colors.lightBackground};
    padding: 20px;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  `,
  searchContainer: `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .ant-input-search {
      width: 100%;
    }
  `,
};
