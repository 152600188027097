/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { Model } from '../../../../_shared/interfaces/model';
import { makeSwitch, makeNumInput } from '../../spoof-a-publish.component';
import { CompressorTestResultsPData } from '../../../../_shared/interfaces/publishData';

type Payload = CompressorTestResultsPData;
type MaybeNumber = number | undefined;

interface IProps {
  model: Model;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const CompressorTestResultsDataForm = (props: IProps): JSX.Element => {
  const { model, validChanged, payloadChanged } = props;

  //STATE
  const [heat, setHeat] = useState<MaybeNumber>(undefined);
  const [motor1Noise, setMotor1Noise] = useState<MaybeNumber>(undefined);
  const [motor1Amps, setMotor1Amps] = useState<MaybeNumber>(undefined);
  const [motor1AmpPass, setMotor1AmpPass] = useState<MaybeNumber>(undefined);
  const [motor1Pass, setMotor1Pass] = useState<MaybeNumber>(undefined);
  const [motor2Noise, setMotor2Noise] = useState<MaybeNumber>(undefined);
  const [motor2Amps, setMotor2Amps] = useState<MaybeNumber>(undefined);
  const [motor2AmpPass, setMotor2AmpPass] = useState<MaybeNumber>(undefined);
  const [motor2Pass, setMotor2Pass] = useState<MaybeNumber>(undefined);
  const [des, setDes] = useState<MaybeNumber>(undefined);
  const [prePerfDuration, setPrePerfDuration] = useState<MaybeNumber>(
    undefined
  );
  const [prePerfPass, setPrePerfPass] = useState<boolean>(true);
  const [prePerfHiDuration, setPrePerfHiDuration] = useState<MaybeNumber>(
    undefined
  );
  const [prePerfPsiTrue, setPrePerfPsiTrue] = useState<MaybeNumber>(undefined);
  const [prePerfPsi, setPrePerfPsi] = useState<MaybeNumber>(undefined);
  const [preDecayPass, setPreDecayPass] = useState<boolean>(true);
  const [preDecayInit, setPreDecayInit] = useState<MaybeNumber>(undefined);
  const [preDecay5Min, setPreDecay5Min] = useState<MaybeNumber>(undefined);
  const [preElecPass, setPreElecPass] = useState<boolean>(true);
  const [preElecVolts, setPreElecVolts] = useState<MaybeNumber>(undefined);
  const [preElecAmps, setPreElecAmps] = useState<MaybeNumber[]>([]);
  const [postPerfDuration, setPostPerfDuration] = useState<MaybeNumber>(
    undefined
  );
  const [postPerfPass, setPostPerfPass] = useState<boolean>(true);
  const [postPerfHiDuration, setPostPerfHiDuration] = useState<MaybeNumber>(
    undefined
  );

  const [postDecayPass, setPostDecayPass] = useState<boolean>(true);
  const [postDecayInit, setPostDecayInit] = useState<MaybeNumber>(undefined);
  const [postDecay5Min, setPostDecay5Min] = useState<MaybeNumber>(undefined);
  const [postElecPass, setPostElecPass] = useState<boolean>(true);
  const [postElecVolts, setPostElecVolts] = useState<MaybeNumber>(undefined);
  const [postElecAmps, setPostElecAmps] = useState<MaybeNumber[]>([]);

  useEffect(() => {
    if (model !== undefined && model.heads !== undefined && model.heads > 0) {
      let amps = [undefined] as MaybeNumber[];
      for (let i = 0; i < model.heads; i++) {
        amps[i] = undefined;
      }
      setPreElecAmps([...amps]);
      setPostElecAmps([...amps]);
    }
  }, []); //pass empty array so this just runs on attach, initialize motor array to dynamic size

  //EFFECTS
  useEffect(() => {
    //VALIDATE!
    if (
      heat !== undefined &&
      motor1Noise !== undefined &&
      motor1Amps !== undefined &&
      motor1AmpPass !== undefined &&
      motor1Pass !== undefined &&
      motor2Noise !== undefined &&
      motor2Amps !== undefined &&
      motor2AmpPass !== undefined &&
      motor2Pass !== undefined &&
      des !== undefined &&
      prePerfDuration !== undefined &&
      prePerfHiDuration !== undefined &&
      prePerfPsiTrue !== undefined &&
      prePerfPsi !== undefined &&
      preDecayInit !== undefined &&
      preDecay5Min !== undefined &&
      preElecVolts !== undefined &&
      preElecAmps.some(a => a === undefined) === false &&
      postPerfDuration !== undefined &&
      postPerfHiDuration !== undefined &&
      postDecayInit !== undefined &&
      postDecay5Min !== undefined &&
      postElecVolts !== undefined &&
      postElecAmps.some(a => a === undefined) === false
    ) {
      validChanged(true);
      payloadChanged({
        heat: heat,
        m1: {
          noise: motor1Noise,
          amps: motor1Amps,
          amp_pass: motor1AmpPass,
          pass: motor1Pass,
        },
        m2: {
          noise: motor2Noise,
          amps: motor2Amps,
          amp_pass: motor2AmpPass,
          pass: motor2Pass,
        },
        des: des,
        pre: {
          perf: {
            dur: prePerfDuration,
            pass: prePerfPass ? 1 : 0,
            hi_dur: prePerfHiDuration,
            psi_true: prePerfPsiTrue,
            psi: prePerfPsi,
          },
          decay: [preDecayPass ? 1 : 0, preDecayInit, preDecay5Min],
          elec: {
            pass: preElecPass ? 1 : 0,
            v: preElecVolts,
            amps: preElecAmps as number[],
          },
        },
        post: {
          perf: {
            dur: postPerfDuration,
            pass: postPerfPass ? 1 : 0,
            hi_dur: postPerfHiDuration,
          },
          decay: [postDecayPass ? 1 : 0, postDecayInit, postDecay5Min],
          elec: {
            pass: postElecPass ? 1 : 0,
            v: postElecVolts,
            amps: postElecAmps as number[],
          },
        },
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [
    heat,
    motor1Noise,
    motor1Amps,
    motor1AmpPass,
    motor1Pass,
    motor2Noise,
    motor2Amps,
    motor2AmpPass,
    motor2Pass,
    des,
    prePerfDuration,
    prePerfPass,
    prePerfHiDuration,
    prePerfPsiTrue,
    prePerfPsi,
    preDecayPass,
    preDecayInit,
    preDecay5Min,
    preElecPass,
    preElecVolts,
    preElecAmps,
    postPerfDuration,
    postPerfPass,
    postPerfHiDuration,
    postDecayPass,
    postDecayInit,
    postDecay5Min,
    postElecPass,
    postElecVolts,
    postElecAmps,
  ]);

  //HANDLERS
  function ampsChanged(
    amps: MaybeNumber[],
    newAmps: MaybeNumber,
    index: number,
    setter: (newArray: MaybeNumber[]) => void
  ) {
    let mutable = amps;
    mutable[index] = newAmps;
    setter([...mutable]);
  }

  //UI GENERATIVE
  const generateElecAmpInputs = (
    amps: MaybeNumber[],
    setter: (newArray: MaybeNumber[]) => void
  ): JSX.Element[] => {
    let content = [] as JSX.Element[];

    content.push(
      makeNumInput(`?? amps`, amps[0], newAmps =>
        ampsChanged(amps, newAmps, 0, setter)
      )
    );

    if (model !== undefined && model.heads !== undefined && model.heads > 0) {
      for (let i = 0; i < model.heads; i++) {
        const fieldIndex = i + 1;

        content.push(
          <div key={`hs${i}`}>
            {makeNumInput(
              `Elec Motor #${fieldIndex} Amps`,
              amps[fieldIndex],
              newAmps => ampsChanged(amps, newAmps, fieldIndex, setter)
            )}
          </div>
        );
      }
    } else {
      content.push(
        <p key={'heads failure'}>unable to determine number of heads</p>
      );
    }

    return content;
  };

  //UI DECLARATIVE
  return (
    <div>
      {makeNumInput('Heat', heat, setHeat)}
      {makeNumInput('Motor #1 Noise', motor1Noise, setMotor1Noise)}
      {makeNumInput('Motor #1 Amps', motor1Amps, setMotor1Amps)}
      {makeNumInput('Motor #1 Amps Pass', motor1AmpPass, setMotor1AmpPass)}
      {makeNumInput('Motor #1 Pass', motor1Pass, setMotor1Pass)}
      {makeNumInput('Motor #2 Noise', motor2Noise, setMotor2Noise)}
      {makeNumInput('Motor #2 Amps', motor2Amps, setMotor2Amps)}
      {makeNumInput('Motor #2 Amps Pass', motor2AmpPass, setMotor2AmpPass)}
      {makeNumInput('Motor #2 Pass', motor2Pass, setMotor2Pass)}
      {makeNumInput('Des', des, setDes)}
      {makeNumInput('Pre Perf Duration', prePerfDuration, setPrePerfDuration)}
      {makeSwitch('Pre Perf Pass', prePerfPass, setPrePerfPass)}
      {makeNumInput(
        'Pre Perf High Duration',
        prePerfHiDuration,
        setPrePerfHiDuration
      )}
      {makeNumInput('Pre Perf PSI True', prePerfPsiTrue, setPrePerfPsiTrue)}
      {makeNumInput('Pre Perf PSI', prePerfPsi, setPrePerfPsi)}
      {makeSwitch('Pre Decay Pass', preDecayPass, setPreDecayPass)}
      {makeNumInput('Pre Decay initial value', preDecayInit, setPreDecayInit)}
      {makeNumInput('Pre Decay after 5 mins', preDecay5Min, setPreDecay5Min)}
      {makeSwitch('Pre Elec Pass', preElecPass, setPreElecPass)}
      {makeNumInput('Pre Elec Volts', preElecVolts, setPreElecVolts)}
      {generateElecAmpInputs(preElecAmps, setPreElecAmps)}
      {makeNumInput(
        'Post Perf Duration',
        postPerfDuration,
        setPostPerfDuration
      )}
      {makeSwitch('Post Perf Pass', postPerfPass, setPostPerfPass)}
      {makeNumInput(
        'Post Perf High Duration',
        postPerfHiDuration,
        setPostPerfHiDuration
      )}
      {makeSwitch('Post Decay Pass', postDecayPass, setPostDecayPass)}
      {makeNumInput(
        'Post Decay initial value',
        postDecayInit,
        setPostDecayInit
      )}
      {makeNumInput('Post Decay after 5 mins', postDecay5Min, setPostDecay5Min)}
      {makeSwitch('Post Elec Pass', postElecPass, setPostElecPass)}
      {makeNumInput('Post Elec Volts', postElecVolts, setPostElecVolts)}
      {generateElecAmpInputs(postElecAmps, setPostElecAmps)}
    </div>
  );
};
