import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { Service } from '../interfaces/service';
import { instance } from './instance';

export const getService = async (id: string) => {
  const resp = await instance.get(`/service/${id}`, { data: {} });
  return get(resp, 'data.services', null) as Service[];
};

export const getServices = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/service`, {
      where,
    });
  } else {
    resp = await instance.get(`/service`, { data: {} });
  }
  return get(resp, 'data.services', []) as Service[];
};

export const createService = async (service: Service) => {
  const resp = await instance.post('/service/create', {
    service: removeEmpty(service),
  });
  return get(resp, 'data.data', null) as Service;
};

export const updateService = (service: Service) => {
  return createService(service);
};
