import getConfig from '../../config';
import { get, set } from 'lodash';
import { TandemGroup } from '../interfaces/tandemGroup';
import { removeEmpty, convertWhere, pullDataOutOfString } from '../../utils';
import { message } from 'antd';
import { instance } from './instance';

export const getTandemGroup = async (id: string) => {
  const resp = await instance.get(`/tandemgroup/${id}`, { data: {} });
  return get(resp, 'data.tandemGroup', null) as TandemGroup;
};

export const getTandemGroups = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/tandemgroup`, {
      ...convertWhere(where),
    });
  } else {
    resp = await instance.get(`/tandemgroup`, { data: {} });
  }
  const tandemGroups = get(resp, 'data.tandemGroups', []) as TandemGroup[];
  return tandemGroups;
};

export const createTandemGroup = async (tandemGroup: TandemGroup) => {
  const resp = await instance.post('/tandemgroup/create', {
    tandemGroup: removeEmpty(tandemGroup),
  });
  return get(resp, 'data.tandemGroup', null) as TandemGroup;
};

export const updateTandemGroup = (tandemGroup: TandemGroup) => {
  return createTandemGroup(tandemGroup);
};

export const deleteTandemGroup = async (tandemGroup: TandemGroup) => {
  const resp = await instance.delete(`/tandemgroup/${tandemGroup.id}`);
  return get(resp, 'data.tandemGroup', null) as TandemGroup;
};

export const sendTGDeviceCommand = async (
  deviceId: string,
  data: {},
  _opts: {
    tandemGroupNumber: number;
    tandemGroupRole: string;
    showErrorToast?: boolean;
  }
) => {
  console.table(_opts);
  const opts = {
    tandem: {
      group: _opts.tandemGroupNumber,
      role: _opts.tandemGroupRole,
    },
    showErrorToast: _opts.showErrorToast ? _opts.showErrorToast : true,
  };
  try {
    const config = getConfig();
    const resp = await instance.post(
      '/schedule/sendDeviceCommand',
      {
        deviceId,
        data,
      },
      {
        headers: {
          access_token: config.access_token,
        },
      }
    );
    const respData = get(resp, 'data.success', {});

    const responseCode = respData.return_value;
    if (responseCode !== 0 && responseCode !== -12) {
      const errr = new Error('Unable to complete command');
      set(errr, 'response', resp);
      throw errr;
    }

    return resp;
  } catch (err) {
    const error = pullDataOutOfString(
      get(err, 'response.data.error', err.message)
    );
    console.error(error);
    if (opts.showErrorToast) {
      message.error(`Request failed. Unable to communicate with equipment.`);
    }
    const retError = new Error(get(error, 'error', err));
    set(retError, 'data', error);
    throw retError;
  }
};
