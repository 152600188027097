import { mergeDeep } from "./utils";

export interface AppConfigState {
  logo: string;
}

export interface AppActionTypes {
  type: AppActions;
  payload?: {};
}

export enum AppActions {
  SET_APP = 'SET_APP',
}

const initialState = {
  logo: '',
  aerasLogo:
    'https://particle-images.s3.us-east-1.amazonaws.com/logos/aeras.png',
};

export const appReducer = (
  state: AppConfigState = initialState,
  action: AppActionTypes
) => {
  switch (action.type) {
    default:
      return state;
    case AppActions.SET_APP:
      return mergeDeep(state, action.payload);
  }
};
