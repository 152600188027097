import colors from '../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${colors.white};
    overflow: auto;
  `,
  title: `
    color: ${colors.highlight};
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  `,
  subTitle: `
    font-size: 12px;
  `,
  error: `
    color: ${colors.error};
    font-size: 14px;
    text-align: center;
    max-width: 300px;
  `,
  inputContainer: `
    margin-top: 10px;
    width: 90%;
    max-width: 400px;
    border: 1px solid ${colors.inputBorder};
    padding: 20px;
  `,
  fgPassword: `
    position: absolute;
    top: -30px;
    right: 0px;
    color: ${colors.labelColor};
    font-size: 10px;
    float: right;
    cursor: pointer;
  `,
  buttonContainer: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    martin-top: -10px;
  `,
  passwordPrompt: `
    text-align: justify;
  `,
  brandedLoginForm: `
    padding-top: 20px;
    width: 80%;
    max-width: 300px;
  `,
  column: `
    display: flex;
    flex-direction: column;
    align-items: center;
  `
};
