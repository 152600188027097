/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button } from "antd";
import { ReactChild, useState, useEffect } from 'react';
import styles from './styles';
import { get } from 'lodash';

interface Btn {
  title?: string;
  body?: (p: any) => string | ReactChild;
  onClick?: (p: any) => void;
}

interface Screen {
  title?: string | ReactChild;
  buttons?: Btn[];
}

interface IProps {
  open: boolean;
  screens: Screen[];
  onClose?: (s: {}) => void;
}

export const Tutorial = (props: IProps) => {
  const { open, screens } = props;

  const [state, setState] = useState({
    index: 0,
  });

  const events = {
    next: () => {
      setState(s => ({ ...s, index: s.index + 1 }));
    },
    prev: () => {
      setState(s => ({ ...s, index: s.index - 1 }));
    },
    close: () => {
      setState(s => ({ ...s, index: 0 }));
      props.onClose && props.onClose({ state });
    }
  }

  const clickBtn = (btn: Btn) => {
    btn.onClick && btn.onClick({ ...btn, state, events });
  }

  const renderScreen = (index: number) => {
    const screen = get(screens, index);

    if (!screen) { return null }
    
    return (
      <div>
        <div css={css(styles.titleBar)}>
          <div className='title'>{get(screen, 'title', '')}</div>

          <div className='close'>
            <Button icon="close" onClick={events.close} />
          </div>
        </div>

        <div css={css(styles.bodyContainer)}>
          {get(screen, 'body', () => '')({ events })}
        </div>

        <div css={css(styles.footerBar)}>
          <div className='buttons'>
            {(get(screen, 'buttons', []) as Btn[]).map((btn, i) => {
              return (
                <Button {...btn} key={i} onClick={() => clickBtn(btn)}>{btn.title}</Button>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!open) {
      setState(s => ({ ...s, index: 0 }));
    }
  }, [open]);

  if (!open) { return null }

  return (
    <div css={css(styles.container)}>
      <div>
        {renderScreen(state.index)}
      </div>
    </div>
  )
}