import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: -20px;
  `,
  mainContainer: `
    min-height: calc(100%);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;
    padding-top: 0px;
    border-left: 2px solid ${colors.inputBorder};
  `,
  billingHistoryEmptyStateHeader: `
    color: ${colors.highlight};
    font-size: 15px;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    padding-bottom: 10px;
  `,
  billingHistoryEmptyStateText: `
    margin: 0px;
    font-size: 12px;
    text-align: center;
  `,
  headerContainer: `
    display: flex;
    flex-direction: row;
  `,
  actionButtonContainer: `
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 10px;
  `,
};
