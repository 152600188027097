import { get } from 'lodash';
import { Role } from '../interfaces/role';
import { encryptString, removeEmpty } from '../../utils';
import { instance } from './instance';

const getError = (err: Error | unknown) => {
  return get(err, 'response.data.error', get(err, 'message', err));
};

export const getAllRoles = async () => {
  try {
    const resp = await instance.get(`/role`, { data: {} });
    return get(resp, 'data.roles', []);
  } catch (_err) {
    const err = getError(_err);
    console.warn(err);
    throw new Error(err);
  }
};

export const createRole = async (role: Role) => {
  try {
    const resp = await instance.post('/role', {
      role: removeEmpty(role),
    });
    return get(resp, 'data.role');
  } catch (err) {
    console.warn(err);
    throw new Error(err);
  }
};

export const signUpUser = async (estring: string, password: string) => {
  try {
    const resp = await instance.post('/role/user/confirm', {
      estring,
      password,
    });
    return get(resp, 'data.user');
  } catch (_err) {
    const err = getError(_err);
    console.warn(err);
    throw new Error(err);
  }
};

export const changeUserPassword = async (
  email: string,
  pins: string,
  password: string
) => {
  try {
    const resp = await instance.post(`/role/user/changeuserpassword`, {
      email,
      pins: encryptString(pins),
      password,
    });
    return get(resp, 'data.data', []);
  } catch (_err) {
    const err = getError(_err);
    throw new Error(err);
  }
};

export const getIntegrations = async () => {
  const resp = await instance.get('/role/integrations');
  return get(resp, 'data.data', []);
};
