/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ChangeEvent, Component } from 'react';
import { Layout } from 'antd';
import { withRouter, RouteComponentProps, Route } from 'react-router';
import moment from 'moment';
import { Header } from './header';
import { SideMenu } from './side-menu';
import { DashRoute } from '../_shared/interfaces/dashroute';
import styles from './dashboard.styles';
import {
  filterBasedOnRoles,
  userHasRole,
  isPB,
  isServiceOrgUser,
} from '../utils';

import { AppState } from '../app.state';
import { Routes, SideRoutes, AdminSideRoutes } from './dashboard.routes';
import Pusher from 'pusher-js';
import CONFIG, { getEnv } from '../config';
import { Publish } from '../_shared/interfaces/publish';
import { Footer } from '../footer';
import { Search } from './header/search.component';
import { uniqBy, get } from 'lodash';
import { Equipment } from '../_shared/interfaces/equipment';
import { Profile } from '../_shared/interfaces/profile';
import { User } from '../_shared/interfaces/user';

const config = CONFIG();

interface IProps extends RouteComponentProps {
  logout: () => void;
  retrieveData: () => Promise<void>;
  sState: AppState;
  addToMap: (pubs: Publish[]) => Publish[];
  updateEquipmentPusher: (equipment: Equipment) => void;
  updateUserPusher: (user: User) => void;
}

interface IState {
  headerRoutes: DashRoute[];
  sideRoutes: DashRoute[];
  ellipsisRoutes: DashRoute[];
  allRoutes: { [key: string]: DashRoute };
  searchValue?: string | undefined;
  profiles?: Profile[];
}

export class DashboardComponent extends Component<IProps, IState> {
  pusher: Pusher.Pusher;
  constructor(props: IProps) {
    super(props);
    this.props.retrieveData();
    const { sState } = props;
    const isOrgAdmin = userHasRole([2], props.sState);
    const isOrgGroupAdmin = userHasRole([3], props.sState);
    const isProductionRole = userHasRole([5], props.sState);
    const profiles: Profile[] = get(sState, 'dash.profiles');

    // const userHasHandPiece: boolean = models.includes('handpiece');

    const getAdminHeaderRoute = () => {
      let route = [Routes.admin];
      if (isOrgAdmin) {
        route = [
          {
            ...Routes.admin,
            path: '/dashboard/myOrg',
          },
        ];
      } else if (isOrgGroupAdmin) {
        route = [
          {
            ...Routes.admin,
            path: '/dashboard/manageGroups',
          },
        ];
      }
      return route;
    };

    let state;
    if (isProductionRole) {
      state = {
        allRoutes: Routes,
        ellipsisRoutes: [
          Routes.account,
          Routes.changePassword,
          {
            title: 'Sign Out',
            icon: { type: 'unlock' },
            path: '/dashboard/logout',
            onClick: props.logout,
          },
        ],
        headerRoutes: [Routes.manufactureProcess],
        sideRoutes: [Routes.manufactureProcess],
      };
      props.history.push('/dashboard/manufactureProcess');
    } else {
      state = {
        allRoutes: Routes,
        ellipsisRoutes: [
          Routes.account,
          Routes.manageUsers,
          Routes.manageGroups,
          Routes.notificationSettings,
          Routes.changePassword,
          {
            title: 'Resources',
            icon: { type: 'resources', lib: 'SvgIcon' },
            onClick: this.openResources,
            path: '/dashboard/resources',
            // allowedRoles: [1, 2, 3, 4, 5, 6],
          },
          {
            title: 'Sign Out',
            icon: { type: 'unlock' },
            path: '/dashboard/logout',
            onClick: props.logout,
          },
        ],
        headerRoutes: [
          Routes.landing,
          Routes.scheduling,
          Routes.profiles,
          ...getAdminHeaderRoute(),
          Routes.reporting,
        ],
        sideRoutes: SideRoutes,
        searchValue: undefined,
        profiles,
      };
    }

    this.state = state;

    this.pusher = new Pusher(config.pusher.key, {
      cluster: config.pusher.cluster,
      forceTLS: true,
    });

    const channel = this.pusher.subscribe(config.pusherChannelName);

    const env = getEnv();

    channel.bind(`publishCreate_${env}`, data => {
      if (get(data, '0') !== '{') {
        //TODO figure out where this weird content is coming from
        //@ts-ignore
        props.addToMap([data]);
      }
    });
    channel.bind(`equipmentCreate_${env}`, data => {
      //@ts-ignore
      console._warn('equipmentCreate:', data);
      const { sState } = this.props;
      const equip = data as Equipment;
      const cUser = get(sState, 'auth.user');

      if (!equip.modelId) { return }

      const isOrgUser = userHasRole([2, 3, 4], sState);
      const isProdUser = userHasRole([5], sState);
      if (isOrgUser) {
        if (
          (!!equip.dentalOrgId && equip.dentalOrgId === cUser.orgId) ||
          (!!equip.serviceOrgId && equip.serviceOrgId === cUser.orgId)
        ) {
          props.updateEquipmentPusher(equip);
        }
        return;
      }
      if (isProdUser) {
        if (equip.test) {
          const now = moment();
          const date = moment(equip.test);
          const diff = now.diff(date, 'days');
          equip._testDiff = diff;
        } else {
          equip._testDiff = undefined;
        }
        if (equip._testDiff !== undefined && equip._testDiff <= 7) {
          props.updateEquipmentPusher(equip);
        }
        return;
      }
      props.updateEquipmentPusher(equip);
    });
    channel.bind(`userCreate_${env}`, data => {
      const { sState } = this.props;
      const user = data as User;
      const cUser = get(sState, 'auth.user');
      const isOrgUser = userHasRole([2, 3, 4], sState);
      const isProdUser = userHasRole([5], sState);
      if (isProdUser) return;
      if (isOrgUser) {
        if (user.orgId === cUser.orgId) {
          props.updateUserPusher(user);
        }
        return;
      }
      props.updateUserPusher(user);
    });
    channel.bind('test', data => {
      console.warn(data);
    });
  }

  componentDidUpdate(prevProps: IProps) {
    const prevSearchString = get(
      prevProps,
      'sState.dash.equipmentFilters.search'
    );
    const currentSearchString = get(
      this.props,
      'sState.dash.equipmentFilters.search'
    );
    if (prevSearchString !== currentSearchString && !currentSearchString) {
      this.setState({ searchValue: undefined });
    }
    if (
      get(prevProps.sState, 'dash.profiles') !==
      get(this.props.sState, 'dash.profiles')
    ) {
      this.setState({
        profiles: get(this.props.sState, 'dash.profiles'),
      });
    }
  }

  openResources = () => {
    const { sState } = this.props;
    const isDZAdmin = userHasRole([0, 1, 7], sState);
    const isSOUser = isServiceOrgUser(sState);
    const url =
      isDZAdmin || isSOUser
        ? 'https://www.dentalez.com/aeras-reference-page-service-orgs-video-tutorials/'
        : 'https://www.dentalez.com/aeras-reference-page-dental-orgs-video-tutorials/';

    var win = window.open(url, '_blank');
    win && win.focus();
  };

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
  };

  changeRoute = (route: DashRoute) => {
    if (route.sideRoutes) {
      return this.setState({ sideRoutes: route.sideRoutes }, () =>
        this.props.history.push(route.path)
      );
    }
    this.props.history.push(route.path);
  };
  findRoute = () => {
    const { location } = this.props.history;
    let route;
    for (let key in Routes) {
      let r = Routes[key];
      if (r && r.path === location.pathname) {
        route = r;
        break;
      }
    }
    return route;
  };
  componentWillUnmount = () => {
    this.pusher.unsubscribe(config.pusherChannelName);
  };
  render() {
    const { sState } = this.props;
    const { headerRoutes, ellipsisRoutes, allRoutes } = this.state;
    const currentRoute = this.findRoute();
    const _headerRoutes = filterBasedOnRoles(headerRoutes, sState);
    const _sideRoutes = filterBasedOnRoles(
      currentRoute && currentRoute.sideRoutes,
      sState
    );
    let _ellipsisRoutes = filterBasedOnRoles(ellipsisRoutes, sState);
    const ispb = isPB(sState);
    if (ispb) {
      const sroutes = filterBasedOnRoles(AdminSideRoutes, sState);

      _ellipsisRoutes = uniqBy(
        [Routes.scheduling, ...sroutes, ..._ellipsisRoutes],
        'path'
      );
    }

    const showSearch = get(currentRoute, 'showSearch', true);
    const showPBSearch = get(currentRoute, 'showPBSearch', true) && showSearch;

    return (
      <Layout css={css(styles.container)}>
        <Header
          routes={_headerRoutes}
          ellipsisRoutes={_ellipsisRoutes}
          changeRoute={this.changeRoute}
          showSearch={showSearch}
          searchValue={this.state.searchValue}
          onChange={this.onChange}
        />
        <Layout>
          {!ispb && _sideRoutes.length > 0 && (
            <SideMenu
              routes={_sideRoutes}
              currentRoute={currentRoute}
              changeRoute={this.changeRoute}
            />
          )}
          <Layout>
            <Layout.Content
              id={'ContentContainer'}
              css={css([
                styles.contentContainer,
                ispb && `margin-bottom: 75px;`,
              ])}
            >
              {ispb && showPBSearch && (
                <div css={css(styles.searchContainer)}>
                  <Search
                    searchValue={this.state.searchValue}
                    onChange={this.onChange}
                  />
                </div>
              )}
              {Object.keys(allRoutes).map((key, i) => (
                <Route key={i} {...allRoutes[key]} />
              ))}
              {ispb &&
                this.props.history.location.pathname !== '/dashboard' && (
                  <Footer shouldExtend={true} />
                )}
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export const Dashboard = withRouter(DashboardComponent);
