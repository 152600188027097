export const thresholds = {
  'C3-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M1P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    TP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 120,
    },
    TPH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 135,
    },
    TT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 100,
      majorUpper: null,
    },
    TH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 50,
      majorUpper: null,
    },
    AT: {
      minorLower: 34,
      majorLower: null,
      minorUpper: 110,
      majorUpper: 140,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 95,
      majorUpper: null,
    },
    VL: {
      minorLower: 203,
      majorLower: 195,
      minorUpper: 253,
      majorUpper: 256,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PDP: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 5,
      majorUpper: null,
    },
    DE: {
      minorLower: 10,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    RGT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PU: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    HDC: {
      minorLower: null,
      majorLower: null,
      minorUpper: 75,
      majorUpper: 240,
    },
  },
  'C4-1': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M1P: {
      minorLower: null,
      majorLower: 460,
      minorUpper: null,
      majorUpper: 1380,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M2T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M2P: {
      minorLower: null,
      majorLower: 460,
      minorUpper: null,
      majorUpper: 1380,
    },
    M2R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    TP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 120,
    },
    TPH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 135,
    },
    TT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 100,
      majorUpper: null,
    },
    TH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 50,
      majorUpper: null,
    },
    AT: {
      minorLower: 34,
      majorLower: null,
      minorUpper: 110,
      majorUpper: 140,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 95,
      majorUpper: null,
    },
    VL: {
      minorLower: 103,
      majorLower: 100,
      minorUpper: 126,
      majorUpper: 230,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PDP: {
      minorLower: null,
      majorLower: null,
      minorUpper: 5,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    DE: {
      minorLower: 10,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    RGT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PU: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    HDC: {
      minorLower: null,
      majorLower: null,
      minorUpper: 75,
      majorUpper: null,
    },
  },
  'C4-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M1P: {
      minorLower: null,
      majorLower: 460,
      minorUpper: null,
      majorUpper: 1380,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M2T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M2P: {
      minorLower: null,
      majorLower: 460,
      minorUpper: null,
      majorUpper: 1380,
    },
    M2R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    TP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 120,
    },
    TPH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 135,
    },
    TT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 100,
      majorUpper: null,
    },
    TH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 50,
      majorUpper: null,
    },
    AT: {
      minorLower: 34,
      majorLower: null,
      minorUpper: 110,
      majorUpper: 140,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 95,
      majorUpper: null,
    },
    VL: {
      minorLower: 103,
      majorLower: 100,
      minorUpper: 126,
      majorUpper: 230,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PDP: {
      minorLower: null,
      majorLower: null,
      minorUpper: 5,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    DE: {
      minorLower: 10,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    RGT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PU: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    HDC: {
      minorLower: null,
      majorLower: null,
      minorUpper: 75,
      majorUpper: null,
    },
  },
  'C6-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M1P: {
      minorLower: null,
      majorLower: 460,
      minorUpper: null,
      majorUpper: 1380,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M2T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M2P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M2R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    TP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 120,
    },
    TPH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 135,
    },
    TT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 100,
      majorUpper: null,
    },
    TH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 50,
      majorUpper: null,
    },
    AT: {
      minorLower: 34,
      majorLower: null,
      minorUpper: 110,
      majorUpper: 140,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 95,
      majorUpper: null,
    },
    VL: {
      minorLower: 103,
      majorLower: 100,
      minorUpper: 126,
      majorUpper: 230,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PDP: {
      minorLower: null,
      majorLower: null,
      minorUpper: 5,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    DE: {
      minorLower: 10,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    RGT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PU: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    HDC: {
      minorLower: null,
      majorLower: null,
      minorUpper: 75,
      majorUpper: null,
    },
  },
  'C7-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M1P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M2T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M2P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M2R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    TP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 120,
    },
    TPH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 135,
    },
    TT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 100,
      majorUpper: null,
    },
    TH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 50,
      majorUpper: null,
    },
    AT: {
      minorLower: 34,
      majorLower: null,
      minorUpper: 110,
      majorUpper: 140,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 95,
      majorUpper: null,
    },
    VL: {
      minorLower: 103,
      majorLower: 100,
      minorUpper: 126,
      majorUpper: 230,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PDP: {
      minorLower: null,
      majorLower: null,
      minorUpper: 5,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    DE: {
      minorLower: 10,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    RGT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PU: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    HDC: {
      minorLower: null,
      majorLower: null,
      minorUpper: 75,
      majorUpper: null,
    },
  },
  'C11-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M1P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M2T: {
      minorLower: null,
      majorLower: null,
      minorUpper: 225,
      majorUpper: 240,
    },
    M2P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M2R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M3P: {
      minorLower: null,
      majorLower: 800,
      minorUpper: null,
      majorUpper: 2415,
    },
    M3R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    TP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 120,
    },
    TPH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: 135,
    },
    TT: {
      minorLower: null,
      majorLower: null,
      minorUpper: 100,
      majorUpper: null,
    },
    TH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 50,
      majorUpper: null,
    },
    AT: {
      minorLower: 34,
      majorLower: null,
      minorUpper: 110,
      majorUpper: 140,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: 95,
      majorUpper: null,
    },
    VL: {
      minorLower: 103,
      majorLower: 100,
      minorUpper: 126,
      majorUpper: 230,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PDP: {
      minorLower: null,
      majorLower: null,
      minorUpper: 5,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    DE: {
      minorLower: 10,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    RGT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PU: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    HDC: {
      minorLower: null,
      majorLower: null,
      minorUpper: 75,
      majorUpper: null,
    },
  },
  'RV4-12': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1P: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WO: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    OL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    IP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ODR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VE: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
  },
  'RV5-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1P: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WO: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    OL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    IP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ODR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VE: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
  },
  'RV7-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1P: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WO: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    OL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    IP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ODR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VE: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
  },
  'RV10-2': {
    M1T: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1P: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    M1R: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    AH: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WO: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    OL: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    WP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    IP: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ER: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    PR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ALT: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    ODR: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
    VE: {
      minorLower: null,
      majorLower: null,
      minorUpper: null,
      majorUpper: null,
    },
  },
};
