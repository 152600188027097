import React, { useState } from 'react';
import { Modal, Alert, message, Icon } from 'antd';
import { get, size } from 'lodash';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import {
  replaceEquipmentController,
} from '../manufacture-process/manufacture-process.utils';
import { AppState } from '../../app.state';
import { Row } from '../../_shared/row';
import colors from '../../_shared/colors';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { wait } from '../../utils';
import { Button } from '../../_shared/button';
import { updateEquipment } from '../../_shared/services/manage-equipment.service';
import { createService } from '../../_shared/services/manage-services.service';
import { _Service } from '../../_shared/interfaces/service';
import { SERIALNOTNECESSARY } from '../../_shared/lib/services';

export interface ReplaceProps extends RouteComponentProps {
  onClose: () => void;
  visible: boolean;
  equipment: Equipment;
  sState: AppState;
}

interface Event {
  name: string;
  title: string;
  state: string;
  data?: {};
}

interface IState {
  loading: boolean;
  error?: string;
  serial1: string;
  serial2: string;
  serial3: string;
  results?: { errors: string[] };
  events: Event[];
  eventsToRun?: Event[];
}

export const SSUpgrade = withRouter((props: ReplaceProps) => {
  const [state, setState] = useState(({
    loading: false,
    error: undefined,
    serial1: '',
    serial2: '',
    serial3: '',
    results: undefined,
    eventsToRun: undefined,
    events: [
      {
        name: 'deviceIsOnline',
        killOnFail: true,
        title: 'Checking if device is online',
        state: 'queue',
        modifyError: (err?: Error) => {
          if (err) {
            return 'Device is Offline';
          }
          return undefined;
        },
      },
      {
        name: 'updateModel',
        title: 'Sending updated model to device',
        state: 'queue',
        killOnFail: true,
      },
      // {
      //   name: 'updateComponentSerial',
      //   title: 'Updating motorhead serial numbers on new device',
      //   state: 'queue',
      //   killOnFail: true,
      //   method: async (opts = {}) => {
      //     await awaitAll(
      //       get(opts, 'serials', []).map(
      //         (s: any, _i: number) => () => async () => {
      //           const i = _i + 1;
      //           const arg = `set_sn,motor${i},${get(opts, `serial${i}`)}`;
      //           await sendDeviceCommand(
      //             get(opts, 'deviceId'),
      //             { arg },
      //             { showErrorToast: false, timeout: DEVICETIMEOUT }
      //           );
      //         }
      //       ),
      //       { queueSize: 1 }
      //     );
      //   },
      // },
      // {
      //   name: 'updateSerialRuntime',
      //   title: 'Updating runtime values on new device',
      //   state: 'queue',
      //   killOnFail: true,
      //   method: async (opts = {}) => {
      //     await awaitAll(
      //       get(opts, 'serials', []).map(
      //         (s: any, _i: number) => () => async () => {
      //           const i = _i + 1;
      //           const arg = `set_runtime,motor${i},0`;
      //           await sendDeviceCommand(
      //             get(opts, 'deviceId'),
      //             { arg },
      //             { showErrorToast: false, timeout: DEVICETIMEOUT }
      //           );
      //         }
      //       ),
      //       { queueSize: 1 }
      //     );
      //   },
      // },
      {
        name: 'creatingServiceRecord',
        title: 'Creating service record',
        state: 'queue',
        killOnFail: true,
        method: async (opts = {}) => {
          const now = new Date().toISOString();
          await createService(new _Service({
            coreid: get(opts, 'equipment.deviceId'),
            techName: 'SS Upgrade',
            note: 'Sound shield field upgrade',
            services: [{ "all_other": SERIALNOTNECESSARY }],
            serviceDate: now,
            published_at: now,
          }))
        },
      },
      {
        name: 'updateEquipment',
        title: 'Updating equipment record',
        state: 'queue',
        killOnFail: true,
        method: async (opts = {}) => {
          await updateEquipment(get(opts, 'equipment'));
        },
      },
    ],
  } as unknown) as IState);

  const onClose = () => {
    setState(s => ({
      ...s,
      results: undefined,
      eventsToRun: undefined,
      events: s.events.map(e => ({ ...e, data: undefined, state: 'queue' })),
    }));
    props.onClose && props.onClose();
  };

  const updateEvent = (name: string, _state: string, data: {}) => {
    setState(s => ({
      ...s,
      events: s.events.map(e => {
        const isEvent = e.name === name;
        return {
          ...e,
          state: isEvent ? _state : e.state,
          data: isEvent ? data : get(e, 'data'),
        };
      }),
    }));
  };

  const submit = async () => {
    try {
      setState(s => ({ ...s, loading: true }));

      const { equipment, sState } = props;
      const me = get(sState, 'auth.user');

      const eq = {
        ...equipment,
        modelId: `${get(equipment, 'modelId')}SS`,
      };

      const results = await replaceEquipmentController({
        ...state,
        deviceId: eq.deviceId,
        equipment: eq,

        checkSerial: false,
        onEventUpdate: updateEvent,
        events: state.eventsToRun || state.events,
      });

      const errors = results.filter((event: {}) => {
        const hasError = get(event, 'error', get(event, 'errors'));
        return !!hasError || get(event, 'data') === null;
      });

      console.warn({
        errors,
      });

      // let newEquipment: Equipment;
      if (!size(errors)) {
        message.success('Controller swap success!');
        const allevents = [...state.events, ...results]
          .filter(f => !!f.data)
          .map(e => ({ ...e, name: e.name || e.event }));
        // newEquipment = new _Equipment(get(
        //   allevents.find((e: {}) => get(e, 'name') === 'createNewEquip'),
        //   'data',
        //   {}
        // ) as Equipment);
      }

      setState(s => ({
        ...s,
        eventsToRun: errors.map((e: {}) =>
          s.events.find(ev => ev.name === get(e, 'event'))
        ),
        results: { errors },
      }));
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  };

  const refresh = async () => {
    const equipment = props.equipment;
    props.history.goBack();
    await wait(200);
    return props.history.push('/dashboard/equipmentDetails', {
      equipment,
    });
  }

  const visible = !!props.visible;

  const canRetry = state.results && !!size(state.results.errors);

  const isComplete = !!state.results && !size(state.results.errors);

  return (
    <Modal
      visible={visible}
      title={'Upgrade Equipment to Sound Shield (SS) Model'}
      centered
      closable
      confirmLoading={state.loading}
      okText={isComplete ? 'Refresh' : canRetry ? 'Resend Failed Updates' : 'Update'}
      onCancel={onClose}
      onOk={isComplete ? refresh : submit}
    >
      {(!!state.loading || !!state.results) && (
        <div>
          {state.events.map(event => {
            const hasError = get(event, 'data.error.message');
            const errorMessage = get(event, 'modifyError', () =>
              get(event, 'data.error')
            )(get(event, 'data.error'));
            return (
              <Row>
                <div>{event.title}</div>
                <div title={errorMessage} style={{ marginLeft: 'auto' }}>
                  {event.state === 'complete' ? (
                    <Icon
                      style={{
                        color: hasError ? colors.error : colors.success,
                      }}
                      type={hasError ? 'close-circle' : 'check-circle'}
                    />
                  ) : event.state === 'start' ? (
                    <Icon type="loading" />
                  ) : (
                    <Icon type="stop" />
                  )}
                </div>
              </Row>
            );
          })}
        </div>
      )}

      {!state.loading && !state.results && (
        <div>
          {state.error && (
            <Alert
              message={state.error}
              closable
              type="error"
              onClose={() => setState(s => ({ ...s, error: undefined }))}
            />
          )}
          <div>Are you sure you want to update this equipment to the SS model?</div>
        </div>
      )}
    </Modal>
  );
});

export const SSUpgradeBtn = (props: any) => {
  const [state, setState] = useState({
    visible: false,
    onClose: () => {
      setState(s => ({ ...s, visible: false }));
    },
  });

  const toggleDisplay = () => {
    setState(s => ({ ...s, visible: !s.visible }));
  };

  return (
    <>
      <Button title="Field SS Upgrade" size="small" onClick={toggleDisplay} />
      <SSUpgrade {...props} {...state} />
    </>
  );
};
