/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { AppState } from '../../app.state';
import {
  findModel,
  getDeviceStatusFromEquip,
  getEquipAlertMap,
  errorDef,
  userHasRole,
  isServiceOrgAdmin,
  isPB,
  isDentalOrgAdmin,
  getEquipMaint,
  getTriggeredMaint,
  isHandpiece,
  handpieceIsDueForService,
  findWorstError,
} from '../../utils';
import { get, last, sortBy } from 'lodash';
import styles, { cardStyles } from './manage-equipment.styles';
import { Org } from '../../_shared/interfaces/org';
import { EquipCard } from './card.component';
import { Location } from '../../_shared/interfaces/location';
import { TableList } from '../../_shared/table-list';
import { Icon, List } from 'antd';
import colors from '../../_shared/colors';
import _Icon from '../../_shared/icon';
import SharedStyles from '../../_shared/styles';
import { useState, MouseEvent } from 'react';
import { ModalEvent } from '../../_shared/modal-event';
import { RowList } from '../../_shared/row-list';
import { renderLabel } from '../../utils/mixins';
import { Footer } from '../../footer';
import moment from 'moment';
import { MaintStatus } from '../../_shared/interfaces/equipmentStatus';
import { ColumnProps } from 'antd/lib/table';

interface CardsProps {
  equipment: Equipment[];
  sState: AppState;
  loading: boolean;
  cardClick: (e: Equipment, fromEdit?: boolean) => void;
  pageSize?: number;
  hidePageSelect?: boolean;
}

export const getSubCatsFromEquipment = (
  equipment: Equipment,
  sState: AppState
) => {
  const location = get(sState, 'dash.locations', []).find(
    (l: Location) => l.id === equipment.locationId
  );

  const serviceOrg = get(sState, 'dash.allOrgs', []).find(
    (l: Org) => l.id === equipment.serviceOrgId
  );

  const dentalOrg = get(sState, 'dash.allOrgs', []).find(
    (l: Org) => l.id === equipment.dentalOrgId
  );

  return {
    location,
    serviceOrg,
    dentalOrg,
  };
};

export const renderStatus = (props: {
  equipment: Equipment;
  loading: boolean;
  sState: AppState;
}) => {
  const { loading, equipment } = props;
  const deviceState = getDeviceStatusFromEquip(equipment);
  const isHand = isHandpiece(equipment);
  const opt: {
    [key: string]: { color: string; label: string; canDisplay?: boolean };
  } = {
    loading: { color: colors.statusOff, label: 'loading' },
    off: { color: colors.statusOff, label: 'Offline' },
    debug: {
      color: colors.statusDebug,
      label: 'Debug',
      canDisplay: userHasRole([0], props.sState),
    },
    maint: { color: colors.statusMaint, label: 'Maint Mode' },
    standby: { color: colors.statusStandby, label: 'Standby' },
    on: { color: colors.statusOn, label: 'On' },
    highPressure: { color: colors.statusStandby, label: 'High Pressure' },
    headsEnabled: {
      color: colors.statusDebug,
      label: 'Heads Disabled',
      canDisplay: !isHand,
    },
    scheduleEnabled: { canDisplay: false, label: '', color: '' },
    highVoltage: { color: colors.statusStandby, label: 'Power Up Override' },
  };
  const renderLabel = (option: { label: string; color: string }) => {
    return (
      <div
        key={option.label}
        css={css(cardStyles.cardStatus, { backgroundColor: option.color })}
      >
        {option.label}
        {loading && <Icon css={css('margin-left: 2px;')} type="loading" />}
      </div>
    );
  };

  return (
    <div css={css(cardStyles.statusContainer)}>
      {!loading && equipment
        ? Object.keys(deviceState).map(k => {
          let obj = deviceState[k];
          if (k === 'headsEnabled') {
            obj = !obj;
          }
          const sel = opt[k];
          const canDisplay =
            sel !== undefined && sel.canDisplay !== undefined
              ? sel.canDisplay
              : true;
          return !obj || !sel || !canDisplay ? null : renderLabel(sel);
        })
        : !loading
          ? renderLabel(opt.off)
          : renderLabel(opt.loading)}
    </div>
  );
};

export const RenderAlert = (props: { equipment: Equipment }) => {
  const alert = findWorstError(get(props, 'equipment.status.alerts'), props.equipment);
  const alertCode = errorDef(get(alert, 'error', 0));

  const [modalVisible, setModalVisible] = useState(false);

  return alert ? (
    <div
      css={css`
        margin-left: 5px;
        margin-right: 5px;
      `}
    >
      <_Icon
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setModalVisible(true);
        }}
        name="exclamation-triangle"
        color={alertCode.color}
        size={16}
      />

      <ModalEvent
        onClose={(e?: MouseEvent) => {
          if (e) {
            e.preventDefault();
            e.stopPropagation();
          }
          setModalVisible(false);
        }}
        allowClear={true}
        visible={modalVisible}
        equipment={props.equipment}
        publish={{ data: alert, published_at: moment(get(alert, 'time') * 1000).toISOString() }}
        onClear={() => setModalVisible(false)}
        isActive={true}
      />
    </div>
  ) : null;
};

export const RenderConsignment = (props: {
  equipment: Equipment;
  table?: boolean;
}) => {
  const { onConsignment } = props.equipment;
  return onConsignment ? (
    <div
      css={css(
        props.table
          ? styles.consignmentContainerTable
          : styles.consignmentContainer
      )}
    >
      <p css={css(styles.consignmentText)}>C</p>
    </div>
  ) : null;
};

export const Cards = (props: CardsProps) => {
  const { equipment, sState, loading, cardClick } = props;
  const ispb = isPB(sState);

  const renderCard = (equip: Equipment) => {
    const cats = getSubCatsFromEquipment(equip, sState);
    const model = findModel(equip, sState);
    const config = get(sState, `dash.equipMap.${equip.deviceId}.configs.0`);
    const alerts = get(sState, `dash.equipMap.${equip.deviceId}.alerts`, []);
    const alertMap = get(
      sState,
      `dash.equipMap.${equip.deviceId}.alertMap`,
      {}
    );

    return (
      <EquipCard
        key={equip.id}
        location={cats.location}
        serviceOrg={cats.serviceOrg}
        dentalOrg={cats.dentalOrg}
        config={config}
        alerts={alerts}
        alertMap={alertMap}
        onClick={() => cardClick(equip)}
        onEditClick={() => cardClick(equip, true)}
        equipment={equip}
        model={model}
        loading={loading}
        sState={sState}
      />
    );
  };

  return (
    <div css={css(styles.cardContainer, `position: relative;`)}>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={equipment}
        renderItem={renderCard}
        pagination={{ pageSize: 100, size: 'small' }}
      />
      {ispb && <Footer shouldExtend={true} />}
    </div>
  );
};

const Columns = (props: CardsProps) => {
  const showServiceOrgCol = () => {
    const isSuper = userHasRole([0, 1, 7], props.sState);
    if (isSuper) {
      return true;
    } else {
      return false;
    }
  };
  const showDentalOrgCol = () => {
    const isSuper = userHasRole([0, 1, 7], props.sState);
    const isSOAdmin = isServiceOrgAdmin(props.sState);

    if (isSuper || isSOAdmin) {
      return true;
    } else {
      return false;
    }
  };
  const ispb = isPB(props.sState);

  const cols = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      render: (text: string, e: Equipment) => {
        const alertMap = getEquipAlertMap(e, props.sState);
        return (
          <div css={css(SharedStyles.row)}>
            {text}
            <RenderAlert equipment={e} />
            <RenderConsignment equipment={e} table={true} />
          </div>
        );
      },
    },
    !ispb && {
      title: 'Model ID',
      dataIndex: 'modelId',
      width: '12%',
    },
    !ispb &&
    showServiceOrgCol() && {
      title: 'Service Org',
      dataIndex: 'serviceOrgId',
      width: '15%',
      render: (text: string, e: Equipment) => {
        const cats = getSubCatsFromEquipment(e, props.sState);
        return get(cats, 'serviceOrg.name', '');
      },
    },
    !ispb && {
      title: 'Service Due',
      dataIndex: 'id',
      width: '10%',
      render: (text: string, e: Equipment) => {
        const sortedMaint = sortBy(get(e, 'status.maint', []) as MaintStatus[], 'timeDiff');
        const isServiceDue = !!sortedMaint.find(m => m.timeDiff > 0);
        const isServiceOverDue = !!sortedMaint.find(m => m.timeDiff <= 0);
        const timeUntilServiceDueHours = get(last(sortedMaint), 'timeDiff');

        return isServiceOverDue ? (
          <p css={css(styles.serviceOverdueText)}>Service Overdue</p>
        ) : isServiceDue ? (
          `Service Due: ${timeUntilServiceDueHours} hours`
        ) : (
          '--'
        );
      },
    },
    !ispb &&
    showDentalOrgCol() && {
      title: 'Dental Org',
      dataIndex: 'dentalOrgId',
      width: '15%',
      render: (text: string, e: Equipment) => {
        const cats = getSubCatsFromEquipment(e, props.sState);
        return get(cats, 'dentalOrg.name', '');
      },
    },
    !ispb && {
      title: 'Location',
      dataIndex: 'locations.0',
      width: '15%',
      render: (text: string, e: Equipment) => {
        const cats = getSubCatsFromEquipment(e, props.sState);
        return get(cats, 'location.name', '');
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      render: (text: string, e: Equipment) => {
        return renderStatus({
          equipment: e,
          loading: props.loading,
          sState: props.sState,
        });
      },
    },
    !ispb && {
      title: ' ',
      dataIndex: 'controllerSerial',
      width: '3%',
      render: (text: string, eq: Equipment) => {
        return (
          <Icon
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              props.cardClick(eq, true);
            }}
            type={'edit'}
            css={css(`color: ${colors.highlight}`)}
          />
        );
      },
    },
  ].filter(c => c);

  return cols;
};
export const renderGrid = (props: CardsProps) => {
  const { equipment, cardClick, pageSize, hidePageSelect } = props;

  const columns = Columns(props) as ColumnProps<any>[];
  const ispb = isPB(props.sState);

  const editClick = (e: MouseEvent, EQ: Equipment) => {
    e.preventDefault();
    e.stopPropagation();

    cardClick && cardClick(EQ, true);
  };

  const countExtra = (() => {
    if (get(props, 'sState.dash.activeCount.connected')) {
      const { total, connected } = get(props, 'sState.dash.activeCount', {});
      return `Connected: ${connected} / ${total} (${((connected / total) * 100).toFixed(
        2
      )}%)`;
    }
  })();

  return (
    <div>
      {equipment.length === 0 && <Icon type="loading" />}
      {equipment.length > 0 && !ispb && (
        <TableList
          canSelectRows={false}
          columns={columns}
          data={equipment as never[]}
          rowClick={(e: Equipment) => cardClick(e)}
          pageSize={pageSize}
          hidePageSelect={hidePageSelect}
          includeCount="Equipment Count"
          countExtra={countExtra}
        />
      )}
      {equipment.length > 0 && ispb && (
        <RowList
          columns={columns}
          data={equipment as never[]}
          rowClick={(e: Equipment) => cardClick(e)}
          renderOpen={_eq => {
            const eq = new _Equipment(_eq);
            const isDOAdmin = isDentalOrgAdmin(props.sState);
            const isSOAdmin = isServiceOrgAdmin(props.sState);
            const model = findModel(eq, props.sState);
            const { serviceOrg, dentalOrg, location } = getSubCatsFromEquipment(
              eq,
              props.sState
            );

            return (
              <div css={css(cardStyles.cardText)}>
                {renderLabel('modelName', {
                  obj: eq,
                  get: _val =>
                    get(model, 'name', get(model, 'id', 'Osprey Compressor')),
                })}
                {/* {renderLabel('serviceDue', {
                obj: eq,
                get: _val => 'Service Due: 14 days',
              })} */}
                {!isDOAdmin &&
                  !isSOAdmin &&
                  renderLabel('serviceOrgId', {
                    obj: eq,
                    prefix: 'Service Org: ',
                    get: (_val: string) => get(serviceOrg, 'name'),
                  })}
                {!isDOAdmin &&
                  renderLabel('dentalOrgId', {
                    obj: eq,
                    prefix: 'Dental Org: ',
                    get: (_val: string) => get(dentalOrg, 'name'),
                  })}
                {renderLabel('locationId', {
                  obj: eq,
                  prefix: 'Location: ',
                  get: (_val: string) => get(location, 'name'),
                })}
                {renderLabel('name', {
                  obj: eq,
                  get: (_val: string) => ' ',
                })}
                <Icon
                  onClick={e => editClick(e, eq)}
                  type={'edit'}
                  css={css(
                    `color: ${colors.highlight}; position: absolute; bottom: 3px; right: 16px;`
                  )}
                />
              </div>
            );
          }}
        />
      )}
    </div>
  );
};
