/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { SvgIcon } from '../../_shared/icon';

interface IProps {
  bars: number | string;
}

interface IBars {
  [key: number]: string;
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
}

const BARS: IBars = {
  0: 'noSignal',
  1: 'signal1',
  2: 'signal2',
  3: 'signal3',
  4: 'signal4',
  5: 'signal5',
};

const SignalStrengthComponent = (props: IProps) => {
  const config = {
    type:
      props.bars === null
        ? 'noSignal'
        : `${BARS[Math.round(props.bars as number)]}`,
    css: css(`font-size: 24px; align-self: center; margin-top: 5px;`),
  };
  return <SvgIcon {...config} />;
};

export const SignalStrength = SignalStrengthComponent;
