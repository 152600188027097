import { connect } from 'react-redux';
import { MFASettings as MFASettingsComponent } from './mfa-settings.component';
import { updateUser } from '../../authentication/authentication.actions';

export const MFASettings = connect(
  state => ({ sState: state }),
  {
    updateUser,
  }
)(MFASettingsComponent);
