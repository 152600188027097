const col1Width = 150;
const colWidth = 50;

export default {
  container: `
    max-width: 400px;
  `,
  alert: `
    margin-bottom: 10px;
  `,
  colContainer: `
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 30px;
  `,
  col1: `
    width: ${col1Width}px;
    float: left;
  `,
  col2: `
    width: ${colWidth}px;
    text-align: center;
    float: left;
  `,
  col3: `
    width: ${colWidth}px;
    text-align: center;
    float: left;
  `,
  label: `
    font-size: 12px !important;
  `,
};
