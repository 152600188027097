/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { TableListHeader, TableList } from '../../_shared/table-list';
import { Input, DatePicker, message, Modal } from 'antd';
import { Button } from '../../_shared/button';
import { Select } from '../../_shared/select';
import {
  eventTypes,
  getEvents,
  getPrettyName,
} from '../../_shared/services/analytics.service';
import { useSelector } from 'react-redux';
import { AppState } from '../../app.state';
import { Org } from '../../_shared/interfaces/org';
import { Role } from '../../_shared/interfaces/role';
import styles from './styles';
import { size, get, keyBy } from 'lodash';
import { useDebouncedEffect } from '../../_shared/hooks/useDebouncedEffect';
import moment from 'moment';
import { exportToCsv, jformat, sortByKey } from '../../utils';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { User } from '../../_shared/interfaces/user';

const format = 'L hh:mm:ssa';

interface IProps {
  user?: User;
  tableProps?: {};
  showFilters?: boolean;
}

export const ReportingEvents = (props: IProps = {}) => {
  const orgs = useSelector((state: AppState) => state.dash.orgs);
  const roles = useSelector((state: AppState) => state.auth.allRoles);
  const user = get(props, 'user', get(props, 'location.state.user'));
  const hasUser = !!user;
  const showFilters = get(props, 'showFilters', true);

  const [state, setState] = useState({
    loading: false,
    uniqUsers: 0,
    uniqEq: 0,
    data: [],
    filtered: null,
    events: [],
    orgs: [],
    roles: [],
    orgTypes: [],
    input: hasUser && showFilters ? get(user, 'userId') : '',
    date: {
      selected: '6_month',
      start: moment().subtract(6, 'month'),
      end: moment(),
    },
    keyedOrgs: keyBy(orgs, 'sk'),
    filters: [
      {
        id: 'events',
        opts: () =>
          Object.keys(eventTypes).map(k => ({
            label: getPrettyName(k),
            value: k,
          })),
        props: {
          placeholder: 'Select Events',
        },
      },
      {
        id: 'orgs',
        // tslint:disable-next-line
        opts: (s: any) =>
          orgs.map((org: Org) => ({ label: org.name, value: org.sk })),
        props: {
          placeholder: `Select Organizations`,
        },
      },
      {
        id: 'roles',
        // tslint:disable-next-line
        opts: (s: any) =>
          roles.map((role: Role) => ({ label: role.title, value: role.sk })),
        props: {
          placeholder: 'Select Roles',
        },
      },
      {
        id: 'orgTypes',
        // tslint:disable-next-line
        opts: (s: any) => [
          { label: 'DentalEZ', value: 'DENTALEZ' },
          { label: 'Service', value: 0 },
          { label: 'Dental', value: 1 },
        ],
        props: {
          placeholder: 'Select Org Types',
        },
      },
    ],
  });

  const filterBasedOnInput = () => {
    try {
      if (!state.input && !size(state.orgTypes)) {
        return setState(s => ({ ...s, filtered: null }));
      }

      const inp = state.input.toLowerCase();

      const filtered = (state.data.filter(d => {
        const toMatch = ['userId', 'equipment.equipmentSN'];
        const found = !state.input
          ? false
          : !!toMatch.find(
              f =>
                `${get(d, f, '')}`
                  .toString()
                  .toLowerCase()
                  .indexOf(inp) > -1
            );

        // tslint:disable-next-line
        const orgType: any = get(state.keyedOrgs, `${get(d, 'orgId')}.orgType`);
        const orgMatch = !size(state.orgTypes)
          ? false
          : !!size(
              state.orgTypes.filter(ot => {
                if (ot === 'DENTALEZ') {
                  return [0, 1, 7].indexOf(get(d, 'roleId')) > -1;
                } else {
                  return ot === orgType;
                }
              })
            );

        return found || orgMatch;
      }) as unknown) as null;

      const uniq = getUniq(filtered as unknown as any[]);

      setState(s => ({ ...s, filtered, uniqEq: size(uniq.equipment), uniqUsers: size(uniq.users) }));
    } catch (err) {
      message.error(err.message);
    }
  };

  const getUniq = (arr: any[]) => {
    const uniq = {
      users: [] as string[],
      equipment: [] as string[],
    };
    arr.map((e: {}) => {
      const userid = get(e, 'userId');
      const eid = get(e, 'equipment.deviceId');
      if (!!userid && uniq.users.indexOf(userid) === -1) {
        uniq.users.push(userid);
      }
      if (!!eid && uniq.equipment.indexOf(eid) === -1) {
        uniq.equipment.push(eid);
      }
      return e;
    });
    return uniq;
  }

  const retrieveData = async () => {
    try {
      setState(s => ({ ...s, loading: true, filtered: null }));
      const events = !size(state.events)
        ? Object.keys(eventTypes).map(k => k)
        : state.events;

      const data = (await getEvents(events, {
        orgs: state.orgs,
        roles: state.roles,
        date: state.date,
        users: hasUser && !showFilters ? [get(user, 'userId')] : undefined,
      }))
      const uniq = getUniq(data);
      setState(s => ({
        ...s,
        data,
        uniqUsers: size(uniq.users),
        uniqEq: size(uniq.equipment),
      }));
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  };

  const changeSelect = (filt: string, values: string[]) => {
    setState(s => ({ ...s, [filt]: values }));
  };

  const columns = (() => {
    const cols = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        sorter: (a: {}, b: {}) =>
          new Date(get(b, 'createdAt')).getTime() -
          new Date(get(a, 'createdAt')).getTime(),
        render: (text: string) => {
          return moment(text).format(format);
        },
      },
      {
        title: 'User',
        dataIndex: 'userId',
        sorter: (a: {}, b: {}) => sortByKey(a, b, 'userId'),
        value: (row = {}) => get(row, 'userId', ''),
        render: (text: string) => {
          return (
            <div style={{ width: 200 }} className="col-ellipsis">
              {text}
            </div>
          );
        },
      },
      {
        title: 'Organization',
        dataIndex: 'orgId',
        render: (text: string, row = {}) => {
          return get(state, ['keyedOrgs', get(row, 'orgId'), 'name'], 'N/A');
        },
      },
      {
        title: 'Event',
        dataIndex: 'event',
        render: (text: string, row = {}) => {
          return getPrettyName(text);
        },
      },
      {
        title: 'Equipment',
        dataIndex: 'equipment',
        render: (text: string, row = {}) => {
          return get(row, 'equipment.equipmentSN', '--');
        },
      },
      {
        title: 'Model',
        dataIndex: 'equipment.modelId',
        sorter: (a: {}, b: {}) =>
          sortByKey(get(a, 'equipment'), get(b, 'equipment'), 'modelId'),
        value: (row = {}) => get(row, 'equipment.modelId', ''),
      },
      {
        title: 'Details',
        dataIndex: '',
        render: (text: string, row = {}) => {
          const onClick = () => {
            Modal.info({
              title: 'Event Details',
              width: '80%',
              style: { maxWidth: 800 },
              maskClosable: true,
              content: (
                <div css={css(styles.modalContainer)}>
                  <div css={css(styles.modalHeader)}>{`${get(
                    row,
                    'event'
                  )} Event at ${moment(get(row, 'createdAt')).format(
                    format
                  )}`}</div>
                  <pre
                    css={css(styles.modalBody)}
                    dangerouslySetInnerHTML={{ __html: jformat(row) }}
                  />
                </div>
              ),
            });
          };

          return <Button title="Details" icon="eye" onClick={onClick} />;
        },
      },
    ];
    return cols.map(c => ({
      ...c,
      width: get(c, 'width', `${100 / size(cols)}%`),
    }));
  })();

  const exportData = () => {
    const exdata = !!size(state.filtered) ? state.filtered : state.data;
    const cols = columns.filter(c => c.title !== 'Details');
    const rows = [cols.map(c => c.title)];
    (exdata || []).map(event => {
      rows.push(
        cols.map(c =>
          c.value
            ? c.value(event)
            : c.render
            ? c.render(get(event, c.dataIndex), event)
            : get(event, get(c, 'dataIndex'), '')
        )
      );
    });
    exportToCsv(`UserEvents-${moment().toISOString()}.csv`, rows);
  };

  // #region date stuff
  const dateRangeChange = (
    _pickerVals: RangePickerValue,
    dates: [string, string]
  ) => {
    const date = { ...state.date };
    date.start = moment(dates[0]);
    date.end = moment(dates[1]);
    setState(s => ({ ...s, date }));
  };

  const datePickerChange = (date: moment.Moment) => {
    const _date = { ...state.date };
    _date.start = moment(date).startOf('day');
    _date.end = _date.start.clone().endOf('day');
    setState(s => ({ ...s, date: _date }));
  };

  const dateChange = (val: string) => {
    const date = { ...state.date };
    if (val === '1_day') {
      date.start = moment().subtract(1, 'day');
    } else if (val === '1_week') {
      date.start = moment().subtract(1, 'week');
    } else if (val === '1_month') {
      date.start = moment().subtract(1, 'month');
    } else if (val === '3_month') {
      date.start = moment().subtract(3, 'month');
    } else if (val === '6_month') {
      date.start = moment().subtract(6, 'month');
    }
    date.end = moment();
    date.selected = val;
    setState(s => ({ ...s, date }));
  };

  const renderDateDrop = () => {
    const {
      date: { selected, start, end },
    } = state;

    const options = [
      { title: '1 day', value: '1_day' },
      { title: '1 week', value: '1_week' },
      { title: '1 month', value: '1_month' },
      { title: '3 months', value: '3_month' },
      { title: '6 months', value: '6_month' },
      { title: 'Custom', value: 'custom_date' },
    ];

    return (
      <div>
        <Select onChange={v => dateChange(v as string)} defaultValue={selected}>
          {options.map((opt, i) => (
            <Select.Option value={opt.value} key={i}>
              {opt.title}
            </Select.Option>
          ))}
        </Select>
        {selected === 'custom_date' && (
          <DatePicker.RangePicker
            onChange={dateRangeChange}
            defaultValue={[start, end]}
          />
        )}
        {selected === '1_day' && (
          <DatePicker onChange={datePickerChange} defaultValue={start} />
        )}
      </div>
    );
  };
  //#endregion

  useEffect(() => {
    filterBasedOnInput();
  }, [state.input, state.orgTypes, state.data]);

  useDebouncedEffect(
    () => {
      retrieveData();
    },
    [state.events, state.orgs, state.roles, state.date] as never[],
    1000
  );

  const { loading, data } = state;

  return (
    <div>
      {showFilters && (
        <TableListHeader style={`justify-content: flex-start;`}>
          <Input.Search
            css={css`
              width: 50%;
              margin-right: 10px;
            `}
            placeholder="Search by User’s Email or Equipment SN..."
            enterButton="Search"
            size="default"
            onSearch={e => setState({ ...state, input: e })}
            defaultValue={state.input}
          />

          <Button
            loading={loading}
            title="Refresh"
            onClick={retrieveData}
            type="link"
          />

          <Button
            css={css('margin-left: auto;')}
            title="Export"
            onClick={exportData}
          />
        </TableListHeader>
      )}

      {showFilters && (
        <TableListHeader style={`justify-content: flex-start;`}>
          {state.filters.map(filt => {
            return (
              <Select
                key={filt.id}
                mode="multiple"
                css={css(styles.select)}
                onChange={value => changeSelect(filt.id, value as string[])}
                filterOption={(input, option) => {
                  const name = option.props.children as string;
                  if (name)
                    return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  return true;
                }}
                {...(filt.props || {})}
              >
                {(get(filt, 'opts', () => [])(state) as never[]).map(
                  (item: { label: string; value: string | number }) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  )
                )}
              </Select>
            );
          })}
        </TableListHeader>
      )}

      {showFilters && <TableListHeader>{renderDateDrop()}</TableListHeader>}

      <TableList
        {...get(props, 'tableProps', {})}
        loading={loading}
        columns={columns}
        canSelectRows={false}
        data={(state.filtered !== null ? state.filtered : data) as never[]}
        pageSize={100}
        subtractHeight={270}
        includeCount="Events"
        countExtra={
          showFilters
            ? `Unique Users: ${state.uniqUsers} - Unique Equipment: ${state.uniqEq}`
            : undefined
        }
      />
    </div>
  );
};
