import axios from 'axios';
import getConfig from '../../config';
import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { Serial } from '../interfaces/serial';
import { instance } from './instance';

const config = getConfig();

export const getSerial = async (id: string) => {
  try {
    const resp = await axios.get(
      `https://api.particle.io/v1/serial_numbers/${id}?access_token=${config.particle_key}`
    );
    const data = get(resp, 'data', {});
    return {
      ...data,
      serial: id,
      deviceId: get(data, 'device_id'),
    };
  } catch (err) {
    return undefined;
  }
};

export const getSerials = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/serial`, {
      where,
    });
  } else {
    resp = await instance.get(`/serial`, { data: {} });
  }
  return get(resp, 'data.serials', []) as Serial[];
};

export const createSerial = async (serial: Serial) => {
  const resp = await instance.post('/serial/create', {
    serial: removeEmpty(serial),
  });
  return get(resp, 'data.serial', null) as Serial;
};

export const updateSerial = (serial: Serial) => {
  return createSerial(serial);
};

export const deleteSerial = async (serial: Serial) => {
  const resp = await instance.delete(`/serial/${serial.serial}`);
  return get(resp, 'data.serial', null) as Serial;
};
