import { get } from 'lodash';
import { Equipment, EquipmentRecord } from '../interfaces/equipment';
import {
  parseResult,
  removeEmpty,
  userHasRole,
  wait,
} from '../../utils';
import { store } from '../../app.component';
import { User } from '../interfaces/user';
import { instance } from './instance';

export const getEquipment = async (id?: string): Promise<Equipment> => {
  const resp = await instance.get(`/equipment/id/${id}`, { data: {} });
  return get(resp, 'data.equipment', null) as Equipment;
};

export const getEquipmentRecord = async (id: string, fullStatus = false) => {
  const resp = await instance.get(`/equipment/record/${id}${fullStatus ? '?fullStatus=true' : ''}`, { data: {} });
  return get(resp, 'data.equipment', null) as EquipmentRecord;
};

export const getEquipments = async (
  where?: {},
  filterOutShowOnDash = true,
  fromState = true
): Promise<Equipment[]> => {
  if (fromState) {
    const { dash } = store.getState();

    if (get(dash, 'view.equipmentLoading')) {
      await wait(1000);
      return (await getEquipments(
        where,
        filterOutShowOnDash,
        fromState
      )) as Equipment[];
    }

    const filtered = filterOutShowOnDash
      ? dash.equipment.filter(e => get(e, 'showOnDash'))
      : dash.equipment;
    return where ? parseResult(where, filtered) : filtered;
  }

  let resp = {};
  if (where && Object.keys(where).length > 0) {
    resp = await instance.post(`/equipment`, {
      where,
    });
  } else {
    resp = await instance.get(`/equipment`);
  }
  let eq = get(resp, 'data.equipment', []) as Equipment[];
  return ((filterOutShowOnDash
    ? eq.filter(e => get(e, `showOnDash`))
    : eq) as unknown) as Equipment[];
};

export const getEquipmentForUser = async (user?: User) => {
  if (!user) {
    return [];
  }

  const isSuper = userHasRole([0, 5], store.getState());

  return await getEquipmentRecordsForUser(user.userId, !isSuper);
};

export const getEquipmentRecordsForUser = async (
  userId: string,
  filterOutShowOnDash = false
) => {
  // const resp = { data: require('../../assets/response.json') }; //use to prevent long request
  const resp = await instance.get(`/equipment/foruser/${userId}`);
  let eq = (get(resp, 'data.equipment', []) as EquipmentRecord[]);
  return ((filterOutShowOnDash
    ? eq.filter(e => get(e, `showOnDash`))
    : eq) as unknown) as EquipmentRecord[];
};

export const getEquipmentRecords = async (
  where?: {},
  filterOutShowOnDash = true
) => {
  let resp = {};
  if (where && Object.keys(where).length > 0) {
    resp = await instance.post(`/equipment/record`, {
      where,
    });
  } else {
    resp = await instance.get(`/equipment/record`);
  }
  let eq = get(resp, 'data.equipment', []) as EquipmentRecord[];
  return ((filterOutShowOnDash
    ? eq.filter(e => get(e, `equipment.showOnDash`))
    : eq) as unknown) as EquipmentRecord[];
};

export const createEquipment = async (equipment: Equipment) => {
  const resp = await instance.post('/equipment/create', {
    equipment: removeEmpty(equipment),
  });
  return get(resp, 'data.equipment', null) as Equipment;
};

export const updateEquipment = (equipment: Equipment) => {
  return createEquipment(equipment);
};

export const deleteEquipment = async (equipment: Equipment) => {
  const resp = await instance.delete(`/equipment/id/${equipment.sk}`);
  return get(resp, 'data.equipment', null) as Equipment;
};

export const getEquipmentBySN = async (sn: string) => {
  const resp = await instance.get(`/equipment/sn/${sn}`, { data: {} });
  return get(resp, 'data.equipment', null) as EquipmentRecord;
}