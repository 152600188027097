import { get } from 'lodash';
import { store } from '../../app.component';
import { instance } from './instance';

export const eventTypes = {
  log_in: 'log_in',
  forgot_password: 'forgot_password',
  log_out: 'log_out',
  search_equipment: 'search_equipment',
  dash_apply_filter: 'dash_apply_filter',
  eq_view: 'eq_view',
  eq_toggle_status: 'eq_toggle_status',
  eq_request_data: 'eq_request_data',
  eq_sensor_history: 'eq_sensor_history',
  eq_sensor_history_timeframe: 'eq_sensor_history_timeframe',
  eq_alert_details: 'eq_alert_details',
  eq_alert_clear: 'eq_alert_clear',
  eq_alert_history: 'eq_alert_history',
  eq_service_view: 'eq_service_view',
  eq_service_add: 'eq_service_add',
  eq_service_update: 'eq_service_update',
  eq_motorhead_toggle: 'eq_motorhead_toggle',
  eq_replace_controller: 'eq_replace_controller',
  eq_cycle_towers: 'eq_cycle_towers',
  eq_cycle_autowash: 'eq_cycle_autowash',
  eq_debug: 'eq_debug',
  eq_vis_toggle: 'eq_vis_toggle',
  eq_update: 'eq_update',
  sched_update_toggle: 'sched_update_toggle',
  sched_add: 'sched_add',
  sched_update: 'sched_update',
  sched_delete: 'sched_delete',
  user_add: 'user_add',
  user_update: 'user_update',
  user_delete: 'user_delete',
  group_add: 'group_add',
  group_update: 'group_update',
  group_delete: 'group_delete',
  tandem_add: 'tandem_add',
  tandem_update: 'tandem_update',
  tandem_delete: 'tandem_delete',
  org_add: 'org_add',
  org_update: 'org_update',
  org_delete: 'org_delete',
  location_add: 'location_add',
  location_update: 'location_update',
  location_delete: 'location_delete',
  eq_transfer: 'eq_transfer',
  user_notifications: 'user_notifications',
};

export const getPrettyName = (e: string) => {
  const names = {
    log_in: 'Log In',
    forgot_password: 'Forgot Password',
    log_out: 'Log Out',
    search_equipment: 'Search Equipment',
    dash_apply_filter: 'Dashboard Filters',
    eq_view: 'View Equipment Details',
    eq_toggle_status: 'Update Equipment Status',
    eq_request_data: 'Request Sensor Data',
    eq_sensor_history: 'View Sensor History',
    eq_sensor_history_timeframe: 'Update Timeframe for Sensor History',
    eq_alert_details: 'View Alert Details',
    eq_alert_clear: 'Clear Alert',
    eq_alert_history: 'View Alert History',
    eq_service_view: 'View Service Record',
    eq_service_add: 'Add Service Record',
    eq_service_update: 'Update Service Record',
    eq_motorhead_toggle: 'Toggle Motorhead',
    eq_replace_controller: 'Replace Aeras Controller',
    eq_cycle_towers: 'Cycle Dryer Towers',
    eq_cycle_autowash: 'Cycle Autowash',
    eq_debug: 'Enable Debug Mode',
    eq_vis_toggle: 'Update Show in Web App',
    eq_update: 'Update Equipment Info',
    sched_update_toggle: 'Update Equipment Schedule',
    sched_add: 'Add Scheduled Time',
    sched_update: 'Update Scheduled Time',
    sched_delete: 'Delete Scheduled Time',
    user_add: 'Invite User',
    user_update: 'Update User',
    user_delete: 'Delete User',
    group_add: 'Add Group',
    group_update: 'Update Group',
    group_delete: 'Delete Group',
    tandem_add: 'Add Tandem Group',
    tandem_update: 'Update Tandem Group',
    tandem_delete: 'Delete Tandem Group',
    org_add: 'Add Service / Dental Org',
    org_update: 'Update Service / Dental Org',
    org_delete: 'Delete Service / Dental Org',
    location_add: 'Add Location',
    location_update: 'Update Location',
    location_delete: 'Delete Location',
    eq_transfer: 'Transfer Equipment',
    user_notifications: 'Update Notification Settings',
  };
  return get(names, e, 'N/A');
};

export const trackEvent = async (event: string, data = {}, _opts = {}) => {
  const opts = {
    supressError: true,
    ..._opts,
  };

  const sState = store.getState();
  const user = get(sState, 'auth.user', get(data, 'user'));

  return await instance
    .post('/event', {
      user,
      event,
      data,
    })
    .catch(e => {
      if (!opts.supressError) {
        throw e;
      } else {
        console.error(e);
      }
    });
};

export const getEvents = async (events: string[] = [], filters = {}) => {
  try {
    const resp = await instance.post('/event/get', {
      events,
      filters,
    });
    return get(resp, 'data.data', []);
  } catch (err) {
    const message = get(err, 'response.data.error', err.message);
    throw new Error(message);
  }
};

export const getNoteEvents = async (events: string[] = [], filters = {}) => {
  try {
    const resp = await instance.post('/event/getnotifications', {
      events,
      filters,
    });
    return get(resp, 'data.data', []);
  } catch (err) {
    const message = get(err, 'response.data.error', err.message);
    throw new Error(message);
  }
};

export const getAlertEvents = async (filters = {}) => {
  try {
    const resp = await instance.post('/event/getalerts', {
      filters,
      ignore0: true,
    });
    return get(resp, 'data.data', []);
  } catch (err) {
    const message = get(err, 'response.data.error', err.message);
    throw new Error(message);
  }
};
