import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: -20px;
  `,
  rowMargin: `
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  `,
  hr: `
    width: 100%;
    height: 2px;
    background-color: ${colors.hrBorder};
    margin-bottom: 20px;
  `,
  hr2: `
    width: 100%;
    height: 1px;
    background-color: ${colors.hrBorder};
    margin-bottom: 20px;
    margin-top: 20px;
  `,
};
