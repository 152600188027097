/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, MouseEvent } from 'react';
import Content from './forgot-password.content';
import { getContent, buildErrorMsgFromForm, isPB } from '../../utils';
import { Button } from '../../_shared/button';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import Link from '../../_shared/link';
import { generatePinAndSendMsgToUser } from '../../_shared/services/authentication.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { AppState } from '../../app.state';
import { get } from 'lodash';

interface IProps extends RouteComponentProps, FormComponentProps {
  appState: AppState;
  forgotPassword: (email: string) => Promise<{ CodeDeliveryDetails?: {} }>;
}
export class ForgotPasswordComponent extends Component<IProps> {
  state = {
    loading: false,
    error: null,
  };
  goToLogin = () => {
    this.props.history.push('/login');
  };
  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      try {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          return this.setState({
            error,
            loading: false,
          });
        }

        const email = values.email.toLowerCase().trim();
        trackEvent(eventTypes.forgot_password, { user: { userId: email } });

        const { pin } = await generatePinAndSendMsgToUser(email, false);

        this.props.history.push('/forgotPasswordSubmit', {
          email,
          pin,
          destination: get(this, 'props.location.state.destination')
        });
      } catch (err) {
        if (err.message && err.message === 'UserNotFoundException') {
          this.props.history.push('/forgotPasswordSubmit');
        } else {
          this.setState({
            error: err.message,
            loading: false,
          });
        }
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { error, loading } = this.state;
    const btnDisabled = !getFieldValue('email');
    const content = getContent(Content);
    const { appState } = this.props;
    const ispb = isPB(appState);

    return (
      <div className="ForgotPassword" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <p css={css(styles.title)}>{content.title}</p>
        <p css={css([styles.subTitle, ispb ? `padding: 20px;` : null])}>
          {content.subTitle}
        </p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <Form layout="vertical" onSubmit={this.submit}>
            <Form.Item label={content.emailLabel}>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail. ',
                    transform: v => v.trim(),
                  },
                  {
                    required: true,
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    max: 60,
                    message: 'Email is limited to 60 characters. ',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item>
              <div css={css(styles.buttonContainer + `margin-top: 20px;`)}>
                <Button
                  loading={loading}
                  title={content.button}
                  onClick={this.submit}
                  disabled={btnDisabled}
                />

                <Link
                  css={css`
                    margin-top: 40px;
                  `}
                  onClick={this.goToLogin}
                >
                  {content.link}
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const ForgotPassword = Form.create()(
  withRouter(ForgotPasswordComponent)
);
