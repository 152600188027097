import colors from '../../_shared/colors';

export const GlobalInserts = `
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom: 5px solid ${colors.highlight};
  }

  .ant-menu-horizontal > .ant-menu-item {
    font-size: 14px;
  }
`;

export const ellipsis = {
  container: `
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 10px;
  `,
  dropContainer: `
    margin-right: 10px;
  `,
  a: `
    transform: rotate(90deg);
    color: ${colors.black};
    font-size: 30px;
    font-weight: bold;
  `,
  menuItem: `
    font-size: 14px;
  `,
  menuIcon: `
    margin-right: 10px;
  `,
};

export default {
  container: `
    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: ${colors.lightBackground};
    line-height: 48px;
    padding: 0;
    border-bottom: 2px solid ${colors.inputBorder};
  `,
  search: `
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;

    .ant-input-wrapper.ant-input-group {
      max-width: 500px;
    }
  `,
};
