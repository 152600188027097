/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import { getUsersName, formatPhoneNumber, userHasRole } from '../../utils';
import { User } from '../../_shared/interfaces/user';
import { Tag, Alert, Modal } from 'antd';
import colors from '../../_shared/colors';
import { AppState } from '../../app.state';
import { get, chain, sortBy } from 'lodash';
import styles from './manage-user.styles';
import { Location } from '../../_shared/interfaces/location';
import { getLocations } from '../../_shared/services/manage-locations.service';
import { TableListMulti } from '../../_shared/table-list-multi';
import { getGroups } from '../../_shared/services/manage-groups.service';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import {
  getUsers,
  updateUser,
  deleteUser,
  getUser,
} from '../../_shared/services/manage-users.service';
import { Group } from '../../_shared/interfaces/group';
import { Equipment } from '../../_shared/interfaces/equipment';
import { Org } from '../../_shared/interfaces/org';
import { Emails } from '../../_shared/lib/emails';
import { sendEmail } from '../../_shared/services/email.service';
import { ActiveSwitch } from '../../_shared/active-switch/active-switch.component';
import { NotificationSettings } from '../notification-settings';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { ReportingEvents } from '../reporting';
import FormTitle from '../../_shared/form-title';

interface IProps extends RouteComponentProps {
  sState: AppState;
}

const LocColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '40%',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    width: '20%',
  },
  {
    title: 'Equipment',
    dataIndex: 'eqCount',
    width: '20%',
  },
  {
    title: 'Users',
    dataIndex: 'uCount',
    width: '20%',
  },
];

const GroupColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '40%',
  },
  {
    title: 'Equipment',
    dataIndex: 'eqCount',
    width: '30%',
  },
  {
    title: 'Users',
    dataIndex: 'uCount',
    width: '30%',
  },
];

const EquipColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '30%',
  },
  {
    title: 'Model ID',
    dataIndex: 'modelId',
    width: '30%',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    width: '30%',
  },
  {
    title: 'Users',
    dataIndex: 'uCount',
    width: '10%',
  },
];

interface UECounts {
  [key: string]: { _ucounts: number; _ecounts: number };
}

export class _ManageuserComponent extends Component<IProps> {
  state = {
    isEdit: false,
    error: undefined,
    success: undefined,
    user: undefined,
    loading: true,
    locations: [],
    allLocations: [],
    groups: [],
    allGroups: [],
    equipment: [],
    allEquipment: [],
    userOrg: undefined,
    show: true,
  };
  toggleActive = async () => {
    this.setState({ loading: true });

    const isActive = get(this.state.user, 'isActive', 0);

    const user = {
      ...(this.state.user || {}),
      isActive: isActive === 0 ? 1 : 0,
    } as User;

    try {
      if (user.userId) {
        await updateUser(user);
        this.setState({
          loading: false,
          user,
        });
      }
    } catch (err) {
      this.setState({
        loading: false,
        error: err.message,
      });
    }
  };

  componentDidMount = () => {
    const {
      sState: { auth },
      history: { location },
    } = this.props;
    const user = get(location, 'state.user')
      ? get(location, 'state.user')
      : get(auth, 'user');
    this.setState({ user }, () => this.retrieveData());
  };

  retrieveData = async (res?: { error?: string }) => {
    if (res && res.error) {
      return this.setState({ error: res.error });
    }

    const { sState } = this.props;

    const user = await getUser(get(this, 'state.user.userId'));
    if (user) {
      const uState = { auth: { user } } as AppState;
      const userIsAdminOrTech = userHasRole([0, 1], uState);
      const userIsOrgAdmin = userHasRole([2], uState);

      this.setState({ loading: true, show: false }, () =>
        this.setState({
          show: userIsAdminOrTech || userIsOrgAdmin ? false : true,
        })
      );

      const sort = (
        a: Location | Group | Equipment,
        b: Location | Group | Equipment
      ) => {
        const textA = (a.name || '').toUpperCase();
        const textB = (b.name || '').toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      };

      const locations = await getLocations();
      let groups = await getGroups();
      groups = sortBy(groups, ['name', 'id']);
      const equipment = await getEquipments();
      const users = await getUsers();
      const models = get(sState, 'dash.models');

      const locationsMap = chain(locations)
        .keyBy('id')
        .value();
      const groupsMap = chain(groups)
        .keyBy('id')
        .value();
      const equipmentMap = chain(equipment)
        .keyBy('id')
        .value();
      const modelMap = chain(models)
        .keyBy('id')
        .value();

      const countUsersAndGrpsinEquip = (
        items: string[],
        map: UECounts,
        eItems: string[]
      ) => {
        Array.isArray(items) &&
          items.map(item => {
            let lMap = map[item];

            if (!lMap || lMap._ucounts === undefined) {
              lMap = map[item] = {
                ...(lMap || {}),
                _ucounts: 0,
                _ecounts: 0,
              };
            }

            if (lMap && typeof lMap._ucounts === 'number') {
              lMap._ucounts += 1;
            }

            Array.isArray(eItems) &&
              eItems.map(e => {
                if (lMap && typeof lMap._ecounts === 'number') {
                  lMap._ecounts += 1;
                }
              });
          });
      };

      const countUsersInGrpsAndLocs = (items: string[], map: UECounts) => {
        Array.isArray(items) &&
          items.map(item => {
            let lMap = map[item];
            if (!lMap || lMap._ucounts === undefined) {
              lMap = map[item] = {
                ...(lMap || {}),
                _ucounts: 0,
                _ecounts: 0,
              };
            }
            if (lMap && typeof lMap._ucounts === 'number') {
              lMap._ucounts += 1;
            }
          });
      };
      const countEquipInGrpsAndLocs = (
        items: string[],
        map: UECounts,
        key: 'groups' | 'locationId'
      ) => {
        Array.isArray(items) &&
          items.map((item: string) => {
            let lMap = map[item];
            Array.isArray(equipment) &&
              equipment.map(e => {
                const eItem = (e[key] as unknown) as string[] | string;
                if (
                  lMap &&
                  typeof lMap._ecounts === 'number' &&
                  eItem &&
                  eItem.includes(item)
                ) {
                  lMap._ecounts += 1;
                }
              });
          });
      };

      users.map(u => {
        countUsersInGrpsAndLocs(
          u.locations,
          (locationsMap as unknown) as UECounts
        );
        countUsersInGrpsAndLocs(u.groups, (groupsMap as unknown) as UECounts);
        countUsersAndGrpsinEquip(
          u.equipment,
          (equipmentMap as unknown) as UECounts,
          u.locations
        );
      });

      countEquipInGrpsAndLocs(
        user.groups,
        (groupsMap as unknown) as UECounts,
        'groups'
      );
      countEquipInGrpsAndLocs(
        user.locations,
        (locationsMap as unknown) as UECounts,
        'locationId'
      );

      const uLocations = locations
        .filter(
          l =>
            user.locations &&
            user.locations.indexOf &&
            user.locations.indexOf(l.id) > -1
        )
        .sort(sort)
        .map(l => {
          return {
            ...l,
            uCount: locationsMap[l.id]._ucounts || 0,
            eqCount: locationsMap[l.id]._ecounts || 0,
          };
        });

      const uGroups = groups
        .filter(l => user.groups && user.groups.indexOf(l.id) > -1)
        .sort(sort)
        .map(l => {
          return {
            ...l,
            uCount: groupsMap[l.id]._ucounts || 0,
            eqCount: groupsMap[l.id]._ecounts || 0,
          };
        });

      const uEquip = equipment
        .filter(
          l => user.equipment && user.equipment.indexOf(l.id as string) > -1
        )
        .sort(sort)
        .map((l: Equipment) => {
          const loc = locationsMap[l.locationId || ''] as Location;
          return {
            ...l,
            location: loc ? loc.name : 'None',
            model: get(modelMap, `${l.modelId}.name`, 'None'),
            uCount: equipmentMap[l.id as string]._ucounts || 0,
            eqCount: equipmentMap[l.id as string]._ecounts || 0,
          };
        });

      this.setState({
        user,
        userOrg: get(sState, 'dash.orgs', []).find(
          (o: Org) => o.id === user.orgId
        ),
        locations: uLocations,
        allLocations: locations.filter(l => l.orgId === user.orgId),
        groups: uGroups,
        allGroups: groups.filter(l => l.orgId === user.orgId),
        equipment: uEquip,
        allEquipment: equipment.filter(
          l => l.serviceOrgId === user.orgId || l.dentalOrgId === user.orgId
        ),
        loading: false,
      });
    }
  };

  resendInvite = async () => {
    try {
      const emailAddress = get(this.state, 'user.email', '').toLowerCase();
      const email = Emails.invite({ ToAddresses: [emailAddress] });

      await sendEmail(email).then(() => {
        this.setState({ success: `Invitation email sent to ${emailAddress}` });
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        error: 'Unable to resend invitation email. Check user record.',
      });
    }
  };

  toggleEdit = () => {
    this.props.history.push('/dashboard/accountDetails', {
      user: this.state.user,
      editMode: true,
      back: `< Back to ${getUsersName((this.state.user as unknown) as User)}`,
    });
  };
  renderUserInfo = () => {
    const {
      sState: { dash, auth },
    } = this.props;
    const { user } = this.state;

    const items = [
      {
        title: 'Organization name',
        var: 'orgId',
        toText: (id: string) =>
          (dash.orgs.find(o => o.id === id) || { name: 'none' }).name,
      },
      {
        title: 'Branch Location',
        var: 'branchLocation',
        toText: (s: string) => s,
      },
      {
        title: 'Role',
        var: 'role',
        toText: (id: number) =>
          (auth.allRoles.find(o => o.id === id) || { name: 'none' }).name,
      },
      {
        title: 'Email',
        var: 'email',
        toText: (id: string) => id,
      },
      {
        title: 'Phone',
        var: 'phone',
        toText: (id: string) => formatPhoneNumber(id),
      },
    ];

    return (
      <span css={css(`font-size: 12px;`)}>
        {items
          .filter((item, _i) => {
            const hasVal = get(user, item.var);
            const val = hasVal && item.toText(hasVal);
            return val;
          })
          .map((item, i) => {
            const hasVal = get(user, item.var);
            const val = hasVal && item.toText(hasVal);
            return (
              <span key={i}>
                {i > 0 ? (
                  <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</span>
                ) : null}
                {`${item.title}: ${val}`}
              </span>
            );
          })}
      </span>
    );
  };

  _deleteUser = async () => {
    const userId = get(this.state, 'user.sk');

    const delUser = async () => {
      try {
        await deleteUser(userId);
        trackEvent(eventTypes.user_delete, {
          user_deleted: this.state.user,
          org: this.state.userOrg,
        });
        this.props.history.goBack();
      } catch (error) {
        console.warn(error);
        this.setState({ error: 'There was a problem deleting the user' });
      }
    };
    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Delete User',
        content: 'Are you sure you want to delete this user?',
        onOk: delUser,
        okText: 'Delete',
      });
    };
    showConfirmModal();
  };

  render() {
    const { sState } = this.props;
    const {
      user,
      loading,
      locations,
      allLocations,
      groups,
      allGroups,
      equipment,
      allEquipment,
      show,
      error,
      success,
      userOrg,
    } = this.state;
    const usr = user || ({} as User);
    const active = usr.isActive > 0 ? true : false;

    const iAmSuper = userHasRole([0], sState);
    const iAmTech = userHasRole([1], sState);
    const uState = { auth: { user: usr } } as AppState;
    const userIsSuperOrTech = userHasRole([0, 1], uState);
    const isEmailVerified = get(this.state, 'user.email_verified');
    const canShowEdit = iAmSuper
      ? true
      : iAmTech && !userIsSuperOrTech
      ? true
      : userHasRole([0, 1, 2], sState) &&
        usr.role >= (sState.auth.user || { role: 4 }).role;

    const uOrgIsService = get(userOrg, 'orgType') === 0;

    return !usr.userId ? null : (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link onClick={() => this.props.history.goBack()}>
            {'< Back to Manage Users'}
          </Link>

          <ActiveSwitch
            canShowEdit={canShowEdit}
            active={active}
            loading={loading}
            toggleActive={this.toggleActive}
          />
        </div>
        <div
          css={css`
            margin-left: auto;
          `}
        >
          {canShowEdit && (
            <div
              css={css(`
              display: flex; 
              flex-direction: row; 
              float: right; 
              margin-left: 10px;`)}
            >
              {!active && !isEmailVerified && (
                <Button
                  css={css('margin-right: 10px;')}
                  title="Resend Invitation"
                  onClick={this.resendInvite}
                />
              )}
              {(iAmSuper || iAmTech) && (
                <Button
                  css={css(`
                      background-color: ${colors.error};
                      border: solid 1px ${colors.error};
                      margin-right: 10px;
                      `)}
                  title="Delete"
                  onClick={this._deleteUser}
                />
              )}
              <Button
                title="Edit"
                css={css('float: right')}
                onClick={this.toggleEdit}
              />
            </div>
          )}
        </div>

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SharedStyles.row, styles.rowMargin2)}>
          <TableTitleHeader>{getUsersName(usr)}</TableTitleHeader>
          <Tag
            css={css`
              margin-left: 10px;
            `}
            color={active ? colors.success : colors.error}
          >
            {active ? 'Active' : 'Not Active'}
          </Tag>
          <Button
            size="small"
            css={css('margin-left: 10px;')}
            loading={loading}
            title="Refresh"
            onClick={() => this.retrieveData()}
          />
        </div>

        <div css={css(SharedStyles.row, styles.rowMargin2)}>
          {this.renderUserInfo()}
        </div>

        {show && (
          <div>
            {!uOrgIsService && (
              <TableListMulti
                data={locations}
                loading={loading}
                columns={LocColumns}
                title={'Locations'}
                variable={'locations'}
                addBtn={'Add Locations'}
                allOpts={allLocations}
                totalTitle={(count: number) => `Total Locations: ${count}`}
                user={usr}
                blankText={'User is not associated with any Locations'}
                onDone={this.retrieveData}
              />
            )}

            <TableListMulti
              data={groups}
              loading={loading}
              columns={GroupColumns}
              variable={'groups'}
              title={'Groups'}
              addBtn={'Add Groups'}
              allOpts={allGroups}
              totalTitle={(count: number) => `Total Groups: ${count}`}
              user={usr}
              blankText={'User is not associated with any Groups'}
              onDone={this.retrieveData}
            />

            <TableListMulti
              data={equipment}
              loading={loading}
              columns={EquipColumns}
              title={'Equipment'}
              variable={'equipment'}
              addBtn={'Add Equipment'}
              allOpts={allEquipment}
              totalTitle={(count: number) => `Total Equipment: ${count}`}
              user={usr}
              blankText={'User is not associated with any Equipment'}
              onDone={this.retrieveData}
            />
          </div>
        )}
        {(iAmSuper || iAmTech) && (
          <div>
            <div css={css(SharedStyles.hr, styles.rowMargin)} />
            <div css={css(SharedStyles.row)}>
              <FormTitle title={`User Events`} />
              <Link
                css={css(`
                  margin-left: auto;
                  font-weight: bold;
                `)}
                onClick={() =>
                  this.props.history.push('/dashboard/reporting', { user })
                }
              >
                <span>{'Filter Events'}</span>
              </Link>
            </div>
            <ReportingEvents
              user={user}
              tableProps={{ scrollHeight: 180, size: 'small' }}
              showFilters={false}
            />
          </div>
        )}
        {userHasRole([0, 1, 2], sState) && (
          <div>
            <div css={css(SharedStyles.hr, styles.rowMargin)} />
            <NotificationSettings _user={user} />
          </div>
        )}
      </div>
    );
  }
}

export const ManageUserComponent = withRouter(_ManageuserComponent);
