/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import SharedStyles from '../styles';
import FormTitle from '../form-title';
import { TableList } from '../table-list';
import Link from '../link';
import { take } from 'lodash';
import { ColumnProps } from 'antd/lib/table';

interface IProps<T> {
  data: T[];
  columns: ColumnProps<T>[];
  loading: boolean;
  addBtn: string;
  title: string;
  blankText?: string;
  totalTitle: (val: number) => string;
  onBtnClick: () => void;
  // tslint:disable-next-line:no-any
  rowClick?: (row: any) => void;
  take?: number;
}

interface IState {
  loading: boolean;
}

export class TableListSimple<T> extends Component<IProps<T>, IState> {
  constructor(props: IProps<T>) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  render() {
    const {
      data,
      loading,
      columns,
      addBtn,
      totalTitle,
      title,
      blankText,
      onBtnClick,
      rowClick,
    } = this.props;

    const showBlank = !loading && data.length === 0 && blankText;

    return (
      <div>
        <div css={css(SharedStyles.hr)} />

        <div css={css(SharedStyles.row, `height: 30px;`)}>
          <FormTitle
            css={css`
              margin-bottom: 0;
            `}
            title={title}
          />
          <Link
            css={css(`
                margin-left: auto;
                font-weight: bold;
              `)}
            onClick={onBtnClick}
          >
            <span>{addBtn}</span>
          </Link>
        </div>
        {!showBlank && (
          <TableList
            columns={columns}
            canSelectRows={false}
            rowClick={rowClick}
            data={data}
            loading={this.state.loading || loading}
            scrollHeight={data.length > 5 ? 180 : undefined}
            showPagination={false}
          />
        )}
        {showBlank && (
          <div
            css={css(
              SharedStyles.row,
              `font-size: 14px;
          font-style: italic;
          margin: 10px 0px;
          justify-content: center;
          alignItems: center;
          text-align: center;
          min-height: 50px;
          padding: 20px;
          `
            )}
          >
            {blankText}
          </div>
        )}
        {!showBlank && (
          <div
            css={css`
              font-size: 12px;
              font-style: italic;
              margin: 10px 0px;
            `}
          >
            {totalTitle(data && data.length ? data.length : 0)}
          </div>
        )}
      </div>
    );
  }
}
