/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, ReactNode } from 'react';
import { AppState } from '../../app.state';
import { User, _User } from '../../_shared/interfaces/user';
import {
  Form,
  Input,
  Alert,
  Modal,
  message,
  DatePicker,
  Checkbox,
  Select,
} from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import styles from './edit-org.styles';
import { Button } from '../../_shared/button';
import {
  buildErrorMsgFromForm,
  uppercaseFirst,
  userHasRole,
  uppercaseWords,
  validPhoneNumber,
  sortByName,
  getMyOrg,
  hasSubHistory,
  setTrialSub,
  setPayedSub,
  cleanPhoneNumber,
  isHandpiece,
  cleanCopy,
} from '../../utils';
import Link from '../../_shared/link';
import { get, set, flatten, chain, uniqBy, sortBy, size } from 'lodash';
import { Org, _Org } from '../../_shared/interfaces/org';
import NumberedHeader from '../../_shared/numbered-header';
import SharedStyles from '../../_shared/styles';
import {
  getEquipments,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import { TableTransfer } from '../../_shared/table-transfer';
import { Equipment } from '../../_shared/interfaces/equipment';
import { getModels } from '../../_shared/services/manage-models.service';
import { _Model, Model } from '../../_shared/interfaces/model';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import {
  createOrg,
  getOrgs,
  updateOrg,
} from '../../_shared/services/manage-orgs.service';
import {
  getUser,
  createUser,
  getUsers,
  updateUser,
} from '../../_shared/services/manage-users.service';
import { Emails } from '../../_shared/lib/emails';
import { sendEmail } from '../../_shared/services/email.service';
import React from 'react';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { _Location } from '../../_shared/interfaces/location';
import { createLocation } from '../../_shared/services/manage-locations.service';
import { Opt } from '../../_shared/interfaces/opt';
import { SelectValue } from 'antd/lib/select';
import { DentalOrgType } from '../../_shared/interfaces/dentalOrgType';
import { getAppSettings } from '../../_shared/services/manage-app-settings.service';
import { resetHandpiecePresets } from '../../_shared/services/handpiece-profiles.service';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { StatesSelect } from '../../_shared/lib/states';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateUser: (user: User) => Promise<User>;
  getUserData: () => Promise<{}>;
  updateOrgs: () => void;
  updateLocations: () => void;
}

interface TDisplay {
  title?: string;
  var: string;
  style?: string;
  canDisplay?: () => boolean;
  transform?: (value: string) => string;
  options?: GetFieldDecoratorOptions;
  data?: () => string[];
  opts?: Opt[] | (() => Opt[]);
  type?: string;
  value?: string;
  checked?: boolean;
  label?: string;
  subText?: (item: TDisplay) => string;
  onChange?: (e: CheckboxChangeEvent | FormEvent) => void;
  onSelect?: (value: SelectValue) => void;
  disabled?: boolean;
  props?: {};
}

export class _EditOrgComponent extends Component<IProps> {
  // tslint:disable-next-line: no-any
  state: any = {
    loading: false,
    error: null,
    success: null,
    checked: true,
    org: undefined,
    orgType: undefined,
    back: undefined,
    canLeave: false,
    isAssignEquipment: false,
    nextLocation: '',
    locationName: '',
    screen: 0,
    equipment: [] as Equipment[],
    allEquipment: [] as Equipment[],
    selectedEquipment: [] as string[],
    originalSelectedEquip: [] as string[],
    allUsers: [] as User[],
    eMap: {} as { [key: string]: Equipment },
    trialDuration: undefined,
    toDisplay: [
      [
        {
          title: 'Service Org Name',
          var: 'name',
          canDisplay: () => get(this, 'state.orgType') === 0,
          transform: (value: string) => uppercaseWords(value),
          options: {
            preserve: true,
            rules: [
              {
                required: true,
                message: 'Service Org Name is required. ',
              },
              {
                max: 50,
                message: 'Service Org Name is limited to 50 characters. ',
              },
            ],
          },
        },
        {
          title: 'Dental Org Name',
          var: 'name',
          canDisplay: () => get(this, 'state.orgType') === 1,
          transform: (value: string) => uppercaseWords(value),
          options: {
            preserve: true,
            rules: [
              {
                required: true,
                message: 'Dental Org Name is required. ',
              },
              {
                max: 50,
                message: 'Dental Org Name is limited to 50 characters. ',
              },
            ],
          },
        },
        {
          title: 'Organization Type',
          var: 'doType',
          type: 'dropdown',
          canDisplay: () => get(this.state, 'orgType') === 1,
          opts: sortBy(
            get(this.props, 'sState.dash.dentalOrgTypes', []),
            'id'
          ).map((dot: DentalOrgType) => {
            return { label: dot.name, value: dot.value };
          }),
          options: {
            preserve: true,
            initialValue: 'dentalPractice',
            rules: [
              { required: true, message: 'Organization Type is required.' },
            ],
          },
        },
        {
          title: 'Account Number',
          var: 'accountNumber',
          canDisplay: () => userHasRole([0, 1], this.props.sState),
          transform: (value: string) => uppercaseWords(value),
          options: {
            preserve: true,
            rules: [
              {
                max: 20,
                message: 'Account Number limited to 20 characters. ',
              },
            ],
          },
        },
        {
          title: 'Service Org',
          var: 'serviceOrgId',
          type: 'dropdown',
          required: () => false,
          onSelect: (value: SelectValue) => {
            const orgId: string = value as string;
            this.retrieveEquipment(orgId);
          },
          canDisplay: () => {
            return (
              userHasRole([0, 1], this.props.sState) &&
              get(this, 'state.orgType') === 1
            );
          },
          transform: (v: string | number) => (v === -1 ? null : v),
          opts: [
            { name: 'No Service Org', id: -1, orgType: 0 },
            ...this.props.sState.dash.orgs.sort(sortByName),
          ]
            .filter(l => l.orgType === 0)
            .map(l => ({
              value: l.id,
              label: l.name || '',
            })),
          value: '',
          options: {
            preserve: true,
            rules: [
              {
                required: true,
                message: 'Service Org is required. ',
              },
            ],
          },
        },
        {
          title: 'Code',
          var: 'code',
          options: {
            preserve: true,
            rules: [
              {
                max: 10,
                message: 'Code is limited to 10 characters. ',
              },
            ],
          },
        },
        {
          title: 'Email',
          var: 'email',
          transform: (value: string) => (value || '').toLowerCase(),
          canDisplay: () =>
            get(this, 'state.orgType') === 0 ||
            (get(this, 'state.orgType') === 1 && this.state.org),
          options: {
            preserve: true,
            validateTrigger: 'onBlur',
            rules: [
              {
                type: 'email',
                message: 'The input is not a valid E-mail. ',
              },
            ],
          },
        },
        {
          title: 'Phone',
          var: 'phone',
          transform: (value: string) => cleanPhoneNumber(value),
          options: {
            preserve: true,
            validateTrigger: 'onBlur',
            rules: [
              {
                // tslint:disable-next-line:no-any
                validator: (rule: any, value: string, cb: any) => {
                  const passes = !value || validPhoneNumber(value);
                  cb(passes ? undefined : rule.message);
                },
                message: 'Phone needs to be a valid phone number. ',
              },
            ],
          },
        },
      ],
      [
        {
          title: 'Address',
          var: 'address.address',
          options: {
            preserve: true,
          },
        },
        {
          title: 'Address 2',
          var: 'address.address2',
          options: {
            preserve: true,
          },
        },
        {
          title: 'City',
          var: 'address.city',
          options: {
            preserve: true,
          },
        },
        {
          title: 'State',
          var: 'address.state',
          type: 'dropdown',
          opts: StatesSelect(),
          style: `
            float: left;
            width: 50%;
            padding-right: 5px;
          `,
          options: {
            preserve: true,
          },
        },
        {
          title: 'ZIP Code',
          var: 'address.zip',
          style: `
            float: left;
            width: 50%;
            padding-left: 5px;
          `,
          options: {
            preserve: true,
          },
        },
        {
          label: 'Add Primary Location at same address',
          type: 'checkbox',
          var: 'locationPlan.checked',
          options: {
            preserve: true,
            valuePropName: 'checked',
            initialValue: true,
          },
          style: `top: 35px; clear: both;`,
          canDisplay: () =>
            get(this, 'state.orgType') === 1 && !get(this, 'state.org'),
        },
        {
          title: 'Location Name',
          var: 'locationPlan.name',
          style: `top: 35px;`,
          canDisplay: () => {
            return (
              this.props.form.getFieldValue('locationPlan.checked') &&
              get(this, 'state.orgType') === 1 &&
              !get(this, 'state.org')
            );
          },
          // tslint:disable-next-line: no-any
          onChange: (e: any) => {
            this.setState({ locationName: e.target.value });
          },
          options: {
            validateTrigger: 'onBlur',
            initialValue: get(this, 'state.locationName', ''),
            rules: [
              {
                required: true,
                message:
                  'Location name is required if add location checkbox is checked.',
              },
              {
                max: 50,
                message: 'Location name is limited to 50 characters.',
              },
            ],
          },
        },
      ],
    ],

    toDisplay1: [
      {
        title: 'Service Org Purchase Order #',
        var: 'soPurchaseOrder',
        canDisplay: () => get(this, 'state.orgType') === 0,
        options: {
          preserve: true,
          rules: [
            {
              max: 50,
              message:
                'Service Org Purchase Order # is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Dental Org Purchase Order #',
        var: 'doPurchaseOrder',
        canDisplay: () => get(this, 'state.orgType') === 1,
        options: {
          preserve: true,
          rules: [
            {
              max: 50,
              message:
                'Dental Org Purchase Order # is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        var: 'onConsignment',
        type: 'checkbox',
        label: 'Equipment is on Consignment',
        canDisplay: () => get(this, 'state.orgType') === 0,
        options: {
          preserve: true,
          valuePropName: 'checked',
          initialValue: false,
        },
      },
    ] as TDisplay[],

    formValues: {},
    toDisplay2: [
      {
        label: 'Do not create Admin user',
        type: 'checkbox',
        var: 'adminUser.checked',
        options: {
          preserve: false,
          valuePropName: 'checked',
          initialValue: () =>
            get(this, 'state.orgType') === 1 &&
            this.state.selectedEquipment.length
              ? true
              : false,
        },
        style: `clear: both;`,
      },
      {
        title: 'First Name',
        var: 'adminUser.firstName',
        canDisplay: () => {
          return !this.props.form.getFieldValue('adminUser.checked');
        },
        transform: (value: string) => uppercaseFirst(value),
        onChange: e => {
          set(
            this,
            `state.formValues.adminUser.firstName`,
            get(e, 'target.value')
          );
        },
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Last Name',
        var: 'adminUser.lastName',
        canDisplay: () => !this.props.form.getFieldValue('adminUser.checked'),
        transform: (value: string) => uppercaseFirst(value),
        onChange: e => {
          set(
            this,
            `state.formValues.adminUser.lastName`,
            get(e, 'target.value')
          );
        },
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Email',
        var: 'adminUser.email',
        canDisplay: () => !this.props.form.getFieldValue('adminUser.checked'),
        transform: (value: string) => (value || '').toLowerCase(),
        onChange: e => {
          set(this, `state.formValues.adminUser.email`, get(e, 'target.value'));
        },
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid E-mail. ',
            },
            {
              required: true,
              message: 'The input is not a valid E-mail. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'adminUser.phone',
        canDisplay: () => !this.props.form.getFieldValue('adminUser.checked'),
        onChange: e => {
          set(this, `state.formValues.adminUser.phone`, get(e, 'target.value'));
        },
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
          ],
        },
      },
    ] as TDisplay[],
  };

  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      try {
        const {
          toDisplay,
          toDisplay1,
          toDisplay2,
          orgType,
          org,
          selectedEquipment,
        } = this.state;
        const { sState } = this.props;
        const me = new _User(get(sState, 'auth.user')) as User;
        const myOrg = getMyOrg(sState);
        const isEdit = org ? true : false;

        const originalOrg = cleanCopy(org);

        //get data plugged into necessary org and user
        const _org = new _Org(org) as Org;
        const user = new _User() as User;
        const _location = new _Location();

        flatten([...toDisplay, ...toDisplay1, ...toDisplay2] as TDisplay[]).map(
          td => {
            const v = td.var;
            const vVal = get(values, v, '');
            const val = td.transform ? td.transform(vVal) : vVal;

            if (val && v && v.startsWith('locationPlan')) {
              //all location values
              const key = v.replace('locationPlan.', '');
              set(_location, key, val);
            } else if (val && v && v.startsWith('adminUser')) {
              //all user values
              const key = v.replace('adminUser.', '');
              set(user, key, val);
            } else if (val) {
              //all org values
              set(_org, v, val);
            }
          }
        );

        const title = uppercaseFirst(this.getTitle());

        if (!isEdit) {
          const shouldCreateAdmin = !this.props.form.getFieldValue(
            'adminUser.checked'
          );
          const hasLocation = get(values, 'locationPlan.checked');

          if (shouldCreateAdmin) {
            try {
              const userExists = await getUser(user.email);
              if (userExists) {
                return this.setState({
                  error:
                    // tslint:disable-next-line:max-line-length
                    'That user already exists. Please use a different email. ',
                  loading: false,
                });
              }
            } catch (err) {}
          }

          if (myOrg && myOrg.orgType === 0) {
            _org.serviceOrgId = myOrg.id;
          }

          if (hasLocation && orgType === 1) {
            set(_location, 'orgId', _org.id);
            set(_location, 'address', {
              address: values.address.address,
              zip: values.address.zip,
              state: values.address.state,
              city: values.address.city,
              address2: values.address.address2,
            });
            set(_location, 'name', this.state.locationName);
            set(_location, 'createdBy', me.userId);
            set(_location, 'updatedBy', me.userId);
            await createLocation(_location).then(() =>
              this.props.updateLocations()
            );
          }

          if (shouldCreateAdmin) {
            user.role = 2;
            user.userId = user.email;
            user.createdBy = me.userId;
            user.updatedBy = me.userId;
            user.orgId = _org.id;
            const Email = Emails.invite({ ToAddresses: [user.email] });
            await sendEmail(Email);
            await createUser(user);
          }
          _org.orgType = orgType;
          _org.updatedBy = me.userId;
          _org.createdBy = me.userId;

          this.addEquipment(values, _org, _location);
          //these belong on the other properties or objects
          delete _org.soPurchaseOrder;
          delete _org.doPurchaseOrder;
          delete _org.subscriptionStart;
          delete _org.trialStart;
          delete _org.billingPreference;
          delete _org.addSubscription;
          delete _org.locationPlan;
          await createOrg(_org).then(() => {
            this.props.updateOrgs();
          });

          message.success(`${title} Organization created Successfully`);
          trackEvent(eventTypes.org_add, { org: _org });
        } else if (isEdit && this.state.isAssignEquipment) {
          _org.updatedAt = new Date().toISOString();
          _org.updatedBy = me.userId;

          const added = await this.addEquipment(values, _org);
          const removed = await this.removeEquipment();
          if (size(added) || size(removed)) {
            trackEvent(eventTypes.eq_transfer, { added, removed });
          }
          //these belong on the other properties or objects
          delete _org.soPurchaseOrder;
          delete _org.doPurchaseOrder;
          delete _org.subscriptionStart;
          delete _org.trialStart;
          delete _org.billingPreference;
          delete _org.addSubscription;
          delete _org.locationPlan;
          await updateOrg(_org).then(() => this.props.updateOrgs());

          message.success(`${title} Organization updated Successfully`);
        } else if (isEdit && !this.state.isAssignEquipment) {
          const acctNum = values.accountNumber;
          const hasDup = await this.checkForDupAcctNum(acctNum);
          if (hasDup) {
            return this.setState({ loading: false });
          }
          if (
            _org.orgType === 1 &&
            _org.serviceOrgId !== get(this.state, '_org.serviceOrgId')
          ) {
            const { eMap } = this.state;
            selectedEquipment.forEach((id: string) => {
              const e = eMap[id];
              set(e, 'updatedAt', moment().toISOString());
              set(e, 'updatedBy', me.userId);
              set(e, 'serviceOrgId', _org.serviceOrgId);
              updateEquipment(e);
            });
          }
          _org.updatedAt = new Date().toISOString();
          _org.updatedBy = me.userId;
          //these belong on the other properties or objects
          delete _org.soPurchaseOrder;
          delete _org.doPurchaseOrder;
          delete _org.subscriptionStart;
          delete _org.trialStart;
          delete _org.billingPreference;
          delete _org.addSubscription;
          delete _org.locationPlan;
          await updateOrg(_org).then(() => this.props.updateOrgs());
          trackEvent(eventTypes.org_update, { old: originalOrg, new: _org });
        }
        this.setState(
          {
            loading: false,
          },
          () => this.goBack(true)
        );
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };

  onCheck = () => {
    setTimeout(() => {
      const trialStart = this.props.form.getFieldValue('trialStart');
      const subscriptionStart = moment(trialStart)
        .add(6, 'months')
        .add(1, 'day');
      if (trialStart) {
        this.props.form.setFieldsValue({ subscriptionStart });
      }
    }, 100);
  };

  //get the equipment that was selected and update it with new orgId
  // tslint:disable-next-line: no-any
  addEquipment = async (values: any, _org: Org, _location?: _Location) => {
    const {
      selectedEquipment,
      orgType,
      originalSelectedEquip,
      org,
    } = this.state;
    const { sState } = this.props;
    const me = get(sState, 'auth.user');
    const isEdit = org ? true : false;
    const equipmentToAdd = selectedEquipment.filter(
      (id: string) => !originalSelectedEquip.includes(id)
    );
    const { eMap } = this.state;
    equipmentToAdd.map(async (id: string) => {
      let e = eMap[id] as Equipment;
      const equipHasSubHistory = hasSubHistory(e);
      const porderstring = `${orgType === 0 ? 'so' : 'do'}PurchaseOrder`;
      const _trialStart = values.trialStart;
      const trialStart = _trialStart
        ? moment(_trialStart).startOf('day')
        : undefined;
      const _subscriptionStart = values.subscriptionStart;
      const subscriptionStart = _subscriptionStart
        ? moment(_subscriptionStart).startOf('day')
        : undefined;
      const billingPreference = values.billingPreference;
      set(e, 'updatedAt', moment().toISOString());
      set(e, 'updatedBy', me.userId);
      set(e, `${orgType === 0 ? 'service' : 'dental'}OrgId`, _org.id);
      set(e, `${orgType === 0 ? 'SO' : 'DO'}SaleDate`, moment().toISOString());
      set(e, porderstring, _org[porderstring]);
      if (!!trialStart && !equipHasSubHistory && !e.alwaysSmart) {
        const { duration } = this.state.trialDuration;
        e = setTrialSub(e, trialStart, duration);
      }
      if (subscriptionStart && !equipHasSubHistory && !e.alwaysSmart) {
        e = setPayedSub(e, subscriptionStart, billingPreference);
      }
      if (!isEdit) {
        values.locationPlan && _location && set(e, `locationId`, _location.id);
      }
      if (orgType === 1 && !e.serviceOrgId) {
        set(e, 'serviceOrgId', _org.serviceOrgId);
      }
      if (orgType === 0 || (orgType === 1 && values.locationPlan)) {
        set(e, 'onConsignment', values.onConsignment);
      }
      try {
        await updateEquipment(e);

        if (isHandpiece(e)) {
          //DEN-874 when HP is assigned to a dental school we need to send a command to the device telling it so
          if (e.deviceId) {
            await sendDeviceCommand(
              e.deviceId,
              {
                arg: `{"cmd":"set_cfg", "cfg": {"config":{"dental_school":${orgType ===
                  1 && this.props.form.getFieldValue('doType') === 2}}}}`,
              },
              { showErrorToast: false },
              true
            );
          }

          if (_location) {
            await resetHandpiecePresets(e, _org.id, _location.id);
          }
        }
      } catch (error) {
        console.warn(error);
      }
    });
    return equipmentToAdd;
  };

  // check to see if equipment has been removed from the list
  //and remove orgId, locations, users etc.
  removeEquipment = () => {
    const {
      originalSelectedEquip,
      selectedEquipment,
      orgType,
      allUsers,
    } = this.state;
    const { sState } = this.props;
    const me: User = get(sState, 'auth.user');
    const equipmentToRemove = originalSelectedEquip.filter(
      (id: string) => !selectedEquipment.includes(id)
    );
    if (equipmentToRemove.length) {
      const { eMap } = this.state;
      equipmentToRemove.map((id: string) => {
        const e: Equipment = eMap[id];
        if (orgType === 0) {
          set(e, 'serviceOrgId', null);
        }
        set(e, 'dentalOrgId', null);
        set(e, 'groups', null);
        set(e, 'locationId', null);
        set(e, 'updatedAt', moment().toISOString());
        set(e, 'updatedBy', me.userId);

        updateEquipment(e);
        let eqUsers = allUsers.filter((u: User) => {
          if (u.equipment) return u.equipment.includes(e.id as string);
          return false;
        });
        if (eqUsers.length) {
          eqUsers.forEach((user: User) => {
            user.equipment = get(user, 'equipment', []).filter(id => {
              return id !== e.id;
            });
            updateUser(user);
          });
        }
      });
    }
    return equipmentToRemove;
  };

  componentDidMount = () => {
    const {
      history: { location },
    } = this.props;

    this.retrieveData();
    const org = get(location, 'state.org');
    const screen = get(location, 'state.screen', 0);
    const orgType = get(location, 'state.orgType', 1);
    const back = get(
      location,
      'state.back',
      `< Back to Manage ${orgType === 0 ? 'Service' : 'Dental'} Orgs`
    );

    this.unblock = this.props.history.block(_nextLocation => {
      if (!this.state.canLeave) {
        this.state.nextLocation = _nextLocation;
        this.goBack();
        return false;
      }
    });

    this.setState(
      {
        org,
        orgType,
        screen,
        isAssignEquipment: screen > 0 ? true : false,
        back,
      },
      this.setFieldsOriginal
    );
  };
  unblock?: () => void;
  componentWillUnmount = () => {
    this.unblock && this.unblock();
  };
  getTitle = () => {
    const { orgType } = this.state;
    return orgType === 0 ? 'service' : 'dental';
  };
  retrieveData = async () => {
    this.setState({ loading: true });
    const {
      history: { location },
    } = this.props;

    const eqs: Equipment[] = await getEquipments();
    const allUsers = await getUsers();
    const models = await getModels();
    const allOrgs = await getOrgs();
    const trialDuration = await getAppSettings();
    const _org = get(location, 'state.org');
    const orgType = get(this, 'state.orgType', 1);
    const eMap = chain(eqs)
      .keyBy('deviceId')
      .value();
    const modelMap = chain(models)
      .keyBy('id')
      .value();

    const orgId = get(_org, 'id');
    const orgTitle = this.getTitle();
    const serviceOrgId = get(_org, 'serviceOrgId');
    const isEdit = !!this.state.org;
    const isDentalEZ = userHasRole([0, 1], this.props.sState);
    const isOrgAdmin = userHasRole(2, this.props.sState);
    const myOrgId = get(this.props, 'sState.auth.user.orgId');
    const selectedEquipment: Equipment[] = [];
    const equipment: Equipment[] = eqs
      .map(e => {
        const model = new _Model(get(modelMap, `${e.modelId}`, {}) as Model);

        return {
          ...e,
          key: e.deviceId,
          type: model.type,
          model: model.name,
        };
      })
      .filter(e => {
        if (orgId && get(e, `${orgTitle}OrgId`) == orgId) {
          selectedEquipment.push(e);
        }
        if (!e.showOnDash || !e.modelId || !e.equipmentSN) {
          return false;
        }
        if (orgType === 1) {
          if (isDentalEZ) {
            return (
              (serviceOrgId === e.serviceOrgId && !e.dentalOrgId) ||
              !e.serviceOrgId
            );
          } else if (!isEdit && isOrgAdmin) {
            return myOrgId === e.serviceOrgId && !e.dentalOrgId;
          } else {
            return serviceOrgId === e.serviceOrgId && !e.dentalOrgId;
          }
        } else {
          return !e.serviceOrgId;
        }
      });

    const originalSelectedEquip = sortBy(selectedEquipment, 'equipmentSN').map(
      e => e.deviceId
    );
    this.setState({
      loading: false,
      equipment: uniqBy([...equipment, ...selectedEquipment], 'deviceId'),
      selectedEquipment: originalSelectedEquip,
      originalSelectedEquip,
      allEquipment: eqs,
      allUsers,
      allOrgs,
      eMap,
      trialDuration,
    });
  };

  retrieveEquipment = async (id: string) => {
    const eqs: Equipment[] = await getEquipments();
    const models = await getModels();

    const modelMap = chain(models)
      .keyBy('id')
      .value();
    const equipment: Equipment[] = eqs
      .map(e => {
        const model = new _Model(get(modelMap, `${e.modelId}`, {}) as Model);
        return {
          ...e,
          key: e.deviceId,
          type: model.type,
          model: model.name,
        };
      })
      .filter(e => {
        if (!e.showOnDash || !e.modelId || !e.equipmentSN) {
          return false;
        }
        return (id === e.serviceOrgId && !e.dentalOrgId) || !e.serviceOrgId;
      });
    this.setState({ equipment });
  };
  setFieldsOriginal = () => {
    const { toDisplay, toDisplay1, toDisplay2, org, formValues } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    const flat = [...toDisplay, ...toDisplay1, ...toDisplay2];

    flatten(flat as TDisplay[]).map(item => {
      const val = get(org, `${item.var}`, get(formValues, item.var));
      if (val) {
        const v = item.type === 'datepicker' ? moment(val) : val;
        setFieldsValue({ [item.var]: v });
      }
    });
  };
  goBack = (force = false) => {
    const onOk = () => {
      const { nextLocation } = this.state;
      const { history } = this.props;
      this.state.canLeave = true;
      if (nextLocation) {
        history.push(nextLocation);
      } else {
        history.goBack();
      }
    };

    const onCancel = () => 0;

    const isEdit = this.state.org ? true : false;
    const title = this.state.orgType === 0 ? 'Service' : 'Dental';

    if (force) {
      onOk();
    } else {
      Modal.confirm({
        title: 'Navigation Confirmation',
        content:
          // tslint:disable-next-line:max-line-length
          `Are you sure you want to navigate away from ${
            isEdit ? 'Edit' : 'Add'
          } ${title} Organization?  The ${
            isEdit ? '' : 'new'
          } ${title} Organization will not be ${
            isEdit ? 'saved' : 'created'
          } and any progress will be lost.`,
        onOk,
        onCancel,
        okText: 'Leave',
      });
    }
  };

  renderTitle = () => {
    const { org, orgType, screen } = this.state;

    const title = orgType === 0 ? 'Service' : 'Dental';

    let comp;
    if (org) {
      comp = <FormTitle>{`Edit ${title} Organization`}</FormTitle>;
    } else {
      const props = {
        cols: [
          { text: `${title} Information` },
          { text: 'Assign Equipment' },
          { text: 'Invite Admin User' },
        ],
        activeIndex: screen,
      };

      comp = (
        <React.Fragment>
          <FormTitle>{`Add ${title} Organization`}</FormTitle>
          <NumberedHeader {...props} />
        </React.Fragment>
      );
    }
    return <div css={css(`width: 80%; margin-top: 10px;`)}>{comp}</div>;
  };
  equipmentChange = (nextTargetKeys: Equipment[]) => {
    this.setState({ selectedEquipment: nextTargetKeys });
  };
  renderScreen = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const {
      toDisplay,
      toDisplay1,
      toDisplay2,
      screen,
      equipment,
      selectedEquipment,
    } = this.state;

    const eqs = sortBy(equipment, 'equipmentSN');

    if (screen === 0) {
      return (
        <Form
          layout="vertical"
          css={css(styles.formContainer)}
          onSubmit={this.submit}
        >
          {(toDisplay as [TDisplay[]]).map((items, i) => {
            return (
              <div key={i} css={css(styles.halfColumn)}>
                {items.map((item, _i) => {
                  const itemOptions = item.options;
                  const canDisplay = item.canDisplay ? item.canDisplay() : true;
                  const opts = item.opts as Opt[];
                  return !canDisplay ? null : (
                    <Form.Item
                      key={_i}
                      css={css(item.style)}
                      label={item.title}
                    >
                      <div>
                        {getFieldDecorator(item.var, itemOptions)(
                          item.type === 'dropdown' && item.opts ? (
                            <Select
                              disabled={item.disabled}
                              onChange={item.onSelect}
                            >
                              {opts.map((opt, i) => (
                                <Select.Option key={i} value={opt.value}>
                                  {opt.label}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : item.type === 'checkbox' ? (
                            <Checkbox onChange={item.onChange}>
                              {item.label}
                            </Checkbox>
                          ) : item.type === 'datepicker' ? (
                            <DatePicker />
                          ) : (
                            <Input onChange={item.onChange} />
                          )
                        )}
                        {item && item.subText && item.subText(item)}
                      </div>
                    </Form.Item>
                  );
                })}
              </div>
            );
          })}
        </Form>
      );
    } else if (screen === 1) {
      const TableColumns = [
        {
          title: 'Model ID',
          dataIndex: 'modelId',
        },
        {
          title: 'Serial Number',
          dataIndex: 'equipmentSN',
        },
      ];
      return (
        <div css={css(styles.container, styles.formContainer)}>
          <TableTransfer
            dataSource={eqs}
            targetKeys={selectedEquipment}
            showSearch={true}
            onChange={this.equipmentChange}
            locale={{
              searchPlaceholder: 'Search by model or serial number',
              emptyText: (
                <span
                  css={css(`font-style: italic;`)}
                >{`No equipment assigned`}</span>
              ),
            }}
            filterOption={(inputValue: string, item: Equipment) =>
              get(item, 'name', '')
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1 ||
              (get(item, 'modelId', '') as string)
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1 ||
              get(item, 'equipmentSN', '')
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) !== -1
            }
            leftColumns={TableColumns}
            rightColumns={TableColumns}
          />

          <Form
            layout="vertical"
            css={css(styles.formContainer2, 'margin-top: 10px;')}
            onSubmit={this.submit}
          >
            {(toDisplay1 as TDisplay[]).map((item, _i) => {
              const itemOptions = item.options;
              const canDisplay = item.canDisplay ? item.canDisplay() : true;
              const opts = item.opts as (() => Opt[]);
              return !canDisplay ? null : (
                <Form.Item key={_i} css={css(item.style)} label={item.title}>
                  {getFieldDecorator(item.var, itemOptions)(
                    item.type === 'dropdown' && item.opts ? (
                      <Select disabled={item.disabled} onChange={item.onSelect}>
                        {opts().map((opt, i) => (
                          <Select.Option key={i} value={opt.value}>
                            {opt.label}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : item.type === 'datepicker' ? (
                      <DatePicker {...item.props} />
                    ) : item.type === 'checkbox' ? (
                      <Checkbox {...item.props}>{item.label}</Checkbox>
                    ) : (
                      <Input />
                    )
                  )}
                  {item && item.subText && <p>{item.subText(item)}</p>}
                </Form.Item>
              );
            })}
          </Form>
        </div>
      );
    } else {
      return (
        <Form
          layout="vertical"
          css={css(styles.formContainer2)}
          onSubmit={this.submit}
        >
          {(toDisplay2 as TDisplay[]).map((item, _i) => {
            const itemOptions = item.options;
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            const opts = item.opts as Opt[];
            return !canDisplay ? null : (
              <Form.Item key={_i} css={css(item.style)} label={item.title}>
                {getFieldDecorator(item.var, itemOptions)(
                  item.type === 'dropdown' && item.opts ? (
                    <Select disabled={item.disabled} onChange={item.onSelect}>
                      {opts.map((opt, i) => (
                        <Select.Option key={i} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : item.type === 'checkbox' ? (
                    <Checkbox onChange={item.onChange}>{item.label}</Checkbox>
                  ) : (
                    <Input onChange={item.onChange} />
                  )
                )}
              </Form.Item>
            );
          })}
        </Form>
      );
    }
  };
  dir = (num: number) => {
    const { screen } = this.state;
    if (num > 0) {
      this.props.form.validateFieldsAndScroll(async err => {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          this.setState({ loading: false });
          return this.setState({ error });
        }

        this.setState(
          {
            screen: screen + num,
          },
          this.setFieldsOriginal
        );
      });
    } else {
      this.setState({
        screen: screen + num,
      });
    }
  };

  checkForDupAcctNum = async (acctNum: string) => {
    const { orgType, org } = this.state;
    const isEdit = !!org ? true : false;
    if (!acctNum) {
      if (isEdit) {
        return false;
      }
      this.setState({ loading: false });
      return this.dir(1);
    }
    const allOrgs = await getOrgs({ isActive: { eq: 1 } });
    const dup = allOrgs.some(_org => {
      return (
        _org.orgType === orgType &&
        _org.accountNumber === acctNum &&
        org.id !== _org.id
      );
    });
    if (!!dup) {
      this.props.form.setFields({
        accountNumber: {
          value: this.props.form.getFieldValue('accountNumber'),
          errors: [
            new Error('Organization with this Account Number already exists.'),
          ],
        },
      });
      this.setState({ loading: false });
      return true;
    } else {
      if (isEdit) {
        return false;
      } else {
        this.setState({ loading: false });
        this.dir(1);
      }
    }
  };

  renderButtons = () => {
    const { loading, screen, org, isAssignEquipment } = this.state;
    const { getFieldValue } = this.props.form;
    const checked = getFieldValue('adminUser.checked');
    const firstName = getFieldValue('adminUser.firstName');
    const lastName = getFieldValue('adminUser.lastName');
    const email = getFieldValue('adminUser.email');
    let buttons: ReactNode[] = [];

    const isEdit = org ? true : false;
    const cancel = (
      <Button
        title={'Cancel'}
        disabled={loading}
        css={css`
          margin: 0 5px;
        `}
        outline={true}
        onClick={() => this.goBack()}
      />
    );

    const prev = (
      <Button
        title={'Previous'}
        loading={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={() => this.dir(-1)}
      />
    );

    const next = (
      <Button
        title={'Next'}
        loading={loading}
        css={css`
          margin: 0 5px;
        `}
        onClick={async () => {
          this.setState({ loading: true });
          const accountNumber = this.props.form.getFieldValue('accountNumber');
          if (screen === 0) {
            return await this.checkForDupAcctNum(accountNumber);
          }
          this.setState({ loading: false });
          this.dir(1);
        }}
      />
    );

    const submit = (
      <Button
        title={'Submit'}
        loading={loading}
        css={css`
          margin: 0 5px;
        `}
        disabled={
          loading ||
          (screen === 2 && !checked && (!firstName || !lastName || !email))
        }
        onClick={this.submit}
        outline={false}
      />
    );

    if (isEdit && !isAssignEquipment) {
      buttons = [cancel, submit];
    } else if (screen === 0) {
      buttons = [cancel, next];
    } else if (screen === 1) {
      if (isEdit && isAssignEquipment) {
        buttons = [cancel, submit];
      } else {
        buttons = [prev, cancel, next];
      }
    } else {
      buttons = [prev, cancel, submit];
    }

    return (
      <div
        css={css(
          SharedStyles.row,
          screen === 0 ? `margin-top: 50px` : `margin-top: 20px;`
        )}
      >
        {buttons.map(b => b)}
      </div>
    );
  };

  render() {
    const { error, success, back } = this.state;
    return (
      <div css={css(styles.container)}>
        {!!back && (
          <Link
            css={css`
              margin-bottom: 5px;
              margin-right: auto;
            `}
            onClick={() => this.goBack()}
          >
            {back}
          </Link>
        )}

        {this.renderTitle()}

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        {this.renderScreen()}

        {this.renderButtons()}
      </div>
    );
  }
}

export const EditOrgComponent = Form.create()(withRouter(_EditOrgComponent));
