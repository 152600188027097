/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';
import {
  TandemGroup,
  _TandemGroup,
} from '../../_shared/interfaces/tandemGroup';
import { Select } from '../../_shared/select';
import { SelectValue } from 'antd/lib/select';

interface IProps extends ModalProps {
  // tslint:disable-next-line:no-any
  visible: boolean;
  availableTGroups: TandemGroup[];
  toggleModal: () => void;
  onAddEquipToTG: (
    selectedTG: SelectValue
  ) => Promise<
    | boolean
    | {
        destroy: () => void;
        update: (newConfig: ModalFuncProps) => void;
      }
  >;
}

const AddEquipToTGModal = (props: IProps) => {
  const [selectedTG, setSelectedTG] = useState<SelectValue>();
  const [loading, setLoading] = useState<boolean>();
  const { visible, availableTGroups, toggleModal, onAddEquipToTG } = props;

  const opts = availableTGroups.map(tg => {
    return { value: tg.id, title: tg.name };
  });

  const onSelect = (value: SelectValue) => {
    setSelectedTG(value);
  };

  useEffect(() => {
    if (availableTGroups.length === 1) {
      setSelectedTG(availableTGroups[0].id);
    }
  }, [availableTGroups]);

  const defaultValue =
    availableTGroups.length === 1 ? availableTGroups[0].id : undefined;

  const onOk = async () => {
    setLoading(true);
    const response = await onAddEquipToTG(selectedTG as SelectValue);
    if (response) {
      setLoading(false);
      toggleModal();
    }
  };

  const onCancel = () => {
    setSelectedTG(undefined);
    toggleModal();
  };
  return (
    <Modal
      centered
      title={'Select Tandem Group'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Select
        id="tg"
        onChange={onSelect}
        defaultValue={defaultValue}
        placeholder="Select Tandem Group"
      >
        {opts.map((opt, i) => (
          <Select.Option key={i} value={opt.value}>
            <p>{opt.title}</p>
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};
export default AddEquipToTGModal;
