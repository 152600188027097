/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ChangeEvent, Component, FormEvent, MouseEvent } from 'react';
import { AppState } from '../../app.state';
import { User, _User } from '../../_shared/interfaces/user';
import { Form, Alert, Checkbox, Row, Col, Modal } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import styles from './notification-settings.styles';
import { Button } from '../../_shared/button';
import { cleanCopy, removeEmpty, userHasRole } from '../../utils';
import SharedStyles from '../../_shared/styles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { get, set } from 'lodash';
import { ModalInput } from '../../_shared/modal-input';
import colors from '../../_shared/colors';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateUser: (user: User, shouldSetCurrent?: boolean) => Promise<User>;
  _user?: User;
}

export class _NotificationSettings extends Component<IProps> {
  state = {
    loading: false,
    error: null,
    success: null,
    checked: false,
    visible: false,
    toDisplay: [
      { title: 'Minor Alerts', var: 'events', vals: ['email', 'sms'] },
      { title: 'Major Alerts', var: 'alerts', vals: ['email', 'sms'] },
      { title: 'Maintenance Alerts', var: 'service', vals: ['email', 'sms'] },
    ],
    phoneModalVisible: false,
    phone: undefined,
  };

  renderModal = () => {
    return (
      <Modal
        title={'SMS Terms and Conditions'}
        visible={this.state.visible}
        onOk={this.submit}
        onCancel={this.closeModal}
        okButtonProps={{ disabled: !this.state.checked }}
        destroyOnClose={true}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <Checkbox
            onChange={this.onCheckTerms}
            css={css`
              margin-right: 10px;
            `}
            value={this.state.checked}
          />
          <div>
            <p>
              By checking this box, you agree to receive SMS notification
              messages regarding the status of your Dental EZ equipment from
              Dental EZ, Inc. to the mobile number provided for this account,
              and understand your consent is not a condition of purchase. You
              may receive up to two (2) automated text messages to complete your
              enrollment in this text alert program.
            </p>
            <p>
              Message & data rates may apply. To opt-out at any time, return to
              this screen to update your Notification Settings or text STOP in
              response to any message. Text HELP for help. Terms:{' '}
              <a href={'https://www.dentalez.com/terms'} target="_blank">
                https://www.dentalez.com/terms
              </a>{' '}
              and Privacy Policy:{' '}
              <a
                href={'https://www.dentalez.com/privacy-policy'}
                target="_blank"
              >
                https://www.dentalez.com/privacy-policy.
              </a>
            </p>
          </div>
        </div>
      </Modal>
    );
  };

  openModal = () => this.setState({ visible: true });
  closeModal = () => this.setState({ visible: false });

  onCheckTerms = (e: CheckboxChangeEvent) => {
    this.setState({
      checked: e.target.checked,
    });
  };

  submit = async (e?: MouseEvent | FormEvent) => {
    e && e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return this.setState({ error: err, loading: false });
      }
      try {
        const {
          sState: { auth },
          _user,
        } = this.props;
        const cUser = auth.user;
        const user = _user
          ? (new _User({ ..._user }) as User)
          : new _User({
              ...auth.user,
            } as User);

        const origUser = cleanCopy(user);

        this.state.toDisplay.map(item => {
          const val = values[item.var];
          user.notification[item.var] = val;
        });
        if (this.state.checked) {
          user.SMSTermsDate = new Date().toISOString();
        }
        if (this.state.phone) {
          set(user, 'phone', this.state.phone);
        }
        const shouldSetCurrent = cUser && user.userId === cUser.userId;
        trackEvent(eventTypes.user_notifications, { old: origUser, new: user });
        await this.props.updateUser(removeEmpty(user), shouldSetCurrent);
        this.setState({
          loading: false,
          isEdit: false,
          success: 'Data saved successfully!',
          checked: false,
          phone: '',
        });
        this.closeModal();
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };
  componentDidMount = () => {
    this.setFieldsOriginal();
  };
  setFieldsOriginal = () => {
    const { toDisplay } = this.state;
    const {
      sState: {
        auth: { user },
      },
      form: { setFieldsValue },
      _user,
    } = this.props;

    const User = _user
      ? new _User({ ..._user })
      : new _User({
          ...user,
        } as User);

    toDisplay.map(item => {
      setFieldsValue({ [item.var]: User.notification[item.var] });
    });
  };

  getShowTerms = () => {
    const {
      sState: {
        auth: { user },
      },
    } = this.props;
    if (get(user, 'SMSTermsDate')) {
      return false;
    }

    const { events, alerts, service } = this.props.form.getFieldsValue([
      'events',
      'alerts',
      'service',
    ]);
    if (
      (events && events.indexOf('sms') > -1) ||
      (alerts && alerts.indexOf('sms') > -1) ||
      (service && service.indexOf('sms') > -1)
    ) {
      return true;
    }
    return false;
  };
  getShowPhone = () => {
    const {
      sState: {
        auth: { user },
      },
    } = this.props;
    if (get(user, 'phone')) {
      return false;
    }

    const { events, alerts, service } = this.props.form.getFieldsValue([
      'events',
      'alerts',
      'service',
    ]);
    if (
      (events && events.indexOf('sms') > -1) ||
      (alerts && alerts.indexOf('sms') > -1) ||
      (service && service.indexOf('sms') > -1)
    ) {
      return true;
    }
    return false;
  };

  notificationsDisabled = (v?: string, type?: string) => {
    const { sState, _user } = this.props;
    const isDZAdminOrTech = userHasRole([0, 1], sState);
    const values = type && this.props.form.getFieldValue(type);
    if (_user && !isDZAdminOrTech && !v && !type) return true;
    if (
      (_user &&
        isDZAdminOrTech &&
        ((values && !values.includes('sms') && v === 'sms') ||
          (v === 'sms' && !values))) ||
      (_user && !isDZAdminOrTech)
    ) {
      return true;
    }
    return false;
  };

  onCancelPhone = () => {
    this.setState({ phoneModalVisible: false, phone: '' });
  };
  onSubmitPhone = async () => {
    this.closePhoneModal();
    const shouldShowTerms = this.getShowTerms();
    if (shouldShowTerms) {
      return this.openModal();
    }
    await this.submit();
  };

  openPhoneModal = () => this.setState({ phoneModalVisible: true });
  closePhoneModal = () => this.setState({ phoneModalVisible: false });

  onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ phone: event.target.value });
  };

  render() {
    const {
      sState: {
        auth: { user },
      },
      form: { getFieldDecorator },
      _user,
    } = this.props;
    const {
      toDisplay,
      loading,
      error,
      success,
      phone,
      phoneModalVisible,
    } = this.state;
    const shouldShowPhone = this.getShowPhone();
    const shouldShowTerms = this.getShowTerms();

    return user ? (
      <div css={css(SharedStyles.formContainer, styles.container)}>
        <FormTitle title={`Notification Settings`} />

        {error && (
          <Alert
            css={css(styles.alert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(styles.alert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <Form layout="vertical" onSubmit={this.submit}>
          <div css={css(styles.colContainer)}>
            <span css={css(styles.col1)}>Notification Type</span>
            <span css={css(styles.col2)}>Email</span>
            <span css={css(styles.col3)}>SMS</span>
          </div>
          {toDisplay.map((item, i) => {
            return (
              <Form.Item key={i}>
                {getFieldDecorator(item.var)(
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      <div css={css(styles.col1)}>
                        <label css={css(styles.label)}>{item.title}</label>
                      </div>
                      {item.vals.map((v, _i) => {
                        const disabled = this.notificationsDisabled(
                          v,
                          item.var
                        );
                        const showRedBorder =
                          v === 'sms' && !disabled && !user.phone;
                        return (
                          <Col key={_i} css={css(styles.col2)}>
                            <Checkbox
                              id={showRedBorder ? 'nophone' : undefined}
                              disabled={this.notificationsDisabled(v, item.var)}
                              key={_i}
                              value={v}
                              css={
                                showRedBorder && {
                                  '.ant-checkbox .ant-checkbox-inner': {
                                    borderColor: colors.error,
                                  },
                                }
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            );
          })}

          {!_user && !this.notificationsDisabled() && !user.phone && (
            <div css={css(styles.errorContainer)}>
              <span css={css(styles.error)}>
                User does not have a phone number stored. You will be required
                to enter a phone number to make any changes to SMS notification
                settings.
              </span>
            </div>
          )}

          {!this.notificationsDisabled() && (
            <Button
              title={'Save'}
              loading={loading}
              onClick={
                shouldShowPhone
                  ? this.openPhoneModal
                  : shouldShowTerms
                  ? this.openModal
                  : this.submit
              }
            />
          )}
        </Form>
        {this.renderModal()}
        <ModalInput
          title={'Telephone Number Required'}
          content={
            'In order to enable SMS Notifications a telephone number is required.'
          }
          label={'Phone'}
          placeholder={'1234567890'}
          onCancel={this.onCancelPhone}
          onChange={this.onChangePhone}
          onSubmit={this.onSubmitPhone}
          value={phone}
          visible={phoneModalVisible}
        />
      </div>
    ) : (
      <div>No User Provided</div>
    );
  }
}

export const NotificationSettings = Form.create()(
  withRouter(_NotificationSettings)
);
