/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent } from 'react';
import { AppState } from '../../app.state';
import { User, _User } from '../../_shared/interfaces/user';
import { Form, Input, Alert, DatePicker, message } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import styles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import {
  buildErrorMsgFromForm,
  userHasRole,
  uppercaseWords,
  isServiceOrgAdmin,
  serviceOrgThenDentalOrgs,
  sortByLabel,
} from '../../utils';
import Link from '../../_shared/link';
import { get, set } from 'lodash';
import { _Group, Group } from '../../_shared/interfaces/group';
import {
  createGroup,
  updateGroup,
} from '../../_shared/services/manage-groups.service';
import { Select } from '../../_shared/select';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { Org } from '../../_shared/interfaces/org';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateUser: (user: User) => Promise<User>;
  getUserData: () => Promise<{}>;
}

interface Opt {
  label: string;
  value: string | number;
}

export class _EditGroupComponent extends Component<IProps> {
  state = {
    isEdit: false,
    isAdd: false,
    loading: false,
    error: null,
    success: null,
    group: undefined,
    back: undefined,
    toDisplay: [
      {
        title: 'Organization',
        type: 'dropdown',
        var: 'orgId',
        canDisplay: () => {
          const { sState } = this.props;
          const hasRole = userHasRole([0, 1], sState);
          const isSOAdmin = isServiceOrgAdmin(sState);
          return hasRole || isSOAdmin ? true : false;
        },
        value: (val: string) => {
          const { sState } = this.props;
          const isSOAdmin = isServiceOrgAdmin(sState);
          if (userHasRole([0, 1], sState) || isSOAdmin) {
            return val;
          } else {
            return get(this.props.sState, 'auth.user.orgId');
          }
        },
        opts: () => {
          const { sState } = this.props;
          const isSOAdmin = isServiceOrgAdmin(sState);

          if (isSOAdmin) {
            return serviceOrgThenDentalOrgs(sState);
          } else {
            return sState.dash.orgs
              .map(o => ({ label: o.name, value: o.id } as Opt))
              .sort(sortByLabel);
          }
        },
        options: {
          rules: [
            {
              required: true,
              message: 'Organization is required. ',
            },
          ],
        },
      },
      {
        title: 'Name',
        var: 'name',
        transform: (value: string) => uppercaseWords(value),
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
    ],
  };

  componentDidMount = () => {
    const {
      history: { location },
    } = this.props;

    const group = get(location, 'state.group', new _Group());
    const back = get(location, 'state.back');
    const isEdit = get(location, 'state.editMode', this.state.isEdit);
    const isAdd = get(location, 'state.isAdd', false);

    this.setState(
      {
        group,
        back,
        isEdit,
        isAdd,
      },
      this.setFieldsOriginal
    );
  };

  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const group = new _Group({
          ...(this.state.group || {}),
        } as Group);

        this.state.toDisplay.map(item => {
          const val = values[item.var];
          group[item.var] = item.value
            ? item.value(val)
            : item.transform
            ? item.transform(val)
            : val;
        });

        const isAdd = get(this.state, 'isAdd', false);

        const date = new Date().toISOString();
        const cu = get(this.props.sState, 'auth.user', {}) as User;

        set(group, 'updatedAt', date);
        set(group, 'updatedBy', cu.userId);

        if (isAdd) {
          set(group, 'createdAt', date);
          set(group, 'createdBy', cu.userId);
          await createGroup(group);
        } else {
          await updateGroup(group);
        }

        message.success(
          isAdd ? 'New group has been created!' : 'Group has been saved'
        );

        const org = get(this, 'props.sState.dash.orgs', []).find(
          (o: Org) => o.id === group.orgId
        );
        trackEvent(
          isAdd ? eventTypes.group_add : eventTypes.group_update,
          isAdd ? { group, org } : { old: this.state.group, new: group }
        );

        this.setState({
          loading: false,
          isEdit: false,
          group,
        });
        this.manageGroup(group);
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };

  setFieldsOriginal = () => {
    const { toDisplay, group } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    const Group = new _Group({
      ...(group || {}),
    } as Group);

    toDisplay.map(item => {
      setFieldsValue({ [item.var]: Group[item.var] });
    });
  };
  goBack = () => {
    this.props.history.goBack();
  };
  manageGroup = (group: Group) => {
    this.props.history.push('/dashboard/manageGroup', {
      group,
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { toDisplay, loading, error, success, back, isAdd } = this.state;

    const group = (this.state.group as unknown) as Group;

    return group ? (
      <div css={css(styles.formContainer)}>
        {!!back && (
          <Link
            css={css`
              margin-bottom: 5px;
            `}
            onClick={this.goBack}
          >
            {back}
          </Link>
        )}

        <FormTitle
          size={'20px'}
          title={!!back && isAdd ? 'Add Group' : 'Edit Group'}
        />

        {error && (
          <Alert
            css={css(styles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(styles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <Form layout="vertical" onSubmit={this.submit}>
          {toDisplay.map((item, i) => {
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            const itemOptions = item.options;
            return !canDisplay ? null : (
              <Form.Item key={i} label={item.title}>
                {getFieldDecorator(item.var, itemOptions)(
                  item.type === 'dropdown' && item.opts ? (
                    <Select>
                      {item.opts().map((opt, i) => (
                        <Select.Option key={i} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : item.type === 'datepicker' ? (
                    <DatePicker />
                  ) : (
                    <Input />
                  )
                )}
              </Form.Item>
            );
          })}

          <Button
            title={'Cancel'}
            disabled={loading}
            css={css`
              margin-right: 10px;
            `}
            outline={true}
            onClick={this.goBack}
          />
          <Button title={'Save'} loading={loading} onClick={this.submit} />
        </Form>
      </div>
    ) : (
      <div>No User Provided</div>
    );
  }
}

export const EditGroupComponent = Form.create()(
  withRouter(_EditGroupComponent)
);
