/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { BaseDzAlertDataForm } from './base-dz-alert-form.component';
import { AlertPublishData } from '../../../../_shared/interfaces/publishData';

type Payload = AlertPublishData;

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const DzAlertDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  const [baseDataValid, setBaseDataIsValid] = useState<boolean>(false);
  const [baseData, setBaseData] = useState<AlertPublishData | undefined>(
    undefined
  );

  //notify parent of (combined) validation change whenever either of our children validation changes
  useEffect(() => {
    let valid = baseDataValid;
    validChanged(valid);
  }, [baseDataValid]);

  //notify parent of (combined) payload change whenever either of our children payload changes
  useEffect(() => {
    if (!!!baseData) {
      payloadChanged(undefined);
      return;
    }

    let data = baseData;
    payloadChanged(data as Payload);
  }, [baseData]);

  return (
    <BaseDzAlertDataForm
      validChanged={setBaseDataIsValid}
      payloadChanged={setBaseData}
    />
  );
};
