/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';

interface IProps {
  label: string;
  className?: string;
  customCss?: string;
  childrenCss?: string;
  required?: boolean;
}

export default class Label extends Component<IProps> {
  render() {
    const { label, children, customCss, className, required } = this.props;
    return (
      <div
        css={css(`width: 100%;`, customCss || '')}
        className={'ant-row ant-form-item ' + (className || '')}
      >
        <label
          className={`ant-col ant-form-item-label${
            required ? ' ant-form-item-required' : ''
          }`}
        >
          {label}
        </label>
        <div>{children}</div>
      </div>
    );
  }
}
