import getConfig from '../../config';
import { encryptString, isDentalOrgUser, isServiceOrgUser } from '../../utils';
import { get } from 'lodash';
import colors from '../colors';
import { AppState } from '../../app.state';

const config = getConfig();

export interface IEmailProps {
  ToAddresses: string[];
  name?: string;
  link?: string;
  reason?: string;
  Subject?: string;
  Body?: string;
  // tslint:disable-next-line:no-any
  props?: any;

}

const defaults = {
  Destination: {
    ToAddresses: config.ToAddresses,
  },
  Source: config.FromAddresses[0],
  ReplyToAddresses: config.FromAddresses,
};
export const Emails = {
  invite: (data: IEmailProps) => {
    const datastring = encryptString(
      JSON.stringify({
        date: new Date().toISOString(),
        email: data.ToAddresses[0],
      })
    );
    const sStateUser = { auth: { user: get(data, 'props.user', {}) }, dash: { orgs: get(data, 'props.orgs', []) } } as AppState;
    const activationlink = `${getConfig(get(data, 'props.env')).app_url}?signUp=${datastring}`;
    const isReminder = get(data, 'props.isReminder', false);
    return {
      ...defaults,
      Destination: {
        ToAddresses: data.ToAddresses,
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data: `You have been invited to join the Aeras platform!  Use 
the link below to complete the registration process.  Please note that 
Internet Explorer is not a supported browser at this time. For best results, use Chrome or Safari.
<br /><br/>
<a href='${activationlink}'>Sign Up</a>
<br /><br/>
Welcome,
<br/>
The DentalEZ Team`,
          },
        },
        Subject: {
          Charset: 'UTF-8',
          Data: `${isReminder ? 'REMINDER - ' : ''}You’re Invited:  Join the Aeras Intel IoT Platform`,
        },
      },
      template: {
        type: 'aeras_user_invite', 
        variables: { 
          isServiceOrgUser: isServiceOrgUser(sStateUser), 
          isDentalOrgUser: isDentalOrgUser(sStateUser),
          primaryColor: colors.highlight,
          activationlink,
          isReminder,
        } 
      }
    };
  },
  verify: (data: IEmailProps) => ({
    ...defaults,
    Destination: {
      ToAddresses: data.ToAddresses,
    },
    Message: {
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data: `Your confirmation code is "${data.props.pin}"`,
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: 'Your verification code',
      },
    },
  }),
  event: (data: IEmailProps) => ({
    ...defaults,
    Destination: {
      ToAddresses: data.ToAddresses,
    },
    Message: {
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data: data.Body,
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: data.Subject,
      },
    },
  }),
  transfer: (data: IEmailProps) => ({
    ...defaults,
    Destination: {
      ToAddresses: data.ToAddresses,
    },
    Message: {
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data: `
New request for Service Org transfer submitted on ${new Date().toUTCString()}
<br /><br/>
Form User's Email: ${data.props.email}
<br /><br/>
Form User's Name: ${data.props.name}
<br /><br/>
Form User's Phone: ${data.props.phone}
<br /><br/>
Current Service Org: ${data.props.serviceOrg}
<br /><br/>
New Service Org: ${data.props.newServiceOrg}
<br /><br/>
Comment: ${data.props.comment}`,
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data:
          'DentalEZ SmartUtility:  New Request for Service Organization Transfer',
      },
    },
  }),
};
