/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { VacuumSensorInstantPData } from '../../../../_shared/interfaces/publishData';
import { makeInput, makeNumInput } from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: VacuumSensorInstantPData | undefined) => void;
}

type MaybeNumber = number | undefined;

export const VacuumSensorInstantDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [pT, setPT] = useState<MaybeNumber>(undefined);
  const [iP, setIP] = useState<string | undefined>(undefined);
  const [tF, setTF] = useState<MaybeNumber>(undefined);
  const [wO, setWO] = useState<MaybeNumber>(undefined);
  const [wP, setWP] = useState<MaybeNumber>(undefined);
  const [oL, setOL] = useState<MaybeNumber>(undefined);

  useEffect(() => {
    if (
      pT !== undefined &&
      iP !== undefined &&
      tF !== undefined &&
      wO !== undefined &&
      wP !== undefined &&
      oL !== undefined
    ) {
      validChanged(true);
      payloadChanged({
        pT: pT,
        iP: iP,
        tF: tF,
        wO: wO,
        wP: wP,
        oL: oL,
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [pT, iP, tF, wO, wP, oL]);

  return (
    <div>
      {makeNumInput('PT', pT, setPT)}
      {makeInput('IP', iP, setIP)}
      {makeNumInput('TF', tF, setTF)}
      {makeNumInput('WO', wO, setWO)}
      {makeNumInput('WP', wP, setWP)}
      {makeNumInput('OL', oL, setOL)}
    </div>
  );
};
