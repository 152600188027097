import colors from '../../_shared/colors';
export default {
  container: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: -20px;
  `,
  mainContainer: `
    min-height: calc(100%);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0px;
    border-left: 2px solid ${colors.inputBorder};
  `,
};
