import { ManageProfileComponent } from './manage-profile.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { updateProfiles, updatePresets } from '../dashboard.actions';
import { Profile } from '../../_shared/interfaces/profile';

interface IProps {
  profile?: Profile;
}

export const ManageProfile = connect(
  (sState: AppState, props: IProps) => ({ sState, profile: props.profile }),
  {
    updateProfiles,
    updatePresets,
  }
)(ManageProfileComponent);
