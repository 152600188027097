import { connect } from 'react-redux';
import { ManageTandemGroupComponent } from './manage-tandem-group.component';
import { AppState } from '../../app.state';
import { updateMyEquipment } from '../dashboard.actions';

export const ManageTandemGroup = connect(
  (sState: AppState) => ({ sState }),
  {
    updateMyEquipment,
  }
)(ManageTandemGroupComponent);
