import { get } from 'lodash';
import { Preset } from '../interfaces/preset';
import { removeEmpty, convertWhere } from '../../utils';
import { instance } from './instance';

export const getPreset = async (id: string) => {
  const resp = await instance.get(`/preset/id/${id}`, { data: {} });
  return get(resp, 'data.preset', null) as Preset;
};

export const getPresets = async (where?: {}) => {
  let resp;
  if (where) {
    resp = await instance.post(`/preset`, {
      ...convertWhere(where),
    });
  } else {
    resp = await instance.get(`/preset`);
  }
  return get(resp, 'data.presets', []) as Preset[];
};

export const createPreset = async (preset: Preset) => {
  const resp = await instance.post('/preset/create', {
    preset: removeEmpty(preset),
  });
  return get(resp, 'data.preset', null) as Preset;
};

export const updatePreset = (preset: Preset) => {
  return createPreset(preset);
};

export const deletePreset = async (presetId: string) => {
  const resp = await instance.delete(`/preset/id/${presetId}`);
  return get(resp, 'data');
};
