export default {
  container: `
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  formContainer: `
    width: 40%;
  `,
  transferContainer: `
    width: 80%;
  `,
  formContainer2: `
    width: 50%;
  `,
  info: `
    font-size: 12px;
    font-style: italic;
  `,
};
