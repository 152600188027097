import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: start;

    .ant-calendar-picker {
      width: 100%;
    }
  `,
  mainContent: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% + 40px);
    width: 100%;
    margin-top: 0;
    justify-content: flex-start;
    align-items: start;
    border-left: 2px solid ${colors.inputBorder};
    padding: 20px;
  `,
};
