/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ConfigPublishData } from '../../../../_shared/interfaces/publishData';
import { EQ_TYPE } from '../../../../_shared/interfaces/equipment';
import { DatePicker, TimePicker } from 'antd';
import { Model } from '../../../../_shared/interfaces/model';
import styles from '../../spoof-a-publish.styles';
import {
  makeSwitch,
  makeInput,
  makeNumInput,
} from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: ConfigPublishData) => void;
  equipmentType: EQ_TYPE;
  model: Model;
}

export const BaseDzConfigDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged, model } = props;

  const now = () => moment();

  //STATE
  const [installTime, setInstallTime] = useState<Moment>(now());
  const [otaStart, setOtaStart] = useState<Moment>(now().startOf('day'));
  const [otaEnd, setOtaEnd] = useState<Moment>(now().endOf('day'));
  const [headsEnabled, setHeadsEnabled] = useState<boolean[]>([]);
  const [deviceOn, setDeviceOn] = useState<boolean>(true);
  const [scheduleEnabled, setScheduleEnabled] = useState<boolean>(true);
  const [smartScheduleEnabled, setSmartScheduleEnabled] = useState<boolean>(
    true
  );
  const [smartSchedulePressure, setSmartSchedulePressure] = useState<
    number | undefined
  >(undefined);
  const [statisticsInterval, setStatisticsInterval] = useState<
    number | undefined
  >(undefined);
  const [icons, setIcons] = useState<number | undefined>(undefined);
  const [highVoltageEnabled, setHighVoltageEnabled] = useState<boolean>(true);
  const [highPressureEnabled, setHighPressureEnabled] = useState<boolean>(true);
  const [bootloader, setBootloader] = useState<number | undefined>(1005);
  const [tandemGroup, setTandemGroup] = useState<number | undefined>(undefined);
  const [tandemRole, setTandemRole] = useState<string | undefined>('none');
  const [targetTandemPressure, setTargetTandemPressure] = useState<
    number | undefined
  >(undefined);
  const [serialNumber, setSerialNumber] = useState<string | undefined>(
    undefined
  );
  const [maintenanceModeOn, setMaintenanceModeOn] = useState<boolean>(false);
  const [seq, setSeq] = useState<number | undefined>(undefined);

  //HANDLERS
  const installTimeChanged = (m: Moment, _s: string) => {
    setInstallTime(m);
  };
  const otaStartChanged = (m: Moment, _s: string) => {
    setOtaStart(m);
  };
  const otaEndChanged = (m: Moment, _s: string) => {
    setOtaEnd(m);
  };
  const toggleHeadEnabled = (head: number) => {
    let mutable = headsEnabled;
    if (mutable[head] !== undefined) {
      mutable[head] = !headsEnabled[head];
    } else {
      //if we aren't keep track of it yet, it was set to false
      mutable[head] = true;
    }
    setHeadsEnabled([...mutable]);
  };

  //EFFECTS
  useEffect(() => {
    //VALIDATE!
    if (
      otaStart < otaEnd && // OTA start must come before end
      headsEnabled !== undefined &&
      headsEnabled.length > 0 && // at least one head enabled must be provided
      !!smartSchedulePressure &&
      smartSchedulePressure >= 0 && // smart schedule pressure should be set and non-negative
      !!statisticsInterval &&
      statisticsInterval > 0 && // statistics interval should be set and above 0
      !!icons && // icons should be set
      !!bootloader && // bootloader should be set
      !!targetTandemPressure && // target tandem pressure should be set
      !!tandemGroup && // tandem group must be set
      !!tandemRole && // tandem role must be set
      !!seq && // seq must be set, whatever it is
      !!serialNumber // serial number must exist
    ) {
      validChanged(true);

      payloadChanged({
        install_time: installTime
          .clone()
          .utc()
          .unix(),
        time_offset: installTime.clone().utcOffset() * 60, //utc comes back as minutes, we want seconds
        ota_start: otaStart.clone().hours(),
        ota_end: otaEnd.clone().hours(),
        heads_enabled: headsEnabled,
        device_on: [
          deviceOn,
          now()
            .utc()
            .unix(),
        ],
        schedule_enabled: [scheduleEnabled],
        smart_schedule: {
          enabled: smartScheduleEnabled,
          pressure: smartSchedulePressure,
        },
        statistics_interval: statisticsInterval,
        icons: icons,
        high_voltage: [highVoltageEnabled],
        highpressuremode_on: [highPressureEnabled],
        bootloader: bootloader,
        tandem: { group: tandemGroup, role: tandemRole },
        model: model.id,
        sn: serialNumber,
        maintmode_on: [maintenanceModeOn],
        seq: seq,
      });
    } else {
      validChanged(false);
    }
  }, [
    otaStart,
    otaEnd,
    headsEnabled,
    smartSchedulePressure,
    statisticsInterval,
    icons,
    bootloader,
    targetTandemPressure,
    tandemGroup,
    tandemRole,
    seq,
    serialNumber,
  ]);

  //UI GENERATIVE
  const generateHeadsEnabledSwitches = (): JSX.Element[] => {
    let content = [] as JSX.Element[];

    if (model !== undefined && model.heads !== undefined && model.heads > 0) {
      for (let i = 0; i < model.heads; i++) {
        const enabled = !!headsEnabled[i] && headsEnabled[i];

        content.push(
          <div key={`hs${i}`}>
            {makeSwitch(`Head #${i}`, enabled, () => toggleHeadEnabled(i))}
          </div>
        );
      }
    } else {
      content.push(
        <p key={'heads failure'}>unable to determine number of heads</p>
      );
    }

    return content;
  };

  //UI DECLARATIVE
  return (
    <div>
      <div css={css(styles.inputGroup)}>
        <p>Install Time:</p>
        <DatePicker
          showTime={true}
          showToday={true}
          defaultValue={installTime}
          onChange={installTimeChanged}
        />
      </div>

      <div css={css(styles.inputGroup)}>
        <p>Heads Enabled:</p>
      </div>
      <div css={css(styles.nestedInputGroup)}>
        {generateHeadsEnabledSwitches()}
      </div>

      <div css={css(styles.inputGroup)}>
        <p>OTA Update Window Start (Hours):</p>
        <TimePicker
          format="HH"
          defaultValue={otaStart}
          onChange={otaStartChanged}
        />
      </div>
      <div css={css(styles.inputGroup)}>
        <p>OTA Update Window End (Hours):</p>
        <TimePicker
          format="HH"
          defaultValue={otaEnd}
          onChange={otaEndChanged}
        />
      </div>

      {makeSwitch('Device On', deviceOn, setDeviceOn)}
      {makeSwitch('Schedule Enabled', scheduleEnabled, setScheduleEnabled)}
      {makeSwitch(
        'Smart Schedule Enabled',
        smartScheduleEnabled,
        setSmartScheduleEnabled
      )}
      {makeNumInput(
        'Smart Schedule Pressure',
        smartSchedulePressure,
        setSmartSchedulePressure
      )}
      {makeNumInput(
        'Statistics Interval',
        statisticsInterval,
        setStatisticsInterval
      )}
      {makeNumInput('Icons', icons, setIcons)}
      {makeSwitch('High Voltage', highVoltageEnabled, setHighVoltageEnabled)}
      {makeSwitch('High Pressure', highPressureEnabled, setHighPressureEnabled)}
      {makeNumInput('Bootloader', bootloader, setBootloader)}
      {makeNumInput(
        'Tandem Target Pressure',
        targetTandemPressure,
        setTargetTandemPressure
      )}
      {makeNumInput('Tendem Group', tandemGroup, setTandemGroup)}
      {makeInput('Tandem Role', tandemRole, setTandemRole)}
      {makeInput('Serial #', serialNumber, setSerialNumber)}
      {makeSwitch('Maintenance Mode', maintenanceModeOn, setMaintenanceModeOn)}
      {makeNumInput('Seq', seq, setSeq)}
    </div>
  );
};
