import colors from '../../_shared/colors';

export const global = `
  .ant-table-content td {
    font-size: 12px;
  }
`;

export const sbstyles = {
  iconMarg: `
    margin-right: 5px;
  `,
  button: `
    font-size: 11px;
    cursor: pointer;
    margin: 3px 0;
  `,
  infoDivide: `
    margin-bottom: 20px;
  `,
  motorItem: `
    margin-bottom: 10px;
  `,
  motorSwitch: `
    margin-left: 10px;
  `,
  serialItem: `
    margin-left: 10px;
    font-size: 11px;
  `,
};

export const modalStyles = {
  content: `
    font-size: 16px;
    font-weight: bold;
  `,
  dividers: `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
  `,
  label: `
    font-weight: bold;
    font-size: 14px;
    width: 100px;
  `,
};

export default {
  icon: `
    color: ${colors.labelColor};
    font-size: 16px;
  `,
  deviceStatusAlertContainer: `
    margin-left: -20px;
    margin-top: -20px;
    width: calc(100% + 40px);
  `,
  deviceStatusAlert: `
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: ${colors.white};
    border-bottom: 1px solid ${colors.disabledLight};
  `,
  container: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: -20px;
  `,
  sideMenu: `
    min-width: 200px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    img {
      width: 150px;
      margin-top: 20px;
    }

    p {
      margin: 0;
      font-size: 12px;
      margin-bottom: 2px;
    }
  `,
  mainContainer: `
    min-height: calc(100% + 38px);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;
    margin-right: -20px;  
    margin-top: -38px;  
    overflow: hidden;
    overflow-y: auto;
    border-left: 2px solid ${colors.inputBorder};
  `,
  mainContainerPB: `
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  `,
  expandRow: `
    font-weight: bold;
  `,
  hr: `
    width: 95%;
    height: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid ${colors.inputBorder};
  `,
  hrMain: `
    width: calc(100% + 60px);
    height: 2px;
    margin-left: -20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${colors.inputBorder};
  `,
  minText: `
    display: flex;
    align-self: flex-end;
    color: ${colors.labelColor};
    font-size: 8px;
  `,
  smallText: `
    display: flex;
    align-self: flex-end;
    color: ${colors.labelColor};
    font-size: 10px;
  `,
  mediumText: `
    display: flex;
    align-self: flex-end;
    color: ${colors.labelColor};
    font-size: 16px;
  `,
  largeText: `
    color: ${colors.labelColor};
    font-size: 24px;
    margin-right: 2px;
  `,
  suffix: `
    margin-bottom: 7px;
  `,
  suffixIcon: `
    margin-bottom: 9px;
  `,
  title: `
    font-size: 13px;
    font-weight: bold;
    margin-right: 20px;
  `,
  date: `
    font-size: 10px;
    color: ${colors.lightText};
  `,
  overflowHidden: `
    overflow: hidden;
  `,
  showMore: `
    margin-top: 10px; 
    margin-left: 5px;
  `,
  blankMessage: `
    font-style: italic;
    font-size: 12px;
    margin-top: 15px;
  `,
  rightLink: `
    margin-left: auto;
  `,
  link: `
    background-color: transparent;
    border-color: transparent;
    color: ${colors.highlight};
    cursor: pointer;
  `,
  info: `
    font-style: italic;
  `,
  cancelLink: `
    font-style: italic;
    font-size: 12px;
  `,
  billingHistorySidebarLink: `
    font-style: italic;
  `,
  error: `
    font-size: 12px;
    color: ${colors.error};
  `,
};
