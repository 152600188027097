import { ManagePresetComponent } from './manage-preset.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { updatePresets } from '../dashboard.actions';
export const ManagePreset = connect(
  (sState: AppState) => ({ sState }),
  {
    updatePresets,
  }
)(ManagePresetComponent);
