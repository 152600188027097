/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { getObjectDiff, sortByKey, getSensorsForEquipmentType } from '../../utils';
import { AppState } from '../../app.state';
import { get, set } from 'lodash';
import { EditableFormTable } from '../../_shared/editable-table';
import { _Model, Model, Threshold } from '../../_shared/interfaces/model';
import { updateModel } from '../../_shared/services/manage-models.service';
import FormTitle from '../../_shared/form-title';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import { message, Modal, Switch } from 'antd';
import { Row } from '../../_shared/row';
import { Form } from '../../_shared/form';
import { FormProps } from 'antd/lib/form';

interface IProps extends RouteComponentProps {
  sState: AppState;
  updateModels: () => Promise<void>;
}

const COLS = [
  {
    title: 'Sensor ID',
    dataIndex: 'sensorId',
  },
  {
    title: 'Sensor',
    dataIndex: 'sensorName',
  },
  {
    title: 'Minor Lower',
    dataIndex: 'minorLower',
    editable: true,
  },
  {
    title: 'Major Lower',
    dataIndex: 'majorLower',
    editable: true,
  },
  {
    title: 'Minor Upper',
    dataIndex: 'minorUpper',
    editable: true,
  },
  {
    title: 'Major Upper',
    dataIndex: 'majorUpper',
    editable: true,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    editable: true,
  }
];

export class _ManageModelComponent extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,

    model: undefined,
    data: [],
    modalProps: {
      visible: false,
    }
  };
  componentDidMount = async () => {
    const { history } = this.props;
    const model = get(history, 'location.state.model');

    this.setState(
      {
        model,
      },
      this.retrieveData
    );
  };
  findModelInSState = () => {
    const { sState } = this.props;
    const modelId = get(this.state, 'model.id');
    const models = get(sState, 'dash.models', []) as Model[];
    return new _Model(models.find(m => m.id === modelId) as Model);
  };
  retrieveData = async () => {
    try {
      this.setState({ loading: true });

      await this.props.updateModels();

      const { sState } = this.props;
      const model = this.findModelInSState();

      const allSensors = getSensorsForEquipmentType(model.type, sState);

      //const data = Object.keys(model.thresholds)
      const data = allSensors
        .map(sensor => {
          const sensorId = sensor.id;
          const thresh = get(model.thresholds, sensorId, undefined) as Threshold | undefined;

          return {
            ...thresh,
            minorLower: thresh && thresh.minorLower || 'null',
            majorLower: thresh && thresh.majorLower || 'null',
            minorUpper: thresh && thresh.minorUpper || 'null',
            majorUpper: thresh && thresh.majorUpper || 'null',
            duration: thresh && thresh.duration || 'null',
            key: sensorId,
            sensorId,
            sensorName: get(sensor, 'name', ''),
          };
        })
        .sort((a, b) => sortByKey(a, b, 'sensorName'));

      this.setState({
        show: true,
        loading: false,
        data,
        model,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  openHardwareModal = () => {
    let form: any;

    const validator = (rule: any, value: string, cb: any) => {
      const regex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
      const passes = regex.test(value);
      cb(!value || passes ? undefined : rule.message);
    }

    const opts = {
      options: {
        rules: [
          {
            required: true,
            message: 'Value is required. ',
          },
          {
            max: 8,
            message: 'Value is limited to 8 characters. ',
          },
          {
            validator,
            message: 'Value is not valid. ',
          },
        ],
      },
    }

    const formProps = {
      registerForm: (f: any) => form = f,
      defaults: this.state.model,
      toDisplay: [
        {
          title: 'Control Board Hardware',
          var: 'control_board_hw',
          ...opts,
        },
        {
          title: 'Control Board Firmware',
          var: 'control_board_fw',
          ...opts,
        },
        {
          title: 'Daughter Board Firmware',
          var: 'fw_version',
          ...opts,
        }
      ]
    } as FormProps;

    const close = () => {
      this.setState({ modalProps: { visible: false }})
    }

    const onSubmit = async () => {
      form.validateFieldsAndScroll(async (err: any, vals: any) => {
        if (err) {
          return;
        }

        try {
          const model = { ...(this.state.model || {}), ...vals };
          await updateModel(model);
          close();
          this.retrieveData();
        } catch (err) {
          message.error(err);
        }
      });
    }

    this.setState({
      modalProps: {
        visible: true,
        title: 'Update Hardware & Firmware Versions',
        onCancel: close,
        onOk: onSubmit,
        okText: 'Submit',
        closable: true,
        children: <Form {...formProps} />
      }
    })
  }
  onSave = async (newData: [], key: string) => {
    const { data } = this.state;
    const model = this.findModelInSState();
    const index = data.findIndex((d: { key: string }) => d.key === key);

    const oldData = data[index];
    const newD = newData[index];

    const objDiff = getObjectDiff(oldData, newD);

    if (objDiff.length > 0) {
      objDiff.map(k => {
        let val = parseFloat(get(newD, k));
        if (isNaN(val)) {
          val = (null as unknown) as number;
        }
        set(model, `thresholds.${key}.${k}`, val);
      });

      //TODO: send changeThreshold command here
      await updateModel(model);
      this.retrieveData();
    }
  };
  changeModel = async (d = {}) => {
    const model = this.findModelInSState();
    const newmodel = { ...model, ...d };
    await updateModel(newmodel);
    this.retrieveData();
  };
  back = () => {
    this.props.history.goBack();
  };
  render() {
    const { data, loading, model } = this.state;

    const props = {
      dataSource: data,
      columns: COLS,
      onSave: this.onSave,
      loading,
      // tslint:disable-next-line:no-any
    } as any;

    const showHardware = ['6400'].includes(get(model, 'id', ''));

    return (
      <div>
        <Link
          css={css('margin-bottom: 15px; width: 150px')}
          onClick={this.back}
        >
          {'< Back to Manage Models'}
        </Link>
        <Row>
          <FormTitle size={'18px'}>
            {get(model, 'name')}
            <Button
              size="small"
              css={css('margin-left: 10px;')}
              loading={loading}
              title="Refresh"
              onClick={() => this.retrieveData()}
            />
          </FormTitle>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: 'auto', width: 'auto' }}>
            {!!showHardware && <Button style={{ float: 'right' }} title="HW & FW Versions" onClick={this.openHardwareModal} />}
            <Row>
              <span css={css('font-size: 16px; margin-right: 5px;')}>
                Show on Manufacturing
              </span>
              <Switch
                checked={!!get(this, 'state.model.showOnManu', true)}
                onChange={showOnManu => this.changeModel({ showOnManu })}
              />
            </Row>
          </div>
        </Row>
        <EditableFormTable {...props} />
        
        <Modal {...this.state.modalProps} />
      </div>
    );
  }
}

export const ManageModelComponent = withRouter(_ManageModelComponent);
