/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import {
  getUsersName,
  userHasRole,
  sortByFirstThenLastName,
  getRoleName,
  singleStringAddress,
  getEmailFromText,
  sortByName,
  isDentalOrgAdmin,
  sortByKey,
  getTandemGroupsForUser,
  isHandpiece,
} from '../../utils';
import { User } from '../../_shared/interfaces/user';
import { Alert, Modal } from 'antd';
import { AppState } from '../../app.state';
import { get, chain, uniq, set, sortBy } from 'lodash';
import styles from './manage-location.styles';
import {
  TableListMultiGroup,
  TableListSimple,
} from '../../_shared/table-list-multi';
import {
  getEquipments,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import {
  getUsers,
  updateUser,
} from '../../_shared/services/manage-users.service';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { getModels } from '../../_shared/services/manage-models.service';
import { Location } from '../../_shared/interfaces/location';
import { ActiveSwitch } from '../../_shared/active-switch/active-switch.component';
import {
  updateLocation,
  deleteLocation,
  getLocation,
} from '../../_shared/services/manage-locations.service';
import colors from '../../_shared/colors';
import { Org } from '../../_shared/interfaces/org';
import moment from 'moment';
import { TandemGroup } from '../../_shared/interfaces/tandemGroup';
import { resetHandpiecePresets } from '../../_shared/services/handpiece-profiles.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { ColumnProps } from 'antd/lib/table';

interface IProps extends RouteComponentProps {
  sState: AppState;
  retrieveData: () => void;
}

const UserColumns = [
  {
    title: 'Full Name',
    dataIndex: 'name',
    width: '33%',
  },
  {
    title: 'Role',
    dataIndex: 'roleName',
    width: '33%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '33%',
  },
];

const EquipColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '33%',
  },
  {
    title: 'Model ID',
    dataIndex: 'modelId',
    width: '33%',
  },
  {
    title: 'Serial Number',
    dataIndex: 'equipmentSN',
    width: '33%',
  },
];

const TandemGroupColumns = (_this: _ManageLocationComponent): ColumnProps<TandemGroup>[] => [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '20%',
    defaultSortOrder: 'ascend',
    sorter: (a: { name: string }, b: { name: string }) => sortByKey(a, b, 'name'),
  },
  {
    title: 'Number',
    dataIndex: 'groupNumber',
    width: '20%',
  },
  {
    title: 'Type',
    dataIndex: 'equipType',
    width: '20%',
  },
  {
    title: 'Equipment',
    dataIndex: 'equipmentCount',
    width: '20%',
  },
  {
    title: 'Primary',
    dataIndex: 'primaryEquipSN',
    width: '20%',
  },
];

interface IState {
  show: boolean;
  loading: boolean;
  isEdit: boolean;
  error: string | undefined;
  success: string | undefined;
  active: boolean;
  location: Location | undefined;
  orgMap: {};
  users: User[];
  allUsers: User[];
  userMap: {};
  equipment: Equipment[];
  allEquipment: Equipment[];
  equipMap: {};
  tandemGroups: TandemGroup[];
  allTandemGroups: TandemGroup[];
}

export class _ManageLocationComponent extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,
    active: true,
    location: undefined,
    orgMap: {},
    users: [],
    allUsers: [],
    userMap: {},
    equipment: [],
    allEquipment: [],
    equipMap: {},
    tandemGroups: [],
    allTandemGroups: [],
  } as IState;
  componentDidMount = async () => {
    const { history } = this.props;

    const location = await getLocation(
      get(history, 'location.state.location.id')
    );

    this.setState(
      {
        location,
        active: location && location.isActive,
      },
      this.retrieveData
    );
  };
  retrieveData = async (res?: { error?: string }) => {
    try {
      if (res && res.error) {
        return this.setState({ error: res.error });
      }

      const { sState } = this.props;

      this.setState(
        {
          show: false,
          loading: true,
        },
        () => this.setState({ show: true })
      );

      const location = (get(this.state, 'location') as unknown) as Location;

      const [allTheEquipment, allOrgs, allModels, ausers] = await Promise.all([
        await getEquipments(),
        await getOrgs(),
        await getModels(),
        await getUsers(),
      ]);

      const allTheUsers = sortBy(
        ausers,
        [user => user.lastName.toLowerCase()],
        [user => user.firstName.toLowerCase()],
        ['lastName', 'firstName', 'userId']
      );

      const allTandemGroups = await getTandemGroupsForUser(
        sState,
        allTheEquipment
      );

      const orgMap = chain(allOrgs)
        .keyBy('id')
        .value();
      const modelMap = chain(allModels)
        .keyBy('id')
        .value();
      const equipMap = chain(allTheEquipment)
        .keyBy('id')
        .value();

      const tandemGroups = allTandemGroups
        .filter(tg => tg.locationId === location.id)
        .map(tg => {
          const equipmentForTg = allTheEquipment.filter(
            equip => equip.tandemGroupId === tg.id
          );
          const primaryEquip = equipmentForTg.find(
            equip => equip.tandemGroupRole === 'central'
          );
          const primaryEquipSN = get(primaryEquip, 'equipmentSN')
            ? get(primaryEquip, 'equipmentSN')
            : get(primaryEquip, 'name');
          const equipmentCount = equipmentForTg.length;
          return {
            ...tg,
            equipmentCount,
            primaryEquipSN,
          };
        });

      const equipment = allTheEquipment
        .filter(e => e.locationId === location.id)
        .map(e => {
          const model = get(modelMap, `${e.modelId}.name`, '');
          const dentalOrg = get(orgMap, `${e.dentalOrgId}.name`, '');
          const serviceOrg = get(orgMap, `${e.serviceOrgId}.name`, '');
          return {
            ...e,
            model,
            dentalOrg,
            serviceOrg,
            location: get(location, 'name', ''),
          } as Equipment;
        })
        .sort(sortByName);

      const allEquipment = allTheEquipment
        .filter(
          e =>
            !e.locationId &&
            (e.serviceOrgId === location.orgId ||
              e.dentalOrgId === location.orgId)
        )
        .map(e => {
          const name = get(e, 'name', '');
          const id = get(e, 'id', '');
          const serial = get(e, 'equipmentSN', '');
          return {
            text: `${name} (${serial})`,
            value: id,
          };
        });

      const users = allTheUsers
        .filter(
          u =>
            location &&
            u.locations &&
            Array.isArray(u.locations) &&
            u.locations.indexOf(location.id || '') > -1
        )
        .map(u => {
          return {
            ...u,
            roleName: getRoleName(u.role, sState),
            name: getUsersName(u),
            eCount: (u.equipment || []).length,
            gCount: (u.groups || []).length,
            organization: get(orgMap, `${u.orgId}.name`, ''),
          } as User;
        })
        .sort(sortByFirstThenLastName);

      const uMap = chain(users)
        .keyBy('userId')
        .value();
      const userMap = chain(allTheUsers)
        .keyBy('userId')
        .value();
      const allUsers = allTheUsers
        .filter(
          u => !uMap[u.userId] && u.orgId === location.orgId && u.role !== 2
        )
        .map(u => `${getUsersName(u)}: ${u.email}`);

      this.setState({
        orgMap,
        users,
        allUsers,
        userMap,
        equipment,
        allEquipment,
        equipMap,
        show: true,
        loading: false,
        tandemGroups,
        allTandemGroups,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  toggleEdit = () => {
    const { location } = this.state;
    this.props.history.push('/dashboard/editLocation', {
      location,
      back: '< Back to Location Details',
    });
  };
  renderSubInfo = () => {
    const { location, orgMap } = this.state;

    const orgId = get(location, 'orgId');

    const items = [
      {
        title: 'Dental Org Name',
        var: 'orgId',
        toText: () => get(orgMap, `${orgId}.name`),
      },
      {
        title: 'Code',
        var: 'code',
        toText: () => get(location, 'code'),
      },
      {
        title: 'Phone',
        var: 'phone',
        toText: () => get(location, 'phone'),
      },
      {
        title: 'Address',
        var: 'address',
        toText: () => singleStringAddress(get(location, 'address')),
      },
    ];

    return (
      <span css={css(`font-size: 12px;`)}>
        {items
          .filter(item => {
            const hasVal = get(location, item.var);
            const val = hasVal && item.toText();
            return val;
          })
          .map((item, i) => {
            const hasVal = get(location, item.var) as never;
            const val = hasVal && item.toText();
            return (
              <span key={i}>
                {i > 0 ? (
                  <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</span>
                ) : null}
                {`${val}`}
              </span>
            );
          })}
      </span>
    );
  };

  userResult = async (res: { tags: string[] }) => {
    return new Promise(resolve => {
      const { userMap, location } = this.state;
      const uMap = userMap as { [key: string]: User };
      const emails = res.tags.map(t =>
        getEmailFromText(t as string)
      ) as string[];
      const users = emails.map(e => uMap[e]).filter(u => u);

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= users.length) {
          resolve(undefined);
        }
      };

      const locationId = get(location, 'id', '');

      if (users.length === 0) {
        checkDone();
      } else {
        users.map(async u => {
          u.locations = uniq([
            ...(Array.isArray(u.locations) ? u.locations : []),
            locationId,
          ]);

          try {
            await updateUser(u);
            checkDone();
          } catch (err) {
            checkDone();
          }
        });
      }
    });
  };
  userRemove = async (rows: User[]) => {
    return new Promise(resolve => {
      const { location } = this.state;

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= rows.length) {
          resolve(undefined);
        }
      };

      const locationId = get(location, 'id', '');

      rows.map(async u => {
        const lIndex = u.locations.indexOf(locationId);
        u.locations = u.locations || [];
        u.locations.splice(lIndex, 1);

        try {
          await updateUser(u);
          checkDone();
        } catch (err) {
          checkDone();
        }
      });
    });
  };

  equipResult = (res: { tags: string[] }) => {
    return new Promise(resolve => {
      const { equipMap, location, orgMap } = this.state;
      const me = get(this.props, 'sState.auth.user');
      const eMap = equipMap as { [key: string]: Equipment };
      const equipIds = res.tags;
      const newEquipment = equipIds.map(e => eMap[e]).filter(u => u);

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= newEquipment.length) {
          resolve(undefined);
          this.props.retrieveData();
        }
      };

      const locationId = get(location, 'id', '');
      const locOrgId = get(location, 'orgId');
      const locOrg = get(orgMap, `${locOrgId}`) as Org;

      const addNewEquipment = () => {
        if (newEquipment.length === 0) {
          checkDone();
        } else {
          newEquipment.map(async e => {
            set(e, 'locationId', locationId);
            set(e, 'onConsignment', false);
            set(e, 'updatedAt', moment().toISOString());
            set(e, 'updatedBy', me.userId);
            if (locOrg) {
              set(
                e,
                `${locOrg.orgType === 0 ? 'SO' : 'DO'}SaleDate`,
                new Date().toISOString()
              );
            }

            try {
              await updateEquipment(e);
              if (isHandpiece(e) && locOrgId) {
                try {
                  const profileRefreshResult = await resetHandpiecePresets(
                    e,
                    locOrgId,
                    locationId
                  );
                  // api error, likely. note in this case it is unlikely that failures were scheduled for re-attempt (failed before that call)
                  if (profileRefreshResult.error) {
                    this.setState({
                      error: `Profile and Preset data could not be sent to handpiece unit(s).`,
                    });
                  }
                  // some failed, some succeeded
                  else if (
                    profileRefreshResult.failed.length > 0 &&
                    profileRefreshResult.synced.length > 0
                  ) {
                    this.setState({
                      success: `Some Profile and Preset data could not be sent to handpiece unit(s). Failures will be re-attempted nightly.`,
                    });
                  }
                  // assuming all succeeded
                  else if (profileRefreshResult.synced.length > 0) {
                    this.setState({
                      success: `Profile and Preset data successfully sent to handpiece unit(s).`,
                    });
                  }
                  // assuming all failed
                  else {
                    this.setState({
                      error: `Profile and Preset data could not be sent to handpiece unit(s). Failures will be re-attempted nightly.`,
                    });
                  }
                } catch (err) {
                  this.setState({
                    error: `Profile and Preset data could not be sent to handpiece unit(s).`,
                  });
                }
              }
              checkDone();
            } catch (err) {
              checkDone();
            }
          });
        }
      };

      addNewEquipment();
    });
  };

  equipRemove = (rows: Equipment[]) => {
    return new Promise(resolve => {
      const { location, orgMap } = this.state;
      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= rows.length) {
          resolve(undefined);
          this.props.retrieveData();
        }
      };

      const locOrg = get(orgMap, `${get(location, 'orgId')}`) as Org;

      rows.map(async _e => {
        const e = new _Equipment(_e);
        const me = get(this.props, 'sState.auth.user');
        set(e, 'locationId', null);
        set(e, 'updatedAt', moment().toISOString());
        set(e, 'updatedBy', me.userId);
        if (locOrg) {
          set(e, `${locOrg.orgType === 0 ? 'SO' : 'DO'}SaleDate`, '');
        }

        try {
          await updateEquipment(e);
          checkDone();
        } catch (err) {
          checkDone();
        }
      });
    });
  };
  toggleActive = () => {
    const dontSetInactive = this.state.equipment.length;
    const isActive = get(this.state, 'location.isActive');
    if (isActive && !!dontSetInactive) {
      this.setState({
        error: `Locations that are associated with equipment can 
        not be deleted or set to inactive.`,
      });
      return;
    }
    const setInactive = async () => {
      try {
        const location = {
          ...(this.state.location || {}),
          isActive: 0,
        } as Location;
        const resp = await updateLocation(location);
        if (resp) {
          this.setState({
            active: location.active,
            location,
            loading: false,
            success: `Location successfully set to Not Active`,
          });
        }
      } catch (err) {
        this.setState({
          loading: false,
          error:
            'There was a problem setting the active state of the Location.',
        });
      }
    };

    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Set Location to Not Active',
        content: 'Are you sure you want to set this location to not active?',
        onOk: setInactive,
        okText: 'OK',
      });
    };
    if (isActive && !dontSetInactive) {
      showConfirmModal();
    }

    if (!isActive) {
      const location = {
        ...(this.state.location || {}),
        isActive: 1,
      } as Location;
      updateLocation(location);
      this.setState({
        loading: false,
        location,
        active: location.isActive,
      });
    }

    return null;
  };

  deleteLoc = () => {
    const { equipment } = this.state;
    const dontDelete = equipment.length;

    const delLoc = async () => {
      const locId = get(this.state, 'location.id');
      try {
        const resp = await deleteLocation(locId);
        if (resp) {
          trackEvent(eventTypes.location_delete, {
            location: this.state.location,
            org: get(this.state.orgMap, get(this, 'state.location.orgId')),
          });
          this.props.history.goBack();
        }
      } catch (error) {
        console.warn(error);
        this.setState({ error: 'There was a problem deleting the location' });
      }
    };
    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Delete Location',
        content: 'Are you sure you want to delete this location?',
        onOk: delLoc,
        okText: 'Delete',
      });
    };

    if (!!dontDelete) {
      this.setState({
        error: `Locations that are associated with equipment can
        not be deleted or set to inactive.`,
      });
      return;
    } else {
      showConfirmModal();
    }
  };

  goToManageTandemGroups = () => {
    this.props.history.push('/dashboard/manageTandemGroups', {
      locationId: get(this.state, 'location.id', ''),
    });
  };

  goToViewTandemGroup = (tandemGroup: TandemGroup) => {
    this.props.history.push('/dashboard/manageTandemGroup', {
      tandemGroup,
    });
  };

  render() {
    const { sState } = this.props;
    const {
      loading,
      show,
      error,
      success,
      active,
      location,
      equipment,
      users,
      allEquipment,
      allUsers,
      tandemGroups,
    } = this.state;
    const isAdmin = userHasRole([0, 1, 2], sState);
    const isSuperOrTech = userHasRole([0, 1], sState);

    const canShowEdit = isAdmin && !isDentalOrgAdmin(sState) ? true : false;
    return (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link
            onClick={() => this.props.history.goBack()}
          >{`< Back to Manage Locations`}</Link>

          {canShowEdit && (
            <div
              css={css`
                margin-left: auto;
              `}
            >
              <ActiveSwitch
                canShowEdit={canShowEdit}
                active={active}
                toggleActive={this.toggleActive}
                loading={loading}
              />
            </div>
          )}
        </div>

        <div
          css={css`
            margin-left: auto;
          `}
        >
          {canShowEdit && (
            <div
              css={css(`
              display: flex; 
              float: right; 
              margin-left: 10px;`)}
            >
              {isSuperOrTech && (
                <Button
                  css={css(`
                      background-color: ${colors.error};
                      border: solid 1px ${colors.error};
                      margin-right: 10px;
                      `)}
                  title="Delete"
                  onClick={this.deleteLoc}
                />
              )}

              <Button
                css={css('float: right;')}
                title="Edit"
                onClick={this.toggleEdit}
              />
            </div>
          )}
        </div>

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <TableTitleHeader>{get(location, 'name', 'N/A')}</TableTitleHeader>
          <Button
            size={'small'}
            css={css('margin-left: 10px;')}
            loading={loading}
            title="Refresh"
            onClick={() => this.retrieveData()}
          />
        </div>

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          {this.renderSubInfo()}
        </div>

        {show && (
          <div>
            <TableListMultiGroup
              data={users as never}
              loading={loading}
              columns={UserColumns}
              canSelectRows={canShowEdit || isDentalOrgAdmin(sState)}
              canShowAdd={canShowEdit || isDentalOrgAdmin(sState)}
              variable={'group'}
              title={'Users'}
              removeLabel={'Remove Users'}
              confirmMessage={
                'Are you sure you want to remove these users from the Location?'
              }
              addBtn={'Add Users'}
              modalPlaceholder={'Add User'}
              modalTitle={'Add Users to Location'}
              allOpts={allUsers as never}
              onResult={this.userResult}
              onRemove={this.userRemove}
              totalTitle={(count: number) => `Total Users: ${count}`}
              blankText={'Location is not associated with any Users'}
              onDone={this.retrieveData}
            />

            <TableListMultiGroup
              data={equipment as never}
              loading={loading}
              columns={EquipColumns}
              canSelectRows={canShowEdit}
              canShowAdd={canShowEdit}
              title={'Equipment'}
              removeLabel={'Remove Equipment'}
              confirmMessage={
                'Are you sure you want to remove this equipment from the Location?'
              }
              modalTitle={'Add Equipment to Location'}
              variable={'equipment'}
              addBtn={'Add Equipment'}
              allOpts={allEquipment as never}
              onResult={this.equipResult}
              onRemove={this.equipRemove}
              totalTitle={(count: number) => `Total Equipment: ${count}`}
              blankText={'Location is not associated with any Equipment'}
              onDone={this.retrieveData}
              equipMap={this.state.equipMap}
            />
            <TableListSimple
              data={tandemGroups}
              loading={loading}
              columns={TandemGroupColumns(this)}
              title={'Tandem Groups'}
              addBtn={'Manage Tandem Groups'}
              totalTitle={(count: number) => `Total Tandem Groups: ${count}`}
              blankText={`Location is not associated with any Tandem Group`}
              onBtnClick={this.goToManageTandemGroups}
              rowClick={this.goToViewTandemGroup}
            />
          </div>
        )}
      </div>
    );
  }
}

export const ManageLocationComponent = withRouter(_ManageLocationComponent);
