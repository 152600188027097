/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, MouseEvent } from 'react';
import { Form, Alert, Input } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import styles from '../../_shared/styles';
import FormTitle from '../../_shared/form-title';
import { FormComponentProps } from 'antd/lib/form';
import { AppState } from '../../app.state';
import { Button } from '../../_shared/button';
import { MultiSelectSearch } from '../../_shared/multi-select-search';
import {
  userHasRole,
  uppercaseFirst,
  validPhoneNumber,
  buildErrorMsgFromForm,
  isServiceOrgAdmin,
  serviceOrgThenDentalOrgs,
  sortByLabel,
} from '../../utils';
import { User, _User } from '../../_shared/interfaces/user';
import { sendEmail } from '../../_shared/services/email.service';
import { Emails } from '../../_shared/lib/emails';
import {
  getUser,
  createUser,
} from '../../_shared/services/manage-users.service';
import Link from '../../_shared/link';
import { set, get, sortBy, size } from 'lodash';
import { Org } from '../../_shared/interfaces/org';
import { Select } from '../../_shared/select';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { Equipment } from '../../_shared/interfaces/equipment';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
}

interface Opt {
  title: string;
  value: string | number;
}

type Vars = 'locations' | 'equipment' | 'groups';

let THIS: any;

export const getRolesAvailableForUser = (sState: AppState) => {
  const {
    auth: { user, allRoles },
  } = sState;
  const roles: Opt[] = [];
  const roleValToMatch = 1;
  const isSuperOrTech = user && user.role < roleValToMatch;
  const isOrgAdmin = userHasRole([2], sState);

  allRoles.map(role => {
    const conv = {
      title: role.title,
      value: role.id,
    };
    if (isOrgAdmin) {
      if (
        role.id >= 2 &&
        role.id !== 5 &&
        role.title.indexOf('DentalEZ') === -1
      ) {
        roles.push(conv);
      }
    } else if (role.id < roleValToMatch && isSuperOrTech) {
      roles.push(conv);
    } else if (role.id >= roleValToMatch) {
      roles.push(conv);
    }
  });

  return roles.sort((a, b) =>
    a.title < b.title ? -1 : a.title > b.title ? 1 : 0
  );
};

export class _AddUserComponent extends Component<IProps> {
  // tslint:disable-next-line: no-any
  state: any = {
    loading: false,
    error: null,
    success: null,
    equipment: [],
    toDisplay: [
      {
        title: 'Role',
        var: 'role',
        type: 'dropdown',
        canDisplay: () => userHasRole([0, 1, 2, 3], this.props.sState),
        placeholder: 'Select',
        opts: () => getRolesAvailableForUser(this.props.sState),
      },
      {
        title: 'Equipment Types',
        var: 'eqTypeOptions',
        type: 'dropdown',
        canDisplay: () => {
          const {
            form: { getFieldValue },
          } = this.props;

          return getFieldValue('role') === 5;
        },
        opts: () => [
          { title: 'Utility', value: 'utility' },
          { title: 'Handpiece', value: 'handpiece' },
          { title: 'Equipment', value: 'chair' },
        ],
        props: {
          mode: 'multiple',
        },
      },
      {
        title: 'Organization',
        var: 'orgId',
        type: 'dropdown',
        canDisplay: () => {
          const { form, sState } = this.props;
          const hasRole = userHasRole([0, 1], sState);
          const isSOAdmin = isServiceOrgAdmin(sState);
          const currentRole = parseInt(form.getFieldValue('role'));
          return (hasRole || isSOAdmin) &&
            (currentRole >= 2 && currentRole !== 5 && currentRole !== 7)
            ? true
            : false;
        },
        value: (val: string) => {
          const { sState } = this.props;
          const isSOAdmin = isServiceOrgAdmin(sState);
          if (userHasRole([0, 1], sState) || isSOAdmin) {
            return val;
          } else {
            return sState.auth.user && sState.auth.user.orgId;
          }
        },
        placeholder: 'Select',
        opts: () => {
          const { sState } = this.props;
          const isSOAdmin = isServiceOrgAdmin(sState);

          if (isSOAdmin) {
            return serviceOrgThenDentalOrgs(sState);
          } else {
            return sState.dash.orgs
              .map(o => ({ title: o.name, label: o.name, value: o.id }))
              .sort(sortByLabel);
          }
        },
        options: () => {
          const { form } = this.props;
          const isRole =
            parseInt(form.getFieldValue('role')) >= 2 ? true : false;
          if (isRole) {
            return {
              rules: [
                {
                  required: true,
                  message: 'Organization is required. ',
                },
              ],
            };
          }
          return undefined;
        },
      },
      {
        title: 'Branch Location',
        var: 'branchLocation',
        required: () => false,
        canDisplay: () => {
          const { form } = this.props;
          return parseInt(form.getFieldValue('role')) == 6;
        },
        options: {
          rules: [
            {
              max: 80,
              message: 'Branch Location is limited to 80 characters. ',
            },
          ],
        },
      },
      {
        title: 'Name',
        var: 'name',
        transform: (value: string) => uppercaseFirst(value),
        options: {
          rules: [
            {
              required: true,
              message: 'Name is required. ',
            },
            {
              max: 50,
              message: 'Name is limited to 50 characters. ',
            },
          ],
        },
      },
      {
        title: 'Email',
        var: 'email',
        options: {
          validateTrigger: 'onBlur',
          normalize: (value: string) => value && value.trim(),
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid E-mail. ',
            },
            {
              required: true,
              message: 'The input is not a valid E-mail. ',
            },
            {
              max: 60,
              message: 'Email is limited to 60 characters. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'phone',
        required: () => false,
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              // tslint:disable-next-line:no-any
              validator: (rule: any, value: string, cb: any) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
          ],
        },
      },
      {
        title: 'Locations',
        var: 'locations',
        type: 'AutoComplete',
        required: () => false,
        canDisplay: () => {
          const { form, sState } = this.props;
          const role = parseInt(form.getFieldValue('role'));
          const orgId = form.getFieldValue('orgId');
          const orgs = get(sState, 'dash.orgs', []) as Org[];
          const org = orgs.find(o => o.id === orgId);

          if (role >= 3 && role !== 5 && role !== 7 && role !== 6) {
            if (org && org.orgType === 0) {
              return false;
            }
            return true;
          }
          return false;
        },
        value: () => {
          const {
            sState: {
              auth: { user },
            },
          } = this.props;
          if (user && user.locations.length > 0) {
            return user.locations;
          } else {
            return true;
          }
        },
        data: () => {
          const { form } = this.props;
          const orgId = form.getFieldValue('orgId');

          return this.props.sState.dash.locations
            .filter(l => l.orgId === orgId)
            .map(l => l.name || '');
        },
        selected: [] as string[],
      },
      {
        title: 'Groups',
        var: 'groups',
        type: 'AutoComplete',
        required: () => false,
        data: () => {
          const { form } = this.props;
          const orgId = form.getFieldValue('orgId');

          return this.props.sState.dash.groups
            .filter(g => g.orgId === orgId)
            .map(g => g.name || '');
        },
        canDisplay: () => {
          const { form } = this.props;
          const role = parseInt(form.getFieldValue('role'));
          return role >= 3 && role !== 5 && role !== 7 && role !== 6
            ? true
            : false;
        },
        selected: [] as string[],
      },
      {
        title: 'Equipment',
        var: 'equipment',
        required: () => false,
        type: 'AutoComplete',
        canDisplay: () => {
          const { form } = this.props;
          const role = parseInt(form.getFieldValue('role'));
          return role >= 3 && role !== 5 && role !== 7 && role !== 6
            ? true
            : false;
        },
        data: () => {
          const { form } = this.props;
          const orgId = form.getFieldValue('orgId');

          return (get(this, 'state.equipment', []) as Equipment[])
            .filter(e => e.dentalOrgId === orgId || e.serviceOrgId === orgId)
            .map(l => l.name || l.id);
        },
        selected: [] as string[],
      },
    ],
  };
  componentDidMount = async () => {
    THIS = this;
    const equipment = await getEquipments();
    this.setState({ equipment });
    if (get(this.props, 'location.state.role')) {
      this.props.form.setFieldsValue({
        role: this.props.location.state.role,
        orgId: this.props.location.state.orgId,
      });
    }
  };
  componentWillUnmount(): void {
    THIS = undefined;
  }
  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const {
          sState: { dash, auth },
        } = this.props;

        const me = auth.user && auth.user.userId;
        const user = new _User({ createdBy: me, updatedBy: me } as User);

        const getIdsFromVal = (
          selected: string[],
          // tslint:disable-next-line:no-any
          listToSearch: any[]
        ) => {
          const ret: string[] = [];
          selected.map(s => {
            const val = listToSearch.find(l => l.name === s);
            if (val) {
              ret.push(val.id);
            }
          });
          return ret;
        };

        // tslint:disable-next-line: no-any
        this.state.toDisplay.map((item: any) => {
          let val = values[item.var];
          if (item.type === 'AutoComplete' && size(getIdsFromVal(item.selected as string[], dash[item.var as Vars]))) {
            const vv = item.var as Vars;
            const dashItem = dash[vv];
            val = getIdsFromVal(item.selected as string[], dashItem);
          } else if (item.value && item.value(val) !== undefined) {
            val = item.value(val);
          }
          user[item.var] = val;
        });
        user.email = user.email.toLowerCase();
        user.userId = user.email.toLowerCase();
        const uState = ({ auth: { user } } as unknown) as AppState;
        if (userHasRole([3], uState)) {
          user.role = 4;
        }

        //set users notification info
        if (userHasRole([3], uState)) {
          //orggroupadmin
          set(user, 'notification.alerts', ['email']);
        } else if (userHasRole([4], uState)) {
          set(user, 'notification.alerts', ['email']);
        }

        if (user.orgId && userHasRole([3, 4], uState)) {
          set(user, 'notification.alerts', ['email']);
          set(user, 'notification.service', ['email']);
        }

        // no notifications for DEZ RO, or Org RO DEN-876, DEN-1022
        if (userHasRole([6, 7], uState)) {
          set(user, 'notification.events', []);
          set(user, 'notification.alerts', []);
          set(user, 'notification.service', []);
        }

        try {
          const userExists = await getUser(user.email);

          if (userExists) {
            return this.setState({
              error:
                // tslint:disable-next-line:max-line-length
                'That user already exists. Please use the edit user screen, or use a different email.',
              loading: false,
            });
          }
        } catch (err) {}

        const Email = Emails.invite({ ToAddresses: [user.email], props: { user, orgs: dash.orgs } });

        await sendEmail(Email, Email.template);
        await createUser(user);
        const org = dash.orgs.find(o => o.id === user.orgId);
        trackEvent(eventTypes.user_add, { user_added: user, org });
        this.props.history.push('/dashboard/manageUsers', {
          toast: 'New user has been invited',
        });
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };
  autoSelected = () => {};
  cancel = () => {
    this.props.history.goBack();
  };
  isSubmitDisabled = () => {
    const { toDisplay } = this.state;
    const {
      form: { getFieldValue },
    } = this.props;
    // tslint:disable-next-line: no-any
    const hasVals = toDisplay.filter((td: any) => {
      const canDisplay = td.canDisplay ? td.canDisplay() : true;
      const val = getFieldValue(td.var);
      const required =
        td.required && typeof td.required === 'function' && td.required();
      return (
        !canDisplay ||
        (td.type === 'AutoComplete' && td.selected
          ? td.selected.length > 0
          : val !== undefined) ||
        required === false
      );
    });
    return hasVals.length < toDisplay.length;
  };
  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { toDisplay, loading, error, success } = this.state;

    const btnDisabled = this.isSubmitDisabled();
    return (
      <div css={css(styles.formContainer)}>
        <div css={css(`margin-bottom: 10px;`)}>
          <Link onClick={this.cancel}>{`< Back to Users`}</Link>
          <FormTitle
            size={'20px'}
            css={css`
              margin-top: 5px;
              margin-bottom: 0px;
            `}
            title={`Invite User`}
          />
        </div>

        {error && (
          <Alert
            css={css(styles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(styles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <Form layout="vertical" onSubmit={this.submit}>
          {/* tslint:disable-next-line: no-any*/}
          {toDisplay.map((item: any, i: number) => {
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            const itemOptions =
              typeof item.options === 'function'
                ? item.options()
                : item.options;
            return !canDisplay ? null : (
              <Form.Item key={i} label={item.title}>
                {getFieldDecorator(item.var as string, itemOptions)(
                  item.type === 'dropdown' && item.opts ? (
                    <Select
                      {...(item.props || {})}
                      placeholder={item.placeholder}
                    >
                      {/* tslint:disable-next-line: no-any*/}
                      {item.opts().map((opt: any, i: number) => (
                        <Select.Option key={i} value={opt.value}>
                          {opt.title}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : item.type === 'AutoComplete' ? (
                    <MultiSelectSearch
                      dataSource={item.data && sortBy(item.data())}
                      placeholder={'Search...'}
                      onTagsChange={selected => {
                        item.selected = selected;
                        this.forceUpdate();
                      }}
                    />
                  ) : (
                    <Input />
                  )
                )}
              </Form.Item>
            );
          })}

          <Button
            title={'Cancel'}
            disabled={loading}
            css={css`
              margin-right: 10px;
            `}
            outline={true}
            onClick={this.cancel}
          />
          <Button
            title={'Submit'}
            loading={loading}
            onClick={this.submit}
            disabled={btnDisabled}
          />
        </Form>
      </div>
    );
  }
}

export const AddUserComponent = Form.create({ 
  onValuesChange: () => { 
    //NOT IDEAL, but in reference to this ticket https://heliosgroup.atlassian.net/browse/AER-1337
    THIS && THIS.forceUpdate && THIS.forceUpdate();
  }})(withRouter(_AddUserComponent));
