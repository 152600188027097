import { AppState } from '../app.state';
import {
  signUp as signUpApi,
  confirmSignUp,
  signIn as signInApi,
  forgotPassword as fgPasswordApi,
  forgotPasswordSubmit as fgPasswordSubmitApi,
} from '../_shared/services/authentication.service';
import { AuthActions } from './authentication.reducer';
import { getAllRoles } from '../_shared/services/roles.service';
import {
  createUser,
  generatePinAndSendMsgToUser,
  getToken,
  getUser,
  updateUser as updateUserApi,
} from '../_shared/services/manage-users.service';
import { User } from '../_shared/interfaces/user';
import { set } from 'lodash';
import moment from 'moment';
import localforage from 'localforage';
import { eventTypes, trackEvent } from '../_shared/services/analytics.service';
import { encryptString } from '../utils';

export const newSignUp = (user: User, password: string) => async (dispatch: Dispatch<{}>, getState: () => AppState) => {
  try {
    if (!user.email) {
      throw new Error('No email provided');
    }
  
    const userExists = await getUser(user.email).catch(() => 0);
  
    if (userExists) {
      throw new Error('User already exists. Please select Back to Sign In to log in to your account.')
    }

    await createUser(user);

    await signUpApi(encryptString(JSON.stringify({ email: user.email })), password);
  } catch (err) {
    throw err;
  }
}

export const signUp = (username: string, password: string) => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const resp = await signUpApi(username, password);

  dispatch({
    type: AuthActions.SET_CURRENT_USER,
    payload: { email: username },
  });

  return resp ? true : false;
};

export const signIn = (
  username: string,
  password: string,
  checkRole = true,
  setToActive = false,
  forcePin = false,
) => async (dispatch: Dispatch<{}>, getState: () => AppState) => {
  try {
    const resp = await signInApi(username, password);

    const user = await getUser(username);

    if (forcePin || (user.role === 0 && checkRole) || (user.isVerified === false && !setToActive)) {
      const { method } = await generatePinAndSendMsgToUser(username);
      return { pin: true, method };
    }

    if (setToActive) {
      await updateUserApi({
        ...user,
        isVerified: true,
        isActive: 1,
      });
    }

    dispatch({
      type: AuthActions.SET_CURRENT_USER,
      payload: resp.attributes,
    });

    if (resp) {
      await dispatch(getUserData());
      const _user = getState().auth.user;
      if (_user && !_user.isActive) {
        await localforage.clear();
        dispatch({
          type: AuthActions.LOG_USER_OUT,
        });
        throw new Error('User is Inactive. You have been logged out.')
      }
      trackEvent(eventTypes.log_in);
    }
    return resp ? true : false;
  } catch (err) {
    throw err;
  }
};

export const getUserData = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const sState = getState();
  const auth = sState.auth;

  if (auth.user) {
    try {
      await dispatch(loadToken());
      const allRoles = await getAllRoles();
      const user = await getUser(auth.user.email);
      dispatch({
        type: AuthActions.SET_CURRENT_USER,
        payload: {
          ...auth.user,
          ...user,
        },
      });
      return dispatch({ type: AuthActions.SET_ALL_ROLES, payload: allRoles });
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }
};

export const updateUser = (user: User, shouldSetCurrent: boolean) => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const cUser = getState().auth.user as User;
  set(user, 'updatedBy', cUser.userId);
  set(user, 'updatedAt', moment().toISOString());
  try {
    const u = await updateUserApi({
      ...user,
      userId: user.userId,
    });
    if (shouldSetCurrent) {
      dispatch({ type: AuthActions.SET_CURRENT_USER, payload: u });
    }

    return getState().auth.user;
  } catch (err) {
    console.warn(err);
    throw new Error(err);
  }
};

export const verify = (email: string, code: string) => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  let resp = { attributes: {}, error: undefined };
  try {
    resp = await confirmSignUp(email, code);
  } catch (err) {
    resp.error = err;
  }
  return resp;
};

export const forgotPassword = (email: string) => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  return await fgPasswordApi(email);
};

export const forgotPasswordSubmit = (
  email: string,
  code: string,
  password: string
) => async (dispatch: Dispatch<{}>, getState: () => AppState) => {
  return await fgPasswordSubmitApi(email, code, password);
};

export const loadToken = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  try {
    const sState = getState();
    const auth = sState.auth;

    const token = await getToken(auth.user || { temp: true });

    dispatch({
      type: AuthActions.SET_TOKEN,
      token,
    });
  } catch (err) {
    console.error('FAILED TO SET TOKEN', err.message);
  }
};
