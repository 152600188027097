/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import { Component } from 'react';
import colors from '../colors';

interface IProps {
  title?: string;
  size?: string;
  css?: SerializedStyles;
}

export default class FormTitle extends Component<IProps> {
  render() {
    const { title, children, size } = this.props;
    return (
      <div
        {...this.props}
        css={css(
          `
          color: ${colors.highlight};
          font-size: ${size || '12px'};
          font-weight: bold;
        `,
          this.props.css
        )}
      >
        {title || children}
      </div>
    );
  }
}
