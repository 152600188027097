import { ProcedureType } from './procedure-type';
import { guid } from '../../utils';
export interface Preset extends ProcedureType {
  [key: string]: string | number | undefined | string[] | boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  profileId: string;
  type?: string;
}

export class _Preset {
  constructor(props?: Preset) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]: string | number | undefined | string[] | boolean;
  id = guid();
  name = '';
  createdAt = new Date().toISOString();
  createdBy = '';
  updatedAt = new Date().toISOString();
  updatedBy = '';
  profileId = '';
  speed = undefined;
  gearRatio = '';
  endoSettings = '';
  lightIntensity = undefined;
  torque = undefined;
  rotation = '';
  oscillation = '';
}
