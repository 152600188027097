/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ChangeEvent, Component, FormEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import Link from '../../_shared/link';
import FormTitle from '../../_shared/form-title';
import { get, set } from 'lodash';
import styles from './edit-profile.styles';
import { Form, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { buildErrorMsgFromForm, uppercaseWords, isPB } from '../../utils';
import { Button } from '../../_shared/button';
import { User, _User } from '../../_shared/interfaces/user';
import { updateProfile } from '../../_shared/services/manage-profiles.service';
import { Profile, _Profile } from '../../_shared/interfaces/profile';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateProfiles: () => Promise<void>;
}

interface Opt {
  label: string;
  value?: string | number;
  orgId?: string;
  title?: string;
}
class EditProfile extends Component<IProps> {
  state = {
    back: undefined,
    profile: undefined as Profile | undefined,
    loading: false,
    error: null,
    success: null,
    nameError: null,
    toDisplay: [
      {
        title: 'Profile Name',
        var: 'name',
        transform: (value: string) => uppercaseWords(value),
        options: {
          initialValue: get(this.props, 'history.location.state.profile.name'),
          rules: [
            {
              required: true,
              message: 'Name is required',
            },
            {
              max: 16,
              message: 'Name is limited to 16 characters. ',
            },
          ],
        },
      },
    ],
  };

  componentDidMount = async () => {
    const {
      history: { location },
    } = this.props;
    const back = get(location, 'state.back');
    const profile = get(location, 'state.profile', new _Profile());
    const profileOriginal = get(location, 'state.profile');
    this.setState({
      back,
      profile,
      profileOriginal,
    });
  };

  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.state.nameError = null;
    this.setState(this.state);
    const { profile } = this.state;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const _profile = new _Profile({
          ...((profile as Profile) || {}),
        }) as Profile;

        const date = new Date().toISOString();
        const currentUser = get(this.props.sState, 'auth.user', {}) as User;

        set(_profile, 'updatedAt', date);
        set(_profile, 'updatedBy', currentUser.userId);
        set(_profile, 'name', values.name);
        await updateProfile(_profile);
        this.props.updateProfiles();
        message.success('Profile has been saved');
        this.setState({
          loading: false,
        });
        if (get(this.props, 'location.pathname').includes('editProfileAdmin')) {
          return this.goToMyOrg();
        }
        this.manageProfiles(_profile);
      } catch (error) {
        message.error('Profile could not be updated');
        return this.setState({
          loading: false,
        });
      }
    });
  };

  setFieldsOriginal = () => {
    const { toDisplay, profile } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    const Profile = new _Profile({
      ...(profile || {}),
    } as Profile);
    toDisplay.map(item => {
      item.var && setFieldsValue({ [item.var]: Profile[item.var] });
    });
  };

  goToMyOrg = () => this.props.history.push('/dashboard/myOrg');

  goBack = () => {
    this.setFieldsOriginal();
    this.props.history.goBack();
  };

  manageProfiles = (profile: Profile) => {
    this.props.history.push('/dashboard/profiles', {
      profile,
    });
  };
  onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ nameError: null });
    const name = event.target.value;

    if (name.length > 16) {
      this.setState({
        nameError: 'Preset names are limited to 16 characters.',
      });
    }
  };

  render() {
    const { back, profile, toDisplay, loading, error, nameError } = this.state;
    const { sState } = this.props;
    const ispb = isPB(sState);
    const {
      form: { getFieldDecorator },
    } = this.props;
    const disabled = !!error || !!nameError;
    return (
      <div css={css(styles.container)}>
        {!!back && (
          <Link
            css={css`
              margin-bottom: 5px;
            `}
            onClick={this.goBack}
          >
            {back}
          </Link>
        )}
        <div
          css={css(
            styles.container,
            !ispb && `flex-direction: row;`,
            ispb && 'margin-bottom: 0px;'
          )}
        >
          {profile ? (
            <div css={css(styles.mainContainer)}>
              <FormTitle size={'20px'} title={'Edit Handpiece Profile'} />

              <Form layout="vertical" onSubmit={this.submit}>
                <div css={css(styles.formContainer)}>
                  {toDisplay.map((item, i) => {
                    return (
                      <Form.Item key={i} label={item.title}>
                        {getFieldDecorator(item.var, item.options)(
                          <Input
                            onChange={this.onChangeName}
                            css={css(`width: 400px;`)}
                          />
                        )}
                      </Form.Item>
                    );
                  })}
                </div>
                <Button
                  title={'Cancel'}
                  disabled={loading}
                  css={css`
                    margin-right: 10px;
                  `}
                  outline={true}
                  onClick={this.goBack}
                />
                <Button
                  title={'Save'}
                  loading={loading}
                  onClick={this.submit}
                  disabled={disabled}
                />
              </Form>
            </div>
          ) : (
            <div>No Preset Profile Provided</div>
          )}
        </div>
      </div>
    );
  }
}

export const EditProfileComponent = Form.create()(withRouter(EditProfile));
