import { guid } from '../../utils';
import { EQStatus, EquipmentStatus, _EQStatus, _EquipmentStatus } from './equipmentStatus';
import { set, get, unset } from 'lodash';

//#region
export enum EMode {
  maintenance = 'maintenance',
  debug = 'debug',
}

export enum EQ_TYPE {
  Compressor = 'Aeras Compressor',
  Vacuum = 'Aeras Vacuum',
  Handpiece = 'Handpiece',
  Chair = 'Chair',
  Unknown = 'Unknown Equipment Type',
}

export const ETYPES = {
  compressor: 'compressor',
  vacuum: 'vacuum',
  chair: 'chair',
  handpiece: 'handpiece',
  sterilizer: 'sterilizer',
  aerasone_compressor: 'aerasone_compressor',
  aerasone_vacuum: 'aerasone_vacuum',
  unknown: 'unknown',
};

export function eqTypeFromString(str: string | undefined): EQ_TYPE {
  for (const eType in EQ_TYPE) {
    if (
      str !== undefined &&
      (eType.toLowerCase() === str.toLowerCase() ||
        EQ_TYPE[eType].toLowerCase() === str.toLowerCase())
    ) {
      return EQ_TYPE[eType] as EQ_TYPE;
    }
  }
  return EQ_TYPE.Unknown;
}

export enum SUBSCRIPTION_TYPE {
  trial = 'trial',
  monthly = '1month',
  yearly = '1year',
  multiyear = '8year',
}

export const SUB_TYPE_DISPLAY: { [key: string]: string } = {
  trial: 'Trial',
  '1month': '1 Month',
  '1year': '1 Year',
  '8year': '8 Year',
};

export interface PSerials {
  controllerSerial: string; //or __none__
  deviceId: string; //or __none__
}

export interface Subscription {
  [key: string]: string;
  subscriptionStart: string;
  subscriptionEnd: string;
  billingPreference: string;
}

export interface IInvoice {
  billSpanEndDate: string;
  billSpanStartDate: string;
  invoiceDate: string;
  invoiceLine: string;
  invoiceNumber: string;
  nextInvoiceDate: string;
}
//#endregion

export interface Equipment {
  [key: string]:
    | string
    | number
    | undefined
    | string[]
    | boolean
    | PSerials[]
    | boolean[]
    | Subscription[]
    | IInvoice[]
    | EQStatus
    | EquipmentStatus;

  pk?: string; //is required but gets automatically created by calling _Equipment
  id?: string;
  sk?: string; //sk automatically gets filled in by deviceId. deviceId is essentially the only thing require to create equipment
  deviceId: string;

  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  serviceOrgId?: string;
  dentalOrgId?: string;
  locationId?: string;
  isActive?: number;
  deviceStatus?: string;
  alert?: number;
  installDate?: string;
  modelId?: string;
  name?: string;
  mSerial1?: string;
  mSerial2?: string;
  mSerial3?: string;
  tankSerial?: string;
  dryerSerial?: string;
  pumpSerial?: string;
  hardwareVersion?: string;
  fw_version?: number;
  soPurchaseOrder?: string;
  doPurchaseOrder?: string;
  groups?: string[];
  serviceOrgNumber?: string;
  test?: string;
  testCompletion?: string;
  onConsignment?: boolean;
  equipmentSN?: string;
  showOnDash?: boolean;
  previousSerials?: PSerials[];
  testDataUrl?: string;
  DOSaleDate?: string;
  serviceRenewalDate?: string;
  controllerSerial?: string;
  tandemGroupId?: string;
  tandemGroupRole?: string;
  hw_version?: string;
  modes?: boolean[];
  subscriptionHistory?: Subscription[];
  alwaysSmart?: boolean;
  invoiceHistory?: IInvoice[];
  bars?: number;
  lastPublish?: string;
}

export class _Equipment {
  constructor(props?: Equipment) {
    for (let key in props) {
      this[key] = props[key];
    }
    set(this, 'sk', get(props, 'deviceId', ''));
  }
  [key: string]:
    | string
    | number
    | undefined
    | string[]
    | boolean
    | PSerials[]
    | boolean[]
    | Subscription[]
    | IInvoice[]
    | EQStatus
    | EquipmentStatus;

  pk = 'equipment';
  sk = new Date().toISOString();
  deviceId = '';

  id = guid();
  createdAt = new Date().toISOString();
  createdBy = '';
  updatedAt = new Date().toISOString();
  updatedBy = '';
  serviceOrgId = undefined;
  dentalOrgId = undefined;
  locationId = '';
  isActive = 1;
  deviceStatus = 'off';
  test = undefined;
  alert = 0;
  installDate = new Date().toISOString();
  modelId = '';
  name = '';
  equipmentSN = '';
  controllerSerial = '';
  mSerial1 = '';
  mSerial2 = '';
  mSerial3 = '';
  tankSerial = '';
  dryerSerial = '';
  pumpSerial = '';
  hardwareVersion = '';
  fw_version = undefined;
  soPurchaseOrder = '';
  doPurchaseOrder = '';
  groups = [];
  serviceOrgNumber = '';
  onConsignment = false;
  showOnDash = false;
  previousSerials = undefined;
  modes = [];
  subscriptionHistory = [];
  alwaysSmart = false;
  invoiceHistory = [];
  bars = 0;
  lastPublish = undefined;
}

export interface EquipmentRecord extends Equipment {
  status?: EQStatus; //only the relevant alert statuses needed to render on dashboard
  _status?: EquipmentStatus; //status if full status of all the most recent type publishes
}

export class _EquipmentRecord implements EquipmentRecord {
  constructor(props?: EquipmentRecord) {
    const oldEqRecord = get(props, 'equipment');

    //old eq record format, this pulls it out and puts it at the top level as expected
    if (!!oldEqRecord) {
      Object.keys(oldEqRecord).map(k => {
        const val = get(oldEqRecord, k);
        if (val !== '') {
          set(this, k, val);
        }
      });
      unset(props, 'equipment');
    }

    for (let key in props) {
      const val = props[key];
      if (val !== '') {
        set(this, key, val);
      }
    }

    this.sk = this.deviceId || new Date().toISOString();
  }
  [key: string]:
    | string
    | number
    | undefined
    | string[]
    | boolean
    | PSerials[]
    | boolean[]
    | Subscription[]
    | IInvoice[]
    | EQStatus
    | EquipmentStatus;

  pk = 'equipment';
  sk = new Date().toISOString();

  id = guid();
  createdAt = new Date().toISOString();
  createdBy = '';
  updatedAt = new Date().toISOString();
  updatedBy = '';
  deviceId = '';
  serviceOrgId = undefined;
  dentalOrgId = undefined;
  locationId = '';
  isActive = 1;
  deviceStatus = 'off';
  test = undefined;
  alert = 0;
  installDate = new Date().toISOString();
  modelId = '';
  name = '';
  equipmentSN = '';
  controllerSerial = '';
  mSerial1 = '';
  mSerial2 = '';
  mSerial3 = '';
  tankSerial = '';
  dryerSerial = '';
  pumpSerial = '';
  hardwareVersion = '';
  fw_version = undefined;
  soPurchaseOrder = '';
  doPurchaseOrder = '';
  groups = [];
  serviceOrgNumber = '';
  onConsignment = false;
  showOnDash = false;
  previousSerials = undefined;
  modes = [];
  subscriptionHistory = [];
  alwaysSmart = false;
  invoiceHistory = [];
  bars = 0;
  lastPublish = undefined;

  status = new _EQStatus();
  _status = undefined;
}
