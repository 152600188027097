/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Select as AntSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { isEqual, size } from 'lodash';

interface IProps extends SelectProps {
  // tslint:disable-next-line:no-any
  children: any;
  useSizeCompare?: boolean;
}

export class Select extends Component<IProps> {
  static Option = AntSelect.Option;
  static defaultProps: { useSizeCompare: boolean };
  shouldComponentUpdate(nextProps: IProps) {
    const { useSizeCompare } = this.props;

    const valEqual = isEqual(nextProps.value, this.props.value);
    const childEqual = useSizeCompare
      ? size(nextProps.children) === size(this.props.children)
      : isEqual(nextProps.children, this.props.children);
    const defEqual = isEqual(nextProps.defaultValue, this.props.defaultValue);

    if (!valEqual || !childEqual || !defEqual) {
      return true;
    }
    return false;
  }
  render() {
    return <AntSelect showArrow={true} {...this.props} />;
  }
}

Select.defaultProps = {
  useSizeCompare: true,
};
