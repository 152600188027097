import { get } from 'lodash';
import { Org } from '../interfaces/org';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getOrg = async (id: string) => {
  const resp = await instance.get(`/org/${id}`, { data: {} });
  return get(resp, 'data.org', null) as Org;
};

export const getOrgs = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/org`, {
      where,
    });
  } else {
    resp = await instance.get(`/org`, { data: {} });
  }
  return get(resp, 'data.orgs', []) as Org[];
};

export const createOrg = async (org: Org) => {
  const resp = await instance.post('/org/create', {
    org: removeEmpty(org),
  });
  return get(resp, 'data.org', null) as Org;
};

export const updateOrg = (org: Org) => {
  return createOrg(org);
};

export const deleteOrg = async (orgId: string) => {
  const resp = await instance.delete(`/org/${orgId}`);
  return get(resp, 'data');
};
