/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { CompressorSensorStatisticsPData } from '../../../../_shared/interfaces/publishData';
import { makeInput, makeNumInput } from '../../spoof-a-publish.component';
import { minMaxAvgInputs } from './base-sensor-stats-form.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: CompressorSensorStatisticsPData | undefined) => void;
}

export const CompressorSensorStatsDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [tT, setTT] = useState<string | undefined>(undefined);
  const [hDC, setHDC] = useState<number | undefined>(undefined);
  const [m2P, setM2P] = useState<string | undefined>(undefined);
  const [m2T, setM2T] = useState<string | undefined>(undefined);
  const [tH, setTH] = useState<string | undefined>(undefined);
  const tpMinState = useState<number | undefined>(undefined);
  const tpMaxState = useState<number | undefined>(undefined);
  const tpAvgState = useState<number | undefined>(undefined);
  const [pDP, setPDP] = useState<string | undefined>(undefined);
  const [rGT, setRGT] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      tT !== undefined &&
      hDC !== undefined &&
      m2P !== undefined &&
      m2T !== undefined &&
      tH !== undefined &&
      tpMinState[0] !== undefined &&
      tpMaxState[0] !== undefined &&
      tpAvgState[0] !== undefined &&
      pDP !== undefined &&
      rGT !== undefined
    ) {
      validChanged(true);
      payloadChanged({
        tT: tT,
        hDC: hDC,
        m2P: m2P,
        m2T: m2T,
        tH: tH,
        tP: [tpMinState[0], tpMaxState[0], tpAvgState[0]],
        pDP: pDP,
        rGT: rGT,
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [
    tT,
    hDC,
    m2P,
    m2T,
    tH,
    tpMinState[0],
    tpMaxState[0],
    tpAvgState[0],
    pDP,
    rGT,
  ]);

  return (
    <div>
      {makeInput('TT', tT, setTT)}
      {makeNumInput('HDC', hDC, setHDC)}
      {makeInput('M2P', m2P, setM2P)}
      {makeInput('M2T', m2T, setM2T)}
      {makeInput('TH', tH, setTH)}
      {minMaxAvgInputs('TP', tpMinState, tpMaxState, tpAvgState)}
      {makeInput('PDP', pDP, setPDP)}
      {makeInput('RGT', rGT, setRGT)}
    </div>
  );
};
