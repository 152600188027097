/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, ReactNode } from 'react';
import Content from './login.content';
import {
  getContent,
  buildErrorMsgFromForm,
  testPassword,
  PWReqsMessage,
} from '../../utils';
import { Button } from '../../_shared/button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert, Checkbox, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import { AppState } from '../../app.state';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { decryptString } from '../../utils';
import moment from 'moment';
import { Emails } from '../../_shared/lib/emails';
import { sendEmail } from '../../_shared/services/email.service';
import { getUser } from '../../_shared/services/manage-users.service';
import { get, noop } from 'lodash';
import { getUrl } from '../../config';

interface IProps extends RouteComponentProps, FormComponentProps {
  signUp: (un: string, pw: string) => Promise<boolean>;
  signIn: (
    un: string,
    pw: string,
    checkRole?: boolean,
    setToActive?: boolean
  ) => Promise<boolean | { pin: string; method: string }>;
  appState: AppState;
  destination?: string;
  inputContainer?: () => ReactNode;
}

class LoginComponent extends Component<IProps> {
  state = {
    error: null,
    loading: false,
    isSignUp: false,
    checked: false,
    email: '',
    estring: '',
    isExpiredLink: false,
  };
  componentDidMount = async () => {
    const { state } = this.props.history.location;
    const {
      form: { setFields },
    } = this.props;

    if (state && state.signUp) {
      this.setState({ loading: true });
      let email = state.signUp;
      try {
        const vals = JSON.parse(decryptString(state.signUp));
        email = vals.email;
        const diff = moment().diff(moment(vals.date), 'hours');
        const user = await getUser(email);

        const isActive = get(user, 'isActive');
        if (isActive) {
          return this.setState({
            error: 'This Sign Up Link has already been used. Please login.',
            loading: false,
          });
        }

        if (diff > 24) {
          this.setState({
            isExpiredLink: true,
            email,
            loading: false,
          });
        }
      } catch (err) {
        console.error('An unknown error occurred. Please try again.');
      }

      setFields({
        email: {
          value: email,
        },
      });

      this.setState({ isSignUp: true, estring: state.signUp, loading: false });
    }
  };
  checkIfEmailAlreadyUsed = async () => {};
  goToForgotPassword = () => {
    this.props.history.push('/forgotPassword', { destination: this.props.destination });
  };
  signInSubmit = async (e?: FormEvent) => {
    e && e.preventDefault && e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      const { email, password } = values;

      const _email = email.toLowerCase().trim();

      const doTheSignin = async (checkRole = true, setToActive = false) => {
        try {
          const req = await this.props.signIn(
            _email,
            password,
            checkRole,
            setToActive
          );
          if (!req) {
            return this.setState({
              error: 'That username and password combination is not valid.',
              loading: false,
            });
          } else if (get(req, 'pin')) {
            this.props.history.push('/verify', {
              destination: this.props.destination,
              email: _email,
              password,
              is2fa: get(req, 'pin'),
              method: get(req, 'method'),
            });
          } else {
            this.props.history.push(this.props.destination || '/dashboard');
          }
        } catch (err) {
          if (err && err.code === 'UserNotConfirmedException') {
            this.props.history.push('/verify', {
              destination: this.props.destination,
              email: _email,
              password,
            });
          } else {
            this.setState({
              error: get(
                err,
                'message',
                'That username and password combination is not valid.'
              ),
              loading: false,
            });
          }
        }
      };

      if (this.state.isSignUp) {
        try {
          const test = testPassword(password);
          if (!test) {
            return this.setState({
              error: PWReqsMessage,
              loading: false,
            });
          }
          await this.props.signUp(this.state.estring, password);
          doTheSignin(true, true);
        } catch (err) {
          if (err.message.indexOf('Email address already exists') > -1) {
            this.setState(
              {
                isSignUp: false,
                loading: false,
              },
              () => this.signInSubmit()
            );
          } else {
            this.setState({
              error: err.message,
              loading: false,
            });
          }
        }
      } else {
        doTheSignin();
      }
    });
  };

  onChange = (e: CheckboxChangeEvent) => {
    this.setState({ checked: e.target.checked });
  };

  resendCode = async () => {
    try {
      const { email } = this.state;
      const {
        form: { setFields },
      } = this.props;
      const Email = Emails.invite({ ToAddresses: [email] });

      await sendEmail(Email);
      this.setState({
        email: undefined,
        isExpiredLink: false,
        estring: '',
        isSignUp: false,
      });
      setFields({
        email: {
          value: '',
        },
      });
      message.success('New Sign Up Link sent to email');
    } catch (err) {
      message.error(
        `There was a problem sending a Sign Up link to that email. Please try again.`
      );
    }
  };

  renderExpired = () => {
    return (
      <div className="Login" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <Alert message="This Sign Up link has expired." type="error" />

        <div css={css(styles.buttonContainer, `margin-top: 20px;`)}>
          <Button
            css={css('margin-bottom: 10px;')}
            onClick={this.resendCode}
            title="Resend Sign Up Link"
          />
        </div>
      </div>
    );
  };

  getErrorText = (error: string | null) => {
    if (error && error.toLowerCase().includes('network error')) {
      return `${error}. Please make sure that the domains https://aeras.dentalez.com and ${getUrl().replace('/api', '')} are allowed under your organization’s IT policies`;
    }
    return error;
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { error, loading, isSignUp, isExpiredLink } = this.state;
    const btnDisabled =
      !(getFieldValue('email') && getFieldValue('password')) ||
      (isSignUp && !this.state.checked);
    const content = getContent(Content);

    const onSubmit = btnDisabled ? noop : this.signInSubmit;

    return isExpiredLink ? (
      this.renderExpired()
    ) : (
      <div className="Login" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo size="xlarge" />
        </div>

      <div css={css(styles.inputContainer)}>       
          {this.props.inputContainer && this.props.inputContainer()} 
          <p css={css(styles.title)}>
            {isSignUp ? content.signUp : content.signIn}
          </p>

          {isSignUp && (
            <div>
              <p css={css(styles.passwordPrompt)}>{content.passwordPrompt}</p>
            </div>
          )}

          {!!error && (
            <Alert
              message={this.getErrorText(error)}
              type={'error'}
              closable
              onClose={() => this.setState({ error: null })}
            />
          )}

          <Form layout="vertical" onSubmit={onSubmit}>
            <Form.Item label={content.emailLabel}>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail. ',
                    transform: v => v.trim(),
                  },
                  {
                    required: true,
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    max: 60,
                    message: 'Email is limited to 60 characters. ',
                  },
                ],
              })(<Input disabled={isSignUp} />)}
            </Form.Item>
            <Form.Item label={content.passwordLabel}>
              {getFieldDecorator('password', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ],
              })(<Input.Password onPressEnter={onSubmit} />)}

              {!isSignUp && (
                <div
                  onClick={this.goToForgotPassword}
                  css={css(styles.fgPassword)}
                >
                  {content.forgotPasswordBtn}
                </div>
              )}
            </Form.Item>
            {isSignUp && (
              <div>
                <Checkbox checked={this.state.checked} onChange={this.onChange}>
                  By checking this box, you consent to and agree to our{' '}
                  <a
                    href={'https://www.dentalez.com/privacy-policy'}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{' '}
                  and our{' '}
                  <a href={'https://www.dentalez.com/terms'} target="_blank">
                    Terms of Use
                  </a>
                  .
                </Checkbox>
              </div>
            )}

            <Form.Item>
              <div css={css(styles.buttonContainer)}>
                <Button
                  loading={loading}
                  title={isSignUp ? content.buttonSignUp : content.button}
                  onClick={this.signInSubmit}
                  disabled={btnDisabled}
                />
              </div>
            </Form.Item>
          </Form>

          {this.props.children}
        </div>
      </div>
    );
  }
}

export const Login = Form.create()(withRouter(LoginComponent));
