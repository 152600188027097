import { connect } from 'react-redux';
import { Login as LoginComponent } from './login';
import { BrandedLogin as BrandedLoginComponent } from './login/branded';
import { ForgotPassword as ForgotPasswordComponent } from './forgot-password';
import { Verify as VerifyComponent } from './verify';
import { ForgotPasswordSubmit as FGPasswordSubmitComponent } from './forgot-password-submit';
import {
  signUp,
  verify,
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
} from './authentication.actions';

const Connected = connect(
  appState => ({ appState }),
  {
    signUp,
    verify,
    signIn,
    forgotPassword,
    forgotPasswordSubmit,
  }
);

export const Login = Connected(LoginComponent);
export const BrandedLogin = Connected(BrandedLoginComponent);
export const ForgotPassword = Connected(ForgotPasswordComponent);
export const Verify = Connected(VerifyComponent);
export const ForgotPasswordSubmit = Connected(FGPasswordSubmitComponent);
