import { AppState } from '../app.state';
import { getOrgTypes } from '../_shared/services/manage-org-types.service';
import {
  DashActions,
  createEquipMap,
  DashEquipFilters,
} from './dashboard.reducer';
import { getOrgs } from '../_shared/services/manage-orgs.service';
import { getIntegrations } from '../_shared/services/roles.service';
import { getLocations } from '../_shared/services/manage-locations.service';
import { getGroups } from '../_shared/services/manage-groups.service';
import { getUser } from '../_shared/services/manage-users.service';
import { AuthActions } from '../authentication/authentication.reducer';
import { getSensors } from '../_shared/services/manage-sensors.service';
import { getModels } from '../_shared/services/manage-models.service';
import {
  getEquipmentForUser,
  getEquipmentRecord,
  getEquipments,
} from '../_shared/services/manage-equipment.service';
import {
  generateAlertMap,
  getEquipConfig,
  getDeviceStatusFromEquip,
  convertStatusToPublish,
  getProfilesForUser,
  getUsersForUser,
  pullOutEquipment,
  getTandemGroupsForUser,
  isLoggedIn,
  userHasRole,
  sortPublishByTimeOrDate,
} from '../utils';
import { checkPhoneBreak } from '../utils';
import { Publish } from '../_shared/interfaces/publish';
import { get, set, reverse, first, size, keyBy } from 'lodash';
import { Equipment, EquipmentRecord } from '../_shared/interfaces/equipment';
import { getProfiles } from '../_shared/services/manage-profiles.service';
import { User } from '../_shared/interfaces/user';
import { getPresets } from '../_shared/services/manage-presets.service';
import { message } from 'antd';
import localforage from 'localforage';
import moment from 'moment';
import { eventTypes, trackEvent } from '../_shared/services/analytics.service';

export const clearStorage = () => {
  return new Promise(resolve => {
    localforage.clear(resolve);
  });
};

export const logout = () => async () => {
  await trackEvent(eventTypes.log_out);
  await clearStorage();
  window.location.href = window.location.origin;
};

export const NONE = '_none_';

export interface MapOpts {
  clear?: string[];
}
let queue: Publish[] = [];
export const addToMap = (pubs: Publish[], opts: MapOpts = {}) => (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const isLoading = get(getState(), 'dash.view.equipmentLoading', false);

  if (isLoading) {
    queue = [...pubs, ...queue] as Publish[];
    return pubs;
  } else {
    pubs = pubs.sort(sortPublishByTimeOrDate);
    const equipMap = get(getState(), 'dash.equipMap', {});

    const _take = (arr: Publish[]) => arr;

    const setE = (coreid: string, name: string, pub: Publish) => {
      const items = get(equipMap, `${coreid}.${name}`, []);
      set(equipMap, `${coreid}.${name}`, _take([pub, ...items]));
    };

    const clear = (coreid: string, name: string, pub = []) => {
      set(equipMap, `${coreid}.${name}`, pub);
    };

    if (opts.clear) {
      //expecting anything sent with clear, is only clearing items from a single coreid.
      //so just grabbing the first pub and its coreid
      const coreid = get(first(pubs), 'coreid');
      if (coreid) {
        opts.clear.map(c => clear(coreid, c));
      }
    }

    reverse(pubs.filter(p => p.event)).map(p => {
      const coreid = p.coreid;
      let device = get(equipMap, coreid);
      if (!device) {
        set(equipMap, coreid, createEquipMap());
        device = get(equipMap, coreid);
      }

      const isChair = p.event.includes('chair_');
      const isAi = p.event.includes('ai_');
      const isSter = p.event.includes('ster_');
      const isCycle = p.event.includes('_cycle_');

      if (p.event.indexOf('config') > -1) {
        setE(coreid, 'configs', p);
      } else if ((p.event.includes('_alert') && p.event !== 'dz_alert_data') || (isChair && p.event.includes('_alert'))) {
        p.event = 'dz_alert';
        setE(coreid, 'alerts', p);
        const alerts = get(equipMap, `${coreid}.alerts`, []);
        set(equipMap, `${coreid}.alertMap`, generateAlertMap(alerts));
      } else if (p.event === 'dz_schedule') {
        setE(coreid, 'schedules', p);
      } else if (p.event === 'dz_thresholds') {
        setE(coreid, 'thresholds', p);
      } else if (
        p.event.indexOf('dz_alert_data') > -1 ||
        p.event.indexOf('dz_profile_runtimes') > -1 ||
        p.event.indexOf('sensor') > -1 ||
        p.event.indexOf('_stats') > -1 ||
        p.event.includes('_cycle_')
      ) {
        const isInstant = p.event.indexOf('_instant') > -1;

        ///IOT board conversions
        if (isChair || isAi || isSter || isCycle) {
          const isDaily = isChair ? get(p, 'data.type') === 1 : isSter ? !isInstant : false;

          if (isInstant) {
            p.event = 'dz_sensor_instant';
          } else {
            p.event = 'dz_sensor_statistics';
          }

          if (isDaily) {
            set(p, 'data.daily', true);
          }
        }
        setE(coreid, 'sensorStatistics', p);
      } else if (p.event.indexOf('dz_maint') > -1) {
        setE(coreid, 'maint', p);
      } else if (p.event.indexOf('dz_testresults') > -1) {
        setE(coreid, 'testresults', p);
      } else if (p.event.indexOf('hp_debug_mode') > -1) {
        setE(coreid, `hp_debug_mode`, p);
      } else if (p.event.indexOf('hp_revs') > -1) {
        setE(coreid, `hp_revs`, p);
      }
    });

    dispatch({ type: DashActions.SET_MAP, map: { equipMap } });

    if (queue.length > 0) {
      const data = [...queue];
      queue = [];
      dispatch(addToMap(data));
    }

    return pubs;
  }
};

export const retrieveData = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  try {
    const sState = getState();

    if (!isLoggedIn(sState)) {
      return;
    }

    dispatch({
      type: DashActions.SET_MAP,
      map: {
        filterEquipment: undefined,
        view: {
          ...sState.dash.view,
          equipmentLoading: true,
        },
        statusesLoading: true,
      },
    });
    const cUser = sState.auth.user;
    const user = await getUser((cUser && cUser.email) as string);

    const hasOrg = user.orgId;
    const orgPred = hasOrg ? { orgId: { eq: hasOrg } } : undefined;

    dispatch({
      type: AuthActions.SET_CURRENT_USER,
      payload: { ...cUser, ...user },
    });

    const userIsProd = userHasRole([5], getState());
    const userNeedsIntegrations = userHasRole([0, 1], getState());

    const [
      orgTypes,
      allOrgs,
      locations,
      eqs,
      sensors,
      profiles,
      presets,
      models,
      integrations,
    ] = await Promise.all([
      getOrgTypes(),
      getOrgs(),
      getLocations(),
      getEquipmentForUser(cUser),
      getSensors(),
      getProfilesForUser(sState),
      getPresets(),
      getModels(),
      userNeedsIntegrations ? getIntegrations() : [],
    ]);

    const orgs = hasOrg
      ? allOrgs.filter(o => o.id === hasOrg || o.serviceOrgId === hasOrg)
      : allOrgs;

    const equipment = pullOutEquipment(eqs, !userIsProd);

    const getDentalOrgTypes = () => {
      const orgTypeDental = orgTypes.find(ot => ot.id == 1);
      return get(orgTypeDental, 'types', []);
    };

    const [tandemGroups, users, groups, dentalOrgTypes] = await Promise.all([
      getTandemGroupsForUser(sState, equipment),
      getUsersForUser(sState),
      getGroups(orgPred),
      getDentalOrgTypes(),
    ]);

    const isSuper = userHasRole([0], getState());
    const now = moment();
    const equipIsActive = (e: EquipmentRecord) => {
      if (!isSuper) {
        return false;
      }

      return  e.lastPublish ? now.diff(moment(e.lastPublish), 'hours') <= 72 : false;
    };

    let connected = 0;
    const pubs: Publish[] = [];
    eqs.map(eq => {
      if (!!eq.soPurchaseOrder && equipIsActive(eq)) {
        connected += 1;
      }
    });
    dispatch(addToMap(pubs));

    dispatch({
      type: DashActions.SET_MAP,
      map: {
        profiles,
        equipment,
        orgs,
        allOrgs,
        orgTypes,
        locations,
        presets,
        models,
        integrations,

        activeCount: isSuper
          ? {
              total: size(
                equipment.filter(
                  e => e.equipmentSN !== 'ACR0000000' && !!e.soPurchaseOrder
                )
              ),
              connected,
            }
          : null,
        sensors,
        tandemGroups,
        users,
        groups,
        dentalOrgTypes,
        view: {
          ...getState().dash.view,
          equipView: equipment.length >= 10 ? 1 : 0,
          equipmentLoading: false,
        },
      },
    });

    dispatch(filterEquipment(get(getState(), 'dash.equipmentFilters', {})));
  } catch (err) {
    //"One or more parameter values are not valid. A value specified for a secondary index key is not supported. The AttributeValue for a key attribute cannot contain an empty string value. IndexName: pk-serviceOrgId-index, IndexKey: serviceOrgId"
    let msg = get(err, 'message', '');
    if (msg.indexOf('IndexName: pk-serviceOrgId-index, IndexKey: serviceOrgId') > -1) {
      return;
    }
    message.error(`Failed to retrieve data: ${msg}`);
  } finally {
    dispatch({
      type: DashActions.SET_MAP,
      map: {
        filterEquipment: undefined,
        view: {
          ...getState().dash.view,
          equipmentLoading: false,
        },
        statusesLoading: false,
      },
    });
  }
};

export const setLastEquipment = (equip?: string) => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  dispatch({
    type: DashActions.SET_DASH_VIEW,
    view: {
      ...getState().dash.view,
      lastEquipment: equip,
    },
  });
};

export const setEquipView = (equipView: number) => (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  dispatch({
    type: DashActions.SET_DASH_VIEW,
    view: {
      ...getState().dash.view,
      equipView,
    },
  });
};

export const filterEquipment = (filters?: DashEquipFilters) => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const sState = getState();

  let payload = {
    filterEquipment: undefined,
    equipmentFilters: {
      ...(filters || {}),
    },
  };

  trackEvent(eventTypes.search_equipment, { params: filters });

  const showOnDash = get(filters, 'showOnDash', 1);
  const equipment = (await getEquipments(undefined, false)).filter(e => {
    if (showOnDash === 1) {
      return e.showOnDash;
    } else if (showOnDash === 0) {
      return !e.showOnDash;
    } else {
      return e;
    }
  });
  let filterEquipment = undefined;

  if (filters) {
    const orgKeys = !!size(filters.dentalOrgState)
      ? keyBy(get(sState, 'dash.orgs', []), 'sk')
      : {};
    filterEquipment = equipment.filter((e: Equipment) => {
      let passes = true;

      if (filters.search) {
        const term = filters.search.toLowerCase();
        const name = (get(e, 'name', '') || '').toLowerCase();
        const deviceId = (get(e, 'deviceId', '') || '').toLowerCase();
        const serial = (get(e, 'equipmentSN', '') || '').toLowerCase();
        const controllerSerial = (
          get(e, 'controllerSerial', '') || ''
        ).toLowerCase();
        passes =
          name.indexOf(term) > -1 ||
          deviceId.indexOf(term) > -1 ||
          controllerSerial.indexOf(term) > -1 ||
          serial.indexOf(term) > -1;
      }

      if (passes && filters.model && filters.model.length > 0) {
        passes = e.modelId ? filters.model.indexOf(e.modelId) > -1 : false;
      }

      if (passes && filters.serviceOrg && filters.serviceOrg.length > 0) {
        const hasNone = filters.serviceOrg.indexOf(NONE) > -1;
        passes = e.serviceOrgId
          ? filters.serviceOrg.indexOf(e.serviceOrgId) > -1
          : hasNone
          ? true
          : false;
      }

      if (passes && filters.dentalOrg && filters.dentalOrg.length > 0) {
        const hasNone = filters.dentalOrg.indexOf(NONE) > -1;
        passes = e.dentalOrgId
          ? filters.dentalOrg.indexOf(e.dentalOrgId) > -1
          : hasNone
          ? true
          : false;
      }

      if (passes && filters.location && filters.location.length > 0) {
        const hasNone = filters.location.indexOf(NONE) > -1;
        passes = e.locationId
          ? filters.location.indexOf(e.locationId) > -1
          : hasNone
          ? true
          : false;
      }

      if (
        passes &&
        filters.dentalOrgState &&
        filters.dentalOrgState.length > 0
      ) {
        const orgState =
          !!e.dentalOrgId &&
          get(orgKeys, [e.dentalOrgId, 'address', 'state'], '').toUpperCase();
        passes = !!orgState && filters.dentalOrgState.indexOf(orgState) > -1;
      }

      const deviceState = getDeviceStatusFromEquip(e);

      if (passes && filters.status && filters.status.length > 0) {
        let has = filters.status.filter(f => {
          if (f !== 'off') {
            return !deviceState.off && deviceState[f];
          } else {
            return deviceState[f];
          }
        });
        passes = has.length > 0;
      }

      if (passes && filters.mode && filters.mode.length > 0) {
        let has = filters.mode.filter(f => deviceState[f]);
        passes = has.length > 0;
      }
      if (passes && filters.hasOwnProperty('onConsignment')) {
        e.onConsignment == filters.onConsignment
          ? (passes = true)
          : !e.onConsignment && !filters.onConsignment
          ? (passes = true)
          : (passes = false);
      }
      if (passes && filters.hasOwnProperty('isActive')) {
        passes = !!filters.isActive;
      }
      if (passes && filters.hasOwnProperty('integrations')) {
        passes = !!(get(e, 'integrations', []) as string[]).find((i: {}) =>
          get(filters, 'integrations', []).includes(i)
        );
      }
      return passes;
    });
  } else {
    const estate = get(window, 'ESearch.input.state');
    if (estate) {
      estate.value = '';
    }
  }

  dispatch({
    type: DashActions.SET_FILTER_EQUIPMENT,
    payload: {
      ...payload,
      filterEquipment,
    },
  });
};

export const updateModels = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  try {
    const models = await getModels();

    return dispatch({
      type: DashActions.SET_MAP,
      map: {
        models,
      },
    });
  } catch (err) {
    console.warn(err);
  }
};

export const updateOrgs = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  try {
    const allOrgs = await getOrgs();
    const sState = getState();
    const user = sState.auth.user;

    const hasOrg = user && user.orgId;
    const orgs = hasOrg
      ? allOrgs.filter(o => o.id === hasOrg || o.serviceOrgId === hasOrg)
      : allOrgs;

    return dispatch({
      type: DashActions.SET_MAP,
      map: {
        orgs,
        allOrgs,
      },
    });
  } catch (err) {
    console.warn(err);
  }
};

export const updateLocations = () => async (dispatch: Dispatch<{}>) => {
  try {
    const locations = await getLocations();
    return dispatch({
      type: DashActions.SET_MAP,
      map: {
        locations,
      },
    });
  } catch (err) {
    console.warn(err);
  }
};
export const updateProfiles = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const sState = getState();
  const user = get(sState, 'auth.user');

  try {
    const profiles = await getProfiles({ dentalOrgId: { eq: user.orgId } });
    dispatch({
      type: DashActions.SET_MAP,
      map: {
        profiles,
      },
    });
  } catch (err) {
    console.warn(err);
  }
};

export const updatePresets = () => async (dispatch: Dispatch<{}>) => {
  try {
    const presets = await getPresets();
    dispatch({
      type: DashActions.SET_PRESETS,
      payload: presets,
    });
  } catch (error) {
    console.warn(error);
  }
};

export const updateMyEquipment = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  try {
    const user = get(getState(), 'auth.user');
    const equipment = await getEquipmentForUser(user);

    return dispatch({
      type: DashActions.SET_MAP,
      map: {
        equipment,
      },
    });
  } catch (err) {
    console.warn(err);
  }
};

export const waitForNewConfig = (eq: Equipment) => (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const config = getEquipConfig(eq, getState());
  const opts = {
    wait: 500,
    expires: 30000,
  };
  const start = Date.now();
  return new Promise((resolve, reject) => {
    const check = () => {
      const newConfig = getEquipConfig(eq, getState());
      const time = Date.now();

      if (newConfig.sk !== config.sk) {
        resolve(newConfig);
      } else if (time - start > opts.expires) {
        reject(new Error('Timed out'));
      } else {
        setTimeout(check, opts.wait);
      }
    };
    check();
  });
};

export const checkForNewStatus = (eq: Equipment, _opts: any = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const eqOriginal = await getEquipmentRecord(eq.deviceId, true);
      
      const opts = {
        wait: 1000,
        expires: 30000,
        ..._opts,
      };
      
      const start = Date.now();
  
      opts.hasOriginal && opts.hasOriginal(eqOriginal);
    
      const check = async () => {
        const eqNew = await getEquipmentRecord(eq.deviceId, true);
  
        const time = Date.now();
  
        const compare = `_status` + (opts.key ? `.${opts.key}` : '');
  
        const o = get(eqOriginal, compare);
        const n = get(eqNew, compare);
        if (o !== n) {
          resolve(eqNew);
        } else if (time - start > opts.expires) {
          reject(new Error('Timed out'));
        } else {
          setTimeout(check, opts.wait);
        }
      };
  
      check();
    } catch (err) {
      reject(err);
    }
  });
};

export const waitForNewStatistic = (eq: Equipment) => (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const prevSensor = get(
    getState(),
    `dash.equipMap.${eq.deviceId}.sensorStatistics.0`,
    {}
  );

  const opts = {
    wait: 500,
    expires: 30000,
  };

  const start = Date.now();
  return new Promise((resolve, reject) => {
    const check = () => {
      const newSensor = get(
        getState(),
        `dash.equipMap.${eq.deviceId}.sensorStatistics.0`,
        {}
      );
      const time = Date.now();

      if (newSensor.sk !== prevSensor.sk) {
        resolve(newSensor);
      } else if (time - start > opts.expires) {
        reject(new Error('Timed out'));
      } else {
        setTimeout(check, opts.wait);
      }
    };
    check();
  });
};

export const waitForNewSchedule = (eq: Equipment) => (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const prevSensor = get(
    getState(),
    `dash.equipMap.${eq.deviceId}.schedules.0`,
    {}
  );

  const opts = {
    wait: 500,
    expires: 30000,
  };

  const start = Date.now();
  return new Promise((resolve, reject) => {
    const check = () => {
      const newSensor = get(
        getState(),
        `dash.equipMap.${eq.deviceId}.schedules.0`,
        {}
      );
      const time = Date.now();

      if (newSensor.sk !== prevSensor.sk) {
        resolve(newSensor);
      } else if (time - start > opts.expires) {
        reject(new Error('Timed out'));
      } else {
        setTimeout(check, opts.wait);
      }
    };
    check();
  });
};

export const checkWindowSize = () => async (
  dispatch: Dispatch<{}>,
  getState: () => AppState
) => {
  const sState = getState();
  const currentBreak = get(sState, 'dash.view.isPhoneBreak');

  const isPhoneBreak = checkPhoneBreak();

  if (currentBreak !== isPhoneBreak) {
    dispatch({
      type: DashActions.SET_DASH_VIEW,
      view: {
        ...get(sState, 'dash.view', {}),
        isPhoneBreak,
      },
    });
  }
};
export const updateEquipmentPusher = (equipment: Equipment) => (
  dispatch: Dispatch<{}>
) => {
  dispatch({
    type: DashActions.UPDATE_EQUIPMENT_PUSHER,
    payload: equipment,
  });
};

export const updateUserPusher = (user: User) => (dispatch: Dispatch<{}>) => {
  dispatch({
    type: DashActions.UPDATE_USERS_PUSHER,
    payload: user,
  });
};
