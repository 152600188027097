/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import {
  getUsersName,
  userHasRole,
  sortByFirstThenLastName,
  getRoleName,
  singleStringAddress,
  findAssocDentalOrgs,
  truncate,
} from '../../utils';
import { User, _User } from '../../_shared/interfaces/user';
import { Icon, Alert, Modal } from 'antd';
import colors from '../../_shared/colors';
import { AppState } from '../../app.state';
import { get, chain, set } from 'lodash';
import styles from './manage-org.styles';
import {
  getLocations,
  deleteLocation,
  updateLocation,
} from '../../_shared/services/manage-locations.service';
import { TableListSimple } from '../../_shared/table-list-multi';
import {
  getGroups,
  deleteGroup,
  updateGroup,
} from '../../_shared/services/manage-groups.service';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import {
  getAllUsers,
  getUsers,
  updateUser,
} from '../../_shared/services/manage-users.service';
import { Group } from '../../_shared/interfaces/group';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import {
  getOrgs,
  deleteOrg,
  updateOrg,
  getOrg,
} from '../../_shared/services/manage-orgs.service';
import { getModels } from '../../_shared/services/manage-models.service';
import { Org } from '../../_shared/interfaces/org';
import { ModalTransfer } from '../modal-transfer';
import { Location } from '../../_shared/interfaces/location';
import { ActiveSwitch } from '../../_shared/active-switch/active-switch.component';
import { Profile } from '../../_shared/interfaces/profile';
import { getProfiles } from '../../_shared/services/manage-profiles.service';
import { getPresets } from '../../_shared/services/manage-presets.service';
import { Preset } from '../../_shared/interfaces/preset';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps {
  sState: AppState;
  retrieveData?: () => Promise<void>;
}

const EquipColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '12%',
  },
  {
    title: 'Model ID',
    dataIndex: 'modelId',
    width: '10%',
  },
  {
    title: 'Serial Number',
    dataIndex: 'equipmentSN',
    width: '12%',
  },
  {
    title: 'Device ID',
    dataIndex: 'deviceId',
    width: '18%',
  },
  {
    title: 'Dental Org',
    dataIndex: 'dentalOrg',
    width: '18%',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    width: '12%',
  },
];

const LocColums = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '15%',
    render: (text: string) => {
      return <span>{truncate(text, 50)}</span>;
    },
  },
  {
    title: 'Code',
    dataIndex: 'code',
    width: '10%',
  },
  {
    title: 'Count of Equipment',
    dataIndex: 'eCount',
    width: '10%',
  },
  {
    title: 'Count of Users',
    dataIndex: 'uCount',
    width: '10%',
  },
];

const GroupColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '33%',
  },
  {
    title: 'Count of Equipment',
    dataIndex: 'eCount',
    width: '33%',
  },
  {
    title: 'Count of Users',
    dataIndex: 'uCount',
    width: '33%',
  },
  {
    title: 'Active',
    dataIndex: 'uCount',
    width: '33%',
    render: (_text: string, record: Group) => {
      const isActive = get(record, 'isActive', 0) > 0;
      return (
        <Icon
          type={isActive ? 'check-circle' : 'close-circle'}
          css={css(`color: ${isActive ? colors.success : colors.error};`)}
        />
      );
    },
  },
];

const msgs = {
  serviceOrgError: `Service Organizations that are associated with active Dental 
  Organizations can not be deleted or set to inactive.`,
  dentalOrgError: `Dental Organizations that are associated with equipment can 
  not be deleted or set to inactive.`,
  setInactiveSuccessSO: `The service organization and all associated users and 
  groups have been set to inactive.`,
  setInactiveSuccessDO: `The dental organization and all associated users, groups, 
  and locations have been set to inactive.`,
  setActiveError:
    'There was a problem setting the active state of the Organization.',
  setDeleteError: 'There was a problem deleting the Organization.',
};

const UserColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '18%',
  },
  {
    title: 'Role',
    dataIndex: 'roleName',
    width: '10%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '10%',
    render: (text: string) => {
      return <span>{truncate(text, 20)}</span>;
    },
  },
  {
    title: 'Count of Equipment',
    dataIndex: 'eCount',
    width: '12%',
  },
  {
    title: 'Count of Groups',
    dataIndex: 'gCount',
    width: '12%',
  },
  {
    title: 'Active',
    dataIndex: 'email',
    width: '8%',
    render: (_text: string, record: User) => {
      const isActive = get(record, 'isActive', 0) > 0;
      return (
        <Icon
          type={isActive ? 'check-circle' : 'close-circle'}
          css={css(`color: ${isActive ? colors.success : colors.error};`)}
        />
      );
    },
  },
];
export class _ManageOrg extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,
    org: undefined,
    orgType: undefined,
    isMyOrg: false,
    showModalTransfer: false,
    orgMap: {},
    eCols: [],
    groups: [],
    equipment: [],
    users: [],
    locations: [],
    profiles: [],
    active: true,
  };
  mounted = false;
  waitForEquipLoaded = () => {
    return new Promise<void>((resolve, _reject) => {
      const checkLoading = () => {
        const { sState } = this.props;
        const isLoading = get(sState, 'dash.view.equipmentLoading', true);
        if (!isLoading && this.mounted) {
          resolve();
        } else if (isLoading && this.mounted) {
          setTimeout(checkLoading, 250);
        }
      };
      checkLoading();
    });
  };
  componentWillUnmount = () => {
    this.mounted = false;
  };
  componentDidMount = async () => {
    this.mounted = true;
    await this.waitForEquipLoaded();

    const {
      sState,
      history: { location },
    } = this.props;
    let org = get(location, 'state.org')
      ? await getOrg(get(location, 'state.org.id'))
      : undefined;
    // let org = get(location, 'state.org');
    let orgType = get(org, 'orgType', []);
    let isMyOrg = false;

    if (!org) {
      //we are likely here from the myOrg route
      const orgs = get(sState, 'dash.orgs', []) as Org[];
      const user = get(sState, 'auth.user');

      org = orgs.find(o => o.id === user.orgId);
      orgType = get(org, 'orgType') as number | never[];
      isMyOrg = true;
    }

    this.setState(
      {
        org,
        active: get(org, 'isActive'),
        orgType,
        isMyOrg,
        eCols:
          orgType === 1
            ? EquipColumns.filter(c => c.dataIndex !== 'dentalOrg')
            : EquipColumns,
      },
      () => this.retrieveData()
    );
  };
  retrieveData = async (res?: { error?: string }) => {
    try {
      if (res && res.error) {
        return this.setState({ error: res.error });
      }

      const { sState } = this.props;

      this.setState(
        {
          show: false,
          loading: true,
        },
        () => this.setState({ show: true })
      );

      const { org, orgType } = this.state;

      const orgId = get(org, 'id', '');

      const [
        allOrgs,
        profiles,
        allUsers,
        allEquipment,
        allGroups,
        allModels,
        allLocations,
      ] = await Promise.all([
        await getOrgs(),
        await getProfiles({ dentalOrgId: { eq: orgId } }),
        await getAllUsers(),
        await getEquipments({
          [orgType === 0 ? 'serviceOrgId' : 'dentalOrgId']: { eq: orgId },
        }),
        await getGroups({
          orgId: { eq: orgId },
        }),
        await getModels(),
        await getLocations(),
      ]);

      const gMap = chain(allGroups)
        .keyBy('id')
        .value();
      const locMap = chain(allLocations)
        .keyBy('id')
        .value();
      const orgMap = chain(allOrgs)
        .keyBy('id')
        .value();
      const modelMap = chain(allModels)
        .keyBy('id')
        .value();
      const equipMap = chain(allEquipment)
        .keyBy('deviceId')
        .value();

      const _profiles = await Promise.all(
        profiles.map(async profile => {
          const presets = await getPresets({ profileId: { eq: profile.id } });
          return {
            ...profile,
            _profile: { ...profile },
            key: profile.id,
            presets,
          };
        })
      );

      const equipment = allEquipment
        .map(e => {
          const location = get(locMap, `${e.locationId}.name`, '');
          const model = get(modelMap, `${e.modelId}.name`, '');
          const dentalOrg = get(orgMap, `${e.dentalOrgId}.name`, '');
          const serviceOrg = get(orgMap, `${e.serviceOrgId}.name`, '');

          e.groups &&
            Array.isArray(e.groups) &&
            e.groups.map((g: string) => {
              const eCount = get(gMap, `${g}.eCount`, 0) as number;
              set(gMap, `${g}.eCount`, eCount + 1);
            });

          if (e.locationId) {
            const lCount = get(locMap, `${e.locationId}.eCount`, 0) as number;
            set(locMap, `${e.locationId}.eCount`, lCount + 1);
          }

          return {
            ...e,
            key: e.id,
            location,
            model,
            dentalOrg,
            serviceOrg,
          } as Equipment;
        })
        .sort((a, b) => {
          const aName = a.name || '';
          const bName = b.name || '';

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });

      const users = allUsers
        .map(u => {
          u.groups &&
            u.groups.map(g => {
              const uCount = get(gMap, `${g}.uCount`, 0) as number;
              set(gMap, `${g}.uCount`, uCount + 1);
            });

          if (u.isActive > 0 && u.locations && u.locations.length > 0) {
            u.locations.map(l => {
              const uCount = get(locMap, `${l}.uCount`, 0) as number;
              set(locMap, `${l}.uCount`, uCount + 1);
            });
          }

          return {
            ...u,
            key: u.userId,
            name: getUsersName(u),
            roleName: getRoleName(u.role, sState),
            eCount: get(u, 'equipment', []).length,
            gCount: get(u, 'groups', []).length,
          };
        })
        .filter(u => u.orgId === orgId)
        .sort(sortByFirstThenLastName);

      const groups = allGroups
        .map(g => {
          const group = get(gMap, g.id);
          return {
            ...g,
            key: g.id,
            eCount: get(group, 'eCount', 0),
            uCount: get(group, 'uCount', 0),
          } as Group;
        })
        .sort((a, b) => {
          const aName = a.name || '';
          const bName = b.name || '';

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });

      let locations = [] as never[];
      if (orgType === 1) {
        locations = allLocations
          .filter(l => l.orgId === orgId)
          .map(l => {
            const loc = get(locMap, `${l.id}`);
            return {
              ...l,
              eCount: get(loc, 'eCount', 0),
              uCount: get(loc, 'uCount', 0),
            };
          })
          .sort((a, b) => {
            const aName = a.name || '';
            const bName = b.name || '';

            if (aName < bName) {
              return -1;
            }
            if (aName > bName) {
              return 1;
            }
            return 0;
          }) as never[];
      }

      this.setState({
        users,
        groups,
        equipment,
        equipMap,
        locations,
        orgMap,
        profiles: _profiles,
        show: true,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  getTitle = () => {
    const { orgType } = this.state;
    return orgType === 0 ? 'Service' : 'Dental';
  };
  toggleEdit = () => {
    const { org, orgType, isMyOrg } = this.state;
    const title = this.getTitle();
    this.props.history.push(`/dashboard/edit${title}Org`, {
      org,
      orgType,
      back: isMyOrg
        ? '< Back to My Organization'
        : `< Back to ${title} Organization Details`,
    });
  };
  renderSubInfo = () => {
    const { org, orgType, orgMap } = this.state;

    const items = [
      {
        title: 'Service Org Name',
        var: 'serviceOrgId',
        toText: (id: string) => orgType === 1 && get(orgMap, `${id}.name`),
      },
      {
        title: 'Email',
        var: 'email',
        toText: () => get(org, 'email'),
      },
      {
        title: 'Phone',
        var: 'phone',
        toText: () => get(org, 'phone'),
      },
      {
        title: 'Address',
        var: 'address.address',
        toText: () => singleStringAddress(get(org, 'address')),
      },
    ];

    return (
      <span css={css(`font-size: 12px;`)}>
        {items
          .filter(item => {
            const hasVal = get(org, item.var);
            const val = hasVal && item.toText(hasVal);
            return val;
          })
          .map((item, i) => {
            const hasVal = get(org, item.var);
            const val = hasVal && item.toText(hasVal);
            return (
              <span key={i}>
                {i > 0 ? (
                  <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</span>
                ) : null}
                {`${val}`}
              </span>
            );
          })}
      </span>
    );
  };

  goToManageEquipment = () => {
    this.props.history.push('/dashboard/manageEquipmentList', {
      org: this.state.org,
    });
  };
  goToViewEquipment = (equipment: Equipment) => {
    this.props.history.push('/dashboard/manageEquipmentSelect', {
      equipment,
    });
  };
  goToManageGroups = () => {
    this.props.history.push('/dashboard/manageGroups', {
      org: this.state.org,
    });
  };
  goToViewGroup = (group: Group) => {
    this.props.history.push('/dashboard/manageGroup', {
      group,
    });
  };
  goToManageUsers = () => {
    this.props.history.push('/dashboard/manageUsers', {
      org: this.state.org,
    });
  };
  goToViewUser = (user: User) => {
    this.props.history.push('/dashboard/manageUser', {
      user,
    });
  };
  goToManageLocations = () => {
    this.props.history.push('/dashboard/manageLocations', {
      org: this.state.org,
    });
  };
  goToViewLocation = (location: Location) => {
    this.props.history.push('/dashboard/manageLocation', {
      location,
    });
  };

  selectPreset = (preset: Preset, _index: number) => {
    this.props.history.push('/dashboard/managePreset', {
      preset,
      isMyPresets: false,
      back: 'Manage Org',
    });
  };

  editProfile = (profile: Profile) => {
    this.props.history.push('/dashboard/editProfileAdmin', {
      profile,
    });
  };
  assignEquipment = () => {
    const { org, orgType } = this.state;
    const title = this.getTitle();
    this.props.history.push(`/dashboard/edit${title}Org`, {
      org,
      orgType,
      back: `< Back to ${title} Organization Details`,
      screen: 1,
    });
  };

  requestTransfer = () => {
    this.setState({
      showModalTransfer: true,
    });
  };

  submitTransfer = () => {};
  assocDentOrgs =
    this.state.org && this.state.orgType === 0
      ? findAssocDentalOrgs(get(this.state, 'org.id', ''), this.props.sState)
      : [];

  deleteOrganization = () => {
    const { orgType, groups, equipment, users, locations } = this.state;
    let dontDelete;

    const delOrg = async () => {
      const orgId = get(this.state, 'org.id');
      try {
        const resp = await deleteOrg(orgId);
        if (resp) {
          users.forEach((user: User) => {
            updateUser({ ...user, isActive: 0 });
          });
          groups.forEach((group: Group) => {
            deleteGroup(group.id);
          });
          if (orgType === 0) {
            this.assocDentOrgs.forEach((org: Org) => {
              delete org.serviceOrgId;
              updateOrg(org);
            });
          }
          if (orgType === 1) {
            locations.forEach((loc: Location) => {
              deleteLocation(loc.id);
            });
          }
          trackEvent(eventTypes.org_delete, { org: this.state.org });
          this.props.retrieveData && await this.props.retrieveData();
          this.props.history.goBack();
        }
      } catch (error) {
        console.warn(error);
        this.setState({ error: msgs.setDeleteError });
      }
    };

    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Delete Organization',
        content: 'Are you sure you want to delete this organization?',
        onOk: delOrg,
        okText: 'Delete',
      });
    };

    orgType === 0
      ? (dontDelete = this.assocDentOrgs.some((org: Org) => {
          return get(org, 'isActive', 0) > 0 ? true : false;
        }))
      : (dontDelete = !!equipment.length);

    if (dontDelete) {
      this.setState({
        error: orgType === 0 ? msgs.serviceOrgError : msgs.dentalOrgError,
      });
      return;
    } else {
      showConfirmModal();
    }
  };

  toggleActive = async () => {
    const { orgType, equipment } = this.state;
    this.setState({ loading: true });

    const isActive = get(this.state.org, 'isActive', 0);
    let dontSetInactive;
    orgType === 0
      ? (dontSetInactive = this.assocDentOrgs.some((org: Org) => {
          return get(org, 'isActive', 0) > 0 ? true : false;
        }))
      : (dontSetInactive = !!equipment.length);

    const setActive = async () => {
      const org = {
        ...(this.state.org || {}),
        isActive: 0,
      } as Org;

      try {
        const resp = await updateOrg(org);
        if (resp) {
          this.state.users.forEach((user: User) => {
            updateUser({ ...user, isActive: 0 });
          });
          this.state.groups.forEach((group: Group) => {
            updateGroup({ ...group, isActive: 0 });
          });
          if (orgType === 1) {
            this.state.locations.forEach((location: Location) => {
              updateLocation({ ...location, isActive: 0 });
            });
          }
          this.setState({
            success:
              org.isActive === 0 && orgType === 0
                ? msgs.setInactiveSuccessSO
                : org.isActive === 0 && orgType === 0
                ? msgs.setInactiveSuccessDO
                : null,
            loading: false,
            org,
            active: org.isActive,
          });
        }
      } catch (err) {
        this.setState({
          loading: false,
          error: msgs.setActiveError,
        });
      }
    };

    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Set Organization Inactive',
        content: 'Are you sure you want to set this organization to inactive?',
        onOk: setActive,
        okText: 'OK',
      });
    };

    if (isActive && dontSetInactive) {
      this.setState({
        error: orgType === 0 ? msgs.serviceOrgError : msgs.dentalOrgError,
      });
      return;
    }
    if (isActive && !dontSetInactive) {
      showConfirmModal();
    }

    if (!isActive) {
      const org = {
        ...(this.state.org || {}),
        isActive: 1,
      } as Org;
      updateOrg(org);
      this.setState({
        loading: false,
        org,
        active: org.isActive,
      });
    }
  };

  render() {
    const { sState } = this.props;
    const {
      loading,
      show,
      error,
      success,
      org,
      orgType,
      eCols,
      isMyOrg,
      showModalTransfer,
      equipment,
      groups,
      users,
      locations,
      active,
    } = this.state;
    const iAmAdmin = userHasRole([0, 1, 2], sState);
    const iAmSuperOrTech = userHasRole([0, 1], sState);
    const showTransfer = orgType === 1 && isMyOrg;
    const canShowEdit = iAmAdmin ? true : false;
    const OrgTitle = this.getTitle();
    const modalProps = {
      onClose: () => {
        this.setState({ showModalTransfer: false });
      },
      onSubmit: this.submitTransfer,
      visible: showModalTransfer,
    };

    return (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          {!isMyOrg && (
            <Link onClick={() => this.props.history.goBack()}>
              {`< Back to Manage ${OrgTitle} Organizations`}
            </Link>
          )}

          {!isMyOrg && (
            <ActiveSwitch
              canShowEdit={canShowEdit}
              active={active}
              loading={loading}
              toggleActive={this.toggleActive}
            />
          )}
        </div>
        <div
          css={css`
            margin-left: auto;
          `}
        >
          {canShowEdit && (
            <div
              css={css(`
                display: flex; 
                flex-direction: row; 
                float: right; 
                margin-left: 10px;`)}
            >
              {showTransfer && (
                <Button
                  css={css('margin-bottom: 5px; margin-right: 10px;')}
                  onClick={this.requestTransfer}
                  title={'Request Transfer'}
                />
              )}

              <div
                css={css(`
                  display: flex; 
                  float: right;`)}
              >
                {iAmSuperOrTech && (
                  <Button
                    css={css(`
                      background-color: ${colors.error};
                      border: solid 1px ${colors.error};
                      margin-right: 10px;
                      `)}
                    title="Delete"
                    onClick={this.deleteOrganization}
                  />
                )}
                <Button title="Edit" onClick={this.toggleEdit} />
              </div>
            </div>
          )}
          {canShowEdit && !isMyOrg && (
            <Button
              css={css('float: right;')}
              title="Transfer Equipment"
              onClick={this.assignEquipment}
            />
          )}
        </div>

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <TableTitleHeader>
            {get(org, 'name', 'N/A')}
            <Button
              size="small"
              css={css('margin-left: 10px;')}
              loading={loading}
              title="Refresh"
              onClick={() => this.retrieveData()}
            />
          </TableTitleHeader>
        </div>

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          {this.renderSubInfo()}
        </div>
        {show && (
          <div>
            <TableListSimple
              data={equipment}
              loading={loading}
              columns={eCols}
              title={'Equipment'}
              addBtn={'Manage Equipment'}
              totalTitle={(count: number) => `Total Equipment: ${count}`}
              blankText={`${OrgTitle} Org is not associated with any Equipment`}
              onBtnClick={this.goToManageEquipment}
              rowClick={this.goToViewEquipment}
            />

            {orgType === 1 && (
              <TableListSimple
                data={locations}
                loading={loading}
                columns={LocColums}
                title={'Locations'}
                addBtn={'Manage Locations'}
                totalTitle={(count: number) => `Total Locations: ${count}`}
                blankText={`${OrgTitle} Org is not associated with any Locations`}
                onBtnClick={this.goToManageLocations}
                rowClick={this.goToViewLocation}
              />
            )}

            <TableListSimple
              data={groups}
              loading={loading}
              columns={GroupColumns}
              title={'Groups'}
              addBtn={'Manage Groups'}
              totalTitle={(count: number) => `Total Groups: ${count}`}
              blankText={`${OrgTitle} Org is not associated with any Groups`}
              onBtnClick={this.goToManageGroups}
              rowClick={this.goToViewGroup}
            />

            <TableListSimple
              data={users}
              loading={loading}
              columns={UserColumns}
              title={'Users'}
              addBtn={'Manage Users'}
              totalTitle={(count: number) => `Total Users: ${count}`}
              blankText={`${OrgTitle} Org is not associated with any Users`}
              onBtnClick={this.goToManageUsers}
              rowClick={this.goToViewUser}
            />
          </div>
        )}

        <ModalTransfer
          // tslint:disable-next-line:no-any
          {...(modalProps as any)}
        />
      </div>
    );
  }
}

export const ManageOrgComponent = withRouter(_ManageOrg);
