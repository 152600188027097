/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Footer } from '../footer';
import {
  ForgotPassword,
  Login,
  BrandedLogin,
  Verify,
  ForgotPasswordSubmit,
} from '../authentication';
import { Dashboard } from '../dashboard';
import GateWay from './gateway.component';
import { connect } from 'react-redux';
import { AppState } from '../app.state';
import { isLoggedIn, isPB } from '../utils';
import styles from './navigation.styles';
import { EQRegistration } from '../registration';

interface IProps {
  sState: AppState;
}

class Navigation extends Component<IProps> {
  render() {
    const { sState } = this.props;
    const loggedIn = isLoggedIn(sState);
    const ispb = isPB(sState);
    return (
      <Router>
        <div className="App" css={css(styles.container)}>
          <Route path="/login" component={Login} />
          <Route path="/orgLogin/:org" component={BrandedLogin} />
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/eqRegistration" component={EQRegistration} />
          <Route
            path="/forgotPasswordSubmit"
            component={ForgotPasswordSubmit}
          />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/verify" component={Verify} />
          {(!loggedIn || !ispb) && (
            <div css={ispb ? css(styles.footerContainer) : null}>
              <Footer shouldExtend={ispb ? true : false} />
            </div>
          )}
          <GateWay />
        </div>
      </Router>
    );
  }
}

export default connect((sState: AppState) => ({ sState }))(Navigation);
