/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { CompressorSensorInstantPData } from '../../../../_shared/interfaces/publishData';
import { makeNumInput } from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: CompressorSensorInstantPData | undefined) => void;
}

type MaybeNumber = number | undefined;

export const CompressorSensorInstantDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [tT, setTT] = useState<MaybeNumber>(undefined);
  const [hDC, setHDC] = useState<MaybeNumber>(undefined);
  const [m2P, setM2P] = useState<MaybeNumber>(undefined);
  const [bars, setBars] = useState<MaybeNumber>(undefined);
  const [m2T, setM2T] = useState<MaybeNumber>(undefined);
  const [tH, setTH] = useState<MaybeNumber>(undefined);
  const [tP, setTP] = useState<MaybeNumber>(undefined);
  const [pDP, setPDP] = useState<MaybeNumber>(undefined);
  const [m2C, setM2C] = useState<MaybeNumber>(undefined);
  const [rGT, setRGT] = useState<MaybeNumber>(undefined);

  useEffect(() => {
    if (
      tT !== undefined &&
      hDC !== undefined &&
      m2P !== undefined &&
      bars !== undefined &&
      m2T !== undefined &&
      tH !== undefined &&
      tP !== undefined &&
      pDP !== undefined &&
      m2C !== undefined &&
      rGT !== undefined
    ) {
      validChanged(true);
      payloadChanged({
        tT: tT,
        hDC: hDC,
        m2P: m2P,
        bars: bars,
        m2T: m2T,
        tH: tH,
        tP: tP,
        pDP: pDP,
        m2C: m2C,
        rGT: rGT,
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [tT, hDC, m2P, bars, m2T, tH, tP, m2C, pDP, rGT]);

  return (
    <div>
      {makeNumInput('TT', tT, setTT)}
      {makeNumInput('HDC', hDC, setHDC)}
      {makeNumInput('M2P', m2P, setM2P)}
      {makeNumInput('Bars', bars, setBars)}
      {makeNumInput('M2T', m2T, setM2T)}
      {makeNumInput('TH', tH, setTH)}
      {makeNumInput('TP', tP, setTP)}
      {makeNumInput('PDP', pDP, setPDP)}
      {makeNumInput('M2C', m2C, setM2C)}
      {makeNumInput('RGT', rGT, setRGT)}
    </div>
  );
};
