import colors from '../../_shared/colors';

export default {
  rowMargin: `
    margin-bottom: 10px;
  `,
  hr: `
    width: 100%;
    height: 2px;
    background-color: ${colors.hrBorder};
  `,
};
