/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState, Fragment } from 'react';
import { BaseSensorStatisticsPData } from '../../../../_shared/interfaces/publishData';
import { TimePicker } from 'antd';
import {
  makeSwitch,
  makeInput,
  makeNumInput,
  makeInputGroup,
} from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: BaseSensorStatisticsPData | undefined) => void;
}

type MaybeNumber = number | undefined;

export const BaseDzSensorStatsDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  const now = (): Moment => moment();

  //STATE
  const [startTime, setStartTime] = useState<Moment>(now());
  const [endTime, setEndTime] = useState<Moment>(now().endOf('day'));
  const [seq, setSeq] = useState<MaybeNumber>(undefined);
  const ahMinState = useState<MaybeNumber>(undefined);
  const ahMaxState = useState<MaybeNumber>(undefined);
  const ahAvgState = useState<MaybeNumber>(undefined);
  const [m1P, setM1P] = useState<string | undefined>(undefined);
  const [m1T, setM1T] = useState<string | undefined>(undefined);
  const [bars, setBars] = useState<MaybeNumber>(undefined);
  const atMinState = useState<MaybeNumber>(undefined);
  const atMaxState = useState<MaybeNumber>(undefined);
  const atAvgState = useState<MaybeNumber>(undefined);
  const [kwh, setKWH] = useState<MaybeNumber>(undefined);
  const [vL, setVL] = useState<string | undefined>(undefined);
  const [time, setTime] = useState<Moment>(now());
  const [daily, setDaily] = useState<boolean>(false);

  const startTimeChanged = (m: Moment, _s: string) => setStartTime(m);
  const endTimeChanged = (m: Moment, _s: string) => setEndTime(m);
  const timeChanged = (m: Moment, _s: string) => setTime(m);

  useEffect(() => {
    if (
      startTime < endTime &&
      seq !== undefined &&
      ahMinState[0] !== undefined &&
      ahMaxState[0] !== undefined &&
      ahAvgState[0] !== undefined &&
      m1P !== undefined &&
      m1T !== undefined &&
      bars !== undefined &&
      atMinState[0] !== undefined &&
      atMaxState[0] !== undefined &&
      atAvgState[0] !== undefined &&
      kwh !== undefined &&
      vL !== undefined
    ) {
      validChanged(true);
      payloadChanged({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        seq: seq,
        aH: [ahMinState[0], ahMaxState[0], ahAvgState[0]],
        m1P: m1P,
        m1T: m1T,
        bars: bars,
        aT: [atMinState[0], ahMaxState[0], ahAvgState[0]],
        kwh: kwh,
        vL: vL,
        time: time.unix(),
        daily: daily,
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [
    startTime,
    endTime,
    seq,
    ahMinState[0],
    ahMaxState[0],
    ahAvgState[0],
    m1P,
    m1T,
    bars,
    atMinState[0],
    atMaxState[0],
    atAvgState[0],
    kwh,
    vL,
    time,
    daily,
  ]);

  return (
    <div>
      {makeInputGroup(
        'Start Time',
        <TimePicker
          defaultValue={startTime}
          format={'HH:mm'}
          onChange={startTimeChanged}
        />
      )}
      {makeInputGroup(
        'End Time',
        <TimePicker
          defaultValue={endTime}
          format={'HH:mm'}
          onChange={endTimeChanged}
        />
      )}
      {makeNumInput('Seq', seq, setSeq)}
      {minMaxAvgInputs('AH', ahMinState, ahMaxState, ahAvgState)}
      {makeInput('M1P', m1P, setM1P)}
      {makeInput('M1T', m1T, setM1T)}
      {makeNumInput('Bars', bars, setBars)}
      {minMaxAvgInputs('AT', atMinState, atMaxState, atAvgState)}
      {makeNumInput('KWH', kwh, setKWH)}
      {makeInput('VL', vL, setVL)}
      {makeInputGroup(
        'Time',
        <TimePicker
          defaultValue={time}
          format={'HH:mm'}
          onChange={timeChanged}
        />
      )}
      {makeSwitch('Daily', daily, setDaily)}
    </div>
  );
};

export const minMaxAvgInputs = (
  sensorName: string,
  sensorMin: [MaybeNumber, (num: MaybeNumber) => void],
  sensorMax: [MaybeNumber, (num: MaybeNumber) => void],
  sensorAvg: [MaybeNumber, (num: MaybeNumber) => void]
): JSX.Element => {
  return (
    <Fragment>
      {makeNumInput(`${sensorName} min`, sensorMin[0], sensorMin[1])}
      {makeNumInput(`${sensorName} max`, sensorMax[0], sensorMax[1])}
      {makeNumInput(`${sensorName} avg`, sensorAvg[0], sensorAvg[1])}
    </Fragment>
  );
};
