/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  createMaintSensorDataInputs,
  sensorDataIsValid,
} from './maint-form-container.component';
import { Model } from '../../../../_shared/interfaces/model';
import {
  MaintSensorData,
  VacuumMaintPData,
} from '../../../../_shared/interfaces/publishData';

type Payload = VacuumMaintPData;

interface IProps {
  model: Model;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const VacuumDzMaintDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  const emptyMaintSensorData = (): MaintSensorData => [
    false,
    0,
    100,
    moment()
      .utc()
      .unix(),
  ];

  //STATE
  const [belt, setBelt] = useState<MaintSensorData>(emptyMaintSensorData());
  const [maFilter, setMAFilter] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [vcFilter, setVCFilter] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [oilFilter, setOilFilter] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );
  const [pump, setPump] = useState<MaintSensorData>(emptyMaintSensorData());
  const [oilChange, setOilChange] = useState<MaintSensorData>(
    emptyMaintSensorData()
  );

  //EFFECTS
  useEffect(() => {
    //VALIDATE!
    if (
      sensorDataIsValid(belt) &&
      sensorDataIsValid(maFilter) &&
      sensorDataIsValid(vcFilter) &&
      sensorDataIsValid(oilFilter) &&
      sensorDataIsValid(pump) &&
      sensorDataIsValid(oilChange)
    ) {
      validChanged(true);
      payloadChanged({
        belt: belt,
        mafilter: maFilter,
        vcfilter: vcFilter,
        oilfilter: oilFilter,
        pump: pump,
        oilchange: oilChange,
      });
    } else {
      validChanged(false);
    }
  }, [belt, maFilter, vcFilter, oilFilter, pump, oilChange]);

  return (
    <div>
      {createMaintSensorDataInputs('Belt', belt, setBelt)}
      {createMaintSensorDataInputs('MA Filter', maFilter, setMAFilter)}
      {createMaintSensorDataInputs('VC Filter', vcFilter, setVCFilter)}
      {createMaintSensorDataInputs('Oil Filter', oilFilter, setOilFilter)}
      {createMaintSensorDataInputs('Pump', pump, setPump)}
      {createMaintSensorDataInputs('Oil Change', oilChange, setOilChange)}
    </div>
  );
};
