export const Globals = `

`;

export const HeaderStyles = {
  container: `
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
  `,
  hide: `
    position: absolute;
    top: -999999999999999px;
  `,
};
