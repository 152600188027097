/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, MouseEvent } from 'react';
import { Alert, message, Upload, Icon, Button, Table } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import { get, orderBy } from 'lodash';
import FormTitle from '../../_shared/form-title';
import {
  getSerials,
  createSerial,
} from '../../_shared/services/manage-serials.service';
import XLSX from 'xlsx';
import styles from './manage-serials.styles';
import { RcFile } from 'antd/lib/upload';
import { Serial } from '../../_shared/interfaces/serial';

const COLS = [
  {
    title: 'Serial',
    dataIndex: 'SERIAL_NUMBER',
    editable: false,
  },
  {
    title: 'Device Id',
    dataIndex: 'DEVICEID',
    editable: false,
  },
  {
    title: 'Error',
    dataIndex: 'error',
    editable: false,
  },
];

interface ExcelData {
  SERIAL_NUMBER: string;
  DEVICEID: string;
  error: string;
}

interface IProps extends RouteComponentProps {
  sState: AppState;
}

class _ManageSerialsComponent extends Component<IProps> {
  state = {
    loading: true,
    success: null,
    error: null,
    data: [],
    serials: [],
    newSerials: [],
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    this.retrieveSerials();
  };
  retrieveSerials = async (shouldFetch = true) => {
    const state = { ...this.state };

    state.loading = true;

    this.setState(state);

    try {
      const serials = shouldFetch ? await getSerials() : state.serials;

      state.data = [...serials] as never[];
      state.serials = serials as never[];
      state.loading = false;

      this.setState(state);
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false, error: err.message });
    }
  };
  processData = (data: ExcelData[]) => {
    const serials: Serial[] = this.state.serials;
    const newSerials = data.map(d => {
      let error = '';
      serials.forEach(item => {
        if (
          d['SERIAL_NUMBER'] === item.serial &&
          d['DEVICEID'] === item.deviceId
        ) {
          return (error = 'Duplicate record');
        }
        if (d['SERIAL_NUMBER'] === item.serial) {
          return (error = 'Particle SN exists with different DeviceID');
        }
        if (d['DEVICEID'] === item.deviceId) {
          return (error = 'DeviceID exists with different Particle SN');
        }
        if (!d['SERIAL_NUMBER']) {
          return (error = 'Particle SN is missing');
        }
        if (!d['DEVICEID']) {
          return (error = 'Device Id is missing');
        }
      });
      return { ...d, key: d['SERIAL_NUMBER'], error };
    });
    const sortedNewSerials = orderBy(newSerials, 'error', 'desc');
    this.setState({
      newSerials: sortedNewSerials,
      loading: false,
    });
  };
  downloadTemplate = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const win = window.open(
      `https://particle-misc.s3.amazonaws.com/Serials_Template.xlsx`,
      '_blank'
    );
    win && win.focus();
  };
  onSaveTable = async (newData: []) => {
    this.processData(newData);
  };
  clearSerials = () => {
    this.setState({ data: [], newSerials: [] });
  };
  submitSerials = async () => {
    if (this.state.loading) return;

    this.setState({ loading: true });

    const { newSerials } = this.state;
    const failedSerials = [] as never[];

    Promise.all(
      newSerials
        .filter(s => {
          return !get(s, 'error');
        })
        .map(async s => {
          const ser = {
            serial: get(s, 'SERIAL_NUMBER'),
            deviceId: get(s, 'DEVICEID'),
          };
          try {
            await createSerial(ser);
          } catch (err) {
            console.error(err);
            failedSerials.push(s);
          }
        })
    );

    if (failedSerials.length > 0) {
      message.error(
        // tslint:disable-next-line:max-line-length
        'These serials failed to create. Please try again and make sure that the Serial Number does not already exist.'
      );
    } else {
      message.success('Serials created successfully');
    }

    this.setState({ newSerials: failedSerials, loading: false });
  };
  render() {
    const { data, loading, error, success, newSerials } = this.state;
    const draggerProps = {
      name: 'file',
      showUploadList: false,
      multiple: false,
      beforeUpload: (file: RcFile) => {
        this.setState({ loading: true });
        const fileType = file.name.substring(file.name.indexOf('.'));
        if (
          fileType !== '.xlsx' &&
          fileType !== '.xls' &&
          fileType !== '.csv'
        ) {
          this.setState({ error: 'File type not supported' });
          return false;
        }
        const fileReader = new FileReader();
        fileReader.onload = e => {
          try {
            let binary = '';
            const bytes = new Uint8Array(get(e, 'target.result'));
            const length = bytes.byteLength;
            for (let i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            const oFile = XLSX.read(binary, {
              type: 'binary',
              cellDates: true,
              cellStyles: true,
            });
            const sheet1 = oFile.Sheets[oFile.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(sheet1);
            this.processData((data as unknown) as ExcelData[]);
          } catch (err) {
            message.error(`Unable to process spreadsheet: ${err.message}`);
          }
        };
        fileReader.readAsArrayBuffer(file);
        return true;
      },
    };

    const tableProps = {
      dataSource: newSerials,
      columns: COLS,
      size: 'small',
      // onSave: this.onSaveTable,
      loading,
      rowKey: 'SERIAL_NUMBER',
      pagination: false,
      rowClassName: (item: ExcelData, index: number) =>
        `tbl-list-row-${index % 2}`,
      // tslint:disable-next-line:no-any
    } as any;
    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <FormTitle size={'18px'}>{'Serials'}</FormTitle>

        <Upload.Dragger css={css(styles.uploadContainer)} {...draggerProps}>
          <Button css={css(styles.dlTemplate)} onClick={this.downloadTemplate}>
            <Icon type="download" />
            <span>Download Template</span>
          </Button>

          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to add Serial Numbers
          </p>
        </Upload.Dragger>

        {newSerials.length > 0 && (
          <div>
            <Table {...tableProps} />
            <div
              css={css(
                SharedStyles.row,
                `justify-content: center; padding: 10px;`
              )}
            >
              <Button
                css={css`
                  width: 100px;
                  margin: 5px;
                `}
                type={'danger'}
                onClick={this.clearSerials}
              >
                Clear
              </Button>
              <Button
                css={css`
                  width: 100px;
                  margin: 5px;
                `}
                type={'primary'}
                onClick={this.submitSerials}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ManageSerialsComponent = withRouter(_ManageSerialsComponent);
