import colors from '../../_shared/colors';

export default {
  rowMargin: `
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  `,
  hr: `
    width: 100%;
    height: 2px;
    background-color: ${colors.hrBorder};
  `,
  billingPreferenceTitle: `
    color: ${colors.labelColor};
    font-weight: bold;
    font-size: 12px;
  `,
  noHandpiece: `
  font-size: 14px;
  font-style: italic;
  margin: 10px 0px;
  justify-content: center;
  alignItems: center;
  text-align: center;
  min-height: 50px;
  padding: 20px;
  `,
};
