/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { Model } from '../../../../_shared/interfaces/model';
import { makeSwitch, makeNumInput } from '../../spoof-a-publish.component';
import { VacuumTestResultsPData } from '../../../../_shared/interfaces/publishData';

type Payload = VacuumTestResultsPData;

interface IProps {
  model: Model;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const VacuumTestResultsDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [suctionPass, setSuctionPass] = useState<boolean>(true);
  const [suctionGaugeReading, setSuctionGaugeReading] = useState<
    number | undefined
  >();
  const [voltCalPass, setVoltCalPass] = useState<boolean>(true);
  const [voltCalResults, setVoltCalResults] = useState<number | undefined>();
  const [voltCalExpected, setVoltCalExpected] = useState<number | undefined>();
  const [electricalPass, setElectricalPass] = useState<boolean>(true);
  const [electricalResults, setElectricalResults] = useState<
    number | undefined
  >();
  const [electricalExpected, setElectricalExpected] = useState<
    number | undefined
  >();
  const [runtime, setRuntime] = useState<number | undefined>();
  const [ambient, setAmbient] = useState<number | undefined>();
  const [oilAlarm, setOilAlarm] = useState<number | undefined>();
  const [airLeak, setAirLeak] = useState<number | undefined>();
  const [probe, setProbe] = useState<number | undefined>();
  const [filter, setFilter] = useState<number | undefined>();
  const [locknut, setLocknut] = useState<number | undefined>();
  const [currentCalPass, setCurrentCalPass] = useState<boolean>(true);
  const [currentCalResults, setCurrentCalResults] = useState<
    number | undefined
  >();
  const [currentCalExpected, setCurrentCalExpected] = useState<
    number | undefined
  >();
  const [alarm, setAlarm] = useState<number | undefined>();
  const [oilLeakPost, setOilLeakPost] = useState<number | undefined>();
  const [oilLeakPre, setOilLeakPre] = useState<number | undefined>();
  const [dripPass, setDripPass] = useState<boolean>(true);
  const [dripRoomTemp, setDripRoomTemp] = useState<number | undefined>();
  const [dripLeft, setDripLeft] = useState<number | undefined>();
  const [dripRight, setDripRight] = useState<number | undefined>();
  const [dripMiddle, setDripMiddle] = useState<number | undefined>();
  const [flowPass, setFlowPass] = useState<boolean>(true);
  const [flowRate, setFlowRate] = useState<number | undefined>();

  //EFFECTS
  useEffect(() => {
    //VALIDATE!
    if (
      suctionGaugeReading !== undefined &&
      voltCalResults !== undefined &&
      voltCalExpected !== undefined &&
      electricalResults !== undefined &&
      electricalExpected !== undefined &&
      runtime !== undefined &&
      ambient !== undefined &&
      oilAlarm !== undefined &&
      airLeak !== undefined &&
      probe !== undefined &&
      filter !== undefined &&
      locknut !== undefined &&
      currentCalResults !== undefined &&
      currentCalExpected !== undefined &&
      alarm !== undefined &&
      oilLeakPost !== undefined &&
      oilLeakPre !== undefined &&
      dripRoomTemp !== undefined &&
      dripLeft !== undefined &&
      dripRight !== undefined &&
      dripMiddle !== undefined &&
      flowRate !== undefined
    ) {
      validChanged(true);

      payloadChanged({
        suction: [suctionPass ? 1 : 0, suctionGaugeReading],
        volt_cal: [voltCalPass ? 1 : 0, voltCalResults, voltCalExpected],
        electrical: [
          electricalPass ? 1 : 0,
          electricalResults,
          electricalExpected,
        ],
        runtime: runtime,
        ambient: ambient,
        oil_alarm: oilAlarm,
        air_leak: airLeak,
        probe: probe,
        filter: filter,
        locknut: locknut,
        current_cal: [
          currentCalPass ? 1 : 0,
          currentCalResults,
          currentCalExpected,
        ],
        alarm: alarm,
        oil_leak: { post: oilLeakPost, pre: oilLeakPre },
        drip: [dripPass ? 1 : 0, dripRoomTemp, dripLeft, dripMiddle, dripRight],
        flow: [flowPass ? 1 : 0, flowRate],
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [
    suctionGaugeReading,
    voltCalPass,
    voltCalResults,
    voltCalExpected,
    electricalPass,
    electricalResults,
    electricalExpected,
    runtime,
    ambient,
    oilAlarm,
    airLeak,
    probe,
    filter,
    locknut,
    currentCalPass,
    currentCalResults,
    currentCalExpected,
    alarm,
    oilLeakPost,
    oilLeakPre,
    dripPass,
    dripRoomTemp,
    dripLeft,
    dripRight,
    dripMiddle,
    flowPass,
    flowRate,
  ]);

  return (
    <div>
      {makeSwitch('Suction Pass', suctionPass, setSuctionPass)}
      {makeNumInput(
        'Suction Gauge',
        suctionGaugeReading,
        setSuctionGaugeReading
      )}
      {makeSwitch('Volt Calibration Pass', voltCalPass, setVoltCalPass)}
      {makeNumInput('Volt Test Results', voltCalResults, setVoltCalResults)}
      {makeNumInput(
        'Volt Expected Results',
        voltCalExpected,
        setVoltCalExpected
      )}
      {makeSwitch('Electrical Pass', electricalPass, setElectricalPass)}
      {makeNumInput(
        'Electrical Results',
        electricalResults,
        setElectricalResults
      )}
      {makeNumInput(
        'Electrical Expected',
        electricalExpected,
        setElectricalExpected
      )}
      {makeNumInput('Runtime', runtime, setRuntime)}
      {makeNumInput('Ambient', ambient, setAmbient)}
      {makeNumInput('Oil Alarm', oilAlarm, setOilAlarm)}
      {makeNumInput('Air Leak', airLeak, setAirLeak)}
      {makeNumInput('Probe', probe, setProbe)}
      {makeNumInput('Filter', filter, setFilter)}
      {makeNumInput('Locknut', locknut, setLocknut)}
      {makeSwitch(
        'Current Calibration Pass',
        currentCalPass,
        setCurrentCalPass
      )}
      {makeNumInput(
        'Current Test Results',
        currentCalResults,
        setCurrentCalResults
      )}
      {makeNumInput(
        'Current Expected Results',
        currentCalExpected,
        setCurrentCalExpected
      )}
      {makeNumInput('Alarm', alarm, setAlarm)}
      {makeNumInput('Oil Leak (Post)', oilLeakPost, setOilLeakPost)}
      {makeNumInput('Oil Leak (Pre)', oilLeakPre, setOilLeakPre)}
      {makeSwitch('Drip Pass', dripPass, setDripPass)}
      {makeNumInput('Drip Room Temp', dripRoomTemp, setDripRoomTemp)}
      {makeNumInput('Drip Left', dripLeft, setDripLeft)}
      {makeNumInput('Drip Middle', dripMiddle, setDripMiddle)}
      {makeNumInput('Drip Right', dripRight, setDripRight)}
      {makeSwitch('Flow Pass', flowPass, setFlowPass)}
      {makeNumInput('Flow Rate', flowRate, setFlowRate)}
    </div>
  );
};
