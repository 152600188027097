/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { AppState } from '../app.state';
import { connect } from 'react-redux';
import { isLoggedIn, getUrlParams, wait } from '../utils';
import {
  getUserData,
  loadToken,
} from '../authentication/authentication.actions';
import { Icon } from 'antd';
import styles from './gateway.styles';
import { checkWindowSize } from '../dashboard/dashboard.actions';
import { get, first, size } from 'lodash';
import {
  getEquipment,
  getEquipments,
} from '../_shared/services/manage-equipment.service';
import { getEnv } from '../config';
import localforage from 'localforage';
import { REG_KEY } from '../registration';

interface IProps extends RouteComponentProps {
  appState: AppState;
  getUserData: () => Promise<void>;
  loadToken: () => Promise<void>;
  checkWindowSize: () => void;
}

interface IParams {
  equipmentDetails?: string;
  org?: string;
}

export class GateWayComponent extends Component<IProps> {
  state = { thinking: true, redirect: null };
  initialParams: IParams = {};
  trackWidthChange = () => {
    this.props.checkWindowSize();
    window.onresize = () => {
      this.props.checkWindowSize();
    };
  };
  continueFlow = () => {
    const { appState, getUserData } = this.props;
    const isLogged = isLoggedIn(appState);

    this.trackWidthChange();

    const initialUrl = window.location;
    const params = getUrlParams(initialUrl.href);
    this.initialParams = params;

    if (initialUrl.href.includes('eqRegistration')) {
      // https://aerasreg.dentalez.com/register?serialnumber=ACR2402204&activationcode=LCT9A-7D264
      // http://localhost:3000/eqRegistration?serialnumber=ACR2402204&activationcode=LCT9A-7D264 //from qr code
      // http://localhost:3000/eqRegistration?serialnumber=ACR2211181&activationcode=6WSKT-A358D //one that actually works
      if (params && params.serialnumber) {
        localStorage.setItem(REG_KEY, JSON.stringify({ equipment: params && params.serialnumber ? { ...params } : null }))
      }
      this.redirect('eqRegistration');
    } else if (initialUrl.href.includes('orgLogin') && params && params.org) {
      this.redirect(`orgLogin/${params.org}`);
    } else if (params && params.signUp) {
      localforage.clear();
      this.props.history.push('/login', {
        ...params,
      });
      this.setState({ thinking: false });
    } else if (!isLogged) {
      this.redirect('/login');
    } else {
      localStorage.removeItem(REG_KEY);
      getUserData()
        .then(() => {
          const sState = this.props.appState;
          const role = get(sState, 'auth.user.role');

          if (role === 5) {
            this.redirect('/dashboard/manufactureProcess');
          } else {
            this.redirect('/dashboard');
          }
        })
        .catch(err => {
          console.warn(err);
          this.redirect('/login');
        });
    }
  };
  componentDidMount = async () => {
    await this.props.loadToken().finally(this.continueFlow);
  };
  componentDidUpdate = (pp: IProps) => {
    const wasLogged = isLoggedIn(pp.appState);
    const isLogged = isLoggedIn(this.props.appState);
    if (isLogged && !wasLogged) {
      setTimeout(() => {
        this.initialParamsCheck();
      }, 1000);
    }
  };
  initialParamsCheck = async () => {
    if (isLoggedIn(this.props.appState)) {
      //wait for the models to load
      if (!size(get(this, 'props.appState.dash.models'))) {
        await wait();
        this.initialParamsCheck();
      } else {
        const edetailsSN = get(this, 'initialParams.equipmentDetailsSN');
        const edetails = get(this, 'initialParams.equipmentDetails');
        const redirect = get(this, 'initialParams.redirect');
  
        if (edetails) {
          const equipment = await getEquipment(edetails);
          this.props.history.push('/dashboard/equipmentDetails', {
            equipment,
          });
        } else if (edetailsSN) {
          const equipment = first(
            await getEquipments({ equipmentSN: { eq: edetailsSN } })
          );
          if (!!equipment) {
            this.props.history.push('/dashboard/equipmentDetails', {
              equipment,
            });
          }
        } else if (redirect) {
          this.props.history.push(redirect);
        }
      }
    }
  };
  redirect = (loc: string) => {
    const env = getEnv();
    this.setState(
      {
        redirect: env === 'local' ? undefined : loc,
        thinking: false,
      },
      async () => {
        //parse any intialParams here to redirect based on url
        this.initialParamsCheck();
      }
    );
  };
  render() {
    const { redirect, thinking } = this.state;

    if (redirect) {
      return <Redirect to={redirect || '/'} />;
    }

    return thinking ? (
      <div css={css(styles.thinkWrap)}>
        <Icon type="loading" css={css(styles.icon)} />
      </div>
    ) : null;
  }
}

export default connect(
  (appState: AppState) => ({ appState }),
  {
    getUserData,
    checkWindowSize,
    loadToken,
  }
)(withRouter(GateWayComponent));
