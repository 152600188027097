/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, MouseEvent } from 'react';
import Content from './forgot-password-submit.content';
import {
  getContent,
  testPassword,
  PWReqsMessage,
  buildErrorMsgFromForm,
} from '../../utils';
import { Button } from '../../_shared/button';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import Link from '../../_shared/link';
import { get } from 'lodash';

interface IProps extends RouteComponentProps, FormComponentProps {
  forgotPasswordSubmit: (
    email: string,
    code: string,
    password: string
  ) => Promise<void>;
  signIn: (un: string, pw: string, checkrole?: boolean) => Promise<boolean>;
}

export class FGPasswordComponent extends Component<IProps> {
  state = {
    loading: false,
    error: null,
  };
  goToLogin = () => {
    this.props.history.push('/login');
  };
  submit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      const { code, pw1 } = values;

      if (!testPassword(pw1)) {
        return this.setState({
          loading: false,
          error: PWReqsMessage,
        });
      }

      const pin = get(this, 'props.location.state.pin');
      if (pin !== code) {
        return this.setState({
          loading: false,
          error: 'Confirmation code does not match',
        });
      }

      try {
        const { email } = this.props.history.location.state;
        await this.props.forgotPasswordSubmit(email, `${code}:${pin}`, pw1);
        await this.props.signIn(email, pw1, false);
        this.props.history.push(get(this, 'props.location.state.destination', '/dashboard'));
      } catch (err) {
        this.setState({
          loading: false,
          error: err.message,
        });
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { error, loading } = this.state;
    const btnDisabled = !(
      getFieldValue('code') &&
      (getFieldValue('pw1') !== '' &&
        getFieldValue('pw1') === getFieldValue('pw2'))
    );
    const content = getContent(Content);

    return (
      <div className="ForgotPasswordSubmit" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <p css={css(styles.title)}>{content.title}</p>
        <p css={css(styles.subTitle)}>{content.subTitle}</p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <Form layout="vertical" onSubmit={this.submit}>
            <Form.Item label={content.codeLabel}>
              {getFieldDecorator('code', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input the code we sent to your email.',
                  },
                  {
                    max: 10,
                    message: 'Code is limited to 10 characters. ',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={content.passwordLabel}>
              {getFieldDecorator('pw1', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    max: 60,
                    message: 'Password is limited to 60 characters. ',
                  },
                  {
                    min: 8,
                    message: 'Passwords must be at least 8 characters.',
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label={content.confirmPasswordLabel}>
              {getFieldDecorator('pw2', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    max: 60,
                    message: 'Password is limited to 60 characters. ',
                  },
                  {
                    min: 8,
                    message: 'Passwords must be at least 8 characters.',
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>

            <Form.Item>
              <div css={css(styles.buttonContainer + `margin-top: 20px;`)}>
                <Button
                  loading={loading}
                  title={content.button}
                  onClick={this.submit}
                  disabled={btnDisabled}
                />

                <Link
                  css={css`
                    margin-top: 40px;
                  `}
                  onClick={this.goToLogin}
                >
                  {content.link}
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const ForgotPasswordSubmit = Form.create()(
  withRouter(FGPasswordComponent)
);
