import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: start;
  `,
  sideMenu: `
    min-width: 200px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    img {
      width: 150px;
      margin-top: 20px;
    }

    p {
      margin: 0;
      font-size: 12px;
      margin-bottom: 2px;
    }
  `,
  mainContainer: `
    min-height: calc(100%);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0px;
    border-left: 2px solid ${colors.inputBorder};
  `,
};
