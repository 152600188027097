import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { Sensor } from '../interfaces/sensor';
import { instance } from './instance';

export const getSensor = async (id: string) => {
  const resp = await instance.get(`/sensor/${id}`, { data: {} });
  return get(resp, 'data.sensor', null) as Sensor;
};

export const getSensors = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/sensor`, {
      where,
    });
  } else {
    resp = await instance.get(`/sensor`, { data: {} });
  }
  return get(resp, 'data.sensors', []) as Sensor[];
};

export const createSensor = async (sensor: Sensor) => {
  const resp = await instance.post('/sensor/create', {
    sensor: removeEmpty(sensor),
  });
  return get(resp, 'data.sensor', null) as Sensor;
};

export const updateSensor = (sensor: Sensor) => {
  return createSensor(sensor);
};
