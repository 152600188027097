import { AccountDetailsComponent } from './account-details.component';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import {
  updateUser,
  getUserData,
} from '../../authentication/authentication.actions';

export const AccountDetails = connect(
  (state: AppState) => ({ sState: state }),
  {
    updateUser,
    getUserData,
  }
)(AccountDetailsComponent);
