module.exports = {
  highlight: '#6F4B9E',
  highlightAlt: '#663445',
  labelColor: '#4d4d4d',
  inputBorder: '#efefef',
  disabledColor: '#bed3d7',
  disabledLight: '#f5f5f5',
  disabledText: 'rgba(0,0,0,.25)',
  success: '#52c41a',
  error: 'red',
  alert: '#ff0000',
  warning: '#ffa500',
  lightBackground: 'white',
  black: 'black',
  white: 'white',
  overlay: 'rgba(0,0,0,0.3)',
  table0: '#eaf4f6',
  tagDark: '#4d4d4d',
  hrBorder: '#efefef',
  tableBorder: '#d6d6d6',
  buttonDark: '#4d4d4d',
  footerBg: '#9a9899',
  eventBg: '#663445',
  eventBgDark: '#4d2231',
  cardOutline: '#9e9e9e',
  infoRow: '#f0fbff',
  lightText: '#aeaeae',
  statusDebug: '#d9103c',
  statusMaint: '#e18b17',
  statusStandby: '#0f6fb5',
  statusOn: '#becd00',
  statusOff: '#777777',
};
