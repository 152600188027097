export interface IHPProfileRuntimesPublish {
  cmd: string;
  profiles: IHPProfileUsageData;
}

export interface IHPProfileUsageData {
  pro_1: number;
  pro_2: number;
  pro_3: number;
  pro_4: number;
  pro_5: number;
  pro_6: number;
  pro_7: number;
  pro_8: number;
  pro_9: number;
  pro_10: number;
}

export enum HandpieceGearRatio {
  oneToFive = 0,
  oneToOne = 1,
  fiveToOne = 2,
  eightToOne = 3,
  tenToOne = 4,
  sixteenToOne = 5,
}

export const humanReadableGearRatio = (g: HandpieceGearRatio): string => {
  switch (g) {
    case 0:
      return '1:5';
    case 1:
      return '1:1';
    case 2:
      return '5:1';
    case 3:
      return '8:1';
    case 4:
      return '10:1';
    case 5:
      return '16:1';
    default:
      return 'unsure';
  }
};

export interface HandpieceSettingBounds {
  lower: number;
  upper: number;
  incrementAmount: number;
}

const makeBounds = (
  l: number,
  u: number,
  i: number
): HandpieceSettingBounds => ({ lower: l, upper: u, incrementAmount: i });

export const torqueOptionsForGearRatio = (
  ratio: HandpieceGearRatio
): number[] => {
  const bounds = gearRatioTorqueBoundsMap[ratio];
  var flat: number[] = [];

  var lowerBound = bounds.lower;
  while (lowerBound <= bounds.upper) {
    flat.push(Number(lowerBound.toFixed(1)));
    lowerBound += bounds.incrementAmount;
  }

  return flat;
};

export const rpmOptionsForGearRatio = (ratio: HandpieceGearRatio): number[] => {
  const bounds = gearRatioRPMBoundsMap[ratio];

  var flat: number[] = [];

  for (const b of bounds) {
    var lowerBound = b.lower;
    while (lowerBound <= b.upper) {
      flat.push(lowerBound);
      lowerBound += b.incrementAmount;
    }
  }

  return flat;
};

//index with HandpieceGearRatio enum
export const gearRatioTorqueBoundsMap: {
  [key: number]: HandpieceSettingBounds;
} = {
  // 1:5
  0: makeBounds(0.1, 0.6, 0.1),
  // 1:1
  1: makeBounds(0.3, 3, 0.1),
  // 5:1
  2: makeBounds(1.5, 15, 0.5),
  // 8:1
  3: makeBounds(2.4, 24, 0.8),
  // 10:1
  4: makeBounds(3.0, 30, 1),
  // 16:1
  5: makeBounds(4.8, 48, 1.6),
};

//index with HandpieceGearRatio enum
export const gearRatioRPMBoundsMap: {
  [key: number]: HandpieceSettingBounds[];
} = {
  // 1:5
  0: [
    makeBounds(500, 950, 50),
    makeBounds(1000, 2400, 100),
    makeBounds(2500, 4750, 250),
    makeBounds(5000, 9500, 500),
    makeBounds(10000, 99000, 1000),
    makeBounds(100000, 200000, 5000),
  ],
  // 1:1
  1: [
    makeBounds(100, 190, 10),
    makeBounds(200, 480, 20),
    makeBounds(500, 950, 50),
    makeBounds(1000, 1900, 100),
    makeBounds(2000, 4800, 200),
    makeBounds(5000, 9500, 500),
    makeBounds(10000, 40000, 1000),
  ],
  // 5:1
  2: [
    makeBounds(20, 38, 2),
    makeBounds(40, 96, 4),
    makeBounds(100, 190, 10),
    makeBounds(200, 380, 20),
    makeBounds(400, 960, 40),
    makeBounds(1000, 1900, 100),
    makeBounds(2000, 8000, 200),
  ],
  // 8:1
  3: [
    makeBounds(12, 58, 2),
    makeBounds(60, 190, 10),
    makeBounds(200, 380, 20),
    makeBounds(400, 760, 40),
    makeBounds(800, 1940, 60),
    makeBounds(2000, 5000, 100),
  ],
  // 10:1
  4: [
    makeBounds(10, 19, 1),
    makeBounds(20, 48, 2),
    makeBounds(50, 95, 5),
    makeBounds(100, 190, 10),
    makeBounds(200, 480, 20),
    makeBounds(500, 950, 50),
    makeBounds(1000, 2000, 100),
  ],
  // 16:1
  5: [
    makeBounds(6, 29, 1),
    makeBounds(30, 95, 5),
    makeBounds(100, 190, 10),
    makeBounds(200, 380, 20),
    makeBounds(400, 970, 30),
    makeBounds(1000, 2500, 50),
  ],
};

export enum HandpieceRotationDirection {
  clockwise = 0,
  counterClockwise = 1,
}

export enum HandpieceEndoSetting {
  autoStop = 0,
  autoReverse = 1,
  autoReverseForward = 2,
  autoOff = 255,
}

export const humanReadableEndoSetting = (s: HandpieceEndoSetting): string => {
  switch (s) {
    case 0:
      return 'Auto Stop';
    case 1:
      return 'Auto Rev';
    case 2:
      return 'Auto Rev/Fwd';
    case 255:
      return 'n/a';
    default:
      return 'unsure';
  }
};

export interface IHandpiecePreset {
  name: string;
  rotation_clockwise: boolean;
  rpm: number;
  torque_threshold: number;
  endo_mode_enum_raw: number;
  gear_ratio_enum_raw: number;
}

export type HandpieceProfileKey =
  | 'profile1'
  | 'profile2'
  | 'profile3'
  | 'profile4'
  | 'profile5'
  | 'profile6'
  | 'profile7'
  | 'profile8'
  | 'profile9'
  | 'profile10';

type HandpieceEndoPresetKey = 'e1' | 'e2' | 'e3';
type HandpiecePrepPresetKey = 'p1' | 'p2' | 'p3';
export type HandpiecePresetKey =
  | HandpieceEndoPresetKey
  | HandpiecePrepPresetKey;

interface IHandpieceEndoPresetSet {
  [key: string]: IHandpiecePreset | undefined;
  e1: IHandpiecePreset;
  e2: IHandpiecePreset;
  e3: IHandpiecePreset;
}

interface IHandpiecePrepPresetSet {
  [key: string]: IHandpiecePreset | undefined;
  p1: IHandpiecePreset;
  p2: IHandpiecePreset;
  p3: IHandpiecePreset;
}

export interface IHandpieceProfile {
  name: string;
  endo_presets: IHandpieceEndoPresetSet;
  prep_presets: IHandpiecePrepPresetSet;
}

export interface IHandpieceDefaultsProfiles {
  [key: string]: IHandpieceProfile | undefined;
  profile1: IHandpieceProfile;
  profile2: IHandpieceProfile;
  profile3: IHandpieceProfile;
  profile4: IHandpieceProfile;
  profile5: IHandpieceProfile;
  profile6: IHandpieceProfile;
  profile7: IHandpieceProfile;
  profile8: IHandpieceProfile;
  profile9: IHandpieceProfile;
  profile10: IHandpieceProfile;
}

export interface IHandpieceLocationOverridePreset {
  profileKey: HandpieceProfileKey;
  presetKey: HandpiecePresetKey;
  preset: IHandpiecePreset;
}

export interface IHandpieceLocationOverrideProfile {
  profileKey: HandpieceProfileKey;
  name: string;
}
