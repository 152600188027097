/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertPublishData } from '../../../../_shared/interfaces/publishData';
import { TimePicker } from 'antd';
import {
  makeInputGroup,
  makeInput,
  makeNumInput,
} from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: AlertPublishData | undefined) => void;
}

export const BaseDzAlertDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [source, setSource] = useState<string | undefined>(undefined);
  const [time, setTime] = useState<Moment>(moment());
  const [error, setError] = useState<number | undefined>(undefined);
  const [seq, setSeq] = useState<number | undefined>(undefined);

  //HANDLERS
  const timeChanged = (m: Moment, _s: string) => {
    setTime(m);
  };

  //EFFECTS
  useEffect(() => {
    //VALIDATE!
    if (source !== undefined && error !== undefined && seq !== undefined) {
      validChanged(true);
      payloadChanged({
        source: source,
        time: time.unix(),
        error: error,
        seq: seq,
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [source, time, error, seq]);

  return (
    <div>
      {makeInput('Source', source, setSource)}
      {makeInputGroup(
        'Time',
        <TimePicker
          defaultValue={time}
          format={'HH:mm'}
          onChange={timeChanged}
        />
      )}
      {makeNumInput('Error', error, setError)}
      {makeNumInput('Seq', seq, setSeq)}
    </div>
  );
};
