/** @jsx jsx */
import { Publish } from '../../_shared/interfaces/publish';
import { getStatus } from '../../_shared/services/status.service';
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import {
  generateTGDeviceCMD,
  getDeviceStatusFromEquip,
  getEquipConfig,
  getEquipmentModel,
  getEquipmentType,
  findLatestPublish,
  isPB,
  TG_ROLES,
} from '../../utils';
import { Alert, Modal, Icon, message } from 'antd';
import colors from '../../_shared/colors';
import { AppState } from '../../app.state';
import { get, chain } from 'lodash';
import styles from './manage-tandem-group.styles';
import { TableListMultiGroup } from '../../_shared/table-list-multi';
import { getTandemGroup } from '../../_shared/services/manage-tandem-groups.service';
import {
  TandemGroup,
  _TandemGroup,
} from '../../_shared/interfaces/tandemGroup';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { VacuumConfigPData } from '../../_shared/interfaces/publishData';
import { EquipmentStatus } from '../../_shared/interfaces/equipmentStatus';
import { getOrg } from '../../_shared/services/manage-orgs.service';
import { Org } from '../../_shared/interfaces/org';
import { Sidebar } from '../equipment-details/sidebar.component';
import { Model } from '../../_shared/interfaces/model';
import { Location } from '../../_shared/interfaces/location';
import { cardStyles } from '../manage-equipment/manage-equipment.styles';
import { renderFWErrorModal } from '../../_shared/fw-error-modal';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import { getEquipmentRecord, getEquipments } from '../../_shared/services/manage-equipment.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { ColumnProps } from 'antd/lib/table';

interface IProps extends RouteComponentProps {
  sState: AppState;
  updateMyEquipment: () => void;
}

const getSensorInfo = (_this: ManageTandemGroup) => {
  const { sState } = _this.props;
  const { equipment } = _this.state;

  let map: { [key: string]: { [key: string]: string | undefined } } = {};

  for (var e of equipment) {
    if (!e.deviceId) continue;

    const sensors = get(
      sState,
      `dash.equipMap.${e.deviceId}.sensorStatistics`,
      []
    );

    const latest = findLatestPublish(sensors as Publish[]);
    let ip: string | undefined = undefined;

    if (latest && latest.data) {
      const dataip = get(latest, 'data.IP');
      ip = Array.isArray(dataip)
        ? dataip[2]
        : dataip === 'null'
          ? undefined
          : dataip;
    }

    const sn = {
      IP: ip,
    };

    map[e.deviceId] = sn;
  }

  return map;
};

const getEquipColumns = (_this: ManageTandemGroup): ColumnProps<Equipment>[] => {
  const { isVacuum, equipmentStatus } = _this.state;
  const sensorInfo = getSensorInfo(_this);

  let cols = [
    {
      title: 'Serial Number',
      width: '12%',
      dataIndex: 'equipmentSN',
      render: (_text: string, record: Equipment) => {
        return <span>{record.equipmentSN || record.name}</span>;
      },
    },
    {
      title: 'Model ID',
      width: '8%',
      dataIndex: 'modelId',
    },
    {
      title: 'Aeras Controller',
      width: '15%',
      dataIndex: 'controllerSerial',
      render: (_text: string, record: Equipment) => {
        return <span>{record.controllerSerial}</span>;
      },
    },
    ...isVacuum
      ? [{
        title: 'Vacuum Level',
        dataIndex: 'vacuumLevel',
        width: '12%',
        render: (_text: string, record: Equipment) => {
          if (!record.deviceId) {
            return <span>{'--'}</span>;
          }

          const status = sensorInfo[record.deviceId];
          const level = status['IP'];
          if (level === undefined) return <span>{'--'}</span>;
          return <span>{`${level} inHg`}</span>;
        },
      }]
      : [],
    ...isVacuum
      ? [{
        title: 'Low Set Point',
        dataIndex: 'target_pressure',
        width: '12%',
        render: (_text: string, record: Equipment) => {
          const status = equipmentStatus.find(
            s => s.coreid === record.deviceId
          );
          let vacuumConfigData: VacuumConfigPData | undefined = undefined;
          if (status && status.dz_config) {
            const config = (status.dz_config
              .data as unknown) as VacuumConfigPData;
            if (config) {
              vacuumConfigData = config;
            }
          }

          if (
            vacuumConfigData &&
            vacuumConfigData.tandem &&
            vacuumConfigData.tandem.target_pressure
          ) {
            return <span>{vacuumConfigData.tandem.target_pressure}</span>;
          } else {
            return <span>{'--'}</span>;
          }
        },
      }]
      : [],
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      render: (_text: string, equip: Equipment) => {
        return _this.renderStatus({
          equipment: equip,
          loading: _this.state.loading,
          sState: _this.props.sState,
        });
      },
    },
    {
      title: 'Connected',
      dataIndex: 'connected',
      width: '12%',
      render: (_text: string, equip: Equipment) => {
        const deviceId = get(equip, 'deviceId', '');
        const configs = get(
          _this.props.sState,
          `dash.equipMap[${deviceId}].configs`
        );
        const isCentral = get(equip, 'tandemGroupRole') === 'central';
        const isConnected = get(configs, '[0].data.tandem.joined');
        return isCentral ? (
          <span>N/A</span>
        ) : isConnected ? (
          <span>Yes</span>
        ) : (
          <span css={css(`color: ${colors.error}; font-weight: bold`)}>No</span>
        );
      },
    },
    {
      title: 'Central',
      dataIndex: '',
      wdith: '10%',
      render: (_text: string, record: Equipment) => {
        const equip = record;
        const showCheckBox = equip.tandemGroupRole === 'central';
        if (showCheckBox) {
          return (
            <Icon
              type={'check-circle'}
              css={css(`color: ${colors.success};`)}
            />
          );
        }
        return (
          <Link
            css={css(`font-size: 14px`)}
            onClick={() => _this.confirmSetAsCentral(record)}
          >
            Set as central
          </Link>
        );
      },
    },
  ];

  return cols;
};

interface IState {
  show: boolean;
  loading: boolean;
  isEdit: boolean;
  error: string | undefined;
  success: string | undefined;
  location: Location | undefined;
  org: Org | undefined;
  equipment: Equipment[];
  equipmentStatus: EquipmentStatus[];
  availableEquipment: Equipment[];
  allEquipment: Equipment[];
  equipMap: {};
  showModalTransfer: boolean;
  tandemGroup: TandemGroup | undefined;
  back: string;
  model: string;
  isVacuum: boolean;
  isAdd: boolean;
  equip: Equipment | undefined;
  showSideBar: boolean;
  refreshLoading: false;
}

class ManageTandemGroup extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,
    availableEquipment: [],
    allEquipment: [],
    equipment: [],
    equipmentStatus: [],
    equipMap: {},
    location: undefined,
    org: undefined,
    tandemGroup: undefined,
    showModalTransfer: false,
    back: '',
    isAdd: false,
    equip: undefined,
    model: '',
    isVacuum: false,
    showSideBar: false,
    refreshLoading: false,
  } as IState;

  componentDidMount = async () => {
    const { history } = this.props;

    const tandemGroup = await getTandemGroup(
      get(history, 'location.state.tandemGroup.id')
    );
    this.setState({ tandemGroup }, this.retrieveData);
  };

  async componentDidUpdate(prevProps: IProps) {
    if (
      get(prevProps, 'sState.dash.equipment', []) !==
      get(this.props, 'sState.dash.equipment', [])
    ) {
      await this.retrieveEquipment(this.state.location);
    }
  }

  retrieveData = async (res?: { error?: string }) => {
    const {
      sState,
      history: { location },
    } = this.props;

    const back = get(location, 'state.back');
    const showSideBar = get(location, 'state.showSideBar');
    const _equipment = get(location, 'state.equipment');
    const model = _equipment && getEquipmentModel(_equipment, sState);
    const isEdit = get(location, 'state.editMode', this.state.isEdit);
    const isAdd = get(location, 'state.isAdd', false);
    try {
      if (res && res.error) {
        return this.setState({ error: res.error });
      }

      this.setState(
        {
          show: false,
          loading: true,
          refreshLoading: true,
        },
        () => this.setState({ show: true })
      );

      const _tandemGroup = (get(
        this.state,
        'tandemGroup'
      ) as unknown) as TandemGroup;
      const tandemGroup = await getTandemGroup(_tandemGroup.id);
      const locId = get(tandemGroup, 'locationId') as string;
      const allLocations = get(
        this.props,
        'sState.dash.locations'
      ) as Location[];
      const loc = allLocations.find(loc => loc.id === locId) as Location;
      const org = loc && (await getOrg(get(loc, 'orgId', '')));
      const equipment = await this.retrieveEquipment(loc);
      Promise.all(
        equipment.map(async equip => {
          const deviceId = get(equip, 'deviceId', '');
          if (deviceId) {
            await Promise.all([
              sendDeviceCommand(
                deviceId,
                {
                  arg: 'get_data',
                },
                { showErrorToast: false }
              ).catch(error => console.warn(error)),
              sendDeviceCommand(
                deviceId,
                {
                  arg: 'get_config',
                },
                { showErrorToast: false }
              ).catch(error => console.warn(error)),
            ]);
          }
        })
      );

      const isVacuum = (get(_tandemGroup, 'equipType', '') || '').toLowerCase() === 'aeras vacuum';

      this.setState({
        location: loc,
        org,
        show: true,
        loading: false,
        tandemGroup,
        back,
        isAdd,
        isEdit,
        equip: _equipment,
        model,
        isVacuum,
        showSideBar,
      });
      setTimeout(() => this.setState({ refreshLoading: false }), 5000);
    } catch (error) {
      console.error(error);
      this.setState({
        error: error.message,
        loading: false,
      });
    }
  };

  retrieveEquipment = async (location?: Location) => {
    const allEquipments = await getEquipments();
    const equipmentsForOrg: Equipment[] = allEquipments.filter(
      (equip: Equipment) =>
        location ? equip.dentalOrgId === get(location, 'orgId') : true
    );
    const tandemGroup = get(this.state, 'tandemGroup') as TandemGroup;
    const equipMap = chain(equipmentsForOrg)
      .keyBy('id')
      .value();
    const availableEquipment = equipmentsForOrg
      .filter(equip => {
        return (
          get(tandemGroup, 'equipType') ===
          getEquipmentType(
            equip.equipmentSN as string,
            equip.modelId as string
          ) &&
          equip.locationId === tandemGroup.locationId &&
          !equip.tandemGroupId
        );
      })
      .map(e => {
        const name = get(e, 'name', '');
        const id = get(e, 'id', '');
        const serial = get(e, 'equipmentSN', '');
        return {
          text: `${name} (${serial})`,
          value: id,
        };
      });

    const equipment: Equipment[] = allEquipments.filter(
      (equip: Equipment) => equip.tandemGroupId === tandemGroup.id
    );

    let equipmentStatus: EquipmentStatus[] = [];
    for (var e of equipment) {
      if (e.deviceId) {
        const { _status } = await getEquipmentRecord(e.deviceId, true);
        equipmentStatus.push(_status as EquipmentStatus);
      }
    }

    this.setState({
      equipmentStatus,
      availableEquipment,
      equipMap,
      equipment,
      allEquipment: equipmentsForOrg,
    });
    return equipment;
  };

  refreshConfigs = async () => {
    const { equipment } = this.state;
    this.setState({ refreshLoading: true });
    Promise.all(
      equipment.map(async equip => {
        const deviceId = get(equip, 'deviceId', '');
        if (deviceId) {
          await sendDeviceCommand(
            deviceId,
            {
              arg: 'get_config',
            },
            { showErrorToast: false }
          ).catch(error => console.warn(error));
        }
      })
    );

    setTimeout(() => this.setState({ refreshLoading: false }), 5000);
  };

  renderStatus = (props: {
    equipment: Equipment;
    loading: boolean;
    sState: AppState;
  }) => {
    const { loading, equipment } = props;

    const deviceState = getDeviceStatusFromEquip(equipment);
    const opt: {
      [key: string]: { color: string; label: string; canDisplay?: boolean };
    } = {
      loading: { color: colors.statusOff, label: 'loading' },
      off: { color: colors.statusOff, label: 'Offline' },
      debug: {
        color: colors.statusDebug,
        label: 'Debug',
        canDisplay: false,
      },
      maint: {
        canDisplay: false,
        color: colors.statusMaint,
        label: 'Maint Mode',
      },
      standby: { color: colors.statusStandby, label: 'Standby' },
      on: { color: colors.statusOn, label: 'On' },
      highPressure: {
        canDisplay: false,
        color: colors.statusStandby,
        label: 'High Pressure',
      },
      headsEnabled: {
        canDisplay: false,
        color: colors.statusDebug,
        label: 'Heads Disabled',
      },
      scheduleEnabled: { canDisplay: false, label: '', color: '' },
      highVoltage: {
        canDisplay: false,
        color: colors.statusStandby,
        label: 'Power Up Override',
      },
    };
    const renderLabel = (option: { label: string; color: string }) => {
      return (
        <div
          key={option.label}
          css={css(cardStyles.cardStatus, { backgroundColor: option.color })}
        >
          {option.label}
          {loading && <Icon css={css('margin-left: 2px;')} type="loading" />}
        </div>
      );
    };

    return (
      <div css={css(cardStyles.statusContainer)}>
        {!loading && equipment
          ? Object.keys(deviceState).map(k => {
            let obj = deviceState[k];
            if (k === 'headsEnabled') {
              obj = !obj;
            }
            const sel = opt[k];
            const canDisplay =
              sel !== undefined && sel.canDisplay !== undefined
                ? sel.canDisplay
                : true;

            return !obj || !sel || !canDisplay ? null : renderLabel(sel);
          })
          : !loading
            ? renderLabel(opt.off)
            : renderLabel(opt.loading)}
      </div>
    );
  };

  editTandemGroup = (
    tandemGroup: TandemGroup | undefined,
    editMode = true,
    isAdd = false
  ) => {
    this.props.history.push('/dashboard/editTandemGroup', {
      tandemGroup,
      editMode,
      isAdd,
      back: `< Back To Tandem Group Details`,
    });
  };

  goToBillingHistory = (equipmentId: string) => { };

  renderSubInfo = () => {
    const { org, location, tandemGroup } = this.state;
    const items = [
      {
        title: 'Number',
        var: 'id',
        toText: (id: string) => `Number: ${get(tandemGroup, 'groupNumber')}`,
      },
      {
        title: 'Dental Org',
        var: 'id',
        toText: (id: string) => `Dental Organization: ${get(org, 'name')}`,
      },
      {
        title: 'Location',
        var: 'id',
        toText: (id: string) => `Location: ${get(location, 'name')}`,
      },
    ];
    return (
      <span css={css(`font-size: 12px;`)}>
        {items
          .filter((item, i) => {
            const hasVal =
              item.title === 'Dental Org'
                ? (get(org, item.var) as never)
                : item.title === 'Location'
                  ? get(location, item.var)
                  : item.title === 'Number'
                    ? get(tandemGroup, item.var)
                    : false;

            const val = hasVal && item.toText(hasVal as string);
            return val;
          })
          .map((item, i) => {
            const hasVal =
              item.title === 'Dental Org'
                ? (get(org, item.var) as never)
                : item.title === 'Location'
                  ? get(location, item.var)
                  : item.title === 'Number'
                    ? get(tandemGroup, item.var)
                    : false;

            const val = hasVal && item.toText(hasVal as string);
            return (
              <span key={i}>
                {i > 0 ? (
                  <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</span>
                ) : null}
                {`${val}`}
              </span>
            );
          })}
      </span>
    );
  };

  confirmSetAsCentral = (record: Equipment) => {
    Modal.confirm({
      title: `Set ${record.equipmentSN} as Central`,
      content:
        'This will set this piece of equipment as the central equipment for the tandem group',
      onCancel: () => 0,
      onOk: () => this.setAsCentral(record.id as string),
      okText: 'OK',
    });
  };

  setAsCentral = async (equipmentId: string) => {
    this.setState({ loading: true });
    const tandemGroup = get(this.state, 'tandemGroup') as TandemGroup;
    const equipForTGroup = this.state.equipment;
    const centralEq = equipForTGroup.find(
      equip =>
        equip.tandemGroupRole === 'central' || equip.id === tandemGroup.primary
    ) as Equipment;
    const equipToUpdate = equipForTGroup.find(
      equip => equip.id === equipmentId
    ) as Equipment;
    try {
      const equipmentSN: string = get(centralEq, 'equipmentSN', '');
      const fwVersion: number = get(centralEq, 'fw_version', 0);
      if (centralEq && fwVersion >= 34) {
        const arg = generateTGDeviceCMD(tandemGroup.groupNumber, TG_ROLES.PEER);
        await sendDeviceCommand(
          get(centralEq, 'deviceId') as string,
          { arg },
          {
            tandem: {
              tandemGroupNumber: tandemGroup.groupNumber,
              tandemGroupRole: TG_ROLES.PEER,
            },
          }
        );
        message.success(
          `${get(centralEq, 'equipmentSN') ||
          get(centralEq, 'name')} role in tandem group has been updated`
        );
      } else if (centralEq && fwVersion < 34) {
        renderFWErrorModal(equipmentSN, fwVersion.toString());
      }
      try {
        const equipmentSN: string = get(equipToUpdate, 'equipmentSN', '');
        const fwVersion: number = get(equipToUpdate, 'fw_version', 0);
        if (fwVersion < 34) {
          return renderFWErrorModal(equipmentSN, fwVersion.toString());
        }
        const arg = generateTGDeviceCMD(
          tandemGroup.groupNumber,
          TG_ROLES.CENTRAL
        );
        await sendDeviceCommand(
          get(equipToUpdate, 'deviceId') as string,
          { arg },
          {
            tandem: {
              tandemGroupNumber: tandemGroup.groupNumber,
              tandemGroupRole: TG_ROLES.CENTRAL,
            },
          }
        );
        message.success(
          `${get(equipToUpdate, 'equipmentSN') ||
          get(equipToUpdate, 'name')} role in tandem group has been updated`
        );
      } catch (error) {
        console.error(error);
        message.error(
          `Unable to update ${get(equipToUpdate, 'equipmentSN') ||
          get(equipToUpdate, 'name')} role in tandem group`
        );
      }
      this.onDone();
    } catch (error) {
      console.error(error);
      message.error(
        `Unable to update ${get(centralEq, 'equipmentSN') ||
        get(centralEq, 'name')} role in tandem group`
      );
      this.setState({
        loading: false,
      });
    }
  };

  equipResult = (res: { tags: string[] }) => {
    return new Promise(async (resolve, reject) => {
      const { tandemGroup } = this.state;

      const tGroup = tandemGroup as TandemGroup;
      const equipIds = res.tags;
      const newEquipment = equipIds.map(e => get(this.state, `equipMap[${e}]`));
      let complete = 0;
      const checkDone = async () => {
        complete += 1;
        if (complete >= newEquipment.length) {
          resolve(undefined);
          this.onDone();
        }
      };

      newEquipment.forEach(async (equip: Equipment) => {
        const arg = generateTGDeviceCMD(tGroup.groupNumber, TG_ROLES.PEER);
        try {
          const equipmentSN: string = get(equip, 'equipmentSN', '');
          const fwVersion: number = get(equip, 'fw_version', 0);
          if (fwVersion < 34) {
            return renderFWErrorModal(equipmentSN, fwVersion.toString());
          }
          await sendDeviceCommand(
            get(equip, 'deviceId', ''),
            { arg },
            {
              tandem: {
                tandemGroupNumber: tGroup.groupNumber,
                tandemGroupRole: TG_ROLES.PEER,
              },
            }
          );
          message.success(
            `${equip.name} successfully added to the Tandem Group`
          );
        } catch (error) {
          console.error(error);
          message.error(`Could not add ${equip.name} to the Tandem Group`);
        }

        checkDone();
      });
    });
  };

  delTandemGroup = async () => {
    const _tandemGroup = this.state.tandemGroup as TandemGroup;
    const tgEquip = get(this, 'props.sState.dash.equipment', []).filter(
      (e: Equipment) => get(e, 'tandemGroupId') === _tandemGroup.id
    ) as Equipment[];

    const centralEquipment = tgEquip.find(
      eq => eq.tandemGroupRole === 'central'
    ) as Equipment;
    const peerEquipment = tgEquip.filter(
      eq => eq.tandemGroupRole === 'peer' || !eq.tandemGroupRole
    );
    const delTg = async () => {
      try {
        if (!tgEquip.length) {
          message.success(`${_tandemGroup.name} successfully deleted`);
          this.onDone();
          return this.props.history.goBack();
        }
        const arg = generateTGDeviceCMD(0, TG_ROLES.NONE);
        await Promise.all(
          peerEquipment.map(async equip => {
            try {
              await sendDeviceCommand(
                get(equip, 'deviceId', ''),
                { arg },
                {
                  tandem: {
                    tandemGroupNumber: 0,
                    tandemGroupRole: TG_ROLES.NONE,
                  },
                }
              );
              message.success(
                `${equip.equipmentSN || equip.name} removed from tandem group ${_tandemGroup.name
                }`
              );
            } catch (error) {
              message.error(
                // tslint:disable-next-line: max-line-length
                `${equip.equipmentSN ||
                equip.name} could not be removed from tandem group ${_tandemGroup.name
                }. Check to make sure it is turned on.`
              );
              throw new Error();
            }
          })
        );

        if (centralEquipment) {
          await sendDeviceCommand(
            get(centralEquipment, 'deviceId', ''),
            { arg },
            { tandem: { tandemGroupNumber: 0, tandemGroupRole: TG_ROLES.NONE } }
          )
            .then(() => {
              message.success(
                `${get(
                  centralEquipment,
                  'name',
                  ''
                )} removed from tandem group ${_tandemGroup.name}`
              );
            })
            .catch(error => {
              throw new Error(error);
            });
        }

        const equip = tgEquip;
        if (!equip.length) {
          message.success(`${_tandemGroup.name} successfully deleted`);
        } else {
          this.onDone();
          return message.error(`${_tandemGroup.name} could not be deleted`);
        }
        trackEvent(eventTypes.tandem_delete, {
          tandemGroup: _tandemGroup,
          location: this.state.location,
          org: this.state.org,
        });
        this.onDone();
        this.props.history.goBack();
      } catch (error) {
        message.error(`${_tandemGroup.name} could not be deleted`);
      }
    };
    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Delete Tandem Group',
        content: 'Are you sure you want to delete this Tandem Group?',
        onOk: delTg,
        okText: 'Delete',
      });
    };
    showConfirmModal();
  };

  equipRemove = (rows: Equipment[]) => {
    return new Promise(async (resolve, reject) => {
      let complete = 0;
      const checkDone = async () => {
        complete += 1;
        if (complete >= rows.length) {
          resolve(undefined);
          this.onDone();
        }
      };
      rows.map(async equip => {
        const _equip: Equipment = new _Equipment(equip);
        try {
          const arg = generateTGDeviceCMD(0, TG_ROLES.NONE);
          await sendDeviceCommand(
            equip.deviceId as string,
            { arg },
            { tandem: { tandemGroupNumber: 0, tandemGroupRole: TG_ROLES.NONE } }
          );
          message.success(
            `${_equip.equipmentSN ||
            _equip.name} successfully removed from the Tandem Group`
          );

          checkDone();
        } catch (error) {
          console.error(error);
          message.error(
            `${_equip.equipmentSN ||
            _equip.name} could not be removed from the Tandem Group`
          );
          this.setState({
            loading: false,
          });
          checkDone();
        }
      });
    });
  };

  showRemoveAlert = (selectedRows: Equipment[]) => {
    if (
      selectedRows.some(equip => {
        return equip.tandemGroupRole === 'central';
      })
    ) {
      this.renderRemoveCentralModal();
      return true;
    }
    return false;
  };

  renderRemoveCentralModal = () => {
    return Modal.error({
      title: 'Central Equipment Required',
      content:
        // tslint:disable-next-line: max-line-length
        'Tandem groups must have a central piece of equipment.  Select a different piece of equipment as central before removing this piece of equipment.',
    });
  };

  showLimitAlert = () => {
    const { equipment } = this.state;
    if (equipment.length >= 4) {
      this.renderEquipLimitModal();
      return true;
    }
    return false;
  };

  showNoAvailEquipAlert = () => {
    const { availableEquipment } = this.state;
    if (!availableEquipment || !availableEquipment.length) {
      this.renderNoAvailEquipModal();
      return true;
    }
    return false;
  };

  renderEquipLimitModal = () => {
    Modal.error({
      title: 'Tandem Group is Full',
      content: 'Tandem Groups can have a maximum of four pieces of equipment',
    });
  };

  renderNoAvailEquipModal = () => {
    Modal.error({
      title: 'No Available Equipment',
      content:
        'There is no available equipment to be added to the Tandem Group.',
    });
  };

  showNoEquipAlert = (rows: Equipment[]) => {
    const { equipment } = this.state;
    const equipIdsToRemove = rows.map(equip => equip.id);

    const newEquipment = equipment
      .filter(equip => !equipIdsToRemove.includes(equip.id))
      .map(equip => equip.id);
    if (!newEquipment.length) {
      this.renderNoEquipModal();
      return true;
    }
    return false;
  };

  renderNoEquipModal = () => {
    Modal.error({
      title: '',
      content:
        // tslint:disable-next-line: max-line-length
        'Tandem groups must have at least 1 piece of equipment.  To remove all equipment from a group, select the Delete option.',
    });
  };

  getSelectedEquipLimit = () => {
    const { equipment } = this.state;
    return 4 - equipment.length || 4;
  };

  onDone = async () => {
    try {
      await this.retrieveData();
      await this.props.updateMyEquipment();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      loading,
      show,
      error,
      success,
      equipment,
      availableEquipment,
      tandemGroup,
      equip,
      model,
      showSideBar,
      back,
      refreshLoading,
    } = this.state;

    const { sState } = this.props;
    const ispb = isPB(sState);
    const config = getEquipConfig(equip, sState);

    return (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link onClick={() => this.props.history.goBack()}>
            {back ? back : `< Back to Manage Tandem Groups`}
          </Link>
        </div>

        <div
          css={css(`
              display: flex;
              float: right;
              margin-left: 10px;`)}
        >
          <Button
            css={css(`
                      background-color: ${colors.error};
                      border: solid 1px ${colors.error};
                      margin-right: 10px;
                      `)}
            title="Delete"
            onClick={this.delTandemGroup}
          />
          <Button
            css={css('float: right;')}
            title="Edit"
            onClick={() => this.editTandemGroup(tandemGroup)}
          />
        </div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}
        <div
          css={css(
            styles.container,
            !ispb && `flex-direction: row;`,
            ispb && 'margin-bottom: 0px;'
          )}
        >
          {!ispb && showSideBar && (
            <Sidebar
              equipment={(equip as unknown) as Equipment}
              model={(model as unknown) as Model}
              config={config}
              goToBillingHistory={this.goToBillingHistory}
            />
          )}
          <div css={css(styles.mainContainer)}>
            <div css={css(SharedStyles.row, styles.rowMargin)}>
              <TableTitleHeader>
                {get(tandemGroup, 'name', 'N/A')}
                <Button
                  size="small"
                  css={css('margin-left: 10px;')}
                  loading={loading || refreshLoading}
                  title="Refresh"
                  onClick={() => {
                    this.refreshConfigs();
                    this.retrieveData();
                  }}
                />
              </TableTitleHeader>
            </div>
            <div css={css(SharedStyles.row, styles.rowMargin)}>
              {this.renderSubInfo()}
            </div>
            {show && (
              <div>
                <TableListMultiGroup
                  data={equipment as never}
                  loading={loading}
                  columns={getEquipColumns(this)}
                  canSelectRows={true}
                  canShowAdd={true}
                  title={'Equipment'}
                  removeLabel={'Remove Equipment'}
                  confirmMessage={
                    'Are you sure you want to remove this equipment from the Tandem Group?'
                  }
                  showRemoveAlert={this.showRemoveAlert}
                  modalTitle={'Add Equipment to Tandem Group'}
                  variable={'equipment'}
                  addBtn={'Add Equipment'}
                  allOpts={availableEquipment as never}
                  onResult={this.equipResult}
                  onRemove={this.equipRemove}
                  totalTitle={(count: number) => `Total Equipment: ${count}`}
                  blankText={
                    'Tandem Group is not associated with any Equipment'
                  }
                  onDone={this.onDone}
                  equipMap={this.state.equipMap}
                  selectedEquipLimit={this.getSelectedEquipLimit()}
                  showNoEquipAlert={this.showNoEquipAlert}
                  showLimitAlert={this.showLimitAlert}
                  showNoAvailEquipAlert={this.showNoAvailEquipAlert}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const ManageTandemGroupComponent = withRouter(ManageTandemGroup);
