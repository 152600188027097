/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import aeras_logo from '../../assets/Aeras_Logo_No.1.png';
import aeras_logo_white from '../../assets/Aeras_Logo_No.4.png';
import aeras_white from '../../assets/aeras_white.png';
import aeras_i from '../../assets/aeras_i.png';
import aeras_logo_footer from '../../assets/RV_Aeras.png';
import dental_ez from '../../assets/DentalEZ_Logo_cmyk.png';
import henry_schein_logo from '../../assets/henry-schein-logo.png';
import henry_schein_canada_logo from '../../assets/henry-schein-canada-logo.png';
import benco_logo from '../../assets/BencoLogo_Color.png';
import patterson_logo from '../../assets/Patterson_dental_logo.png';
import { useAppConfig } from '../hooks/useAppConfig';
import { get } from 'lodash';

const LOGOS = {
  default: aeras_logo,
  white: aeras_logo_white,
  aeras_i,
  aeras_white,
  footer1: aeras_logo_footer,
  footer2: dental_ez,
  henryschein: henry_schein_logo,
  henryscheinCanada: henry_schein_canada_logo,
  benco: benco_logo,
  patterson: patterson_logo,
};

interface IProps {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  small?: boolean;
  style?: {};
  src?: string;
  height?: number;
  width?: number;
  type?:
    | 'default'
    | 'aeras'
    | 'aeras_white'
    | 'white'
    | 'aeras_i'
    | 'footer1'
    | 'footer2'
    | 'henryschein'
    | 'henryscheinCanada'
    | 'benco'
    | 'patterson';
  onClick?: () => void;
}

const Dims = {
  small: { width: 60 },
  medium: { width: 80 },
  large: { width: 150 },
  xlarge: { width: 300 },
};

const Logo = (props: IProps) => {
  const app = useAppConfig();
  const { small, style, onClick, size } = props;
  const type = get(props, 'type', 'default');

  const dim = small ? Dims['small'] : size ? Dims[size] : Dims['large'];

  const logo = (() => {
    if (props.src) {
      return props.src;
    } else if (type === 'default') {
      return app.logo || get(app, 'aerasLogo');
    } else if (type === 'aeras') {
      return get(app, 'aerasLogo');
    } else {
      return LOGOS[type || 'default'];
    }
  })();

  const WidthOrHeight = (() => {
    if (props.height) {
      return `height: ${props.height}${props.height.toString().indexOf('px') > -1 ? '' : 'px'};`;
    } else {
      return `width: ${props.width || dim.width}px;`;
    }
  })();

  const hasHeight = !!props.height;

  return (
    <div
      className={'Logo'}
      css={css(`
        ${WidthOrHeight}
        display: flex;
        align-items: center;
        justify-content: center;
        color: #cecece;
        cursor: ${onClick ? 'pointer' : 'default'};
      `)}
      style={style}
      onClick={onClick}
    >
      <img
        alt={'DentalEZ'}
        css={css`
          width: ${hasHeight ? 'auto' : '100%'};
          height: 100%;
        `}
        src={logo}
      />
    </div>
  );
};

Logo.defaultProps = {
  type: 'default',
} as IProps;

export default Logo;
