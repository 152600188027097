import { get } from 'lodash';
export interface User {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | NotificationSettings
    | string[];
  userId: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  sub: string;
  orgId: string;
  role: number;
  isActive: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  email_verified?: boolean;
  notification: NotificationSettings;
  locations: string[];
  groups: string[];
  equipment: string[];
  mfa?: string;
  phoneVerified?: boolean;
}

export type NoteAvail = 'email' | 'sms';

export interface NotificationSettings {
  [key: string]: NoteAvail[];
  events: NoteAvail[];
  alerts: NoteAvail[];
  service: NoteAvail[];
}

export class _User {
  constructor(props?: User) {
    for (let key in props) {
      this[key] = props[key];
    }
    this.name = get(
      props,
      'name',
      `${get(props, 'firstName', '')} ${get(props, 'lastName', '')}`.trim()
    );
  }
  [key: string]:
    | string
    | number
    | undefined
    | boolean
    | NotificationSettings
    | string[];
  userId = '';
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  sub = '';
  orgId = '';
  role = 4;
  isActive = 0;
  name = '';
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  mfa = '';
  notification: NotificationSettings = {
    events: [],
    alerts: [],
    service: [],
  };
  locations = [];
  groups = [];
  equipment = [];
  phoneVerified = false;
}
