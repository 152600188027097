import colors from '../../_shared/colors';

export const cardStyles = {
  container: `
    width: 20%;
    float: left;
    padding: 10px;
  `,
  card: `
    height: 298px;
    overflow: hidden;
  `,
  cardOutline: `
    border-color: ${colors.cardOutline};

    &:hover {
      border-color: ${colors.cardOutline};
    }
  `,
  image: `
    height: 100px;
  `,
  imageHolder: `
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 2px solid ${colors.inputBorder};
  `,
  icon: `
    color: ${colors.success};
    position: absolute;
    top: 5px;
    left: 5px;
  `,
  iconRight: `
    left: auto;
    right: 5px;
  `,
  cardText: `
    font-size: 12px;

    p {
      margin: 0;
    }
  `,
  cardTitle: `
    font-weight: bold;
  `,
  statusContainer: `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  cardStatus: `
    float: left;
    padding: 2px 7px;
    color: ${colors.white};
    font-size: 12px;
    margin-top: 5px;
    margin-right: 5px;
  `,
  serviceOverdueText: `
    color: ${colors.error};
    margin-bottom: 0px;
  `,
};

export default {
  container: `
    position: relative;
    height: 100%;
  `,
  cardContainer: `
    margin-top: 10px;
    padding-bottom: 30px;
  `,
  loadingContainer: `
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    width: 100%;
  `,
  noEquipHeader: `
    color: ${colors.highlight};
    font-size: 15px;
    font-weight: bold;
  `,
  noEquipText: `
    width: 420px;
    margin-top: 0px; 
    font-size: 12px;
    text-align: center;
  `,
  serviceOverdueText: `
    color: ${colors.error};
    margin-bottom: 0px;
  `,
  consignmentContainer: `
    background-color: ${colors.statusStandby};
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-bottom: 3px; 
  `,
  consignmentContainerTable: `
    background-color: ${colors.statusStandby};
    margin-left: 5px;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 50%; 
    padding-bottom: 4px;
    margin-bottom: 2px;
  `,
  consignmentText: `
    color: ${colors.white};
    font-weight: 600;
    line-height: 16px;
  `,
};
