/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ChangeEvent, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker, Modal, Select, message, Input } from 'antd';
import {
  BigThreeSOs,
  cancelSub,
  findActiveSubscription,
  findPendingSubs,
  setPayedSub,
} from '../../utils';
import {
  Equipment,
  Subscription,
  SUBSCRIPTION_TYPE,
  _Equipment,
} from '../../_shared/interfaces/equipment';
import { updateEquipment } from '../../_shared/services/manage-equipment.service';
import { get } from 'lodash';
import styles from '../../_shared/styles';

interface IProps {
  closeModal: () => void;
  visible: boolean;
  equipment: Equipment;
  refreshEquipment?: () => Promise<void>;
}

interface IOpt {
  label: string;
  value: string;
}

const ChangeBillingModalComponent = (props: IProps) => {
  const { visible, closeModal, equipment, refreshEquipment } = props;
  const [billingPref, setBillingPref] = useState<string>();
  const [subStart, setSubStart] = useState<Moment | undefined>();
  const [poNumber, setPONumber] = useState<string>('');
  const [poError, setPOError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const activeSub = findActiveSubscription(equipment);
  const pendingSubs = findPendingSubs(equipment);

  const isActiveTrial =
    activeSub && activeSub.billingPreference === SUBSCRIPTION_TYPE.trial;
  const isPendingTrial =
    pendingSubs.length &&
    pendingSubs.some(sub => sub.billingPreference === SUBSCRIPTION_TYPE.trial);

  const subToCancel = (get(equipment, 'subscriptionHistory', []).find(
    (sub: Subscription) => {
      return !get(sub, 'subscriptionEnd');
    }
  ) as unknown) as Subscription;
  const canShowMonthlyOpt = BigThreeSOs.includes(
    get(equipment, 'serviceOrgId', '')
  );

  const opts = [
    { label: 'Month-to-Month', value: SUBSCRIPTION_TYPE.monthly },
    {
      label: '12 Months (Renewed Annually)',
      value: SUBSCRIPTION_TYPE.yearly,
    },
    {
      label: '96 Months (Paid in Advance)',
      value: SUBSCRIPTION_TYPE.multiyear,
    },
  ];
  const filteredOpts = opts.filter(opt => {
    if (opt.value === SUBSCRIPTION_TYPE.monthly && !canShowMonthlyOpt) {
      return false;
    }
    if (opt.value === get(subToCancel, 'billingPreference', '')) {
      return false;
    }
    return true;
  });

  const onChangeBillingPref = (value: string) => {
    setBillingPref(value);
  };

  const onChangeSubStart = (value: Moment) => {
    setError('');
    if (moment(value).valueOf() < moment().valueOf()) {
      setError('Change date can not be prior to the current date.');
      return;
    }
    setSubStart(value);
  };

  const onChangePO = (event: ChangeEvent<HTMLInputElement>) => {
    setPOError('');
    if (event.target.value.length > 50) {
      setPOError('Dental Org Purchase Order # is limited to 50 characters.');
    }
    setPONumber(event.target.value);
  };

  const submit = async () => {
    setLoading(true);
    let equip = new _Equipment(equipment) as Equipment;
    const _subscriptionEnd = (subStart as unknown) as Moment;
    const subscriptionEnd = moment(_subscriptionEnd).endOf('day');
    const subscriptionStart =
      isActiveTrial || isPendingTrial
        ? moment(subStart).startOf('day')
        : ((moment(subStart)
            .startOf('day')
            .add(1, 'day') as unknown) as Moment);
    const billingPreference = (billingPref as unknown) as string;
    const doPurchaseOrder = (poNumber as unknown) as string;
    equip = cancelSub(equip, subscriptionEnd);
    equip = setPayedSub(
      equip,
      subscriptionStart,
      billingPreference,
      doPurchaseOrder
    );
    try {
      const response = await updateEquipment(equip);
      if (response) {
        setLoading(false);
        message.success('Smart Subscription Service Updated Successfully');
        closeModal();
        refreshEquipment && refreshEquipment();
      }
    } catch (error) {
      message.error(
        'There was a problem updating you Smart Subscription Service.'
      );
    }
  };

  const renderBillingPref = (billingPref: string) => {
    if (billingPref === SUBSCRIPTION_TYPE.monthly) {
      return 'Month to Month';
    } else if (billingPref === SUBSCRIPTION_TYPE.yearly) {
      return '12 Months (Renewed Annually)';
    } else if (billingPref === SUBSCRIPTION_TYPE.multiyear) {
      return '96 Months (Paid in Advance)';
    } else {
      return '';
    }
  };
  const disabled = !billingPref || !subStart || loading || !!error;
  return (
    <Modal
      visible={visible}
      title={'Update Billing Preference'}
      centered
      closable
      confirmLoading={loading}
      okText="Save"
      onCancel={closeModal}
      onOk={submit}
      okButtonProps={{
        disabled: disabled,
      }}
    >
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">
          Current Billing Preference
        </label>
        <div className="ant-col ant-form-item-control-wrapper">
          <p>{`${renderBillingPref(
            get(subToCancel, 'billingPreference')
          )} starting on ${moment(get(subToCancel, 'subscriptionStart')).format(
            'L'
          )}`}</p>
        </div>
      </div>
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">
          Billing Preference
        </label>
        <div className="ant-col ant-form-item-control-wrapper">
          <Select
            css={css`
              width: 50%;
            `}
            onChange={onChangeBillingPref}
            value={billingPref}
            placeholder="Select Billing Preference"
          >
            {filteredOpts.map((opt: IOpt) => {
              return (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">Change Date</label>
        <div className="ant-col ant-form-item-control-wrapper">
          <DatePicker onChange={onChangeSubStart} value={subStart} />
        </div>
        {error && <p css={css(styles.error)}>{error}</p>}
      </div>
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">
          Dental Org Purchase Order #
        </label>
        <div className="ant-col ant-form-item-control-wrapper">
          <Input
            css={css`
              width: 50%;
            `}
            onChange={value => onChangePO(value)}
            value={poNumber}
            placeholder="Dental Org PO#"
          />
          {!!poError && <p css={css(styles.error)}>{poError}</p>}
        </div>
      </div>
    </Modal>
  );
};

export const ChangeBillingModal = ChangeBillingModalComponent;
