import { get } from 'lodash';
import { Group } from '../interfaces/group';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getGroup = async (id: string) => {
  const resp = await instance.get(`/group/${id}`, { data: {} });
  return get(resp, 'data.group', null) as Group;
};

export const getGroups = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/group`, {
      where,
    });
  } else {
    resp = await instance.get(`/group`, { data: {} });
  }
  return get(resp, 'data.groups', []) as Group[];
};

export const createGroup = async (group: Group) => {
  const resp = await instance.post('/group/create', {
    group: removeEmpty(group),
  });
  return get(resp, 'data.group', null) as Group;
};

export const updateGroup = (group: Group) => {
  return createGroup(group);
};

export const deleteGroup = async (groupId: string) => {
  const resp = await instance.delete(`/group/${groupId}`);
  return get(resp, 'data');
};
