/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import Content from './footer.content';
import { getContent } from '../utils';
import Logo from '../_shared/logo';
import styles, { P } from './footer.styles';

interface IProps {
  shouldExtend?: boolean;
}

export class Footer extends Component<IProps> {
  render() {
    const { shouldExtend } = this.props;
    const content = getContent(Content);
    return (
      <div
        css={css(styles.container, shouldExtend && styles.extended)}
        className={'Footer'}
      >
        <div css={css(styles.logo_1_container)}>
          <Logo
            size={shouldExtend ? 'small' : 'large'}
            width={120}
            type={'aeras_white'}
            style={{
              marginLeft: 20,
            }}
          />
        </div>
        <div css={css(styles.policyContainer)}>
          <a
            css={css(styles.policies, shouldExtend && styles.policiesSmall)}
            href={'https://www.dentalez.com/terms'}
            target="_blank"
          >
            Terms of Use
          </a>
          <p css={css(styles.divider, shouldExtend && styles.dividerSmall)}>
            |
          </p>
          <a
            css={css(styles.policies, shouldExtend && styles.policiesSmall)}
            href={'https://www.dentalez.com/privacy-policy'}
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
        <div css={css(styles.logo_2_container)}>
          {/* <P css={css(styles.copywrite, shouldExtend && styles.copywriteSmall)}>
            {content.leftText}
          </P> */}
        </div>
      </div>
    );
  }
}
