/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ChangeEvent, Component } from 'react';
import { Icon, Input } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { DashRoute } from '../../_shared/interfaces/dashroute';
import styles from './header.styles';
import { connect } from 'react-redux';
import { filterEquipment } from '../dashboard.actions';
import { AppState } from '../../app.state';
import { get } from 'lodash';
import { DashEquipFilters } from '../dashboard.reducer';

declare global {
  interface Window {
    // tslint:disable-next-line:no-any
    ESearch: any;
  }
}

interface IProps extends RouteComponentProps {
  filterEquipment: (filters?: DashEquipFilters) => void;
  sState: AppState;
  searchValue: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export class SearchComponent extends Component<IProps> {
  onSearch = (value: string) => {
    const { sState } = this.props;
    const { location } = this.props.history;
    if (location.pathname !== '/dashboard') {
      this.props.history.push('/dashboard');
    }

    this.props.filterEquipment({
      ...get(sState, 'dash.equipmentFilters', {}),
      search: value.trim(),
    });
  };
  render() {
    const isLoading = !!get(this, 'props.sState.dash.view.searchLoading');
    return (
      <Input.Search
        ref={comp => (window.ESearch = comp)}
        id={'EquipmentSearch'}
        value={this.props.searchValue}
        css={css(styles.search)}
        placeholder="Search equipment..."
        enterButton="Search"
        size="default"
        onChange={this.props.onChange}
        onSearch={this.onSearch}
        suffix={isLoading ? <Icon type="loading" /> : null}
      />
    );
  }
}

export const Search = connect(
  (sState: AppState) => ({ sState }),
  {
    filterEquipment,
  }
)(withRouter(SearchComponent));
