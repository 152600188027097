/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Icon, Input, message } from 'antd';
import styles from './styles';

const noop = () => 0;

interface IProps {
  text: string;
  successMessage?: string;
  onSave: (val: string) => Promise<void | unknown>;
  italic: boolean;
}

export default class EditableP extends Component<IProps> {
  state = {
    isInEdit: false,
    value: this.props.text,
    saving: false,
  };
  toggleEdit = () => {
    this.setState({ isInEdit: !this.state.isInEdit, value: this.props.text });
  };
  changeText = (e: { target: { value: string } }) => {
    const value = e.target.value;
    this.setState({ value });
  };
  save = async (val: string) => {
    this.setState({
      saving: true,
    });

    const { successMessage, onSave } = this.props;

    try {
      await onSave(val);
      this.setState({
        saving: false,
        isInEdit: false,
      });
      message.success(successMessage || 'Field saved successfully!');
    } catch (err) {
      message.error(`Failed to save: ${err.message}`);
      this.setState({
        saving: false,
      });
    }
  };
  render() {
    const { isInEdit, value, saving } = this.state;

    const { text, italic } = this.props;

    return isInEdit ? (
      <Input.Search
        value={value}
        size={'small'}
        disabled={saving}
        prefix={
          <Icon
            css={css(styles.closeIcon)}
            onClick={this.toggleEdit}
            type="close"
          />
        }
        onChange={this.changeText}
        enterButton={saving ? <Icon type="loading" /> : 'Save'}
        onSearch={saving ? noop : this.save}
      />
    ) : (
      <p css={css([styles.container, italic && styles.italic])}>
        {text}
        <span onClick={this.toggleEdit} css={css(styles.edit)}>
          <Icon type={'edit'} />
        </span>
      </p>
    );
  }
}
