/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, MouseEvent } from 'react';
import { Alert, message, Upload, Icon, Button, Table } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import { get, set, orderBy, keyBy } from 'lodash';
import FormTitle from '../../_shared/form-title';
import {
  getSerials,
  createSerial,
} from '../../_shared/services/manage-serials.service';
import XLSX from 'xlsx';
import styles from './process-data.styles';
import { RcFile } from 'antd/lib/upload';
import {
  getEquipments,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import { createOrg, getOrgs } from '../../_shared/services/manage-orgs.service';
import { Org, _Org } from '../../_shared/interfaces/org';
import { _Location, Location } from '../../_shared/interfaces/location';
import { Equipment } from '../../_shared/interfaces/equipment';
import { createLocation } from '../../_shared/services/manage-locations.service';

//THIS IS NOT A PUBLICLY USED COMPONENT. MEANT AS A QUICK ACCESS WAY TO PROCESS XLSX FILES.
//MODIFY HOW YOU WANT

const COLS = [
  {
    title: 'Name',
    dataIndex: 'Name',
    editable: false,
  },
  {
    title: 'Customer #',
    dataIndex: 'Customer #',
    editable: false,
  },
  {
    title: 'Error',
    dataIndex: 'error',
    editable: false,
  },
];

interface ExcelData {
  [key: string]: string;
  key: string;
  'Combined Name': string;
  Addr1: string;
  Addr2: string;
  City: string;
  State: string;
  Zip: string;
  Phone: string;
  'Serial Num': string;
}

class _ExcelData<ExcelData> {
  key: string;
  constructor(props: ExcelData) {
    Object.keys(props).map((k: string) => {
      const val = get(props, k, '');
      set(this, k, typeof val === 'string' ? val.trim() : val);
    });
    this.key = get(this, 'Combined Name');
  }
}

interface IProps extends RouteComponentProps {
  sState: AppState;
}

class _ProcessDataComponent extends Component<IProps> {
  state = {
    loading: true,
    success: null,
    error: null,
    data: [],
    serials: [],
    newSerials: [],
    equipment: {},
    orgs: {},
    eqToUpdate: [],
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    this.retrieveSerials();
  };
  retrieveSerials = async (shouldFetch = true) => {
    const state = { ...this.state };

    state.loading = true;

    this.setState(state);

    try {
      const [equipment, orgs] = await Promise.all([getEquipments(), getOrgs()]);

      state.equipment = keyBy(equipment, 'equipmentSN');
      state.orgs = keyBy(orgs, 'name');

      state.loading = false;

      this.setState(state);
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false, error: err.message });
    }
  };
  processData = (data: ExcelData[]) => {
    const SERVICEORGID = '69e40ee2-53e8-4759-b7ad-e37bcc9264fe';
    const { equipment, orgs } = this.state;
    const eqToUpdate: Equipment[] = [];

    const newSerials = data.map(d => {
      let error;
      const Data = new _ExcelData(d);
      const hasSerial = get(Data, 'Serial Num');
      const eq = get(equipment, hasSerial) as Equipment;
      let org = get(orgs, get(Data, 'Combined Name'));
      const eqHasService = get(eq, 'serviceOrgId');
      let loc;

      if (hasSerial && !eq) {
        error = `${hasSerial} does not exist`;
      } else if (hasSerial && eqHasService && eqHasService !== SERVICEORGID) {
        error = `${hasSerial} is already associated with a different Dental Organization than the one provided.`;
      } else if (hasSerial && eq) {
        // eqToUpdate.push({
        //   ...eq,
        //   onConsignment: false,
        //   serviceOrgId: SERVICEORGID,
        // })
      }

      if (org) {
        error = 'Org already exists';
        eqToUpdate.push({
          ...eq,
          onConsignment: false,
          serviceOrgId: SERVICEORGID,
          dentalOrgId: get(org, 'id'),
        });
      } else {
        const address = {
          address: get(Data, 'Addr1', ''),
          address2: get(Data, 'Addr2', ''),
          city: get(Data, 'City', ''),
          state: get(Data, 'State', ''),
          zip: get(Data, 'Zip', ''),
        };

        org = new _Org({
          orgType: 1,
          name: get(Data, 'Combined Name'),
          serviceOrgId: SERVICEORGID,
          address,
          phone: get(Data, 'Phone', ''),
        } as Org) as never;

        loc = new _Location(({
          orgId: get(org, 'id'),
          name: get(Data, 'City', ''),
          address,
        } as unknown) as Location);
      }

      return {
        ...Data,
        eq,
        org,
        loc,
        error,
      };
    });

    // console.log(newSerials);

    this.setState({
      newSerials,
      eqToUpdate,
      loading: false,
    });
  };
  downloadTemplate = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const win = window.open(
      `https://particle-misc.s3.amazonaws.com/Serials_Template.xlsx`,
      '_blank'
    );
    win && win.focus();
  };
  onSaveTable = async (newData: []) => {
    this.processData(newData);
  };
  clearSerials = () => {
    this.setState({ data: [], newSerials: [] });
  };
  submitSerials = async () => {
    if (this.state.loading) return;

    // this.setState({ loading: true });

    // const { newSerials, eqToUpdate } = this.state;
    // const failedSerials: any[] = [];

    // Promise.all(
    //   newSerials.map(async (s: any) => {
    //     if (s.error) {
    //       failedSerials.push(s);
    //     } else {
    //       try {
    //         const org = get(s, 'org');
    //         const loc = get(s, 'loc');
    //         if (org) {
    //           await createOrg(org);
    //         }
    //         if (loc) {
    //           await createLocation(loc);
    //         }
    //       } catch (err) {
    //         failedSerials.push({ ...s, error: get(err, 'message') });
    //       }
    //     }
    //   })
    // );

    // Promise.all(eqToUpdate.map(async e => await updateEquipment(e).catch(err => ({ error: err.message }))));

    // if (failedSerials.length > 0) {
    //   console.log(JSON.stringify(failedSerials));
    //   message.error(
    //     // tslint:disable-next-line:max-line-length
    //     'These items failed to create. Please try again.'
    //   );
    // } else {
    //   message.success('Data created successfully');
    // }

    // this.setState({
    //   newSerials: failedSerials,
    //   eqToUpdate: [],
    //   loading: false,
    // });
  };
  render() {
    const { loading, error, success, newSerials } = this.state;

    const draggerProps = {
      name: 'file',
      showUploadList: false,
      multiple: false,
      beforeUpload: (file: RcFile) => {
        this.setState({ loading: true });
        const fileType = file.name.substring(file.name.indexOf('.'));
        if (
          fileType !== '.xlsx' &&
          fileType !== '.xls' &&
          fileType !== '.csv'
        ) {
          this.setState({ error: 'File type not supported' });
          return false;
        }
        const fileReader = new FileReader();
        fileReader.onload = e => {
          try {
            let binary = '';
            const bytes = new Uint8Array(get(e, 'target.result'));
            const length = bytes.byteLength;
            for (let i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            const oFile = XLSX.read(binary, {
              type: 'binary',
              cellDates: true,
              cellStyles: true,
            });
            const sheet1 = oFile.Sheets[oFile.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(sheet1);
            this.processData((data as unknown) as ExcelData[]);
          } catch (err) {
            message.error(`Unable to process spreadsheet: ${err.message}`);
          }
        };
        fileReader.readAsArrayBuffer(file);
        return true;
      },
    };

    const tableProps = {
      dataSource: newSerials,
      columns: COLS,
      size: 'small',
      // onSave: this.onSaveTable,
      loading,
      rowKey: 'SERIAL_NUMBER',
      pagination: false,
      rowClassName: (item: ExcelData, index: number) =>
        `tbl-list-row-${index % 2}`,
      // tslint:disable-next-line:no-any
    } as any;

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <FormTitle size={'18px'}>{'Process Data'}</FormTitle>

        <Upload.Dragger css={css(styles.uploadContainer)} {...draggerProps}>
          <Button css={css(styles.dlTemplate)} onClick={this.downloadTemplate}>
            <Icon type="download" />
            <span>Download Template</span>
          </Button>

          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to add Serial Numbers
          </p>
        </Upload.Dragger>

        {newSerials.length > 0 && (
          <div>
            <Table {...tableProps} />
            <div
              css={css(
                SharedStyles.row,
                `justify-content: center; padding: 10px;`
              )}
            >
              <Button
                css={css`
                  width: 100px;
                  margin: 5px;
                `}
                type={'danger'}
                onClick={this.clearSerials}
              >
                Clear
              </Button>
              <Button
                css={css`
                  width: 100px;
                  margin: 5px;
                `}
                type={'primary'}
                onClick={this.submitSerials}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ProcessDataComponent = withRouter(_ProcessDataComponent);
