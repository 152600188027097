import colors from '../../_shared/colors';

export default {
  filtersContainer: `
    border-bottom: 2px solid ${colors.inputBorder};
    height: auto;
  `,
  container: `
    height: auto;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  `,
  pbContainer: `
    height: 60px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: -20px;
    padding-left: 20px;
    padding-right: 20px;
  `,
  pbSearch: `
    margin-bottom: 20px;

    .ant-input-search {
      width: 100% !important;
    }
  `,
  margRight: `
    margin-right: 5px;
  `,
  select: `
    width: 150px;
  `,
  link: `
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  `,
  viewContainer: `
    margin-left: auto;
  `,
  viewIcon: `
    margin-right: 10px;
    cursor: pointer;
    &: hover {
      opacity: 0.8;
    }
  `,
  pbFilters: `
    width: 100%;

    .ant-select, > .ant-btn {
      width: 100% !important;
      margin-bottom: 10px;
    }
  `,
};
