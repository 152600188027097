import colors from '../_shared/colors';
import styled from '@emotion/styled';

export const P = styled.p`
  font-size: 12px;
  font-weight: 600;
`;

export default {
  container: `
    background-color: ${colors.footerBg};
    width: 100%;
    min-height: 75px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
  `,
  extended: `
    position: fixed;
    margin-left: -20px;
    bottom: 0;
  `,
  divider: `
    margin: 0px 5px 0px 5px;
    color: ${colors.white}
  `,
  dividerSmall: `
    margin: 0px 5px 0px 5px;
    color: ${colors.white};
    font-size: 10px;
  `,
  policyContainer: `
    display: flex;
    align-items: center;
  `,
  policies: `
    color: ${colors.white};
    text-decoration: none;
  `,
  policiesSmall: `
    margin-left: 20px;
    color: ${colors.white};
    font-size: 10px;
  `,
  logo_1_container: `
    align-items: space-around;
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  logo_2_container: `
    justify-content: flex-end;
    display: flex;
    flex: 1;
    align-items: flex-end;
  `,
  copywrite: `
    margin-right: 10px;
  `,
  copywriteSmall: `
    margin-top -12px;
    font-size: 10px;
    margin-left: 20px;
    line-height: 10px;
  `,
};
