/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import SharedStyles from '../../_shared/styles';
import {
  getUsersName,
  userHasRole,
  sortByFirstThenLastName,
  getEmailFromText,
  getRoleName,
  sortByName,
  isServiceOrgAdmin,
  sortByLabel,
} from '../../utils';
import { User } from '../../_shared/interfaces/user';
import { Icon, Alert, Switch, Tag, Modal } from 'antd';
import colors from '../../_shared/colors';
import { AppState } from '../../app.state';
import { get, chain, uniq, sortBy, set } from 'lodash';
import styles from './manage-group.styles';
import { getLocations } from '../../_shared/services/manage-locations.service';
import { TableListMultiGroup } from '../../_shared/table-list-multi';
import {
  updateGroup,
  deleteGroup,
} from '../../_shared/services/manage-groups.service';
import {
  getEquipments,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import {
  updateUser,
  getUsers,
} from '../../_shared/services/manage-users.service';
import { Group } from '../../_shared/interfaces/group';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { Org } from '../../_shared/interfaces/org';
import { getOrgs, deleteOrg } from '../../_shared/services/manage-orgs.service';
import { getModels } from '../../_shared/services/manage-models.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps {
  sState: AppState;
}

const GroupColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '33%',
  },
  {
    title: 'Role',
    dataIndex: 'roleName',
    width: '33%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '33%',
  },
];

const EquipColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '33%',
  },
  {
    title: 'Model ID',
    dataIndex: 'modelId',
    width: '33%',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    width: '33%',
  },
];

interface UECounts {
  [key: string]: { _ucounts: number; _ecounts: number };
}

export class _ManageGroupComponent extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    isEdit: false,
    error: undefined,
    success: undefined,
    group: undefined,
    groupOrg: undefined,
    users: [],
    allUsers: [],
    userMap: {},
    equipment: [],
    allEquipment: [],
    equipMap: {},
  };
  toggleActive = async () => {
    this.setState({ loading: true });

    const isActive = get(this.state.group, 'isActive', 0);

    const group = {
      ...(this.state.group || {}),
      isActive: isActive === 0 ? 1 : 0,
    } as Group;

    try {
      if (group.id) {
        await updateGroup(group);
        this.setState({
          loading: false,
          group,
        });
      }
    } catch (err) {
      this.setState({
        loading: false,
        error: err.message,
      });
    }
  };
  componentDidMount = async () => {
    this.retrieveData();
  };
  retrieveData = async (res?: { error?: string }) => {
    try {
      if (res && res.error) {
        return this.setState({ error: res.error });
      }

      const {
        sState,
        history: { location },
      } = this.props;

      const group = get(location, 'state.group') as Group;
      const orgs = get(sState, 'dash.orgs', []) as Org[];
      const groupOrg = orgs.find(o => o.id === group.orgId);

      this.setState(
        {
          show: false,
          loading: true,
          group,
          groupOrg,
        },
        () => this.setState({ show: true })
      );

      const [
        allTheEquipment,
        allOrgs,
        allModels,
        allLocations,
        allTheUsers,
      ] = await Promise.all([
        getEquipments(),
        getOrgs(),
        getModels(),
        getLocations(),
        sortBy(
          await getUsers(),
          [user => user.lastName.toLowerCase()],
          [user => user.firstName.toLowerCase()],
          ['lastName', 'firstName', 'userId']
        ),
      ]);

      const orgMap = chain(allOrgs)
        .keyBy('id')
        .value();
      const modelMap = chain(allModels)
        .keyBy('id')
        .value();
      const locMap = chain(allLocations)
        .keyBy('id')
        .value();

      const equipMap = chain(allTheEquipment)
        .keyBy('id')
        .value();

      const equipment = allTheEquipment
        .filter(
          e => ((get(e, 'groups', []) || []) as string[]).indexOf(group.id) > -1
        )
        .map(e => {
          const location = get(locMap, `${e.locationId}.name`, '');
          const model = get(modelMap, `${e.modelId}.name`, '');
          const dentalOrg = get(orgMap, `${e.dentalOrgId}.name`, '');
          const serviceOrg = get(orgMap, `${e.serviceOrgId}.name`, '');
          return {
            ...e,
            location,
            model,
            dentalOrg,
            serviceOrg,
          } as Equipment;
        })
        .sort(sortByName);

      const eMap = chain(equipment)
        .keyBy('deviceId')
        .value();

      const allEquipment = allTheEquipment
        .filter(
          e =>
            !get(eMap, e.deviceId || '') &&
            (e.dentalOrgId === group.orgId || e.serviceOrgId === group.orgId)
        )
        .map(e => {
          const name = get(e, 'name', '');
          const id = get(e, 'id', '');
          const serial = get(e, 'equipmentSN');
          return {
            label: `${name} (${serial || 'none'})`,
            text: `${name} (${serial || 'none'})`,
            value: id || '_none_',
          };
        })
        .sort(sortByLabel);

      const users = allTheUsers
        .filter(u => (u.groups || []).indexOf(group.id) > -1)
        .map(u => {
          return {
            ...u,
            name: getUsersName(u),
            roleName: getRoleName(u.role, sState),
            organization: get(orgMap, `${u.orgId}.name`, ''),
          } as User;
        })
        .sort(sortByFirstThenLastName);

      const uMap = chain(users)
        .keyBy('userId')
        .value();
      const userMap = chain(allTheUsers)
        .keyBy('userId')
        .value();
      const allUsers = allTheUsers
        .filter(u => !uMap[u.userId] && u.orgId === group.orgId && u.role !== 2)
        .map(u => `${getUsersName(u)}: ${u.email}`);

      this.setState({
        users,
        allUsers,
        userMap,
        equipment,
        allEquipment,
        equipMap,
        show: true,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  toggleEdit = () => {
    this.props.history.push('/dashboard/editGroup', {
      group: this.state.group,
      editMode: true,
      back: `< Back to ${get(this.state.group, 'name')}`,
    });
  };
  renderGroupInfo = () => {
    const { sState } = this.props;
    const { groupOrg } = this.state;
    const isSuperOrTech = userHasRole([0, 1], sState);
    const isSOAdmin = isServiceOrgAdmin(sState);
    return !isSuperOrTech && !isSOAdmin ? null : (
      <span css={css(`font-size: 12px;`)}>{get(groupOrg, 'name', '')}</span>
    );
  };
  userResult = async (res: { tags: string[] }) => {
    return new Promise((resolve, reject) => {
      const { userMap, group } = this.state;
      const uMap = userMap as { [key: string]: User };
      const emails = res.tags.map(t =>
        getEmailFromText(t as string)
      ) as string[];
      const users = emails.map(e => uMap[e]).filter(u => u);

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= users.length) {
          resolve(undefined);
        }
      };

      const groupId = get(group, 'id', '');

      if (users.length === 0) {
        checkDone();
      } else {
        users.map(async u => {
          u.groups = uniq([...(u.groups || []), groupId]);

          try {
            await updateUser(u);
            checkDone();
          } catch (err) {
            checkDone();
          }
        });
      }
    });
  };
  userRemove = async (rows: User[]) => {
    return new Promise((resolve, reject) => {
      const { group } = this.state;

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= rows.length) {
          resolve(undefined);
        }
      };

      const groupId = get(group, 'id', '');

      rows.map(async u => {
        const gIndex = u.groups.indexOf(groupId);
        u.groups = u.groups || [];
        u.groups.splice(gIndex, 1);

        try {
          await updateUser(u);
          checkDone();
        } catch (err) {
          checkDone();
        }
      });
    });
  };
  equipResult = (res: { tags: string[] }) => {
    return new Promise((resolve, reject) => {
      const { equipMap, group } = this.state;
      const eMap = equipMap as { [key: string]: Equipment };
      const equipIds = res.tags;
      const equipment = equipIds.map(e => eMap[e]).filter(u => u);

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= equipment.length) {
          resolve(undefined);
        }
      };

      const groupId = get(group, 'id', '');

      if (equipment.length === 0) {
        checkDone();
      } else {
        equipment.map(async e => {
          const me = get(this.props, 'sState.auth.user');
          e.groups = uniq([...(e.groups || []), groupId]);
          set(e, 'updatedAt', moment().toISOString());
          set(e, 'updatedBy', me.userId);
          try {
            await updateEquipment(e);
            checkDone();
          } catch (err) {
            checkDone();
          }
        });
      }
    });
  };
  equipRemove = (rows: Equipment[]) => {
    return new Promise((resolve, reject) => {
      const { group } = this.state;
      const me = get(this.props, 'sState.auth.user');
      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= rows.length) {
          resolve(undefined);
        }
      };

      const groupId = get(group, 'id', '');

      rows.map(async _e => {
        const e = new _Equipment(_e);
        const gIndex = (e.groups as string[]).indexOf(groupId);
        e.groups = e.groups || [];
        e.groups.splice(gIndex, 1);
        set(e, 'updatedAt', moment().toISOString());
        set(e, 'updatedBy', me.userId);

        try {
          await updateEquipment(e);
          checkDone();
        } catch (err) {
          checkDone();
        }
      });
    });
  };

  _deleteGroup = async () => {
    const groupId = get(this.state, 'group.id');
    const equipment = get(this.state, 'equipment', []);
    const users = get(this.state, 'users');
    const delGroup = async () => {
      try {
        const resp = await deleteGroup(groupId);
        if (resp) {
          this.userRemove(users);
          this.equipRemove(equipment);
        }
        trackEvent(eventTypes.group_delete, {
          group: this.state.group,
          org: this.state.groupOrg,
        });
        this.props.history.goBack();
      } catch (error) {
        console.warn(error);
        this.setState({ error: 'There was a problem deleting the group' });
      }
    };

    const showConfirmModal = () => {
      Modal.confirm({
        title: 'Confirmation: Delete Group',
        content: 'Are you sure you want to delete this group?',
        onOk: delGroup,
        okText: 'Delete',
      });
    };
    showConfirmModal();
  };

  render() {
    const { sState } = this.props;
    const {
      group,
      loading,
      users,
      allUsers,
      equipment,
      allEquipment,
      show,
      error,
      success,
    } = this.state;

    const active = get(group, 'isActive', 0) > 0 ? true : false;

    const canShowEdit = userHasRole([0, 1, 2, 3], sState);

    return (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link onClick={() => this.props.history.goBack()}>
            {'< Back to Manage Groups'}
          </Link>

          <div
            css={css`
              margin-left: auto;
            `}
          >
            {canShowEdit && (
              <div css={css(SharedStyles.row, `margin-bottom: 5px;`)}>
                <span
                  css={css`
                    font-size: 16px;
                    margin-right: 5px;
                  `}
                >
                  {active ? 'Active' : 'Not Active'}
                  {loading && (
                    <Icon css={css('margin-left: 4px;')} type="loading" />
                  )}
                </span>
                <Switch
                  onChange={loading ? () => 0 : this.toggleActive}
                  checked={active}
                />
              </div>
            )}
            {canShowEdit && (
              <div css={css('justify-content: flex-end;')}>
                <Button
                  css={css(`
                      background-color: ${colors.error};
                      border: solid 1px ${colors.error};
                      margin-right: 10px;
                      `)}
                  title="Delete"
                  onClick={this._deleteGroup}
                />
                <Button
                  css={css('float: right;')}
                  title="Edit"
                  onClick={this.toggleEdit}
                />
              </div>
            )}
          </div>
        </div>

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <TableTitleHeader>{get(group, 'name', 'N/A')}</TableTitleHeader>
          <Tag
            css={css`
              margin-left: 10px;
            `}
            color={active ? colors.success : colors.error}
          >
            {active ? 'Active' : 'Not Active'}
          </Tag>
          <Button
            size="small"
            css={css('margin-left: 10px;')}
            loading={loading}
            title="Refresh"
            onClick={() => this.retrieveData()}
          />
        </div>

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          {this.renderGroupInfo()}
        </div>

        {show && (
          <div>
            <TableListMultiGroup
              data={users}
              loading={loading}
              columns={GroupColumns}
              variable={'group'}
              title={'Users'}
              removeLabel={'Remove Users'}
              modalPlaceholder={'Add User'}
              modalTitle="Add Users to Group"
              confirmMessage={
                'Are you sure you want to remove these users from the Group?'
              }
              addBtn={'Add Users'}
              allOpts={allUsers}
              onResult={this.userResult}
              onRemove={this.userRemove}
              totalTitle={(count: number) => `Total Users: ${count}`}
              blankText={'Group is not associated with any Users'}
              onDone={this.retrieveData}
            />

            <TableListMultiGroup
              data={equipment}
              loading={loading}
              columns={EquipColumns}
              title={'Equipment'}
              removeLabel={'Remove Equipment'}
              modalTitle="Add Equipment to Group"
              confirmMessage={
                'Are you sure you want to remove this equipment from the Group?'
              }
              variable={'equipment'}
              addBtn={'Add Equipment'}
              allOpts={allEquipment}
              onResult={this.equipResult}
              onRemove={this.equipRemove}
              totalTitle={(count: number) => `Total Equipment: ${count}`}
              blankText={'Group is not associated with any Equipment'}
              onDone={this.retrieveData}
            />
          </div>
        )}
      </div>
    );
  }
}

export const ManageGroupComponent = withRouter(_ManageGroupComponent);
