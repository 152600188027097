export default {
  container: `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  footerContainer: `
    padding: 20px;
    display: flex;
    height: 75px;
  `,
};
