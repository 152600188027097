import { guid } from '../../utils';
import { Address } from './org';
import {
  IHandpieceLocationOverridePreset,
  IHandpieceLocationOverrideProfile,
} from './handpieceProfiles';

export interface Location {
  [key: string]:
    | string
    | number
    | undefined
    | Address
    | IHandpieceLocationOverrideProfile[]
    | IHandpieceLocationOverridePreset[];
  id: string;
  sk: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  isActive?: number;
  orgId?: string;
  name: string;
  code?: string;
  plan?: string;
  address?: Address | string;
  activationDate?: string;
  handpieceDefaultsVersion?: number;
  handpieceDefaultsProfileOverrides?: IHandpieceLocationOverrideProfile[];
  handpieceDefaultsOverrides?: IHandpieceLocationOverridePreset[];
}

export class _Location {
  constructor(props?: Location) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  [key: string]:
    | string
    | number
    | undefined
    | Address
    | IHandpieceLocationOverrideProfile[]
    | IHandpieceLocationOverridePreset[];
  id = guid();
  sk = guid();
  createdAt = new Date().toISOString();
  createdBy = '';
  updatedAt = new Date().toISOString();
  updatedBy = '';
  orgId = '';
  isActive = 1;
  name = '';
  code = '';
  plan = '';
  activationDate = '';
  address = {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  };
}
