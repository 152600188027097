/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router';
import { TableTitleHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import SharedStyles from '../../_shared/styles';
import {
  getUsersName,
  userHasRole,
  sortByFirstThenLastName,
  getEmailFromText,
  sortByName,
  isServiceOrgAdmin,
} from '../../utils';
import { User } from '../../_shared/interfaces/user';
import { Alert } from 'antd';
import { AppState } from '../../app.state';
import { get, chain, uniq, set, sortBy } from 'lodash';
import styles from './manage-equipment-select.styles';
import { getLocations } from '../../_shared/services/manage-locations.service';
import { TableListMultiGroup } from '../../_shared/table-list-multi';
import { getGroups } from '../../_shared/services/manage-groups.service';
import {
  getEquipment,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import {
  updateUser,
  getUsers,
  getAllUsers,
} from '../../_shared/services/manage-users.service';
import { Group } from '../../_shared/interfaces/group';
import { Equipment, _Equipment } from '../../_shared/interfaces/equipment';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { Model } from '../../_shared/interfaces/model';
import { Role } from '../../_shared/interfaces/role';
import { Button } from '../../_shared/button';

interface IProps extends RouteComponentProps {
  sState: AppState;
}

const GroupColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '50%',
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    width: '50%',
  },
];

const UserColumns = [
  {
    title: 'Full Name',
    dataIndex: 'name',
    width: '50%',
  },
  {
    title: 'Role',
    dataIndex: 'roleName',
    width: '50%',
  },
];
export class _ManageEquipmentSelect extends Component<IProps> {
  state = {
    show: true,
    loading: true,
    error: undefined,
    success: undefined,
    users: [],
    availUsers: [],
    availGroups: [],
    allUsers: [],
    userMap: {},
    locMap: {},
    orgMap: {},
    equipment: undefined,
    groups: [],
    allGroups: [],
  };
  componentDidMount = async () => {
    this.retrieveData();
  };
  retrieveData = async (res?: { error?: string }) => {
    try {
      if (res && res.error) {
        return this.setState({ error: res.error });
      }

      const {
        sState,
        history: { location },
      } = this.props;

      const e =
        this.state.equipment || (get(location, 'state.equipment') as Equipment);
      const equipment = await getEquipment(e.deviceId);

      this.setState(
        {
          show: false,
          loading: true,
          equipment,
        },
        () => this.setState({ show: true })
      );

      let allUsers = await getAllUsers();
      allUsers = sortBy(allUsers, ['name', 'userId']);
      const allOrgs = await getOrgs();
      const allLocations = await getLocations();
      let allGroups = await getGroups();
      allGroups = sortBy(allGroups, ['name', 'id']);

      const orgMap = chain(allOrgs)
        .keyBy('id')
        .value();
      const locMap = chain(allLocations)
        .keyBy('id')
        .value();
      const userMap = chain(allUsers)
        .keyBy('email')
        .value();

      const isSuper = userHasRole([0, 1, 7], sState);
      const isSOAdmin = isServiceOrgAdmin(sState);
      const orgStrings = [
        (isSuper || isSOAdmin) && equipment.serviceOrgId,
        equipment.dentalOrgId,
      ];

      const availGroups: Group[] = [];
      const groups = allGroups
        .filter(g => {
          const include = (equipment.groups || []).indexOf(g.id) > -1;
          if (!include) {
            const add = () => {
              availGroups.push(g);
            };

            const isInStrings = orgStrings.indexOf(g.orgId || '') > -1;

            if (isInStrings) {
              add();
            }
          }
          return include;
        })
        .map(g => {
          return {
            ...g,
            organization: get(orgMap, `${g.orgId}.name`, ''),
          };
        })
        .sort(sortByName);

      const availUsers: User[] = [];
      const users = allUsers
        .filter(u => {
          const include =
            u.equipment && u.equipment.indexOf(equipment.id as string) > -1;
          if (!include) {
            const isInStrings = orgStrings.indexOf(u.orgId) > -1;
            const isNotDZOrOrgAdmin =
              u.role === 3 || u.role === 4 || u.role === 5;
            if (isInStrings && isNotDZOrOrgAdmin) {
              availUsers.push(u);
            }
          }
          return include;
        })
        .map(u => {
          return {
            ...u,
            roleName: get(
              get(sState, 'auth.allRoles', []).find(
                (r: Role) => r.id === u.role
              ),
              'name',
              'N/A'
            ),
            name: getUsersName(u),
          };
        })
        .sort(sortByFirstThenLastName);

      this.setState({
        users,
        allUsers,
        allGroups,
        userMap,
        availGroups: availGroups.map(g => g.name),
        availUsers: availUsers.map(u => `${getUsersName(u)}: ${u.email}`),
        orgMap,
        locMap,
        groups,
        equipment,
        show: true,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  renderEquipInfo = () => {
    const { orgMap, equipment, locMap } = this.state;

    const items = [
      {
        title: 'Service Org Name',
        var: 'serviceOrgId',
        toText: (id: string) => get(orgMap, `${id}.name`),
      },
      {
        title: 'Dental Org Name',
        var: 'dentalOrgId',
        toText: (id: string) => get(orgMap, `${id}.name`),
      },
      {
        title: 'Location',
        var: 'locationId',
        toText: (id: string) => get(locMap, `${id}.name`),
      },
    ];

    return (
      <span css={css(`font-size: 12px;`)}>
        {items
          .filter(item => {
            const hasVal = get(equipment, item.var, '');
            const val = item.toText(hasVal);
            return val;
          })
          .map((item, i) => {
            const hasVal = (get(equipment, item.var, '') as unknown) as string;
            const val = item.toText(hasVal);
            return (
              <span key={i}>
                {i > 0 ? (
                  <span>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</span>
                ) : null}
                {`${item.title}: ${val}`}
              </span>
            );
          })}
      </span>
    );
  };
  getEquipment = () => {
    return (get(this.state, 'equipment') as unknown) as Equipment;
  };
  userResult = async (res: { tags: string[] }) => {
    return new Promise((resolve, _reject) => {
      const { userMap } = this.state;
      const e = this.getEquipment();

      const uMap = userMap as { [key: string]: User };
      const emails = res.tags.map(t =>
        getEmailFromText(t as string)
      ) as string[];
      const users = emails.map(e => uMap[e]).filter(u => u);

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= users.length) {
          resolve(undefined);
        }
      };

      if (users.length === 0) {
        checkDone();
      } else {
        users.map(async u => {
          if (e) {
            u.equipment = uniq([...(u.equipment || []), e.id as string]);

            try {
              await updateUser(u);
              checkDone();
            } catch (err) {
              checkDone();
            }
          } else {
            checkDone();
          }
        });
      }
    });
  };
  userRemove = async (rows: User[]) => {
    return new Promise((resolve, _reject) => {
      const e = this.getEquipment();

      let complete = 0;
      const checkDone = () => {
        complete += 1;
        if (complete >= rows.length) {
          resolve(undefined);
        }
      };

      const eId = get(e, 'id', '');

      rows.map(async u => {
        const eIndex = u.equipment.indexOf(eId);
        u.equipment = u.equipment || [];
        u.equipment.splice(eIndex, 1);

        try {
          await updateUser(u);
          checkDone();
        } catch (err) {
          checkDone();
        }
      });
    });
  };
  groupResult = (res: { tags: string[] }) => {
    return new Promise(async (resolve, reject) => {
      const me = get(this.props, 'sState.auth.user');
      try {
        const { allGroups } = this.state;
        const groups = res.tags
          .map(t => {
            const group = (allGroups.find(
              (g: Group) => g.name && g.name === t
            ) as unknown) as Group;
            return group && group.id;
          })
          .filter(g => g);

        const equipment = this.getEquipment();
        if (equipment) {
          set(equipment, 'updatedAt', moment().toISOString());
          set(equipment, 'updatedBy', me.userId);
          set(
            equipment,
            'groups',
            uniq([...(equipment.groups || []), ...groups])
          );
          await updateEquipment(equipment);
          this.setState({ equipment });
        }
        resolve(undefined);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };
  groupRemove = (rows: Group[]) => {
    return new Promise(async (resolve, reject) => {
      try {
        const me = get(this.props, 'sState.auth.user');
        const e = this.getEquipment();
        const groupsToRemove = rows.map(r => r.id);
        const groups = [...(e.groups || [])].filter(
          g => groupsToRemove.indexOf(g) === -1
        );

        if (e) {
          const equipment = {
            ...e,
            groups,
            updatedAt: moment().toISOString(),
            updatedBy: me.userId,
          };
          await updateEquipment(equipment);
          this.setState({ equipment });
        }
        resolve(undefined);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };
  equipName = () => {
    const { sState } = this.props;

    const e = get(this.state, 'equipment', { modelId: '' });

    const model = get(sState, 'dash.models', []).find(
      (m: Model) => m.id === e.modelId
    );
    const modelName = get(model, 'name');

    return `${modelName}: ${get(e, 'name', 'No name')}`;
  };
  toggleEdit = () => {
    this.props.history.push('/dashboard/editEquipment', {
      equipment: this.state.equipment,
      editMode: true,
      back: `< Back to ${get(this.state.equipment, 'name')}`,
    });
  };
  goToEquipmentDetails = () => {
    this.props.history.push('/dashboard/equipmentDetails', {
      equipment: this.state.equipment,
      back: `< Back to ${get(this.state.equipment, 'name')}`,
    });
  };
  render() {
    const { sState } = this.props;
    const {
      loading,
      users,
      show,
      error,
      success,
      groups,
      availGroups,
      availUsers,
    } = this.state;

    const canShowEdit = userHasRole([0, 1, 2, 3], sState);

    return (
      <div>
        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link onClick={() => this.props.history.goBack()}>
            {'< Back to Manage Equipment'}
          </Link>

          <div
            css={css`
              margin-left: auto;
            `}
          >
            <Button
              css={css('margin-right: 10px;')}
              loading={loading}
              title="Refresh"
              onClick={() => this.retrieveData()}
            />
            {canShowEdit && (
              <Button
                css={css('float: right;')}
                title="Edit"
                onClick={this.toggleEdit}
              />
            )}
          </div>
        </div>

        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          <Link onClick={this.goToEquipmentDetails}>
            <TableTitleHeader>{this.equipName()}</TableTitleHeader>
          </Link>
        </div>

        <div css={css(SharedStyles.row, styles.rowMargin)}>
          {this.renderEquipInfo()}
        </div>

        {show && (
          <div>
            <TableListMultiGroup
              data={groups}
              loading={loading}
              columns={GroupColumns}
              title={'Groups'}
              removeLabel={'Remove Group'}
              confirmMessage={
                'Are you sure you want to remove these Groups from the Equipment?'
              }
              variable={'group'}
              addBtn={'Add Groups'}
              allOpts={availGroups}
              onResult={this.groupResult}
              onRemove={this.groupRemove}
              totalTitle={(count: number) => `Total Groups: ${count}`}
              blankText={'Equipment is not associated with any Groups'}
              onDone={this.retrieveData}
              modalTitle={'Add Group to Equipment'}
            />

            <TableListMultiGroup
              data={users}
              loading={loading}
              columns={UserColumns}
              variable={'user'}
              title={'Users'}
              removeLabel={'Remove Users'}
              confirmMessage={
                'Are you sure you want to remove these users from the Equipment?'
              }
              addBtn={'Add User'}
              allOpts={availUsers}
              onResult={this.userResult}
              onRemove={this.userRemove}
              totalTitle={(count: number) => `Total Users: ${count}`}
              blankText={'Equipment is not associated with any Users'}
              onDone={this.retrieveData}
              modalTitle={'Add User to Equipment'}
            />
          </div>
        )}
      </div>
    );
  }
}

export const ManageEquipmentSelect = withRouter(_ManageEquipmentSelect);
