/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { ChangeEvent, Component } from 'react';
import { Modal, Input } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import colors from '../colors';
import { validPhoneNumber } from '../../utils';

interface IProps extends ModalProps {
  placeholder?: string;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  content?: string;
  value: string | undefined;
  visible: boolean;
  label?: string;
  type?: string;
  pattern?: string;
  resetForm?: () => void;
}

export class ModalInput extends Component<IProps> {
  state = { loading: false, error: undefined };
  onOk = async () => {
    const { value } = this.props;
    if (!value) {
      return this.setState({ error: 'Phone is required.' });
    }
    const isValid = value && validPhoneNumber(value);
    if (!isValid) {
      return this.setState({ error: 'Please enter a valid phone number' });
    }
    if (value.length > 16) {
      this.setState({ error: 'Phone is limited to 16 characters.' });
    }
    const { onSubmit } = this.props;
    this.setState({ loading: true });
    await onSubmit();
    this.setState({ loading: false, error: '' });
  };
  onCancel = () => {
    const { onCancel } = this.props;
    this.setState({ error: '' });
    onCancel && onCancel();
  };
  render() {
    const { loading } = this.state;
    const {
      title,
      visible,
      content,
      label,
      value,
      onChange,
      type,
      pattern,
      placeholder,
    } = this.props;
    return (
      <Modal
        centered
        title={title}
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        confirmLoading={loading}
      >
        {content && <p>{content}</p>}
        <div>
          {label && (
            <div>
              <label htmlFor="phone" className="ant-form-item-required">
                Phone
              </label>
            </div>
          )}
          <Input
            type={type ? type : 'text'}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            pattern={pattern}
          />
          {this.state.error && (
            <span css={css(styles.error)}>{this.state.error}</span>
          )}
        </div>
      </Modal>
    );
  }
}

const styles = {
  error: {
    color: colors.error,
    marginVertical: 5,
  },
};
