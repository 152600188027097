import { get } from 'lodash';
import { Profile } from '../interfaces/profile';
import { removeEmpty, convertWhere } from '../../utils';
import { instance } from './instance';

export const getProfile = async (id: string) => {
  const resp = await instance.get(`/profile/${id}`, { data: {} });
  return get(resp, 'data.profile', null) as Profile;
};

export const getProfiles = async (where?: {}) => {
  let resp;
  if (where) {
    resp = await instance.post(`/profile`, {
      ...convertWhere(where),
    });
  } else {
    resp = await instance.get(`/profile`);
  }
  return get(resp, 'data.profiles', []) as Profile[];
};

export const createProfile = async (profile: Profile) => {
  const resp = await instance.post('/profile/create', {
    profile: removeEmpty(profile),
  });
  return get(resp, 'data.profile', null) as Profile;
};

export const updateProfile = (profile: Profile) => {
  return createProfile(profile);
};

export const deleteProfile = async (profileId: string) => {
  const resp = await instance.delete(`/profile/${profileId}`);
  return get(resp, 'data');
};
