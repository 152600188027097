/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import SharedStyles from '../../_shared/styles';
import { Icon, Switch } from 'antd';

interface IProps {
  canShowEdit: boolean;
  active: boolean;
  toggleActive: () => void;
  loading: boolean;
}

export const ActiveSwitch = ({ ...props }: IProps) => {
  const { canShowEdit, active, loading, toggleActive } = props;
  return canShowEdit ? (
    <div css={css(SharedStyles.row, `margin-bottom: 5px; float: right;`)}>
      <span
        css={css`
          font-size: 16px;
          margin-right: 5px;
        `}
      >
        {active ? 'Active' : 'Not Active'}
        {loading && <Icon css={css('margin-left: 4px;')} type="loading" />}
      </span>
      <Switch onChange={loading ? () => 0 : toggleActive} checked={active} />
    </div>
  ) : null;
};
