/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import { BaseDzConfigDataForm } from './base-dz-config.component';
import { VacuumDzConfigDataForm } from './vacuum-config-form.component';
import { EQ_TYPE } from '../../../../_shared/interfaces/equipment';
import { Model } from '../../../../_shared/interfaces/model';
import _ from 'lodash';
import {
  BaseConfigPData,
  VacuumConfigPData,
  ConfigPublishData,
} from '../../../../_shared/interfaces/publishData';

type Payload = ConfigPublishData;

interface IProps {
  equipmentType: EQ_TYPE;
  model: Model;
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: Payload | undefined) => void;
}

export const DzConfigDataForm = (props: IProps): JSX.Element => {
  const { equipmentType, model, validChanged, payloadChanged } = props;

  const [baseDataValid, setBaseDataIsValid] = useState<boolean>(false);
  const [vacuumDataValid, setVacuumDataIsValid] = useState<boolean>(false);
  const [baseData, setBaseData] = useState<BaseConfigPData | undefined>(
    undefined
  );
  const [vacuumData, setVacuumData] = useState<VacuumConfigPData | undefined>(
    undefined
  );

  //notify parent of (combined) validation change whenever either of our children validation changes
  useEffect(() => {
    let valid = baseDataValid;
    if (equipmentType === EQ_TYPE.Vacuum && valid) {
      valid = vacuumDataValid;
    }

    validChanged(valid);
  }, [baseDataValid, vacuumDataValid]);

  //notify parent of (combined) payload change whenever either of our children payload changes
  useEffect(() => {
    if (!!!baseData) {
      payloadChanged(undefined);
      return;
    }

    let data = baseData;
    if (equipmentType === EQ_TYPE.Vacuum) {
      _.merge(data, vacuumData);
    }

    payloadChanged(data);
  }, [baseData, vacuumData]);

  const baseDataValidChanged = (isValid: boolean) => {
    setBaseDataIsValid(isValid);
  };
  const baseDataChanged = (data: BaseConfigPData) => {
    setBaseData(data);
  };
  const vacuumDataValidChanged = (isValid: boolean) => {
    setVacuumDataIsValid(isValid);
  };
  const vacuumDataChanged = (data: VacuumConfigPData) => {
    setVacuumData(data);
  };

  return (
    <Fragment>
      <BaseDzConfigDataForm
        equipmentType={equipmentType}
        model={model}
        validChanged={baseDataValidChanged}
        payloadChanged={baseDataChanged}
      />

      {equipmentType === EQ_TYPE.Vacuum && (
        <VacuumDzConfigDataForm
          validChanged={vacuumDataValidChanged}
          payloadChanged={vacuumDataChanged}
        />
      )}
    </Fragment>
  );
};
