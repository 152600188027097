/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BaseSensorInstantPData } from '../../../../_shared/interfaces/publishData';
import { TimePicker } from 'antd';
import { makeNumInput, makeInputGroup } from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: BaseSensorInstantPData | undefined) => void;
}

type MaybeNumber = number | undefined;

export const BaseDzSensorInstantDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  //STATE
  const [seq, setSeq] = useState<MaybeNumber>(undefined);
  const [aH, setAH] = useState<MaybeNumber>(undefined);
  const [m1P, setM1P] = useState<MaybeNumber>(undefined);
  const [m1T, setM1T] = useState<MaybeNumber>(undefined);
  const [aT, setAT] = useState<MaybeNumber>(undefined);
  const [vL, setVL] = useState<MaybeNumber>(undefined);
  const [m1C, setM1C] = useState<MaybeNumber>(undefined);
  const [time, setTime] = useState<Moment>(moment());

  const timeChanged = (m: Moment, _s: string) => {
    setTime(m);
  };

  useEffect(() => {
    if (
      seq !== undefined &&
      aH !== undefined &&
      m1P !== undefined &&
      m1T !== undefined &&
      aT !== undefined &&
      vL !== undefined &&
      m1C !== undefined
    ) {
      validChanged(true);
      payloadChanged({
        seq: seq,
        aH: aH,
        m1P: m1P,
        m1T: m1T,
        aT: aT,
        vL: vL,
        m1C: m1C,
        time: time.unix(),
      });
    } else {
      validChanged(false);
      payloadChanged(undefined);
    }
  }, [seq, aH, m1P, m1T, aT, vL, m1C, time]);

  //todo time inputs
  return (
    <div>
      {makeNumInput('seq', seq, setSeq)}
      {makeNumInput('AH', aH, setAH)}
      {makeNumInput('M1P', m1P, setM1P)}
      {makeNumInput('M1T', m1T, setM1T)}
      {makeNumInput('AT', aT, setAT)}
      {makeNumInput('VL', vL, setVL)}
      {makeNumInput('M1C', m1C, setM1C)}
      {makeInputGroup(
        'Time',
        <TimePicker
          defaultValue={time}
          format={'HH:mm'}
          onChange={timeChanged}
        />
      )}
    </div>
  );
};
