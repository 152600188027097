/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { VacuumConfigPData } from '../../../../_shared/interfaces/publishData';
import {
  makeSwitch,
  makeNumInput,
  makeInput,
} from '../../spoof-a-publish.component';

interface IProps {
  validChanged: (valid: boolean) => void;
  payloadChanged: (data: VacuumConfigPData) => void;
}

export const VacuumDzConfigDataForm = (props: IProps): JSX.Element => {
  const { validChanged, payloadChanged } = props;

  const [infiniTankEnabled, setInfiniTankEnabled] = useState<boolean>(false);
  const [tandemRole, setTandemRole] = useState<string | undefined>(undefined);
  const [targetTandemPressure, setTargetTandemPressure] = useState<
    number | undefined
  >(undefined);
  const [tandemGroup, setTandemGroup] = useState<number | undefined>(undefined);

  useEffect(() => {
    validChanged(true);
    payloadChanged({
      infinitank: infiniTankEnabled,
      tandem: {
        role: tandemRole ? tandemRole : '',
        target_pressure: targetTandemPressure ? targetTandemPressure : 0,
        group: tandemGroup ? tandemGroup : 0,
      },
    });
  }, [infiniTankEnabled]);

  return (
    <div>
      {makeSwitch('High Voltage', infiniTankEnabled, setInfiniTankEnabled)}
      {makeInput('Tandem Role', tandemRole, setTandemRole)}
      {makeNumInput(
        'Target Tandem Role',
        targetTandemPressure,
        setTargetTandemPressure
      )}
      {makeNumInput('Tandem Group', tandemGroup, setTandemGroup)}
    </div>
  );
};
